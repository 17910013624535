import useWindowSizes from "./window-sizes.hook"

const RespondType = {
  MIN: 'min',
  MAX: 'max',
}

/**
 * @param {number} breakpoint 
 * @returns {boolean} windowSizes.width
 * @param {'min' | 'max'} respondType
 */
const useRespondTo = (breakpoint, respondType = RespondType.MAX) => {
  const windowSizes = useWindowSizes();

  return respondType === RespondType.MAX 
    ? windowSizes.width < breakpoint
    : windowSizes.width >= breakpoint;
}

export default useRespondTo;

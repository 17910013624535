import { useMemo } from "react";
import { hexToRGBA } from "./MspClientPriceCalculator";
import MyRadarChart from "../../chartjs/my-radar.chart";

export const PackageListRadar = ({ dataList, colorPassed, passedWidth, passedHeight }) => {
    const updatedData = useMemo(() => {
      return {
        labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],
  
        datasets: [
          {
            data: dataList ?? [0, 0, 0, 0, 0],
            pointRadius: [0, 0, 0, 0, 0],
            datalabels: {
              display: false,
            },
            backgroundColor: colorPassed ? hexToRGBA(colorPassed, 0.24) : "rgba(0, 143, 212, 0.24)",
            // backgroundColor: `${"#5244B3"}30`,
            borderColor: colorPassed ?? `${"#008FD4"}`, // "rgba(0, 143, 212, 1)",
            pointBackgroundColor: colorPassed ?? "rgba(0, 143, 212, 1)",
            borderWidth: 1,
            pointLabelFontSize: 0,
            scaleShowLabels: false,
            label: "Current Posture",
          },
        ],
      };
    }, [dataList]);
    const updatedOptions = useMemo(() => {
      return {
        animation: {
          duration: 1, // 4000, // Set the duration of the transition in milliseconds
        },
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
  
        tooltips: {
          enabled: true,
        },
        scales: {
          r: {
            beginAtZero: false,
            max: /*40*/50,
            min: 0,
            ticks: {
              display: false,
              stepSize: 10,
              backdropColor: "rgba(0, 0, 0, 0)",
            },
            pointLabels: {
              display: false,
              fontSize: 16,
              fontFamily: "Archivo, sans-serif",
              fontColor: "#101010",
            },
          },
        },
        elements: {
          point: {
            radius: 2,
            hoverRadius: 4,
          },
        },
      };
      // return opts;
    }, []);
    return (
      <div>
        <MyRadarChart
          id="actial-radar"
          // redraw={true}
          height={passedHeight ?? 80}
          width={passedWidth ?? 80}
          style={{ margin: "auto" }}
          data={updatedData}
          options={updatedOptions}
        />
      </div>
    );
  };
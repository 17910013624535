import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import ModalDynamic from "../../ModalDynamic/ModalDynamic";
import { useAuth } from "../../context/Auth";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import {
  Button,
  Modal,
  
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { BsFillEyeFill } from "react-icons/bs";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setsegment } from "../../../redux/segmentEventStatus/actions";
import BigModal from "../../settingsV2/rewards/BigModal";
import { CustomRightSideToastForSendReport, RightSideToast } from "../../PriceCalculator/modals/CustomToast";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
import { ROUTE_PATHS } from "../../../constants/app.constants";

const CreditModal = (props) => {
  const { authTokens } = useAuth();
  const history= useHistory()
  const [modalStage, setModalStage] = useState(0);
  const [activeTab, setActiveTab] = useState("refer");
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [email, setEmail] = useState("");
  const [reportEmail, setReportEmail] = useState("");
  const [error, setError] = useState(false);
  const [proServiceEarnReward, setProServiceEarnReward] = useState([]);
  const [sendReportId, setSendReportId] = useState(0);
  const [sharePostId, setSharePostId] = useState(0);
  const [reward, setReward] = useState([]);
  const [sreward, setSreward] = useState([]);
  const [copy, isCopy] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isSendingReport, setIsSendingReport] = useState(false);
  const [sent, isSent] = useState(false);
  const [sendInvite, isSendInvite] = useState(true);
  const [rewardopportunities, setRewardopportunities] = useState([]);
  const [pdfProcess, setPdfProcess] = useState(false);
  const [reportSent, isReportSent] = useState(false);
  const [shareDetails, setShareDetails] = useState({});
  const [rewardId, setRewardId] = useState("");
  const [rewardType, setRewardType] = useState("");
  const [reportOnceSent, setReportOnceSent] = useState(false);

  const [showBigModal, setShowBigModal] = useState(false);
  const dispatch = useDispatch();
  const insideShareReport = useSelector(
    (state) => state.segment?.insideShareReport
  );
  useEffect(() => {
    getProServiceEarnReward();
    getReward();
    if (email) {
      isSent(false);
      isSendInvite(true);
    }
  }, [email]);

  async function getProServiceEarnReward() {
    const response = await getAPIData(131, authTokens);
    setProServiceEarnReward(response);
  }

  async function getReward() {
    const response = await getAPIData(132, authTokens);
    setReward(response);
    if (props.setProTotalCredit) {
      props.setProTotalCredit(response.ProServices_TotalCredit);
    }
  }

  async function setRewardopportunity() {
    const response = await getAPIData(133, authTokens);
    setRewardopportunities(response);
  }

  async function handleSendInvite() {
    if (handleValidations(email)) {
    } else {
      setError(false);
      isSendInvite(false);
      setIsSendingEmail(true);
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendReferral`,
        getRequestOptions(authTokens, "POST", {
          EmailAddress: email,
          AccessKey: localStorage.getItem("user_MessageObj")
            ? JSON.parse(localStorage.getItem("user_MessageObj"))
            : "null",
        })
      ).then((response) => response.json());
      if (data.mr.Success) {
        updateAccessKeyInLocalStorage(data?.mr?.Message)
        
        setIsSendingEmail(false);
        isSent(true);
        setTimeout(() => {
          isSent(false);
          isSendInvite(true);
        }, 1000);
        setEmail("");
        RightSideToast(
          5,
          `Thanks for sending invitation, we have credited to your Pro Services account.`
        );
        TrackUser("Invite Sent");
      } else {
        setEmail("");
        setIsSendingEmail(false);
        isSendInvite(true);
        setError("Invite failed");
      }
    }
  }
  const handleValidations = (email) => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email == "") {
      setError("Email field is required");
      return true;
    } else if (email && !re.test(email)) {
      setError("Invalid email address format");
      return true;
    } else {
      setError("Failed");
    }
    return false;
  };

  const sendReportHandler = (id) => {
    setModalStage(3);
    setSendReportId(id);
  };

  async function previewReport() {
    setPdfProcess(true);
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/PreviewReportPDF?emailReportTypeInt=1`,
      getRequestOptions(authTokens, "GET")
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.mr.Success == true) {
          TrackUser("Preview report clicked");
          setPdfProcess(false);
          window.open(data.FileURL);
        } else {
          props.showToast(true);
          setPdfProcess(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function sendReport(type = null, share = false) {
    const accessKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : "null";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${authTokens}`);
    var inputs = new URLSearchParams();
    if (share) {
      inputs.append("RewardId", rewardId);
    } else {
      inputs.append("RewardId", sendReportId);
    }

    if (type == null) {
      inputs.append("RewardType", 0);
      inputs.append("EmailAddress", reportEmail);
    } else {
      if (share) {
        TrackUser("Earned credit");
        inputs.append("RewardType", rewardType);
      } else {
        inputs.append("RewardType", 1);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };

    if (type == null) {
      if (handleValidations(reportEmail)) {
        return false;
      }
    }

    setIsSendingReport(true);
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CompleteReward?accessKey=${accessKey}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data?.Success) {
          updateAccessKeyInLocalStorage(data?.Message)
         
        }
        if (data) {
          getReward();
          getProServiceEarnReward();
          if (props.setProCredit) {
            props.setProCredit(false);
          }
        }

        setIsSendingReport(false);
        isReportSent(true);
        {
          type == null && TrackUser("Send report sent"); //segment
          setReportOnceSent(true);
        } //segment
        if (type == null) {
          setTimeout(() => {
            props.closeModal();
            isReportSent(false);
          }, 1500);
          //
          // RightSideToast(
          //   5,
          //   `Thanks for sending report to CEO, we have credited to your Pro Services account.`
          // );

          CustomRightSideToastForSendReport(
            "+ £5 Congratulations!",
            `Thanks for sending report to CEO, we have credited to your Pro Services account.`,
            `Add to your Team`,
            () => {
              history.push(`${ROUTE_PATHS.saas.settings.team}?openTeamModal=true`);
            }
          );
        }
        setSreward(data.SendReport_Rewards_List);
        setReportEmail("");
        if (type != null) {
          setModalStage(2);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSendingReport(false);
      });
  }

  const sharePostHandler = (id, share = false) => {
    // let metatag = document.querySelector('#meta_title');
    if (share) {
      // metatag.insertAdjacentHTML('afterend', '<meta property="og:title" id="meta_title" content="'+ share.Description +'">');
      // metatag.remove();
      let url = window.location.origin + "/signup";
      let url2 = window.location.origin + "/sign-up";
      //let url =  "https://portal.highground.io/signup";
      setShareDetails({
        desc: share.Description,
        price: share.Value,
        facebook:
          "https://www.facebook.com/share.php?u=https://cybercare-ui-dev.azurewebsites.net/login" +
          url +
          "&quote=" +
          share.Description,
        twitter:
          "https://twitter.com/intent/tweet?url=" +
          url2 +
          "&text=" +
          share.Description,
        linked:
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          url +
          "&title=" +
          share.Description +
          "&summary=I’ve just completed a Cyber Risk Assessment with CyberCare Vision&source=LinkedIn",
        //linked: "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.socialmediatoday.com/content/adding-anchor-text-links-linkedin-profiles&title=Adding to liked",
      });
    }
    setModalStage(1);
    setSharePostId(id);
  };

  const copyTextHandler = (link) => {
    navigator.clipboard.writeText(link);
    isCopy(true);
  };

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const referralData = () => {
    return (
      <div className="ml24px mr24px">
        <span className="main-bold-heading">How it Works</span>
        <p className="textBlack mt16px">
          CyberCare Vision users are rewarded with Free Integrations and
          Professional Services Credit for referring colleagues, peers or any
          individual who will use the product within their business to improve
          their cyber security compliance, visibility or access services
          available for purchase through CyberCare Vision. Users should not
          refer to friends, family or any individual who has no intention of
          using or reasonable use for the product.
        </p>
        <p className="textBlack mt16px" style={{ marginBottom: "40px" }}>
          Users are rewarded with a Free Integration upon account signup,
          whether directly or via referral. The first 2 referrals are rewarded
          with Free Integrations. Subsequent referrals are each rewarded with £5
          Professional Services credit, up to a maximum of £500 for the lifetime
          of the account. In both scenarios, the user must complete signup for a
          Freemium account using the link in the referral email for the referrer
          to redeem the reward. Rewards are redeemed automatically and should be
          available immediately after the user completes signup, however this
          can take up to 24 hours. If you do not have your reward within 24
          hours, please contact support from within your portal.
        </p>
        <span className="main-bold-heading">Restrictions</span>
        <p className="textBlack mt16px">
          Users cannot invite users using free or anonymous email services. Any
          user suspected to be using a free or throwaway email address which is
          not banned during registration, but which is later identified as being
          so, will have the reward associated with this referral revoked, and
          may have their account suspended indefinitely and without notice. Any
          credit consumed to this point for services purchased directly or
          indirectly via CyberCare Vision will also be rescinded, and users will
          be liable to repay any credit used up to that point.{" "}
        </p>
        <Button
          className="w-100 submit-btn"
          onClick={() => $(".fade.bigModal").click()}
          style={{ marginTop: "40px" }}
        >
          Okay
        </Button>
      </div>
    );
  };
  const message = error ? error : "";
  return (
    <Fragment>
      <Modal
        animation={false}
        show={props.show}
        dialogClassName="modal-50w"
        className="centerModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        onHide={props.closeModal}
      >
        <Modal.Header className="text-center">
          {modalStage == 0 ? (
            <div className="w-100 mb-0 txt-blue heading4">
              Your credit balance:
              {props.proCredit
                ? props.proCredit
                : ` £${proServiceEarnReward.ProServices_TotalCredit}`}
            </div>
          ) : (
            <Fragment>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setModalStage(0);
                  isReportSent(false);
                  setError(false);
                  if (reportOnceSent == false) {
                    TrackUser("Send report clicked off");
                  }
                }}
              >
                <FiChevronLeft size="25" />
              </span>
              <div className="w-100 mb-0 greyText heading4">
                {modalStage == 1
                  ? `Share and earn ${shareDetails.price}`
                  : modalStage == 3
                  ? "Send Report to CEO"
                  : "Rewards"}
              </div>
            </Fragment>
          )}
          <span
            className="cursor-pointer"
            onClick={() => {
              if (reportOnceSent == false && insideShareReport == true) {
                TrackUser("Send report clicked off");
              }
              dispatch(setsegment({ insideShareReport: false }));

              props.closeModal();
            }}
          >
            <img src="/images/icons/close.svg" alt="" />
          </span>
        </Modal.Header>
        <Modal.Body>
          {modalStage == 1 && (
            <div>
              <hr />
              <div style={{ margin: "35px" }}>
                <div className="heading4 mb16px">
                  {shareDetails
                    ? shareDetails.desc
                    : "I've just completed a Cyber Risk Assessment with CyberCareVision"}
                </div>
                <img src="/images/shareRewardlogo.png"></img>
              </div>
              <hr />
              <div className="text-center" style={{ margin: "35px" }}>
                <div className="textBlackBold" style={{ marginBottom: "35px" }}>
                  Share on:
                </div>
                <div className="d-flex justify-content-center">
                  <a
                    onClick={() => {
                      TrackUser("Share post Completed");
                      sendReport("facebook", true);
                    }}
                    target="_blank"
                    href={shareDetails.facebook}
                  >
                    <div className="mr16px">
                      <div className="greyBorderCont p16px cursor-pointer">
                        <img src="/images/icons/facebook-icon.svg"></img>
                      </div>
                      <div className="textBlackBold mt8px">Facebook</div>
                    </div>
                  </a>

                  <a
                    href={shareDetails.twitter}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      TrackUser("Share post Completed");
                      sendReport("twitter", true);
                    }}
                  >
                    <div className="mr16px">
                      <div className="greyBorderCont p16px align-self-stretch cursor-pointer">
                        <img src="/images/icons/twitter-icon.svg"></img>
                      </div>
                      <div className="textBlackBold mt8px">Twitter</div>
                    </div>
                  </a>
                  <a
                    href={shareDetails.linked}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      TrackUser("Share post Completed");
                      sendReport("linkedin", true);
                    }}
                  >
                    <div>
                      <div className="greyBorderCont p16px cursor-pointer">
                        <img src="/images/icons/linkein-icon.svg"></img>
                      </div>
                      <div className="textBlackBold mt8px">LinkedIn</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
          {modalStage == 0 && (
            <Tabs
              className={`cybercare-tabs ${
                proServiceEarnReward.SendReport_Reward_Available
                  ? "rewardsTab "
                  : ""
              }  ${
                proServiceEarnReward.Shared_Reward_Available ? "shareTab" : ""
              }`}
              defaultActiveKey={activeTab}
              transition={false}
            >
              <Tab
                eventKey="refer"
                title="Refer"
                className="cybercare-tab-content"
                onClick={() => setActiveTab("refer")}
              >
                <div>
                  <h6 className="heading3">
                    Earn Integrations and Pro Services Credit
                  </h6>
                  <p className="greyText">
                    {proServiceEarnReward.Opportunity_Header}
                  </p>
                  <InputGroup
                    className="grey-input mb8px"
                    style={{ marginTop: "32px" }}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text className="greyText">
                        Email:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      value={email}
                      // defaultValue="alma.lawson@example.com"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <InputGroup.Append
                    // onClick={() => setModalStage(2)}
                    >
                      <InputGroup.Text className="passwordIcon p8px cursor-pointer">
                        <span
                          className={`input-btn ${sent ? " greenText" : ""}`}
                          onClick={() => handleSendInvite()}
                        >
                          {isSendingEmail ? (
                            <span>
                              <Spinner
                                animation="border"
                                variant="info"
                                size="sm"
                                className="mr8px"
                              />
                              Sending
                            </span>
                          ) : sent ? (
                            "Sent!"
                          ) : sendInvite ? (
                            "Send Invite"
                          ) : (
                            ""
                          )}
                        </span>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  {error && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 250 }}
                        overlay={renderTooltip(message)}
                      >
                        <span>
                          <img src="/images/icons/error.svg" /> Send Invite
                          Failed.
                        </span>
                      </OverlayTrigger>
                    </Form.Control.Feedback>
                  )}
                  <span className="greyText smallTxt">
                    Note: You can refer to a corporate email address only -
                    generic email domains (such as gmail.com, outlook.com) are
                    not permitted. Read our{" "}
                    <span
                      className="txt-blue cursor-pointer"
                      onClick={() => {
                        // setShowRulesModal(false);
                        // setInterval(() => {
                        //   setShowRulesModal(true);
                        // }, 100);
                        setShowBigModal(true);
                      }}
                    >
                      'referral program rules'
                    </span>{" "}
                    for more info.
                  </span>
                  <h6 className="heading3" style={{ marginTop: "56px" }}>
                    Or copy your invite link
                  </h6>
                  <InputGroup className="grey-input mt16px">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="greyText">
                        Refferal Link:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      defaultValue={proServiceEarnReward.Referral_Link}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="passwordIcon p8px">
                        <span
                          className="input-btn"
                          onClick={() => {
                            copyTextHandler(proServiceEarnReward.Referral_Link);
                            TrackUser("Invite link copied");
                          }}
                        >
                          {copy ? (
                            "copied"
                          ) : (
                            <img src="/images/icons/copyIcon.svg" />
                          )}
                        </span>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </Tab>
              <Tab
                eventKey="share"
                title="Share"
                className="cybercare-tab-content shareTab"
                onClick={() => setActiveTab("share")}
              >
                <div>
                  {proServiceEarnReward &&
                    proServiceEarnReward.Shared_Reward_List &&
                    proServiceEarnReward.Shared_Reward_List.map(
                      (sharedReward, index) => (
                        <>
                          {sharedReward.RewardId == 0 ? (
                            <div>
                              <span className="textBlackBold">
                                {sharedReward.Description}
                              </span>
                            </div>
                          ) : (
                            <>
                              <div
                                key={index}
                                className="d-flex justify-content-between cursor-pointer"
                                onClick={() => {
                                  TrackUser("Share post clicked");

                                  setRewardType(sharedReward.Type);
                                  setRewardId(sharedReward.RewardId);
                                  sharePostHandler(
                                    sharedReward.RewardId,
                                    sharedReward
                                  );
                                }}
                              >
                                <div>
                                  <div className="greyText lll">
                                    {sharedReward.Type}
                                  </div>
                                  <span className="textBlackBold">
                                    {sharedReward.Description}
                                  </span>
                                </div>
                                <div className="d-flex align-self-center greyText">
                                  <span
                                    style={{ paddingRight: "10px" }}
                                    className="align-self-center"
                                  >
                                    {sharedReward.Value}
                                  </span>
                                  <FiChevronRight
                                    className="align-self-center"
                                    size="25"
                                  />
                                </div>
                              </div>
                              <hr />
                            </>
                          )}
                        </>
                      )
                    )}
                </div>
              </Tab>
              <Tab
                eventKey="send-report"
                title="Send Report"
                className="cybercare-tab-content"
                onClick={() => setActiveTab("send-report")}
              >
                <h6 className="heading3">
                  Send your cyber report to earn credit
                </h6>
                <p className="greyText">
                  Your cyber report is an executive summary of your
                  organisations cyber health in HighGround so far.
                </p>
                <div
                  className="light-blue-btn"
                  style={{ width: "154px", marginBottom: "56px" }}
                  onClick={() => {
                    // TrackUser("Preview report clicked");
                    previewReport();
                  }}
                >
                  Preview Report
                  {pdfProcess ? (
                    <span className="ml-1">
                      &nbsp;
                      <Spinner animation="border" variant="info" size="sm" />
                    </span>
                  ) : (
                    <BsFillEyeFill size="16" style={{ marginLeft: "8px" }} />
                  )}
                </div>
                {proServiceEarnReward &&
                  proServiceEarnReward.SendReport_Rewards_List &&
                  proServiceEarnReward.SendReport_Rewards_List.map(
                    (sendReportReward, index) => (
                      <>
                        {!proServiceEarnReward.SendReport_Reward_Available ? (
                          <div key={index}>
                            <div className="greyText">
                              {sendReportReward.Type}
                            </div>
                            <span className="textBlackBold">
                              {sendReportReward.Description}
                            </span>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="d-flex justify-content-between cursor-pointer"
                            onClick={() => {
                              dispatch(setsegment({ insideShareReport: true }));
                              sendReportHandler(sendReportReward.RewardId);
                            }}
                          >
                            <div>
                              <div className="greyText">
                                {sendReportReward.Type}
                              </div>
                              <span className="textBlackBold">
                                {sendReportReward.Description}
                              </span>
                            </div>
                            <div className="d-flex align-self-center greyText">
                              <span
                                style={{ paddingRight: "10px" }}
                                className="align-self-center"
                              >
                                {sendReportReward.Value}
                              </span>
                              <FiChevronRight
                                className="align-self-center"
                                size="25"
                              />
                            </div>
                          </div>
                        )}

                        <hr />
                      </>
                    )
                  )}
                {/* <div
                  className="d-flex justify-content-between cursor-pointer"
                  onClick={() => setModalStage(3)}
                >
                  <div>
                    <div className="greyText">Share post</div>
                    <span className="textBlackBold">
                      I've just completed a Cyber Essentials Audit with
                      CyberCare Vision
                    </span>
                  </div>
                  <div className="d-flex align-self-center greyText">
                    <span
                      style={{ paddingRight: "10px" }}
                      className="align-self-center"
                    >
                      £5
                    </span>
                    <FiChevronRight className="align-self-center" size="25" />
                  </div>
                </div>
                <hr />
                <div */}
                {/* className="d-flex justify-content-between cursor-pointer"
                  onClick={() => setModalStage(3)}
                >
                  <div>
                    <div className="greyText">Share post</div>
                    <span className="textBlackBold">
                      I've just completed a Cyber Essentials Audit with
                      CyberCare Vision
                    </span>
                  </div>
                  <div className="d-flex align-self-center greyText">
                    <span
                      style={{ paddingRight: "10px" }}
                      className="align-self-center"
                    >
                      £5
                    </span>
                    <FiChevronRight className="align-self-center" size="25" />
                  </div>
                </div> */}
              </Tab>
            </Tabs>
          )}
          {modalStage == 2 && (
            <Fragment>
              <div className="text-center" style={{ padding: "80px 100px" }}>
                <div className="circle-light-green d-table">
                  <span className="main-heading d-table-cell align-middle">
                    {shareDetails && shareDetails.price}
                  </span>
                </div>
                <div className="heading4 mt-16px">Congratulations!</div>
                <div className="textBlackBold mt-16px">
                  Thanks for sharing, {shareDetails && shareDetails.price} has
                  been credited to your professional services account.
                </div>
              </div>
              <div className="p24px">
                <div className="textBlackBold">
                  {sreward && sreward.FooterText}
                </div>
                <div className="d-flex overflow-auto share-slider mt8px">
                  {sreward &&
                    sreward.SendReport_Rewards_List &&
                    sreward.SendReport_Rewards_List.map(
                      (rewardReport, index) => (
                        <div
                          key={index}
                          className="small_container mr8px d-flex cursor-pointer"
                          style={{ minWidth: "240px" }}
                        >
                          <div>
                            <div className="greyText">{rewardReport.Type}</div>
                            <div className="mt8px textBlackBold">
                              {rewardReport.Description}
                            </div>
                          </div>
                          <div className="greyText d-flex ml-auto">
                            <div> {rewardReport.Value}</div>
                            <FiChevronRight size="19" />
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </Fragment>
          )}
          {modalStage == 3 && (
            <div style={{ padding: "40px 24px" }}>
              <h6 className="heading3">Send a Cyber Report to your CEO</h6>
              <p className="greyText">
                Enter your CEO's email below to send them your report.
              </p>
              <div
                className="light-blue-btn"
                style={{ width: "154px", marginBottom: "56px" }}
                onClick={() => previewReport()}
              >
                Preview Report
                {pdfProcess ? (
                  <span className="ml-1">
                    &nbsp;
                    <Spinner animation="border" variant="info" size="sm" />
                  </span>
                ) : (
                  <BsFillEyeFill size="16" style={{ marginLeft: "8px" }} />
                )}
              </div>
              <InputGroup
                className="grey-input mb8px"
                style={{ marginTop: "32px" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText">Email:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  defaultValue="alma.lawson@example.com"
                  value={reportEmail}
                  onChange={(event) => setReportEmail(event.target.value)}
                />
                <InputGroup.Append
                  onClick={() => {
                    if (reportEmail) {
                      TrackUser("Send report clicked");
                    }

                    sendReport();
                  }}
                >
                  <InputGroup.Text className="passwordIcon p8px cursor-pointer">
                    {isSendingReport ? (
                      <span className="input-btn">
                        <Spinner
                          className="mr-1"
                          animation="border"
                          variant="info"
                          size="sm"
                        />
                        &nbsp;Sending
                      </span>
                    ) : reportSent ? (
                      <span
                        className={`input-btn ${
                          reportSent ? " greenText" : ""
                        }`}
                      >
                        Sent!
                      </span>
                    ) : (
                      <span className="input-btn">Send Report</span>
                    )}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {error && error != "Failed" && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 200, hide: 250 }}
                    overlay={renderTooltip(message)}
                  >
                    <span>
                      <img src="/images/icons/error.svg" /> {error}
                    </span>
                  </OverlayTrigger>
                </Form.Control.Feedback>
              )}
              <span className="greyText smallTxt">
                Note: You can refer to a corporate email address only - generic
                email domains (such as gmail.com, outlook.com) are not
                permitted. Read our{" "}
                <span
                  className="txt-blue cursor-pointer"
                  onClick={() => {
                    setShowBigModal(true);
                  }}
                >
                  'referral program rules'
                </span>
                for more info.
              </span>
            </div>
          )}
        </Modal.Body>
        {modalStage != 1 && (
          <Modal.Footer className="txt-blue text-center p16px">
            {modalStage == 0 ? (
              <Link
                to={ROUTE_PATHS.saas.settings.rewards}
                className="cursor-pointer"
              >
                Go to Rewards
                <FiChevronRight className="align-self-center" size="16" />
              </Link>
            ) : modalStage == 2 ? (
              <div className="cursor-pointer" onClick={() => setModalStage(0)}>
                Thanks!
              </div>
            ) : (
              ""
            )}
          </Modal.Footer>
        )}
      </Modal>
      {showRulesModal ? (
        <ModalDynamic
          setShow={true}
          modalHeader={"Referral Programme Rules"}
          modalData={referralData}
        />
      ) : (
        ""
      )}
      <BigModal show={showBigModal} hideModal={() => setShowBigModal(false)} />
    </Fragment>
  );
};

export default CreditModal;

import { useHistory } from "react-router-dom";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { TrimString } from "../../Utils/StringTrimmer";
import { ROUTE_PATHS } from "../../constants/app.constants";

const UserDetailsButton = ({
  userDetails,
  darkTheme = true,
  isSaas = false
}) => {
  const history = useHistory();
  return (
    <div
      className="d-flex justify-content-between align-items-center pointer user-details-info"
      onClick={() => {
        history.push(isSaas ? ROUTE_PATHS.saas.settings.myProfile : ROUTE_PATHS.mspsettingsv2.myProfile);
      }}
    >
      <div className="d-flex align-items-center">
        {userDetails.Avatar == "No URL" ? (
          <ImageWithInitials
            width={31}
            background="purple"
            initials={userDetails?.Initials}
          />
        ) : (
          <img
            alt=""
            src={userDetails?.Avatar}
            className="thumbnail-pic"
          />
        )}
        <div className={`ml-2 ${!darkTheme ? 'f-white' : ''}`}>
          <p className="title m-0">
            {TrimString(userDetails.FullName, 17)}
          </p>
          <p className="subtitle m-0">
            {TrimString(userDetails.UserName, 17)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserDetailsButton;

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VisibilityType } from "../../../../../constants/filter.constants";
import { TrimString } from "../../../../../Utils/StringTrimmer";
import AttackSurfaceTooltip from "../../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import SearchFilterComponent from "../../../../SearchFilterComponent/SearchFilterComponent";
import { AutomaticNoSpendFoundUI, AutomaticSpendSyncingUI, NoSpendFoundUI } from "../techspend.not-found";
import { endOfDay, startOfMonth, subMonths } from "date-fns";
import TechnologySpendFilter from "../../technologySpendFilter/TechnologySpendFilter";
import { mspClientDashboardDatePickerState } from "../../../../../redux/mspClientStates/actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GreyLoader } from "../../../../Theme/APILoaders";
import { viewOnlyAccess } from "../../../../App/App";
import PaymentIconComponent from "../../../../Common/payment-icon.component";
import { Dropdown } from "react-bootstrap";
import useDropdown from "../../../../../hooks/dropdown.hook";
import HorizontalDotsIcon from "../../../../icons/horizontal-dots.icon";
import TechspendAddDropdown from "../techspend-add.dropdown";
import { TechspendAddMenuAction } from "../../../../../constants/techspend.constants";
import { useState } from "react";
import { all_integration_data } from "../../../../../Utils/CommonFunctions";
import useDateDefineds from "../../../../../hooks/date-defineds.hook";

const MenuActions = {
  Edit: "Edit",
  View: "View",
  Delete: "Delete",
  Unlink: "Unlink",
}

const EditableMenuItems = [
  {
    label: "Edit",
    action: MenuActions.Edit
  },
  {
    label: "Delete",
    action: MenuActions.Delete
  }
]

const NotEditableMenuItems = [
  {
    label: "View",
    action: MenuActions.View
  },
  {
    label: "Unlink",
    action: MenuActions.Unlink
  }
]

const invoiceFilterData = [
  {
    filterName: "Date",
    filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
    filterData: {
      filterType: "date",
      selectedDateRange: {
        startDate: null, // new Date(),
        endDate: null, //new Date(),
        key: "selection",
      },
    },
  },
  {
    filterName: "Amount",
    filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    filterData: {
      filterType: "amount",
      filterAmount: "",
    },
  },
  {
    filterName: "Subcategory",
    filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
    filterData: {
      filterType: "subcategory",
      filterList: [],
    },
  },
];

const AllSpendTab = ({
  categoryPageData,
  invoiceFilterSearch,
  spendSearch,
  setSpendSearch,
  spendSearchRef,
  goTo,
  getInvoiceDetails,
  setInvoiceEditMode,
  setAddSpendSelectedTab,
  setLoading,
  viewOnlyAccess,
  Pages,
  highlightedInvoice,
  activeFilters,
  setActiveFilters,
  applyingFilters,
  getFilters,
  getCategoryDetail,
  setApplyingFilters,
  setSubscriptionViewInvoiceData,
  setShowCreateSubcategoryModal,
  parentCategortyData,
  childCategoryData,
  setDeleteID,
  setDeleteType,
  setDeleteMessage,
  setShowDeleteModal,
  setUnlinkRelinkModalData,
  setCategoryDeleteData,
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState(0);
  const defineds = useDateDefineds();
  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-2">
        <div className="f-500 f-grey pl-2">All spend</div>
        <div className="d-flex align-items-center">
          <SearchFilterComponent
            visibilityType={VisibilityType.LIST}
            showLastButton={true}
            searchValue={spendSearch}
            searchOnChange={e => {
              setSpendSearch(e.target.value);
            }}
            passedRef={spendSearchRef}
            setRef={() => {
              spendSearchRef.current.focus()
            }}
            searchOnCross={() => {
              setSpendSearch("");
            }}
            onSortClick={() => {
              setOrder(order === 0 ? 1 : 0);
            }}
            childFilterItem={(
              invoiceFilterData
                ?.filter((item) => 
                  (categoryPageData?.SubCategory_List?.length > 0
                    ? []
                    : ["Subcategory"]
                  ).indexOf(item?.filterName) === -1)
                .map((filterItem, filterItemIndex) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between p-2 bg-hover-grey pointer"
                      onClick={(e) => {
                        let updated_active_filters = [...activeFilters];
                        let indexOfFilter = updated_active_filters.findIndex(
                          (item) =>
                            item?.filterType ===
                            filterItem?.filterData?.filterType
                        );
                        if (indexOfFilter === -1) {
                          setActiveFilters([
                            ...updated_active_filters,
                            { ...filterItem?.filterData },
                          ]);
                        }
                        try {
                          setTimeout(() => {
                            let filter_Component =
                              document.querySelector(".filter-box-X");
                            if (filter_Component) {
                              let dropdown = filter_Component.querySelector(
                                `#${filterItem?.filterData?.filterType}_filterX`
                              );

                              if (dropdown) {
                                dropdown
                                  .querySelector(".dropdown-toggle")
                                  .click();
                              }
                            }
                          }, 100);
                        } catch (err) {}
                      }}
                    >
                      <div className="f-500 f-grey">
                        <img
                          src={filterItem.filterItemIcon}
                          alt=""
                          className="mr-2"
                        />
                        {filterItem.filterName}
                      </div>
                      <div></div>
                    </div>
                  );
                })
            )}
            lastButtonChild={(
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_TechnologySpend")
                    ? ["hover", "focus"]
                    : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <TechspendAddDropdown 
                  goTo={goTo} 
                  Pages={Pages} 
                  setAddSpendSelectedTab={setAddSpendSelectedTab}
                  setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                  getInvoiceDetails={getInvoiceDetails}
                  setLoading={setLoading}
                  setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                  defaultAction={TechspendAddMenuAction.AddInvoice}
                  setInvoicePageData={setInvoiceEditMode}
                  categoryPageData={categoryPageData}
                  parentCategortyData={parentCategortyData}
                  childCategoryData={childCategoryData}
                />
              </OverlayTrigger>
            )}
          />
        </div>
      </div>
      <div className="my-2">
        <TechnologySpendFilter
          subCategoryList={categoryPageData?.SubCategory_List ?? []}
          hideFilters={
            categoryPageData?.SubCategory_List?.length > 0
              ? []
              : ["Subcategory"]
          }
          applyingFilters={applyingFilters}
          invoiceFilterSearch={invoiceFilterSearch}
          setInvoiceFilterSearch={(value) => {
            // setInvoiceFilterSearch(value);
          }}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          onClickAdd={() => {
           
          }}
          onApply={(_filters) => {
            const filterData = getFilters(_filters);
             
            if (filterData || true) {
              getCategoryDetail(categoryPageData.ID, filterData);
              setApplyingFilters(true);
              if (
                activeFilters.filter(
                  (item) => item?.filterType === "date"
                ).length > 0
              ) {
                dispatch(
                  mspClientDashboardDatePickerState({
                    startDate: moment(
                      filterData?.fromDate
                        ? filterData?.fromDate
                        : defineds.startOfLastTwelveMonth,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    endDate: moment(
                      filterData?.toDate
                        ? filterData?.toDate
                        : defineds.endOfToday,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    key: "selection",
                  })
                );
              } else {
                dispatch(
                  mspClientDashboardDatePickerState({
                    startDate: defineds.startOfLastTwelveMonth,
                    endDate: defineds.endOfToday,
                    key: "selection",
                  })
                );
              }
            }
          }}
          onReset={() => {
            // getCategoryDetail(categoryPageData.ID);
            dispatch(
              mspClientDashboardDatePickerState({
                startDate: defineds.startOfLastTwelveMonth,
                endDate: defineds.endOfToday,
                key: "selection",
              })
            );
          }}
        />
      </div>
      {applyingFilters ? (
        <div className="d-flex align-items-center justify-content-center w-100 mt-5 mb-5">
          <GreyLoader width={18} />
        </div>
      ) : (
        <div>
          {categoryPageData?.InvoiceItemVM_List
          ?.sort((a, b) => {
            if (order === 0) {
              return new Date(a?.InvoiceDate) < new Date(b?.InvoiceDate) ? 1 : -1;
            } else {
              return new Date(a?.InvoiceDate) > new Date(b?.InvoiceDate) ? 1 : -1;
            }
          })
          ?.map((invoice, invoiceIndex) => {
            const invoices = invoice?.InvoiceItem_List?.filter((item) =>
              item?.Title?.includes(invoiceFilterSearch)
            )
              ?.filter(
                (_item) => _item?.Title?.toLowerCase().includes(spendSearch.toLowerCase())
              );
            if (invoices.length === 0) return null;
            return (
              <div>
                <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                  {invoice?.GroupInvoiceDate}
                </div>
                {invoices
                  ?.map((invoiceItem) => {
                    return (
                      <InvoiceItem 
                        Pages={Pages}
                        categoryPageData={categoryPageData}
                        getInvoiceDetails={getInvoiceDetails}
                        goTo={goTo}
                        highlightedInvoice={highlightedInvoice}
                        invoiceIndex={invoiceIndex}
                        invoiceItem={invoiceItem}
                        setAddSpendSelectedTab={setAddSpendSelectedTab}
                        setInvoiceEditMode={setInvoiceEditMode}
                        setLoading={setLoading}
                        spendSearch={spendSearch}
                        setDeleteID={setDeleteID}
                        setDeleteMessage={setDeleteMessage}
                        setDeleteType={setDeleteType}
                        setShowDeleteModal={setShowDeleteModal}
                        setUnlinkRelinkModalData={setUnlinkRelinkModalData}
                        setCategoryDeleteData={setCategoryDeleteData}
                        relatedToCategory={(ObjId_SubCategory) => {
                          return categoryPageData?.SubCategory_List?.find(
                            (item) => item?.objId_Category === ObjId_SubCategory
                          ) || categoryPageData?.ID === ObjId_SubCategory;
                        }}
                      />
                    );
                  })}
              </div>
            );
          })}
          {categoryPageData?.InvoiceItemVM_List?.length === 0 &&
            invoiceFilterSearch !== "" && (
              <NoSpendFoundUI />
            )}
          {categoryPageData?.InvoiceItemVM_List?.length === 0 &&
            invoiceFilterSearch === "" && (
              <>
                {" "}
                {categoryPageData?.SyncStatus?.isFirstRun === true && categoryPageData?.SyncStatus?.IsSyncing === true ? (
                  <AutomaticSpendSyncingUI />
                ) : (
                  <AutomaticNoSpendFoundUI />
                )}
              </>
            )}
        </div>
      )}
    </>
  )
}

export default AllSpendTab;

const InvoiceItem = ({
  invoiceItem,
  invoiceIndex,
  spendSearch,
  setInvoiceEditMode,
  setAddSpendSelectedTab,
  goTo,
  getInvoiceDetails,
  setLoading,
  Pages,
  highlightedInvoice,
  categoryPageData,
  setDeleteID = () => {},
  setDeleteType = () => {},
  setDeleteMessage = () => {},
  setShowDeleteModal = () => {},
  setUnlinkRelinkModalData = () => {},
  setCategoryDeleteData = () => {},
  relatedToCategory = () => {},
}) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const disableMenu = viewOnlyAccess(
    "AccessState_TechnologySpend"
  );
  const isEditable = invoiceItem?.Editable;
  const menuList = isEditable ? EditableMenuItems : NotEditableMenuItems;
  const isRelatedToCategory = relatedToCategory(invoiceItem?.objId_SpendSubCategory);

  const handleAction = (action) => {
    switch (action) {
      case MenuActions.Edit:
      case MenuActions.View:
        onEditClick();
        break;
      case MenuActions.Delete:
        setDeleteID(invoiceItem?.Id);
        setDeleteType("deleteInvoice");
        setDeleteMessage(
          "Are you sure you want to delete this Invoice?"
        );
        setCategoryDeleteData({
          id: invoiceItem?.ID,
          invoiceName: invoiceItem?.InvoiceRef,
        });
        setShowDeleteModal(true);
        break;
      case MenuActions.Unlink:
        setUnlinkRelinkModalData((prev) => ({
          ...prev,
          show: true,
          linkButtonText: "Unlink",
          headerText: `${`Unlink ${
            invoiceItem.InvoiceRef
          } from ${
            all_integration_data[invoiceItem?.objId_Integration]
          }`}`,
          bodyText: `${`Are you sure you would like to unlink this invoice ${
            invoiceItem.InvoiceRef
          } from ${
            all_integration_data[invoiceItem?.objId_Integration]
          }?`}`,
          deleteParams: {
            linkStatus: false,
            objId_invoice: invoiceItem?.objId_Invoice,
          },
        }));
        break;
      default:
        break;
    }
  }

  const onEditClick = () => {
    if (
      !viewOnlyAccess(
        "AccessState_TechnologySpend"
      )
    ) {
      setLoading(true);
      setInvoiceEditMode(
        invoiceItem?.Editable
      );
      if (
        invoiceItem?.IsDirectAmount
      ) {
        setAddSpendSelectedTab(
          "addByAmount"
        );
      } else {
        setAddSpendSelectedTab(
          "addByInvoice"
        );
      }
      goTo(
        <div className="d-flex align-items-center justify-content-center">
          <div className="position-relative w-fit-content h-fit-content">
            <PaymentIconComponent payment={invoiceItem} />
          </div>
          {TrimString(
            invoiceItem?.Title,
            30
          )}
        </div>,
        Pages.ADD_SPEND,
        {
          invoiceId: invoiceItem?.Id,
          currentTab: invoiceItem?.IsDirectAmount ? "addByAmount" : "addByInvoice",
        }
      );
      getInvoiceDetails(
        invoiceItem?.objId_Invoice,
        categoryPageData?.ID,
        { isSubscriptionView: false }
      );
    }
  }

  return (
    <div
      className={`invoice-row p-2 bg-hover-grey radius-4 parent-visible ${
        viewOnlyAccess(
          "AccessState_TechnologySpend"
        )
          ? "cursor-default"
          : "pointer"
      } `}
      onClick={() => {
        onEditClick()
      }}
    >
      <div className="d-flex align-items-center">
        <div className="position-relative w-fit-content h-fit-content">
          <PaymentIconComponent payment={invoiceItem} />
        </div>
        <div className="d-flex flex-column">
          <span className="f-14 lin-height-22 f-black">
            {highlightedInvoice(
              invoiceItem?.Title,
              spendSearch
            )}
          </span>
          {invoiceItem?.SpendSubCategory_Name ? (
            <span className={`f-12 line-height-18 ${isRelatedToCategory ? 'f-blue' : 'f-grey'}`}>
              {invoiceItem?.SpendSubCategory_Name}
            </span>
          ) : (
            <span className={`f-12 line-height-18 f-darkgrey`}>
              No subcategory
            </span>
          )}
        </div>
      </div>
      <div>
        {invoiceItem?.subCategory ? (
          <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
            <div
              className="rounded-full mr-2"
              style={{
                width: "9px",
                height: "9px",
                background:
                  invoiceItem?.subCategory
                    ?.color,
              }}
            />
            {
              invoiceItem?.subCategory
                ?.name
            }
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="f-black f-500 d-flex align-items-center justify-content-end" style={{ gap: '8px' }}>
        <AttackSurfaceTooltip
          bottomLeft={
            invoiceIndex <
            categoryPageData
              ?.InvoiceItemVM_List
              ?.length -
              1
          }
          topLeft={
            invoiceIndex ===
            categoryPageData
              ?.InvoiceItemVM_List
              ?.length -
              1
          }
          content={
            categoryPageData?.TotalSpendInvoiceToolTip
          }
        >
          <span
            className="f-black"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {invoiceItem?.TotalItemValue_Text?.replaceAll(
              " ",
              ""
            )}
          </span>
        </AttackSurfaceTooltip>
        <div 
          onClick={e => {
            e.stopPropagation();
          }}
          style={{
            opacity: disableMenu ? 0 : 1
          }}
        >
          <Dropdown 
            show={isOpen} 
            className="transparennt-wrapper-dropdown my-dropdown"
            onToggle={(isOpen) => {
              if (disableMenu) return;
              setIsOpen(isOpen);
            }}
          >
            <Dropdown.Toggle ref={refBtn} className="child-visible">
              <HorizontalDotsIcon fill="#616778" width="13" height="4" />
            </Dropdown.Toggle>
            <Dropdown.Menu ref={refMenu} className="menu subcategory-item-menu" style={{ padding: '4px 0' }}>
              <div>
                {menuList.map((item) => (
                  <div 
                    key={`subitem-${item.label}`} 
                    className="sub-item f-14 line-height-22 f-grey bg-hover-grey"
                    onClick={() => handleAction(item.action)}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react";
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import SwitchButtonMulti from "../../Theme/switchButtonMulti";
import { VisibilityType } from "../../../constants/filter.constants";
import { HGHighlightText, getAccessStateValue, viewOnlyAccess } from "../../App/App";
import { GreyLoader } from "../../Theme/APILoaders";
import { TrimString } from "../../../Utils/StringTrimmer";
import { Button, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PackageListRadarUI } from "./MspClientPriceCalculator";
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";
import { ROUTE_PATHS } from "../../../constants/app.constants";


export const PackagesListBlock = ({
    data = [],
    SearchInput,
    ownClientID,
    packageOrder,
    savingAssets,
    searchString,
    openedPackages,
    showUptoCustom,
    isMspClientView,
    setSearchString,
    packagesLoading,
    switchIncludedTaxVal,
    priceCalculatorState,
    showRightSide = false,
    activeOrInactiveLoading,
    packageTypeIsStandard = true,
    closeScenario = () => { },
    setClickedItem = () => { },
    getFeaturesData = () => { },
    setPackageOrder = () => { },
    setShowUptoCustom = () => { },
    setOpenedPackages = () => { },
    getPackageDetails = () => { },
    checkPackageIfInUse = () => { },
    setIsActiveOrInactive = () => { },
    _setOpenedPackagesIds = () => { },
    getSecurityCapabilities = () => { },
    setSwitchIncludedTaxVal = () => { },
    setShowUpdateCyberKPIModal = () => { },
    setMapCurrentSpendPackageId = () => { },
    setShowMapCurrentSpendModal = () => { },
    setActiveOrInactivePackageId = () => { },
    setMapCurrentSpendPackagePrice = () => { },
    setShowCreateCustomPackageModal = () => { },
    setMapCurrentSpendPackagePeriod = () => { },
    setMapCurrentSpendPackagePriceText = () => { },
    setPriceCalculatorState = () => { },
}) => {

    const clientId = window.location.pathname.split("/")?.[4] ?? -1;
    const history = useHistory();
    const isMobile = useRespondTo(WindowBreakpoints.md);

    return (
        <div>
            <div className="packages-list-header-block mt-4 mb-2">
                <div className="f-500 f-14 f-black">{packageTypeIsStandard ? 'MSPs Standard' : 'Custom'} Packages</div>
                {
                    showRightSide ?
                        <SearchFilterComponent
                            leftSide={(
                                <div className="d-flex align-items-center mt-1">
                                    <div className="f-400 f-14 f-grey mr-3">
                                        Inc. taxes
                                    </div>
                                    <SwitchButtonMulti
                                        id={`switch_toggle_button`}
                                        label={""}
                                        value={switchIncludedTaxVal}
                                        onChange={(e) => {
                                            setSwitchIncludedTaxVal(!switchIncludedTaxVal);
                                        }}
                                    />
                                </div>
                            )}
                            passedMainStyles={'clients-header'}
                            searchValue={searchString}
                            searchOnChange={(e) => {
                                setSearchString(e.target.value);
                            }}
                            searchOnCross={() => {
                                setSearchString("");
                            }}
                            passedRef={SearchInput}
                            setRef={() => {
                                SearchInput.current.focus()
                            }}
                            onSortClick={() => {
                                setPackageOrder(prev => prev === 0 ? 1 : 0)
                            }}
                            visibilityType={VisibilityType.LIST}
                            showLastButton={isMobile ? false : true}
                            isLastButtonDisabled={getAccessStateValue("AccessState_ManagedSecurity") !== 99}
                            lastButtonLabel='Add'
                            lastButtonOnClick={() => {
                                if (Number(ownClientID) != Number(clientId)) {
                                    if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                        setShowCreateCustomPackageModal(true);
                                    }
                                } else {
                                    setShowCreateCustomPackageModal(true);
                                }
                            }}
                        /> : <></>
                }
            </div>
            {
                packagesLoading ? <div className="w-100 d-flex align-items-center justify-content-center my-4">
                    <GreyLoader />
                    <span className="text3">Loading...</span>
                </div> :
                    <div className="bg-grey radius-8 border-parent">
                        {data?.filter(
                            (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
                        ).filter(
                            (item) => item.IsPackageActive === false
                        ).length > 0 && (
                                data?.filter(
                                    (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
                                ).filter(
                                    (item) => item.IsPackageActive === false
                                )
                                    ?.filter((item) =>
                                        item.Name.toLowerCase().includes(searchString.toLowerCase())
                                    )
                                    ?.sort((a, b) => {
                                        if (packageOrder === 0) {
                                            return a.Name.localeCompare(b.Name);
                                        } else {
                                            return b.Name.localeCompare(a.Name);
                                        }
                                    })
                                    ?.slice(0, showUptoCustom)
                                    .map((_package, _packageIndex) => {
                                        const isPackageOpen = openedPackages.includes(
                                            _package.objId_Scenario
                                        );

                                        return (
                                            <div
                                                className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
                      ${Number(ownClientID) == Number(clientId) ? 'pointer' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
                    `}
                                                style={{
                                                    minHeight: '106px',
                                                    maxHeight: '106px'
                                                }}
                                            >
                                                {/* Radar Chart */}
                                                <div className="d-flex align-items-center w-100"
                                                    onClick={(e) => {
                                                        if (getAccessStateValue("AccessState_ManagedSecurity") == 99) {
                                                            if (Number(ownClientID) != Number(clientId)) {
                                                                if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                                                    if (openedPackages.length > 0) {
                                                                        const updatedData = isPackageOpen
                                                                            ? openedPackages.filter(
                                                                                (item) => item !== _package.objId_Scenario
                                                                            )
                                                                            : [...openedPackages, _package.objId_Scenario];

                                                                        setOpenedPackages(updatedData);
                                                                        _setOpenedPackagesIds(updatedData);

                                                                        if (isPackageOpen) {
                                                                            closeScenario(_package.objId_Scenario);
                                                                        } else {
                                                                            getPackageDetails(_package.objId_Scenario);
                                                                            getFeaturesData(_package.objId_Scenario);
                                                                            getSecurityCapabilities(_package.objId_Scenario);
                                                                        }
                                                                    } else {
                                                                        setOpenedPackages([_package.objId_Scenario]);
                                                                        getPackageDetails(_package.objId_Scenario);
                                                                        getFeaturesData(_package.objId_Scenario);
                                                                        getSecurityCapabilities(_package.objId_Scenario);
                                                                    }
                                                                    setClickedItem(true);
                                                                }
                                                            } else {
                                                                if (openedPackages.length > 0) {
                                                                    const updatedData = isPackageOpen
                                                                        ? openedPackages.filter(
                                                                            (item) => item !== _package.objId_Scenario
                                                                        )
                                                                        : [...openedPackages, _package.objId_Scenario];

                                                                    setOpenedPackages(updatedData);
                                                                    _setOpenedPackagesIds(updatedData);

                                                                    if (isPackageOpen) {
                                                                        closeScenario(_package.objId_Scenario);
                                                                    } else {
                                                                        getPackageDetails(_package.objId_Scenario);
                                                                        getFeaturesData(_package.objId_Scenario);
                                                                        getSecurityCapabilities(_package.objId_Scenario);
                                                                    }
                                                                } else {
                                                                    setOpenedPackages([_package.objId_Scenario]);
                                                                    getPackageDetails(_package.objId_Scenario);
                                                                    getFeaturesData(_package.objId_Scenario);
                                                                    getSecurityCapabilities(_package.objId_Scenario);
                                                                }
                                                                setClickedItem(true);
                                                            }

                                                            localStorage.removeItem("activePackageName");
                                                            localStorage.setItem("activePackageName", _package?.Name);
                                                            let updated_data = { ...priceCalculatorState };
                                                            updated_data.activeTab = priceCalculatorState?.tabsData?.length;
                                                            updated_data.activeChart = priceCalculatorState?.tabsData?.length;
                                                            updated_data.mergedStatusArray = updated_data.mergedStatusArray.map((value, index) => false);
                                                            // updated_data.newScenarioCreating = true;
                                                            updated_data.loadingScenarioID = _package.objId_Scenario;
                                                            updated_data.newScenarioCreating = priceCalculatorState?.tabsData?.find(
                                                                (obj) => obj?.ScenarioHeader?.objId_Scenario === _package.objId_Scenario
                                                            )
                                                                ? false
                                                                : true;
                                                            updated_data.scenarioLoadingText = "Loading...";

                                                            console.log('updated_data - ', updated_data)
                                                            setPriceCalculatorState(updated_data);
                                                        }
                                                    }}
                                                >
                                                    {
                                                        !isMobile &&
                                                        <div className="mr-2">
                                                            <PackageListRadarUI
                                                                dataList={[
                                                                    _package?.InspectScore,
                                                                    _package?.ProtectScore,
                                                                    _package?.DetectScore,
                                                                    _package?.RespondScore,
                                                                    _package?.RecoverScore,
                                                                ]}
                                                                colorPassed={priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario)?.circleColour ?? '#9499AA'}
                                                            />
                                                        </div>
                                                    }
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="subclass d-flex align-items-center">
                                                                    {" "}
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: HGHighlightText(
                                                                                _package?.Name,
                                                                                searchString
                                                                            ),
                                                                        }}
                                                                    /> {
                                                                        activeOrInactiveLoading == _package?.objId_Scenario ? <div className="msp-service-update-loader ml-2 mr-1"></div> :
                                                                            <span className="f-500 f-12 txt-blue ml-2">
                                                                                {_package?.IsPackageActive && 'Active'}
                                                                            </span>
                                                                    }
                                                                </div>
                                                                <div className="f-12 f-darkgrey">
                                                                    {TrimString(_package?.Description, (isMobile ? 25 : 50)) ?? ""}
                                                                </div>
                                                                {
                                                                    _package?.MappedPrice > 0 && _package?.Contribution > 0 ?
                                                                        <div className={`d-flex align-items-center ${isMobile && 'f-12'}`}>
                                                                            <div className="d-flex align-items-center f-darkgrey line-through-text mr-2">
                                                                                {
                                                                                    switchIncludedTaxVal ? _package?.MonthlyPrice_Text?.split(' ')[0] : _package?.MonthlyPriceWithoutTax_Text?.split(' ')[0]
                                                                                }
                                                                            </div>
                                                                            <div className="d-flex align-items-center txt-blue">
                                                                                {
                                                                                    `${_package?.MonthlyPrice_Text?.match(/[^\d.,/\s]+/)[0]}${switchIncludedTaxVal ?
                                                                                        (_package?.MonthlyPrice - _package?.Contribution)?.toFixed(2) :
                                                                                        (parseFloat(_package?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')) - _package?.Contribution)?.toFixed(2)} /${_package?.BillingPeriod_Text}`
                                                                                }
                                                                            </div>
                                                                            {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                                                        </div>
                                                                        :
                                                                        <div className={`d-flex align-items-center f-darkgrey ${isMobile && 'f-12'}`}>
                                                                            {
                                                                                switchIncludedTaxVal ? _package?.MonthlyPrice_Text : _package?.MonthlyPriceWithoutTax_Text
                                                                            }
                                                                            {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="dots-menu-block">
                                                    <div>
                                                        <div className="d-flex justify-content-end align-content-end" style={{
                                                            marginBottom: '30px'
                                                        }}>
                                                            <Dropdown className="transparennt-wrapper-dropdown-menu">
                                                                <Dropdown.Toggle>
                                                                    <img
                                                                        alt=""
                                                                        className="pointer mx-2"
                                                                        src="/images/settings-v2/horizontal-dots.svg"
                                                                    />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="p-0">
                                                                    <div
                                                                        className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                                        onClick={() => {
                                                                            history.push(ROUTE_PATHS.mspv2.priceCalculator(_package.objId_Scenario), {
                                                                                backUrl: ROUTE_PATHS.mspv2.clients.securityPackage(clientId),
                                                                                // allPackagesList: pageData?.ScenarioHheaderList,
                                                                                clientId: clientId,
                                                                            });
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </div>
                                                                    <div
                                                                        className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                                        onClick={(e) => {
                                                                            if (_package?.IsPackageActive == false) {
                                                                                setActiveOrInactivePackageId(_package.objId_Scenario);
                                                                                setShowUpdateCyberKPIModal(true);
                                                                            } else {
                                                                                setIsActiveOrInactive(_package.objId_Scenario, 0);
                                                                            }
                                                                            e.target
                                                                                .closest(".transparennt-wrapper-dropdown-menu")
                                                                                .click();
                                                                        }}
                                                                    >
                                                                        Set as {_package.IsPackageActive ? 'Inactive' : 'Active'}
                                                                    </div>
                                                                    <div
                                                                        className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                                        onClick={(e) => {
                                                                            setMapCurrentSpendPackageId(_package?.objId_Scenario);
                                                                            setMapCurrentSpendPackagePeriod(_package?.PackageBillingPeriod_Text)
                                                                            setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? _package?.MonthlyPrice_Text : _package?.MonthlyPriceWithoutTax_Text);
                                                                            setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? _package?.MonthlyPrice : parseFloat(_package?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')));
                                                                            setShowMapCurrentSpendModal(true);
                                                                            const drag = document?.getElementById("map-current-spend-modal");
                                                                            drag.style.display = "block";
                                                                            e.target
                                                                                .closest(".transparennt-wrapper-dropdown-menu")
                                                                                .click();
                                                                        }}
                                                                    >
                                                                        Add Contribution
                                                                    </div>
                                                                    <div className="hg-border-top mx-3"></div>
                                                                    <div
                                                                        className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                                        onClick={(e) => {
                                                                            checkPackageIfInUse(_package?.objId_Scenario);
                                                                            e.target
                                                                                .closest(".transparennt-wrapper-dropdown-menu")
                                                                                .click();
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div
                                                            className=""
                                                            style={{
                                                                // height: '100%',
                                                                // marginTop: '20px'
                                                            }}>
                                                            {
                                                                priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario) ? <div className="d-flex justify-content-end align-content-end align-items-center">
                                                                    <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                                                                    <div
                                                                        className="rounded-full ml-2 f-400 f-12"
                                                                        style={{
                                                                            width: "12px",
                                                                            height: "12px",
                                                                            backgroundColor: priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario)?.circleColour,
                                                                            color: "#9499AA"
                                                                        }}
                                                                    />
                                                                </div> :
                                                                    <div style={{
                                                                        height: '20px'
                                                                    }}></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                            )}
                    </div>
            }

            {data?.filter(
                (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
            )?.length > 0 &&
                data?.filter(
                    (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
                )?.filter((item) =>
                    item.Name.toLowerCase().includes(searchString.toLowerCase())
                )?.length === 0 &&
                searchString !== "" && (
                    <div className="f-grey f-500 w-100 text-center mt-5">
                        No results found!
                    </div>
                )}

            {data?.filter(
                (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
            )?.length <= 0 && (
                    <div className="f-grey f-500 w-100 text-center mt-5">
                        No data yet
                    </div>
                )}

            {data?.filter(
                (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
            )?.length > 0 &&
                data?.filter(
                    (item) => packageTypeIsStandard ? item.IsCustomPackage === false : item.IsCustomPackage === true
                )?.filter((item) =>
                    item.Name.toLowerCase().includes(searchString.toLowerCase())
                )?.length > 5 && (
                    <>
                        <Button
                            className="hg-cancel-btn mt-3"
                            onClick={() => {
                                if (showUptoCustom === 5) {
                                    setShowUptoCustom(10000000);
                                } else {
                                    setShowUptoCustom(5);
                                }
                            }}
                        >
                            {showUptoCustom === 5 ? "Show more" : "Show less"}
                        </Button>
                    </>
                )}
        </div>
    );
};

export default PackagesListBlock;

import React from "react";
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";
import MspClientPriceCalculatorRadarChartMerge from "./MspClientPriceCalculatorRadarChartMerge";
import { MspClientPriceCalculatorEmptyRadarChart } from "../mspSell/mspPriceCalculator/TemplatesModal/PackageView";
import { services_enums_selected } from "../mspSell/mspPriceCalculator";
import CyberComplianceGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import CyberScoreGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import PriceCalculatorPosture from "./PriceCalculatorPosture";
import PriceCalculatorDashBoard from "./PriceCalculatorDashBoard";
import PackagesDetailsBlock from "./PackagesDetailsBlock";

export const CalculationBlock = ({
    pageData,
    inUseData,
    SearchInput,
    ownClientID,
    showInfoModal,
    packagesLoading,
    activePackageId,
    isMspClientView,
    deleteModalConfig,
    activePackageFound,
    _openedPackagesIds,
    switchIncludedTaxVal,
    priceCalculatorState,
    showUpdateCyberKPIModal,
    activeOrInactiveLoading,
    defaultUserDeviceDetails,
    activeOrInactivePackageId,
    defaultUserDeviceDetailsStartData,
    featuresData,
    packageDetails,
    componentsData,
    featuresLoading,
    securityCapabilitiesData,
    securityCapabilitiesLoading,
    refreshData = () => { },
    getPageData = () => { },
    closeScenario = () => { },
    updateCyberKPI = () => { },
    setClickedItem = () => { },
    getFeaturesData = () => { },
    setShowInfoModal = () => { },
    getPackageDetails = () => { },
    getPackagesPageData = () => { },
    checkPackageIfInUse = () => { },
    setActivePackageFound = () => { },
    _setOpenedPackagesIds = () => { },
    setIsActiveOrInactive = () => { },
    updateQuantityOfAssets = () => { },
    setSwitchIncludedTaxVal = () => { },
    getSecurityCapabilities = () => { },
    setShowUpdateCyberKPIModal = () => { },
    setShowMapCurrentSpendModal = () => { },
    setMapCurrentSpendPackageId = () => { },
    setActiveOrInactivePackageId = () => { },
    setMapCurrentSpendPackagePrice = () => { },
    setShowCreateCustomPackageModal = () => { },
    setMapCurrentSpendPackagePeriod = () => { },
    setMapCurrentSpendPackagePriceText = () => { },
    discount,
    switchVal,
    contribLoading,
    showContribReset,
    contributionValue,
    updatePackageMappedPrice = () => { },
    setDiscount = () => { },
    setContribLoading = () => { },
    setShowContribReset = () => { },
    resetAssetSeatDataApi = () => { },
    updateSeatAssetDataApi = () => { },
    setIsFromAddContribTable = () => { },
    setIsSmthOverriden = () => { },
    setPriceCalculatorState = () => { },
}) => {
    const isMobile = useRespondTo(WindowBreakpoints.md);

    return (
        <div
            className="calculation-block hide-scrollbar">
            <div>
                {
                    priceCalculatorState?.newScenarioCreating ? <PriceCalculatorDashBoard
                        pageData={pageData}
                        inUseData={inUseData}
                        SearchInput={SearchInput}
                        ownClientID={ownClientID}
                        packagesLoading={packagesLoading}
                        activePackageId={activePackageId}
                        isMspClientView={isMspClientView}
                        deleteModalConfig={deleteModalConfig}
                        activePackageFound={activePackageFound}
                        _openedPackagesIds={_openedPackagesIds}
                        switchIncludedTaxVal={switchIncludedTaxVal}
                        priceCalculatorState={priceCalculatorState}
                        showUpdateCyberKPIModal={showUpdateCyberKPIModal}
                        activeOrInactiveLoading={activeOrInactiveLoading}
                        defaultUserDeviceDetails={defaultUserDeviceDetails}
                        activeOrInactivePackageId={activeOrInactivePackageId}
                        defaultUserDeviceDetailsStartData={defaultUserDeviceDetailsStartData}
                        refreshData={refreshData}
                        getPageData={getPageData}
                        closeScenario={closeScenario}
                        showInfoModal={showInfoModal}
                        updateCyberKPI={updateCyberKPI}
                        setClickedItem={setClickedItem}
                        getFeaturesData={getFeaturesData}
                        setShowInfoModal={setShowInfoModal}
                        getPackageDetails={getPackageDetails}
                        getPackagesPageData={getPackagesPageData}
                        checkPackageIfInUse={checkPackageIfInUse}
                        setActivePackageFound={setActivePackageFound}
                        _setOpenedPackagesIds={_setOpenedPackagesIds}
                        setIsActiveOrInactive={setIsActiveOrInactive}
                        updateQuantityOfAssets={updateQuantityOfAssets}
                        setSwitchIncludedTaxVal={setSwitchIncludedTaxVal}
                        getSecurityCapabilities={getSecurityCapabilities}
                        setShowUpdateCyberKPIModal={setShowUpdateCyberKPIModal}
                        setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                        setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                        setActiveOrInactivePackageId={setActiveOrInactivePackageId}
                        setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                        setShowCreateCustomPackageModal={setShowCreateCustomPackageModal}
                        setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                        setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                        setPriceCalculatorState={setPriceCalculatorState}
                    /> : <>
                        {priceCalculatorState?.tabsData?.map((tab, key) => {
                            return (
                                <div eventKey={key} key={key}>
                                    {
                                        priceCalculatorState?.activeTab == key && <>
                                            {key === 0 ? (
                                                <PriceCalculatorDashBoard
                                                    pageData={pageData}
                                                    inUseData={inUseData}
                                                    SearchInput={SearchInput}
                                                    ownClientID={ownClientID}
                                                    packagesLoading={packagesLoading}
                                                    activePackageId={activePackageId}
                                                    isMspClientView={isMspClientView}
                                                    deleteModalConfig={deleteModalConfig}
                                                    activePackageFound={activePackageFound}
                                                    _openedPackagesIds={_openedPackagesIds}
                                                    switchIncludedTaxVal={switchIncludedTaxVal}
                                                    priceCalculatorState={priceCalculatorState}
                                                    showUpdateCyberKPIModal={showUpdateCyberKPIModal}
                                                    activeOrInactiveLoading={activeOrInactiveLoading}
                                                    defaultUserDeviceDetails={defaultUserDeviceDetails}
                                                    activeOrInactivePackageId={activeOrInactivePackageId}
                                                    defaultUserDeviceDetailsStartData={defaultUserDeviceDetailsStartData}
                                                    refreshData={refreshData}
                                                    getPageData={getPageData}
                                                    closeScenario={closeScenario}
                                                    showInfoModal={showInfoModal}
                                                    updateCyberKPI={updateCyberKPI}
                                                    setClickedItem={setClickedItem}
                                                    getFeaturesData={getFeaturesData}
                                                    setShowInfoModal={setShowInfoModal}
                                                    getPackageDetails={getPackageDetails}
                                                    getPackagesPageData={getPackagesPageData}
                                                    checkPackageIfInUse={checkPackageIfInUse}
                                                    setActivePackageFound={setActivePackageFound}
                                                    _setOpenedPackagesIds={_setOpenedPackagesIds}
                                                    setIsActiveOrInactive={setIsActiveOrInactive}
                                                    updateQuantityOfAssets={updateQuantityOfAssets}
                                                    setSwitchIncludedTaxVal={setSwitchIncludedTaxVal}
                                                    getSecurityCapabilities={getSecurityCapabilities}
                                                    setShowUpdateCyberKPIModal={setShowUpdateCyberKPIModal}
                                                    setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                                                    setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                                                    setActiveOrInactivePackageId={setActiveOrInactivePackageId}
                                                    setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                                                    setShowCreateCustomPackageModal={setShowCreateCustomPackageModal}
                                                    setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                                                    setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                                                    setPriceCalculatorState={setPriceCalculatorState}
                                                />
                                            ) : <></>}
                                            {key > 0 ? (
                                                <PackagesDetailsBlock
                                                    packageName={tab.Name}
                                                    _packageData={priceCalculatorState.tabsData[key]}
                                                    featuresData={featuresData}
                                                    packageDetails={packageDetails}
                                                    componentsData={componentsData}
                                                    featuresLoading={featuresLoading}
                                                    priceCalculatorState={priceCalculatorState}
                                                    securityCapabilitiesData={securityCapabilitiesData}
                                                    securityCapabilitiesLoading={securityCapabilitiesLoading}
                                                    discount={discount}
                                                    switchVal={switchVal}
                                                    contribLoading={contribLoading}
                                                    isMspClientView={isMspClientView}
                                                    showContribReset={showContribReset}
                                                    contributionValue={contributionValue}
                                                    switchIncludedTaxVal={switchIncludedTaxVal}
                                                    updatePackageMappedPrice={updatePackageMappedPrice}
                                                    setDiscount={setDiscount}
                                                    setContribLoading={setContribLoading}
                                                    getPackageDetails={getPackageDetails}
                                                    setShowContribReset={setShowContribReset}
                                                    resetAssetSeatDataApi={resetAssetSeatDataApi}
                                                    updateSeatAssetDataApi={updateSeatAssetDataApi}
                                                    setIsFromAddContribTable={setIsFromAddContribTable}
                                                    setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                                                    setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                                                    setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                                                    setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                                                    setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                                                    setIsSmthOverriden={setIsSmthOverriden}
                                                />
                                            ) : <></>
                                            }
                                        </>
                                    }
                                </div>
                            );
                        })}
                    </>
                }
            </div>
        </div>
    );
};

export default CalculationBlock;

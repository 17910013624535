import { useEffect, useState } from "react";
import useIntegrationActions from "../../../../hooks/actions/integrations.actions";

const useRecurringSpendData = ({ show }) => {
  const [data, setData] = useState([]);
  const [sortVisibilityList, setSortVisibilityList] = useState([]);
  const [isFetchingLoading, setIsFetchingLoading] = useState(true);
  const { getRecurringSpend } = useIntegrationActions();

  const fetchData = async ({ clientId, sortVisibiityList }) => {
    setIsFetchingLoading(true);
    const response = await getRecurringSpend({ clientId, sortVisibiityList });
    if (response) {
      setData(response?.PackageMapCurrentSpend?.PackageMapCurrentSpend_List);
      setSortVisibilityList(response?.MSPClientSortVisibility_List);
    }
    setIsFetchingLoading(false);
  }

  useEffect(() => {
    if (show) return;
    setData([]);
    setIsFetchingLoading(true);
  }, [show]);

  return {
    data,
    sortVisibilityList,
    fetchData,
    setSortVisibilityList,
    isFetchingLoading,
  }
}

export default useRecurringSpendData;

import { DateRangePicker } from 'react-date-range';

const DateRangeFilterType = ({
  item,
  onFilterChange,
  setDateSelectionValue,
  selectedDateFilter,
  defineds,
  staticRanges
}) => {
  return (
    <div className='date-filter-dropdown dashboardDateFilter'>
        <div className='dropdown-menu show menu p-0'>
          <DateRangePicker
            direction="vertical"
            ranges={[selectedDateFilter ?? {}]}
            staticRanges={staticRanges}
            onChange={(ranges) => {
              setDateSelectionValue(ranges.selection);
              onFilterChange(prev => {
                const updatedFilterList = prev.map(f => {
                  if (f.key === item.key) {
                    return {
                      ...f,
                      value: ranges.selection
                    };
                  }
                  return f;
                });
                return updatedFilterList;
              }, {
                ...item,
                value: ranges.selection
              })
            }}
          />
          <div
            className="pl-4 f-12 pointer border-bottom pt-0"
            onClick={() => {
              setDateSelectionValue({
                startDate: defineds.startOfLastTwelveMonth,
                endDate: defineds.endOfToday,
                key: "selection",
              });
              onFilterChange(prev => {
                const updatedFilterList = prev.map(f => {
                  if (f.key === item.key) {
                    return {
                      ...f,
                      value: {
                        startDate: defineds.startOfLastTwelveMonth,
                        endDate: defineds.endOfToday,
                      }
                    };
                  }
                  return f;
                });
                return updatedFilterList;
              }, {
                ...item,
                value: {
                  startDate: defineds.startOfLastTwelveMonth,
                  endDate: defineds.endOfToday,
                }
              });
            }}
            style={{
              padding: "0.7rem",
            }}
          >
            Clear
          </div>
        </div>
      </div>
  )
}

export default DateRangeFilterType;

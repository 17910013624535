export const NoAccessContainer = ({ type }) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ marginTop: "9rem" }}
    >
      <img alt="" src="/images/supply-chain/supply-lock.svg" className="mb-2" />
      <div className="f-500 f-grey mb-2">You dont have access to this data</div>
      <div className="f-12 f-darkgrey">To view {type}, update</div>
      <div className="f-12 f-darkgrey"> your access for this Supplier</div>
    </div>
  );
};

export const AttackSurfaceAccessUI = () => {
  return (
    <div
      className="d-flex align-items-center radius-8 p-3"
      style={{
        background: "#FFF8EE",
      }}
    >
      <img
        alt=""
        src="/images/supply-chain/attack-access.svg"
        className="mr-2"
      />
      <div className="f-black d-flex align-items-center justify-content-center">
        You are viewing this data from the suppliers view, as per your approved
        access level
      </div>
    </div>
  );
};

export async function downloadPDF(_url) {
  try {
    const response = await fetch(_url);
    const data = await response.blob();
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    // a.target = "_blank";
    a.download = "file.pdf";
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error(error);
  }
}

import React, { useEffect } from "react";
import SlidingModal from "../CustomModal/SlidingModal";
import { useState } from "react";
import {
  FrameworksToolTip,
  PackageToolTip,
  QuestionChip,
} from "../MSPv2/mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import Loader from "../Common/loader";
import { Dropdown } from "react-bootstrap";
import { useAuth } from "../context/Auth";
import { getPageDataApi } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { TrimString } from "../../Utils/StringTrimmer";
import { HGHighlightText, viewOnlyAccess } from "../App/App";
import { HGPopUp } from "../AttackSurface/KPIpopup/KPIpopup";
import { renderSelectedItemsInDropdown } from "../../Utils/CommonFunctions";
const ExploreMappingModal = ({ show, hideModal }) => {
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [controlFilterData, setControlFilterData] = useState({
    1: [], // filterType = 1
    2: [], // filterType = 2
  });
  const [frameworksList, setFrameworksList] = useState([]);
  const [frameworkDetails, setFrameworkDetails] = useState({});
  const [sectionDetails, setSectionDetails] = useState({});
  const [controlDetails, setControlDetails] = useState({});
  const [searchDetails, setSearchDetails] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showMoreFrameworkDetails, setshowMoreFrameworkDetails] =
    useState(false);
  const [pages, setPages] = useState([
    {
      type: "frameworks_list",
      name: (
        <div className="d-flex align-items-center mr-2">
          <img
            src="/images/security-assessment/explore-mapping-icon.svg"
            alt=""
            className="mr-2"
          />
          Explore mapping
        </div>
      ),
      data: {},
    },
  ]);
  const Pages = {
    FRAMEWORKS: "frameworks_list",
    FRAMEWORK_DETAILS: "framework_details",
    SECTION_DETAILS: "section_details",
    CONTROL_DETAILS: "explore_mappings",
    SEARCH_DETAILS: "search_details",
  };
  const activePage = pages[pages.length - 1];

  /**
   *
   * @param {"Modal Header Name"} pageName
   * @param {"Type of Page"} type
   * @param {"Data for Page"} data
   *
   * @returns void
   */
  function goTo(pageName, type = "", data = {}) {
    // if (pageName == null || pageName === "") {
    //   console.log("go to page is Null");
    //   return;
    // }
    setPages([
      ...pages,
      {
        name: pageName ?? pages[pages.length - 1].name,
        type: type,
        data: data,
      },
    ]);
  }

  function goBack() {
    let updated_pages = [...pages];
    console.log(updated_pages, "updated_pages");
    if (updated_pages.length > 1) {
      let future_page = updated_pages[updated_pages.length - 2];

      if (future_page.type === Pages.FRAMEWORKS) {
        setLoading(true);
        getFrameworksList();
      }
      if (future_page.type === Pages.FRAMEWORK_DETAILS) {
        setLoading(true);
        getFrameworkDetails({ frameworkId: frameworkDetails.objId_Framework });
      }
      if (future_page.type === Pages.SECTION_DETAILS) {
        setLoading(true);
        getSectionDetails({
          frameworkId: frameworkDetails.objId_Framework,
          sectionId: sectionDetails?.objId_Section,
        });
      }
      if (future_page.type === Pages.CONTROL_DETAILS) {
        setLoading(true);

        getControlDetails({
          frameworkId: frameworkDetails?.objId_Framework,
          controlId: controlDetails?.objId_Control,
        });
      }
      if (future_page.type === Pages.SEARCH_DETAILS) {
        setLoading(true);
        goBackFromSearchPage();

        return;
      }

      updated_pages.pop();
      setPages(updated_pages);
    } else {
      hideModal();
    }
  }

  function goBackFromSearchPage() {
    let updated_p = [...pages];
    updated_p.pop();
    let updated_data = updated_p.filter(
      (item) => item.type !== Pages.SEARCH_DETAILS
    );
    let last_page = updated_data[updated_data.length - 1];
    if (last_page.type === Pages.FRAMEWORKS) {
      getFrameworksList();
    }
    if (last_page.type === Pages.FRAMEWORK_DETAILS) {
      getFrameworkDetails({ frameworkId: frameworkDetails.objId_Framework });
    }
    if (last_page.type === Pages.SECTION_DETAILS) {
      getSectionDetails({
        frameworkId: frameworkDetails.objId_Framework,
        sectionId: sectionDetails?.objId_Section,
      });
    }
    if (last_page.type === Pages.CONTROL_DETAILS) {
      getControlDetails({
        frameworkId: frameworkDetails?.objId_Framework,
        controlId: controlDetails?.objId_Control,
      });
    }

    setPages(updated_data);
  }

  function getFrameworksList() {
    getPageDataApi("SecurityFramework/GetFrameworks", authTokens)
      .then((response) => {
        setLoading(false);
        setSearchLoading(false);
        if (response?.mr?.Success) {
          setFrameworksList(response?.detail);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  function getFrameworkDetails(kwargs = {}) {
    getPageDataApi("SecurityFramework/GetFramework", authTokens, {
      objId_Framework: kwargs?.frameworkId,
    })
      .then((response) => {
        setLoading(false);
        setSearchLoading(false);
        setSearchString("");
        setSelectedFilter("All");
        if (response?.mr?.Success) {
          setFrameworkDetails(response?.detail);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  function getSectionDetails(kwargs = {}) {
    let sectionId = kwargs?.sectionId;
    getPageDataApi("SecurityFramework/GetSectionInfo", authTokens, {
      objId_Framework: kwargs?.frameworkId,
      objId_Section: kwargs?.sectionId,
    })
      .then((response) => {
        setLoading(false);
        setSearchLoading(false);
        setSearchString("");
        setSelectedFilter("All");
        setControlFilterData({
          1: [],
          2: [],
        });
        if (response?.mr?.Success) {
          setSectionDetails({ ...response, objId_Section: sectionId });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  function getControlDetails(kwargs = {}) {
    let controlId = kwargs?.controlId;
    getPageDataApi("/SecurityFramework/GetControlInfo", authTokens, {
      objId_Framework: kwargs?.frameworkId,
      objId_Control: kwargs?.controlId,
    })
      .then((response) => {
        setLoading(false);
        setSearchLoading(false);
        setSearchString("");
        setSelectedFilter("All");
        if (response?.mr?.Success) {
          setControlDetails({ ...response, objId_Control: controlId });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  function getSearchDetails(kwargs = {}) {
    getPageDataApi("/SecurityFramework/SearchControl", authTokens, {
      objId_Framework: kwargs?.frameworkId ?? frameworkDetails?.objId_Framework,
      searchBy: kwargs?.searchBy,
    })
      .then((response) => {
        setLoading(false);
        setSearchLoading(false);
        goTo(null, Pages.SEARCH_DETAILS, {});
        if (response?.mr?.Success) {
          setSearchDetails(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  useEffect(() => {
    if (show) {
      setLoading(true);
      getFrameworksList();
      setPages([
        {
          // type: "frameworks_list",
          type: Pages.FRAMEWORKS,
          // type: Pages.CONTROL_DETAILS,
          name: (
            <div className="d-flex align-items-center mr-2">
              <img
                src="/images/security-assessment/explore-mapping-icon.svg"
                alt=""
                className="mr-2"
                style={{
                  maxWidth: "25px",
                  maxHeight: "25px",
                }}
              />
              Explore Framework Mappings
            </div>
          ),
          data: {},
        },
      ]);
    } else {
    }
  }, [show]);

 
  const [selectedFilter, setSelectedFilter] = useState("All");

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setSearchLoading(true);
      if (value) {
        getSearchDetails({
          searchBy: value,
        });
      } else {
        goBackFromSearchPage();
      }
    }, 800);
    setTimeoutId(_timeoutId);
  };

  // Scroll button setup
  const [showScrollIcon, setShowScrollIcon] = useState(true);
  const [showScrollLeftIcon, setShowScrollLeftIcon] = useState(false);

  const handleScroll = () => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    const isScrolledToRight =
      scrollContainer.scrollLeft + scrollContainer.clientWidth + 15 >
      scrollContainer.scrollWidth;

    console.log(scrollContainer.scrollLeft + scrollContainer.clientWidth);
    console.log(scrollContainer.scrollWidth);
    // Check if scrolled to the right edge
    setShowScrollIcon(!isScrolledToRight);
    const isScrolledToLeft = scrollContainer.scrollLeft > 30;

    setShowScrollLeftIcon(isScrolledToLeft);
  };

  const handleIconClick = () => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    // You can adjust the scroll amount based on your requirements
    // scrollContainer.scrollLeft += 50;
    const scrollAmount = 200;
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const handleLeftIconClick = () => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    // You can adjust the scroll amount based on your requirements
    const scrollAmount = -200;
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    // Add scroll event listener
    if (!scrollContainer) return;

    scrollContainer.removeEventListener("scroll", handleScroll);
    scrollContainer.addEventListener("scroll", handleScroll);
    // Initial check for scroll position
    handleScroll();

    // Cleanup: Remove event listener when component unmounts
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [controlDetails]);

  return (
    <SlidingModal
      // backdropZindex={1500}
      show={show}
      className="explore-mapping-icon"
      parentID="mainBody"
      id="acsswseag"
      onHide={() => {
        hideModal();
      }}
    >
      <SlidingModal.Header className="align-items-center justify-content-between d-flex p-3 border-bottom">
        <span className="cursor-pointer d-flex-inline">
          {pages.length > 1 && (
            <img
              src="/images/supply-chain/chevron-left.svg"
              onClick={() => {
                goBack();
              }}
              alt=""
            />
          )}
        </span>

        <div
          className=" mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
          title={activePage?.name}
        >
          {activePage?.name}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>

      <SlidingModal.Body className="">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {activePage?.type === Pages.FRAMEWORKS && (
              <div className="p-2">
                <div className="f-500 f-darkgrey pl-3 mb-2">Frameworks</div>
                {frameworksList.map((framework, frameworkIndex) => {
                  return (
                    <div
                      className={`d-flex align-items-center bg-hover-grey p-2 radius-4 ${viewOnlyAccess("AccessState_ComplianceManager") ? 'cursor-default' : 'pointer'} `}
                      onClick={(e) => {
                        if (!viewOnlyAccess("AccessState_ComplianceManager")) {
                          e.stopPropagation();
                          setLoading(true);
                          getFrameworkDetails({
                            frameworkId: framework?.objId_Framework,
                          });
                          goTo(
                            <div className="d-flex align-items-center mr-2">
                              <img
                                src={framework?.logo}
                                alt=""
                                className="mr-2 radius-4"
                                width={25}
                                height={25}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "/images/user-logo.svg";
                                }}
                              />
                              {framework?.frameworkName}
                            </div>,
                            Pages.FRAMEWORK_DETAILS,
                            {}
                          );
                        }
                      }}
                    >
                      <img
                        src={framework?.logo}
                        alt=""
                        className="mr-2 radius-4"
                        width={40}
                        height={40}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/images/user-logo.svg";
                        }}
                      />
                      <div className="w-100">
                        <div className="f-black f-500 d-flex align-items-center">
                          {framework?.frameworkName}{" "}
                          {
                            viewOnlyAccess("AccessState_ComplianceManager") && 
                            <img
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="ml-2 mb-1"
                            />
                          }
                        </div>
                        <div className="f-darkgrey text-ellipsis w-90">
                          {framework.description}
                        </div>
                      </div>
                      {/* {
                        viewOnlyAccess("AccessState_ComplianceManager") && 
                        <img
                          alt=''
                          className='lockerDarkGreyImage mr-2 mb-1'
                          src='/images/settings-v2/locker-grey.svg'
                        />
                      } */}
                    </div>
                  );
                })}
              </div>
            )}
            {activePage?.type !== Pages.FRAMEWORKS && (
              <div className="p-2 bg-grey radius-4 highlight-input d-flex align-items-center mt-3 mx-2">
                <img
                  src="/images/attack-surface/search-icon.svg"
                  alt=""
                  className="mr-2"
                />
                <input
                  type="text"
                  className="bg-transparent border-none flex-1"
                  placeholder="Find sections, subsections or controls..."
                  value={searchString}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {searchString && (
                  <img
                    src="/images/settings-v2/header-search-cross.svg"
                    alt=""
                    className="mx-2 pointer"
                    onClick={() => {
                      handleInputChange({ target: { value: "" } });
                    }}
                  />
                )}
              </div>
            )}

            {searchLoading ? (
              <div
                className="d-flex align-items-center justify-content-center w-100 "
                style={{
                  height: "70vh",
                }}
              >
                <Loader />
              </div>
            ) : (
              <>
                {activePage?.type === Pages.FRAMEWORK_DETAILS && (
                  <div className="p-3">
                    <div className="f-500 f-black  mt-3">
                      {frameworkDetails?.frameworkName}
                    </div>
                    <div className="f-400 f-grey  mt-2">
                      {TrimString(
                        frameworkDetails?.description,
                        showMoreFrameworkDetails ? null : 120
                      )}
                    </div>

                    {showMoreFrameworkDetails && (
                      <>
                        <div className="f-500 f-darkgrey  mt-2 mb-2">
                          Resources
                        </div>
                        {frameworkDetails?.resources?.map(
                          (resource, resourceIndex) => {
                            return (
                              <div
                                className="f-blue pointer mb-3"
                                onClick={() => {
                                  window.open(resource, "_blank");
                                }}
                              >
                                {" "}
                                {resource}
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                    <div
                      className="d-flex align-items-center w-fit-content pointer p-2 f-500 f-grey"
                      onClick={() => {
                        setshowMoreFrameworkDetails(!showMoreFrameworkDetails);
                      }}
                    >
                      <img
                        src="/images/big-chevron-down.svg"
                        alt=""
                        className={`mr-2 ${
                          showMoreFrameworkDetails ? "rotate180" : ""
                        }`}
                      />
                      Show more
                    </div>
                    <div className="f-500 f-darkgrey mb-2">
                      {frameworkDetails?.sectionLabel}{" "}
                    </div>
                    {frameworkDetails?.sections
                      ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
                      // ?.filter((item) =>
                      //   item?.sectionName
                      //     ?.toLowerCase()
                      //     ?.includes(searchString?.toLowerCase())
                      // )
                      .map((section, sectionIndex) => {
                        return (
                          <div
                            className="d-flex align-items-center bg-hover-grey p-2 pointer radius-4"
                            onClick={() => {
                              setLoading(true);
                              getSectionDetails({
                                frameworkId: frameworkDetails?.objId_Framework,
                                sectionId: section?.objId_Section,
                              });
                              goTo(null, Pages.SECTION_DETAILS, {});
                            }}
                          >
                            <img
                              src="/images/msp/framework-section-icon.svg"
                              alt=""
                              className="mr-2"
                            />

                            <div className="f-black f-500">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: HGHighlightText(
                                    TrimString(section.sectionName, 150),
                                    ""
                                  ),
                                }}
                              />{" "}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                {activePage?.type === Pages.SECTION_DETAILS && (
                  <div className="p-3">
                    <div className="d-flex align-items-start  mt-3">
                      <img
                        src="/images/msp/framework-section-icon.svg"
                        alt=""
                        className="mr-2 mt-1"
                      />
                      <div className="f-500 f-black">
                        {sectionDetails?.sectionName}
                      </div>
                    </div>

                    {/* Filters */}

                    <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                      <div className="d-flex flex-wrap">
                        {sectionDetails?.filters?.map((filter, filterIndex) => {
                          return (
                            <Dropdown className="transparennt-wrapper-dropdown  mr-2 mb-2">
                              <Dropdown.Toggle className="p-0">
                                <span
                                  className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                  style={{
                                    color: `${
                                      controlFilterData[filter.filterType]
                                        .length > 0
                                        ? " #008FD4"
                                        : "#616778"
                                    }`,
                                    border: `${
                                      controlFilterData[filter.filterType]
                                        .length > 0
                                        ? "1px solid #008FD4"
                                        : "1px solid #D4D6DD"
                                    }`,
                                    whiteSpace: "nowrap",
                                    borderRadius: "50px",
                                  }}
                                >
                                  {controlFilterData[filter.filterType]
                                    .length === 0 && (
                                    <>
                                      {filter?.filterLabel}{" "}
                                      <img
                                        src="/images/big-chevron-down.svg"
                                        alt=""
                                        width={10}
                                        height={10}
                                        className="ml-1"
                                      />
                                    </>
                                  )}

                                  {controlFilterData[filter.filterType].length >
                                    0 && (
                                    <>
                                      {filter?.filterLabel}:{" "}
                                      {renderSelectedItemsInDropdown(
                                        controlFilterData[
                                          filter.filterType
                                        ].map((item) => item.text),
                                        2,
                                        20
                                      )}
                                      <img
                                        src="/images/attack-surface/cross-icon.svg"
                                        alt=""
                                        className="pointer ml-2"
                                        style={{
                                          filter:
                                            "invert(45%) sepia(82%) saturate(2585%) hue-rotate(172deg) brightness(89%) contrast(101%)",
                                        }}
                                        onClick={() => {
                                          let updated_data = {
                                            ...controlFilterData,
                                          };
                                          updated_data[filter.filterType] = [];
                                          setControlFilterData(updated_data);
                                        }}
                                      />
                                    </>
                                  )}
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="pl-0 p-2"
                                style={{
                                  width: "max-content",
                                  maxWidth: "270px",
                                }}
                              >
                                {filter?.filterList?.map(
                                  (filterItem, filterItemIndex) => {
                                    let selectedIndex = controlFilterData[
                                      filter.filterType
                                    ].findIndex(
                                      (item) => item.objId === filterItem.objId
                                    );
                                    return (
                                      <div
                                        className={`d-flex align-items-center pointer py-1 f-grey ${
                                          filterItemIndex ===
                                          filter?.filterList?.length - 1
                                            ? ""
                                            : "border-bottom"
                                        }`}
                                        onClick={() => {
                                          let updated_data = {
                                            ...controlFilterData,
                                          };
                                          if (selectedIndex === -1) {
                                            updated_data[
                                              filter.filterType
                                            ].push(filterItem);
                                          } else {
                                            updated_data[
                                              filter.filterType
                                            ].splice(selectedIndex, 1);
                                          }
                                          setControlFilterData(updated_data);
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/msp/dropdown-grey-tick.svg"
                                          className="mx-2"
                                          style={{
                                            visibility:
                                              selectedIndex > -1
                                                ? "visible"
                                                : "hidden",
                                          }}
                                        />
                                        {filterItem?.text}
                                      </div>
                                    );
                                  }
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          );
                        })}
                      </div>

                      <div>
                        {Object.values(controlFilterData).some(
                          (array) => array.length > 0
                        ) && (
                          <div
                            className="f-12 f-500 f-grey pointer"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={() => {
                              setControlFilterData({
                                1: [],
                                2: [],
                              });
                            }}
                          >
                            Reset all
                          </div>
                        )}
                      </div>
                    </div>
                    {sectionDetails?.subSections
                      ?.filter((item) =>
                        controlFilterData[1].length
                          ? controlFilterData[1]
                              .map((item) => item?.text)
                              .includes(item?.subSectionName)
                          : true
                      )
                      .map((subSection, subSectionIndex) => {
                        return (
                          <div className="">
                            <div className="f-500 f-darkgrey my-2">
                              {subSection?.subSectionName}{" "}
                            </div>

                            {subSection?.controls

                              ?.filter((item) =>
                                controlFilterData[2].length
                                  ? item?.chips.some((element) =>
                                      controlFilterData[2]
                                        .map((_item) => _item?.text)
                                        .includes(element)
                                    )
                                  : true
                              )

                              ?.map((control) => {
                                return (
                                  <div
                                    className="bg-hover-grey pointer radius-4 p-2 mb-2"
                                    onClick={() => {
                                      setLoading(true);
                                      getControlDetails({
                                        frameworkId:
                                          frameworkDetails?.objId_Framework,
                                        controlId: control?.objId_Control,
                                      });
                                      goTo(null, Pages.CONTROL_DETAILS, {});
                                    }}
                                  >
                                    <div className=" ">
                                      <span className="d-inline-flex">
                                        <HGPopUp
                                          maxWidth={"300px"}
                                          content={
                                            <div className="p-3">
                                              <div className="my-2 white-space-pre-wrap f-black">
                                                {" "}
                                                <span className="f-600">
                                                  {control.controlID}:
                                                </span>{" "}
                                                {control?.controlDescription}{" "}
                                              </div>
                                              <div className="my-2 f-darkgrey mb-3">
                                                {" "}
                                                {control?.chipLabel}{" "}
                                              </div>
                                              {control?.chips?.map((chip) => {
                                                return (
                                                  <span
                                                    className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                                    style={{
                                                      color: `${"#616778"}`,
                                                      border: `${"1px solid #D4D6DD"}`,
                                                      whiteSpace: "nowrap",
                                                      borderRadius: "50px",
                                                    }}
                                                  >
                                                    {chip}
                                                  </span>
                                                );
                                              })}
                                            </div>
                                          }
                                        >
                                          <span
                                            className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                            style={{
                                              color: `${"#616778"}`,
                                              border: `${"1px solid #D4D6DD"}`,
                                              whiteSpace: "nowrap",
                                              borderRadius: "50px",
                                            }}
                                          >
                                            {control?.controlID}
                                          </span>
                                        </HGPopUp>
                                      </span>
                                      <span className="f-500 f-black">
                                        {control?.controlDescription}
                                      </span>
                                    </div>

                                    {control?.chipLabel && (
                                      <div className="my-2 f-darkgrey">
                                        {control?.chipLabel}{" "}
                                      </div>
                                    )}

                                    {control?.chips?.length > 0 && (
                                      <div className="mt-2">
                                        {control?.chips?.map((chip) => {
                                          return (
                                            <span
                                              className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                              style={{
                                                color: `${"#616778"}`,
                                                border: `${"1px solid #D4D6DD"}`,
                                                whiteSpace: "nowrap",
                                                borderRadius: "50px",
                                              }}
                                            >
                                              {chip}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}

                            {/* If No groups exist */}
                            {subSection?.controls?.filter((item) =>
                              controlFilterData[2].length
                                ? item?.chips.some((element) =>
                                    controlFilterData[2]
                                      .map((_item) => _item?.text)
                                      .includes(element)
                                  )
                                : true
                            )?.length === 0 && (
                              <div className="w-100 text-center f-darkgrey my-3">
                                No control found !!
                              </div>
                            )}
                          </div>
                        );
                      })}

                    {Object.values(controlFilterData).some(
                      (array) => array.length > 0
                    ) &&
                      sectionDetails?.subSections?.filter((item) =>
                        controlFilterData[1].length
                          ? controlFilterData[1]
                              .map((item) => item?.text)
                              .includes(item?.subSectionName)
                          : true
                      ).length === 0 && (
                        <div className="w-100 mt-4">
                          <NoSearchResultUI
                            onReset={() => {
                              setControlFilterData({
                                1: [],
                                2: [],
                              });
                            }}
                          />
                        </div>
                      )}
                  </div>
                )}
                {activePage?.type === Pages.CONTROL_DETAILS && (
                  <div className="p-3">
                    <div className=" mb-2 mt-3">
                      <span
                        className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                        style={{
                          color: `${"#616778"}`,
                          border: `${"1px solid #D4D6DD"}`,
                          whiteSpace: "nowrap",
                          borderRadius: "50px",
                        }}
                      >
                        {controlDetails?.controlID}
                      </span>
                      <span className="f-500 f-black">
                        {controlDetails?.controlDescription}
                      </span>
                    </div>

                    <div className="f-400 f-grey  mt-2 mb-3">
                      {controlDetails?.sectionName}
                    </div>
                    <div className="w-100 position-relative">
                      <div
                        className="d-flex align-items-center hide-scrollbar overflow-x-auto"
                        id="filters-scroll-container"
                      >
                        {controlDetails?.frameworkChips?.map(
                          (item, itemIndex) => {
                            return (
                              <>
                                <div
                                  className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                  style={{
                                    color: `${
                                      selectedFilter === item
                                        ? "#008FD4"
                                        : "#616778"
                                    }`,
                                    border: `${
                                      selectedFilter === item
                                        ? "1px solid #008FD4"
                                        : "1px solid #D4D6DD"
                                    }`,
                                    whiteSpace: "nowrap",
                                    borderRadius: "50px",
                                  }}
                                  onClick={() => {
                                    setSelectedFilter(item);
                                  }}
                                >
                                  {item}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>

                      {showScrollLeftIcon &&
                        controlDetails?.frameworkChips?.length > 0 && (
                          <div
                            className="position-absolute bg-white d-flex align-items-center justify-content-center pointer"
                            style={{
                              left: "0%",
                              top: "50%",
                              transform: "translate(0%, -50%)",

                              zIndex: 3,
                              opacity: 1,
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() => {
                              handleLeftIconClick();
                            }}
                          >
                            <img
                              src="/images/chevron-left-cal.svg"
                              alt=""
                              className=""
                            />
                          </div>
                        )}
                      {showScrollIcon &&
                        controlDetails?.frameworkChips?.length > 0 && (
                          <div
                            className="position-absolute bg-white d-flex align-items-center justify-content-center pointer"
                            style={{
                              right: "0%",
                              top: "50%",
                              transform: "translate(0%, -50%)",

                              zIndex: 3,
                              opacity: 1,
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() => {
                              handleIconClick();
                            }}
                          >
                            <img
                              src="/images/chevron-right-cal.svg"
                              alt=""
                              className=""
                            />
                          </div>
                        )}
                    </div>

                    <div className="f-darkgrey f-500 mt-2 mb-3">
                      Mapped Controls
                    </div>

                    {controlDetails?.similarControls?.[0]?.list
                      ?.filter((item) =>
                        selectedFilter === "All"
                          ? true
                          : item?.frameworkName === selectedFilter
                      )
                      ?.map((framework, dataIndex) => {
                        return (
                          <>
                            <div className="">
                              <div
                                className={`${
                                  dataIndex > 0 ? "mt-2" : ""
                                } f-600`}
                              >
                                {framework?.frameworkName}{" "}
                              </div>

                              {framework?.sections?.map(
                                (section, sectionIndex) => {
                                  return (
                                    <>
                                      <div>
                                        <div className="f-darkgrey f-12 f-500 my-2">
                                          {section.sectionName}{" "}
                                        </div>

                                        <div className="d-flex flex-wrap mt-1">
                                          {section?.controls?.map(
                                            (control, controlIndex) => {
                                              return (
                                                <div className="d-flex align-items-center position-relative tooltip-parent mb-1">
                                                  <HGPopUp
                                                    maxWidth={"300px"}
                                                    content={
                                                      <div className="p-3">
                                                        <div className="my-2 white-space-pre-wrap f-black">
                                                          {" "}
                                                          <span className="f-600">
                                                            {control.controlID}:
                                                          </span>{" "}
                                                          {
                                                            control?.controlDescription
                                                          }{" "}
                                                        </div>
                                                        <div className="my-2 f-darkgrey mb-3">
                                                          {" "}
                                                          {
                                                            control?.chipLabel
                                                          }{" "}
                                                        </div>
                                                        {control?.chips?.map(
                                                          (chip) => {
                                                            return (
                                                              <span
                                                                className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                                                style={{
                                                                  color: `${"#616778"}`,
                                                                  border: `${"1px solid #D4D6DD"}`,
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                  borderRadius:
                                                                    "50px",
                                                                }}
                                                              >
                                                                {chip}
                                                              </span>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    }
                                                  >
                                                    <span
                                                      className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                                      style={{
                                                        color: `${"#616778"}`,
                                                        border: `${"1px solid #D4D6DD"}`,
                                                        whiteSpace: "nowrap",
                                                        borderRadius: "50px",
                                                      }}
                                                    >
                                                      {control?.controlID}
                                                    </span>
                                                  </HGPopUp>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>

                            {dataIndex > 0 && (
                              <div
                                className="w-100 my-2"
                                style={{
                                  borderBottom: "1px solid #EBEDF1",
                                }}
                              />
                            )}
                          </>
                        );
                      })}

                    {/* If No Mapped controles found */}
                    {controlDetails?.similarControls?.length === 0 && (
                      <div className="w-100 text-center f-darkgrey my-3">
                        No mapped controls found !!
                      </div>
                    )}
                  </div>
                )}

                {activePage?.type === Pages.SEARCH_DETAILS && (
                  <div className="w-100 p-3 pt-2">
                    <div className="f-darkgrey">
                      Search results: {searchDetails?.detail?.length}
                    </div>
                    {searchDetails?.detail?.length > 0 ? (
                      <>
                        {searchDetails?.detail?.map((dataRow, dataRowindex) => {
                          return (
                            <>
                              {dataRow?.isSection ? (
                                <div
                                  className="bg-hover-grey pointer radius-4 p-2 mb-2"
                                  onClick={() => {
                                    setLoading(true);
                                    getSectionDetails({
                                      frameworkId:
                                        frameworkDetails?.objId_Framework,
                                      sectionId: dataRow?.objId,
                                    });
                                    goTo(null, Pages.SECTION_DETAILS, {});
                                  }}
                                >
                                  <div className="f-grey f-12 f-500 my-2">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          TrimString(dataRow?.header, null),
                                          searchString
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex align-items-start ">
                                    <img
                                      src="/images/msp/framework-section-icon.svg"
                                      alt=""
                                      className="mr-2 mt-1"
                                    />
                                    <div className="f-500 f-black">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            TrimString(dataRow?.title, null),
                                            searchString
                                          ),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="bg-hover-grey pointer radius-4 p-2 mb-2"
                                  onClick={() => {
                                    setLoading(true);
                                    getControlDetails({
                                      frameworkId:
                                        frameworkDetails?.objId_Framework,
                                      controlId: dataRow?.objId,
                                    });
                                    goTo(null, Pages.CONTROL_DETAILS, {});
                                  }}
                                >
                                  <div className="f-grey f-12 f-500 my-2">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          TrimString(dataRow?.header, null),
                                          searchString
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div className=" ">
                                    <span
                                      className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                      style={{
                                        color: `${"#616778"}`,
                                        border: `${"1px solid #D4D6DD"}`,
                                        whiteSpace: "nowrap",
                                        borderRadius: "50px",
                                      }}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            TrimString(
                                              dataRow?.controlId,
                                              null
                                            ),
                                            searchString
                                          ),
                                        }}
                                      />
                                    </span>
                                    <span className="f-500 f-black">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            TrimString(dataRow?.title, null),
                                            searchString
                                          ),
                                        }}
                                      />
                                    </span>
                                  </div>

                                  {dataRow?.chips?.length > 0 && (
                                    <div className="mt-2">
                                      {dataRow?.chips?.map((chip) => {
                                        return (
                                          <span
                                            className="px-2 p-1 mr-2 pointer f-12 f-500 w-max-content"
                                            style={{
                                              color: `${"#616778"}`,
                                              border: `${"1px solid #D4D6DD"}`,
                                              whiteSpace: "nowrap",
                                              borderRadius: "50px",
                                            }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: HGHighlightText(
                                                  TrimString(chip, null),
                                                  searchString
                                                ),
                                              }}
                                            />
                                          </span>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <div className="w-100 mt-4">
                        <NoSearchResultUI
                          onReset={() => {
                            setSearchLoading(true);
                            handleInputChange({ target: { value: "" } });
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </SlidingModal.Body>
    </SlidingModal>
  );
};

export default ExploreMappingModal;

const NoSearchResultUI = ({ onReset = () => {} }) => {
  return (
    <div className="w-50 mx-auto">
      <div className="f-500 f-grey text-center">No results</div>
      <div className="f-darkgrey mt-2 text-center">
        Try another search request. Or reset search and filters{" "}
      </div>
      <div
        className="f-blue f-500 mt-3 pointer text-center"
        onClick={() => {
          onReset();
        }}
      >
        Reset search and filters
      </div>
    </div>
  );
};

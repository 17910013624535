import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, ToggleButton } from "react-bootstrap";
import { useAuth } from "../../../../context/Auth";
import { getAPIData, postData } from "../../../../../Utils/commonApi";
import { HGHighlightText } from "../../../../App/App";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addZerows } from "..";
import integrationUtils from "../../../../../Utils/integration.utils";
import { ROUTE_PATHS } from "../../../../../constants/app.constants";

const SelectProductModal = ({
  children,
  contributionName,
  contributionId = 0,
  isReplaceWithClicked = false,
  setIsReplaceWithClicked = () => { },
  productIdToReplace,
  importDetails,
  setImportDetails = () => { },
  startData,
  setStartData = () => { },
  checkIfReplaced = () => { },
  fromPractices = false,
  serviceId = 0
}) => {
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (IsDropdownOpen) {
      setLoading(true);
      getProducts();
      if (fromPractices) {
        getLabourData(/*13149618*/serviceId);
      }
    }
  }, [IsDropdownOpen])


  useEffect(() => {
    if (isReplaceWithClicked) {
      setIsDropdownOpen(true);
    }
  }, [isReplaceWithClicked])

  const history = useHistory();
  const scrollContainerRef = useRef(null);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [data, setData] = useState([]);
  const { authTokens } = useAuth();

  useEffect(() => {
    if (!open) setPageNumber(0);
  }, [open]);
  useEffect(() => {
  }, [pageNumber]);

  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 201 >=
        e.target.scrollHeight
      ) {
        if (isLastPage) return;

        let element = document.getElementById("scrollList");
        element.removeEventListener("scroll", getMoreClients);

        // setLoading(true);
        try {
          await getProducts();

          console.log("You've reached the end of the screen!");
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("scrollList");
    if (element) {
      element.removeEventListener("scroll", getMoreClients);
      element.addEventListener("scroll", getMoreClients);

      return () => {
        element.removeEventListener("scroll", getMoreClients);
      };
    }
  }, [data, loading, open]);

  function removeDuplicates(arr) {
    const seen = {};
    return arr.filter(item => {
      return seen.hasOwnProperty(item.ObjId_PSAProduct) ? false : (seen[item.ObjId_PSAProduct] = true);
    });
  }

  async function getProducts(num, searchStr) {
    try {
      const pageN = num ?? pageNumber;
      const response = await postData(authTokens, "GetPagedPackageProduct", {
        PageNumber: pageN,
        SearchText: searchStr ?? searchString,
        Technology: contributionName ?? '',
      });

      if (response.PSAProductVM_List?.length == 0) {
        setData(response?.PSAProductVM_List);
      } else if (response?.PageNumber === 0 || pageNumber === 0) {
        setData(response?.PSAProductVM_List);
        setPageNumber(response?.PageNumber);
      } else {
        const new_output = response?.PSAProductVM_List;
        const old_client_data = [...data];
        const updated_data = [...new Set([...old_client_data, ...new_output])];

        const new_updated_data = removeDuplicates(updated_data);
        setData(new_updated_data);
        setPageNumber(response?.PageNumber);
      }
      setLoading(false);
      setIsLastPage(response?.IsLastPage);

      console.log("response num", response?.PageNumber);

      return response?.PageNumber;
    } catch (error) {
      console.error("error in getProducts", error);
      setLoading(false);
      throw error;
    }
  }
  const [labourData, setLabourData] = useState([])
  async function getLabourData(serviceId) {
    await getAPIData(993, authTokens, {
      optionEnum1: serviceId,
    })
      .then((response) => {
        setLabourData(response?.SecurityPackLabour_List);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

  }

  function findProductById(id) {
    return importDetails?.find(({ ScoreContributionSelected_List }) =>
      ScoreContributionSelected_List?.find(({ ContributionName, List_ScoreProducts }) =>
        ContributionName == contributionName &&
        List_ScoreProducts?.find(({ objId_Product }) => objId_Product == id)
      )
    )?.ScoreContributionSelected_List?.find(({ ContributionName, List_ScoreProducts }) =>
      ContributionName == contributionName
    )?.List_ScoreProducts?.find(({ objId_Product }) => objId_Product == id);
  }

  const replacedFrom = checkIfReplaced(productIdToReplace, startData, contributionName);
  const [selectedTab, setSelectedTab] = useState({
    id: 0,
    name: 'Product'
  })

  const tabs = [
    {
      id: 0,
      name: 'Product'
    },
    {
      id: 1,
      name: 'Labour'
    },
  ]

  // const labourData = [{
  //   LabourName: 'Test',
  //   NoOfHours: 1,
  //   Frequency_Text: 'hour(s)',
  //   Total: 10
  // }]

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (value) => {
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);
    console.log("check input", value);
    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setLoading(true);
      getProducts(0, value).then((tempN) => {
        setLoading(false);
        setPageNumber(tempN);
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };

  return (
    <Dropdown className="select-product-or-labour-dropdown mr-4"
      show={IsDropdownOpen}
      onToggle={(e) => {
        setIsDropdownOpen(e);
        setIsReplaceWithClicked(false);
      }}
    >
      <Dropdown.Toggle>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <div>
          {
            fromPractices ? <div className="d-flex align-items-center py-2 px-2">
              {
                tabs?.map((tabsItem) => {
                  return (
                    <div
                      className="d-flex align-items-center w-50 pointer"
                      onClick={() => {
                        setSelectedTab(tabsItem);
                        setSearchString("");
                      }}
                    >
                      <Form.Group controlId={"check"} className="round-radio2">
                        <Form.Check
                          type="radio"
                          name="stripeId"
                          label=""
                          value={selectedTab?.id === tabsItem?.id}
                          checked={selectedTab?.id === tabsItem?.id}
                          onChange={() => { }}
                          onClick={() => { }}
                          className="custom-radio"
                        />
                      </Form.Group>
                      <div className="f-400 f-14 f-black line-height-22 letter-space-2 ml-">{tabsItem?.name}</div>
                    </div>
                  )
                })
              }
            </div> : <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2 px-1">
              Replace with...
            </div>
          }

          <div className="no-highlight-input w-100 d-flex align-items-center p-2">
            <div>
              <img
                width={14}
                height={14}
                src="/images/attack-surface/search-icon.svg"
                className="m-1"
                alt=""
              />
            </div>

            <input
              className="pl-2 flex-grow-1"
              placeholder="Search"
              type="text"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
                if (selectedTab?.id == 0) {
                  setPageNumber(0);
                  handleInputChange(e.target.value);
                }
              }}
            />
          </div>

          <div>
            {/* {
              searchString != "" &&
              <div
                className="f-blue f-500 ml-3 my-2 d-flex align-items-center pointer"
                onClick={() => {
                  setPage("addProduct", searchString);
                }}
              >
                <img alt="" src="/images/msp/blue-add-icon.svg" className="mr-2" />
                {searchString ? (
                  <div className="pt-1">Add {` "${searchString}" `}</div>
                ) : (
                  <div className="pt-1">Add product</div>
                )}
              </div>
            } */}

            {
              replacedFrom && !fromPractices &&
              <div>
                <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                  Default product from this template
                </div>
                <div
                  className="ml-2 f-400 f-14 f-grey px-1 py-2 line-height-22 letter-spice-2 radius-4 bg-hover-grey pointer"
                  onClick={(e) => {
                    const updatedData = importDetails.map(service => {
                      return {
                        ...service,
                        ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                          if (contribution.ContributionName == contributionName) {
                            return {
                              ...contribution,
                              List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                if (item.objId_Product === productIdToReplace) {
                                  return {
                                    ProductName: replacedFrom?.ProductName,
                                    objId_Product: replacedFrom?.objId_Product,
                                    List_ProductTool: [
                                      ...item?.List_ProductTool
                                    ]
                                  };
                                }
                                return item;
                              })
                            };
                          }
                          return contribution;
                        })
                      };
                    });

                    setImportDetails(updatedData);

                    const updatedStartData = startData.map(service => {
                      return {
                        ...service,
                        ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                          if (contribution.ContributionName == contributionName) {
                            return {
                              ...contribution,
                              List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                if (item.objId_Product === productIdToReplace) {
                                  return {
                                    ProductName: replacedFrom?.ProductName,
                                    objId_Product: replacedFrom?.objId_Product,
                                    List_ProductTool: [
                                      ...item?.List_ProductTool
                                    ]
                                  };
                                }
                                return item;
                              })
                            };
                          }
                          return contribution;
                        })
                      };
                    });

                    setStartData(updatedStartData);

                    e.target
                      .closest(".select-product-or-labour-dropdown")
                      .click();
                  }}
                >
                  {replacedFrom?.ProductName}
                </div>
              </div>
            }

            {
              selectedTab?.id == 0 ? <div>
                {
                  loading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                    <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                  </div> : <div>
                    <div className="h-50">
                      <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2 mt-1">
                        Linked Products
                      </div>
                      {
                        data?.filter((product) => product.IsLinked === true)?.length > 0 ? <div>
                          {
                            data?.filter((product) => product.IsLinked === true)?.map((product) => {
                              return (
                                <div
                                  className={`product-item align-items-center justify-content-between p-2 mx-1 mb-1 radius-4 ${(fromPractices ? findProductById(product?.ObjId_PSAProduct) : productIdToReplace == product?.ObjId_PSAProduct) ? '' : 'bg-hover-grey pointer'}`}
                                  onClick={(e) => {
                                    if (fromPractices) {
                                      if (!findProductById(product?.ObjId_PSAProduct, importDetails)) {
                                        const updatedData = importDetails.map(service => {
                                          return {
                                            ...service,
                                            ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                              if (contribution.ContributionName == contributionName) {
                                                return {
                                                  ...contribution,
                                                  List_ScoreProducts: [
                                                    ...contribution?.List_ScoreProducts,
                                                    {
                                                      ProductName: product?.ProductName,
                                                      objId_Product: product?.ObjId_PSAProduct,
                                                    }
                                                  ]
                                                };
                                              }
                                              return contribution;
                                            })
                                          };
                                        });

                                        setImportDetails(updatedData);

                                        e.target
                                          .closest(".select-product-or-labour-dropdown")
                                          .click();
                                      }
                                    } else {
                                      if (productIdToReplace != product?.ObjId_PSAProduct) {
                                        if (productIdToReplace) {
                                          const updatedData = importDetails.map(service => {
                                            return {
                                              ...service,
                                              ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                if (contribution.ContributionName == contributionName) {
                                                  return {
                                                    ...contribution,
                                                    List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                                      if (item.objId_Product === productIdToReplace) {
                                                        return {
                                                          ProductName: product?.ProductName,
                                                          objId_Product: product?.ObjId_PSAProduct,
                                                          List_ProductTool: [
                                                            ...item?.List_ProductTool
                                                          ]
                                                        };
                                                      }
                                                      return item;
                                                    })
                                                  };
                                                }
                                                return contribution;
                                              })
                                            };
                                          });

                                          setImportDetails(updatedData);

                                          const updatedStartData = startData.map(service => {
                                            return {
                                              ...service,
                                              ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                if (contribution.ContributionName == contributionName) {
                                                  return {
                                                    ...contribution,
                                                    List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                                      if (item.objId_Product === productIdToReplace) {
                                                        return {
                                                          ...item,
                                                          replacedTo: product?.ObjId_PSAProduct

                                                        };
                                                      }
                                                      return item;
                                                    })
                                                  };
                                                }
                                                return contribution;
                                              })
                                            };
                                          });

                                          setStartData(updatedStartData);

                                        } else {
                                          const updatedData = importDetails.map(service => {
                                            return {
                                              ...service,
                                              ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                if (contribution.ContributionName == contributionName) {
                                                  return {
                                                    ...contribution,
                                                    List_ScoreProducts: [
                                                      {
                                                        ProductName: product?.ProductName,
                                                        objId_Product: product?.ObjId_PSAProduct,
                                                        List_ProductTool: [],
                                                        isAdded: true
                                                      }
                                                    ]
                                                  };
                                                }
                                                return contribution;
                                              })
                                            };
                                          });

                                          setImportDetails(updatedData);
                                        }
                                        e.target
                                          .closest(".select-product-or-labour-dropdown")
                                          .click();
                                      }
                                    }
                                  }}
                                >
                                  <div>
                                    <div className={` f-500 subclass ${(fromPractices ? findProductById(product?.ObjId_PSAProduct) : productIdToReplace == product?.ObjId_PSAProduct) ?
                                      "txt-blue" : "f-grey"
                                      }`}>
                                      {/* <div className={` f-500 subclass f-grey`}> */}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            product?.ProductName,
                                            searchString
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex f-darkgrey f-400 f-12 align-items-center">
                                      {product?.IntegrationId > 0 && (
                                        <img
                                          src={integrationUtils.getImagePath(product?.IntegrationId, product?.ToolIcon)}
                                          alt=""
                                          className="rounded-full mr-1"
                                          width={13}
                                          height={13}
                                          style={{}}
                                        />
                                      )}
                                      {product?.IntegrationName && (
                                        <div className="mr-1">
                                          {product?.IntegrationName}
                                        </div>
                                      )}

                                      {product?.CurrencyCode_Selected?.CurrencySymbol && (
                                        <div>{`${product?.CurrencyCode_Selected?.CurrencySymbol} `}</div>
                                      )}
                                      {product?.Price_Text && (
                                        <div className="mr-1">{`${product?.Price_Text} `}</div>
                                      )}
                                      {product?.SnapToUnit_Text && (
                                        <div className="mr-1">{`/${product?.SnapToUnit_Text} `}</div>
                                      )}
                                      {product?.BillingPeriod_Text && (
                                        <div>{`/${product?.BillingPeriod_Text} `}</div>
                                      )}
                                    </div>
                                  </div>
                                  {product?.IsMissingDetails && (
                                    <div className="d-flex align-items-center f-darkgrey f-500 f-12">
                                      {" "}
                                      <img
                                        width={16}
                                        alt=""
                                        className="mr-1"
                                        src="/images/msp/grey-alert.svg"
                                      />
                                      <div>Missing Details</div>
                                    </div>
                                  )}
                                </div>
                              )
                            })
                          }
                        </div> : <div className="d-flex align-items-center justify-content-center w-100 my-3">
                          <div style={{
                            marginRight: '90px',
                            marginLeft: '90px'
                          }}>
                            <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey">
                              You have not linked any products to a tool in your security stack for this technology type.
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-100 f-400 f-14 f-blue pointer"
                              onClick={() => {
                                history.push(ROUTE_PATHS.mspv2.securityStack);
                              }}
                            >
                              Go to Security Stack
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="h-50">
                      <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                        Products
                      </div>
                      {
                        data?.filter((product) => product.IsLinked === false)?.length > 0 ? <div>
                          {
                            data?.filter((product) => product.IsLinked === false)?.map((product) => {
                              return (
                                <div
                                  className={`product-item align-items-center justify-content-between  p-2 mx-1 mb-1 radius-4 ${(fromPractices ? findProductById(product?.ObjId_PSAProduct) : productIdToReplace == product?.ObjId_PSAProduct) ? '' : 'bg-hover-grey pointer'}`}
                                  onClick={(e) => {
                                    if (fromPractices) {
                                      if (!findProductById(product?.ObjId_PSAProduct, importDetails)) {
                                        const updatedData = importDetails.map(service => {
                                          return {
                                            ...service,
                                            ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                              if (contribution.ContributionName == contributionName) {
                                                return {
                                                  ...contribution,
                                                  List_ScoreProducts: [
                                                    ...contribution?.List_ScoreProducts,
                                                    {
                                                      ProductName: product?.ProductName,
                                                      objId_Product: product?.ObjId_PSAProduct,
                                                    }
                                                  ]
                                                };
                                              }
                                              return contribution;
                                            })
                                          };
                                        });

                                        setImportDetails(updatedData);

                                        e.target
                                          .closest(".select-product-or-labour-dropdown")
                                          .click();
                                      }
                                    } else {

                                      if (productIdToReplace != product?.ObjId_PSAProduct) {

                                        if (productIdToReplace) {
                                          const updatedData = importDetails.map(service => {
                                            return {
                                              ...service,
                                              ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                if (contribution.ContributionName == contributionName) {
                                                  return {
                                                    ...contribution,
                                                    List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                                      if (item.objId_Product === productIdToReplace) {
                                                        return {
                                                          ProductName: product?.ProductName,
                                                          objId_Product: product?.ObjId_PSAProduct,
                                                          List_ProductTool: [
                                                            ...item?.List_ProductTool
                                                          ]
                                                        };
                                                      }
                                                      return item;
                                                    })
                                                  };
                                                }
                                                return contribution;
                                              })
                                            };
                                          });

                                          setImportDetails(updatedData);

                                          const updatedStartData = startData.map(service => {
                                            return {
                                              ...service,
                                              ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                if (contribution.ContributionName == contributionName) {
                                                  return {
                                                    ...contribution,
                                                    List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                                      if (item.objId_Product === productIdToReplace) {
                                                        return {
                                                          ...item,
                                                          replacedTo: product?.ObjId_PSAProduct

                                                        };
                                                      }
                                                      return item;
                                                    })
                                                  };
                                                }
                                                return contribution;
                                              })
                                            };
                                          });

                                          setStartData(updatedStartData);

                                        } else {
                                          const updatedData = importDetails.map(service => {
                                            return {
                                              ...service,
                                              ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                if (contribution.ContributionName == contributionName) {
                                                  return {
                                                    ...contribution,
                                                    List_ScoreProducts: [
                                                      {
                                                        ProductName: product?.ProductName,
                                                        objId_Product: product?.ObjId_PSAProduct,
                                                        List_ProductTool: [],
                                                        isAdded: true
                                                      }
                                                    ]
                                                  };
                                                }
                                                return contribution;
                                              })
                                            };
                                          });

                                          setImportDetails(updatedData);
                                        }
                                        e.target
                                          .closest(".select-product-or-labour-dropdown")
                                          .click();
                                      }
                                    }
                                  }}
                                >
                                  <div>
                                    <div className={` f-500 subclass ${(fromPractices ? findProductById(product?.ObjId_PSAProduct) : productIdToReplace == product?.ObjId_PSAProduct) ?
                                      "txt-blue" : "f-grey"
                                      }`}>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            product?.ProductName,
                                            searchString
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex f-darkgrey f-400 f-12 align-items-center">
                                      {product?.IntegrationId > 0 && (
                                        <img
                                          src={integrationUtils.getImagePath(product?.IntegrationId, product?.ToolIcon)}
                                          alt=""
                                          className="rounded-full mr-1"
                                          width={13}
                                          height={13}
                                          style={{}}
                                        />
                                      )}
                                      {product?.IntegrationName && (
                                        <div className="mr-1">
                                          {product?.IntegrationName}
                                        </div>
                                      )}

                                      {product?.CurrencyCode_Selected?.CurrencySymbol && (
                                        <div>{`${product?.CurrencyCode_Selected?.CurrencySymbol} `}</div>
                                      )}
                                      {product?.Price_Text && (
                                        <div className="mr-1">{`${product?.Price_Text} `}</div>
                                      )}
                                      {product?.SnapToUnit_Text && (
                                        <div className="mr-1">{`/${product?.SnapToUnit_Text} `}</div>
                                      )}
                                      {product?.BillingPeriod_Text && (
                                        <div>{`/${product?.BillingPeriod_Text} `}</div>
                                      )}
                                    </div>
                                  </div>
                                  {
                                    product?.IsMissingDetails && (
                                      <div className="d-flex align-items-center f-darkgrey f-500 f-12">
                                        {" "}
                                        <img
                                          width={16}
                                          alt=""
                                          className="mr-1"
                                          src="/images/msp/grey-alert.svg"
                                        />
                                        <div>Missing Details</div>
                                      </div>
                                    )
                                  }
                                </div>
                              )
                            })
                          }
                        </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                          No data yet
                        </div>
                      }
                    </div>
                  </div>
                }
              </div> : <div>
                <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                  Labours
                </div>
                <div>
                  {
                    labourData?.filter((labour) =>
                      labour.LabourName.toLowerCase().includes(searchString.toLowerCase()))?.length > 0 ? <div>
                      {
                        labourData?.filter((labour) =>
                          labour.LabourName.toLowerCase().includes(searchString.toLowerCase()))?.map((labour) => {
                            return (
                              <div
                                className={`p-2 mx-1 mb-1 radius-4 ${findProductById(labour?.objId_SecurityPackLabour) ? '' : 'bg-hover-grey pointer'}`}
                                onClick={(e) => {
                                  if (!findProductById(labour?.objId_SecurityPackLabour)) {
                                    const updatedData = importDetails.map(service => {
                                      return {
                                        ...service,
                                        ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                          if (contribution.ContributionName == contributionName) {
                                            return {
                                              ...contribution,
                                              List_ScoreProducts: [
                                                ...contribution?.List_ScoreProducts,
                                                {
                                                  ProductName: labour?.LabourName,
                                                  objId_Product: labour?.objId_SecurityPackLabour
                                                }
                                              ]
                                            };
                                          }
                                          return contribution;
                                        })
                                      };
                                    });

                                    setImportDetails(updatedData);

                                    e.target
                                      .closest(".select-product-or-labour-dropdown")
                                      .click();
                                  }
                                }}
                              >
                                <div className={` f-500 subclass ${findProductById(labour?.objId_SecurityPackLabour) ?
                                  "txt-blue" : "f-grey"
                                  }`}>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: HGHighlightText(
                                        labour?.LabourName,
                                        searchString
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="f-400 f-12 f-darkgrey">
                                  {labour?.NoOfHours} {labour?.Frequency_Text} • {addZerows((labour?.Total?.toString()))}
                                </div>
                              </div>
                            )
                          })
                      }
                    </div> : <div className="my-3">
                      <div className="d-flex align-items-center justify-content-center f-400 f-12 f-darkgrey">No labour costs</div>
                      <div className="d-flex align-items-center justify-content-center f-400 f-12 f-darkgrey">Add labour costs at the bottom</div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown >
  );
};

export default SelectProductModal;
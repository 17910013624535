import { useRef, useState } from 'react';
import './side-bar.styles.scss';
import useToggleRow from '../../../../hooks/toggle-row.hook';
import SelectArrowIcon from '../../../icons/select-arrow.icon';
import stringUtils from '../../../../Utils/string.utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import useDownloadFile from '../../../../hooks/download-file.hook';
import ContentLoader from 'react-content-loader';

function getPercentage(str) {
  return str.split('%')[0].trim();
}

const Item = ({ item }) => {
  const history = useHistory();
  const downloadFile = useDownloadFile();
  const onClick = () => {
    if (!item.url) return;
    const isValidUrl = stringUtils.isValidUrl(item.url);
    if (isValidUrl && stringUtils.isUrlToFile(item.url)) {
      downloadFile(item.url);
    } else if (isValidUrl) {
      window.open(item.url, '_blank');
    } else {
      history.push(item.url);
    }
  }

  return (
    <div className='item' onClick={onClick}>
      <div className='icon-container'>
        <img 
          src={item.Icon}
          className='mr-2'
          alt='icon'
        />
      </div>
      <span className={`title f-grey ${item.isStrikethrough ? 'name-strikethrough ' : ''}`}>
        {item.Name}
      </span>
    </div>
  )
}

const Section = ({ section }) => {
  const pointRef = useRef(null);
  const panelRef = useRef(null);

  const { toggleRow, isOpen } = useToggleRow({ multiple: true, defaultOpen: true, rowRef: pointRef });
  const percentage = section.Percentage ? getPercentage(section.Percentage) : 0;
  const percantageClass = percentage == 0 ? 'grey' : percentage == 100 ? 'green' : 'orange';

  return (
    <div ref={pointRef} className="point section" style={{ overflow: 'hidden', maxWidth: '100%' }}>
      <div onClick={() => toggleRow()} className='cursor-pointer row'>
        <div className='header'>
          <span className='title f-grey mr-2'>{section.Title}</span>
          {section?.Percentage && (
            <span className={`percentage ${percantageClass}`}>
              <span>{section.Percentage}</span>
            </span>
          )}
        </div>
        <div className={`select-arrow ${isOpen ? 'open' : ''}`}>
          <SelectArrowIcon width="9px" height="6px" />
        </div>
      </div>
      <div ref={panelRef} className="tool-info panel">
        <div className='list'>
          {section?.SectionItems?.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

const DashboardSideBar = ({ sidebarData, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = (state) => {
    setIsOpen(state);
  }

  return (
    <>
    <div className='client-right-sideBar-btn' onClick={() => onToggle(true)}>
      <img 
        src='/images/dashboard/menuCollapse.svg'
        alt="client-right-sideBar-btn"
      />
    </div>
    {isOpen && (
      <div 
        className='client-right-sideBar-bg' 
        onClick={() => onToggle(false)}
      />
    )}
    <div className={`client-right-sideBar sideBar fullHeight p-0 ${isOpen ? 'open' : ''}`}>
      <div className='dashboard-right-side-bar-wrapper hide-scrollbar'>
        <div className='dashboard-right-side-bar'>
          {isLoading && !sidebarData ? (
            <SidebarLoader />
          ) : (
            sidebarData?.map((section, index) => (
              <Section 
                key={index} 
                section={section}
              />
            ))
          )}
        </div>
      </div>
      </div>
    </>
  );
}

export default DashboardSideBar;

const SidebarLoader = () => (
  <ContentLoader
    width={'100%'}
    height={'620px'}
    className='pr-1'
  >
    {[...Array(17)].map((_, index) => (
      <rect x="0" y={`${(index * 32) + (index * 4)}px`} rx="8px" ry="8px" width="100%" height = "32px" />
    ))}
  </ContentLoader>
)

export const AccessRoleModulesName = {
  Dashboard: 'Dashboard'
}

export const AccessStatesName = {
  NoAccess: 'No Access',
  RestrictedView: 'Restricted View',
  View: 'View',
  Edit: 'Edit',
}

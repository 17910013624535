import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import Confetti from "react-dom-confetti";
import { getAPIData } from "../../Utils/commonApi";
import { TrimString } from "../../Utils/StringTrimmer";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";

import ConnectIntegrationModal from "../MSPv2/integrations/ConnectIntegrationModal";
import { useHistory } from "react-router-dom";
import { TrackUser } from "../../Utils/SegmentFunctions";
import UpgradeToPremiumModal from "./UpgradeToPremiumModal";
import MspConnectIntegrationModal from "../MSPv2/integrations/mspIntegrationdetailScreen/MspConnectIntegrationModal";
import { accessStateValue, getAccessStateValue, NO_ACCESS_TEXT, viewOnlyAccess } from "../App/App";
import integrationUtils from "../../Utils/integration.utils";
import ClockIcon from "../icons/clock.icon";
import DisabledButtonWithToolTip from "../Theme/DisabledButtonWithToolTip";
import RequestToolIcon from "../icons/request-tool.icon";
import IntegrationsSearchDropdown from "./integrations-search.dropdown";
import { AllToolsLabel, ComingSoonLabel, IntegrationState } from "../../constants/integrations.constants";
import { ROUTE_PATHS } from "../../constants/app.constants";

const AppMarketPlace = forwardRef(
  (
    {
      setSuggestIntegrationModal,
      sortAsc,
      showRecommendedList,
      showComingSoonlist,
      IsMsp,
    },
    ref
  ) => {
    const [searchString, setSearchString] = useState("");
    const { authTokens } = useAuth();
    const [loading, setLoading] = useState(false);
    const [upgradeOptions, setUpgradeOptions] = useState({});
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showMspConnectModal, setShowMspConnectModal] = useState(false);
    const history = useHistory();
    const [selectedIntegrationId, setSelectedIntegrationId] = useState("");
    const [pageData, setPageData] = useState(false);
    const [recommendedList, setRecommendedList] = useState([]);
    const [allTools, setAllTools] = useState([]);
    const [itemsVisible, setItemsVisible] = useState(7);
    const [comingSoonTools, setComingSoonTools] = useState([]);
    const [initialTagsLength, setInitialTagsLength] = useState("empty");
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(AllToolsLabel);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [filters, setFilters] = useState({
      ObjectId: 0,
      IntegrationFilter_List: [
        {
          IntegrationFilter_Enum: 1,
          FilterText_List: null, // [""],
        },
        {
          IntegrationFilter_Enum: 2,
          FilterText_List: null, // [""],
        },
      ],
      TaskSort: 0,
      TaskSortOrder: 0,
    });
    async function showConnectToolModal(integrationId) {
      await getAPIData(`${502}`, authTokens)
        .then((response) => {
          if (response?.SuccessCount === 1) {
            setSelectedIntegrationId(integrationId);
            setShowConnectModal(true);
          } else if (
            response?.SuccessCount === 0 ||
            response?.SuccessCount === -1
          ) {
            setUpgradeOptions(
              response?.AccessObjList?.filter(
                (item) => item?.Question === "Integrations Visibility"
              )?.[0]?.UpgradeOptions ?? {}
            );
            setShowUpgradeModal(true);
          } else {
            CallToast(response?.Message, false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }

    async function showConnectModalWithoutSubscription(_integrationId) {
      setSelectedIntegrationId(_integrationId);
      setShowConnectModal(true);
    }

    async function showMspConnectIntegrationModal(_integrationId) {
      setSelectedIntegrationId(_integrationId);
      setShowMspConnectModal(true);
    }
    useEffect(() => {
      setLoading(true);
      getPageData(filters);
      setSelectedSearchTab();
    }, []);

    async function getPageData(_filters) {
      await getAPIData("458", authTokens, {
        optionStrEnum1: JSON.stringify(_filters),
        optionEnum99:1
      })
        .then((response) => {
          setLoading(false);
          if (response?.mr?.Success) {
            setPageData(response);
            // Extracting Tags from the Integration List
            if (initialTagsLength === "empty") {
              let all_tags = [];
              for (
                let integrationIndex = 0;
                integrationIndex < response?.IntegrationList?.length;
                integrationIndex++
              ) {
                if (
                  response?.IntegrationList[integrationIndex].Tags_List
                    ?.length > 0
                ) {
                  for (
                    let tagIndex = 0;
                    tagIndex <
                    response?.IntegrationList[integrationIndex].Tags_List
                      ?.length;
                    tagIndex++
                  ) {
                    if (
                      all_tags.indexOf(
                        response?.IntegrationList[integrationIndex].Tags_List[
                          tagIndex
                        ]
                      ) === -1
                    ) {
                      all_tags.push(
                        response?.IntegrationList[integrationIndex].Tags_List[
                          tagIndex
                        ]
                      );
                    }
                  }
                }
              }

              setSections([AllToolsLabel, ...all_tags]);
              setInitialTagsLength("not-empty");
            }

            // Setting Integration lists
            let whole_list = response?.IntegrationList?.filter(
              (item) => item?.IntegrationId > 0
            );
            setRecommendedList(
              whole_list?.filter(
                (item) => item?.IntegrationMarketplaceEnum === 0
              )
            );
            setAllTools(
              whole_list?.filter(
                (item) => item?.IntegrationMarketplaceEnum === 1
              )
            );
            setComingSoonTools(
              whole_list?.filter(
                (item) => item?.IntegrationMarketplaceEnum === 2
              )
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }

    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
      if (!initialLoad) {
        setInitialLoad(true);
        return;
      }

      getPageData(filters);
    }, [filters]);

    async function voteIntegration(integrationId) {
      await getAPIData("65", authTokens, {
        OptionEnum1: integrationId,
        OptionEnum2: 4,
      })
        .then((response) => {
          if (response?.Success) {
            getPageData(filters);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useImperativeHandle(ref, () => ({
      searchIntegration(text) {
        let updated_data = { ...filters };

        updated_data.IntegrationFilter_List[1].FilterText_List = [text];

        setFilters(updated_data);
      },
    }));

    function filterIntegrationByTags(integration_list) {
      return integration_list
        .sort((a, b) => {
          if (sortAsc === true) {
            if (a.IntegrationName < b.IntegrationName) {
              return -1;
            }
            if (a.IntegrationName > b.IntegrationName) {
              return 1;
            }
            return 0;
          }
          if (sortAsc === false) {
            if (a.IntegrationName > b.IntegrationName) {
              return -1;
            }
            if (a.IntegrationName < b.IntegrationName) {
              return 1;
            }
            return 0;
          }

          return 0;
        })
        .filter((integration) => {
          if (selectedSection === AllToolsLabel) {
            return true;
          } else {
            if (integration.Tags_List?.includes(selectedSection)) {
              return true;
            } else {
              return false;
            }
          }
        });
    }

    // To show selected Search Tab

    function setSelectedSearchTab() {
      const selectedSearchTab = new URLSearchParams(window.location.search).get(
        "selectedSearchTab"
      );
      if (selectedSearchTab) {
        setSelectedSection(selectedSearchTab);

        if (IsMsp) {
          return;
        }

        history.push({ search: "" });
      }
    }

    const currentToolList = selectedSection !== ComingSoonLabel ? filterIntegrationByTags(allTools) : comingSoonTools;

    useEffect(() => {
      setIsSidebarOpen(false);
    }, [selectedSection]);

    return (
      <>
        {false ? (
          <div className="pt-5 pl-4">
            <AppMarketPlaceLoader />
          </div>
        ) : (
          <div className="integrations-layout">
            <div className="sidebar-layout ">
              {loading ? (
                <SideBarLoader />
              ) : (
                <>
                  {isSidebarOpen && (
                    <div 
                      className="sidebar-bg-open-state"
                      onClick={() => setIsSidebarOpen(false)}
                    />
                  )}
                  <div
                    className="sidebar-state-btn"
                    onClick={() => setIsSidebarOpen(true)}
                  >
                    <img 
                      src="/images/dashboard/menuCollapse.svg"
                      alt="menuCollapse"
                    />
                  </div>
                  <div className={`sidebar hide-scrollbar ${isSidebarOpen ? "open" : ""}`}>
                    <div className="items-list ">
                      {sections?.length > 0 &&
                        [
                          sections[0],
                          ...sections
                            .slice(1)
                            .sort((a, b) =>
                              a
                                .trim()
                                .toLowerCase()
                                .substring(0, 2)
                                .localeCompare(b.trim().toLowerCase().substring(0, 2))
                            ),
                        ]
                          ?.map((section, sectionIndex) => {
                            return (
                              <div
                                className={`radius-4 pointer item ${
                                  selectedSection === section
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedSection(section);
                                }}
                              >
                                <span className="text">{section}</span>
                              </div>
                            );
                          })}
                      <div className="separator" />
                      <div
                        className={`radius-4 pointer item d-flex align-items-center ${
                          selectedSection === ComingSoonLabel
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedSection(ComingSoonLabel);
                        }}
                        style={{
                          gap: '13px'
                        }}
                      >
                        <div 
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: '16px', height: '16px' }}
                        >
                          <ClockIcon 
                            width="14px"
                            height="14px"
                            className="item-icon"
                          />
                        </div>
                        <span className="text">
                          {ComingSoonLabel}
                        </span>
                      </div>
                      <div className="request-tool-container">
                        <div />
                        <div className="separator" />
                        <div style={{ padding: '0 8px' }}>
                          <h3 className="m-0 f-14 line-height-22 f-black">
                            Coudn't find desired tool?
                          </h3>
                          <p className="m-0 f-12 line-height-18 f-grey" style={{ whiteSpace: 'pre-wrap' }}>
                            {`You can vote for your favorite tool or\nrequest this tool`}
                          </p>
                        </div>
                        {   
                          getAccessStateValue("AccessState_MSP_Integrations" )>2 ? (
                            <div
                              className="d-flex align-items-center item active pointer"
                              onClick={() => {
                                setSuggestIntegrationModal(true);
                                TrackUser("Suggest Integration clicked");
                              }}
                              style={{ gap: '8px', width: 'fit-content' }}
                            >
                              <RequestToolIcon className="item-icon" />
                              <span className="text">
                                Request a tool
                              </span>
                            </div>
                          ) : (
                            <OverlayTrigger
                                placement="bottom"
                                trigger={["hover", "focus"]}
                                delay={{ show: 200, hide: 250 }}
                                overlay={(props) => (
                                  <Tooltip
                                      className="tooltip-request-access" 
                                      style={{ position: 'relative', zIndex: 10000 }}
                                      {...props}
                                  >
                                    <div>
                                      {NO_ACCESS_TEXT}
                                    </div>
                                  </Tooltip>
                                )}
                            >
                              <div
                                className="d-flex align-items-center item active pointer"
                                style={{ gap: '8px', width: 'fit-content' }}
                              >
                                <img
                                  width={14}
                                  height={14}
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="mr-2"
                                />
                                <span className="text">
                                  Request a tool
                                </span>
                              </div>
                            </OverlayTrigger>
                          )
                        } 
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="w-100">
              <div className="header-wrapper">
                <div className="d-flex flex-column" style={{ gap: '2px' }}>
                  <h2 className="m-0">{sections[0] === selectedSection ? "Find an integration" : selectedSection}</h2>
                  {sections[0] === selectedSection ? (
                    <span className="f-12 line-height-18 f-darkgrey">
                      Search for the tools you use
                    </span>
                  ) : selectedSection === ComingSoonLabel ? (
                    <span className="f-12 line-height-18 f-darkgrey">
                      Upvote your favourite tools to see them in HighGround faster
                    </span>
                  ) : null}
                </div>
                {!loading && selectedSection !== ComingSoonLabel ? (
                  <IntegrationsSearchDropdown
                    searchString={searchString}
                    setSearchString={setSearchString}
                    tools={[
                      ...recommendedList,
                      ...allTools,
                      ...comingSoonTools,
                    ]}
                    categories={sections}
                    setSelectedSection={setSelectedSection}
                    IsMsp={IsMsp}
                  />
                ) : null}
              </div>
              <div className="main-body">
                {loading ? (
                  <IntegrationCardsLoader />
                ) : (
                  <>
                    {(showRecommendedList && selectedSection !== ComingSoonLabel) && (
                      <>
                        <div className="d-flex align-items-start mb-3">
                          <img
                            src="/images/attack-surface/thunder-icon.svg"
                            alt=""
                            className="mr-2 mt-2"
                          />
                          <div>
                            <p className="f-16 f-600 mb-0">
                              Recommended Integrations
                            </p>
                            <div className="f-darkgrey">
                              Recommendation based on your Security Stack
                            </div>
                          </div>
                        </div>
                        {/* Recommended Tools List */}
                        <div className="integrations-container">
                          {filterIntegrationByTags(recommendedList)?.map(
                            (integration, integrationIndex) => {
                              return (
                                <IntegrationCard
                                  integration={integration}
                                  isRecommended={true}
                                  refreshData={getPageData}
                                  showConnectToolModal={showConnectToolModal}
                                  IsMsp={IsMsp}
                                  showConnectModalWithoutSubscription={
                                    showConnectModalWithoutSubscription
                                  }
                                  showMspConnectIntegrationModal={
                                    showMspConnectIntegrationModal
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </>
                    )}

                    {/* All tools */}
                    <div className="f-16 f-600 my-3">
                      {selectedSection === AllToolsLabel
                        ? "All Integrations"
                        : selectedSection === ComingSoonLabel
                        ?  "Coming integrations"
                        : `All ${selectedSection}`}
                    </div>
                    {/* All tools list */}
                    <div className="integrations-container">
                      {currentToolList?.map(
                        (integration, integrationIndex) => {
                          return (
                            <IntegrationCard
                              integration={integration}
                              isAllTool={selectedSection !== ComingSoonLabel}
                              refreshData={getPageData}
                              showConnectToolModal={showConnectToolModal}
                              IsMsp={IsMsp}
                              showConnectModalWithoutSubscription={
                                showConnectModalWithoutSubscription
                              }
                              showMspConnectIntegrationModal={
                                showMspConnectIntegrationModal
                              }
                              isVotingCard={selectedSection === ComingSoonLabel}
                            />
                          );
                        }
                      )}
                    </div>

                    {/* Coming Soon Tools */}
                    {/* Header */}

                    {showComingSoonlist && (
                      <>
                      {
                        currentToolList?.length <= 0 &&
                          <div className="d-flex algn-items-center justify-content-center"
                            style={{
                              marginTop: '50px',
                              marginBottom: '50px'
                            }}
                          >
                            <div>
                              <div className="f-500 f-darkgrey mx-auto d-flex algn-items-center justify-content-center">
                                Currently we do not have any integrations in this Category 
                              </div>
                              <div className="f-500 f-darkgrey mx-auto d-flex algn-items-center justify-content-center">
                                Please check back later
                              </div>
                            </div>
                          </div>
                        }

                        

                        {/* Voting Tools List */}
                        {selectedSection !== ComingSoonLabel && (
                          <>
                            {
                              filterIntegrationByTags(comingSoonTools)?.length > 0 &&
                              <div className="d-flex algn-items-center justify-content-between pt-4">
                                <div className="d-flex align-items-start mb-3">
                                  <img
                                    src="/images/attack-surface/grey-thumbs-up.svg"
                                    alt=""
                                    className="mr-2 mt-2"
                                  />
                                  <div>
                                    <p className="f-16 f-600 mb-0">Coming Soon...</p>
                                    <div className="f-darkgrey">
                                      Vote for your favourite tools
                                    </div>
                                  </div>
                                </div>

                                <div></div>
                              </div>
                            }
                            <div className="integrations-container">
                              {filterIntegrationByTags(comingSoonTools)?.map(
                                (integration, integrationIndex) => {
                                  return (
                                    <IntegrationCard
                                      integration={integration}
                                      isVotingCard={true}
                                      refreshData={getPageData}
                                      voteIntegration={voteIntegration}
                                      IsMsp={IsMsp}
                                      showConnectModalWithoutSubscription={
                                        showConnectModalWithoutSubscription
                                      }
                                      showMspConnectIntegrationModal={
                                        showMspConnectIntegrationModal
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <ConnectIntegrationModal
          show={showConnectModal}
          hideModal={() => setShowConnectModal(false)}
          integrationId={selectedIntegrationId}
          refreshData={getPageData}
        />
        <MspConnectIntegrationModal
          show={showMspConnectModal}
          hideModal={() => setShowMspConnectModal(false)}
          integrationId={selectedIntegrationId}
          refreshData={getPageData}
        />

        <UpgradeToPremiumModal
          show={showUpgradeModal}
          hideModal={() => setShowUpgradeModal(false)}
          upgradeOptions={upgradeOptions}
        />
      </>
    );
  }
);

export default AppMarketPlace;

export const IntegrationCard = ({
  integration,
  isVotingCard,
  isRecommended,
  isAllTool,
  refreshData,
  voteIntegration,
  showConnectToolModal,
  showConnectModalWithoutSubscription,
  showMspConnectIntegrationModal,
  IsMsp,
}) => {
  let accessState =
    integration?.TechnologyTitle?.toLowerCase() === "psa"
      ? "AccessState_PSA_Integration"
      : IsMsp
      ? "AccessState_MSP_Integrations"
      : "AccessState_Integrations";
  const config = {
    angle: 90,
    spread: "360",
    startVelocity: 50,
    elementCount: 70,
    dragFriction: 0.3,
    duration: 4000,
    stagger: 1,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };
  const [isExploding, setIsExploding] = useState(false);

  const history = useHistory();
  return (
    <div
      className="w-100 bg-grey bg-hover-dark-grey radius-8 d-flex align-items-center position-relative flex-column p-3 po inter hover-scale-integration-card"
      style={{
        minHeight:"210px"
      }}
      onClick={() => {
        if (!isVotingCard) {
          if(window.screen.width<700){
return
          }
          if (IsMsp) {
            history.push(
              ROUTE_PATHS.mspv2.integrationDetails(integration?.IntegrationId)
            );
          } else {
            history.push(`/integrations/${integration?.IntegrationId}`);
          }
          TrackUser("Tool description clicked", {
            type: integration?.IntegrationName,
          });
        }
      }}
    >
{(isRecommended|| isAllTool) && false &&

<div className="position-absolute top-0 right-0 bottom-0 hover-over-slider"

style={{
  zIndex:100
}}
>
<div className="h-100 d-flex flex-column justify-content-center ml-2">

<div className="f-blue f-500 mb-2 pointer">Connect integration</div>
<div className="f-blue f-500 mb-2 pointer">View Intgeration Details</div>
<div className="f-blue f-500 mb-2 pointer">View Mapping Info</div>
 
</div>
</div>

}









      {/* first row */}
      <div
        className="d-flex align-items-center justify-content-between w-100 mb-2"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <div className="f-500 f-black d-flex align-items-center">
          <img
            src={integrationUtils.getImagePath(integration?.IntegrationId, integration?.ToolIcon)}
            alt=""
            className="mr-2 radius-4"
            width={34}
            height={34}
          />
          <div className="break-word">
            {TrimString(integration?.IntegrationName, 50)}
          </div>
        </div>
        <div className="radius-2 d-flex align-items-center justify-content-end       ">
          {/* Is Recomended Card */}
          {isRecommended && (
            <>
              {integration?.ButtonFunction !== 2 && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess(accessState) ? ["hover", "focus"] : []
                  }
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip id={`tooltip-1365`}>
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div
                    className={`${
                      viewOnlyAccess(accessState) && "cursor-default"
                    }`}
                  >
                    <Button
                      className={` ${
                        viewOnlyAccess(accessState)
                          ? "btn-disable"
                          : "hg-blue-btn"
                      } d-flex align-items-center`}
                      onClick={(e) => {
                        e.stopPropagation(integration?.IntegrationId);
                        if (integration?.IntegrationId ==3417718 ) {
                          showConnectModalWithoutSubscription(
                            integration?.IntegrationId
                          );
                          return
                        }
                        if (
                          IsMsp &&
                          integration?.TechnologyTitle?.toLowerCase() === "psa"
                        ) {
                          showMspConnectIntegrationModal(
                            integration?.IntegrationId
                          );
                        } else if (
                          IsMsp &&
                          integration?.TechnologyTitle?.toLowerCase() !== "psa"
                        ) {
                          showConnectModalWithoutSubscription(
                            integration?.IntegrationId
                          );
                        } else {
                          showConnectToolModal(integration?.IntegrationId);
                        }
                        TrackUser("Clicked Integrate Tool");
                      }}
                    >
                      {viewOnlyAccess(accessState) ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/attack-surface/white-plus-icon.svg"
                          alt=""
                          className=" mr-2"
                        />
                      )}
                      Connect
                    </Button>
                  </div>
                </OverlayTrigger>
              )}
              {integration?.ButtonFunction === 2 && (
                <div
                  className="radius-2 d-flex align-items-center justify-content-end p-1 px-2 f-12 f-500 expand-on-hover"
                  style={{
                    color: `${integration?.IntegrationStateColour}`,
                  }}
                >
                 
                  {integration?.IntegrationState === IntegrationState.Syncing ? (
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm"
                      style={{
                        width: "14px",
                        height: "14px",
                        minWidth: '14px'
                      }}
                      className="mr-2"
                    />
                  ) : (
                    <img
                      alt=""
                      className="mr-2"
                      width={14}
                      height={14}
                      src={integration?.IntegrationStateIconURL}
                    />
                  )}
                  <div
                    style={{
                      height: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {integration?.IntegrationState}
                  </div>
                </div>
              )}
            </>
          )}
          <Confetti active={isExploding} config={config} />

          {/* Is All tools  Card */}
          {isAllTool && (
            <>
              {integration?.ButtonFunction !== 2 && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess(accessState) ? ["hover", "focus"] : []
                  }
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip id={`tooltip-1365`}>
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div
                    className={`${
                      viewOnlyAccess(accessState) && "cursor-default"
                    } ${
                      accessStateValue(accessState) == 0 && "cursor-default"
                    }`}
                  >
                    <Button
                      className={` ${
                        viewOnlyAccess(accessState)
                          ? "btn-disable" :
                          accessStateValue(accessState) == 0
                          ? "btn-disable"
                          : "hg-tranparent-grey-btn"
                      } d-flex align-items-center`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (integration?.IntegrationId ==3417718 ) {
                          showConnectModalWithoutSubscription(
                            integration?.IntegrationId
                          );
                          return
                        }
                        if (accessStateValue(accessState) != 0) {
                          if (!viewOnlyAccess(accessState)) {
                            e.stopPropagation();
                            if (
                              IsMsp &&
                              integration?.TechnologyTitle?.toLowerCase() === "psa"
                            ) {
                              showMspConnectIntegrationModal(
                                integration?.IntegrationId
                              );
                            } else if (
                              IsMsp &&
                              integration?.TechnologyTitle?.toLowerCase() !== "psa"
                            ) {
                              showConnectModalWithoutSubscription(
                                integration?.IntegrationId
                              );
                            } else {
                              showConnectToolModal(integration?.IntegrationId);
                            }
                            TrackUser("Clicked Integrate Tool");
                          }
                        }
                      }}
                    >
                      {viewOnlyAccess(accessState) ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : accessStateValue(accessState) == 0 ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2"
                        />
                      ) : (
                        <img
                          src="/images/actioncenter/plusgrey.svg"
                          alt=""
                          className=" mr-2"
                        />
                      )}
                      Connect
                    </Button>
                  </div>
                </OverlayTrigger>
              )}

              {integration?.ButtonFunction === 2 && (
                <div
                  className="radius-2 d-flex align-items-center justify-content-end p-1 px-2 f-12 f-500 expand-on-hover"
                  style={{
                    color: `${integration?.IntegrationStateColour}`,
                  }}
                >
                  
                  {integration?.IntegrationState === IntegrationState.Syncing ? (
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm"
                      style={{
                        width: "14px",
                        height: "14px",
                        minWidth: '14px'
                      }}
                      className="mr-2"
                    />
                  ) : (
                    <img
                      alt=""
                      className="mr-2"
                      width={14}
                      height={14}
                      src={integration?.IntegrationStateIconURL}
                    />
                  )}
                  <div
                    style={{
                      height: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {integration?.IntegrationState}
                  </div>
                </div>
              )}
            </>
          )}

          {/* If isVoting Card */}
          {isVotingCard && (
            <>
              {integration?.IsUpVoted ? (
                <div className="f-green">
                  <img
                    alt=""
                    src="/images/attack-surface/voted-tick.svg"
                    className="mr-1"
                  />
                  Voted
                </div>
              ) : (
                <Button
                  className="voting-btn d-flex align-items-center"
                  onClick={() => {
                    voteIntegration(integration?.IntegrationId);
                    setIsExploding(true);
                    TrackUser("Integration Upvote clicked", {
                      type: integration?.IntegrationName,
                    });
                    setTimeout(() => {
                      setIsExploding(false);
                    }, 2500);
                  }}
                >
                  <img
                    src="/images/attack-surface/white-thumbs-up.svg"
                    alt=""
                    className=" mr-2"
                  />
                  Vote
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {/* second row */}
      {isRecommended && (
        <>
          <div className="w-100 d-flex flex-column justify-content-between h-100">
            <div className="f-grey py-3 word-break" title={integration?.IntegrationSummary}>
              {TrimString(integration?.IntegrationSummary, 200)}
            </div>
            <div className="d-flex align-items-end justify-content-between">
              <div className="d-flex align-items-center w-100 mt-2">
                <MultiTagsUI tagList={integration?.Tags_List} />
              </div>

              <div className="d-flex align-items-center justify-content-end w-100">
                <img
                  src="/images/attack-surface/thunder-icon.svg"
                  alt=""
                  className=""
                />
              </div>
            </div>
          </div>
        </>
      )}
      {isAllTool && (
        <>
          <div className="w-100 d-flex flex-column justify-content-between h-100">
            <div className="f-grey py-3 word-break" title={integration?.IntegrationSummary}>
              {TrimString(integration?.IntegrationSummary, 200)}
            </div>
            <div className="d-flex align-items-center w-100 mt-2">
              <MultiTagsUI tagList={integration?.Tags_List} />
            </div>
          </div>
        </>
      )}

      {isVotingCard && (
        <>
          <div className="w-100 d-flex align-items-center justify-content-start">
            <div className="d-flex align-items-center w-100 mt-2">
              <MultiTagsUI tagList={integration?.Tags_List} />
            </div>
          </div>

          <div className="d-flex align-items-center w-100 mb-2">
            {integration?.type?.map((item) => {
              return (
                <div
                  className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                  style={{
                    background: `${item.color}30`,
                    color: `${item.color}`,
                  }}
                >
                  {item.name === "Default" && (
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/msp/integrations/default-integration-icon.svg"
                    />
                  )}
                  {item.name}
                </div>
              );
            })}
          </div>
          {/* Third row */}
          <div className="w-100 d-flex flex-column mt-1">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <div className="f-grey">Added</div>
              <div className="f-grey">{integration?.UpVoteDate ?? "N/A"}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="f-grey">Votes</div>
              <div className="f-grey"> {integration?.UpVoteCount} </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const AppMarketPlaceLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="0" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="240" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="480" rx="8" ry="8" width="30%" height="220" />
    </ContentLoader>
  );
};

const SideBarLoader = () => (
  <div 
    className="sidebar hide-scrollbar"
    style={{ top: 35 }}
  >
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"790px"}
      viewBox="0 0 300 790"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      {[...Array(30)].map((_, index) => (
        <rect x="0" y={`${(index * 32) + (index * 4)}px`} rx="8" ry="8" width="100%" height="32px" />
      ))}
    </ContentLoader>
  </div>
);

const IntegrationCardsLoader = () => (
  <div>
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="8" ry="8" width="32.8%" height="220" />
      <rect x="33.5%" y="0" rx="8" ry="8" width="32.8%" height="220" />
      <rect x="67%" y="0" rx="8" ry="8" width="32.8%" height="220" />

      <rect x="0" y="230" rx="8" ry="8" width="32.8%" height="220" />
      <rect x="33.5%" y="230" rx="8" ry="8" width="32.8%" height="220" />
      <rect x="67%" y="230" rx="8" ry="8" width="32.8%" height="220" />

      <rect x="0" y="460" rx="8" ry="8" width="32.8%" height="220" />
      <rect x="33.5%" y="460" rx="8" ry="8" width="32.8%" height="220" />
      <rect x="67%" y="460" rx="8" ry="8" width="32.8%" height="220" />
    </ContentLoader>
  </div>
)

export const MultiTagsUI = ({ tagList }) => {
  return (
    <div className="d-flex align-items-center flex-wrap gap-8">
      {tagList?.slice(0, 2).map((tagName) => (
        <SingleTagUI itemName={tagName} />
      ))}
      {/* if tags length more than 2 */}

      {tagList?.length > 2 && (
        <div>
          <Dropdown
            className="interationv2_tagDropdown"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown.Toggle className="">
              <div
                className="radius-2 p-1 px-2 f-12 f-500"
                style={{
                  background: `${"#616778"}30`,
                  color: `${"#616778"}`,
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                }}
              >
                +{tagList?.length - 2}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="d-flex flex-column px-2">
              {tagList?.slice(2, 1000)?.map((tagName) => (
                <div className="mb-2">
                  <SingleTagUI itemName={tagName} />
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export const SingleTagUI = ({ itemName }) => {
  return (
    <div
      className="radius-2 p-1 px-2 f-12 f-500 w-max-content"
      style={{
        background: `${"#616778"}30`,
        color: `${"#616778"}`,
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
      }}
    >
      {itemName}
    </div>
  );
};

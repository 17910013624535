import { useEffect, useMemo, useState } from "react";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";
import { createStaticRanges } from "react-date-range"
import useDateDefineds from "../../../../../hooks/date-defineds.hook";

const useDatePickerData = ({ setDateSelection = () => {}, id }) => {
  const defineds = useDateDefineds();
  const [dateSelectionValue, setDateSelectionValue] = useState({
    startDate: defineds.startOfLastTwelveMonth,
    endDate: defineds.endOfToday,
    key: "selection",
  })
  function getDateDifferenceCategory(date1, date2) {
    // Convert the input strings to Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Get the current date
    const currentDate = new Date();
    currentDate.setHours(23, 59, 59, 0);

    // Define the time frames
    const currentYearStart = new Date(currentDate.getFullYear(), 0, 1);

    const last12MonthsStart = startOfMonth(subMonths(new Date(), 12));
    const previousYearStart = new Date(currentDate.getFullYear() - 1, 0, 1);
    const previousYearEnd = new Date(currentDate.getFullYear() - 1, 11, 31);
    previousYearEnd.setHours(23, 59, 59);
    const last6MonthsStart = startOfMonth(subMonths(new Date(), 6));
    const last90DaysStart = startOfDay(addDays(new Date(), -90));
    const last30DaysStart = startOfDay(addDays(new Date(), -30));
    function isDifferenceInRange(number1, number2, difference) {
      const absoluteDifference = Math.abs(number1 - number2);
      return absoluteDifference <= difference;
    }
    // Compare the input dates with the defined time frames
    if (
      startDate.getTime() === currentYearStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Current Year";
    } else if (
      startDate.getTime() === previousYearStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), previousYearEnd.getTime(), 5000)
    ) {
      return "Previous Year";
    } else if (
      startDate.getTime() === last12MonthsStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 12 Months";
    } else if (
      startDate.getTime() === last6MonthsStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 6 Months";
    } else if (
      startDate.getTime() === last90DaysStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 90 Days";
    } else if (
      startDate.getTime() === last30DaysStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 30 Days";
    } else {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const _startDate = startDate.toLocaleDateString("en-US", options);
      const _endDate = endDate.toLocaleDateString("en-US", options);
      return _startDate + " - " + _endDate;
    }
  }
  const selectedDateFilter = useMemo((state) => {
    let dashboard_date = {
      ...dateSelectionValue,
    };

    dashboard_date.startDate = dashboard_date.startDate
      ? new Date(dashboard_date.startDate)
      : null;

    dashboard_date.endDate = dashboard_date.endDate
      ? new Date(dashboard_date.endDate)
      : null;
    dashboard_date.key = "selection";
    return dashboard_date;
  },[dateSelectionValue]);
  const dateFilterValue = getDateDifferenceCategory(
    selectedDateFilter?.startDate,
    selectedDateFilter?.endDate
  );
  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "Current Year",
        range: () => ({
          startDate: defineds.startOfCurrentYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Previous Year",
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOfPreviousYear,
        }),
      },
      {
        label: "Last 12 Months",
        range: () => ({
          startDate: defineds.startOfLastTwelveMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 6 Months",
        range: () => ({
          startDate: defineds.startOfLastSixMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 90 Days",
        range: () => ({
          startDate: defineds.startOfLastNintyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 30 Days",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];

    return customDateObjects;
  };
  const sideBar = sideBarOptions();
  const staticRanges = [...createStaticRanges(sideBar)];

  useEffect(() => {
    setDateSelection(dateSelectionValue);
  }, [dateSelectionValue, id]);

  return {
    dateSelectionValue,
    setDateSelectionValue,
    selectedDateFilter,
    dateFilterValue,
    staticRanges,
    defineds
  }
}

export default useDatePickerData;

import React, { useState, useEffect } from "react";

import { AttackSurfaceGetApi } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AssetsListMainPageLoader from "../AttackSurfaceLoaders/AssetsListMainPageLoader";

import "../common.scss";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import { useHistory } from "react-router-dom";
import { cleanText } from "../Index";
import { showSubtitleOfAsset } from "../communications/Index";
import { viewOnlyAccess } from "../../App/App";
import { ROUTE_PATHS } from "../../../constants/app.constants";

const AssetsList = ({ goTo, setAssetId, refreshMenuItems }) => {
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const { authTokens } = useAuth();
  const [showResetModal, setShowResetModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setMainPageLoading(true);
    getPageData();
  }, []);

  async function getPageData() {
    await AttackSurfaceGetApi(`AttackSurfaceAPI/LandingPage?`, authTokens, {
      // start: 2271,
      // end: 2282,
      ASEComponentSecEnum: 2
    }).then((response) => {
      setMainPageLoading(false);
      if (response?.Success) {
        setPageData(response?.landingPageResponses);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  return (
    <>
      {mainPageLoading ? (
        <AssetsListMainPageLoader />
      ) : (
        <>
          {" "}
          <div className="w-100 d-flex align-items-center justify-content-center bg-lightblue p-2">
            <div className="d-flex align-items-center justify-content-center">
              <img
                alt=""
                src="/images/attack-surface/plug-icon.svg"
                className="mr-3"
              />
              <div className="d-flex align-items-center justify-content-center">
                Automate your data acquisition by integrating your existing
                security tools
              </div>
              <div
                className="d-flex align-items-center justify-content-center f-blue mx-3 pointer"
                onClick={() => {
                  history.push(ROUTE_PATHS.saas.integrations);
                }}
              >
                Connect tools
              </div>
            </div>
          </div>
          <div className="cdsa-wrapper">
            {/*Assets List */}
            <div className="font-weight-bold mt-3 mb-2">Assets List</div>
            <div className="bg-grey radius-8 border-parent">
              {pageData.map((asset, assetIndex) => {
                return (
                  <div
                    className="d-flex flex-column border-item"
                    onClick={() => {
                      if (!viewOnlyAccess("AccessState_AttackSurface")) {
                      setAssetId(asset?.ObjectDefID);
                      goTo(cleanText(asset?.ObjectName));
                      }
                    }}
                  >
                    <div className={`d-flex align-items-center justify-content-between w-100 px-4  py-3 bg-hover-row ${
                      viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer' 
                    } `}>
                      <div
                        className="d-flex flex-column "
                        style={{ width: "75%" }}
                      >
                        <div className="d-flex align-items-baseline ">
                          <img
                            alt=""
                            src="/images/attack-surface/yellow-dot.svg"
                            className="mr-1"
                            style={{
                              visibility: `${asset?.ObjectCount > 0 || asset?.Disabled
                                  ? "hidden"
                                  : "visible"
                                }`,
                            }}
                          />

                          <p className="f-500 m-0 f-black">
                            {cleanText(asset?.ObjectName)}
                          </p>
                        </div>
                        <p
                          className="f-12 f-darkgrey "
                          style={{ marginLeft: "0.83rem" }}
                        >
                          {showSubtitleOfAsset(
                            cleanText(asset?.ObjectName),
                            asset?.isService,
                            asset?.ObjectCount,
                            asset?.Disabled
                          )}
                        </p>
                      </div>
                      <div>
                        {
                          viewOnlyAccess("AccessState_AttackSurface") ?
                            <img
                              alt=''
                              className='lockerDarkGreyImage mr-2 mb-1'
                              src='/images/settings-v2/locker-grey.svg'
                            />
                            :
                            <img
                              alt=""
                              src="/images/attack-surface/chevron-right.svg"
                            />
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Reset button */}

            <div
              className="f-grey  mt-4 ml-2 pointer d-none"
              onClick={() => {
                setShowResetModal(true);
              }}
            >
              <img
                alt=""
                src="/images/attack-surface/loader-icon.svg"
                className="mr-2"
              />
              Reset answers
            </div>
          </div>
        </>
      )}
      <AttackSurfaceResetModal
        refreshData={getPageData}
        resetPageEnum={267}
        show={showResetModal}
        hideModal={() => {
          setShowResetModal(false);
        }}
      />
    </>
  );
};

export default AssetsList;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MspSingleTagUI } from "../integrations/mspIntegrationdetailScreen/MspIntegrationCard";
import { renderPriceText } from "./MspClientPriceCalculator";
import { Dropdown } from "react-bootstrap";
import MspComponents from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspComponents";
import { PackageToolTip, QuestionChip } from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import GeneralInformationBlock from "./packaesDetails/GeneralInformationBlock";
import SecurityCapabilitiesBlock from "./packaesDetails/SecurityCapabilitiesBlock";
import IncludedFeaturesBlock from "./packaesDetails/IncludedFeaturesBlock";
import AlignmentToFrameworkBlock from "./packaesDetails/AlignmentToFrameworkBlock";
import PerSeatBlock from "./calculationBlock/perSeat";
import PerAssetBlock from "./calculationBlock/perAsset";
import ServiceComponentsBlock from "./packaesDetails/ServiceComponentsBlock";


export const PackagesDetailsBlock = ({
    packageName,
    _packageData,
    featuresData,
    packageDetails,
    componentsData,
    featuresLoading,
    priceCalculatorState,
    securityCapabilitiesData,
    securityCapabilitiesLoading,
    discount,
    switchVal,
    contribLoading,
    isMspClientView,
    showContribReset,
    contributionValue,
    switchIncludedTaxVal,
    updatePackageMappedPrice = () => { },
    setDiscount = () => { },
    setContribLoading = () => { },
    getPackageDetails = () => { },
    setShowContribReset = () => { },
    resetAssetSeatDataApi = () => { },
    updateSeatAssetDataApi = () => { },
    setIsFromAddContribTable = () => { },
    setShowMapCurrentSpendModal = () => { },
    setMapCurrentSpendPackageId = () => { },
    setMapCurrentSpendPackagePrice = () => { },
    setMapCurrentSpendPackagePeriod = () => { },
    setMapCurrentSpendPackagePriceText = () => { },
    setIsSmthOverriden = () => { },
}) => {

    const [packageData, setPackageData] = useState(_packageData);
    useEffect(() => {
        setPackageData(_packageData);
    }, [_packageData]);


    const mergedDataList = packageData?.pillarList?.reduce((acc, item) => {
        return acc?.concat(item.dataList);
    }, []);

    const showBy = [{
        id: 0,
        label: 'Show by NIST CSF Function'
    },
    {
        id: 1,
        label: 'Show by Security Pillar'
    }]

    const [selectedShowBy, setSelectedShowBy] = useState({
        id: 0,
        label: 'Show by NIST CSF Function'
    })
    const [selectedShowByData, setSelectedShowByData] = useState(securityCapabilitiesData?.SecurityCapabiltiesByNist_List?.sort((a, b) => a?.ObjectId - b?.ObjectId));

    useEffect(() => {
        setSelectedShowByData(securityCapabilitiesData?.SecurityCapabiltiesByNist_List)?.sort((a, b) => a?.ObjectId - b?.ObjectId);
    }, [securityCapabilitiesData])

    return (
        <div className="package-info-block">
            <GeneralInformationBlock
                packageData={packageData}
            />
            <div className="border-bottom-gray w-100 py-3"></div>

            <SecurityCapabilitiesBlock
                showBy={showBy}
                selectedShowBy={selectedShowBy}
                selectedShowByData={selectedShowByData}
                securityCapabilitiesData={securityCapabilitiesData}
                securityCapabilitiesLoading={securityCapabilitiesLoading}
                setSelectedShowBy={setSelectedShowBy}
                setSelectedShowByData={setSelectedShowByData}
            />


            <div>
                {priceCalculatorState.tabsData[priceCalculatorState.activeTab]?.ScenarioHeader?.PackageType_Enum === 0 && (
                    <PerSeatBlock
                        data={priceCalculatorState.tabsData[priceCalculatorState.activeTab]}
                        discount={discount}
                        switchVal={switchVal}
                        packageDetails={packageDetails}
                        contribLoading={contribLoading}
                        isMspClientView={isMspClientView}
                        showContribReset={showContribReset}
                        contributionValue={contributionValue}
                        switchIncludedTaxVal={switchIncludedTaxVal}
                        updatePackageMappedPrice={updatePackageMappedPrice}
                        setDiscount={setDiscount}
                        setContribLoading={setContribLoading}
                        getPackageDetails={getPackageDetails}
                        setShowContribReset={setShowContribReset}
                        resetAssetSeatDataApi={resetAssetSeatDataApi}
                        updateSeatAssetDataApi={updateSeatAssetDataApi}
                        setIsFromAddContribTable={setIsFromAddContribTable}
                        setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                        setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                        setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                        setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                        setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                    />
                )}

                {priceCalculatorState.tabsData[priceCalculatorState.activeTab]?.ScenarioHeader?.PackageType_Enum === 1 && (
                    <PerAssetBlock
                        data={priceCalculatorState.tabsData[priceCalculatorState.activeTab]}
                        discount={discount}
                        switchVal={switchVal}
                        packageDetails={packageDetails}
                        contribLoading={contribLoading}
                        isMspClientView={isMspClientView}
                        showContribReset={showContribReset}
                        contributionValue={contributionValue}
                        switchIncludedTaxVal={switchIncludedTaxVal}
                        setDiscount={setDiscount}
                        setContribLoading={setContribLoading}
                        getPackageDetails={getPackageDetails}
                        setIsSmthOverriden={setIsSmthOverriden}
                        setShowContribReset={setShowContribReset}
                        resetAssetSeatDataApi={resetAssetSeatDataApi}
                        updateSeatAssetDataApi={updateSeatAssetDataApi}
                        updatePackageMappedPrice={updatePackageMappedPrice}
                        setIsFromAddContribTable={setIsFromAddContribTable}
                        setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                        setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                        setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                        setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                        setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                    />
                )}
            </div>

            <div className="border-bottom py-4"></div>

            <IncludedFeaturesBlock
                packageDetails={packageDetails}
            />


            <ServiceComponentsBlock
                componentsData={componentsData}
            />

            <AlignmentToFrameworkBlock
                featuresLoading={featuresLoading}
                featuresData={featuresData}
            />
        </div>
    );
};

export default PackagesDetailsBlock;

import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Nav, Tab } from "react-bootstrap";
import "../secOps.scss";
import HighgroundDatePicker from "../../../test/TestComponent";
import {
  ContributorGetAPIData,
  ContributorPostData,
  ContributorPostJsonData,
  getAPIData,
  postData,
  postJsonData,
} from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { makeGroup } from "../../../AttackSurface/AssetsList/WorkStations/WorkstationModal";
import Loader from "../../../Common/loader";
import axios from "axios";
import moment from "moment";
import { UploadFileCircularProgress } from "../SecOps";
import { convertDateFormat } from "../../../../Utils/CommonFunctions";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { NO_ACCESS_TEXT, accessStateValue } from "../../../App/App";
import { DeleteModal } from "../../../DeleteModal/DeleteModal";
import { auditUpdate } from "../audit/AuditHeader";

const AddEditCertificateModal = ({
  show,
  hideModal,
  refreshData,
  selectedCertificateId,
  selectedUserId,
  categoryId,
  status,
  questionId,
  employeeData,
  headerName,
  isContributorView,
  passedUserId,
  passedOptionEnum99,
  objId_EmployeeCertification,
  isAudit = false
}) => {
  const uploadRef = React.useRef();
  const [saving, setSaving] = useState(false);
  const { authTokens } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);

  const [certificateId, setCertificateId] = useState(null);
  const [userId, setUserId] = useState(null);

  const [errorData, setErrorData] = useState({
    errMessage: "",
    errIcon: "",
  });

  useEffect(() => {
    setUserId(selectedUserId);
  }, [selectedUserId]);

  useEffect(() => {
    setCertificateId(selectedCertificateId);
  }, [selectedCertificateId]);

  // certificates
  // details
  const [page, setPage] = useState();
  const [categoryWiseCertificatesList, setCategoryWiseCertificatesList] =
    useState([]);

  useEffect(() => {
    if (categoryId !== null) {
      setPage("certificates");
    } else {
      setPage("details");
    }
  }, [categoryId, show]);

  useEffect(() => {
    if (show && page) {
      setLoading(true);
      if (categoryId !== null && page === "certificates") {
        getCategoryWiseCertificatesList();
      } else {
        getCertificatesList();
      }
      if (employeeData?.memberName) {
        setEmployeeName(employeeData.memberName);
        setEmployeePosition(employeeData.position);
        setUserId(employeeData.userId);
      }
    }
    if (show === false) {
      setPage(null);
      resetModal();
    }
  }, [show, page, categoryId, employeeData]);

  const [certificateName, setCertificateName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeePosition, setEmployeePosition] = useState("");
  const [certificatesList, setCertificatesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [loadingCertificates, setLoadingCertificates] = useState(false);
  const [newTypeLoading, setNewTypeLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingFileList, setUploadingFileList] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const [certificateData, setCertificateData] = useState({
    certificationType: null,
    certificateId: "",
    certificationInProgress: false,
    dateCertified: "",
    expiryDate: "",
    expectedCompletionDate: "",
    linkUrl: "",
    objId_Accreditation: 0,
  });

  function resetModal() {
    setCertificateData({
      certificationType: null,
      certificateId: "",
      certificationInProgress: false,
      dateCertified: "",
      expiryDate: "",
      expectedCompletionDate: "",
      linkUrl: "",
      objId_Accreditation: 0,
    });
    setCertificateName("");
    setEmployeeName("");
    setEmployeePosition("");
    setUserId();
    setUploadedFiles([]);
    setSelectedTab(0);
  }

  async function getCategoryWiseCertificatesList() {
    if (isContributorView) {
      await ContributorGetAPIData("810", authTokens, passedOptionEnum99 ? {
        optionEnum1: categoryId ?? 0,
        optionEnum99: passedOptionEnum99
      } : {
        optionEnum1: categoryId ?? 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setCategoryWiseCertificatesList(response?.items);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData("810", authTokens, { optionEnum1: categoryId ?? 0 })
        .then((response) => {
          if (response?.mr?.Success) {
            setCategoryWiseCertificatesList(response?.items);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }
  async function getCertificationData() {
    if (isContributorView) {
      await ContributorGetAPIData("808", authTokens, passedOptionEnum99 ? {
        optionEnum1: certificateId,
        optionEnum2: questionId ? questionId : 0,
        optionEnum99: passedOptionEnum99
      } : {
        optionEnum1: certificateId,
        optionEnum2: questionId ? questionId : 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            fillModalData(response?.detail);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData("808", authTokens, passedOptionEnum99 ? {
        optionEnum1: certificateId,
        optionEnum2: questionId ? questionId : 0,
        optionEnum99: passedOptionEnum99
      } : {
        optionEnum1: certificateId,
        optionEnum2: questionId ? questionId : 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            fillModalData(response?.detail);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }
  async function handleSave() {
    setSaving(true);
    if (isContributorView) {
      if (status === "Assessment" && !employeeName) {
        CallToast("Employee must exist!", false, false, "v2style");
        setSaving(false);
      } else {
        await ContributorPostJsonData(authTokens, "Certification/Upsert", passedOptionEnum99 ? { 
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          objId_Certification: certificateId ?? 0,
          objId_EmployeeCertification: objId_EmployeeCertification,  
          isInProgress: certificateData?.certificationInProgress ?? false,
          issueDate: convertDateFormat(certificateData?.issueDate ?? ""),
          expiryDate: convertDateFormat(certificateData?.expiryDate ?? ""),
          expectedCompletionDate: convertDateFormat(
            certificateData?.expectedCompletionDate ?? ""
          ),
          certificateID: certificateData.certificateId,
          certificateLinkUrl: certificateData?.linkUrl,
          objIds_FileUpload:
            uploadedFiles?.map((obj) => obj.objId_FileUpload).join(",") ?? 0,
          userId: userId ?? selectedUserId,
          objId_TrainingCertType:
            certificateData?.certificationType?.objId_TrainingCertType ?? 0,
          objId_SendFormQuestionnaire: questionId ?? 0,
          position: employeePosition,
          memberName: employeeName,
          optionEnum99: passedOptionEnum99
        } : {
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          objId_Certification: certificateId ?? 0,
          objId_EmployeeCertification: objId_EmployeeCertification, 
          isInProgress: certificateData?.certificationInProgress ?? false,
          issueDate: convertDateFormat(certificateData?.issueDate ?? ""),
          expiryDate: convertDateFormat(certificateData?.expiryDate ?? ""),
          expectedCompletionDate: convertDateFormat(
            certificateData?.expectedCompletionDate ?? ""
          ),
          certificateID: certificateData.certificateId,
          certificateLinkUrl: certificateData?.linkUrl,
          objIds_FileUpload:
            uploadedFiles?.map((obj) => obj.objId_FileUpload).join(",") ?? 0,
          userId: userId ?? selectedUserId,
          objId_TrainingCertType:
            certificateData?.certificationType?.objId_TrainingCertType ?? 0,
          objId_SendFormQuestionnaire: questionId ?? 0,
          position: employeePosition,
          memberName: employeeName,
        })
          .then((response) => {
            if (response?.Success) {
              CallToast("Saved successfully", false, false, "v2style");
              if (isAudit) {
                auditUpdate(
                  authTokens, 
                  objId_EmployeeCertification, 
                  1, 
                  certificateId == 0 ? 0 : 1, 
                  certificateId == 0 ? 0 : 1
                )
              }
              hideModal();
              setShowDeleteButton(false);
              if (refreshData) {
                refreshData();
              }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            setSaving(false);
          })
          .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
            setSaving(false);
          });
      }
    } else {
      if (status === "Assessment" && !employeeName) {
        CallToast("Employee must exist!", false, false, "v2style");
        setSaving(false);
      } else {
        await postJsonData(authTokens, "Certification/Upsert", passedOptionEnum99 ? {  
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          objId_Certification: certificateId ?? 0,
          objId_EmployeeCertification: objId_EmployeeCertification,
          isInProgress: certificateData?.certificationInProgress ?? false,
          issueDate: convertDateFormat(certificateData?.issueDate ?? ""),
          expiryDate: convertDateFormat(certificateData?.expiryDate ?? ""),
          expectedCompletionDate: convertDateFormat(
            certificateData?.expectedCompletionDate ?? ""
          ),
          certificateID: certificateData.certificateId,
          certificateLinkUrl: certificateData?.linkUrl,
          objIds_FileUpload:
          uploadedFiles?.map((obj) => obj.objId_FileUpload).join(",") ?? 0,
          userId: userId ?? selectedUserId,
          objId_TrainingCertType:
            certificateData?.certificationType?.objId_TrainingCertType ?? 0,
          objId_SendFormQuestionnaire: questionId ?? 0,
          position: employeePosition,
          memberName: employeeName,
          optionEnum99: passedOptionEnum99
        } : {
          // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          objId_Certification: certificateId ?? 0,
          objId_EmployeeCertification: objId_EmployeeCertification,
          isInProgress: certificateData?.certificationInProgress ?? false,
          issueDate: convertDateFormat(certificateData?.issueDate ?? ""),
          expiryDate: convertDateFormat(certificateData?.expiryDate ?? ""),
          expectedCompletionDate: convertDateFormat(
            certificateData?.expectedCompletionDate ?? ""
          ),
          certificateID: certificateData.certificateId,
          certificateLinkUrl: certificateData?.linkUrl,
          objIds_FileUpload:
            uploadedFiles?.map((obj) => obj.objId_FileUpload).join(",") ?? 0,
          // userId: /*userId*/ selectedUserId,
          userId: userId ?? selectedUserId,
          objId_TrainingCertType:
            certificateData?.certificationType?.objId_TrainingCertType ?? 0,
          objId_SendFormQuestionnaire: questionId ?? 0,
          position: employeePosition,
          memberName: employeeName,
        })
          .then((response) => {
            if (response?.Success) {
              CallToast("Saved successfully", false, false, "v2style");
              if (isAudit) {
                auditUpdate(
                  authTokens, 
                  objId_EmployeeCertification,
                  1, 
                  certificateId == 0 ? 0 : 1, 
                  certificateId == 0 ? 0 : 1
                )
              }
              hideModal();
              setShowDeleteButton(false);
              if (refreshData) {
                refreshData();
              }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            setSaving(false);
          })
          .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
            setSaving(false);
          });
      }
    }
  }

  async function getCertificatesList(_searchString = "", intialLoad = true) {
    setLoadingCertificates(true);
    if (isContributorView) {
      return new Promise((resolve, reject) => {
        ContributorGetAPIData("807", authTokens, passedOptionEnum99 ? {
          optionEnum1: 3,
          optionStrEnum1: _searchString === "" ? "" : _searchString,
          optionEnum99: passedOptionEnum99
        } : {
          optionEnum1: 3,
          optionStrEnum1: _searchString === "" ? "" : _searchString,
        })
          .then((response) => {
            setLoadingCertificates(false);
            if (response?.mr?.Success) {
              let updated_list = makeGroup(response?.items, "category");
              setCertificatesList(updated_list);
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }

            if (
              certificateId === 0 ||
              certificateId === null ||
              certificateId === undefined
            ) {
              setLoading(false);
            } else {
              if (intialLoad) {
                setShowDeleteButton(true);
                getCertificationData();
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            CallToast("Something went wrong", false, false, "v2style");
            setLoadingCertificates(false);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        getAPIData("807", authTokens, passedOptionEnum99 ? {
          optionEnum1: 3,
          optionStrEnum1: _searchString === "" ? "" : _searchString,
          optionEnum99: passedOptionEnum99
        } : {
          optionEnum1: 3,
          optionStrEnum1: _searchString === "" ? "" : _searchString,
        })
          .then((response) => {
            setLoadingCertificates(false);
            if (response?.mr?.Success) {
              let updated_list = makeGroup(response?.items, "category");
              setCertificatesList(updated_list);
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }

            if (
              certificateId === 0 ||
              certificateId === null ||
              certificateId === undefined
            ) {
              setLoading(false);
            } else {
              if (intialLoad) {
                setShowDeleteButton(true);
                getCertificationData();
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            CallToast("Something went wrong", false, false, "v2style");
            setLoadingCertificates(false);
          });
      });
    }
  }

  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete.",
    deleteSentQuestionnaire: () => {},
  });

  const handleInputChange = (event) => {
    const { value } = event.target;
    setCertificateName(value);

    // Clear the previous timeout
    // clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    // let _timeoutId = setTimeout(() => {
    getCertificatesList(value, false);
    // }, 800);
    // setTimeoutId(_timeoutId);
  };

  async function addCertificateType(_value) {
    let value = _value;
    getCertificatesList("", false);
    if (!isContributorView) {
      await postData(authTokens, "Certification/AddCertficateType", passedOptionEnum99 ? {
        value: _value,
        optionEnum99: passedOptionEnum99
      } : {
        value: _value,
      })
        .then((response) => {
          setCertificateData({
            ...certificateData,
            certificationType: {
              objId_TrainingCertType: response?.EntityId,
              heading: value,
            },
          });
          setCertificateName("");
          setNewTypeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setNewTypeLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await ContributorPostData(authTokens, "Certification/AddCertficateType", passedOptionEnum99 ? {
        value: _value,
        optionEnum99: passedOptionEnum99
      } : {
        value: _value,
      })
        .then((response) => {
          setCertificateData({
            ...certificateData,
            certificationType: {
              objId_TrainingCertType: response?.EntityId,
              heading: value,
            },
          });
          setCertificateName("");
          setNewTypeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setNewTypeLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  const handleUpload = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const fileSizeInMB = file?.size / (1024 * 1024);
    if (fileSizeInMB > 10) {
      setErrorData({
        errMessage: "File size should be less than 10 MB",
        errIcon: "/images/msp/uploading-error-icon.svg",
      });
      return;
    }

    setUploadingFileList(event.target.files);
    const allowedFileTypes = [
      "application/pdf",
      "image/jpeg",
      "image/bmp",
      "image/png",
    ];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const formData = new FormData();
        formData.append("UploadFile", file);
        formData.append("fileType", 1);
        formData.append(
          "accessKey",
          JSON.parse(localStorage.getItem("user_MessageObj"))
        );
        passedOptionEnum99 && formData.append("optionEnum99", passedOptionEnum99);
        formData.append("objId_SendFormQuestionnaire", questionId ?? 0);
        formData.append("calledFrom", 0);
        formData.append("objId", certificateId ?? 0);

        try {
          let response = null;
          if (isContributorView) {
            formData.append(
              "key",
              JSON.parse(localStorage.getItem("user_MessageObj"))
            );
            response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/HGIR/SecOps_FileUpload`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${authTokens}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentCompleted);
                },
              }
            );
          } else {
            formData.append(
              "accessKey",
              JSON.parse(localStorage.getItem("user_MessageObj"))
            );
            response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/CC_API/SecOps_FileUpload`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${authTokens}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentCompleted);
                },
              }
            );
          }

          if (response?.data?.mr?.Success) {
            CallToast("Uploaded successfully", false, false, "v2style");
            if (certificateId > 0) {
              setUploadedFiles(response?.data?.files);
            } else {
              if (uploadedFiles && uploadedFiles[0]) {
                const updData = uploadedFiles;
                updData.push(response?.data?.files[0]);
                setUploadedFiles(updData);
              } else {
                setUploadedFiles(response?.data?.files);
              }
              if (isAudit) {
                auditUpdate(
                  authTokens, 
                  objId_EmployeeCertification, 
                  1, 
                  certificateId == 0 ? 0 : 1, 
                  certificateId == 0 ? 0 : 1
                )
              }
            }
            setErrorData({ ...errorData, errMessage: "" });
          } else {
            console.error("Something went wrong");
          }

          setUploadProgress(0);
        } catch (error) {
          console.error("Upload failed:", error);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.error(
        "Invalid file type. Please upload a PDF, JPG, BMP, or PNG file."
      );
      setErrorData({
        errMessage:
          "Invalid file type. Please upload a PDF, JPG, BMP, or PNG file.",
        errIcon: "/images/msp/uploading-error-icon.svg",
      });
    }
  };

  const handleDeleteFile = async (fileId) => {
    const formData = new FormData();
    formData.append(
      "accessKey",
      JSON.parse(localStorage.getItem("user_MessageObj"))
    );
    formData.append("objId_SendFormQuestionnaire", questionId ?? 0);
    formData.append("objId_FileUpload", fileId);
    formData.append("objId", certificateId);

    try {
      let response = null;

      response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/SecOps_DeleteFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }
      );

      if (response?.Success) {
        CallToast("Deleted successfully", false, false, "v2style");
        if (isAudit) {
          auditUpdate(
            authTokens, 
            objId_EmployeeCertification, 
            1, 
            certificateId == 0 ? 0 : 1, 
            certificateId == 0 ? 0 : 1
          )
        }
        setErrorData({ ...errorData, errMessage: "" });
      } else {
        console.error("Something went wrong");
      }

      setUploadProgress(0);
    } catch (error) {
      console.error("Upload failed:", error);
      if (error?.code === "ERR_NETWORK") {
        setErrorData({
          errMessage:
            "There was a connection error during uploading. Please try again.",
          errIcon: "/images/msp/wifi-error.svg",
        });
      }
    }
  };

  function fillModalData(_response) {
    let updated_data = { ...certificateData };
    if (_response.certificationType) {
      updated_data.certificationType = _response.certificationType;
    }

    updated_data.certificationInProgress = _response.isInProgress;
    updated_data.certificateId = _response.certificateID ?? "";

    updated_data.linkUrl = _response.certificateLinkUrl ?? "";

    if (_response.files) {
      setUploadedFiles(_response.files);
    }
    if (_response.issueDate) {
      updated_data.issueDate = moment(_response.issueDate).format("DD/MM/YYYY");
    }
    if (_response.expiryDate) {
      updated_data.expiryDate = moment(_response.expiryDate).format(
        "DD/MM/YYYY"
      );
    }

    if (_response.expectedCompletionDate) {
      updated_data.expectedCompletionDate = moment(
        _response.expectedCompletionDate
      ).format("DD/MM/YYYY");
    }

    setCertificateData(updated_data);
  }

  async function checkValidation() {
    let errors = 0;
    let updated_data = { ...certificateData };
    try {
      if (!certificateData.certificationType) {
        errors = errors + 1;

        updated_data.certificationTypeError = true;
      }
      if (status === "Assessment") {
        if (employeeName.trim() == "") {
          errors = errors + 1;

          updated_data.employeeNameError = true;
        }
        if (employeePosition.trim() == "") {
          errors = errors + 1;

          updated_data.employeePositionError = true;
        }
      }

      if (certificateData.certificationInProgress) {
        if (
          !certificateData.expectedCompletionDate &&
          certificateData.certificationInProgress
        ) {
          errors = errors + 1;

          updated_data.expectedCompletionDateError = true;
          setCertificateData(updated_data);

          return errors;
        }
        setCertificateData(updated_data);
        return errors;
      }

      if (!certificateData.issueDate) {
        errors = errors + 1;

        updated_data.issueDateError = true;
      }
      if (!certificateData.expiryDate) {
        errors = errors + 1;

        updated_data.expiryDateError = true;
      }

      if (
        !certificateData.certificateId ||
        certificateData.certificateId.trim() == ""
      ) {
        errors = errors + 1;

        updated_data.certificateIdError = true;
      }

      setCertificateData(updated_data);
    } catch (err) {
      console.log(err, "errrrr");
    }

    return errors;
  }

  function thisDateNextYear(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year + 1, month - 1, day);
    return date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
      ? `${date.getDate()}/${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}/${date.getFullYear()}`
      : (() => {
          let nextDate = new Date(year + 1, month - 1, day);
          while (isNaN(nextDate.getTime())) {
            day++;
            nextDate = new Date(year + 1, month - 1, day);
          }
          return `${nextDate.getDate()}/${String(
            nextDate.getMonth() + 1
          ).padStart(2, "0")}/${nextDate.getFullYear()}`;
        })();
  }

  async function deleteCertification(certificationId) {
    setSaving(true);
    await postData(authTokens, "Certification/ArchiveOrDelete", {
      objId_Certification: certificationId,
      userId: selectedUserId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Certification deleted successfully",
            true,
            false,
            "v2style"
          );
          hideModal();
          setShowDeleteButton(false);
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [placeholder, setPlaceholder] = useState("Enter your link here...");
  const handleInput = (e) => {
    const textContent = e.target.textContent;
    setCertificateData({
      ...certificateData,
      linkUrl: textContent.trim(), // Trim to remove leading/trailing spaces
    });
    if (placeholder !== "" && textContent !== "") {
      setPlaceholder("");
    }
  };

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="secops-cert-modal "
      className="   "
      aria-labelledby=" "
      onHide={() => {
        hideModal();
        setShowDeleteButton(false);
      }}
      centered
      scrollable
    >
      <Modal.Header className="d-flex align-items-center justify-content-between">
        <div
          onClick={() => {
            setPage("certificates");
          }}
        >
          {categoryId !== null && page === "details" && (
            <img
              src="/images/supply-chain/chevron-left.svg"
              alt=""
              className="pointer"
            />
          )}
        </div>
        <div className="mb-0 font-bold-14 title" style={{ fontWeight: "600" }}>
          {categoryId !== null
            ? renderHeaderIfCategoryExist(categoryId)
            : headerName
            ? headerName
            : "Add training certificate"}
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
            setShowDeleteButton(false);
            setUploadedFiles([]);
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body
        className="transparent-scroller"
        style={{
          paddingRight: "24px",
          paddingLeft: "24px",
          paddingTop: "16px",
        }}
      >
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {page === "certificates" && (
              <>
                <div>
                  {categoryWiseCertificatesList?.map(
                    (certificate, certificateIndex) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between mb-2 bg-hover-grey radius-4 pointer p-2 pl-3"
                          onClick={() => {
                            setUserId(certificate?.userDetail?.userId);
                            setCertificateId(certificate?.objId_Certification);
                            setPage("details");
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center overlap_parent">
                              {certificate?.iconDetail?.showInitials ? (
                                <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                  <ImageWithInitials
                                    initials={certificate?.iconDetail?.initials}
                                    width={33}
                                    background={"purple"}
                                  />
                                  {certificate?.iconDetail?.statusIcon && (
                                    <img
                                      width={13}
                                      src={
                                        "/images/msp/" +
                                        certificate?.iconDetail?.statusIcon
                                      }
                                      className="rounded-full overlapping_icon"
                                      alt=""
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                  <img
                                    width={33}
                                    src={`/images/msp/framework-certificates/${certificate?.iconDetail?.icon}`}
                                    className="rounded-full"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/msp/default-cert-icon.svg";
                                    }}
                                  />
                                  {certificate?.iconDetail?.statusIcon && (
                                    <img
                                      width={13}
                                      src={
                                        "/images/msp/" +
                                        certificate?.iconDetail?.statusIcon
                                      }
                                      className="rounded-full overlapping_icon"
                                      alt=""
                                    />
                                  )}
                                </div>
                              )}
                              <div className="ml-3">
                                <div className="f-500">
                                  {certificate?.name}
                                  &nbsp; &nbsp;
                                  {certificate?.status && (
                                    <span
                                      style={{
                                        color: certificate?.status?.foreColor,
                                      }}
                                    >
                                      {certificate?.status?.text}
                                    </span>
                                  )}
                                </div>
                                <div className="f-darkgrey">
                                  {certificate?.points?.map((point, idx) => (
                                    <span>
                                      {idx > 0 ? " • " : ""} {point}
                                    </span>
                                  ))}
                                  {" • "}{" "}
                                  <span>
                                    {certificate?.userDetail?.showInitials ? (
                                      <ImageWithInitials
                                        initials={
                                          certificate?.userDetail?.initials
                                        }
                                        width={16}
                                        background={"purple"}
                                      />
                                    ) : (
                                      <img
                                        width={16}
                                        src={certificate?.userDetail?.avatar}
                                        alt=""
                                        className="rounded-full"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/user-logo.svg";
                                        }}
                                      />
                                    )}
                                    <span className="ml-2 f-500">
                                      {certificate?.userDetail?.fullName}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <img
                              src="/images/attack-surface/chevron-right.svg"
                              alt=""
                              className=""
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}

            {page === "details" && (
              <>
                {status === "Assessment" && (
                  <>
                    <div className="f-darkgrey f-500 my-2 mt-4">Employee</div>

                    <div className="w-100">
                      <div
                        className={`${
                          certificateData?.employeeNameError
                            ? "input-error"
                            : ""
                        }
                          d-flex align-items-center radius-4 grey-border p-2  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
                      >
                        <img
                          src={`/images/attack-surface/attack-modal-user-icon.svg`}
                          alt=""
                        />

                        <input
                          type="text"
                          className="bg-transparent w-100 border-none pl-2"
                          placeholder=" Enter employee..."
                          value={employeeName}
                          disabled={employeeData?.memberName ? true : false}
                          onChange={(e) => {
                            if (!employeeData?.memberName) {
                              setEmployeeName(e.target.value);
                              // handleInputChange(e);
                              setCertificateData({
                                ...certificateData,
                                employeeNameError: false,
                              });
                            }
                          }}
                        />
                        {loadingCertificates && (
                          <div className="msp-service-update-loader mr-1"></div>
                        )}
                        {!employeeData?.memberName && employeeName && (
                          <img
                            alt=" "
                            src="/images/attack-surface/cross-icon.svg"
                            className="pointer"
                            onClick={() => {
                              setCertificateName("");
                              // setCertificateData({
                              //   ...certificateData,
                              //   certificationType: null,
                              //   certificationTypeError: false,
                              // });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center w-100"
                      style={{ marginBottom: "24px", marginTop: "16px" }}
                    >
                      <div className="w-30 f-500 f-grey">Job Title</div>
                      <div className="w-70  ">
                        <div
                          className={` ${
                            certificateData.employeePositionError
                              ? "input-error"
                              : ""
                          }  d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                        >
                          <input
                            type="text"
                            className="bg-transparent w-100 border-none pl-2"
                            placeholder="Type"
                            value={employeePosition}
                            disabled={employeeData?.memberName ? true : false}
                            onChange={(e) => {
                              if (!employeeData?.position) {
                                setEmployeePosition(e.target.value);

                                setCertificateData({
                                  ...certificateData,
                                  employeePositionError: false,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <Dropdown className="transparennt-wrapper-dropdown">
                  <Dropdown.Toggle className="w-100">
                    <div
                      className={`

                  ${
                    certificateData?.certificationTypeError ? "input-error" : ""
                  }

                  d-flex align-items-center radius-4 grey-border p-2  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
                    >
                      <div className="f-darkgrey">Name:</div>

                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Enter or choose certification type..."
                        value={
                          certificateData?.certificationType?.heading ??
                          certificateName
                        }
                        onChange={(e) => {
                          handleInputChange(e);
                          setCertificateData({
                            ...certificateData,
                            certificationType: null,
                            certificationTypeError: false,
                          });
                        }}
                      />
                      {loadingCertificates && (
                        <div className="msp-service-update-loader mr-1"></div>
                      )}
                      {!loadingCertificates &&
                        certificateData.certificationType && (
                          <img
                            alt=" "
                            src="/images/attack-surface/cross-icon.svg"
                            className="pointer"
                            onClick={() => {
                              setCertificateName("");
                              setCertificateData({
                                ...certificateData,
                                certificationType: null,
                                certificationTypeError: false,
                              });
                            }}
                          />
                        )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100 p-2">
                    <div
                      className="transparent-scroller"
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      {certificatesList?.map((group, groupIndex) => {
                        return (
                          <>
                            <div className="f-12 f-darkgrey ml-2">
                              {group?.groupName}
                            </div>

                            {group?.data?.map(
                              (certificate, certificateIndex) => {
                                return (
                                  <div
                                    className="d-flex align-items-center my-1 pointer"
                                    onClick={(e) => {
                                      e.target
                                        .closest(".dropdown-menu")
                                        .classList.remove("show");
                                      setCertificateData({
                                        ...certificateData,
                                        certificationType: certificate,
                                        certificationTypeError: false,
                                      });
                                    }}
                                  >
                                    {certificate?.iconDetail?.showInitials ? (
                                      <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                        <ImageWithInitials
                                          initials={
                                            certificate?.iconDetail?.initials
                                          }
                                          width={33}
                                          background={"purple"}
                                        />
                                      </div>
                                    ) : (
                                      <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                        <img
                                          width={33}
                                          src={`/images/msp/framework-certificates/${certificate?.iconDetail?.icon}`}
                                          className="rounded-full"
                                          alt=""
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "/images/msp/default-cert-icon.svg";
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div className="ml-3">
                                      <div className="f-500 f-grey">
                                        {certificate?.heading}
                                      </div>
                                      <div className="f-12 f-darkgrey">
                                        {certificate?.name}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        );
                      })}
                    </div>

                    {certificateName && (
                      <div
                        className="d-flex align-items-center justify-content-between f-blue my-2 pointer"
                        onClick={() => {
                          setNewTypeLoading(true);
                          addCertificateType(certificateName);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/msp/blue-plus.svg"
                            alt=""
                            className="mr-2"
                          />
                          Add "{certificateName}"
                        </div>
                        <div>
                          {newTypeLoading && (
                            <div className="msp-service-update-loader mr-1"></div>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="f-darkgrey f-500 my-2 mt-4">Details</div>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">
                    Certification In Progress
                  </div>
                  <div className="w-50 pl-3-5rem">
                    <Form.Group controlId={"check" + "" + 99} className="">
                      <Form.Check
                        tabIndex={-1}
                        type="switch"
                        id={"switch-ques1" + "" + 99 + Math.random() * 1000000}
                        style={{
                          transform: "scale(1.2)",
                          paddingLeft: "0.75rem",
                        }}
                        className={`m-0 scale-switch switch-shadow-none ${true}  `}
                        checked={
                          certificateData?.certificationInProgress ?? false
                        }
                        label=""
                        disabled={false}
                        onChange={(e) => {
                          setCertificateData({
                            ...certificateData,
                            certificationInProgress: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {certificateData?.certificationInProgress ? (
                  <div className="d-flex align-items-center my-2 w-100">
                    <div className="w-30 f-500 f-grey">
                      Expected Completion Date
                    </div>
                    <div className="w-70  ">
                      <HighgroundDatePicker
                        selected={certificateData?.expectedCompletionDate ?? ""}
                        placeholder={"Choose "}
                        icon={"/images/attack-surface/date-icon.svg"}
                        onChange={(e) => {
                          setCertificateData({
                            ...certificateData,
                            expectedCompletionDate: e,
                            expectedCompletionDateError: false,
                          });
                        }}
                        id={"bhfgiibvwmv"}
                        isError={certificateData?.expectedCompletionDateError}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center my-2 w-100">
                      <div className="w-30 f-500 f-grey">Certificate ID</div>
                      <div className="w-70  ">
                        <div
                          className={` ${
                            certificateData.certificateIdError
                              ? "input-error"
                              : ""
                          }  d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                        >
                          <input
                            type="text"
                            className="bg-transparent w-100 border-none pl-2"
                            placeholder="Type"
                            value={certificateData?.certificateId ?? ""}
                            onChange={(e) => {
                              setCertificateData({
                                ...certificateData,
                                certificateId: e.target.value,
                                certificateIdError: false,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center my-2 w-100">
                      <div className="w-30 f-500 f-grey">Date Certified</div>
                      <div className="w-70  ">
                        <HighgroundDatePicker
                          selected={certificateData?.issueDate ?? ""}
                          placeholder={"Choose "}
                          icon={"/images/attack-surface/date-icon.svg"}
                          onChange={(e) => {
                            setCertificateData({
                              ...certificateData,
                              issueDate: e,
                              issueDateError: false,
                              expiryDate: thisDateNextYear(e),
                              expiryDateError: false,
                            });
                          }}
                          id={"bhfgwertbvwmv"}
                          isError={certificateData.issueDateError}
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center my-2 w-100">
                      <div className="w-30 f-500 f-grey">Expiry Date</div>
                      <div className="w-70  ">
                        <HighgroundDatePicker
                          selected={certificateData?.expiryDate ?? ""}
                          placeholder={"Choose  "}
                          icon={"/images/attack-surface/date-icon.svg"}
                          onChange={(e) => {
                            setCertificateData({
                              ...certificateData,
                              expiryDate: e,
                              expiryDateError: false,
                            });
                          }}
                          id={"bhfgvwrrver"}
                          isError={certificateData?.expiryDateError}
                        />
                      </div>
                    </div>

                    <Tab.Container
                      onSelect={(v) => {
                        setSelectedTab(Number(v));
                      }}
                      id="left-tabs-example"
                      defaultActiveKey={selectedTab}
                    >
                      <div
                        className="scenarioName bg-white d-flex align-items-start    pt-2  "
                        style={{ top: "101px", zIndex: 2 }}
                        id="navHeader"
                      >
                        <Nav.Item key={0} className={"  mt-2"}>
                          <Nav.Link
                            eventKey={0}
                            className={`${selectedTab === 0 && "active"} mt-`}
                            onClick={() => {}}
                          >
                            Upload File
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item key={1} className={"  mt-2"}>
                          <Nav.Link
                            eventKey={1}
                            className={`${selectedTab === 1 && "active"} mt-`}
                            onClick={() => {}}
                          >
                            Link
                          </Nav.Link>
                        </Nav.Item>
                      </div>

                      <Tab.Content className="mt-4">
                        <Tab.Pane eventKey={0}>
                          {errorData?.errMessage !== "" && (
                            <div
                              onClick={() => {}}
                              className="d-flex align-items-center justify-content-between bg-warning-light p-3 radius-8 mb-2 "
                            >
                              <div className="d-flex align-items-center f-black f-500 mr-2">
                                <img
                                  alt=""
                                  src={errorData?.errIcon}
                                  className="mr-2"
                                />

                                {errorData?.errMessage}
                              </div>
                              <div
                                className="d-flex align-items-center f-black f-500 pointer mr-2"
                                onClick={() => {
                                  setErrorData({
                                    ...errorData,
                                    errMessage: "",
                                  });
                                }}
                              >
                                Dismiss
                              </div>
                            </div>
                          )}

                          {uploadedFiles &&
                            uploadedFiles[0] &&
                            uploadedFiles?.map((uploadedFile) => {
                              return (
                                <div className="radius-4 bg-grey p-3  d-flex align-items-center justify-content-between mb-2">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="/images/msp/upload-icon-placeholder.svg"
                                      alt=""
                                      className="mr-2"
                                    />
                                    <div>
                                      <div className="f-500">
                                        {uploadedFile?.fileName}{" "}
                                      </div>
                                      <div className="f-darkgrey">
                                        {uploadedFile?.fileSize}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-item-center">
                                    <img
                                      src="/images/msp/policy-download-icon.svg"
                                      alt=""
                                      className="mx-2 pointer"
                                      onClick={() => {
                                        downloadFile(
                                          uploadedFile?.fullPath,
                                          uploadedFile?.fileName
                                        );
                                      }}
                                    />
                                    <img
                                      src="/images/actioncenter/delete.svg"
                                      alt=""
                                      className="mx-2 pointer"
                                      onClick={() => {
                                        let updData = uploadedFiles;
                                        updData = updData.filter(
                                          (obj) =>
                                            obj.objId_FileUpload !==
                                            uploadedFile?.objId_FileUpload
                                        );
                                        setUploadedFiles(updData);
                                        handleDeleteFile(
                                          uploadedFile?.objId_FileUpload
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}

                          <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center w-100 p-3 mt-3 mb-3">
                            {uploadProgress > 0 && uploadProgress < 100 ? (
                              <>
                                <UploadFileCircularProgress
                                  progress={100 - uploadProgress}
                                />

                                <div className="f-grey my-2 w-fit-content">
                                  {uploadingFileList?.[0]?.name}{" "}
                                </div>

                                <div className="f-darkgrey my-2 w-fit-content">
                                  Uploading... {uploadProgress}%
                                </div>

                                <Button
                                  className="hg-cancel-btn"
                                  onClick={() => {
                                    uploadRef.current.click();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <>
                                <img
                                  width={20}
                                  src="/images/msp/framework-upload-icon.svg"
                                  alt=""
                                />
                                <div className="f-grey mt-2 w-fit-content">
                                  Upload file or browse file on your computer
                                </div>
                                <div className="f-darkgrey my-2 w-fit-content">
                                  Format pdf, jpg, bmp, png
                                </div>
                                <Button
                                  className="hg-cancel-btn"
                                  onClick={() => {
                                    uploadRef.current.click();
                                  }}
                                >
                                  Browse file
                                </Button>
                              </>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={1}>
                          <div
                            className={`d-flex align-items-center radius-4 highlight-input-with-grey-border p-2 flex-grow-1 mr-1 ${true}`}
                          >
                            <div className="f-darkgrey">Link:</div>
                            <div
                              className={`pl-2 bg-transparent border-none flex-grow-1 ${
                                placeholder !== "" &&
                                certificateData?.linkUrl === "" &&
                                "f-darkgrey"
                              }`}
                              contentEditable="true"
                              style={{ width: "390px" }}
                              onClick={(e) => {
                                if (placeholder !== "") {
                                  setPlaceholder("");
                                }
                              }}
                              onInput={handleInput}
                              onBlur={() => {
                                if (certificateData.linkUrl === "") {
                                  setPlaceholder("Enter your link here...");
                                }
                              }}
                            >
                              {placeholder}
                            </div>
                            {/* <input
                              type="text"
                              placeholder="Enter your link here..."
                              value={certificateData?.linkUrl}
                              onChange={(e) => {
                                setCertificateData({
                                  ...certificateData,
                                  linkUrl: e.target.value,
                                });
                              }}
                              className="pl-2 bg-transparent border-none flex-grow-1"
                            /> */}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>

                    <div className="p-5" />
                  </>
                )}
              </>
            )}
          </>
        )}

        <input
          type="file"
          accept=".pdf,.doc,.dox,.txt"
          onChange={(e) => handleUpload(e)}
          className="d-none"
          ref={uploadRef}
          multiple
        />
      </Modal.Body>

      {page === "details" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          {showDeleteButton ? (
            <div className="d-flex align-items-center">
              <div
                className="p-2 pl-3 my-1 pointer f-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal({
                    showModal: true,
                    deleteMessage:
                      "You are about to delete a training certificate.",
                    deleteSentQuestionnaire: () =>
                      deleteCertification(certificateId),
                  });
                }}
              >
                Delete
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="d-flex align-items-center justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
                setShowDeleteButton(false);
              }}
            >
              Cancel
            </Button>

            <AttackSurfaceTooltip
              disabled={accessStateValue("AccessState_SecOps") !== 1}
              // bottomLeft={true}
              topLeft={true}
              content={<span>{NO_ACCESS_TEXT}</span>}
            >
              <Button
                className={`hg-blue-btn
           ${accessStateValue("AccessState_SecOps") === 1 ? " btn-disable" : ""}
           
           `}
                onClick={async () => {
                  let errors = await checkValidation();

                  if (errors > 0) {
                    let error_element =
                      document.getElementsByClassName(`input-error`);
                    if (error_element?.length > 0) {
                      error_element[0].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      });
                    }

                    return;
                  } else {
                    handleSave();
                  }
                }}
              >
                {accessStateValue("AccessState_SecOps") === 1 ? (
                  <img
                    alt=""
                    src="/images/attack-surface/small-lock.svg"
                    className="mr-2"
                  />
                ) : (
                  <></>
                )}{" "}
                {saving ? "Saving.." : "Save"}
              </Button>
            </AttackSurfaceTooltip>
          </div>
        </Modal.Footer>
      )}
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() => {
          setShowDeleteModal({
            showModal: false,
          });
          setShowDeleteButton(false);
        }}
        deleteMessage={showDeleteModal.deleteMessage}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />
    </Modal>
  );
};

export default AddEditCertificateModal;

function downloadFile(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function renderHeaderIfCategoryExist(_categoryId) {
  switch (_categoryId) {
    case 0:
      return (
        <div className="d-flex align-items-center f-black f-500">
          <img
            src="/images/msp/inprogress-black-clock.svg"
            alt=""
            className="mr-2"
          />{" "}
          <div className="pt-1">In Progress</div>
        </div>
      );
    case 1:
      return (
        <div className="d-flex align-items-center f-black f-500">
          <img
            src="/images/msp/black-exclammation.svg"
            alt=""
            className="mr-2"
          />{" "}
          <div className="pt-1">Expiring</div>
        </div>
      );
    case 2:
      return (
        <div className="d-flex align-items-center f-black f-500">
          <img
            src="/images/msp/black-exclammation.svg"
            alt=""
            className="mr-2"
          />{" "}
          <div className="pt-1">Expired</div>
        </div>
      );
    default:
      return "";
  }
}

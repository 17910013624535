import { Dropdown } from 'react-bootstrap';
import CrossIcon from '../icons/cross.icon';
import './searchFilterComponent.scss';
import { useEffect, useState } from 'react';
import useDatePickerData from '../Dashboard/msp-client-dashboard-v2/technology-spend/date-picker/date-picker.data';
import DateRangeFilterType from './selected-filter-types/date-range.filter-type';
import InputFilterType from './selected-filter-types/input.filter-type';
import CheckboxRangeFilterType from './selected-filter-types/checkbox-range.filter-type';
import NumberRangeFilterType from './selected-filter-types/number-range.filter-type';

/**
 * @param {Object} props
 * @param {Array<{ 
 *  label: string, 
 *  key: string, 
 *  value: string | number | { startDate: string, endDate: string } | Array<{ key: string, label: string, value: boolean }> 
 * | { prefix: string, value: Array<number> } | null, 
 *  type: "string" | "number" | "date-range" | "checkbox-range" | "number-range" | null 
 * }>} props.filterList
 * @param {function} props.onFilterChange
 * @param {function} props.onReset
 * @param {boolean} props.isPositionFixed
 * 
 * @returns 
 */
const SelectedFilters = ({ 
  filterList = [], 
  onFilterChange = () => {}, 
  onReset = () => {},
  isPositionFixed = false
}) => {
  const displayReset = filterList.length > 0 && filterList.some(filter => filter.isRemovable !== false);
  if (filterList.length === 0) return null;

  return (
    <div className="selected-filters-component d-flex justify-content-between" style={{ gap: '16px' }}>
      <div className='items-wrapper'>
        {filterList.map((filter, index) => (
          <FilterItem 
            key={`selected-filter-${filter.key}-${index}`}
            item={filter}
            onRemove={(e) => {
              e.stopPropagation();
              const updatedFilterList = filterList.filter(f => f.key !== filter.key);
              onFilterChange(updatedFilterList);
            }}
            onFilterChange={onFilterChange}
            isPositionFixed={isPositionFixed}
          />
        ))}
      </div>
      {displayReset && (
        <div 
          className='reset-btn f-grey'
          onClick={onReset}
        >
          Reset
        </div>
      )}
    </div>
  );
}

/**
 * @param {Object} props
 * @param {{
 *  label: string,
 *  key: string,
 *  value: string | number | { startDate: string, endDate: string } | Array<{ key: string, label: string, value: boolean }> 
 * | { prefix: string, value: Array<number> } | null,
 *  type: "string" | "number" | "date-range" | "checkbox-range" | "number-range" | null
 * }} props.item
 * @param {function} props.onRemove
 * @param {function} props.onFilterChange
 * @param {boolean} props.isPositionFixed
 * 
 * @returns 
 */
const FilterItem = ({ item, onRemove, onFilterChange, isPositionFixed }) => {
  const [inputValue, setInputValue] = useState('');
  const isDate = item.type === 'date-range';
  const isCheckbox = item.type === 'checkbox-range';
  const isNumberRange = item.type === 'number-range';
  const dropdownToggleId = `dropdown-toggle-${item.label}-${item.key}`;
  const inputType = item.type === 'number' ? 'number' : 'text';

  const hasValue = isCheckbox 
    ? item.value?.filter(i => i.value)?.length > 0
    : isNumberRange ? item.value?.value?.length === 2
    : item.value !== null && item.value !== undefined;

  const onCancel = () => {
    const dropdownToggle = document.getElementById(dropdownToggleId);
    if (dropdownToggle) {
      dropdownToggle.click();
    }
  }

  const { 
    setDateSelectionValue,
    selectedDateFilter,
    staticRanges,
    defineds,
    dateFilterValue
  } = useDatePickerData({ id: `date-picker-${item.key}` });

  const labelValue = hasValue ? (
    isDate ? dateFilterValue
      : isCheckbox ? item.value.filter(i => i.value).map(i => i.label).join(', ') 
      : isNumberRange ? `${item.value?.prefix ?? ''}${item.value.value[0]} - ${item.value?.prefix ?? ''}${item.value.value[1]}`
      : item.value
  ) : '';

  const onApply = () => {
    onFilterChange(prev => {
      const updatedFilterList = prev.map(f => {
        if (f.key === item.key) {
          return {
            ...f,
            value: inputValue
          };
        }
        return f;
      });
      return updatedFilterList;
    }, {
      ...item,
      value: inputValue
    })
    onCancel();
  }

  useEffect(() => {
    if (!isDate) return;
    if (item.value) {
      setDateSelectionValue({
        startDate: item.value.startDate,
        endDate: item.value.endDate,
        key: "selection",
      });
    }
  }, []);

  return (
    <Dropdown 
      className='selected-item-wrapper transparennt-wrapper-dropdown my-dropdown'
      onToggle={(isOpen) => {
        setInputValue(item.value);
        if (isDate && item.value) {
          setDateSelectionValue({
            startDate: item.value.startDate,
            endDate: item.value.endDate,
            key: "selection",
          });
        }
        if (!isOpen) {
          onFilterChange(prev => {
            if (!hasValue) {
              return prev.filter(f => f.key !== item.key);
            }
            return prev;
          });
        }
      }}
    >
      <Dropdown.Toggle
        id={dropdownToggleId}
      >
        <div className={`selected-item ${hasValue ? 'has-value' : ''}`}>
          <span className='label'>
            {`${item.label}${hasValue ? `: ${labelValue}` : ''}`}
          </span>
          {item?.isRemovable !== false && (
            <div
              className='remove-button'
              onClick={onRemove}
            >
              <CrossIcon 
                width="9px"
                height="9px"
                fill={hasValue ? '#fff' : '#9499AA'}
              />
            </div>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu 
        className='menu hide-scrollbar' 
        style={{ 
          minWidth: '200px',
          ...(isCheckbox ? { maxHeight: '300px', overflowY: 'auto' } : {})
        }}
        {...(isPositionFixed ? {
          popperConfig:{ 
            strategy: 'fixed'
          }
        } : {})}
      >
        {isDate ? (
          <DateRangeFilterType 
            item={item}
            onFilterChange={onFilterChange}
            setDateSelectionValue={setDateSelectionValue}
            selectedDateFilter={selectedDateFilter}
            defineds={defineds}
            staticRanges={staticRanges}
          />
        ) : isCheckbox ? (
          <CheckboxRangeFilterType 
            title={item.label}
            checkboxList={item.value}
            onFilterChange={(updatedList) => {
              onFilterChange(prev => {
                const updatedFilterList = prev.map(f => {
                  if (f.key === item.key) {
                    return {
                      ...f,
                      value: updatedList
                    };
                  }
                  return f;
                });
                return updatedFilterList;
              }, {
                ...item,
                value: updatedList
              })
            }}
          />
        ) : isNumberRange ? (
          <NumberRangeFilterType 
            title={item.label}
            value={item.value}
            onFilterChange={(fromValue, toValue) => {
              onFilterChange(prev => {
                const updatedFilterList = prev.map(f => {
                  if (f.key === item.key) {
                    return {
                      ...f,
                      value: {
                        prefix: item.value?.prefix,
                        value: [fromValue, toValue]
                      }
                    };
                  }
                  return f;
                }, {
                  ...item,
                  value: {
                    prefix: item.value?.prefix,
                    value: [fromValue, toValue]
                  }
                });
                const toggleElement = document.getElementById(dropdownToggleId);
                if (toggleElement) {
                  toggleElement.click();
                }
                return updatedFilterList;
              })
            }}
          />
        ) : (
          <InputFilterType 
            item={item}
            inputType={inputType}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onCancel={onCancel}
            onApply={onApply}
         />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SelectedFilters;

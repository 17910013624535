import { Form } from "react-bootstrap";

const CheckboxRangeFilterType = ({
  title,
  checkboxList,
  onFilterChange
}) => {
  return (
    <div>
      <div className="f-12 f-500 f-darkgrey mt-2 ml-2">
        {title}
      </div>
      <div className="p-1 ">
        {checkboxList?.map(
          (checkbox, checkboxIndex) => {
            return (
              <div 
                key={`checkbox-${checkbox?.key}`}
                className="d-flex align-items-center"
              >
                <div>
                  <Form.Group
                    controlId={`card ${checkbox?.key}`}
                    className={`round-checkbox blue-checkbox checkboxstyle mb-3 ${"radio-shadow"}`}
                  >
                    <Form.Check
                      type={"checkbox"}
                      checked={checkbox.value}
                      name={
                        `check ` + checkbox?.key + ""
                      }
                      label=""
                      disabled={false}
                      onClick={(e) => {
                        const updatedList = [...checkboxList].map((check, index) => {
                          if (checkboxIndex === index) {
                            return {
                              ...check,
                              value: !check.value
                            };
                          }
                          return check;
                        });
                        onFilterChange(updatedList);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="f-500 pl-3 pt-2" style={{ whiteSpace: 'nowrap' }}>
                  {checkbox.label}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export default CheckboxRangeFilterType;

const SecurityProcessesProceduresBlockSection = ({ auditDetails }) => (
  <div className="w-100 d-flex flex-column">
    <div className="f-14 f-600">
      MSP Security Processes & Procedures
    </div>
    {
      auditDetails?.MSPSecurityProcesses_List?.length !== 0 ?
        <div >
          {
            auditDetails?.MSPSecurityProcesses_List?.map((questionBlock, index) => {
              return (
                <>
                  {
                    questionBlock?.MSPSecopsAuditSection_List?.length !== 0 ? <>
                      <div>
                        {
                          questionBlock?.MSPSecopsAuditSection_List?.map((questions, indexBlock) => {
                            return (
                              <>
                                {
                                  questions?.MSPSecopsAuditQuestion_List?.length !== 0 ? <>
                                    <div >
                                      {
                                        questions?.MSPSecopsAuditQuestion_List?.map((question, index) => {
                                          return (
                                            <>
                                              {(index == 0 && indexBlock == 0) &&
                                                <div className="f-14 f-500"
                                                  style={{
                                                    marginTop: "8px",
                                                    marginBottom: '8px'
                                                  }}
                                                >
                                                  {questionBlock?.MainSectionText}
                                                </div>
                                              }
                                              {index == 0 &&
                                                <div className="f-14 f-400"
                                                  style={{
                                                    marginTop: "8px",
                                                    marginBottom: '8px'
                                                  }}
                                                >
                                                  {questions?.SectionText}
                                                </div>
                                              }
                                              <div
                                                className={`border-item d-flex align-items-center justify-content-between p-3`}
                                                style={{
                                                  backgroundColor: '#F6F8FB', borderRadius: '8px',
                                                  borderBottom: `${index < questions?.MSPSecopsAuditQuestion_List?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                                }}
                                              >
                                                <div className="d-flex align-items-center">
                                                  <div>
                                                    <div>
                                                      <span className="f-400 f-14 f-black">
                                                        {question?.QuestionText}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="d-flex ml-2"
                                                  style={{
                                                    minWidth: 'fit-content'
                                                  }}>
                                                  <div className="f-400 f-14"
                                                    style={{
                                                      color: "#9499AA"
                                                    }}
                                                  >
                                                    {question?.OldStatus}
                                                  </div>
                                                  <img
                                                    alt=""
                                                    src="/images/actioncenter/arrowdown.svg"
                                                    className="mx-2"
                                                    style={{
                                                      transform: "rotate(270deg)"
                                                    }}
                                                  />
                                                  <div className="f-400 f-14"
                                                    style={{
                                                      color: `${question?.NewStatus == 'No' ? "#F36060" : question?.NewStatus == "In Progress" ? "#008FD4" : "#9499AA"}`
                                                    }}
                                                  >
                                                    {question?.NewStatus}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )
                                        })
                                      }
                                    </div>
                                  </> : <></>
                                }
                              </>
                            )
                          })
                        }
                      </div>
                    </> : <></>
                  }
                </>
              )
            })
          }
        </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
          style={{
            backgroundColor: '#F6F8FB',
            marginTop: '8px'
          }}
        >
          MSP Security Processes & Procedures have not been changed
        </div>
    }
  </div>
);

export default SecurityProcessesProceduresBlockSection;

import { USER_ROLES } from "../../constants/user.constants";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/Auth";
import KnowledgeBaseIcon from "../icons/knowledgebase.icon";
import ContactSupportIcon from "../icons/contact-support.icon";
import JoinOurMspCommunityIcon from "../icons/join-our-msp-community.icon";
import TemporarySupportAccessIcon from "../icons/temporary-support-access.icon";
import SettingsGearIcon from "../icons/settings-gear.icon";
import LogoutIcon from "../icons/logout.icon";
import { ROUTE_PATHS } from "../../constants/app.constants";
import useIntercom from "../../hooks/intercom.hook";

const UserActionsList = ({
  userDetails,
  setShowTemporarySupportAccessModal,
  onItemClick = () => {},
  isSidebar = false,
  isSaas = false
}) => {
  const history = useHistory();
  const { setAuth } = useAuth();
  const itemClassName = isSidebar ? "menuItem" : "f-grey-imp";
  const { showNewMessage } = useIntercom({ userDetail: userDetails });
  return (
    <div className={itemClassName}>
      <div
        target="_blank"
        onClick={() => {
          window.open("https://kb.highground.io", "_blank")
          TrackUser("Knowledge Base clicked")
          onItemClick();
        }}
        className="contextmenu-grey-item p-2 pointer"
      >
        <KnowledgeBaseIcon 
           className={`mr-2 ${itemClassName}`}
           width={16}
           height={17}
        />{" "}
        <span className={itemClassName}>Knowledgebase</span>
      </div>
      {userDetails?.Role !== USER_ROLES.MSP_CLIENT && (
        <div
          type="button"
          onClick={(event) => {
            TrackUser("Support Ticket Clicked");
            showNewMessage();
            onItemClick();
          }}
          className="contextmenu-grey-item p-2 pointer"
        >
          <ContactSupportIcon 
            className={`mr-2 ${itemClassName}`}
            width={16}
            height={17}
          />{" "}
          <span className={itemClassName}>Contact Support</span>
        </div>
      )}
      {(userDetails?.Role !== USER_ROLES.SASS && userDetails?.Role !== USER_ROLES.MSP_CLIENT) && (
        <div
          type="button"
          className="contextmenu-grey-item p-2 pointer"
          onClick={(event) => {
            TrackUser("Join Slack community clicked");
            window.open(
              "https://chat.whatsapp.com/C0W3c8N0owfCQeof5auWjG",
              "_blank"
            );
            onItemClick();
          }}
        >
          <JoinOurMspCommunityIcon 
            className={`mr-2 ${itemClassName}`}
            width={16}
            height={17}
          />{" "}
          <span className={itemClassName}> Join our MSP Community</span>
        </div>
      )}
      {!isSaas && (
        <div
          className="contextmenu-grey-item p-2 pointer"
          onClick={() => {
            setShowTemporarySupportAccessModal(true);
            onItemClick();
          }}
        >
          <TemporarySupportAccessIcon
            className={`mr-2 ${itemClassName}`}
            width={16}
            height={17}
          />
          <span
            className={itemClassName}
            style={{
              marginLeft: "3px",
            }}
          >
            Temporary Support Access
          </span>
        </div>
      )}
      <div
        className="contextmenu-grey-item p-2 pointer"
        onClick={() => {
          history.push(isSaas ? ROUTE_PATHS.saas.settings.myProfile : ROUTE_PATHS.mspsettingsv2.myProfile);
          onItemClick();
        }}
      >
        <SettingsGearIcon 
          className={`mr-2 ${itemClassName}`}
          width={16}
          height={17}
        />
        <span
          className={itemClassName}
          style={{
            marginLeft: "3px",
          }}
        >
          {isSaas ? "System Settings" : "MSP System Settings"}
        </span>
      </div>
      <div
        className="contextmenu-grey-item p-2 pointer"
        onClick={() => {
          TrackUser("User Signed out");
          window.localStorage.clear();
          history.push({
            pathname: ROUTE_PATHS.auth.login,
          });
          setAuth(false);
          onItemClick();
        }}
      >
        <LogoutIcon 
          className={`mr-2 ${itemClassName}`}
        />
        <span className={itemClassName}> Logout</span>
      </div>
    </div>
  );
}

export default UserActionsList;

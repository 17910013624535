import ImageWithInitials from "../../../../Utils/ImageWithInitials";

const TrainingCertifications = ({ auditDetails }) => (
  <div className="w-100 d-flex flex-column">
     <div className="f-14 f-600"
        style={{
          marginBottom: '8px'
        }}
      >
        Training Certifications
      </div>
      {
        auditDetails?.TrainingCert_UserListVM?.items?.length !== 0 ?
          <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
            {
              auditDetails?.TrainingCert_UserListVM?.items?.map((certificateItem, index) => {
                return (
                  <div
                    className={`border-item d-flex align-items-center justify-content-between p-3`}
                    style={{
                      borderBottom: `${index < auditDetails?.TrainingCert_UserListVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {certificateItem?.showInitials ? <div className="mr-2">
                        <ImageWithInitials
                          initials={certificateItem?.initials}
                          width={32}
                          background={"purple"}
                        />
                      </div>
                        : (
                          <img
                            src={certificateItem?.avatar}
                            alt=""
                            width={32}
                            className="mr-2 rounded-full"
                          />
                        )}
                      <div>
                        <div>
                          <span className="f-500 f-black">
                            {certificateItem?.fullName}
                          </span>
                          &nbsp; &nbsp;
                        </div>
                        <div className="f-darkgrey">
                          {certificateItem?.certificateText}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      {certificateItem?.certificates?.length > 0 ? (
                        <div className="secops_training_certificate_overlap_parent mr-3">
                          {certificateItem?.certificates
                            ?.slice(0, 8)
                            .map((certificate, certificateIndex) => {
                              return (
                                <>
                                  {certificate?.showInitials ? (
                                    <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child-2">
                                      <ImageWithInitials
                                        initials={certificate?.initials}
                                        width={25}
                                        background={"purple"}
                                      />
                                      {certificate?.statusIcon && (
                                        <img
                                          width={13}
                                          src={
                                            "/images/msp/" +
                                            certificate?.statusIcon
                                          }
                                          className="rounded-full overlapping_icon"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child-2">
                                      <img
                                        width={25}
                                        src={`/images/msp/framework-certificates/${certificate?.icon}`}
                                        className="rounded-full"
                                        alt=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            "/images/msp/default-cert-icon.svg";
                                        }}
                                      />
                                      {certificate?.statusIcon && (
                                        <img
                                          width={13}
                                          src={
                                            "/images/msp/" +
                                            certificate?.statusIcon
                                          }
                                          className="rounded-full overlapping_icon"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        className={` px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                        style={{
                          height: '24px',
                          color: `${certificateItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                          backgroundColor: `${certificateItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        {certificateItem?.UpdatedStatus}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
            style={{
              backgroundColor: '#F6F8FB',
            }}
          >
            Certifications have not been changed
          </div>
      }
  </div>
)

export default TrainingCertifications;

import { useState } from "react";
import { Dropdown } from "react-bootstrap";

const TaxMenu = [
  { label: 'Include', value: true },
  { label: 'Exclude', value: false },
];

const MSPTaxDropdown = ({ ToggleTax, showTax }) => {
  return (
    <TaxDropDown
      ToggleTax={ToggleTax}
      showTax={showTax}
    >
      <div
        className="d-flex align-items-center f-grey pointer bg-hover-grey"
        // onClick={(e) => {
        //   props?.ToggleTax();
        // }}
        id="rename-div"
        style={{ padding: '4px 12px' }}
      >
        <div>Taxes</div>
        <span className="mr-3"></span>
        <div className="d-flex justify-content-end w-100 ml-5">
          <span className="f-darkgrey mr-2">
            {
              TaxMenu
                ?.find((item) => item.value === showTax)
                ?.label
            }
          </span>
          <img
            alt=""
            src="/images/attack-surface/chevron-right.svg"
            style={{ marginRight: '6px' }}
          />
        </div>
      </div>
    </TaxDropDown>
  )
}

export default MSPTaxDropdown;

const TaxDropDown = ({
  children,
  ToggleTax,
  showTax
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  return (
    <Dropdown
      className="transparennt-wrapper-dropdown my-dropdown w-100"
      onToggle={() => {
        setSelectedValue(null);
      }}
    >
      <Dropdown.Toggle className="w-100">
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu 
        className="menu tax-type-menu overlap-toggle"
        style={{ 
          width: 'fit-content', 
          padding: '8px 4px',
        }}
      >
        {TaxMenu.map((item) => (
         <div
            key={`tax-${item.value}`}
            className="integration-item bg-hover-grey d-flex pointer"
            onClick={() => {
              ToggleTax(item.value);
              setSelectedValue(item.value);
            }}
          >
            <div className="ml-2 d-flex align-items-center" style={{ width: '16px' }}>
              {selectedValue === item.value ? (
                 <div className="msp-service-update-loader mr-1" />
              ) : item?.value === showTax ? (
                <img
                  src="/images/msp/dropdown-grey-tick.svg"
                  alt=""
                  className="mr-2"
                />
              ) : (
                ""
              )}
            </div>
            <span className="item py-1 f-grey">{item.label}</span>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
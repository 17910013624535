import React from "react";
import { numberWithCommas, renderPriceText } from "../../MspClientPriceCalculator";
import { IncreaseDecreaseNumbersComponentAlwaysHighlight } from "../../../mspSell/mspPriceCalculator/MspAddEditServiceModal";

export const SummaryTable = ({
  discount,
  packageDetails,
  seatBreakDownData,
  contributionValue,
  switchIncludedTaxVal,
  setDiscount = () => { },
  setIsFromAddContribTable = () => { },
  setMapCurrentSpendPackageId = () => { },
  setShowMapCurrentSpendModal = () => { },
  setMapCurrentSpendPackagePrice = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  setMapCurrentSpendPackagePriceText = () => { },
}) => {
  return (
    <div className="">
      <div className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between">
        Summary
      </div>
      <div className="border-parent  radius-8">
        <div className="per_asset_total_pricing_table-updated border-item">
          <div className="f-500  pl-2">Subtotal</div>
          <div></div>
          <div className="f-500 f-grey">
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {numberWithCommas(
              Number(seatBreakDownData?.MSPPackagePrice?.Subtotal)?.toFixed(2)
            )}
          </div>
        </div>

        <div className="per_asset_total_pricing_table-updated  border-item">
          <div className="f-500 pl-2">
            Contribution
          </div>
          <div className="f-500 txt-blue underline pointer"
            onClick={() => {
              setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
              setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
              setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${seatBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
              setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax).toFixed(2) : seatBreakDownData?.MSPPackagePrice?.Subtotal);
              setShowMapCurrentSpendModal(true);
              setIsFromAddContribTable(true);
              const drag = document?.getElementById("map-current-spend-modal");
              drag.style.display = "block";
            }}
          >
            -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {numberWithCommas(Number(contributionValue)?.toFixed(2))}
          </div>

          <div className="f-500 f-grey"
            onClick={() => {

            }}
          >
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {numberWithCommas(
              Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax - contributionValue).toFixed(2)
            )}
          </div>
        </div>

        <div className="per_asset_total_pricing_table-updated  border-item">
          <div className="f-400 f-14 f-black pl-2">
            Discount
          </div>
          <div className="f-400 f-14"
            style={{
              marginLeft: '-14px'
            }}
          >
            <IncreaseDecreaseNumbersComponentAlwaysHighlight
              type={"number"}
              floatValue={true}
              children={<div
                className="f-400 f-14 f-black"
                style={{
                  width: '17px',
                  marginBottom: '1px'
                }}
              >
                -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              </div>}
              value={discount ?? 0}
              onChange={(value) => {
                setDiscount(value);
                return true;
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </div>

          <div className="f-400 f-14 greyText2">
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {numberWithCommas(
              Number(seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount).toFixed(2)
            )}
          </div>
        </div>

        <div className="per_asset_total_pricing_table-updated  border-item">
          <div className="f-500 pl-2">
            {`
          Tax @ ${seatBreakDownData?.TaxRates_Selected?.TaxRate}% (${seatBreakDownData?.TaxName})
        ` ?? "Tax @ 0% (No Taxable)"}
          </div>
          <div className="f-400 f-14 greyText2">
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {Number((seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * seatBreakDownData?.TaxRates_Selected?.TaxRate / 100)?.toFixed(2)}
            {/* {Number(seatBreakDownData?.MSPPackagePrice?.Tax)?.toFixed(2)} */}
          </div>
          <div className="f-400 f-14 greyText2">
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {Number(
              seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount +
              ((seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * seatBreakDownData?.TaxRates_Selected?.TaxRate / 100)
            ).toFixed(2)}{" "}
          </div>
        </div>

        <div className="per_asset_total_pricing_table-updated  border-item">
          <div className="f-600  pl-2">Total</div>
          <div></div>
          <div className="f-600 f-grey">
            {renderPriceText(
              seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                ?.DDLSort,
              (
                seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount +
                ((seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * seatBreakDownData?.TaxRates_Selected?.TaxRate / 100)
              )?.toFixed(2),
              packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;

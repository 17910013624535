import CryptoJS from 'crypto-js';

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const SECRET_KEY = process.env.REACT_APP_INTERCOM_SECRET_KEY;

const useIntercom = ({ userDetail }) => {
  const bootIntercom = () => {
    const userIdentifier = userDetail?.UserName;
    const hash = CryptoJS.HmacSHA256(userIdentifier, SECRET_KEY).toString(CryptoJS.enc.Hex);

    window.Intercom('boot', {
      app_id: APP_ID, 
      email: userDetail?.UserName,
      name: userDetail?.FirstName,
      user_hash: hash
    });
  }

  const showNewMessage = () => {
    bootIntercom();
    window.Intercom("showNewMessage");
  }

  return {
    bootIntercom,
    showNewMessage
  }
}

export default useIntercom;

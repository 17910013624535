import { createContext, useContext } from 'react';

export const AuthContext = createContext();

//Creating a new context to contain Login data - Soruce; https://reactjs.org/docs/context.html

/** 
 * @returns {Object} AuthContext
 * @returns {Object} windowSizes
 * @returns {number} windowSizes.width
 * @returns {number} windowSizes.height
*/
export function useAuth() {
    return useContext(AuthContext);
}
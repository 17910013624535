import React from "react";
import { Route } from "react-router-dom/cjs/react-router-dom";
import SupplierProfilerHomeScreen from "./SupplierProfilerHomeScreen";
import { ROUTE_PATHS } from "../../constants/app.constants";

const SupplierProfiler = () => {
  return (
    <>
      <Route
        exact
        path={`${ROUTE_PATHS.saas.supplierProfiler}/profiling`}
        component={SupplierProfilerHomeScreen}
      />
    </>
  );
};

export default SupplierProfiler;

export const IntegrationCategories = {
  CyberSecurity: 'Cyber Security',
}

export const ManagedBySOCState = {
  Always: 0,
  Optional: 1,
  Never: 2
};

export const ContributionTypeTitles = ["products", "practices", "tools"];

export const ReplaceIntegrationTypes = {
  REMOVE: 0,
  DELETE: 1
}

export const AttestationStatus = {
  ToolsListed: "Tools Listed",
  NoCapability: "No capability",
  NotAnswered: "Not answered",
  IsIncomplete: "TBC",
  Issues: "GovAndRes Listed"
}

export const ComingSoonLabel = "Coming Soon";
export const AllToolsLabel = "All tools";

export const IntegrationState = {
  Syncing: "Syncing"
}
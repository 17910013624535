export const BlueComingSoonTag = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center rounded-full w-fit-content f-9"
      style={{
        background: "#D6EDF8",
        color: "#008FD4",
        padding: "0px 6px",
      }}
    >
      Coming Soon
    </div>
  );
};
export const BlueTag = ({ text, className }) => {
  return (
    <div
      className={`d-inline-flex align-items-center justify-content-center rounded-full w-fit-content f-9 f-600 ${className}`}
      style={{
        background: "#D6EDF8",
        color: "#008FD4",
        padding: "0px 6px",
      }}
    >
      {text}
    </div>
  );
};
export const GreyTag = ({ text, className }) => {
  return (
    <div
      className={`d-inline-flex align-items-center justify-content-center rounded-full w-fit-content f-9 f-600     ${className}}`}
      style={{
        background: "#EBEDF1",
        color: "#616778",
        padding: "0px 6px",
      }}
    >
      {text}
    </div>
  );
};

import { BASE_API_PAGE_ENUMS, BASE_API_PATHS } from "../../constants/apis.constants";
import { getAPIData, IndependentGetAPIData, postData } from "../../Utils/commonApi";
import useBaseActions from "./base.actions";

const useUserActions = () => {
  const { authTokens } = useBaseActions();

  const updateGettingStarted = ({
    configValue, configType, clientId
  }) => {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateGettingStarted", {
        ConfigValue: configValue,
        ConfigType: configType,
        ...(clientId && { objId_Customer: clientId }),
      })
        .then((response) => {
          if (response?.Success) {
            resolve(response);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          console.log(err, "error in PSAGlobalConfig");
          reject(false);
        });
    });
  }

  const archiveClient = (clientId) => {
    return getAPIData(BASE_API_PAGE_ENUMS.ArchiveClient, authTokens, {
      optionEnum1: clientId,
      optionEnum2: 0,
    })
  }

  const sendEmailToStorefront = (userEmail) => {
    return IndependentGetAPIData(BASE_API_PAGE_ENUMS.EmailToStorefront, authTokens, {
      optionStrEnum1: userEmail,
    });
  }

  const getActionCentreSortVisibility = ({ 
    sortVisibilityList,
    TaskNumber,
    isArchived,
    TaskFilter_List,
    TaskSort,
    TaskSortOrder,
    ObjectId
  }) => {
    return postData(authTokens, BASE_API_PATHS.getActionCentreSortVisibility, {
      MSPActionCentreSortVisibility_List: sortVisibilityList,
      ObjectId,
      TaskSort,
      TaskSortOrder,
      IsArchived: isArchived,
      TaskNumber,
      TaskFilter_List
    });
  }

  return {
    updateGettingStarted,
    archiveClient,
    sendEmailToStorefront,
    getActionCentreSortVisibility
  }
}

export default useUserActions;

import { ROUTE_PATHS } from "./app.constants";
import DashboardIcon from "../Components/icons/client-sidebar/dashboard.icon";
import TechnologySpendIcon from "../Components/icons/client-sidebar/technology-spend.icon";
import AssetsIcon from "../Components/icons/client-sidebar/assets.icon";
import GovernanceResilienceIcon from "../Components/icons/client-sidebar/governance-resilience.icon";
import IntegrationsIcon from "../Components/icons/client-sidebar/integrations.icon";
import ManagedSecurityIcon from "../Components/icons/client-sidebar/managed-security.icon";
import StorefrontIcon from "../Components/icons/client-sidebar/storefront.icon";

export const USER_TYPES = {
  MSP: 555555
};

export const USER_ROLES = {
  SASS: "HG_SAAS",
  MSP: "MSP",
  MSP_CLIENT: "HG_MSP_CLIENT",
  MSP_MEMBER: "HG_MSP_MEMBER"
}

export const USER_PASSWORD_STRENGTH = [
  {
    maxValue: 0,
    color: "greyText",
    barColor: "bar-not-active",
  },
  {
    maxValue: 1,
    color: "redText",
    barColor: "bar-filled-red",
  },
  {
    maxValue: 2,
    color: "redText",
    barColor: "bar-filled-red",
  },
  {
    maxValue: 3,
    color: "orangeText",
    barColor: "bar-filled-orange",
  },
  {
    maxValue: 4,
    color: "orangeText",
    barColor: "bar-filled-orange",
  },
  {
    maxValue: 5,
    color: "greenText",
    barColor: "bar-filled",
  },
];

/**
 * User Access States
 * These are the access states that are used to determine which tabs the user has access to
 * 
 * key - CollapsedText of the tab
 * value - Access State
 */
export const SAAS_USER_ACCESS_TABS = {
  ["Dashboard"]: "AccessState_Dashboard",
  ["Technology Spend"]: "AccessState_TechnologySpend",
  ["Attack Surface"]: "AccessState_AttackSurface",
  ["Compliance"]: "AccessState_ComplianceManager",
  ["Integrations"]: "AccessState_Integrations",
  ["Managed Security"]: "AccessState_ManagedSecurity"
}

export const SAAS_USER_ROUTE_TABS = {
  ["Dashboard"]: ROUTE_PATHS.saas.dashboard,
  ["Technology Spend"]: ROUTE_PATHS.saas.technologySpend,
  ["Attack Surface"]: ROUTE_PATHS.saas.attackSurface,
  ["Compliance"]: ROUTE_PATHS.saas.complianceManager,
  ["Integrations"]: ROUTE_PATHS.saas.integrations,
  ["Managed Security"]: ROUTE_PATHS.saas.securityPackage
}

export const CLIENT_GETTING_STARTED_TYPE = {
  ManageTechnologySpend: 3,
  ApplySecurityPackage: 4,
  ManageGovernance: 5,
  ReviewSecurityPosture: 6,
}

export const ClientSidebar = {
  MSP: [
    {
      tabName: "Dashboard",
      tabIcon: DashboardIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.dashboard(clientId),
      tabKey: "dashboard",
    },
    {
      tabName: "Technology Spend",
      tabIcon: TechnologySpendIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.technologySpend(clientId),
      tabKey: "technology-spend",
    },
    {
      tabName: "Assets",
      tabIcon: AssetsIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.attackSurface(clientId),
      tabKey: "assets",
    },
    {
      tabName: "Governance & Resilience",
      tabIcon: GovernanceResilienceIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.complianceManager(clientId),
      tabKey: "Compliance_Manager",
    },
    {
      tabName: "Storefront",
      tabIcon: StorefrontIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.storefront(clientId),
      tabKey: "storefront",
      comingSoon: "COMING"
    },
    {
      tabName: "Integrations", 
      tabIcon: IntegrationsIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.integrations(clientId),
      tabKey: "integrations",
    },
    {
      tabName: "Security Packages",
      tabIcon: ManagedSecurityIcon,
      tabURL: (clientId) => ROUTE_PATHS.mspv2.clients.securityPackage(clientId),
      tabKey: "security-packages",
    },
  ],
  Client: [
    {
      tabName: "Dashboard",
      tabIcon: DashboardIcon,
      tabURL: ROUTE_PATHS.saas.dashboard,
      tabKey: "dashboard",
    },
    {
      tabName: "Technology Spend",
      tabIcon: TechnologySpendIcon,
      tabURL: ROUTE_PATHS.saas.technologySpend,
      tabKey: "technology-spend",
    },
    {
      tabName: "Assets",
      tabIcon: AssetsIcon,
      tabURL: ROUTE_PATHS.saas.attackSurface,
      tabKey: "assets",
    },
    {
      tabName: "Governance & Resilience",
      tabIcon: GovernanceResilienceIcon,
      tabURL: ROUTE_PATHS.saas.complianceManager,
      tabKey: "Compliance_Manager",
    },
    {
      tabName: "Storefront",
      tabIcon: StorefrontIcon,
      tabURL: ROUTE_PATHS.saas.storefront,
      tabKey: "storefront",
      comingSoon: "COMING",
    },
    {
      tabName: "Integrations",
      tabIcon: IntegrationsIcon,
      tabURL: ROUTE_PATHS.saas.integrations,
      tabKey: "integrations",
    },
    {
      tabName: "Security Packages", 
      tabIcon: ManagedSecurityIcon,
      tabURL: ROUTE_PATHS.saas.securityPackage,
      tabKey: "security-packages",
    },
  ]
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import "../secOps.scss";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import AddMemberModal from "../../../Common/add-member-modal/add-member.modal";

const ClientMspAddMemberModal = ({
  show,
  hideModal,
  refreshData,
  showMemberAccessModal,
  hideCreateOption,
  passedOptionEnum98, 
  passedOptionEnum99,
  isForMSPMember
}) => {
  const { authTokens } = useAuth();
  const mspClientId = window.location.pathname.split("/")?.[2];
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false)
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (show) {
      setPageLoading(true);
      getMembersDetails(0, "");
      getAccessRolesData();
    } else {
      setTimeout(() => {
        setMembers([]);
        setRoles([]);
        setPageNumber(1);
        setModalLoading(false)
        setSearch("")
      }, 300);
    }
  }, [show]);

  const divRef = useRef();

  const handleScroll = useCallback(() => {
    if (isForMSPMember && pages > 1) {
      setModalLoading(true)
      setPageNumber(pageNumber+1)
      getMembersDetails(0, search, pageNumber+1)
    }
  }, [pages, pageNumber, members, search]);

  const onSearch = (searchValue) => {
    setModalLoading(true)
    getMembersDetails(0, searchValue)
    setPageNumber(1)
  }

  async function getMembersDetails(option1, option2, pageNum) {
    await getAPIData("554", authTokens, isForMSPMember ? {
      optionEnum1: 20,
      optionEnum2: mspClientId,
      optionEnum3: pageNum ? pageNum : 1,
      optionStrEnum1: option2,
    } : passedOptionEnum98 && passedOptionEnum99 ? {
      optionEnum1: option1,
      optionEnum2: mspClientId,
      optionStrEnum1: option2,
      optionEnum98: passedOptionEnum98,
      optionEnum99: passedOptionEnum99
    } : passedOptionEnum99 ? {
      optionEnum1: option1,
      optionEnum2: mspClientId,
      optionStrEnum1: option2,
      optionEnum98: passedOptionEnum98,
      optionEnum99: passedOptionEnum99
    } : {
      optionEnum1: option1,
      optionEnum2: mspClientId,
      optionStrEnum1: option2,
    }).then((response) => {
      if (response.mr.Success) {
        if (isForMSPMember) {
          if (!members[0] || !pageNum) {
            setPages(Math.ceil(response?.totalUsers/20))
            setMembers(response?.users);
          } else {
            setMembers(members.concat(response?.users))
          }
        } else {
          setMembers(response?.users);
        }
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    }).finally(() => {
      setPageLoading(false);
      setModalLoading(false)
    });
  }

  async function getAccessRolesData() {
    await getAPIData("551", authTokens, passedOptionEnum98 && passedOptionEnum99 ? {
      optionEnum98: passedOptionEnum98,
      optionEnum99: passedOptionEnum99
    } : passedOptionEnum99 ? {
      optionEnum99: passedOptionEnum99
    } : {}).then((response) => {
      if (response.mr.Success) {
        setRoles(response.roles);
      }
    });
  }

  async function inviteMember(selectedMembersList, sendInviteOnly) {
    setLoading(true);
    let list = [];
    selectedMembersList.map((member) => {
      list.push({
        Key: member.objId_PSAContact, 
        Value: member.userName,
        ...(member?.selectedRole?.objId_AccessRole ? { objId_AccessRole: [member?.selectedRole?.objId_AccessRole] } : {})
      });
    });
    await postData(authTokens, "Invite_Potential_MSPCustomerUser", {
      obj_List: JSON.stringify(list),
      objId_Customer: mspClientId,
      optionEnum98: mspClientId,
      optionEnum99: 6,
      ...(sendInviteOnly ? { SendInviteOnly: sendInviteOnly } : {})
    })
      .then((response) => {
        setLoading(false);
        if (response?.Success) {
          CallToast("Member invited successfully", false, false, "v2style");

          hideModal();
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <div>
      {show && (
        <AddMemberModal 
          show={show}
          onHide={hideModal} 
          roles={roles}
          handleScroll={handleScroll}
          members={members}
          inviteMember={inviteMember}
          hideCreateOption={hideCreateOption}
          loading={loading}
          pageLoading={pageLoading}
          modalLoading={modalLoading}
          setShowAccessLevelModal={setShowAccessLevelModal}
          isForMSPMember={false}
          getAccessRolesData={getAccessRolesData}
          memberKey="objId_PSAContact"
          onSearch={onSearch}
          currentPage={pageNumber}
          totalPages={pages}
        />
      )}
    </div>
  )
};

export default ClientMspAddMemberModal;

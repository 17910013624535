import { Dropdown } from "react-bootstrap";
import useDropdown from "../../hooks/dropdown.hook";
import { useMemo, useRef } from "react";
import integrationUtils from "../../Utils/integration.utils";
import { HGHighlightText } from "../App/App";
import { AllToolsLabel } from "../../constants/integrations.constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ROUTE_PATHS } from "../../constants/app.constants";

const IntegrationsSearchDropdown = ({ 
  searchString, 
  setSearchString, 
  tools, 
  categories,
  setSelectedSection,
  IsMsp
}) => {
  const inputRef = useRef(null);
  const history = useHistory();
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false });
  const integrations = useMemo(() => (
    [...tools]
      .filter(tool => tool.IntegrationName.toLowerCase().includes(searchString.toLowerCase()))
      .splice(0, 5)
  ), [tools, searchString]);
  const filteredCategories = useMemo(() => (
    [...categories]
      .filter(category => (
        category !== AllToolsLabel && category.toLowerCase().includes(searchString.toLowerCase())
      ))
      .splice(0, 5)
      .map(category => ({
        categoryName: category,
        amountOfTools: tools.filter(tool => tool?.Tags_List?.includes(category))?.length
      }))
  ), [categories, searchString]);

  const hideMenu = integrations.length === 0 && filteredCategories.length === 0;

  return (
    <Dropdown 
      className="transparennt-wrapper-dropdown my-dropdown w-100"
      {...(!searchString ? { show: false } : { show: isOpen && !hideMenu })}
      onToggle={() => {
        setIsOpen(prev => !prev);
        if (!isOpen) {
          inputRef.current.focus();
        }
      }}
    >
      <Dropdown.Toggle ref={refBtn} id="dropdown-basic" className="dropdown-toggle w-100">
        <div className="search-wrapper d-flex align-items-center">
          <div className="search-icon-wrapper">
            <img
              alt=""
              src="/images/settings-v2/search-icon.svg"
              width={'14px'}
              height={'14px'}
            />
          </div>
          <input
            ref={inputRef}
            placeholder="Search tool name..."
            className="border-none bg-transparent f-14 line-height-22 f-darkgrey w-100"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
              setIsOpen(true);
            }}
          />
          {searchString && (
            <img
              alt=""
              src="/images/settings-v2/header-search-cross.svg"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setSearchString("");
              }}
            />
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu ref={refMenu} className="w-100 dropdown-toggle search-modal menu">
        {integrations.length > 0 && (
          <h4 className="m-0 section-title f-darkgrey">
            Integrations
          </h4>
        )}
        {integrations.map((tool, index) => (
          <div 
            className="list-item" 
            key={`tool-${tool?.IntegrationId}`}
            onClick={() => {
              if (IsMsp) {
                history.push(
                  ROUTE_PATHS.mspv2.integrationDetails(tool?.IntegrationId)
                );
              } else {
                history.push(`${ROUTE_PATHS.saas.integrations}/${tool?.IntegrationId}`);
              }
            }}
          >
            <div className="name-wrapper break-word pre-wrap">
              <img
                src={integrationUtils.getImagePath(tool?.IntegrationId, tool?.ToolIcon)}
                alt=""
                className="radius-4 icon-wrapper"
                width={24}
                height={24}
              />
              <span 
                dangerouslySetInnerHTML={{
                  __html: HGHighlightText(tool?.IntegrationName, searchString)
                }}
              />
            </div>
            <div className="tags f-darkgrey break-word pre-wrap text-align-end">
              {tool?.Tags_List?.join(', ')}
            </div>
          </div>
        ))}
        {filteredCategories.length > 0 && (
          <h4 className="m-0 section-title f-darkgrey">
            Categories
          </h4>
        )}
        {filteredCategories.map((category) => (
          <div 
            className="list-item" 
            key={`category-${category.categoryName}`}
            onClick={() => {
              setSelectedSection(category.categoryName);
              setIsOpen(false);
            }}
          >
            <div className="name-wrapper">
              <span 
                dangerouslySetInnerHTML={{
                  __html: HGHighlightText(category.categoryName, searchString)
                }}
              />
            </div>
            <div className="tags f-darkgrey">
              {category.amountOfTools} integrations
            </div>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default IntegrationsSearchDropdown;

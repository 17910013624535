import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import './priceChangedModal.scss'
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const ChangesModal = ({
  show,
  selectedId = 0,
  fromService = false,
  packagesList = [],
  clientsList = [],
  hideModal = () => { },
  editOption = () => { },
  cloneOption = () => { }
}) => {
  const { authTokens } = useAuth();
  const [cloningLoading, setCloningLoading] = useState(false);

  async function duplicateData() {
    setCloningLoading(true);
    await getAPIData(fromService ? 633 : 620, authTokens, {
      optionEnum1: selectedId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          cloneOption(response?.EntityId);
          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setCloningLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCloningLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="changesModal"
      className="changesModal"
      aria-labelledby="changesModal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="">
        <div>
          <div className="d-flex align-items-center">
            <img
              width={20}
              height={20}
              alt=""
              src="/images/warningTriangleFullOrange.svg"
              className="mr-2"
            />
            <div className="f-500 f-16 f-black line-height-28 letter-space-2">
              Caution, editing this {fromService ? 'service' : 'package'}
            </div>
          </div>

          <div className="f-400 f-14 f-black line-height-22 letter-space-2 mt-2">
            {fromService ? 'Changing the security capabilities of a service will affect the security posture of clients who have this service applied via a security package.'
              : 'Changing the package will affect the security posture of clients who have this package as security package.'}
          </div>
          <div className="f-400  f-14 f-black line-height-22 letter-space-2 mt-3">
            {fromService ? 'We recommend you clone this service, add it to a new package and apply that package to your clients.'
             : 'We recommend you clone this package, and apply it to your clients.'}
          </div>

          {
            fromService &&
            <div className="grey-textarea radius-8 mt-2">
              <div>
                {
                  packagesList?.length == 0 ?
                    <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                      No packages
                    </div> :
                    <div>
                      <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2">
                        Packages this service is included in
                      </div>
                      {
                        packagesList?.map((packageItem) => {
                          return (
                            <div className="d-flex align-items-center mt-1">
                              <img
                                width={10}
                                height={10}
                                alt=""
                                src="/images/msp/msp-package-icon-light.svg"
                                className="mr-2"
                              />
                              <div className="f-400 f-14 f-black line-height-18 letter-space-2">{packageItem?.Name}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </div>
            </div>
          }

          <div className="grey-textarea radius-8 mt-2">
            {
              clientsList?.length == 0 ?
                <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                  No clients
                </div> :
                <div>
                  <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                    Applied to clients
                  </div>
                  {
                    clientsList?.map((clientItem) => {
                      return (
                        <div className="d-flex align-items-center mt-1">
                          <img
                            width={10}
                            height={10}
                            alt=""
                            src="/images/client-folder-icon.svg"
                            className="mr-2"
                          />
                          <div className="f-400 f-14 f-black line-height-18 letter-space-2">{clientItem?.ClientName}</div>
                        </div>
                      )
                    })
                  }
                </div>
            }
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-between m-3">
          <div>
            <Button
              className="hg-tranparent-grey-btn"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
                editOption();
              }}
            >
              Edit {fromService ? 'Service' : 'Package'}
            </Button>
            <Button
              className="hg-blue-btn"
              onClick={() => {
                duplicateData();
              }}
            >
              {cloningLoading ? 'Cloning' : 'Clone'}{" "}{fromService ? 'Service' : 'Package'}{cloningLoading ? '...' : ''}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangesModal;
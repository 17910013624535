import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BaseInfoIcon from '../../../../icons/base-info.icon';
import './managed-by-provider.styles.scss';

const ManagedByProvider = () => {
  return (
    <div className='d-flex  justify-content-end'>
      <OverlayTrigger
        placement="bottom-end"
        trigger={["hover", "focus"]}
        delay={{ show: 200, hide: 250 }}
        overlay={(props) => (
          <Tooltip
            id="manged-by-provider-tooltip"
            style={{ position: 'relative', zIndex: 10000,  }}
            positionLeft={0}
            {...props}
          >
            <div>
              <span className='f-12'>
                Managed by your Managed Service Provider
              </span>
            </div>
          </Tooltip>
        )}
      >
        <div className='managed-by-provider'>
          <span className='managed-label f-grey'>
            Managed
          </span>
          <div className='info-icon-wrapper'>
            <BaseInfoIcon />
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default ManagedByProvider;

import React, { useEffect } from "react";
import CyberScoreGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import CyberComplianceGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import { TrimString } from "../../../Utils/StringTrimmer";
import { HGHighlightText, getAccessStateValue, viewOnlyAccess } from "../../App/App";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PackageListRadarUI } from "./MspClientPriceCalculator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { WindowBreakpoints } from "../../../constants/window.constants";
import useRespondTo from "../../../hooks/respond-to.hook";
import { PackageListRadar } from "./PackageListRada";
import { ROUTE_PATHS } from "../../../constants/app.constants";

export const ActivePackageBlock = ({
    clientId,
    pageData,
    ownClientID,
    searchString,
    savingAssets,
    openedPackages,
    activePackageFound,
    isActivePackageOpen,
    switchIncludedTaxVal,
    priceCalculatorState,
    activeOrInactiveLoading,
    selectActivePackageDataSearch,
    closeScenario = () => { },
    setClickedItem = () => { },
    getFeaturesData = () => { },
    getPackageDetails = () => { },
    setOpenedPackages = () => { },
    checkPackageIfInUse = () => { },
    setIsActiveOrInactive = () => { },
    _setOpenedPackagesIds = () => { },
    getSecurityCapabilities = () => { },
    setShowUpdateCyberKPIModal = () => { },
    setShowMapCurrentSpendModal = () => { },
    setMapCurrentSpendPackageId = () => { },
    setActiveOrInactivePackageId = () => { },
    setMapCurrentSpendPackagePrice = () => { },
    setMapCurrentSpendPackagePeriod = () => { },
    setSelectActivePackageDataSearch = () => { },
    setMapCurrentSpendPackagePriceText = () => { },
}) => {
    const history = useHistory();
    const isMobile = useRespondTo(WindowBreakpoints.md);


    return (
        <div className="active-package-block">
            <div className="f-500 f-14 f-black mt-4 mb-2">Active Package</div>
            {
                activePackageFound ?
                    <div className="bg-grey radius-8 border-parent mt-3">
                        <div
                            className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
            ${Number(ownClientID) == Number(clientId) ? 'pointer' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
          `}
                            style={{
                                minHeight: '106px',
                                maxHeight: '106px'
                            }}
                        >
                            {/* Radar Chart */}
                            <div className="d-flex align-items-center w-100"
                                onClick={(e) => {
                                    if (Number(ownClientID) != Number(clientId)) {
                                        if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                            if (openedPackages.length > 0) {
                                                const updatedData = isActivePackageOpen
                                                    ? openedPackages.filter(
                                                        (item) => item !== activePackageFound.objId_Scenario
                                                    )
                                                    : [...openedPackages, activePackageFound.objId_Scenario];

                                                setOpenedPackages(updatedData);
                                                _setOpenedPackagesIds(updatedData);

                                                if (isActivePackageOpen) {
                                                    closeScenario(activePackageFound.objId_Scenario);
                                                } else {
                                                    getPackageDetails(activePackageFound.objId_Scenario);
                                                    getFeaturesData(activePackageFound.objId_Scenario);
                                                    getSecurityCapabilities(activePackageFound.objId_Scenario);
                                                }
                                            } else {
                                                setOpenedPackages([activePackageFound.objId_Scenario]);
                                                getPackageDetails(activePackageFound.objId_Scenario);
                                                getFeaturesData(activePackageFound.objId_Scenario);
                                                getSecurityCapabilities(activePackageFound.objId_Scenario);
                                            }
                                            setClickedItem(true);
                                        }
                                    } else {
                                        if (openedPackages.length > 0) {
                                            const updatedData = isActivePackageOpen
                                                ? openedPackages.filter(
                                                    (item) => item !== activePackageFound.objId_Scenario
                                                )
                                                : [...openedPackages, activePackageFound.objId_Scenario];

                                            setOpenedPackages(updatedData);
                                            _setOpenedPackagesIds(updatedData);

                                            if (isActivePackageOpen) {
                                                closeScenario(activePackageFound.objId_Scenario);
                                            } else {
                                                getPackageDetails(activePackageFound.objId_Scenario);
                                                getFeaturesData(activePackageFound.objId_Scenario);
                                                getSecurityCapabilities(activePackageFound.objId_Scenario);
                                            }
                                        } else {
                                            setOpenedPackages([activePackageFound.objId_Scenario]);
                                            getPackageDetails(activePackageFound.objId_Scenario);
                                            getFeaturesData(activePackageFound.objId_Scenario);
                                            getSecurityCapabilities(activePackageFound.objId_Scenario);
                                        }
                                        setClickedItem(true);
                                    }
                                }}
                            >
                                {
                                    !isMobile &&
                                    <div className="mr-2">
                                        <PackageListRadar
                                            dataList={[
                                                activePackageFound?.InspectScore,
                                                activePackageFound?.ProtectScore,
                                                activePackageFound?.DetectScore,
                                                activePackageFound?.RespondScore,
                                                activePackageFound?.RecoverScore,
                                            ]}
                                            colorPassed={priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === activePackageFound?.objId_Scenario)?.circleColour ?? '#9499AA'}
                                        />
                                    </div>
                                }
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <div className={`subclass d-flex align-items-center`}>
                                                {" "}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: HGHighlightText(
                                                            activePackageFound?.Name,
                                                            searchString
                                                        ),
                                                    }}
                                                />
                                                {
                                                    !isMobile && <>
                                                        {
                                                            activeOrInactiveLoading == activePackageFound?.objId_Scenario ? <div className="msp-service-update-loader ml-2 mr-1"></div> :
                                                                <span className="f-500 f-12 txt-blue ml-2">
                                                                    {activePackageFound?.IsPackageActive && 'Active'}
                                                                </span>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className={`f-12 f-darkgrey`}>
                                                {TrimString(activePackageFound?.Description, (isMobile ? 25 : 50)) ?? ""}
                                            </div>
                                            {
                                                activePackageFound?.MappedPrice > 0 && activePackageFound?.Contribution > 0 ?
                                                    <div className={`d-flex align-items-center ${isMobile && 'f-12'}`}>
                                                        <div className="d-flex align-items-center f-darkgrey line-through-text mr-2">
                                                            {
                                                                switchIncludedTaxVal ? activePackageFound?.MonthlyPrice_Text?.split(' ')[0] : activePackageFound?.MonthlyPriceWithoutTax_Text?.split(' ')[0]
                                                            }
                                                        </div>
                                                        <div className="d-flex align-items-center txt-blue">
                                                            {
                                                                `${activePackageFound?.MonthlyPrice_Text?.match(/[^\d.,/\s]+/)[0]}${switchIncludedTaxVal ?
                                                                    (activePackageFound?.MonthlyPrice - activePackageFound?.Contribution)?.toFixed(2) :
                                                                    (parseFloat(activePackageFound?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')) - activePackageFound?.Contribution)?.toFixed(2)} /${activePackageFound?.BillingPeriod_Text}`
                                                            }
                                                        </div>
                                                        {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                                    </div>
                                                    :
                                                    <div className={`d-flex align-items-center f-darkgrey ${isMobile && 'f-12'}`}>
                                                        {
                                                            switchIncludedTaxVal ? activePackageFound?.MonthlyPrice_Text : activePackageFound?.MonthlyPriceWithoutTax_Text
                                                        }
                                                        {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="dots-menu-block">
                                <div>
                                    <div className="d-flex justify-content-end align-content-end" style={{
                                        marginBottom: '30px'
                                    }}>
                                        <Dropdown className="transparennt-wrapper-dropdown-menu">
                                            <Dropdown.Toggle>
                                                <img
                                                    alt=""
                                                    className="pointer mx-2"
                                                    src="/images/settings-v2/horizontal-dots.svg"
                                                />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="p-0">
                                                <div
                                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                    onClick={() => {
                                                        history.push(ROUTE_PATHS.mspv2.priceCalculator(activePackageFound.objId_Scenario), {
                                                            backUrl: ROUTE_PATHS.mspv2.clients.securityPackage(clientId),
                                                            allPackagesList: pageData?.ScenarioHheaderList,
                                                            clientId: clientId,
                                                        });
                                                    }}
                                                >
                                                    Edit
                                                </div>
                                                <div
                                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                    onClick={(e) => {
                                                        if (activePackageFound?.IsPackageActive == false) {
                                                            setActiveOrInactivePackageId(activePackageFound.objId_Scenario);
                                                            setShowUpdateCyberKPIModal(true);
                                                        } else {
                                                            setIsActiveOrInactive(activePackageFound.objId_Scenario, 0);
                                                        }
                                                        e.target
                                                            .closest(".transparennt-wrapper-dropdown-menu")
                                                            .click();
                                                    }}
                                                >
                                                    Set as {activePackageFound.IsPackageActive ? 'Inactive' : 'Active'}
                                                </div>
                                                <div
                                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                    onClick={(e) => {
                                                        setMapCurrentSpendPackageId(activePackageFound?.objId_Scenario);
                                                        setMapCurrentSpendPackagePeriod(activePackageFound?.PackageBillingPeriod_Text);
                                                        setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? activePackageFound?.MonthlyPrice_Text : activePackageFound?.MonthlyPriceWithoutTax_Text);
                                                        setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? activePackageFound?.MonthlyPrice : parseFloat(activePackageFound?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')));
                                                        setShowMapCurrentSpendModal(true);
                                                        const drag = document?.getElementById("map-current-spend-modal");
                                                        drag.style.display = "block";
                                                        e.target
                                                            .closest(".transparennt-wrapper-dropdown-menu")
                                                            .click();
                                                    }}
                                                >
                                                    Add Contribution
                                                </div>
                                                <div className="hg-border-top mx-3"></div>
                                                <div
                                                    className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                                    onClick={async (e) => {
                                                        checkPackageIfInUse(activePackageFound?.objId_Scenario);
                                                        e.target
                                                            .closest(".transparennt-wrapper-dropdown-menu")
                                                            .click();
                                                    }}
                                                >
                                                    Delete
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div>
                                        {
                                            priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === activePackageFound?.objId_Scenario) ? <div className="d-flex justify-content-end align-content-end align-items-center">
                                                <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                                                <div
                                                    className="rounded-full ml-2 f-400 f-12"
                                                    style={{
                                                        width: "12px",
                                                        height: "12px",
                                                        backgroundColor: priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === activePackageFound?.objId_Scenario)?.circleColour,
                                                        color: "#9499AA"
                                                    }}
                                                />
                                            </div> :
                                                <div style={{
                                                    height: '20px'
                                                }}></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className={`bg-lightblue w-100 radius-8 mt-3 ${isMobile ? 'p-2' : 'p-3'}`}>
                        <div className="d-flex align-items-center">
                            <img
                                alt=""
                                height={15}
                                className="mr-2"
                                src="/images/navIcons/calculator.svg"
                            />
                            <div className="f-400 f-14 f-black line-height-22 letter-space-2">Apply an active security package to your client</div>
                        </div>
                        <Dropdown className="select-active-package-dropdown">
                            <Dropdown.Toggle className="d-flex align-items-center" disabled={getAccessStateValue("AccessState_ManagedSecurity") !== 99}>
                                <div
                                    className={`f-500 f-14 line-height-22 letter-space-2 mt-1 ${activeOrInactiveLoading != 0 || getAccessStateValue("AccessState_ManagedSecurity") !== 99 ? 'f-darkgrey' : 'f-blue'}`}
                                    style={{
                                        marginLeft: '20px'
                                    }}
                                >
                                    {activeOrInactiveLoading != 0 ? 'Setting package as active' : 'Select package'} 
                                </div>
                                {
                                    activeOrInactiveLoading != 0 ? <div className="msp-service-update-loader ml-2 mr-1 mt-1"></div> : <></>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                className="p-2"
                                style={{
                                    width: '332px !important',
                                    height: '460px !important'
                                }}
                            >
                                <div className="no-highlight-input w-100 d-flex align-items-center p-2">
                                    <div>
                                        <img
                                            width={14}
                                            height={14}
                                            src="/images/attack-surface/search-icon.svg"
                                            className="m-1"
                                            alt=""
                                        />
                                    </div>

                                    <input
                                        className="pl-2 flex-grow-1"
                                        placeholder="Find package..."
                                        type="text"
                                        value={selectActivePackageDataSearch}
                                        onChange={(e) => {
                                            setSelectActivePackageDataSearch(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="f-400 f-12 f-darkgrey mt-2 ml-2">Packages</div>
                                {
                                    pageData?.ScenarioHheaderList?.filter((item) => item.IsPackageActive === false)?.filter((item) =>
                                        item.Name.toLowerCase().includes(selectActivePackageDataSearch.toLowerCase())
                                    )?.length > 0 ?
                                        pageData?.ScenarioHheaderList?.filter((item) => item.IsPackageActive === false)?.filter((item) =>
                                            item.Name.toLowerCase().includes(selectActivePackageDataSearch.toLowerCase())
                                        )?.map((packageItem) => {
                                            return (
                                                <div
                                                    className="p-1 bg-hover-grey pointer"
                                                    onClick={(e) => {
                                                        if (packageItem?.IsPackageActive == false) {
                                                            setActiveOrInactivePackageId(packageItem.objId_Scenario);
                                                            setShowUpdateCyberKPIModal(true);
                                                        } else {
                                                            setIsActiveOrInactive(packageItem.objId_Scenario, 0);
                                                        }
                                                        e.target
                                                            .closest(".select-active-package-dropdown")
                                                            .click();
                                                    }}
                                                >
                                                    <div className="f-400 f-14 f-grey">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: HGHighlightText(
                                                                    packageItem?.Name,
                                                                    selectActivePackageDataSearch
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="f-400 f-12 f-darkgrey">
                                                        {packageItem?.PackageBillingPeriod_Text} • {switchIncludedTaxVal ? packageItem?.MonthlyPrice_Text : packageItem?.MonthlyPriceWithoutTax_Text}
                                                    </div>
                                                </div>
                                            )
                                        }) : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-13 f-grey my-3">
                                            {selectActivePackageDataSearch != "" ? `No results for "${selectActivePackageDataSearch}"` : "No packages yet"}
                                        </div>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
            }
        </div>
    );
};

export default ActivePackageBlock;

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const IP_API_URL = process.env.REACT_APP_IP_API_URL;
export const LIVE_SESSION_CALENDLY_URL = process.env.REACT_APP_LIVE_SESSION_CALENDLY_URL;

export const BASE_API_PAGE_ENUMS = {
  GetTaxesList: 99,
  QuestionNote: 261,
  ToolToYes: 262,
  ArchiveClient: 685,
  CurrentUserDetails: 915,
  CyberSecurityPosture: 965,
  IntegrationByTechType: 985,
  RenameCustomIntegrationName: 986,
  RemoveIntegration: 987,
  CheckToolInUse: 988,
  GetAllTechTypeByIntegration: 1051,
  DeleteIntegration: 1055,
  GetAllTechsByIntegration: 1060,
  CheckServiceName: 1062,
  CheckProductName: 1063,
  GetSuggestions: 1065,
  ReviewSuggestions: 1066,
  EmailToStorefront: 1067,
};

export const BASE_API_ROUTES = {
  validateUserToken: `${BASE_API_URL}/AttackSurfaceAPI/ValidateUser`,
  getAllIntegrationsByTechType: `${BASE_API_URL}/CC_API/GetAllIntegrationsByTechologyType`,
  saveIntegrationsInfo: `${BASE_API_URL}/CC_API/CreateIntegrationInfo`,
  submitNotificationSettings: `${BASE_API_URL}/CC_API/SubmitNotificationSettings`,
  getClientDashboardV2: `${BASE_API_URL}/CC_API/GetClientDashboardV2`,
  getTechnologySpend: `${BASE_API_URL}/CC_API/GetTechnologySpend`,
  getAllAssetSourcesV2: `${BASE_API_URL}/CC_API/GetAllAssetSourcesV2`,
  getUserDeviceIssues: `${BASE_API_URL}/CC_API/GetUserDeviceIssues`,
  createIntegrationsMonitoredSOC: `${BASE_API_URL}/CC_API/CreateIntegrationsMonitoredSOC`,
  getAddSubscriptionSpendCalcs: `${BASE_API_URL}/CC_API/GetAddSubscriptionSpendCalcs`,
  uploadFile: `${BASE_API_URL}/CC_API/UploadFile`,
  getExportPackageReportMenuSort: `${BASE_API_URL}/CC_API/GetExportPackageReportMenuSort`,
  createTaxRate: `${BASE_API_URL}/CC_API/CreateTaxRate`,
  getPagedPackageProduct: `${BASE_API_URL}/CC_API/GetPagedPackageProduct`,
  refreshAccessToken: `${BASE_API_URL}/HGIR/RefreshAccessToken`,
  getUserEmailById: `${BASE_API_URL}/CC_API/GetEmailByUserObjId`,
  hideConfirmVerifiedBanner: `${BASE_API_URL}/CC_API/SetShowConfirmVerified`,
  CreateMultipleIntegrationTechTool: `${BASE_API_URL}/CC_API/CreateMultipleIntegrationTechnologyTool`,
  CheckIntegrationSyncState: `${BASE_API_URL}/CC_API/isClientSyncFinished`,
  generateSubscription: `${BASE_API_URL}/CC_API/GenerateSubscriptionFromJson`,
  getSubscriptionLastInvoiceDate: `${BASE_API_URL}/CC_API/GetSubscriptionLastInvoiceDate`,
  getConfigurationMappingList: `${BASE_API_URL}/CC_API/GetConfigurationMappingObjectList`,
  updatePhoneEmailInSettings: `${BASE_API_URL}/CC_API/UpdateEmailPhoneInSettings`,
  rateAIMapping: `${BASE_API_URL}/CC_API/RateAIMapping`,
  reviewAssetsMapping: `${BASE_API_URL}/CC_API/ReviewAssetsMapping`,
  getRecurringSpend: `${BASE_API_URL}/CC_API/GetMapSpendWithSortAndVisibility`,
}

export const BASE_API_PATHS = {
  getActionCentreSortVisibility: "GetActionCentreSortVisibility",
  getMapSpendWithSortAndVisibility: "GetMapSpendWithSortAndVisibility",
}

export const FILE_TYPES_API = {
  UserProfileImg: 0,
  UserCompanyLogo: 1,
}

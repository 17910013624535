import React from "react";
import MspClientPriceCalculatorRadarChartMerge, { MspClientPriceCalculatorRadar, MspClientPriceCalculatorRadarSmall } from "./MspClientPriceCalculatorRadarChartMerge";
import { MspClientPriceCalculatorEmptyRadarChart } from "../mspSell/mspPriceCalculator/TemplatesModal/PackageView";
import { services_enums_selected } from "../mspSell/mspPriceCalculator";
import CyberComplianceGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import CyberScoreGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import PriceCalculatorPosture from "./PriceCalculatorPosture";
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";
import MspClientPriceCalculatorRadarChartMergeSmall from "./MspClientPriceCalculatorRadarChartMergeSmall";

export const SecurityPostureBlock = ({
    pageData,
    isAnimating,
    ACTIVE_CHART,
    TABS_DATASETS,
    isAnimatingEnd,
    priceCalculatorState,
    securityCapabilitiesData,
    setIsAnimating = () => { },
    setIsAnimatingEnd = () => { },
    activateChartIndex = () => { },
    setPriceCalculatorState = () => { },
}) => {
    const isMobile = useRespondTo(WindowBreakpoints.md);

    const test = {
        PackageScenarioPosture: {
            Identify: 35,
            Protect: 21,
            Detect: 21,
            Respond: 14,
            Recover: 15,
            CyberScore: 100,
            CyberCompliance: 30
        },
        ProjectedScenarioPosture: {
            Identify: 32,
            Protect: -1,
            Detect: -4,
            Respond: -6,
            Recover: -22,
            CyberScore: 44,
            CyberCompliance: 30
        },
        CurrentScenarioPosture: {
            Identify: 3,
            Protect: 22,
            Detect: 25,
            Respond: 20,
            Recover: 37,
            CyberScore: 56,
            CyberCompliance: 0
        }
    }

    return (
        <div className='security-posture-block'
            xs={5}
        >
            <div className="bg-grey-imp radius-8 m-auto mt-4 w-100">
                <div className="p-3">
                    <div className="f-500 f-14 greyText2">
                        Security Posture Comparison
                    </div>
                    <div className="m-auto w-fit-content mt-4">
                        {pageData?.CurrentPosture?.Identify !== 0 ||
                            pageData?.CurrentPosture?.Protect !== 0 ||
                            pageData?.CurrentPosture?.Detect !== 0 ||
                            pageData?.CurrentPosture?.Response !== 0 ||
                            pageData?.CurrentPosture?.Recover !== 0 ||
                            priceCalculatorState?.tabsData?.length > 1 ? (
                            <>
                                {
                                    ACTIVE_CHART == 0 ? <>
                                        {
                                            isMobile ? <MspClientPriceCalculatorRadarSmall
                                                activeChart={ACTIVE_CHART}
                                                _datasets={TABS_DATASETS}
                                            /> : <MspClientPriceCalculatorRadar
                                                activeChart={ACTIVE_CHART}
                                                _datasets={TABS_DATASETS}
                                            />
                                        }</> : <>
                                        {
                                            isMobile ? <MspClientPriceCalculatorRadarChartMergeSmall
                                                activeChart={ACTIVE_CHART}
                                                _datasets={TABS_DATASETS}
                                                merged_Array={priceCalculatorState.mergedStatusArray}
                                                merged_filter={priceCalculatorState.mergedStatusArray.filter((item) => item === true).length}
                                                isAnimating={isAnimating}
                                                setIsAnimating={setIsAnimating}
                                                isAnimatingEnd={isAnimatingEnd}
                                                setIsAnimatingEnd={setIsAnimatingEnd}
                                            /> : <MspClientPriceCalculatorRadarChartMerge
                                                activeChart={ACTIVE_CHART}
                                                _datasets={TABS_DATASETS}
                                                merged_Array={priceCalculatorState.mergedStatusArray}
                                                merged_filter={priceCalculatorState.mergedStatusArray.filter((item) => item === true).length}
                                                isAnimating={isAnimating}
                                                setIsAnimating={setIsAnimating}
                                                isAnimatingEnd={isAnimatingEnd}
                                                setIsAnimatingEnd={setIsAnimatingEnd}
                                            />
                                        }
                                    </>
                                }
                            </>
                        ) : (
                            <MspClientPriceCalculatorEmptyRadarChart
                                activeChart={ACTIVE_CHART}
                                _datasets={TABS_DATASETS}
                            />
                        )}
                    </div>


                </div>


                {
                    priceCalculatorState.activeTab > 0 && <>
                        <div
                            className="w-100 d-flex align-items-center justify-content-center"
                            style={{
                                marginTop: '-30px'
                            }}
                        >
                            <div
                                className={`bg-greyish f-grey mb-2 py-2 px-3 pointer radius-4`}
                                onClick={() => {
                                    let updated_data = { ...priceCalculatorState }
                                    updated_data.mergedStatusArray[updated_data.activeChart] = !updated_data.mergedStatusArray[updated_data.activeChart]
                                    setIsAnimating(true);
                                    console.log('isAnimating - ', isAnimating)
                                    setPriceCalculatorState(updated_data)
                                }}
                            >
                                {!priceCalculatorState.mergedStatusArray[priceCalculatorState.activeChart] ? "Merge" : "Unmerge"}
                            </div>
                        </div>

                        <div className="p-3">
                            <div className="nist-component-table">
                                <div className="f-500 f-12 f-darkgrey">
                                    NIST Component
                                </div>
                                <div className="f-500 f-12 f-darkgrey">
                                    Current
                                </div>
                                <div className="f-500 f-12 f-darkgrey">
                                    Predicted
                                </div>
                            </div>

                            {
                                Object.values(services_enums_selected)?.map((serviceNistItem) => {
                                    return (
                                        <div className="nist-component-table">
                                            <div className="f-400 f-14 f-black">
                                                {serviceNistItem?.DDLText}
                                            </div>
                                            <div className="f-400 f-14 f-grey">
                                                {securityCapabilitiesData?.CurrentScenarioPosture?.[serviceNistItem?.DDLText]}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="f-500 f-14 f-black mr-2">
                                                    {securityCapabilitiesData?.PackageScenarioPosture?.[serviceNistItem?.DDLText]}
                                                </div>
                                                <img
                                                    alt=""
                                                    src={`${securityCapabilitiesData?.ProjectedScenarioPosture?.[serviceNistItem?.DDLText] > 0 ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                                    className="mr-1"
                                                />
                                                <div className={`f-500 f-14 ${securityCapabilitiesData?.ProjectedScenarioPosture?.[serviceNistItem?.DDLText] > 0 ? 'f-green-light' : 'redText'}`}>
                                                    {Math.abs(securityCapabilitiesData?.ProjectedScenarioPosture?.[serviceNistItem?.DDLText])}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="d-flex justify-content-between p-3 border-top">
                            <div>
                                <div className="f-500 f-14 f-grey">
                                    Predicted CyberResilience
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <div className="f-500 f-18 f-black mr-2">
                                        {securityCapabilitiesData?.PackageScenarioPosture?.CyberCompliance}%
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            alt=""
                                            src={`${securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance > 0 ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                            className="mr-1"
                                        />
                                        <div className={`f-500 f-14 ${securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance > 0 ? 'f-green-light' : 'redText'}`}>
                                            {Math.abs(securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance)}%
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CyberComplianceGauge
                                value={securityCapabilitiesData?.PackageScenarioPosture?.CyberCompliance}
                                showTitle={false}
                            />
                        </div>

                        <div className="d-flex justify-content-between p-3 border-top">
                            <div>
                                <div className="f-500 f-14 f-grey">
                                    Predicted CyberScore
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <div className="f-500 f-18 f-black mr-2">
                                        {securityCapabilitiesData?.PackageScenarioPosture?.CyberScore}
                                    </div>
                                    <img
                                        alt=""
                                        src={`${securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore > 0 ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                        className="mr-1"
                                    />
                                    <div className={`f-500 f-14 ${securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore > 0 ? 'f-green-light' : 'redText'}`}>
                                        {Math.abs(securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore)}
                                    </div>
                                </div>
                            </div>

                            <CyberScoreGauge
                                value={securityCapabilitiesData?.PackageScenarioPosture?.CyberScore?.toFixed(1)}
                                showTitle={false}
                            />
                        </div>
                    </>
                }

                {priceCalculatorState.activeTab === 0 && (
                    <div className="p-3 hg-border-top">
                        <PriceCalculatorPosture
                            tabsData={priceCalculatorState.tabsData[0]}
                            activateChartIndex={activateChartIndex}
                        />
                    </div>
                )}
            </div>
            <div className="p-5" />
        </div >
    );
};

export default SecurityPostureBlock;

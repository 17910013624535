import { Button, Spinner } from "react-bootstrap";
import "./sections.styles.scss";
import { useState } from "react";
import AuthChangeInput from "../auth-change-input.component";
import AvatarIcon from "../../icons/avatar.icon";
import EnailSendIcon from "../../icons/email-send.icon";
import SuccessSignIcon from "../../icons/success-sign.icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ROUTE_PATHS } from "../../../constants/app.constants";

const ForgotPasswordSendEmailSection = ({ 
  loading,
  defaultEmail,
  sendPasswordResetEmail,
  timeState,
  emailSent
}) => {
  const [btnText, setBtnText] = useState("Send email");
  const history = useHistory();
  return (
    <div className="forgot-password-section d-flex flex-column" style={{ gap: '16px' }}>
      <AuthChangeInput
        value={defaultEmail}
        onChange={() => {
          history.push(`${ROUTE_PATHS.auth.login}?email=${encodeURIComponent(defaultEmail)}`);
        }}
        icon={(
          <AvatarIcon />
        )}
        className="rounded"
        valueStyles={{ marginLeft: '4px' }}
      />
      <div>
        <div className="send-to-info">
          {emailSent ? (
            <>
              <SuccessSignIcon 
                width="11px" 
                height="8px" 
                fill="#40D88E" 
                style={{ marginTop: '8px', minWidth: '11px' }}
              />
              <span className="f-black">
                {`Email with instructions sent to ${defaultEmail}`}
              </span>
            </>
          ) : (
            <>
              <EnailSendIcon 
                width="14px" 
                height="12px" 
                className="email-send-icon" 
                style={{ minWidth: '14px' }}
              />
              <span className="f-black">
                {`Email with instructions will be sent to ${defaultEmail}`}
              </span>
            </>
          )}
          </div>
      </div>
      <div>
        <Button
          size="lg"
          variant={timeState.seconds > 0 ? "none" : "blue"}
          className={`w-100 f-14 continue-btn ${timeState.seconds > 0 ? "disabled-btn" : ""}`}
          type="submit"
          style={{ padding: "5px" }}
          onClick={() => {
            if (loading) return;
            sendPasswordResetEmail();
            setBtnText("Resend email");
          }}
          disabled={timeState.seconds > 0}
        >
          {loading ? (
            <span>
              <Spinner
                size="sm"
                className="mr8px"
                animation="border"
              />{" "}
              Sending email...
            </span>
          ) : (
            btnText
          )}
        </Button>
        <div className="d-flex justify-content-center" style={{ marginTop: '8px' }}>
          <div
            className="f-500 f-blue "
          >
            {timeState.seconds > 0 && (
            <span className="">{timeState.seconds}s</span>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordSendEmailSection;

import React from "react";

export const IncludedFeaturesBlock = ({
    packageDetails,
}) => {

    return (
        <div>
            <div className="f-600 f-14 f-black my-2 pt-4">
                Included Features
            </div>

            <div className="border-parent  radius-8">
                <div className="features_table border-item px-2 py-1">
                    <div className="f-500 f-12 f-darkgrey">Service</div>
                    <div className="f-500 f-12 f-darkgrey">Features</div>
                </div>
                {
                    packageDetails?.Nist_All?.map((item) => {
                        return (
                            <div className="features_table border-item py-1">
                                <div>
                                    <div className="d-flex">
                                        <img
                                            alt=""
                                            width={14}
                                            height={14}
                                            className="mx-2 mt-1"
                                            src='/images/other-apps-icon.svg'
                                        />
                                        <div>
                                            <div className="f-400 f-14 f-black line-height-22">
                                                {item?.Headline}
                                            </div>
                                            <div className="f-12 f-darkgrey">
                                                {item?.SecurityPillar_Selected?.DDLText && `${item?.SecurityPillar_Selected?.DDLText} • `}
                                                {
                                                    item?.MonthlyPrice_Text
                                                }{" "}
                                                {
                                                    item
                                                        ?.SnapToUnit_Selected
                                                        ?.DDLColour
                                                }{" "}
                                                /
                                                {
                                                    item
                                                        ?.BillingPeriod_Selected
                                                        ?.DDLColour
                                                }{" "}
                                                <span className="f-12 txt-blue">
                                                    {item?.IsOnDemand ? ' • POD' : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {item?.SecurityPack_Bullet_List?.length > 0 ? (
                                        <>
                                            {item?.SecurityPack_Bullet_List?.map((item) => {
                                                return (
                                                    <div className="d-flex align-items-center my-1 f-12 f-400 f-black">
                                                        <img
                                                            alt=""
                                                            className="ml-1 mr-2"
                                                            src="/images/msp/green-circular-tick.svg"
                                                        />
                                                        {item?.Text}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <div className="d-flex align-items-center my-2 f-grey f-12">
                                            This service has no features
                                        </div>
                                    )}

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default IncludedFeaturesBlock;

import React from "react";
import { IncreaseDecreaseNumbersComponentAlwaysHighlight } from "../../../mspSell/mspPriceCalculator/MspAddEditServiceModal";
import useRespondTo from "../../../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../../../constants/window.constants";

export const SeatPricingTable = ({
  switchVal,
  packageDetails,
  seatBreakDownData,
  handleSave = () => { }
}) => {
  const isMobile = useRespondTo(WindowBreakpoints.md);

  return (
    <div className="my-3">
      <div className="f-500 f-12 f-darkgreym b-1">
        Seat Pricing
      </div>

      <div className="border-block  radius-8">
        {
          switchVal &&
          <div className={`border-item f-12 f-500 f-darkgrey px-2 py-2 ${isMobile ? "seat_pricing_table_mobile" : "seat_pricing_table" }`}>
            <div className="f-400 f-14 f-black">Seat Cost</div>
            <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{seatBreakDownData?.MSPPackagePrice?.Cost_Text}</div>
          </div>
        }

        <div className={`border-item f-12 f-500 f-darkgrey px-2 py-2 ${isMobile ? "seat_pricing_table_mobile" : "seat_pricing_table" }`}>
          <div className="f-400 f-14 f-black">Seat RRP</div>
          <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{seatBreakDownData?.MSPPackagePrice?.RRP_Text?.replace("/", " /")}</div>
        </div>

        <div className={`border-item f-12 f-500 f-darkgrey px-2 ${isMobile ? "seat_pricing_table_mobile" : "seat_pricing_table" }`}>
          <div className="f-400 f-14 f-black">Seat Price</div>
          <div className="d-flex"
            style={{
              marginBottom: '4px',
              marginLeft: '-14px'
            }}>
            <IncreaseDecreaseNumbersComponentAlwaysHighlight
              type={"number"}
              value={seatBreakDownData?.MSPPackagePrice?.SellOverridden}
              isBlue={seatBreakDownData?.MSPPackagePrice?.IsSellEddited}
              children={<div className={`f-400 f-14 ${seatBreakDownData?.MSPPackagePrice?.IsSellEddited ? 'txt-blue' : 'f-black'}`}
                style={{ marginBottom: '1px' }}
              >
                {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              </div>}
              children2={<div className={`f-400 f-14 ${seatBreakDownData?.MSPPackagePrice?.IsSellEddited ? 'txt-blue' : 'f-black'}`}>
                /{seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
              </div>}
              onChange={(value) => {
                if (seatBreakDownData.MSPPackagePrice.SellOverridden != value) {
                  let updated_data = { ...seatBreakDownData };
                  updated_data.MSPPackagePrice.SellOverridden = value;
                  updated_data.MSPPackagePrice.IsSellEddited = true;

                  // handleSave();
                  return true;
                }
              }}
              onBlur={() => {
                console.log('save - 2')
                handleSave();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatPricingTable;

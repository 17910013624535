import CrossIcon from "../icons/cross.icon";
import UserDetailsButton from "../MSPv2/user-details.button";
import './user-sidebar-menu.styles.scss';

import { createPortal } from 'react-dom';

const UserSidebarMenu = ({
  isSidebarOpen,
  setIsSidebarOpen,
  userDetails,
  children,
  isSaas
}) => {
  const onClose = () => {
    setIsSidebarOpen(false);
  }

  const sidebarContent = (
    <div 
      className={`msp-header-sidebar f-white ${isSidebarOpen ? 'open' : ''}`}
      onClick={onClose}
    >
      <div 
        className="sidebar-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="header">
          <div className="header-logo-dropdown thumbnail-pic">
            <UserDetailsButton userDetails={userDetails} darkTheme={false} isSaas={isSaas} />
          </div>
          <CrossIcon 
            width="16px"
            height="16px"
            fill="#fff"
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="body hide-scrollbar">
          {children}
        </div>
      </div>
    </div>
  );

  return createPortal(
    sidebarContent,
    document.body
  );
}

export default UserSidebarMenu;

import React from "react";
import { Dropdown, Nav, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SwitchButtonMulti from "../../Theme/switchButtonMulti";
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";
import { ROUTE_PATHS } from "../../../constants/app.constants";

export const ManagedSecurityHeader = ({
    priceCalculatorState,
    isSmthOverriden,
    clientId,
    pageData,
    activePackageFound,
    switchIncludedTaxVal,
    packageDetails,
    _data,
    switchVal,
    toggleSwitch = () => { },
    setLoading = () => { },
    getPageData = () => { },
    getPackagesPageData = () => { },
    setPriceCalculatorState = () => { },
    removePackage = () => { },
    AddScenario = () => { },
    setExportManagedSecurityPackageId = () => { },
    setShowExportManagedSecurityPackageModal = () => { },
    setActiveOrInactivePackageId = () => { },
    setShowUpdateCyberKPIModal = () => { },
    setIsActiveOrInactive = () => { },
    setMapCurrentSpendPackageId = () => { },
    setMapCurrentSpendPackagePeriod = () => { },
    setMapCurrentSpendPackagePriceText = () => { },
    setMapCurrentSpendPackagePrice = () => { },
    setShowMapCurrentSpendModal = () => { },
    setIsFromAddContribTable = () => { },
    checkPackageIfInUse = () => { },
}) => {
    const history = useHistory();
    const isLaptop = useRespondTo(WindowBreakpoints.xl);
    const isMobile = useRespondTo(WindowBreakpoints.md);

    return (
        <div
            className="managed-security-header"
            id="navHeader"
        >
            <div className="left-side-header">
                {priceCalculatorState?.tabsData?.map((tab, key) => (
                    <Nav.Item
                        key={key}
                        className={`${key === 0 ? "" : ""} mt-2`}
                    >
                        {key === 0 && (
                            <Nav.Link
                                eventKey={key}
                                className={`navlinks all-scenario-navlink ${priceCalculatorState.activeTab === 0 ? "active" : ""
                                    }`}
                                onClick={() => {
                                    localStorage.removeItem("activePackageName");
                                    let updated_data = { ...priceCalculatorState };
                                    updated_data.activeTab = key;
                                    updated_data.activeChart = key;
                                    updated_data.mergedStatusArray = updated_data.mergedStatusArray.map((value, index) => false);

                                    setPriceCalculatorState(updated_data);
                                    if (isSmthOverriden) {
                                        setLoading(true);
                                        getPageData();
                                        getPackagesPageData()
                                    }
                                }}
                            >
                                <div className="d-flex align-items-center circleHeader  mr-2">
                                    <span
                                        style={{
                                            color: `${priceCalculatorState.activeTab === 0
                                                ? "#008FD4"
                                                : ""
                                                }`,
                                        }}
                                        className="ml-2- mr-2"
                                    >
                                        <img
                                            src={`/images/calculator/${priceCalculatorState.activeTab === 0
                                                ? "allscenariob.svg"
                                                : "allscenariog.svg"
                                                }`}
                                            // onClick={() => removeScenario(key)}
                                            className="ml-1- mr-2"
                                            alt=""
                                        />
                                        {isMobile ? 'All' : 'All packages'}
                                    </span>
                                </div>
                            </Nav.Link>
                        )}

                        {key > 0 && (
                            <Nav.Link
                                href={"javascript:void(0)"}
                                className={`navlinks ${priceCalculatorState.activeTab === key
                                    ? "active"
                                    : ""
                                    }`}
                                eventKey={key}
                                onClick={() => {
                                    localStorage.removeItem("activePackageName");
                                    localStorage.setItem("activePackageName", tab?.Name);
                                    let updated_data = { ...priceCalculatorState };
                                    updated_data.activeTab = key;
                                    updated_data.activeChart = key;
                                    updated_data.mergedStatusArray = updated_data.mergedStatusArray.map((value, index) => false);

                                    setPriceCalculatorState(updated_data);
                                }}
                            >
                                <div className="d-flex align-items-center mr-2">
                                    <div
                                        className="circleDiv"
                                        style={{
                                            background: `${tab.circleColour}`,
                                        }}
                                    />
                                    <span
                                        style={{
                                            color: `${priceCalculatorState.activeTab === key
                                                ? "#008FD4"
                                                : ""
                                                }`,
                                        }}
                                        className="ml-2 mr-2"
                                    >
                                        {tab?.Name}
                                        <img
                                            src={`/images/calculator/close1.svg`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removePackage(key);
                                                localStorage.removeItem("activePackageName");
                                            }}
                                            className="ml-3 close-icon"
                                            alt=""
                                        />
                                    </span>
                                </div>
                            </Nav.Link>
                        )}
                    </Nav.Item>
                ))}
                <Nav.Item className={"disabled mt-2"}>
                    <Nav.Link
                        className={`${priceCalculatorState.tabsData.length === 11
                            ? "disabled"
                            : ""
                            } cursor-default`}
                        onClick={() => {
                            if (!priceCalculatorState?.newScenarioCreating) {
                                AddScenario();
                            }
                        }}
                    >
                        <div className="d-flex align-items-center circleHeader mr-4 addscn cursor-default">
                            {priceCalculatorState?.newScenarioCreating ? (
                                <span className="d-inline-flex align-items-center">
                                    <Spinner
                                        animation="border"
                                        variant="secondary"
                                        size="sm"
                                        className="mx-2"
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            marginBottom: "2px",
                                        }}
                                    />
                                    <span className="d-inline-flex align-items-center">
                                        {priceCalculatorState?.scenarioLoadingText}
                                    </span>
                                </span>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    </Nav.Link>
                </Nav.Item>
            </div>

            <div className="right-side-header">
                {!isLaptop &&
                    priceCalculatorState.activeTab !== 0 &&
                    <span
                        className="mr-3 f-500 f-14 greyText2 pointer"
                        onClick={() => {
                            setExportManagedSecurityPackageId(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                            setShowExportManagedSecurityPackageModal(true);
                        }}
                    >
                        <img alt="" width={15} height={17} className="mr-2" src="/images/msp/export-supplier-icon.svg" />
                        Export
                    </span>
                }
                {
                    priceCalculatorState.activeTab !== 0 ?
                        <Dropdown className="transparennt-wrapper-dropdown-menu">
                            <Dropdown.Toggle>
                                <img
                                    alt=""
                                    className="pointer mx-2"
                                    src="/images/settings-v2/horizontal-dots.svg"
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-0">
                                <div
                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={() => {
                                        history.push(ROUTE_PATHS.mspv2.priceCalculator(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario), {
                                          backUrl: ROUTE_PATHS.mspv2.clients.securityPackage(clientId),
                                          allPackagesList: pageData?.ScenarioHheaderList,
                                          clientId: clientId,
                                        });
                                      }}
                                >
                                    Edit
                                </div>
                                <div
                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={(e) => {
                                        if (priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.IsPackageActive == false) {
                                            setActiveOrInactivePackageId(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                            setShowUpdateCyberKPIModal(true);
                                        } else {
                                            setIsActiveOrInactive(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario, 0);
                                        }
                                        e.target
                                            .closest(".transparennt-wrapper-dropdown-menu")
                                            .click();
                                    }}
                                >
                                    Make {activePackageFound?.objId_Scenario == priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario ? 'Inactive' : 'Active'}
                                </div>
                                <div
                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={(e) => {
                                        setMapCurrentSpendPackageId(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                        setMapCurrentSpendPackagePeriod(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.BillingPeriod_Text)
                                        setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal + priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Tax).toFixed(2)} /${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                                        setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal).toFixed(2) : priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal);
                                        setShowMapCurrentSpendModal(true);
                                        setIsFromAddContribTable(true);
                                        const drag = document?.getElementById("map-current-spend-modal");
                                        drag.style.display = "block";
                                        e.target
                                            .closest(".transparennt-wrapper-dropdown-menu")
                                            .click();
                                    }}
                                >
                                    Add Contribution
                                </div>
                                <div className="hg-border-top mx-3"></div>
                                <div>
                                    {_data?.UserDetail?.Role?.toLowerCase() === "msp" && (
                                        <div
                                            className="d-flex align-items-center py-2 px-3 bg-hover-dark-grey"
                                            onClick={(e) => {
                                                // toggleSwitch(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                            }}
                                        >
                                            <div
                                                className="greyText2 f-14 f-400 mr-2"
                                            >
                                                Extended details
                                            </div>
                                            <SwitchButtonMulti
                                                id={`switch_toggle_button_${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario}`}
                                                // label={"extended details"}
                                                value={switchVal}
                                                onChange={(e) => {
                                                    toggleSwitch(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="hg-border-top mx-3"></div>
                                <div
                                    className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={(e) => {
                                        checkPackageIfInUse(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                        e.target
                                            .closest(".transparennt-wrapper-dropdown-menu")
                                            .click();
                                    }}
                                >
                                    Delete
                                </div>
                            </Dropdown.Menu>
                        </Dropdown> : <></>
                }
            </div>
        </div>
    );
};

export default ManagedSecurityHeader;

const TemporarySupportAccessIcon = (props) => (
  <svg width="16" height="16" {...props} className={`fill-current ${props?.className ? props?.className : ''}`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_56250_71465)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99463 2C1.63663 2 1.33329 2.3 1.33329 2.67133V11.3333H14.6666V2.67133C14.6666 2.3 14.3706 2 14.0053 2H1.99463ZM15.3333 12.6667H0.666626V14H15.3333V12.6667ZM7.69063 8.90933L5.33329 6.552L6.27663 5.60933L7.69063 7.024L10.048 4.66667L10.9906 5.60933L7.69063 8.90933Z" />
    </g>
    <defs>
    <clipPath id="clip0_56250_71465">
    <rect width="16" height="16" />
    </clipPath>
    </defs>
  </svg>
);

export default TemporarySupportAccessIcon;

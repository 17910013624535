import { useEffect, useState } from "react";
import useIntegrationActions from "../../../../hooks/actions/integrations.actions";
import useApiActions from "../../../../hooks/api-actions.hook";

const useSuggestionsData = ({ show }) => {
  const [data, setData] = useState([]);
  const [isFetchingLoading, setIsFetchingLoading] = useState(true);
  const { getSuggestions } = useIntegrationActions();
  const { createMultipleIntegrationTechTool } = useApiActions();

  const fetchData = async ({ clientId }) => {
    setIsFetchingLoading(true);
    const response = await getSuggestions({ clientId });
    setData(response?.IntegrationTechnologyToolSuggestion_List);
    setIsFetchingLoading(false);
  }

  const onApply = async ({
    IntegrationTechList, ObjectId, Selected, ToolName, customerId
  }) => {
    const response = await createMultipleIntegrationTechTool({ 
      IntegrationTechList, ObjectId, Selected, ToolName, customerId
    });
    return response;
  }

  useEffect(() => {
    if (show) return;
    setData([]);
    setIsFetchingLoading(true);
  }, [show]);

  return {
    data,
    isFetchingLoading,
    fetchData,
    onApply
  }
}

export default useSuggestionsData;
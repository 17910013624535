import Lottie from "react-lottie-player";
import Dashboard from "../Dashboard/Dashboard"
import PrivateRoute from "../PrivateRoute/PrivateRoute"
import { Link, useLocation, useHistory } from "react-router-dom";
import * as animationData from "../Spinner/loader.json";
import { useEffect, useRef, useState } from "react";
import { APP_ROUTES } from "../../constants/app.constants";

const SaaSRouterLayout = () => {
  const history = useHistory();
  const rowElement = useRef(null);
  const [dashboardLoader, setDashboardLoader] = useState(localStorage.getItem("dasboardLoader") === "true");

  useEffect(() => {
      let demo = 0;
      const interval = setInterval(function () {
        demo = demo + 1;
        if (rowElement.current) {
          rowElement.current.style.width = `${demo}%`;
        } else {
          clearInterval(interval);
        }
        if (demo >= 95) {
          clearInterval(interval);
        }
      }, 40);
  }, []);

  if (dashboardLoader) {
    return (
      <div className="center-container-loader">
        <div className="center-container">
          <Lottie
            loop={false}
            play={true}
            animationData={animationData.default}
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice",
              filterSize: {
                width: "100%",
                height: "100%",
                x: "-50%",
                y: "-50%",
              },
            }}
            onComplete={() => {
              localStorage.setItem("dasboardLoader", null);
              setDashboardLoader(false);
            }}
            style={{ width: 320, height: 231, margin: "0 auto" }}
            width={320}
            height={231}
          ></Lottie>
          <div className="outsideLoader">
            <div className="custom_loader">
              <div className="custom_bar" ref={rowElement}></div>
            </div>
          </div>
          <p className="smallGrey custom_text">
            It is taking longer than usual to load this page.
            <br /> Please wait...
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="fullHeight w-100 h-100 overflow-hidden">
      <PrivateRoute
        exact
        path={APP_ROUTES.client.dashboard}
        component={
          Dashboard
        }
      />
    </div>
  )
}

export default SaaSRouterLayout;
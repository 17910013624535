import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import Dashboard from "../Dashboard/Dashboard";
import TrendData from "../TrendData/TrendData";
import ForgottenPasswordForm from "../ForgottenPassword/ForgottenPasswordForm";
import ResetPasswordForm from "../ResetPassword/ResetPassword";
import SideNav from "../SideNav/SideNav";
import { Button, Col, Row } from "react-bootstrap";
import { dbContext } from "../context/db";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import MFA from "../MFA/MFA";
import { AuthContext, useAuth } from "../context/Auth";

import { useIdleTimer } from "react-idle-timer";
import { getAPIData, refreshAccessToken } from "../../Utils/commonApi";

import SettingsIndex from "../Settings/SettingsIndex";
import SystemSettings from "../SystemSettings/index";
import UserProfile from "../SystemSettings/UsersAndDevices/userProfile";
import PersonalSettings from "../PersonalSettings/index";
import IntegrationIndex from "../Integrations/IntegrationIndex";
import LoadingSpinner from "../Spinner/Spinner";

import ErrorPage from "../Error";
import SessionPage from "../Error/session";
import PriceCalculator from "../PriceCalculator";

import DesignSystem from "../DesignSystem";
import ActionCenter from "../ActionCenter";
import { Design } from "./Design";
import "../../responsive.scss";
import { hotjar } from "react-hotjar";
import SettingsSideNav from "../settingsV2/SettingsSideNav";
import ActiveSessions from "../settingsV2/activeSessions/ActiveSessions";
import ResetSettings from "../settingsV2/resetSettings/ResetSettings";
import CompanySettings from "../settingsV2/companySettings/CompanySettings";
import Members from "../settingsV2/members/Index";
import User from "../settingsV2/members/User";
import CSuite from "../settingsV2/c-suite/Index";
import Subscriptions from "../settingsV2/subscriptions/Index";
import BillingV2 from "../settingsV2/billing/Index";
import SettingsV2Header from "../settingsV2/SettingsV2Header";
import PersonalProfileV2 from "../settingsV2/personal-profile/Index";
import NotificationsV2 from "../settingsV2/notifications/Index";
import { getMenuCollapseStatus } from "../../redux/highgroundGlobalState/selectors";
import { createStructuredSelector } from "reselect";
import { menuToggle } from "../../redux/highgroundGlobalState/actions";
import RewardsV2 from "../settingsV2/rewards/Index";
import ActionCentreV2 from "../settingsV2/action-center-notification/Index";
import { useSelector } from "react-redux";
import userflow from "userflow.js";

import { clearLocalStorage } from "../../Utils/clearLocalStorage";
import AttackSurface from "../AttackSurface/Index";
import AuditLogs from "../../AuditLogs";

import PersonalProfileForMobile from "../settingsV2/personal-profile/personalProfileForMobile";
import Oauth2 from "../Integrations/Oauth2";
import IntegrationsV2 from "../Integrationsv2";
import Logout from "../Logout/Logout";
import AttackSurfaceInsights from "../AttackSurfaceInsights";
import IntegrationDetailsPage from "../Integrationsv2/IntegrationDetailsPage";
import { ResendEmail } from "../../Utils/VerifyEmail";
import Testt from "../MSPv2/mspSell/mspPriceCalculator/Testt";
import Handle3DS from "../settingsV2/subscriptions/subscriptionModals/Handle3DS";
import Loginv2 from "../Loginv2/Loginv2";
import ForgotPassword from "../Loginv2/ForgotPassword";
import ResetPassword from "../Loginv2/ResetPassword";
import SignUpv2 from "../Loginv2/SignUpv2";
import SupplierProfiler from "../supplierProfiler/index";
import AnonymousLogin from "../Loginv2/AnonymousLogin";
import Registrationv2 from "../Loginv2/Registrationv2";
import NoAccessToHighground from "../Common/NoAccessToHighground";
import NoAccessToMSP from "../Common/NoAccessToMSP";
import AnonymousContributorsView from "../supplierProfiler/anonymousContributorsView/AnonymousContributorsView";
import TechnicalDrillLoader from "../../Utils/Loaders/TechnicalDrillLoader";
import ComplianceManagerLoader from "../../Utils/Loaders/ComplianceManagerLoader";
import MSPLoginLoader from "../../Utils/Loaders/MSPLoginLoader";
import MembersAccess from "../settingsV2/membersAccess/MembersAccess";
import SaasMemberDetails from "../settingsV2/membersAccess/SaasMemberDetails";

import { MSP_CATEGORY_SORT_FILTER_TOOLTIP } from "../../Utils/StringConstants";
import SaasTechnologySpend from "../TechnologySpend/SaasTechnologySpend";
import GovernaceResilence from "../GovernanceResilence/GovernaceResilence";
import SupplierProfilerHomeScreen from "../supplierProfiler/SupplierProfilerHomeScreen";
import SaasSubscriptionV2 from "../settingsV2/subscriptions/SaasSubscriptionV2";
import { PopupButton } from "react-calendly";
import SubscriptionRequests from "../SubscriptionBackendPortal/requests/SubscriptionRequests";
import MspSubscriptionAccounts from "../SubscriptionBackendPortal/mspAccountsSubscription/MspSubscriptionAccounts";
import MspSubscriptionAccountDetails from "../SubscriptionBackendPortal/mspAccountsSubscription/MspSubscriptionAccountDetails";
import SubscriptionPortalFinance from "../SubscriptionBackendPortal/finance/SubscriptionPortalFinance";
import SubscriptionOverview from "../SubscriptionBackendPortal/overview/SubscriptionOverview";
import { FullScreenLoader } from "../Common/loader";
import Marketplace from "../Marketplace/Marketplace";
import { ArcElement, Chart, LineElement, PointElement, RadialLinearScale, CategoryScale, LinearScale, Filler } from "chart.js";
import SaaSRouterLayout from "../SAAS/saas-router.layout";
import MarketplaceComingSoon from "../Marketplace/MarketplaceComingSoon";
import { LIVE_SESSION_CALENDLY_URL } from "../../constants/apis.constants";
import useWindowSizes from "../../hooks/window-sizes.hook";
import Sidebar from "../Common/sidebar/sidebar.component";
import { APP_PORTALS, APP_ROUTES, ROUTE_PATHS } from "../../constants/app.constants";
import ClientSettingsLayoutRoutes from "../ClientMSPSettingsV2/client-settings-layout.routes";
// import RefreshTokenComponent from "./refresh-token.component";

Chart.register(RadialLinearScale, PointElement, LineElement, ArcElement, CategoryScale, LinearScale, Filler);

const MSPV2 = lazy(() => import("../MSPv2"));
const ClientMSPSettingsV2 = lazy(() => import("../ClientMSPSettingsV2"));
const MSPSettingsV2 = lazy(() => import("../MSPSettingsv2"));
const MspAttackSurface = lazy(() =>
  import("../MSPv2/MspAttackSurface.js/MspAttackSurface")
);
const StepForm = lazy(() => import("../Form/StepForm"));
const TDrill = lazy(() => import("../TechnicalDrillDown/TechnicalDrillDown"));
const TechnicalDrilldownRoutes = lazy(() =>
  import("../../route/TechnicalDrilldownRoutes")
);
const ComplianceMainManager = lazy(() =>
  import("../ComplianceManager/ComplianceGovernanceMain")
);
const ComplianceManagerRoutes = lazy(() =>
  import("../../route/ComplianceManagerRoutes")
);

// Function to preload MSP components
const preloadMspComponents = async (type) => {
  if (type == "msp") {
    await Promise.all([
      import("../MSPSettingsv2"),
      import("../MSPv2/MspAttackSurface.js/MspAttackSurface"),
      import("../ClientMSPSettingsV2"),
      import("../MSPv2"),
    ]);
  }
  if (type == "techDrilldown") {
    await Promise.all([
      import("../TechnicalDrillDown/TechnicalDrillDown"),
      import("../ComplianceManager/ComplianceGovernanceMain"),
    ]);
  }
  // Optionally, you can perform additional actions after preloading, if needed
};

// let timer = get_json_parse_or_null(localStorage.getItem("InActivityTimer"),0) ?? 0;

// let userLastActivityTime = get_json_parse_or_null(localStorage.getItem("LastActivityTime"),Math.floor(Date.now() / 60000)) ?? Math.floor(Date.now() / 60000)

function App(props) {
  const isMenuCollapse = useSelector((state) => state.global.menuCollapse);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const URLpath = window.location.pathname.split("/");
  const settings_V2_exist = URLpath[1] === "settings" && URLpath[2] === "v2";
  const user_data = JSON.parse(localStorage.getItem("object_Data"));
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const existingAuth = JSON.parse(localStorage.getItem("userAuthenticated"));
  // const [auth, setAuth] = useState(existingAuth);
  const [auth, setAuth] = useState(false);
  const [clientDetail, setClientDetail] = useState();
  const mainBodyRef = useRef(null);

  useEffect(() => {
    // Preload MSP components when the app mounts
    if (CURRENT_URL && CURRENT_URL.toLowerCase().includes("msp")) {
      preloadMspComponents("msp");
    } else if (
      (CURRENT_URL && CURRENT_URL.toLowerCase().includes("tech")) ||
      userDetails?.FullName
    ) {
      preloadMspComponents("techDrilldown");
    } else if (
      (CURRENT_URL && CURRENT_URL.toLowerCase().includes("Compliance")) ||
      userDetails?.FullName
    ) {
      preloadMspComponents("techDrilldown");
    }
  }, [userDetails]);

  // Userflow start  script---START
  // Production: ct_nzefriiwybff5aqzhdcfpqy2uq
  // Staging:  ct_va7xinx7gnhsnp5vb5mxd6qpb4
  try {
    // USERFLOW CODE
    // userflow.init("ct_va7xinx7gnhsnp5vb5mxd6qpb4");
    // userflow.identify(userDetails?.UserName || "test", {
    //   name: userDetails?.FullName,
    //   email: userDetails?.UserName,
    //   // signed_up_at: "2019-12-11T12:34:56Z",
    // });
  } catch (err) {}

  // restrict user to MSP portal
  useEffect(() => {
    const userData = window.localStorage.getItem("object_Data")
      ? JSON.parse(window.localStorage.getItem("object_Data"))
      : null;
    // return
    if (userData?.allowMSP === false) {
      if (window.location.pathname.split("/")[1] === APP_PORTALS.mspv2.split("/")[1]) {
        history.push(ROUTE_PATHS.noAccessToMsp);
      }
    }
    const Highground_Paths = [
      "ceo",
      "cto",
      "cfo",
      "integrations",
      "attack_surface",
      "trend_data",
      "technical_drilldown",
      "Compliance_Manager",
      "pro_services",
      "supply",
      "managed_security",
      "marketplace"
    ];
    if (userData?.allowMSP === true) {
      if (Highground_Paths.includes(window.location.pathname.split("/")[1])) {
        history.push(ROUTE_PATHS.noAccessToHighground);
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    // Run only if there is no token exist in Local storage
    // let userAgent = navigator.userAgent;
    // if(userAgent.match(/firefox|fxios/i) && !window.localStorage.getItem("tokens")){
    //   window.localStorage.clear()
    //   window.location.replace("/login")
    // }

    // Run only if the token is removed from Localstorage
    let runOnce = 0;
    const localStorageSetHandler = function (e) {
      if (
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("signup")
      ) {
        return;
      }
      if (e.key === "tokens") {
        if (runOnce > 0) return;

        runOnce += 1;
        setTimeout(() => {
          runOnce = 0;
        }, 2000);

        if (true) {
          window.localStorage.clear();
          setAuth(false);

          history.push({
            pathname: ROUTE_PATHS.auth.login,
            state: {
              // redirectUser: true,
              // redirectUrl: `${window.location.pathname}${window.location.search}`,
            },
          });
        }
      }
    };

    document.addEventListener("tokenRemoved", localStorageSetHandler, false);
  }, []);

  // Segment integration
  // window.analytics.identify(userDetails?.UserName || "test", {
  //   userName: userDetails?.Fullname,
  //   userEmail: userDetails?.UserName,
  //   unsubscribed: false,
  // });

  // To find last Pass pop-up and to Fix its UI
  const myInterval = setInterval(startSolving, 1000);
  function startSolving() {
    // var ifrm1 = document.querySelector("[name=LPFrame]");
    var ifrm1 = document.querySelector("[data-lastpass-iframe='true']");

    // console.log(ifrm1);
    if (ifrm1) {
      const tets = ifrm1?.parentElement;
      // console.log(tets);
      tets.style.zoom = 1.5;
      setTimeout(() => clearInterval(myInterval), 100);
    } else {
      setTimeout(() => clearInterval(myInterval), 15000);
    }
  }
  // ---------------------

  // Code to clear cache during page Refresh

  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //       console.log("caches", name);
  //     });
  //   });
  //   console.log("Complete Cache Cleared");
  // };

  // useEffect(() => {
  //   clearCacheData();
  // }, []);

  // get Action Centre Data

  // --FOR PRODUCTION---
  hotjar.initialize(2974856, 6);

  //-- FOR STAGING
  hotjar.initialize(2975178, 6);

  // Identify the user
  hotjar.identify("USER_ID", { userProperty: user_data?.UserDetail?.UserName });

  // Add an event
  hotjar.event("button-click");

  // Update SPA state
  hotjar.stateChange("/");

  //Constants
  const CURRENT_URL = useLocation().pathname;
  if (CURRENT_URL.includes("Logout") || CURRENT_URL.includes("logout")) {
    console.log("Routing towards Logout");
    let element = document.getElementById("root").firstChild;

    if (element) {
      element.classList.add("fadeOut");
    }

    setTimeout(() => {
      clearLocalStorage();
      // window.location.href = "/login";
      return <Redirect to={APP_ROUTES.auth.login}/>;
    }, 500);
  }

  //States and Persistant Storages
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));

  const existingDb = JSON.parse(localStorage.getItem("object_Data"));
  const existingUserType = JSON.parse(localStorage.getItem("userType"));
  const existingDrilldown = JSON.parse(localStorage.getItem("techDrilldown"));
  const existingUserMessage = JSON.parse(
    localStorage.getItem("user_MessageObj")
  );

  const [dbObj, setdbObject] = useState(existingDb);
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [qrCode, setQrCode] = useState();

  const [userType, setUserType] = useState(existingUserType);
  const [drilldown, setDrillDown] = useState(existingDrilldown);
  const [messageEncrypt, setMessageEncrypt] = useState(existingUserMessage);
  const windowSizes = useWindowSizes();
  const [loadingScreen, setLoadingScreen] = useState();
  const pageScreen = JSON.parse(localStorage.getItem("currentUrl"));

  useEffect(() => {
    setAuthTokens(existingTokens);
  }, [existingTokens]);

  const [resfreshTokenInterval, setResfreshTokenInterval] = useState(null);

  useEffect(() => {
    if (!authTokens) return;
    refreshAccessToken((new_token) => {
      setTokens(new_token);
    });
  }, []);

  useEffect(() => {
    if (resfreshTokenInterval) {
      clearInterval(resfreshTokenInterval);
      setResfreshTokenInterval(null);
    }
    if (!authTokens) return;
    const interval = setInterval(() => {
      refreshAccessToken((new_token) => {
        setTokens(new_token);
      });
    }, 1000 * 60 * 20);
    setResfreshTokenInterval(interval);
  }, [authTokens]);


  const [ViewingMSP, setViewingMSP] = useState(
    localStorage.getItem("ViewingMSP")
  );
  const [ViewingMSPPageType, setViewingMSPPageType] = useState(
    localStorage.getItem("ViewingMSPPageType")
  );
  const [impersinatingDetails, setImpersinatingDetails] = useState(
    JSON.parse(localStorage.getItem("impersinatingDetails"))
  );

  const setImpersinatingDetailsObj = (data) => {
    if (!data) localStorage.removeItem("impersinatingDetails");
    else localStorage.setItem("impersinatingDetails", JSON.stringify(data));
    setImpersinatingDetails(data);
  };

  const setViewingMspObj = (data) => {
    if (!data) localStorage.removeItem("ViewingMSP");
    else localStorage.setItem("ViewingMSP", JSON.stringify(data));
    setViewingMSP(data);
  };
  const setViewingMspPageTypeObj = (data) => {
    if (!data) localStorage.removeItem("ViewingMSPPageType");
    else localStorage.setItem("ViewingMSPPageType", JSON.stringify(data));
    setViewingMSPPageType(data);
  };

  const setDrilldownObj = (data) => {
    // Testing
    if (!data) localStorage.removeItem("techDrilldown");
    else localStorage.setItem("techDrilldown", JSON.stringify(data));
    setDrillDown(data);
  };

  const setDBObj = (data) => {
    if (!data || data === null || data === undefined) {
      localStorage.removeItem("object_Data");
      return;
    } else localStorage.setItem("object_Data", JSON.stringify(data));
    setdbObject(data);
  };

  const setUserTypeObj = (data) => {
    if (!data) localStorage.removeItem("userType");
    else localStorage.setItem("userType", JSON.stringify(data));
    setUserType(data);
  };

  const setTokens = (data) => {
    if (!data) localStorage.removeItem("tokens");
    else localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  const setAuths = (data) => {
    if (!data) localStorage.removeItem("userAuthenticated");
    else localStorage.setItem("userAuthenticated", JSON.stringify(data));
    setAuth(data);
  };

  const setMessage = (data) => {
    if (!data) localStorage.removeItem("user_MessageObj");
    else localStorage.setItem("user_MessageObj", JSON.stringify(data));
    setMessageEncrypt(data);
  };
  function determineUserRole(role) {
    switch (role) {
      case 111111:
        return "ceo";
      case 222222:
        return "cto";
      case 333333:
        return "cfo";
      case 555555:
        return "msp";
      default:
        return "ceo";
    }
  }
  function getUsersRole() {
    // if (userType === 444444) {
    //   if (impersinatingDetails)
    //     return determineUserRole(impersinatingDetails.role);
    //   return "ceo";
    // }
    return determineUserRole(userType);
  }

  if (
    authTokens &&
    auth
    // && loader == "true"
  ) {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("route");
    const tab = query.get("tab");
    if (CURRENT_URL.includes("redirect")) {
      if (tab == "subscriptions") {
        localStorage.setItem("redirectTab", true);
        history.push("/system_settings");
      }
      if (tab == "billing") {
        history.push("/system_settings?tab=billing");
      } else if (page == "system_settings") {
        history.push("/system_settings");
      } else if (page == ROUTE_PATHS.saas.settings.subscriptions) {
        history.push(ROUTE_PATHS.saas.settings.subscriptions);
      } else if (page == ROUTE_PATHS.saas.integrations) {
        if (tab == "Patch_Management") {
          localStorage.setItem("redirectTab", "Patch Management");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Endpoint_Protection") {
          localStorage.setItem("redirectTab", "Endpoint Protection");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Email_Filtering") {
          localStorage.setItem("redirectTab", "Email Filtering");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "SIEM_IDS") {
          localStorage.setItem("redirectTab", "SIEM IDS");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Web_Filtering") {
          localStorage.setItem("redirectTab", "Web Filtering");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Dark_Web_Monitoring") {
          localStorage.setItem("redirectTab", "Dark Web Monitoring");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Phishing_Training") {
          localStorage.setItem("redirectTab", "Phishing Training");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Website_Scan") {
          localStorage.setItem("redirectTab", "Website Scan");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "External_Network_Scan") {
          localStorage.setItem("redirectTab", "External Network Scan");
          history.push(ROUTE_PATHS.saas.integrations);
        } else if (tab == "Incident_Response") {
          localStorage.setItem("redirectTab", "Incident Response");
          history.push(ROUTE_PATHS.saas.integrations);
        } else {
          localStorage.setItem("redirectTab", "false");
          history.push(ROUTE_PATHS.saas.integrations);
        }
      } else if (page == "Cyber_Essential_Status") {
        history.push(ROUTE_PATHS.saas.complianceManagerSections.cyberEssentialStatus);
      } else if (page == "IR_Plan_Status") {
        history.push(ROUTE_PATHS.saas.complianceManagerSections.irPlanStatus);
      } else if (page == "Infosec_Policies") {
        history.push(ROUTE_PATHS.saas.complianceManagerSections.infosecPolicies);
      } else if (page == "Risk_Assessment") {
        history.push(ROUTE_PATHS.saas.complianceManagerSections.riskAssessment);
      } else if (page == "Annual_Cyber_Review") {
        history.push(ROUTE_PATHS.saas.complianceManagerSections.annualCyberReview);
      } else if (page == "Test_Recovery") {
        history.push(ROUTE_PATHS.saas.complianceManagerSections.testRecovery);
      } else if (page == "integrations") {
        history.push(ROUTE_PATHS.saas.integrations);
      }
    } else {
      // if (
      //   loader == "true" &&
      //   CURRENT_URL.includes("login") &&
      //   !CURRENT_URL.includes("redirect")
      // ) {
      //   console.log("fdd===========================")
      //   history.push("/");
      // }
    }
  }

  function showVerifyEmailBanner() {
    let showDashboardLoader = false;
    try {
      let dashboard_status = localStorage.getItem("dasboardLoader");
      if (dashboard_status) {
        showDashboardLoader = JSON.parse(
          localStorage.getItem("dasboardLoader")
        );
      }
    } catch (err) {
      showDashboardLoader = false;
    }

    if (URLpath[1] === "login") {
      return false;
    } else if (URLpath[1] === "logout") {
      return false;
    } else if (URLpath[1] === "signup") {
      return false;
    }

    // else if (URLpath[1] === "mspv2") {
    //   return false;
    // }
    else if (URLpath[1] === "supplier-profiler") {
      return false;
    } else if (URLpath[1] === "assessment-frameworks") {
      return false;
    }

    // else if (showDashboardLoader === true) {
    // }
    else if (URLpath[1] === "mspsettingsv2") {
      return false;
    } else if (URLpath[1] === "clientmspsettingsv2") {
      return false;
    } else if (showDashboardLoader === true) {
      return true;
    } else {
      return true;
    }
  }
  // console.log(qrCode);

  function removeSupportAccess() {
    return new Promise((resolve, reject) => {
      getAPIData(1102, authTokens, {})
        .then((response) => {
          resolve(response);
          window.localStorage.clear()
          history.push(ROUTE_PATHS.auth.login)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // useEffect(() => {
  //   if(user_data){

  //     window.Intercom('boot', {
  //       app_id: 'jaa5e0c1',
  //       custom_launcher_selector:  user_data?.UserDetail?.UserName,
  //       email:   user_data?.UserDetail?.UserName,
  //    });

  //   }
  // }, [ ]);
  const location = useLocation();
  const blocked_paths_for_intercomm = ["login", "logout", "signup"];
  useEffect(() => {
    const current_url = location.pathname?.split("/")?.[1];

    if (blocked_paths_for_intercomm.includes(current_url)) {
      console.log("intercomm removed");
      window.Intercom("update", { hide_default_launcher: true });
    } else {
      console.log("intercomm Enabled");
      window.Intercom("update", { hide_default_launcher: false });
    }
  }, [location]);

  function authenticate() {
    setAuth(true);
    setIsAuthenticated(true);
    setLoading(false);
  }
  function unauthenticate() {
    window.localStorage.clear();
    setIsAuthenticated(false);
    setLoading(false);
  }

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("tokens");
      if (token) {
        fetch(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/AttackSurfaceAPI/ValidateUser?AccessKey=${JSON.parse(
            localStorage.getItem("user_MessageObj")
          )}`,
          {
            method: "GET",
            headers: {
              Accept: "*/*",

              Connection: "keep-alive",

              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              authenticate();
            } else {
              unauthenticate();
            }
            window.localStorage.removeItem("pageLoading");
          })
          .then((data) => {
            setLoading(false);
          })
          .catch((error) => unauthenticate());
      } else {
        unauthenticate();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const [scrollUpdatedPath, setScrollUpdatedPath] = useState('');
  useEffect(() => {
    if (scrollUpdatedPath === location.pathname || !mainBodyRef) return;
    mainBodyRef.current.scrollTop = 0;
    setScrollUpdatedPath(location.pathname);
  }, [mainBodyRef, mainBodyRef?.current?.scrollTop, location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        authTokens:
          get_json_parse_or_null(localStorage.getItem("tokens")) ?? authTokens,
        setAuthTokens: setTokens,
        qrCode,
        setQrCode,
        auth,
        setAuth: setAuths,
        userType,
        setUserType: setUserTypeObj,
        drilldown,
        setDrillDown: setDrilldownObj,
        messageEncrypt,
        setMessageEncrypt: setMessage,
        loadingScreen,
        setLoadingScreen,
        isAuthenticated,
        loading,
        windowSizes
      }}
    >
      <dbContext.Provider
        value={{
          dbObj,
          setdbObject: setDBObj,
          ViewingMSP,
          setViewingMSP: setViewingMspObj,
          impersinatingDetails,
          setImpersinatingDetails: setImpersinatingDetailsObj,
          ViewingMSPPageType,
          setViewingMSPPageType: setViewingMspPageTypeObj,
        }}
      >
        <div
          className={`w-100 h-100 ${
            URLpath[1] !== "settings" && isMenuCollapse
              ? "smallSideMenu"
              : "largeSideMenu"
          }
          
          ${CURRENT_URL.includes("MSP") ? "MSP" : ""} 
          
          ${pageScreen ? pageScreen.name : "empty"}
          
          -Screen-Wrapper`}
          id="mainRow"
        >
          {loadingScreen ? (
            <span>
              <LoadingSpinner />
            </span>
          ) : (
            ""
          )}

          {/* Show Temporary Access Banner */}
          {window.location.pathname.split("/")[1] !== "login" &&
           window.location.pathname.split("/")[1] !== "signup" && 
           window.localStorage.getItem("showTempAccessBanner")
            &&
            (
              <div
                className="position-fixed w-100 top-0"
                style={{ zIndex: 100 }}
              >
                <div
                  className="w-100"
                  style={{
                    background: "#FFAC2C",
                    height: "3px",
                  }}
                >
                  <div
                    className="d-flex align-items-center mx-auto w-fit-content  f-white pl-3 pr-2"
                    style={{
                      background: "#FFAC2C",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <div>Support Access</div>
                    <div className="p-1 pointer"   onClick={()=>{
                      removeSupportAccess()
                    }} >
                      <img
                        src="/images/msp/white-cross.svg"
                        alt=""
                        className="ml-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* START Testing Side Nav */}
          <div
            className="fullHeight w-100"
            id="mainBody"
            ref={mainBodyRef}
          >
            <Switch>
              <PrivateRoute
                exact
                path="/"
                render={({ history }) => {
                  let user_token = localStorage.getItem("tokens");
                  let existingAuth = localStorage.getItem("userAuthenticated")
                    ? Boolean(
                        JSON.parse(localStorage.getItem("userAuthenticated"))
                      )
                    : null;
                  if (userType && user_token && auth && existingAuth) {
                    if (getUsersRole() === "msp") {
                      return <Redirect to={APP_ROUTES.mspv2.clientsView} />;
                    } else {
                      return <Redirect to={APP_ROUTES.client.dashboard} />;
                    }
                  } else {
                    console.log("Redirecting to login...");
                    console.log(window.location.pathname, "pathname");
                    // console.log(history?.length>0 && history.entries[history.length - 2].pathname,"history")
                    console.log(history?.entries, "histor eteuy");
                    return <Redirect to={APP_ROUTES.auth.login} />;
                  }
                }}
              />
              <Route exact path={APP_PORTALS.mspv2}>
                <Redirect to={APP_ROUTES.mspv2.clientsView} />;
              </Route>
              <Route exact path={APP_ROUTES.mspv2.sell}>
                <Redirect to={APP_ROUTES.mspv2.sellPackages} />;
              </Route>
              <PrivateRoute exact path={APP_ROUTES.error} component={ErrorPage} />

              <PrivateRoute exact path={APP_ROUTES.auth.logout} component={Logout} />

              <PrivateRoute exact path={APP_ROUTES.auth.login} component={Loginv2} />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.submitNewPassword}
                component={ResetPasswordForm}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.loginAnonymous}
                component={AnonymousContributorsView}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.anonymous}
                component={SupplierProfilerHomeScreen}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.forgotPassword}
                component={ForgotPassword}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.resetPassword}
                component={ResetPassword}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.clientRegistration}
                component={Registrationv2}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.forgottenPassword}
                component={ForgottenPasswordForm}
              />
              <PrivateRoute exact path={APP_ROUTES.auth.loginMfa} component={MFA} />
              <PrivateRoute
                exact
                path={APP_ROUTES.auth.signUpReferraltoken}
                component={SignUpv2}
              />
              <PrivateRoute exact path={APP_ROUTES.auth.signup} component={SignUpv2} />
              <Redirect exact from="/:page/redirect" to="/:page" />
              <Redirect
                exact
                from="/:page/:page2/redirect"
                to="/:page/:page2"
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.noAccessToMsp}
                component={NoAccessToMSP}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.noAccessToHighground}
                component={NoAccessToHighground}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.sessionExpiry}
                render={(props) => <SessionPage />}
              />
              <Suspense fallback={<TechnicalDrillLoader />}>
                <Suspense fallback={<div></div>}>

                  <Suspense fallback={<ComplianceManagerLoader />}>
                    <Suspense fallback={<FullScreenLoader />}>
                      <Switch>
                        <PrivateRoute
                          path={APP_ROUTES.mspv2Type}
                          render={(props) => (
                            <MSPV2
                              {...props}
                              setClientDetail={setClientDetail}
                            />
                          )}
                        />
                        <PrivateRoute
                          path={APP_ROUTES.mspsettingsv2Type}
                          component={MSPSettingsV2}
                        />
                        <PrivateRoute
                          path={APP_ROUTES.clientmspsettingsv2Type}
                          component={ClientMSPSettingsV2}
                        />
                      </Switch>
                    </Suspense>
                  </Suspense>
                </Suspense>
              </Suspense>
            </Switch>
            {authTokens &&
              auth &&
              !CURRENT_URL.includes("MSP") &&
              URLpath?.[1] !== "insights" &&
              URLpath?.[1] !== APP_PORTALS.mspv2.split('/')[1] &&
              URLpath?.[1] !== "no-access-to-msp" &&
              URLpath?.[1] !== "no-access-to-highground" &&
              URLpath?.[1] !== "supplier-profiler" &&
              URLpath?.[1] !== "assessment-frameworks" &&
              URLpath?.[1] !== "login" &&
              URLpath?.[1] !== APP_PORTALS.mspsettingsv2.split('/')[1] &&
              URLpath?.[1] !== APP_PORTALS.clientmspsettingsv2.split('/')[1] && (
                <div className={`d-flex w-100 overflow-hidden`} style={{ height: '100vh' }}>
                    {!settings_V2_exist && (
                      <SideNav />
                    )}
                    <PrivateRoute exact path="/Settings" component={SettingsIndex} />
                    <PrivateRoute
                      path={APP_ROUTES.client.dashboard}
                      component={
                        SaaSRouterLayout
                      }
                    />
                    <Route
                      path={APP_ROUTES.client.storefront}
                      exact
                      component={
                        MarketplaceComingSoon
                        // Marketplace
                      }
                    />
                     <Route
                      path={APP_ROUTES.client.storefrontTest}
                      exact
                      component={
                        Marketplace
                      }
                    />
                    <Suspense fallback={<div></div>}>
                      <PrivateRoute exact path={APP_ROUTES.client.complianceManagerSections.form} component={StepForm} />
                    </Suspense>
                    {/* Integratiosn V2 */}
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.integrations}
                      component={
                        accessAllowed("AccessState_Integrations", clientDetail)
                          ? IntegrationsV2
                          : NoAccessUI
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.integrationDetail}
                      component={
                        accessAllowed("AccessState_Integrations", clientDetail)
                          ? IntegrationDetailsPage
                          : NoAccessUI
                      }
                    />

                    <PrivateRoute
                      path={APP_ROUTES.client.settingsType}
                      render={(props) => (
                        <ClientSettingsLayoutRoutes
                          {...props}
                          clientDetail={clientDetail}
                        />
                      )}
                    />

                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.systemSettings}
                      component={UserProfile}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.test}
                      component={SubscriptionOverview}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.handlesubscription3ds}
                      component={Handle3DS}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.supplierProfilerType}
                      component={SupplierProfiler}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.technologySpend}
                      component={
                        accessAllowed("AccessState_TechnologySpend")
                          ? SaasTechnologySpend
                          : NoAccessUI
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.attackSurface}
                      component={
                        accessAllowed("AccessState_AttackSurface", clientDetail)
                          ? AttackSurface
                          : NoAccessUI
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.trendData}
                      component={
                        accessAllowed("AccessState_CyberTrendData", clientDetail)
                          ? TrendData
                          : NoAccessUI
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.createSupportTicket}
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.design_system}
                      component={DesignSystem}
                    />
                    <PrivateRoute exact path={APP_ROUTES.client.auditLogs} component={AuditLogs} />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.securityPackage}
                      component={
                        accessAllowed("AccessState_ManagedSecurity", clientDetail)
                          ? PriceCalculator
                          : NoAccessUI
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.marketplace}
                      component={
                        MarketplaceComingSoon
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.actionCentre}
                      component={ActionCenter}
                    />
                    <Suspense fallback={<ComplianceManagerLoader />}>
                      <PrivateRoute
                        exact
                        path={APP_ROUTES.client.complianceManager}
                        component={
                          accessAllowed("AccessState_ComplianceManager")
                            ? ComplianceMainManager
                            : NoAccessUI
                        }
                      />
                      <PrivateRoute
                        exact
                        path={APP_ROUTES.client.complianceManagerPageType}
                        component={ComplianceManagerRoutes}
                      />
                    </Suspense>
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.personalSettings}
                      component={PersonalSettings}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.technicalDrilldown}
                      component={
                        accessAllowed(
                          "AccessState_TechnicalDrilldown",
                          clientDetail
                        )
                          ? TDrill
                          : NoAccessUI
                      }
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.technicalDrilldownPageType}
                      component={TechnicalDrilldownRoutes}
                    />
                    <PrivateRoute
                      exact
                      path={APP_ROUTES.client.integrationsV2}
                      component={IntegrationIndex}
                    />
                </div>
            )}
          </div>

          <PopupButton
            className="calendly-button d-none"
            iframeTitle="Calendly Scheduling Page"
            pageSettings={{
              backgroundColor: "ffffff",
              hideEventTypeDetails: false,
              hideGdprBanner: true,
              hideLandingPageDetails: false,
              primaryColor: "00a2ff",
              textColor: "4d5055",
            }}
            prefill={{
              customAnswers: {
                a1: "",
                a10: "a10",
                a2: "a2",
                a3: "a3",
                a4: "a4",
                a5: "a5",
                a6: "a6",
                a7: "a7",
                a8: "a8",
                a9: "a9",
              },
              date: new Date(),
              email: "",
              firstName: "",
              guests: [],
              lastName: "",
              name: "",
            }}
            rootElement={document.getElementById("root")}
            text="Click here to schedule!"
            url={LIVE_SESSION_CALENDLY_URL}
            utm={{
              utmCampaign: "Spring Sale 2019",
              utmContent: "Shoe and Shirts",
              utmMedium: "Ad",
              utmSource: "Facebook",
              utmTerm: "Spring",
            }}
          />
        </div>
      </dbContext.Provider>
    </AuthContext.Provider>
  );
}

const mapStateToProps = createStructuredSelector({
  isMenuCollapse: getMenuCollapseStatus(),
});

const compose = connect(mapStateToProps, { menuToggle });

export default compose(App);

export const NoAccessUI = ({ type }) => {
  const history = useHistory();
  const objectData = window.localStorage.getItem("object_Data");
  var UserEmail = null;
  var mspDetail = null;
  if (objectData) {
    UserEmail = JSON.parse(objectData)?.UserDetail?.UserName;
    mspDetail = JSON.parse(objectData)?.mspDetail;
  }

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center w-100"
      style={{ marginTop: "9rem" }}
    >
      {/* <img alt="" src="/images/supply-chain/supply-lock.svg" className="mb-2" /> */}
      <div className="d-flex align-items-center f-16 f-600 mb-5">
        <div className="mr-2 position-relative">
          <img
            alt=""
            src={mspDetail?.logo ?? "/images/user-logo.svg"}
            width={40}
            height={40}
            className="radius-4"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/user-logo.svg";
            }}
            // (e) => (e.target.src = "/images/user-logo.svg")
          />
          <img
            alt=""
            src="/images/settings-v2/settingwheel.svg"
            width={18}
            height={18}
            className="position-absolute "
            style={{
              top: "100%",
              transform: "translate(-50%,-50%)",
              zIndex: 2,
            }}
          />
        </div>
        {mspDetail?.name}
      </div>
      <div className=" f-black mb-3 f-24 f-700 highground-royal-font">
        Access locked
      </div>
      {type === "HG_SAAS" && (
        <div className="f-darkgrey mb-4 text-center">
          Want in? Ask your administrator for permission or login with <br />{" "}
          another credentials.
        </div>
      )}
      {!type && (
        <div className="f-darkgrey mb-4 text-center">
          Want in? Ask your service provider for permission or login with <br />{" "}
          another credentials.
        </div>
      )}
      {UserEmail && (
        <div className="f-grey mb-3">
          You are signed in as <span className="f-600">{UserEmail}</span>
        </div>
      )}
      <Button
        className="hg-blue-btn radius-2"
        onClick={() => {
          window.localStorage.clear();
          window.location.href = ROUTE_PATHS.auth.login;
        }}
      >
        Logout / Switch account
      </Button>

      <div
        className="f-darkgrey"
        style={{
          position: "fixed",
          bottom: "26px",
        }}
      >
        <p className="lgnGreyText terms">
          © 2024 HighGround. &nbsp;
          <a
            href="https://www.highground.io/privacy_policy/"
            target="_blank"
            rel="noopener noreferrer"
            className="f-12 f-500"
          >
            &nbsp;<u>Privacy Policy</u>
          </a>
          &nbsp; &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.highground.io/highground-terms-and-conditions/"
            className="f-12 f-500"
          >
            <u> Terms & Conditions</u>
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export function UserSystemSettingsBlocked() {
  const objectData = window.localStorage.getItem("object_Data");

  if (objectData) {
    let accessData = JSON.parse(objectData)?.accessData;

    if (
      accessData?.AccessState_SystemSettings === 0 &&
      accessData?.Override === true
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function checkAccessSection(_accessSection, clientDetail) {
  let accessName = _accessSection;

  if (clientDetail && clientDetail?.isSameOrg === true) {
    accessName = _accessSection.replace("AccessState", "AccessState_Own");
  }
  return accessName;
}
export function accessAllowed(_accessSection, clientDetail) {
  let objectData = JSON.parse(localStorage.getItem("object_Data") ?? "{}");

  let accessName = checkAccessSection(_accessSection, clientDetail);

  if (!objectData || !objectData?.accessData) return false;
  if (!objectData?.accessData.hasOwnProperty(accessName)) return false;
  if (objectData?.accessData?.Override === true) {
    if (objectData?.accessData[accessName] === 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
export function getAccessStateValue(_accessSection, clientDetail) {
  let objectData = JSON.parse(localStorage.getItem("object_Data") ?? "{}");

  let accessName = checkAccessSection(_accessSection, clientDetail);

  if (!objectData || !objectData?.accessData) return 0;
  if (!objectData?.accessData.hasOwnProperty(accessName)) return 0;
  if (objectData?.accessData?.Override === true) {
    return objectData?.accessData[accessName];
  } else {
    return 99;
  }
}
export function viewOnlyAccess(_accessSection) {
  let objectData = JSON.parse(localStorage.getItem("object_Data") ?? "{}");

  if (!objectData || !objectData?.accessData) return false;
  if (!objectData?.accessData.hasOwnProperty(_accessSection)) return false;
  if (objectData?.accessData?.Override === true) {
    if (objectData?.accessData[_accessSection] === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function editAccess(_accessSection) {
  let objectData = JSON.parse(localStorage.getItem("object_Data") ?? "{}");

  if (!objectData || !objectData?.accessData) return false;
  if (!objectData?.accessData.hasOwnProperty(_accessSection)) return false;
  if (objectData?.accessData?.Override === true) {
    if (objectData?.accessData[_accessSection] === 3) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function accessStateValue(_accessSection) {
  let objectData = JSON.parse(localStorage.getItem("object_Data") ?? "{}");

  if (!objectData || !objectData?.accessData) return false;
  if (!objectData?.accessData.hasOwnProperty(_accessSection)) return false;
  if (objectData?.accessData?.Override === true) {
    return objectData?.accessData[_accessSection];
  } else {
    return 99;
  }
}

export const NO_ACCESS_TEXT =
  "This feature is managed by your service provider. ";

export function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function HGHighlightFuzzyText(text, searchString) {
  try {
    if (!searchString.trim()) {
      return text;
    }

    // Split search string into individual characters
    const searchCharacters = searchString.split("");

    // Escape special characters in search string for regex
    const escapedSearch = searchCharacters
      .map((char) => char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
      .join("");

    // Construct regex pattern to match each character with optional commas
    const regexPattern = `(${escapedSearch.split("").join("|")}|,)`;

    const output = text.replace(new RegExp(regexPattern, "gi"), (match) => {
      if (match === ",") {
        return match; // Keep commas as they are
      } else {
        return `<span class="list-highlight-class">${match}</span>`; // Highlight search characters
      }
    });

    return output;
  } catch (err) {
    console.log(err);
  }
}

export function HGHighlightText(text, searchString) {
  try {
    if (!searchString.trim()) {
      return text;
    }

    const output = text.replace(
      new RegExp(searchString, "gi"),
      (match) => `<span class="list-highlight-class">${match}</span>`
    );

    return output;
  } catch (err) {
    console.log(err);
  }

  return;

  if (searchString?.length > 0) {
    // Escape special characters in the search string
    const escapedSearchString = searchString.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );

    // Create a regular expression with the search string and case-insensitive flag
    const regex = new RegExp(
      `\\b${escapedSearchString}\\b|${escapedSearchString}`,
      "gi"
    );

    // Replace the search string with a highlighted version only if it matches
    const highlightedText = text.replace(
      regex,
      (match) => `<span class="list-highlight-class">${match}</span>`
    );
    if (text === "IT Support" && searchString === "su") {
      console.log(highlightedText, "text");
    }
    return highlightedText;
  } else {
    return text;
  }
}

function get_json_parse_or_null(data, returnDefault = null) {
  try {
    let parsed_value = JSON.parse(data);
    return parsed_value;
  } catch (error) {
    console.log("error is parsing", error);
    return returnDefault;
  }
}

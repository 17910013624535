import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ColorDropdown } from "../../CreateCategorySpendModal";
import AttackSurfaceTooltip from "../../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import MspDonutChart from "../../MspDonutChart";
import { Nav, Tab } from "react-bootstrap";
import AllSpendTab from "../techspend-home-tabs/all-spend.tab";
import SubscriptionsTab from "../techspend-home-tabs/subscriptions.tab";
import SubcategoriesTab from "../techspend-home-tabs/subcategories.tab";
import { ROUTE_PATHS } from "../../../../../constants/app.constants";

const TechspendHomeContent = ({
  categoryPageData,
  setCategoryPageData,
  subCategoryPageData,
  setSelectedInvoiceItemData,
  setErrors,
  viewOnlyAccess,
  graphData,
  categoryGraphRef,
  selectedTab,
  setSelectedTab,
  defaultSelectedTab,
  setDefaultSelectedTab,
  spendSearch,
  invoiceFilterSearch,
  subcategoriesSearch,
  setSubcategoriesSearch,
  subcategorySearchRef,
  applyingFilters,
  errors,
  isSaas,
  hideModal,
  ModalTabs,
  searchSpendInvoices,
  setLoading,
  goTo,
  getSubCategoryDetail,
  updateSubCategorySorting,
  sortingSubcategoryList,
  getInvoiceDetails,
  setInvoiceEditMode,
  Pages,
  setAddSpendSelectedTab,
  highlightedInvoice,
  setShowCreateSubcategoryModal,
  subCategoriesLoading,
  donutColorIds,
  subscriptionSearch,
  setSubscriptionSearch,
  subscriptionSearchRef,
  setSpendSearch,
  spendSearchRef,
  activeFilters,
  getFilters,
  setActiveFilters,
  setApplyingFilters,
  getCategoryDetail,
  setSubscriptionViewInvoiceData,
  setDefaultSpendSelectedTab, 
  clientId,
  subscriptionActiveFilters,
  subscriptionViewData,
  setSubscriptionActiveFilters = () => {},
  setDeleteID = () => {},
  setDeleteType = () => {},
  setDeleteMessage = () => {},
  setShowDeleteModal = () => {},
  setPages = () => {},
  getSubPaymentLastInvoice = () => {},
  getSubscriptionViewData = () => {},
  setUnlinkRelinkModalData = () => {},
  setCategoryDeleteData = () => {},
}) => {
  const history = useHistory();
  return (
    <div className="p-2">
      <div
        className="d-flex align-items-center"
        style={{
          height: "37px",
          cursor: `${
            categoryPageData?.IsDefault === true ? "not-allowed" : ""
          }`,
        }}
      >
        {/* Input */}
        <div
          className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 ${
            errors?.categoryNameError ? "input-error" : ""
          }`}
          style={{
            pointerEvents: `${
              categoryPageData?.IsDefault === true ? "none" : "all"
            }`,
          }}
        >
          <div className="f-darkgrey">Name:</div>
          <input
            type="text"
            className="bg-transparent w-100 border-none pl-2"
            placeholder="Type"
            value={categoryPageData?.CategoryName}
            onChange={(e) => {
              let value = e.target.value;
              setCategoryPageData((prev) => ({
                ...prev,
                CategoryName: value,
              }));
              setSelectedInvoiceItemData({
                Category_Selected: categoryPageData,
                SubCategory_Selected: subCategoryPageData,
              });
              setErrors((prev) => ({
                ...prev,
                categoryNameError: false,
              }));
            }}
            disabled={viewOnlyAccess("AccessState_TechnologySpend")}
          />
        </div>
        <div
          className="bg -grey h-1 00 d-flex align-items-center"
        >
          {viewOnlyAccess("AccessState_TechnologySpend") ? (
            <div
              className="rounded-full"
              style={{
                width: `${17}px`,
                height: `${17}px`,
                background: categoryPageData?.CategoryColourHexCode,
              }}
            />
          ) : (
            <ColorDropdown
              height={37}
              colorListWithAvailability={
                categoryPageData?.CategorySpendColor_List ?? []
              }
              itemCircleWidth={14}
              selectedCircleWidth={17}
              data={categoryPageData?.CategorySpendColor_List}
              selected={{
                ColorCode: categoryPageData?.CategoryColourHexCode,
                ColorName: categoryPageData?.CategoryColour,
              }}
              onChange={(_color) => {
                let colorCode = _color.ColorCode;
                let colorName = _color.ColorName;
                setCategoryPageData((prev) => ({
                  ...prev,
                  CategoryColour: colorName,
                  CategoryColourHexCode: colorCode,
                }));
                setSelectedInvoiceItemData({
                  Category_Selected: categoryPageData,
                  SubCategory_Selected: subCategoryPageData,
                });
              }}
            />
          )}
        </div>
      </div>
      {categoryPageData?.SyncStatus?.ShowBanner && !isSaas && (
        <div
          className={
            "bg-grey radius-4 d-flex align-items-center justify-content-between mt-2 p-2"
          }
        >
          <div className=" d-flex align-items-center">
            <img
              alt=""
              src="/images/supply-chain/blue-plug.svg"
              className="mr-2"
            />
            <div className="f-black">
              Integrate your PSA tools to automatically import your
              clients spend
            </div>
          </div>
          <div 
            className="f-blue d-flex align-items-center pointer"
            onClick={() => {
              hideModal();
              setTimeout(() => {
                history.push(
                  ROUTE_PATHS.mspv2.connectedIntegrations
                );
              }, 350);
            }}
          >
            <div className="pointer" onClick={() => {}}>
              Integrations
            </div>
            <img
              src="/images/supply-chain/blue-redirect.svg"
              alt=""
              className="mx-2 pointer"
            />
          </div>
        </div>
      )}
      {categoryPageData?.SyncStatus?.IsSyncing === true && !isSaas ? (
        <div className="d-flex align-items-center f-darkgrey f-500 ml-1 mt-2">
          <img
            src="/images/msp/integration-sync-icon.svg"
            alt=""
            className="mr-2"
          />
          Syncing...
        </div>
      ) : (
        <>
          {categoryPageData?.SyncStatus?.MessageAvatarURL && (
            <div
              className="ml-1 d-flex align-items-center mt-2"
              style={{
                color: categoryPageData?.SyncStatus?.MessageColour,
              }}
            >
              <AttackSurfaceTooltip
                content={
                  <>
                    <div className="f-12 f-white"> Updated by:</div>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          categoryPageData?.SyncStatus
                            ?.MessageAvatarURL
                        }
                        alt=""
                        width={18}
                        height={18}
                        className="mr-2 rounded-full"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/images/user-logo.svg";
                        }}
                      />{" "}
                      <div className="f-white">
                        {" "}
                        {categoryPageData?.SyncStatus?.Source}{" "}
                      </div>
                    </div>
                  </>
                }
              >
                <img
                  src={categoryPageData?.SyncStatus?.MessageAvatarURL}
                  alt=""
                  width={18}
                  height={18}
                  className="mr-2 rounded-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/user-logo.svg";
                  }}
                />
              </AttackSurfaceTooltip>
              <div> {categoryPageData?.SyncStatus?.Message}</div>
            </div>
          )}
        </>
      )}
      <div className="f-500 f-grey p-3">
        {categoryPageData?.CategoryName} spend
      </div>
      <div className="f-18 f-500 f-black pl-3">
        {categoryPageData?.TotalAmount_Text}
      </div>
      {selectedTab === ModalTabs.Subcategories && (
        <>
          <div className="chart">
            <MspDonutChart
              height={200}
              thickness={40}
              graphData={graphData}
              forwardedRef={categoryGraphRef}
            />
          </div>
        </>
      )}
      <div className={`${selectedTab === ModalTabs.Subcategories ? 'mt-2' : 'mt-4'}`}>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={defaultSelectedTab}
          activeKey={selectedTab}
          onSelect={(e) => {
            setSelectedTab(e);
          }}
        >
          <div>
            <div
              sm={12}
              className="tab-header d-flex justify-content-between align-items-center ml- p-0"
            >
              <div className={`${true}`}>
                <Nav
                  variant="pills"
                  className="flex-row"
                >
                  <Nav.Item>
                    <Nav.Link eventKey={ModalTabs.AllSpend}>
                      All Spend
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item id="">
                    <Nav.Link eventKey={ModalTabs.Subscriptions}>
                      Subscriptions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item id="">
                    <Nav.Link eventKey={ModalTabs.Subcategories}>
                      Subcategories
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div sm={12} className="p-0">
              <Tab.Content>
                <Tab.Pane eventKey={ModalTabs.AllSpend}>
                  <AllSpendTab 
                    Pages={Pages}
                    categoryPageData={categoryPageData}
                    getInvoiceDetails={getInvoiceDetails}
                    goTo={goTo}
                    highlightedInvoice={highlightedInvoice}
                    invoiceFilterSearch={invoiceFilterSearch}
                    searchSpendInvoices={searchSpendInvoices}
                    setAddSpendSelectedTab={setAddSpendSelectedTab}
                    setInvoiceEditMode={setInvoiceEditMode}
                    setLoading={setLoading}
                    spendSearch={spendSearch}
                    setSpendSearch={setSpendSearch}
                    spendSearchRef={spendSearchRef}
                    viewOnlyAccess={viewOnlyAccess}
                    activeFilters={activeFilters}
                    applyingFilters={applyingFilters}
                    getCategoryDetail={getCategoryDetail}
                    getFilters={getFilters}
                    setActiveFilters={setActiveFilters}
                    setApplyingFilters={setApplyingFilters}
                    setDefaultSpendSelectedTab={setDefaultSpendSelectedTab}
                    setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                    setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                    parentCategortyData={categoryPageData}
                    childCategoryData={subCategoryPageData}
                    setDeleteID={setDeleteID}
                    setDeleteMessage={setDeleteMessage}
                    setDeleteType={setDeleteType}
                    setShowDeleteModal={setShowDeleteModal}
                    setUnlinkRelinkModalData={setUnlinkRelinkModalData}
                    setCategoryDeleteData={setCategoryDeleteData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={ModalTabs.Subscriptions}>
                  <SubscriptionsTab 
                    Pages={Pages}
                    categoryPageData={categoryPageData}
                    getInvoiceDetails={getInvoiceDetails}
                    goTo={goTo}
                    highlightedInvoice={highlightedInvoice}
                    invoiceFilterSearch={invoiceFilterSearch}
                    searchSpendInvoices={searchSpendInvoices}
                    setAddSpendSelectedTab={setAddSpendSelectedTab}
                    setInvoiceEditMode={setInvoiceEditMode}
                    setLoading={setLoading}
                    spendSearch={spendSearch}
                    viewOnlyAccess={viewOnlyAccess}
                    setSubscriptionSearch={setSubscriptionSearch}
                    subscriptionSearch={subscriptionSearch}
                    subscriptionSearchRef={subscriptionSearchRef}
                    setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                    setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                    parentCategortyData={categoryPageData}
                    childCategoryData={subCategoryPageData}
                    setPages={setPages}
                    clientId={clientId}
                    getSubPaymentLastInvoice={getSubPaymentLastInvoice}
                    subscriptionActiveFilters={subscriptionActiveFilters}
                    setSubscriptionActiveFilters={setSubscriptionActiveFilters}
                    getSubscriptionViewData={getSubscriptionViewData}
                    subscriptionViewData={subscriptionViewData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={ModalTabs.Subcategories}>
                  <SubcategoriesTab 
                    Pages={Pages}
                    categoryGraphRef={categoryGraphRef}
                    categoryPageData={categoryPageData}
                    donutColorIds={donutColorIds}
                    getSubCategoryDetail={getSubCategoryDetail}
                    goTo={goTo}
                    isSaas={isSaas}
                    setLoading={setLoading}
                    setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                    setSubcategoriesSearch={setSubcategoriesSearch}
                    sortingSubcategoryList={sortingSubcategoryList}
                    subCategoriesLoading={subCategoriesLoading}
                    subcategoriesSearch={subcategoriesSearch}
                    subcategorySearchRef={subcategorySearchRef}
                    updateSubCategorySorting={updateSubCategorySorting}
                    viewOnlyAccess={viewOnlyAccess}
                    ModalTabs={ModalTabs}
                    getInvoiceDetails={getInvoiceDetails}
                    setAddSpendSelectedTab={setAddSpendSelectedTab}
                    setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                    setDeleteID={setDeleteID}
                    setDeleteMessage={setDeleteMessage}
                    setDeleteType={setDeleteType}
                    setShowDeleteModal={setShowDeleteModal}
                    setCategoryDeleteData={setCategoryDeleteData}
                    parentCategortyData={categoryPageData}
                    childCategoryData={subCategoryPageData}
                    setSelectedTab={setSelectedTab}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
      {/* </>} */}
    </div>
  )
}

export default TechspendHomeContent;

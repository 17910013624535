import { Nav, Tab } from "react-bootstrap";
import CrossIcon from "../../icons/cross.icon";
import './suggest-modal.styles.scss';
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import { useEffect, useMemo, useRef, useState } from "react";
import { VisibilityType } from "../../../constants/filter.constants";
import SuggestionsContent from "./suggestions-content/suggestions.content";
import RecurringSpendContent from "./suggestions-content/recurring-spend.content";
import useSuggestionsData from "./suggestions-content/suggestions.data";
import OrderSummary from "../../Common/loader";
import useRecurringSpendData from "./suggestions-content/recurring-spend.data";
import moment from 'moment';
import SelectedFilters from "../../SearchFilterComponent/selected-filters.component";
import filterUtils from "../../../Utils/filter.utils";

const Tabs = {
  Suggestions: "suggestions",
  RecurringSpend: "recurringSpend",
}

const BodyTitle = {
  [Tabs.Suggestions]: "Technologies",
  [Tabs.RecurringSpend]: "",
}

const Header = ({ onHide }) => {
  return (
    <div className="header">
      <div className="navs">
        <Nav
          variant="pills"
          className="flex-row"
        >
          <Nav.Item>
            <Nav.Link eventKey={Tabs.Suggestions}>
              Suggestions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={Tabs.RecurringSpend}>
              Recurring Spend
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="cross-btn-wrapper">
        <div className="cross-btn cursor-pointer" onClick={onHide}>
          <CrossIcon 
            width="14px"
            height="14px"
            fill="#616778"
          />
        </div>
      </div>
    </div>
  )
}

const FiltersEnum = {
  Tecnhologies: 0,
  LastSold: 1,
  Price: 2,
  Quantity: 3,
  Total: 4,
  LastInvoiced: 5,
  Categories: 6,
  Subcategories: 7,
}

const BodyTop = ({
  searchValue,
  setSearchValue,
  filters,
  setFilters,
  suggestData,
  recurringSpendData,
  filtersList,
  orderData,
  onOrderUpdate,
  onSortClick
}) => {
  const SearchInput = useRef();

  const createFilter = (key, label, value, type) => {
    const newFilter = { key, label, value, type };
    setTimeout(() => {
      const toggleId = filterUtils.getFilterToggleId(newFilter);
      const toggle = document.getElementById(toggleId);
      if (toggle) {
        toggle.click();
      }
    }, 100);
    return newFilter;
  };

  return (
    <div>
      <div className="body-top">
        <SearchFilterComponent
          searchValue={searchValue}
          searchOnChange={(e) => {
            setSearchValue(e.target.value);
          }}
          searchOnCross={() => {
            setSearchValue("");
          }}
          passedRef={SearchInput}
          setRef={() => {
            SearchInput.current.focus()
          }}
          onSortClick={() => {
            onSortClick(prev => ({
              ...prev,
              sortOrder: prev.sortOrder === 1 ? 0 : 1
            }));
          }}
          visibilityType={VisibilityType.TABLE}
          orderData={orderData}
          setOrderData={(updatedData) => {
            onOrderUpdate(updatedData);
          }}
          showLastButton={false}
          passedFilterList={filtersList}
          onFilterClick={(filterItem) => {
            switch (filterItem.enum) {
              case FiltersEnum.Tecnhologies: {
                setFilters(prev => {
                  const checkboxes = suggestData?.reduce((acc, item) => {
                    const isTechnologyAdded = acc?.find(tech => tech === item?.IntegrationTechnologyName);
                    if (!item?.IntegrationTechnologyName) return acc;
                    if (!isTechnologyAdded) {
                      acc.push(item?.IntegrationTechnologyName);
                    }
                    return acc;
                  }, []);
                  const newFilter = createFilter(
                    "technologoies-filter",
                    "Technologies",
                    checkboxes.map(tech => ({
                      key: tech,
                      label: tech,
                      value: false
                    })),
                    "checkbox-range"
                  );
                  if (prev.find(item => item.key === "technologoies-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              case FiltersEnum.LastSold: {
                setFilters(prev => {
                  const newFilter = createFilter(
                    "lastsold-filter",
                    "Last sold",
                    null,
                    "date-range"
                  );
                  if (prev.find(item => item.key === "lastsold-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              case FiltersEnum.Price: {
                setFilters(prev => {
                  const findCurrency = recurringSpendData?.find(item => item.CurrencySymbol);
                  const newFilter = createFilter(
                    "price-filter",
                    "Price",
                    {
                      prefix: findCurrency ? findCurrency?.CurrencySymbol : "£",
                      value: []
                    },
                    "number-range"
                  );
                  if (prev.find(item => item.key === "price-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              case FiltersEnum.Quantity: {
                setFilters(prev => {
                  const newFilter = createFilter(
                    "quantity-filter",
                    "Quantity",
                    { value: [] },
                    "number-range"
                  );
                  if (prev.find(item => item.key === "quantity-filter")) return prev;
                  return [...prev, newFilter];
                });
                break
              }
              case FiltersEnum.Total: {
                setFilters(prev => {
                  const findCurrency = recurringSpendData?.find(item => item.CurrencySymbol);
                  const newFilter = createFilter(
                    "total-filter",
                    "Total",
                    {
                      prefix: findCurrency ? findCurrency?.CurrencySymbol : "£",
                      value: []
                    },
                    "number-range"
                  );
                  if (prev.find(item => item.key === "total-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              case FiltersEnum.LastInvoiced: {
                setFilters(prev => {
                  const newFilter = createFilter(
                    "lastinvoiced-filter",
                    "Last invoiced",
                    null,
                    "date-range"
                  );
                  if (prev.find(item => item.key === "lastinvoiced-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              case FiltersEnum.Categories: {
                setFilters(prev => {
                  const checkboxes = recurringSpendData?.reduce((acc, item) => {
                    const isCategoryAdded = acc?.find(cat => cat === item?.CategoryName);
                    if (!item?.CategoryName) return acc;
                    if (!isCategoryAdded) {
                      acc.push(item?.CategoryName);
                    }
                    return acc;
                  }, []);
                  const newFilter = createFilter(
                    "categories-filter",
                    "Categories",
                    checkboxes.map(cat => ({
                      key: cat,
                      label: cat,
                      value: false
                    })),
                    "checkbox-range"
                  );
                  if (prev.find(item => item.key === "categories-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              case FiltersEnum.Subcategories: {
                setFilters(prev => {
                  const checkboxes = recurringSpendData?.reduce((acc, item) => {
                    const isSubCategoryAdded = acc?.find(subCat => subCat === item?.SubCategoryName);
                    if (!item?.SubCategoryName) return acc;
                    if (!isSubCategoryAdded) {
                      acc.push(item?.SubCategoryName);
                    }
                    return acc;
                  }, []);
                  const newFilter = createFilter(
                    "subcategories-filter",
                    "Subcategories",
                    checkboxes.map(subCat => ({
                      key: subCat,
                      label: subCat,
                      value: false
                    })),
                    "checkbox-range"
                  );
                  if (prev.find(item => item.key === "subcategories-filter")) return prev;
                  return [...prev, newFilter];
                });
                break;
              }
              default:
                break;
            }
          }}
        />
      </div>
      <SelectedFilters 
        filterList={filters}
        onFilterChange={setFilters}
        onReset={() => {
          setFilters([]);
        }}
        isPositionFixed
      />
    </div>
  )
}

const Body = ({ 
  activeTab, clientId, searchValue, refreshData, suggestData, recurringSpendData,
  fetchSuggestData, fetchRecurringSpendData, isSuggestFetching, isRecurringSpendFetching, onApply,
  suggestionsFilters, suggestionsSort, recurringSpendFilters, recurringSpendSort, setRecurringSpendSort, 
  orderData, onOrderUpdate
}) => {
  const title = BodyTitle[activeTab];

  const mappedRecurringSpendData = useMemo(() => {
    return recurringSpendData?.map((item) => ({
      title: item.ItemName,
      subTitle: `${item.CategoryName}${item.SubCategoryName ? ` > ${item.SubCategoryName}` : ''}`,
      price: item.Price,
      priceText: `${item.CurrencySymbol} ${Number(item.Price).toFixed(2)}`,
      qty: item.Quantity,
      total: item.Total,
      totalText: `${item.CurrencySymbol} ${Number(item.Total).toFixed(2)}`,
      lastInvoiced: moment(item.InvoiceDate).toDate(),
      lastInvoicedText: moment(item.InvoiceDate).format('DD/MM/YY'),
      categoryName: item.CategoryName,
      subCategoryName: item.SubCategoryName,
    }))
  }, [recurringSpendData]);

  const handleApply = async (suggestedItem) => {
    await onApply(suggestedItem);
    fetchSuggestData({ clientId });
    refreshData();
  }

  const isLoading = activeTab === Tabs.Suggestions 
    ? (isSuggestFetching && suggestData?.length === 0) 
    : (isRecurringSpendFetching && recurringSpendData?.length === 0);

  const showTitle = activeTab === Tabs.Suggestions && title && suggestData?.length > 0;

  useEffect(() => {
    if (activeTab === Tabs.RecurringSpend && recurringSpendData?.length === 0) {
      fetchRecurringSpendData({ clientId });
      return;
    }
    if (activeTab === Tabs.Suggestions && suggestData?.length === 0) {
      fetchSuggestData({ clientId });
    }
  }, [clientId, activeTab]);
  
  return (
    <div className="body">
      {(isLoading) ? (
        <div className="my-4">
          <OrderSummary />
        </div>
      ) : (
        <>
          {showTitle && (
            <h3 className="m-0 f-12 line-height-18 f-500 f-darkgrey body-header ">
              {title}
            </h3>
          )}
          <Tab.Content>
            <Tab.Pane eventKey={Tabs.Suggestions}>
              <SuggestionsContent 
                data={suggestData}
                onApply={handleApply}
                clientId={clientId}
                searchValue={searchValue}
                filters={suggestionsFilters}
                sort={suggestionsSort}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={Tabs.RecurringSpend}>
              <RecurringSpendContent
                data={mappedRecurringSpendData} 
                searchValue={searchValue}
                filters={recurringSpendFilters} 
                sort={recurringSpendSort}
                setSort={setRecurringSpendSort}
                orderData={orderData}
                onOrderUpdate={onOrderUpdate}
              />
            </Tab.Pane>
          </Tab.Content>
        </>
      )}
    </div>
  );
}

const defaultSuggestionSort = {
  sortItem: "Technologies",
  sortOrder: 0,
  sorting: false,
}

const defaultRecurringSpendSort = {
  sortItem: "Recurring Items",
  sortOrder: 1,
  sorting: false,
}

const SuggestModal = ({ clientId, show, onHide, refreshData }) => {
  const scrollRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(Tabs.Suggestions);
  const [suggestionsFilters, setSuggestionsFilters] = useState([]);
  const [recurringSpendFilters, setRecurringSpendFilters] = useState([]);
  const [suggestionsSort, setSuggestionsSort] = useState(defaultSuggestionSort);
  const [recurringSpendSort, setRecurringSpendSort] = useState(defaultRecurringSpendSort);

  const { 
    data: suggestData, 
    fetchData: fetchSuggestData, 
    isFetchingLoading: isSuggestFetching,
    onApply
  } = useSuggestionsData({ show });
  const {
    data: recurringSpendData,
    sortVisibilityList: recurringSpendSortVisibilityList,
    setSortVisibilityList: setRecurringSpendSortVisibilityList,
    fetchData: fetchRecurringSpendData,
    isFetchingLoading: isRecurringSpendFetching
  } = useRecurringSpendData({ show });

  useEffect(() => {
    if (show) {
      setActiveTab(Tabs.Suggestions);
      setSearchValue("");
      setSuggestionsFilters([]);
      setRecurringSpendFilters([]);
      setSuggestionsSort(defaultSuggestionSort);
      setRecurringSpendSort(defaultRecurringSpendSort);
    }
  }, [show]);

  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTop = 0;
  }, [activeTab]);

  const curFilters = activeTab === Tabs.Suggestions ? suggestionsFilters : recurringSpendFilters;
  const curSetFilters = activeTab === Tabs.Suggestions ? setSuggestionsFilters : setRecurringSpendFilters;
  const curSortFunc = activeTab === Tabs.Suggestions ? setSuggestionsSort : setRecurringSpendSort;
  const filtersList = activeTab === Tabs.Suggestions 
    ? [
      { text: "Technologies", enum: FiltersEnum.Tecnhologies },
      { text: "Last sold", enum: FiltersEnum.LastSold },
    ] 
    : [
      { text: "Price", enum: FiltersEnum.Price },
      { text: "Quantity", enum: FiltersEnum.Quantity },
      { text: "Total", enum: FiltersEnum.Total },
      { text: "Last invoiced", enum: FiltersEnum.LastInvoiced },
      { text: "Categories", enum: FiltersEnum.Categories },
      { text: "Subcategories", enum: FiltersEnum.Subcategories },
    ];
  const orderData = activeTab === Tabs.RecurringSpend ? recurringSpendSortVisibilityList : [];

  const onOrderUpdate = (updatedData) => {
    if (activeTab === Tabs.RecurringSpend) {
      setRecurringSpendSortVisibilityList(updatedData);
      fetchRecurringSpendData({ clientId, sortVisibiityList: JSON.stringify(updatedData) });
    }
  }

  if (!show) return null;

  return (
    <div className="cyber-group-modal suggest-modal">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={Tabs.Suggestions}
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        <Header onHide={onHide} />
        <div className="body-scrollview hide-scrollbar" ref={scrollRef}>
          <BodyTop 
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filters={curFilters}
            setFilters={curSetFilters}
            suggestData={suggestData}
            recurringSpendData={recurringSpendData}
            filtersList={filtersList}
            onSortClick={curSortFunc}
            orderData={orderData}
            onOrderUpdate={onOrderUpdate}
          />
          <Body 
            activeTab={activeTab}
            clientId={clientId}
            searchValue={searchValue}
            refreshData={refreshData}
            suggestData={suggestData}
            recurringSpendData={recurringSpendData}
            fetchSuggestData={fetchSuggestData}
            fetchRecurringSpendData={fetchRecurringSpendData}
            isSuggestFetching={isSuggestFetching}
            isRecurringSpendFetching={isRecurringSpendFetching}
            onApply={onApply}
            suggestionsFilters={suggestionsFilters}
            suggestionsSort={suggestionsSort}
            recurringSpendFilters={recurringSpendFilters}
            recurringSpendSort={recurringSpendSort}
            setRecurringSpendSort={setRecurringSpendSort}
            orderData={orderData}
            onOrderUpdate={onOrderUpdate}
          />
        </div>
      </Tab.Container>
    </div>
  );
}

export default SuggestModal;

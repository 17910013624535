import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { getAPIData } from "../../Utils/commonApi";
import { TrimString } from "../../Utils/StringTrimmer";
import { useAuth } from "../context/Auth";
import IntegrationV2Modal from "../MSPv2/integrations/IntegrationV2Modal";
import MspIntegrationV2Modal from "../MSPv2/integrations/MspIntegrationV2Modal";
import { useHistory } from "react-router";
import { viewOnlyAccess } from "../App/App";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { MultiTagsUI } from "./AppMarketPlace";
import "./integrationsv2.scss"
import integrationUtils from "../../Utils/integration.utils";
import useQueryParams from "../../hooks/query-params.hook";
import { ROUTE_ACTIONS, ROUTE_PATHS } from "../../constants/app.constants";
import { IntegrationState } from "../../constants/integrations.constants";

const ConnectedTools = ({ IsMsp }) => {
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const { authTokens } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [connectedToolsList, setConnectedToolsList] = useState([]);
  const [showMspIntegrationModal, setShowMspIntegrationModal] = useState(false);
  const connectModalToOpenFor = new URLSearchParams(window.location.search).get(
    "openConnectModal"
  );
  const { getParam } = useQueryParams();
  const actionParam = getParam("action");
  const integrationIdParam = getParam("integrationId");

  async function getPageData() {
    await getAPIData("457", authTokens, { 
      optionEnum1: 3
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setConnectedToolsList(response?.IntegrationList);
          if (connectModalToOpenFor && response?.IntegrationList?.length > 0) {
            let integration_List = [...response?.IntegrationList];
            let itemIndex = integration_List.findIndex(
              (elem) =>
                Number(elem?.IntegrationId) === Number(connectModalToOpenFor)
            );
            if (itemIndex > -1) {
              setSelectedIntegration(connectModalToOpenFor);

              setShowMspIntegrationModal(true);
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  useEffect(() => {
    setLoading(true);
    getPageData();
  }, []);

  useEffect(() => {
    if (!actionParam || !integrationIdParam) return;
    if (actionParam === ROUTE_ACTIONS.integrationViewMapData) {
      history.push(
        `${ROUTE_PATHS.mspv2.mappings}?type=mapClients`,
        {
          IsMicrosoftLightHouse: integrationIdParam == 7242957 ? true : false,
          isPSA: false
        }
      );
    }
  }, [actionParam, integrationIdParam]);

  return (
    <div className="p-4 connectedTools-Tab">
      {loading ? (
        <ConnectedToolsLoader />
      ) : (
        <>
          {connectedToolsList?.length > 0 && (
            <div className="f-16 f-600 mb-4">Connected tools</div>
          )}
          <div className="d-flex flex-wrap gap-16">
            {/* If Connected tools not exist */}
            {connectedToolsList?.length === 0 && (
              <div className="d-flex m-auto flex-column align-items-center pt-5">
                <img
                  width={48}
                  src="/images/attack-surface/plug-icon.svg"
                  alt=""
                  className=""
                />
                <div className="f-16 f-500 mt-3 text-center">
                  No integrations connected
                </div>
                <div className="mt-3">
                  <span
                    className="f-blue pointer"
                    onClick={() => {
                      if (IsMsp) {
                        history.push(
                          ROUTE_PATHS.mspv2.allIntegrations
                        );
                      } else {
                        let appMarketPlaceTab = document.querySelector(
                          "#app-market-place a"
                        );
                        if (appMarketPlaceTab) appMarketPlaceTab.click();
                      }
                    }}
                  >
                    Browse HighGround App Marketplace
                  </span>
                </div>
              </div>
            )}

            {/* Connected tools List */}
            {connectedToolsList?.map((connectedIntegration, index) => {
              return (
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess("AccessState_Integrations")
                      ? ["hover", "focus"]
                      : []
                  }
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip id={`tooltip-1365`}>
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                    <div
                      className="bg-grey bg-hover-dark-grey radius-8 d-flex align-items-center flex-column p-3 pointer connected-tool-card"
                      style={{
                        pointerEvents: viewOnlyAccess(
                          "AccessState_Integrations"
                        )
                          ? "none"
                          : "all",
                      }}
                      onClick={() => {
                        setSelectedIntegration(
                          connectedIntegration?.IntegrationId
                        );
                        if (IsMsp) {
                          setShowMspIntegrationModal(true);
                        } else {
                          setShowIntegrationModal(true);
                        }
                      }}
                    >
                      {/* first row */}
                      <div
                        className="d-flex align-items-center justify-content-between w-100 mb-3"
                        style={{
                          height: "auto",
                          // width: "100%",
                        }}
                      >
                        <div
                          className="f-500 f-black d-flex align-items-center"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <img
                            src={integrationUtils.getImagePath(connectedIntegration?.IntegrationId, connectedIntegration?.ToolIcon)}
                            alt=""
                            className="mr-2 radius-4"
                            width={34}
                            height={34}
                          />
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {TrimString(
                              connectedIntegration?.IntegrationName,
                              30
                            )}
                          </div>
                        </div>
                        <div
                          className="radius-2 d-flex align-items-center justify-content-end p-1 px-2 f-12 f-500 expand-on-hover gap-4"
                          style={{
                            color: `${connectedIntegration?.IntegrationProgressColour}`,
                          }}
                        >
                          {connectedIntegration?.IntegrationProgress === IntegrationState.Syncing ? (
                            <Spinner
                              animation="border"
                              variant="secondary"
                              size="sm"
                              style={{
                                width: "14px",
                                height: "14px",
                                minWidth: '14px'
                              }}
                            />
                          ) : (
                            <img
                              alt=""
                              width={14}
                              height={14}
                              src={
                                connectedIntegration?.IntegrationProgressIconURL
                              }
                            />
                          )}
                          <div
                            style={{
                              height: "20px",
                              whiteSpace:"nowrap"
                            }}
                          >
                            {connectedIntegration?.IntegrationProgress}
                          </div>
                        </div>
                      </div>
                      {/* second row */}
                      <div className="d-flex align-items-center w-100 mb-2">
                       
                        {/* <div
                          className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                          style={{
                            background: `${"#616778"}30`,
                            color: `${"#616778"}`,
                          }}
                        >
                         
                          {connectedIntegration?.TechnologyTitle}
                        </div> */}
                        <MultiTagsUI tagList={connectedIntegration?.Tags_List} />
                       
                      </div>
                      {/* Third row */}
                      <div className="w-100 d-flex flex-column mt-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <div className="f-grey">Last Synced</div>
                          <div className="f-grey">
                            {connectedIntegration?.LastSyncDate
                              ? connectedIntegration?.LastSyncDate?.toString()?.split(
                                  " "
                                )?.[0]
                              : ""}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <div className="f-grey">Next Sync</div>
                          <div className="f-grey">
                            {connectedIntegration?.NextSyncDate
                              ? connectedIntegration?.NextSyncDate?.toString()?.split(
                                  " "
                                )?.[0]
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                </OverlayTrigger>
              );
            })}
          </div>
          <IntegrationV2Modal
            show={showIntegrationModal}
            hideModal={() => setShowIntegrationModal(false)}
            integrationId={selectedIntegration}
            parentID="integration-v2-screen"
            refreshData={() => {
              getPageData();
            }}
          />
          <MspIntegrationV2Modal
            show={showMspIntegrationModal}
            hideModal={() => {
              setShowMspIntegrationModal(false);
            }}
            integrationId={selectedIntegration}
            refreshData={() => {
              getPageData();
            }}
            parentID="msp-main-screen-for-integration-modal"
          />
        </>
      )}
    </div>
  );
};

export default ConnectedTools;

const ConnectedToolsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="0" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="0" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="240" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="240" rx="8" ry="8" width="30%" height="220" />

      <rect x="0" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="31%" y="480" rx="8" ry="8" width="30%" height="220" />
      <rect x="62%" y="480" rx="8" ry="8" width="30%" height="220" />
    </ContentLoader>
  );
};

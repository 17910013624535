import React, { useEffect } from "react";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import SlidingModal from "../../CustomModal/SlidingModal";
import { useState } from "react";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";
import { getAPIData, postJsonData, postRequest } from "../../../Utils/commonApi";
import "../mspMembersAccess/mspMembersAccess.scss"
import MspAccessModalToolTip from "../mspAccessModalTooltip";

const MspAccessLevelModal = ({ 
  show, hideModal, parentID, accessLevelId, refreshData, refreshDataOnDelete, dontAllowEdit,
  setUsersInUseData
}) => {
  const { authTokens} = useAuth();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  const [accessSettings, setAccessSettings] = useState([]);
  const [role, setRole] = useState({});

  useEffect(() => {
    if (show) {
      setLoading(true);
      getAccessSettings();
    }
  }, [show]);

  async function getAccessSettings() {
    await getAPIData("552", authTokens, {
      optionEnum1: accessLevelId ? accessLevelId : 0
    }).then((response) => {
      if (response?.mr?.Success) {
        console.log(response);
        setRole(response.data?.role);
        // setAccessSettings(response.data?.permissions?.filter((item) => item?.moduleName != "Technical Drilldown" && item?.moduleName != "Cyber Trend Data" && item?.moduleName != "Pro Services"));
        setAccessSettings(response.data?.permissions?.filter((item) => item?.moduleName != "Technical Drilldown" && item?.moduleName != "Cyber Trend Data" && item?.moduleName != "Pro Services"
        && item?.moduleName != "MSP Attack Surface Insights" && item?.moduleName != "Logs" && item?.moduleGroup != 'Marketing'));
        setLoading(false);
      }
    });
  }

  function changeAccessDropdown(obj, name){
    let newSettings = accessSettings.map(setting=>{
      if(setting.moduleName === name){
        console.log(setting.selected_AccessState, obj);
        setting.selected_AccessState = obj;
      }
      return setting;
    });
    setAccessSettings(newSettings);
  }

  async function deleteAccessLevel(){
    setDeleting(true);
    await postRequest(authTokens, "DeleteAccessRole", {
          objId_AccessRole: accessLevelId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }
    )
      .then((response) => {
        setDeleting(false);
        if (response?.mr?.Success) {
          CallToast("Access role deleted successfully", false, false, "v2style");

          hideModal();
          if (refreshDataOnDelete) {
            refreshDataOnDelete();
          }
          // if (refreshData) {
          //   refreshData();
          // }
        } else {
          setUsersInUseData(response?.detail);
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function sendRequest(newAccess) {
    setSaving(true);
    let accessObject = {
      role: role,
      permissions: accessSettings,
    };
    console.log(accessObject);
    if(accessObject?.role?.RoleName && accessObject?.role?.RoleName.trim() !== ""){
      await postJsonData(authTokens, "UpsertAccessRole", 
     accessObject
   )
     .then((response) => {
       setSaving(false);
       if (response?.Success) {
         CallToast("Access role updated successfully", false, false, "v2style");

         hideModal();
         if (refreshData) {
           refreshData();
         }
       } else {
         CallToast(response.Message, false, false, "v2style");
       }
     })
     .catch(() => {
       CallToast("Something went wrong", false, false, "v2style");
     });
   }
   else{
     CallToast("Access role cannot be empty", false, false, "v2style");
     setSaving(false);

   }
  }

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="ounsqxw"
      onHide={hideModal}
      className="common-big-modal memberAccessModal"
    >
      <SlidingModal.Header className=" text-center d-flex p-3 border-bottom">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {accessLevelId === 0 ? "Add Client Access Role" : role?.RoleName}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body className={`msp-service-add-edit-modal    ${true}`}>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="m-24px">
          <div className={`asset-name-wrapper   d-flex align-items-center p-2 radius-4`}>
            <input
              disabled={dontAllowEdit}
              tabIndex={0}
              type="text"
              placeholder={`Name: Type `}
              className="flex-grow-1 pl-3 pr-2"
              value={role?.RoleName}
              onChange={(e) => {
                setRole({
                  ...role,
                  RoleName: e.target.value,
                })
              }}
            />
            </div>
            <div className={`asset-name-wrapper d-flex align-items-center p-2 radius-4 mt8px `}>
            <input
              disabled={dontAllowEdit}
              tabIndex={0}
              type="text"
              placeholder={`Description: Type `}
              className="flex-grow-1 pl-3 pr-2"
              value={role?.RoleDescription}
              onChange={(e) => {
                setRole({
                  ...role,
                  RoleDescription: e.target.value,
                })
              }}
            />
            </div>
            <div className="border-top mt-24px">
            <div className="f-12 f-darkgrey mt-16px" >Build access</div>
                    {
                      accessSettings.map((setting, index)=>{
                          // if(setting.moduleGroup === group){
                            return (
                            <div className="d-flex flex-row justify-content-between  align-items-center">
                              <div className="d-flex flex-row align-items-center">
                                <div key={`${setting.moduleName} + ${index}`}>{setting.moduleName}</div>
                                <div className="w-12 tooltip-parent position-relative">
                                <img
                                  alt=""
                                  src="/images/supply-chain/dark-info-icon.svg"
                                  className="mr-2 ml-2 w-100"
                                />
                                <div className="tooltip-item position-absolute" style={{width: '150px', zIndex: 101}}>
                                  <MspAccessModalToolTip
                                  data={{
                                    Description: setting.moduleDescription,
                                  }}/>
                                </div>
                                </div>
                              </div>
                              
                              {
                                dontAllowEdit ? <div className="my-1 greyText2 mr-1">
                                {setting.selected_AccessState ? setting.selected_AccessState.stateName : 'No Access'}
                              </div> :
                              <Dropdown
                                    className="hg-white-dropdown user-detail-dropdown"
                                    onToggle={() => {
                                    }}
                                  >
                                    <Dropdown.Toggle className="custom-dropdown-toggle f-gray-imp">
                                      {/* {memb.SO_Admin? '' : memb.roleName} */}
                                      {setting.selected_AccessState ? setting.selected_AccessState.stateName : 'No Access'}
                                      
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {setting.accessStates.map((el, index)=>{
                                            return (<div
                                            key={el.stateName + index}
                                          className="p-2 pl-3 my-1 pointer f-grey"
                                          style={{ 'display': 'grid','grid-template-columns': '10% 90%'}}
                                          onClick={(e) => {
                                            changeAccessDropdown(el, setting.moduleName);
                                            e.target.closest(".hg-white-dropdown").click();
                                          }}
                                        >
                                          <div>
                                          {setting.selected_AccessState?.stateName === el.stateName ? 
                                          <img
                                          alt=""
                                          src="/images/settings-v2/check-icon.svg"
                                          className="mr-2"
                                          />
                                         : <></>
                                        } 
                                        </div>
                                          <div>
                                            {el.stateName}
                                          <div className="smallGreyText text-ellipsis f-darkgrey">{el.description}</div>
                                          </div>
                                          
                                        </div>)
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                              }
                            </div>);
                          // }
                      
                      })
                    }

            </div>
          </div>
        )}
     
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div className="d-flex align-items-center justify-content-between">
          {
            !dontAllowEdit ? <>
              <div>
                {accessLevelId > 0 && (
                  <div
                    className="d-flex align-items-center f-500 f-grey pointer"
                    onClick={() => {
                      deleteAccessLevel();
                    }}
                  >
                    <img
                      alt=""
                      className="mx-2"
                      src="/images/actioncenter/delete.svg"
                    />
                    {deleting ? "Deleting..." : "Delete"}
                  </div>
                )}
              </div> 
            </> : <div className="w-100"></div>
          }

          <div className="d-flex justify-content-end">
            <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
              Cancel
            </Button>
            {
              !dontAllowEdit && 
              <Button disabled={false} className="hg-blue-btn" onClick={() => {sendRequest()}}>
                {saving ? (
                  <>
                    {/* <WhiteLoader width={13} /> */}
                    Saving..
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            }
          </div>
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default MspAccessLevelModal;

import React, { memo, useEffect, useMemo, useState } from "react";
import MyRadarChart from "../../chartjs/my-radar.chart";
import { Radar } from "react-chartjs-2";

const MspClientPriceCalculatorRadarChartMergeSmall = ({
  activeChart = 1,
  _datasets = staticDatasets,
  merged_Array = [],
  merged_filter,
  isAnimating = false,
  setIsAnimating = () => { },
  isAnimatingEnd = false,
  setIsAnimatingEnd = () => { }
}) => {
  const gradientColors = [
    { stop: 0, color: 'rgba(64, 216, 142, 0.2)' },
    { stop: 0.17, color: 'rgba(255, 209, 29, 0.2)' },
    { stop: 0.32, color: 'rgba(255, 172, 44, 0.2)' },
    { stop: 0.49, color: 'rgba(243, 96, 96, 0.2)' },
    { stop: 0.66, color: 'rgba(255, 172, 44, 0.2)' },
    { stop: 0.85, color: 'rgba(255, 209, 29, 0.2)' },
    { stop: 1, color: 'rgba(64, 216, 142, 0.2)' }
  ];

  const gradientBorderColors = [
    { stop: 0, color: 'rgba(64, 216, 142, 1)' },
    { stop: 0.17, color: 'rgba(255, 209, 29, 1)' },
    { stop: 0.32, color: 'rgba(255, 172, 44, 1)' },
    { stop: 0.49, color: 'rgba(243, 96, 96, 1)' },
    { stop: 0.66, color: 'rgba(255, 172, 44, 1)' },
    { stop: 0.85, color: 'rgba(255, 209, 29, 1)' },
    { stop: 1, color: 'rgba(64, 216, 142, 1)' }
  ];

  const createGradient = (colors) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const newGradient = ctx.createLinearGradient(0, 150, 300, 150);
    colors.forEach(({ stop, color }) => {
      newGradient.addColorStop(stop, color);
    });

    return newGradient;
  };

  const gradient = createGradient(gradientColors);
  const gradientBorder = createGradient(gradientBorderColors);

  const mergedDataList = useMemo(() => {
    const firstDataArray = _datasets[0].data;

    return _datasets.map((dataset, index) => {
      if (index === 0) {
        return dataset;
      }

      const newData = dataset.data.map((value, i) =>
        Math.max(value, firstDataArray[i])
      );

      return {
        ...dataset,
        data: newData,
      };
    });
  }, [_datasets]);


  const updatedData = useMemo(() => {
    const index = merged_Array?.indexOf(true);
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => {
      if (index >= 0) {
        if (dataIndex === index) {
          return {
            ...data,
            data: mergedDataList[index]?.data,
            backgroundColor: isAnimating ? gradient : 'rgba(54, 162, 235, 0.3)',
            borderColor: isAnimating ? gradientBorder : 'rgba(54, 162, 235, 1)',
            pointBackgroundColor: isAnimating ? gradientBorder : 'rgba(54, 162, 235, 1)',
            pointRadius: dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],
            borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
          };
        } else {
          return {}
        }
      } else {
        if (dataIndex === activeChart || dataIndex === 0) {
          return {
            ...data,
            data: data.data,
            backgroundColor: isAnimating ? gradient :
              dataIndex === activeChart
                ? `${data.backgroundColor}30`
                : `${data.backgroundColor}00`,
            borderColor: isAnimating ? gradientBorder :
              dataIndex === activeChart
                ? `${data.borderColor}`
                : `${data.borderColor}`,
            pointBackgroundColor: isAnimating ? gradientBorder :
              dataIndex === activeChart
                ? `${data.borderColor}`
                : `${data.borderColor}`,
            pointRadius: dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0],
            borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
          }
        } else {
          return {}
        }
      }
    });

    if (isAnimating) {
      setIsAnimatingEnd(true);
      setTimeout(() => {
        setIsAnimating(false);
        // setGradient(null);
      }, index >= 0 ? 150 : 80);
    }
    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart, merged_Array, mergedDataList, merged_filter, isAnimating/*, isAnimating && gradient*/]);

  console.log('merged_filter', merged_filter)


  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 300,
      },
      devicePixelRatio: 4,
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: 50,
          min: 0,
          ticks: {
            display: false,
            stepSize: 10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);


  return (
    <div
      className="position-relative"
      style={{
        width: "150px",
        height: "150px",
        marginTop: "50px",
        marginBottom: "40px",
      }}
    >
      <MyRadarChart
        id="actial-radar"
        // redraw={true}
        height={150}
        width={150}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-17px",
          left: "76px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[0] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-42px",
          top: "30px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[1] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "2px",
          bottom: "-25px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[2] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "107px",
          bottom: "-25px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[3] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "-45px",
          top: "30px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[4] : 0}
        </div>
      </div>
    </div>
  );
};

export default memo(MspClientPriceCalculatorRadarChartMergeSmall);


export const MspClientPriceCalculatorRadarChartMergeMiddle = ({
  activeChart = 1,
  _datasets = staticDatasets,
  merged_Array = [],
  merged_filter,
  isAnimating = false,
  setIsAnimating = () => { },
  isAnimatingEnd = false,
  setIsAnimatingEnd = () => { }
}) => {
  const gradientColors = [
    { stop: 0, color: 'rgba(64, 216, 142, 0.2)' },
    { stop: 0.17, color: 'rgba(255, 209, 29, 0.2)' },
    { stop: 0.32, color: 'rgba(255, 172, 44, 0.2)' },
    { stop: 0.49, color: 'rgba(243, 96, 96, 0.2)' },
    { stop: 0.66, color: 'rgba(255, 172, 44, 0.2)' },
    { stop: 0.85, color: 'rgba(255, 209, 29, 0.2)' },
    { stop: 1, color: 'rgba(64, 216, 142, 0.2)' }
  ];

  const gradientBorderColors = [
    { stop: 0, color: 'rgba(64, 216, 142, 1)' },
    { stop: 0.17, color: 'rgba(255, 209, 29, 1)' },
    { stop: 0.32, color: 'rgba(255, 172, 44, 1)' },
    { stop: 0.49, color: 'rgba(243, 96, 96, 1)' },
    { stop: 0.66, color: 'rgba(255, 172, 44, 1)' },
    { stop: 0.85, color: 'rgba(255, 209, 29, 1)' },
    { stop: 1, color: 'rgba(64, 216, 142, 1)' }
  ];

  const createGradient = (colors) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const newGradient = ctx.createLinearGradient(0, 150, 300, 150);
    colors.forEach(({ stop, color }) => {
      newGradient.addColorStop(stop, color);
    });

    return newGradient;
  };

  const gradient = createGradient(gradientColors);
  const gradientBorder = createGradient(gradientBorderColors);

  const mergedDataList = useMemo(() => {
    const firstDataArray = _datasets[0].data;

    return _datasets.map((dataset, index) => {
      if (index === 0) {
        return dataset;
      }

      const newData = dataset.data.map((value, i) =>
        Math.max(value, firstDataArray[i])
      );

      return {
        ...dataset,
        data: newData,
      };
    });
  }, [_datasets]);


  const updatedData = useMemo(() => {
    const index = merged_Array?.indexOf(true);
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => {
      if (index >= 0) {
        if (dataIndex === index) {
          return {
            ...data,
            data: mergedDataList[index]?.data,
            backgroundColor: isAnimating ? gradient : 'rgba(54, 162, 235, 0.3)',
            borderColor: isAnimating ? gradientBorder : 'rgba(54, 162, 235, 1)',
            pointBackgroundColor: isAnimating ? gradientBorder : 'rgba(54, 162, 235, 1)',
            pointRadius: dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],
            borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
          };
        } else {
          return {}
        }
      } else {
        if (dataIndex === activeChart || dataIndex === 0) {
          return {
            ...data,
            data: data.data,
            backgroundColor: isAnimating ? gradient :
              dataIndex === activeChart
                ? `${data.backgroundColor}30`
                : `${data.backgroundColor}00`,
            borderColor: isAnimating ? gradientBorder :
              dataIndex === activeChart
                ? `${data.borderColor}`
                : `${data.borderColor}`,
            pointBackgroundColor: isAnimating ? gradientBorder :
              dataIndex === activeChart
                ? `${data.borderColor}`
                : `${data.borderColor}`,
            pointRadius: dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0],
            borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
          }
        } else {
          return {}
        }
      }
    });

    if (isAnimating) {
      setIsAnimatingEnd(true);
      setTimeout(() => {
        setIsAnimating(false);
        // setGradient(null);
      }, index >= 0 ? 150 : 80);
    }
    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart, merged_Array, mergedDataList, merged_filter, isAnimating/*, isAnimating && gradient*/]);

  console.log('merged_filter', merged_filter)


  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 300,
      },
      devicePixelRatio: 4,
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: 50,
          min: 0,
          ticks: {
            display: false,
            stepSize: 10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);


  return (
    <div
      className="position-relative"
      style={{
        width: "150px",
        height: "150px",
        marginTop: "50px",
        marginBottom: "40px",
      }}
    >
      <MyRadarChart
        id="actial-radar"
        // redraw={true}
        height={150}
        width={150}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-17px",
          left: "76px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[0] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-42px",
          top: "30px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[1] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "2px",
          bottom: "-25px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[2] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "107px",
          bottom: "-25px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[3] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "-45px",
          top: "30px",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[4] : 0}
        </div>
      </div>
    </div>
  );
};


const staticDatasets = [
  {
    // data: [25, 20, 17, 30, 31],
    data: [0, 0, 0, 0, 0],
    pointRadius: [0, 0, 0, 0, 0],
    datalabels: {
      display: false,
    },
    backgroundColor: `${"#5244B3"}`,
    borderColor: `${"#5244B3"}`,
    pointBackgroundColor: "#5244B3",
    borderWidth: 1,
    pointLabelFontSize: 0,
    scaleShowLabels: false,
    label: "Current Posture",
  },
  // {
  //   // data: [30, 5, 34, 20, 15],
  //   data: [0, 0, 0, 0, 0],
  //   pointRadius: [0, 0, 0, 0, 0],
  //   datalabels: {
  //     display: false,
  //   },
  //   backgroundColor: `${"#008fd4"}`,
  //   borderColor: `${"#008fd4"}`,
  //   pointBackgroundColor: "#008fd4",
  //   borderWidth: 1,
  //   pointLabelFontSize: 0,
  //   scaleShowLabels: false,
  //   label: "Current Posture",
  // },
  // {
  //   // data: [0, 10, 30, 25, 5],
  //   data: [0, 0, 0, 0, 0],
  //   pointRadius: [0, 0, 0, 0, 0],
  //   datalabels: {
  //     display: false,
  //   },
  //   backgroundColor: `${"#008fd4"}`,
  //   borderColor: `${"#008fd4"}`,
  //   pointBackgroundColor: "#008fd4",
  //   borderWidth: 1,
  //   pointLabelFontSize: 0,
  //   scaleShowLabels: false,
  //   label: "Current Posture",
  // },
];

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./settingsSideNav.scss";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../Utils/StringTrimmer";
import { TrackUser } from "../../Utils/SegmentFunctions";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import ImageLoader from "../Common/ImageLoader";
import useUserSettings from "./settings.data";
import CrossIcon from "../icons/cross.icon";
import { HGHighlightText } from "../App/App";
import { ROUTE_PATHS } from "../../constants/app.constants";
import UserSidebarMenu from "../user-sidebar-menu/user-sidebar-menu.component";
import UserActionsList from "../MSPv2/user-actions.list";

const SettingsV2Header = () => {
  const [search, setSearch] = useState("");
  const [dropDownvisible, setDropDownvisible] = useState(false);
  // const [NoOfPagesSearched, setNoOfPagesSearched] = useState(0);
  const history = useHistory();
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const dashboardUrlPrefix = window.localStorage.getItem("dashboardUrlPrefix") ?? null;
  const { SearchablePages, SuggestedPages } = useUserSettings();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const NoOfPagesSearched = SearchablePages.filter(
    (val) => search && val.pageName.toLowerCase().includes(search.toLowerCase())
  ).length;
  return (
    <>
      <SidebarMenu 
        userDetails={userDetails}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen} 
      />
      <div className="settings-v2-header p-2 justify-content-between w-100">
        {/* right side header */}

        <div className="d-flex align-items-center ">
          <img
            alt=""
            src="/images/settings-v2/logo.svg"
            className="mr-3 pointer"
            onClick={() => {
              history.replace(ROUTE_PATHS.saas.dashboard);
            }}
          />
          <div className="header-title align-items-center mr-4 d-none d-sm-flex">
            Settings
          </div>
        </div>
        {/* search bar */}
        <div className="d-flex align-items-center flex-1 justify-content-between">
          <div className="search-bar-v2">
            <img
              alt=""
              src="/images/settings-v2/search-icon.svg"
              className="mr-2"
            />
            <input
              type="text"
              placeholder="Search Settings"
              className="search-bar-v2-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              autoComplete="new-password"
              onFocus={() => setDropDownvisible(true)}
              onBlur={() => setTimeout(() => setDropDownvisible(false), 300)}
            />
            {search && (
              <img
                alt=""
                src="/images/settings-v2/header-search-cross.svg"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setDropDownvisible(false);

                  setSearch("");
                }}
              />
            )}
            {/* Search drop down */}
            <div
              className={`search-v2-Dropdown ${dropDownvisible ? "" : "d-none"}`}
            >
              <div className="suggested-dropdown">
                <div className="suggestion my-2 ml-2">
                  {search == "" && "Suggestions"}
                  {NoOfPagesSearched == 1 && "1 result"}
                  {NoOfPagesSearched > 1 && `${NoOfPagesSearched} results`}
                  {search &&
                    NoOfPagesSearched == 0 &&
                    `No results for the “${search}”  request`}
                </div>

                {search && NoOfPagesSearched == 0 && (
                  <div className="suggestion mb-2 mt-3 ml-2">Suggestions</div>
                )}

                {(!search || NoOfPagesSearched == 0) &&
                  SuggestedPages.map((page, index) => {
                    return (
                      <div
                        className="d-flex align-items-center pl-3 py-1 suggested-page"
                        key={index}
                        onClick={() => {
                          history.replace(page.pageURL);
                        }}
                      >
                        <ImageLoader 
                          alt="" 
                          src={page.Image} 
                          className="mb-1 mr-2"
                          {...(page.imageStyle && { style: page.imageStyle })}
                          noImageComponent={page.noImageComponent ?? <div />} 
                        />
                        <div className="suggested-page-name d-flex align-items-center">
                          {page.pageName}
                        </div>
                      </div>
                    );
                  })}

                {search &&
                  SearchablePages.filter((val) =>
                    val.pageName.toLowerCase().includes(search.toLowerCase())
                  ).map((page, index) => {
                    return (
                      <div
                        className="d-flex align-items-center pl-3 py-1 suggested-page"
                        key={index}
                        onClick={() => {
                          history.replace(page.pageURL);
                          setSearch("");
                        }}
                      >
                        <ImageLoader 
                          alt="" 
                          src={page.Image} 
                          className="mb-1 mr-2"
                          {...(page.imageStyle && { style: page.imageStyle })}
                          noImageComponent={page.noImageComponent ?? <div />} 
                        />
                        <div className="suggested-page-name d-flex align-items-center">
                          <span 
                            dangerouslySetInnerHTML={{
                              __html: HGHighlightText(
                                page.pageName,
                                search
                              ),
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* left side header */}
          <div className="d-flex align-items-center" style={{ gap: '16px' }}>
            <div className='pointer' onClick={() => history.goBack()}>
              <CrossIcon width="14px" height="14px" fill="#9499aa" />
            </div>

            {/* User logo with drop down */}
            <div className="d-lg-none">
              <div className="w-100 d-flex justify-content-end d-lg-none">
                <img 
                  src="/images/menu.svg"
                  width="24px"
                  height="24px"
                  alt="menu"
                  onClick={() => setIsSidebarOpen(true)}
                />
              </div>
            </div>

            <div
              style={{ marginLeft: "" }}
              className="d-none d-lg-block sideBarDropdown- supportDropdown  header-logo-dropdown"
            >
              <Dropdown>
                <Dropdown.Toggle>
                  {userDetails.Avatar == "No URL" ? (
                    <ImageWithInitials
                      width={31}
                      background="purple"
                      initials={userDetails?.Initials}
                    />
                  ) : (
                    <img
                      alt=""
                      src={userDetails?.Avatar}
                      className="thumbnail-pic"
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={true}
                    style={{ opacity: "1 !important" }}
                  >
                    <div className="d-flex justify-content-between align-items-center ">
                      <div className="d-flex align-items-center">
                        {userDetails.Avatar == "No URL" ? (
                          <ImageWithInitials
                            width={31}
                            background="purple"
                            initials={userDetails?.Initials}
                          />
                        ) : (
                          <img
                            alt=""
                            src={userDetails?.Avatar}
                            className="thumbnail-pic"
                          />
                        )}

                        <div className="ml-2">
                          <p className="title m-0">
                            {TrimString(userDetails?.FullName, 17)}
                          </p>
                          <p
                            className="subtitle m-0"
                            style={{ color: "#9499aa" }}
                          >
                            {TrimString(userDetails?.UserName, 17)}
                          </p>
                        </div>
                      </div>
                      <div>{/* <img src="/images/chevron-right.svg" /> */}</div>
                    </div>
                  </Dropdown.Item>
                  <hr className="m-0" />
                  <Dropdown.Item
                    type="button"
                    className="mt-1 mb-1"
                    onClick={() => history.replace(ROUTE_PATHS.saas.settings.myProfile)}
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/settingwheel.svg"
                      className="mr-2 v2-item-color"
                    />
                    <span
                      className="f-grey-imp"
                      style={{
                        paddingTop: "2px",
                      }}
                    >
                      Personal Settings
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    type="button"
                    onClick={() => {
                      TrackUser("User Signed out");
                      setTimeout(() => history.replace(ROUTE_PATHS.auth.logout), 100);
                      // history.push("/logout")
                    }}
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/logout.svg"
                      className="mr-2 v2-item-color"
                    />
                    <span className="f-grey-imp">Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsV2Header;

const SidebarMenu = ({
  userDetails,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const onClose = () => {
    setIsSidebarOpen(false);
  }
  return (
    <div className="f-white">
      <UserSidebarMenu
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        userDetails={userDetails}
        isSaas
      >
        <UserActionsList 
          userDetails={userDetails}
          onItemClick={onClose}
          isSidebar
          isSaas
        />
      </UserSidebarMenu>
    </div>
  )
}

import React from "react";
import { numberWithCommas } from "../../MspClientPriceCalculator";
import useRespondTo from "../../../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../../../constants/window.constants";
import { addZerows } from "../../../mspSell/mspPriceCalculator";

export const ContributionTable = ({
  resetting,
  packageDetails,
  contribLoading,
  showContribReset,
  contributionValue,
  assetBreakDownData,
  switchIncludedTaxVal,
  setResetting = () => { },
  setContribLoading = () => { },
  setShowContribReset = () => { },
  updatePackageMappedPrice = () => { },
  setIsFromAddContribTable = () => { },
  setShowMapCurrentSpendModal = () => { },
  setMapCurrentSpendPackageId = () => { },
  setMapCurrentSpendPackagePrice = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  setMapCurrentSpendPackagePriceText = () => { },
}) => {
  const isMobile = useRespondTo(WindowBreakpoints.md);

  return (
    <div className="my-3">
      <div className="d-flex align-items-center justify-content-between mb-1">
        <div className="f-500 f-12 f-darkgrey w-100">
          Contribution
        </div>
        {contribLoading && <div className="msp-service-update-loader ml-2 mr-1"></div>}
        {
          !contribLoading && showContribReset && <div
            className="txt-blue pointer d-flex align-items-center"
            onClick={() => {
              setResetting(true);
              setShowContribReset(false);
              setContribLoading(true);
              updatePackageMappedPrice(packageDetails?.ScenarioHeader?.objId_Scenario);
              setResetting(false);
            }}
          >
            <img
              alt=""
              className={`mr-1 ${resetting && "rotate-full"}`}
              src="/images/msp/blue-load-icon.svg"
            />
            {resetting ? "Resetting.." : "Reset"}
          </div>
        }
      </div>

      <div className="border-parent  radius-8">
        <div className="border-item f-12 f-500 f-darkgrey p-2 contribution_table">
          <div>Recurring Item</div>
          <div>Qty.</div>
          <div>Price</div>
          <div>Total</div>
          <div>Period</div>
        </div>
        {assetBreakDownData?.MSPPackagePrice?.PackageMapCurrentSpend_List
          ?.map((data, dataIndex) => {
            return (
              <>
                {data?.ItemName && data?.ItemName !== null && (
                  <div className="border-item p-2 contribution_table">
                    <div className="f-400 f-14 f-black">{data.ItemName}</div>
                    <div className="f-400 f-14 greyText2">{data.Quantity}</div>
                    <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{addZerows(data?.Price?.toString())}</div> 
                    <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{addZerows(data?.Total?.toString())}</div>
                    <div className="f-400 f-14 greyText2">{data.Period_Text}</div>
                    {
                      !isMobile &&
                      <img
                        alt=""
                        width={10}
                        src="/images/pencil-icon.svg"
                        className="mx-2 pointer"
                        onClick={(e) => {
                          setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
                          setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
                          setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(assetBreakDownData?.MSPPackagePrice?.Subtotal + assetBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${assetBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                          setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(assetBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : assetBreakDownData?.MSPPackagePrice?.Subtotal);
                          setShowMapCurrentSpendModal(true);
                          setIsFromAddContribTable(true);
                          const drag = document?.getElementById("map-current-spend-modal");
                          drag.style.display = "block";
                        }}
                      />
                    }
                  </div>
                )}
              </>
            );
          })}

        <div className="f-500 f-12 greyText2  p-2 border-item pointer"
          onClick={() => {
            setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
            setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
            setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(assetBreakDownData?.MSPPackagePrice?.Subtotal + assetBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${assetBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
            setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(assetBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : assetBreakDownData?.MSPPackagePrice?.Subtotal);
            setShowMapCurrentSpendModal(true);
            setIsFromAddContribTable(true);
            const drag = document?.getElementById("map-current-spend-modal");
            drag.style.display = "block";
          }}
        >
          + Add
        </div>

        <div className="contribution_table p-2">
          <div className="f-400 f-14 greyText2">
            Total
          </div>
          {
          !isMobile &&
          <div></div>
          }
          <div></div>
          <div>
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {numberWithCommas(Number(contributionValue)?.toFixed(2))}
          </div>
          {
          isMobile &&
          <div></div>
          }
          <div className="f-400 f-14 greyText2">
            /{assetBreakDownData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionTable;

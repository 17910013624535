import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";

import Loader from "../../Common/loader";
import Integration from "./ConnectTool/Integration";
import ConnectTool from "./ConnectTool/ConnectTool";
import Loder from "./ConnectTool/Loader";
import Connection from "./ConnectTool/Connection";
import Notification from "./ConnectTool/Notification";
import Task from "./ConnectTool/Task";
import "./actionCentreModals.scss";
// import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import APIconnection from "./ConnectTool/APIconnection";
import { getAPIData as getToolsList } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const ActionCenter = (props) => {
  const {
    isOpen,
    setIsOpen,
    setResetModal,
    isIntegration,
    setIntegration,
    integration,
    settingsV2,
  } = props;
  const [loading, setLoading] = useState(false);
  const [toolData, setToolData] = useState({
    toolName: "Jira",
  });
  function UpdateTooldata(data) {
    setToolData({
      ...toolData,
      ...data,
    });
  }
  // Below modal tabs States
  // action_center
  // integration
  // connect_tool
  // connect
  //loading
  //connections
  //notifications
  //taskcreation
  const [modaltab, setModalTab] = useState("action_center");
   
  const [connectedTool, setConnectedTool] = useState(null);
  const { authTokens } = useAuth();
  useEffect(() => {
    if (isOpen) {
      if (settingsV2) {
        setModalTab(settingsV2);
      } else {
        setLoading(true);
        getToolsList(256, authTokens)
          .then((response) => {
            setLoading(false);
            if (response?.mr?.Success) {
              let connected_tool = [
                ...response?.TaskManagerIntergration,
              ].filter((item) => item?.TMIntegration_Connected === true);
              if (connected_tool?.length > 0) {
                UpdateTooldata({
                  toolName: connected_tool[0]?.TMIntegartionName,
                  toolId: connected_tool[0]?.TMIntegartion_ObjId,
                  ConnectionStrings: [],
                });
                setConnectedTool(true);
              }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
          });
      }
    }
  }, [isOpen, settingsV2]);

 
  const [mappingType, setMappingType] = useState(0);
  const isCloseModal = () => {
    setModalTab("action_center");
    {
      !settingsV2 && integration();
    }
    // setTimeout(() => {
    setIsOpen(false);
    // }, 100);
  };
  return (
    <Modal
      animation={false}
      show={isOpen}
      dialogClassName={`notifications-modal  ${
        modaltab === "taskcreation" ? "taskcreationmodalissue" : ""
      }    
      
      ${modaltab === "integration" ? "modal-650-760h" : ""}

      ${modaltab === "notifications" ? "modal-650-760h" : ""}
      
      ${modaltab === "connections" ? "modal-650-760h" : ""}

      ${modaltab === "connect_tool" ? "modal-650-760h" : ""}
      ${modaltab === "connect" ? "modal-650-760h" : ""}
      
      
      
      `}
      className={`integrationModal  centerModal dashboardModal action_center_settings_modal     `}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={isCloseModal}
      scrollable
    >
      <Modal.Header className="text-center ">
        {(modaltab === "integration" ||
          modaltab === "connect_tool" ||
          modaltab === "connect" ||
          modaltab === "connections" ||
          modaltab === "notifications" ||
          modaltab === "taskcreation") && (
          <span
            class="cursor-pointer"
            onClick={() => {
              if (connectedTool) {
                setModalTab("action_center");
                return;
              }
              let newTab = "";
              switch (modaltab) {
                case "integration":
                  newTab = "action_center";
                  break;
                case "connect_tool":
                  newTab = "integration";
                  break;
                case "connect":
                  newTab = "connect_tool";
                  break;
                case "connections":
                  newTab = "connect";
                  break;
                case "notifications":
                  newTab = "action_center";
                  break;
                case "taskcreation":
                  newTab = "action_center";
                  break;
                default:
                  newTab = "action_center";
              }
              setModalTab(newTab);
            }}
          >
            {modaltab === settingsV2 ? null : (
              <img src="/images/icons/arrowLeft.svg" alt="" />
            )}
          </span>
        )}

        <h1
          className={`w-100 mb-0 mt-0  ${
            modaltab === "connections" && "header-setting"
          }  `}
          style={{ fontSize: "16px" }}
        >
          {modaltab === "action_center" && (
            <img
              alt=""
              className="mr-2"
              src="/images/actionCentreSettings/setting-wheel.svg"
            />
          )}
          {modaltab === "action_center" && "Action Centre Settings"}
          {modaltab === "integration" && "Integrations"}
          {modaltab === "connect_tool" && "Connect Tool"}
          {modaltab === "connect" && `Connect ${toolData?.toolName}`}
          {modaltab === "loading" && "Connect Asana"}

          {modaltab === "taskcreation" && "Tasks creation"}
          {modaltab === "notifications" && "Notifications"}
          {modaltab === "connections" &&
            `${toolData?.toolName} Integration Settings`}
          {modaltab === "connections" && (
            <span className="notification-header-dropdownx ml-4">
              {/* <CompanySettingsDropdown
                fieldId={"id"}
                fieldValue={"category"}
                selected={notificationType}
                placeholder="Mapping type"
                //  showid={true}
                onClick={(id, val) => {
                  setMappingType(id);
                  setNotificationType({ id, category: val });
                }}
                data={[
                  { id: 0, category: "Setting" },
                  { id: 1, category: "Status" },
                ]}
              /> */}
            </span>
          )}
        </h1>

        {/* Header dropdown in Notification section */}

        <span className="cursor-pointer" onClick={() => isCloseModal()}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="p-0">
         
        {loading && (
          <div className="loader d-flex align-items-center justify-content-center h-100">
            <Loader />
          </div>
        )}

        <Row
          className={`m-0 h-100 ${loading ? "d-none" : ""}`}
          style={{
            overflow: ` ${modaltab !== "connections" ? "hidden" : "visible"}`,
          }}
        >
          <Col md={12} className="mt-1 p-0 modal_action_padding">
            {modaltab === "action_center" && (
              <div className="p24lr">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="mt-3">
                    <span className="titletext">Integrations</span>
                    <p className="titletextgrey">
                    Sync your HighGround tasks with current task/project management tool for easier management
                    </p>
                  </div>
                  <div>
                    {connectedTool && true ? (
                      <Button
                        className="hg-cancel-btn"
                        onClick={() => {
                          setModalTab("connections");
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <button
                        className="replotposture mt-4"
                        onClick={() => setModalTab("integration")}
                      >
                        <img
                          alt=""
                          src="/images/actioncenter/plus.svg"
                          className="pr-1"
                        />
                        Connect
                      </button>
                    )}
                  </div>
                </div>
                <hr className="mb-0" />

                <div className="d-flex align-items-center justify-content-between">
                  <div className="mt-3">
                    <span className="titletext">Task Creation</span>
                    <p className="titletextgrey">
                      Configure how tasks are automatically created in your action centre
                    </p>
                  </div>
                  <div>
                    <button
                      className="editbutton mt-4"
                      onClick={() => setModalTab("taskcreation")}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <hr className="mb-0" />

                <div
                  className="d-flex align-items-center justify-content-between crsrpoint"
                  onClick={() => setModalTab("notifications")}
                >
                  <div className="mt-3">
                    <span className="titletext">Notifications</span>
                    <p className="titletextgrey">
                    Configure how you are notified about tasks created in your action centre
                    </p>
                  </div>
                  <div>
                    <img
                      alt=""
                      src="/images/actioncenter/arrowright.svg"
                      height="12"
                      className="mt-4"
                    />
                  </div>
                </div>
                <hr className="mb-0" />

                <div
                  className="d-flex align-items-center justify-content-between crsrpoint"
                  onClick={setResetModal}
                >
                  <div className="mt-3">
                    <span className="titletext">Reset settings</span>
                    <p className="titletextgrey">
                      Restore settings to their defaults
                    </p>
                  </div>
                  <div>
                    <img
                      alt=""
                      src="/images/actioncenter/arrowright.svg"
                      height="12"
                      className="mt-4"
                    />
                  </div>
                </div>
                <hr className="mb-0" />
              </div>
            )}

            {/* Integration */}
            {modaltab === "integration" && (
              <Integration
                isIntegration={isIntegration}
                setModalTab={() => setModalTab("connect_tool")}
              />
            )}

            {/* Integration */}
            {modaltab === "connect_tool" && (
              <ConnectTool
                setModalTab={() => setModalTab("connect")}
                UpdateTooldata={UpdateTooldata}
                goToIntegrationPage={() => setModalTab("connections")}
              />
            )}

            {/* Connect */}
            {modaltab === "connect" && (
              <APIconnection
                setModalTab={() => setModalTab("connections")}
                toolData={toolData}
              />
            )}

            {/* loading */}
            {modaltab === "loading" && (
              <Loder
                modalTab={modaltab}
                setModalTab={() => setModalTab("connections")}
              />
            )}

            {/* connections */}
            {modaltab === "connections" && (
              <Connection
                mappingType={mappingType}
                closeModal={isCloseModal}
                setModalTab={() => {
                  // setModalTab("integration");
                  {
                    !settingsV2 && setIntegration();
                  }
                }}
              />
            )}

            {/* Notification */}
            {modaltab === "notifications" && (
              <Notification
                closeModal={isCloseModal}
                setModalTab={() => setModalTab("connections")}
              />
            )}

            {/* Notification */}
            {modaltab === "taskcreation" && (
              <Task
                closeModal={isCloseModal}
                setModalTab={() => setModalTab("connections")}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
};

export default ActionCenter;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./OldLoginStyling.scss";
import { Form, Spinner, Button, Row, Col, InputGroup } from "react-bootstrap";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { useDb } from "../context/db";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { updateAccessKeyInLocalStorage, validateEmail } from "../../Utils/CommonFunctions";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { useAuth } from "../context/Auth";
import { ROUTE_PATHS } from "../../constants/app.constants";

const AnonymousLogin = (props) => {
  const {
    keyVal,
    pageState,
    userDetail,
    showConfirmationScreen,
    message,
    objId,
  } = props;
  const [loading] = useState(false);
  const { setdbObject } = useDb();

  const { setAuthTokens } = useAuth();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [obj_Id, setobj_Id] = useState(objId ? objId : 0);

  const CURRENT_URL = useLocation().pathname;

  const location = useLocation();
  const { state: loginInformation } = location;

  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [codeError, setCodeError] = useState("");

  const history = useHistory();
  const [pageData, setPageData] = useState({});
  const [formState, setFormState] = useState(pageState);

  const [timeState, setTimeState] = useState({
    seconds: 0,
    startTimer: false,
  });
  const [sendingEmail, setSendingEmail] = useState(false);
  const [errors, setErrors] = useState({
    emailError: "",
  });

  const PAGE_STATE = {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    MAIL_SENT: "MAIL_SENT",
    RESET_PASSWORD: "RESET_PASSWORD",
  };

  useEffect(() => {
    if (timeState.startTimer) {
      countdownTimer();
      setTimeState({ ...timeState, startTimer: false });
    }
  }, [timeState]);

  useEffect(() => {
    if (formState === 0) {
      setPagState(PAGE_STATE.FORGOT_PASSWORD);
    } else if (formState === 1) {
      setPagState(PAGE_STATE.MAIL_SENT, email);
    }
  }, [formState]);

  function setPagState(pageType, email) {
    switch (pageType) {
      case PAGE_STATE.MAIL_SENT:
        setPageData({
          headerText: "Confirm your email",
          subText: `Check your email and enter 6-digit code`,
          state: "MAIL_SENT",
        });

        break;

      default:
        break;
    }
  }

  async function loginUser(event) {
    event.preventDefault();

    if (name === "") {
      setNameError("Name is required");
      return;
    }
    if (!validateEmail(email)) {
      setEmailError("Enter valid email address");
      return;
    }

    setTimeState({ seconds: 10, startTimer: true });
    SubmitInformationRequest();

    return;
  }
  async function confirmLogin() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const data = fetch(
      `${process.env.REACT_APP_API_BASE_URL}/HGIR/ConfirmLogin?key=${keyVal}&pincode=${code}&obj_Id=${obj_Id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("confirmLogin", data);
        if (data.Success) {
          console.log("Message", data.Message);
          if (true) {
            updateAccessKeyInLocalStorage(keyVal)
           
            setAuthTokens(data.Message);
            // history.push("/supplier-profiler/profiling", {
            history.push(ROUTE_PATHS.anonymous, {
              backUrl: null,
              isContributorView: true,
              accessToken: data.Message,
              accessKey: keyVal,
              questionId: null,
            });
          }
        }
      })
      .catch((ex) => {
        CallToast("Something went wrong" + ex, false, false, "v2style");
      });
  }

  async function SubmitInformationRequest() {
    // var myHeaders = new Headers();
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("name", name);
    urlencoded.append("key", keyVal);
    urlencoded.append("baseCallingUrl", "http://localhost:3000");
    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };
    const data = fetch(
      `${process.env.REACT_APP_API_BASE_URL}/HGIR/SubmitInformationRequest`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("SubmitInformationRequest", data);
        // CallToast("Profiling updated successfully", false, false, "v2style");
        if (data.Success) {
          setFormState(1);
          setobj_Id(data.EntityId);
        }
      })
      .catch((ex) => {
        CallToast("Something went wrong" + ex, false, false, "v2style");
      });
  }

  useEffect(() => {
    // setAuth(false);
    let user_token = localStorage.getItem("tokens");
    let user_data = localStorage.getItem("object_Data");

    if (user_token !== null && user_data !== null) {
      let _data = JSON.parse(user_data);
      if (!CURRENT_URL.includes("redirect")) {
        history.push(ROUTE_PATHS.saas.dashboard);
      }
    } else {
      setdbObject();
      localStorage.setItem("dasboardLoader", true);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.newEmail) {
      setEmail(location?.state?.newEmail);
      setName(location?.state?.newPassword);
    }
  }, []);

  function countdownTimer() {
    const timer = setInterval(() => {
      if (timeState.seconds > 0) {
        setTimeState((prev) => {
          if (prev.seconds >= 1) {
            return { ...prev, seconds: prev.seconds - 1 };
          } else {
            clearInterval(timer);
            return prev;
          }
        });
      }
    }, 1000);
  }
  return (
    <div className="d-flex w-100 h-100 p-4">
      <div className="w-100 d-flex flex-column mx-auto">
        {formState === 0 && (
          <div className="m-auto w-35">
            <div>
              <Row
                className="align-items-center slideLeft m-0"
                style={{ paddingTop: "36px" }}
              >
                <Col sm={12} className="text-center pt-5 pb-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      className="mb-4"
                      alt="HighGround Logo"
                      src="/images/logo/highground/regular.svg"
                    />
                  </div>

                  <div className="bg-grey radius-4 p-3 mx-5 mb-4">
                    <div className="f-12 f-500 f-darkgrey mb-2 d-flex align-items-centr justify-content-start">
                      Supplier Profiling
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        {!userDetail?.avatar && !userDetail?.initails && (
                          <img
                            src="/images/dashboard/pro_1.svg"
                            alt="lock"
                            className="mr-2 rounded-full"
                          />
                        )}
                        {userDetail?.showInitials ? (
                          <ImageWithInitials
                            initials={userDetail?.initails}
                            width={24}
                            background={"purple"}
                          />
                        ) : (
                          <img
                            src={userDetail?.avatar}
                            alt=""
                            width={24}
                            className="mr-2 rounded-full"
                          />
                        )}
                      </div>
                      <div className="f-14 f-500">
                        {userDetail?.fullName} needs your help
                      </div>
                    </div>
                    <div
                      className="f-14 d-flex align-items-center mt-2"
                      style={{
                        textAlign: "initial",
                      }}
                    >
                      “{message}”
                    </div>
                  </div>

                  <div>
                    <h3
                      className="lgn_HeaderText2"
                      style={{
                        fontFamily: "Syne",
                      }}
                    >
                      Login
                    </h3>
                    <p className="f-darkgrey">
                      Login to get access to document
                    </p>
                  </div>
                </Col>
                <Col md={10} className="mx-auto" style={{ paddingTop: "1em" }}>
                  <Form
                    className="userLoginForm"
                    onSubmit={loginUser}
                    autoComplete="none"
                  >
                    <div className="label-sign-up">Name</div>
                    <Form.Group
                      controlId="loginPassword"
                      className={`mt- ${nameError ? "border-danger" : ""}`}
                      style={{ border: "1px solid #EBEDF1" }}
                    >
                      <InputGroup className="mr-sm-2">
                        <Form.Control
                          className="inputControl"
                          size="lg"
                          placeholder="Enter Name Here"
                          type={"text"}
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                            setNameError("");
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                    {nameError && (
                      <span className="error-text">{nameError}</span>
                    )}

                    <div className="d-flex align-items-center justify-content-betwee n mb-1 mt-3">
                      <div className="f-500 f-black">Email</div>
                    </div>

                    <Form.Group
                      controlId="loginEmail"
                      className={emailError ? "border-danger" : ""}
                      style={{ border: "1px solid #EBEDF1" }}
                    >
                      <InputGroup className="mr-sm-2">
                        <Form.Control
                          className="inputControl"
                          size="lg"
                          type="text"
                          placeholder="Enter Email Here"
                          onChange={(event) => {
                            setEmail(event.target.value);
                            setEmailError("");
                          }}
                          value={email}
                        />
                      </InputGroup>
                    </Form.Group>
                    {emailError && (
                      <span className="error-text">{emailError}</span>
                    )}

                    <div className="mt-4">
                      <Button
                        size="lg"
                        variant="blue"
                        className={`form-control btnControl   `}
                        type="submit"
                      >
                        {loading ? (
                          <span>
                            <Spinner
                              size="sm"
                              className="mr8px"
                              animation="border"
                            />{" "}
                            Authenticating
                          </span>
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
            <Row className="m-0">
              <Col md={10} className="pt-4 pb-5 mx-auto f-12 f-darkgrey">
                Creating a HighGround account means you have read, understood
                and agree to M3 Networks Limited{" "}
                <u
                  className="pointer"
                  onClick={() => {
                    window.open(
                      "https://www.highground.io/privacy_policy/",
                      "_blank"
                    );
                  }}
                >
                  Privacy Policy
                </u>{" "}
                and{" "}
                <u
                  className="pointer"
                  onClick={() => {
                    window.open(
                      "https://www.highground.io/highground-terms-and-conditions/",
                      "_blank"
                    );
                  }}
                >
                  Terms & Conditions
                </u>
                .
              </Col>
            </Row>
          </div>
        )}

        {formState === 1 && (
          <div className=" w-25 m-auto">
            <Row className="align-items-center" style={{ paddingTop: "40px" }}>
              <Col md={12} className="text-center">
                <div>
                  <img alt="" src="/images/logo-large.svg" className="icon" />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "1.5em" }}>
              <Col md={12} className="text-center pt-3 pb-4">
                <div>
                  <h3 className="f-24 f-700 mb-0">{pageData.headerText}</h3>
                  <p className="f-14 f-darkgrey">{pageData.subText}</p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center" style={{ paddingTop: "0.1em" }}>
              <Col className="mx-auto">
                <div className="f-500">Code</div>
                <div
                  className={`highlight-input-with-grey-border  w-100  d-flex align-items-center p-2 radius-4 mt-1   ${
                    errors?.emailError ? "input-error" : ""
                  } `}
                >
                  <input
                    tabIndex={0}
                    // disabled={timeState.seconds > 0}
                    type="text"
                    placeholder="Enter 6-digit code"
                    className="flex-grow-1 pl-1 pr-2 py-1"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                      setCodeError("");
                    }}
                  />
                  {timeState.seconds > 0 ? (
                    <span className="f-grey">{timeState.seconds}s</span>
                  ) : (
                    <span
                      className="f-blue pointer"
                      onClick={() => {
                        setTimeState({ seconds: 10, startTimer: true });
                      }}
                    >
                      Resend
                    </span>
                  )}
                </div>

                <div className="f-danger">{codeError}</div>

                <div className="mt-4">
                  <Button
                    className={`form-control btnControl   ${
                      formState === 1 && timeState.seconds > 0
                        ? "disabled-bt n"
                        : ""
                    } `}
                    size="lg"
                    variant="blue"
                    type="submit"
                    onClick={async () => {
                      if (code.length < 1) {
                        setCodeError("Please enter a valid code");
                        return;
                      }

                      confirmLogin();
                      // if (true) {
                      //   history.push("/supplier-profiler/profiling", {
                      //     backUrl: null,
                      //     isContributorView: true,
                      //   });
                      // }
                    }}
                  >
                    <>
                      {sendingEmail ? (
                        <span>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />{" "}
                          Please wait...
                        </span>
                      ) : (
                        "Continue"
                      )}
                    </>
                  </Button>
                </div>
                {/* </Form.Group> */}

                <div
                  className="text-center pt-4 pointer"
                  onClick={() => {
                    if (formState === 1) {
                      setFormState(0);
                    } else {
                      history.push(ROUTE_PATHS.auth.login);
                    }
                  }}
                >
                  Back
                </div>
              </Col>
            </Row>
          </div>
        )}

        {formState === 2 && (
          <div className="p-3">
            <div className="d-flex">
              <img alt="" src="/images/logo-large.svg" className="icon" />
            </div>

            <div className="d-flex align-items-center justify-content-center mt-5">
              <img src="/images/msp/snooze-icon.svg" alt="" />
            </div>
            <div className="f-24 f-700 d-flex align-items-center justify-content-center mt-4">
              Link Expired
            </div>
            <div className="mt-4 f-14 f-darkgrey d-flex align-items-center justify-content-center mx-auto w-25 text-center">
              Your login link has been expired or revoked. Contact sender again
              to review a new login link
            </div>
          </div>
        )}

        <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
          © 2024 HighGround. &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/privacy_policy/",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </u>
          &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/highground-terms-and-conditions/",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </u>
        </div>
      </div>
    </div>
  );
};

export default AnonymousLogin;

import React, { useEffect, useState } from "react";
import "./drilldownModal.scss";
import { IndependentGetAPIData, IndependentPostRequest, getAPIData, postRequest } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import { accessStateValue, viewOnlyAccess } from "../App/App";
import {
  CreateClientComplianceLoader,
} from "../MSPv2/clients/createClientScreens/CyberComplianceForGroupModal";
import SearchInput from "../Common/search-input/search-input.component";
import { BASE_API_PAGE_ENUMS } from "../../constants/apis.constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KPISettingsDropdown from "./kpi-settings-dropdown/kpi-settings.dropdown";
import AttestationRow from "./attestation-row.component";
import ReviewSuggestionsBanner from "./review-suggestions/review-suggestions.banner";
import useIntegrationActions from "../../hooks/actions/integrations.actions";
import { ROUTE_PATHS } from "../../constants/app.constants";

const CyberScoreForGroupModal = ({
  clientId, AccessStateLabel, passedOptionEnum99, showActivePackage = false, /*activePackageName, activePackageId,*/
  showAttestationIcons,
  setShowAttestationIcons,
  isSaas,
  setDisplaySuggestModal,
  suggestUpdatesCount,
  isViewOnly=false
}) => {
  const { reviewSuggestions } = useIntegrationActions();
  const [searchComponent, setSearchComponent] = useState('');
  const { authTokens } = useAuth();
  const history = useHistory();
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const [toolData, setToolData] = useState([]);
  const [cyberScoreData, setCyberScoreData] = useState({});
  const [loading, setLoading] = useState(false)

  const [selectedIntegrationId, setSelectedIntegrationId] = useState();

  const [showMspConnectModal, setShowMspConnectModal] = useState(false);
  const [showReviewBanner, setShowReviewBanner] = useState(false);
  const [activePackageName, setActivePackageName] = useState('');
  const [activePackageId, setActivePackageId] = useState(0);


  useEffect(() => {
    setMainPageLoading(true);
    getCyberScoreData();
    if (showActivePackage) {
      getActivePackage();
    }
  }, []);

  useEffect(() => {
    if (suggestUpdatesCount) {
      getCyberScoreData();
    }
  }, [suggestUpdatesCount]);

  async function getActivePackage() {
    await IndependentGetAPIData(984, authTokens, {
      optionEnum1: clientId
    })
      .then((response) => {
        if (response/*?.mr?.Success*/) {
          setActivePackageName(response?.PackageName);
          setActivePackageId(response?.objId_Scenario);
        }
      })
      .catch((err) => { });
  }

  useEffect(() => {
    let main_wrapper = document.querySelector("#cyber-score-group-wrapperr");
    if (!main_wrapper) return;
    let all_rows = main_wrapper.querySelectorAll(".point");
    for (let i = 0; i < all_rows.length; i++) {
      let panel = all_rows[i].querySelector(".panel");
      if (panel?.style?.maxHeight) {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }, [toolData]);

  /**
   * @param
   *
   * {
   * showUpdateToast: false,
   * showErrorToast:false,
   * keepToolsAtRow
   * }
   */

  function getCyberScoreData(kwargs = {}) {
    if (passedOptionEnum99) {
      IndependentGetAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];

          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            const curTool = toolData.find(item => item?.TechnologyEnum === toolInfo?.TechnologyEnum);
            const hasChilds = toolInfo.Grouping && toolInfo.IsChild === false;
            const childs = hasChilds ? tool_data.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === toolInfo.Grouping) : [];
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              ...(curTool?.selected
                ? { selected: curTool.selected }
                : {
                  selected:
                    toolInfo?.SelectedTool_List?.length > 0 || childs.some(child => child?.SelectedTool_List?.length > 0)
                      ? { id: 1, category: "Yes" }
                      : toolInfo?.ShowNoCapability
                        ? { id: 0, category: "No" }
                        : null
                }),
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
              ScoreAttestationRow: toolInfo.ScoreAttestationRow
            };
          });

          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            // CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        }).finally(() => {
          setLoading(false)
        });
    } else {
      getAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];

          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            const curTool = toolData.find(item => item?.TechnologyEnum === toolInfo?.TechnologyEnum);
            const hasChilds = toolInfo.Grouping && toolInfo.IsChild === false;
            const childs = hasChilds ? tool_data.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === toolInfo.Grouping) : [];
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              ...(curTool?.selected
                ? { selected: curTool.selected }
                : {
                  selected:
                    toolInfo?.SelectedTool_List?.length > 0 || childs.some(child => child?.SelectedTool_List?.length > 0)
                      ? { id: 1, category: "Yes" }
                      : toolInfo?.ShowNoCapability
                        ? { id: 0, category: "No" }
                        : null
                }),
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });

          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            // CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        }).finally(() => {
          setLoading(false)
        });
    }
  }

  // kwargs={
  //   technologyEnum,
  //   IntegrationId,
  //   toolName,
  //   selected,
  //   rowIndex
  // }

  function addNewIntegration(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentPostRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          optionEnum99: 3,
          MonitoredBySOC: kwargs.isManagedBySOC
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve(response);
            } else {
            }
          })
          .catch((err) => {
            getCyberScoreData({ showErrorToast: true });
          });
      } else {
        postRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          MonitoredBySOC: kwargs.isManagedBySOC
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve(response);
            } else {
            }
          })
          .catch((err) => {
            getCyberScoreData({ showErrorToast: true });
          });
      }
    });
  }

  async function disconnectIntegration(_integrationId) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => { });
      } else {
        getAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => { });
      }
    });
  }

  async function renameIntegration(_integrationId, newName) {
    return new Promise((resolve, reject) => {
      IndependentGetAPIData(BASE_API_PAGE_ENUMS.RenameCustomIntegrationName, authTokens, {
        optionEnum1: _integrationId,
        optionStrEnum1: newName,
      })
        .finally(() => resolve())
    });
  }

  async function createIntegrationsMonitoredSOC(_integrationId, isMonitoredBySOC) {
    return new Promise((resolve, reject) => {
      IndependentPostRequest(authTokens, 'CreateIntegrationsMonitoredSOC', {
        ObjId_Customer: clientId,
        ObjId_Integration: _integrationId,
        IsMonitoredBySOC: isMonitoredBySOC,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .finally(() => resolve());
    });
  }

  // kwargs={
  //   technologyEnum,
  //   rowIndex
  // }
  async function removeAllIntegrations(kwargs = {}) {
    if (passedOptionEnum99) {
      await IndependentGetAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {

          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
          setLoading(false)
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    } else {
      await getAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
      })
        .then((response) => {

          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
          setLoading(false)
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    }
  }

  function itemsLoading() {
    let isLoading = toolData?.some((toolRow) => {
      let integrationLoading = toolRow?.IntegrationList?.some(
        (integrationItem) => integrationItem?.loading === true
      );

      return integrationLoading || toolRow?.loading || toolRow?.addingNewTool;
    });

    return isLoading;
  }

  function deleteIntegration(integrationId) {
    return new Promise((resolve, reject) => {
      IndependentGetAPIData(BASE_API_PAGE_ENUMS.DeleteIntegration, authTokens, {
        optionEnum1: integrationId,
        optionEnum2: isSaas ? clientId : 0,
      }).finally(() => {
        resolve()
      })
    })
  }

  // kwargs={
  //   parentID,
  //   rowIndex,
  //   open,
  // }

  // kwargs= {
  //   rowIndex,
  //   technologyEnum
  // }
  function getTools(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            let updated_data = [...toolData];
            updated_data[kwargs.rowIndex].IntegrationList = [
              ...response?.TechnologyTool_List,
            ];
            updated_data[kwargs.rowIndex].loading = false;
            setToolData(updated_data);
            resolve();
          })
          .catch((err) => { });
      } else {
        getAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
        })
          .then((response) => {
            let updated_data = [...toolData];
            updated_data[kwargs.rowIndex].IntegrationList = [
              ...response?.TechnologyTool_List,
            ];
            updated_data[kwargs.rowIndex].loading = false;
            setToolData(updated_data);
            resolve();
          })
          .catch((err) => { });
      }
    });
  }

  useEffect(() => {
    if (!cyberScoreData) return;
    setShowReviewBanner(cyberScoreData?.ShowReviewSuggestion);
  }, [cyberScoreData]);

  return (
    <div
      id="cyber-score-group-wrapperr"
      className="w-100 position-relative"
    >
      {
        loading && <div className="position-absolute" style={{
          top: "0px",
          left: "0px",
          bottom: "0px",
          right: "0px",
          opacity: 0.5,
          background: "#fff",
          zIndex: 300
        }}></div>
      }
      {mainPageLoading ? (
        <CreateClientComplianceLoader />
      ) : (
        <>
          <div className="search-container">
            <SearchInput
              placeholder="Search"
              value={searchComponent}
              onChange={(value) => setSearchComponent(value)}
            />
            {
              (!viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard")) &&
              <KPISettingsDropdown
                showAttestationIcons={showAttestationIcons}
                setShowAttestationIcons={setShowAttestationIcons}
                switchId="showAttestationIcons"
                clientId={clientId}
                reviewSuggestionsClick={() => {
                  setDisplaySuggestModal(true);
                }}
              />
            }
          </div>

          {
            (!viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard")) &&
            <>
              {
                showActivePackage && <div className="mb-2">
                  {
                    activePackageName && activePackageId ? <div className="bg-lightblue radius-4" style={{ padding: '8px 16px' }}>
                      <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                        Some of these components are covered by an active security package.
                      </div>
                      <div
                        className="d-flex align-items-center pointer"
                        onClick={() => {
                          history.push(
                            ROUTE_PATHS.mspv2.clients.securityPackage(clientId), {
                            activePackageId: activePackageId
                          }
                          );
                        }}
                      >
                        <img
                          alt=""
                          height={13}
                          className="mr-2"
                          src="/images/msp/msp-package-icon.svg"
                        />
                        <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
                          {activePackageName}
                        </div>
                      </div>
                    </div> : <div className="d-flex align-items-center bg-lightblue radius-4" style={{ padding: '8px 16px' }}>
                      <img
                        alt=""
                        height={15}
                        className="mr-2"
                        src="/images/navIcons/calculator.svg"
                      />
                      <div className="f-400 f-14 f-black line-height-22 letter-space-2 mr-1">
                        Apply a security package in
                      </div>
                      <div
                        className="f-500 f-14 f-blue line-height-22 letter-space-2 pointer"
                        onClick={() => {
                          history.push(
                            ROUTE_PATHS.mspv2.clients.securityPackage(clientId)
                          );
                        }}
                      >
                        Managed Security
                      </div>
                    </div>
                  }
                </div>
              }
            </>
          }
          {showReviewBanner && !viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") && (
            <div className="mb-2">
              <ReviewSuggestionsBanner 
                onReviewClick={() => {
                  setDisplaySuggestModal(true);
                }}
                onCrossClick={() => {
                  reviewSuggestions({ clientId, reviewClicked: 0 });
                  setShowReviewBanner(false);
                }} 
              />
            </div>
          )}
          <div
            className={`bg-grey radius-8 border-parent   ${itemsLoading() || isViewOnly ? "stop-all-events" : ""
              }`}
            id="stack-wrapper"
          >
            <div className="border-item f-12 f-500 f-grey header-section cyber-score-table-row">
              <div>Component</div>
            </div>
            <div id="cyber-score-parent-rows">
              {toolData?.map((tool, toolIndex) => {
                return (
                  <AttestationRow
                    key={`tool-${tool?.TechnologyEnum}-${toolIndex}`}
                    tool={tool}
                    toolIndex={toolIndex}
                    toolData={toolData}
                    setToolData={setToolData}
                    clientId={clientId}
                    showAttestationIcons={showAttestationIcons}
                    setShowMspConnectModal={setShowMspConnectModal}
                    searchComponent={searchComponent}
                    getTools={getTools}
                    removeAllIntegrations={removeAllIntegrations}
                    addNewIntegration={addNewIntegration}
                    renameIntegration={renameIntegration}
                    createIntegrationsMonitoredSOC={createIntegrationsMonitoredSOC}
                    isSaas={isSaas}
                    setLoading={setLoading}
                    getCyberScoreData={getCyberScoreData}
                    AccessStateLabel={AccessStateLabel}
                    setSelectedIntegrationId={setSelectedIntegrationId}
                    disconnectIntegration={disconnectIntegration}
                    deleteIntegration={deleteIntegration}
                    rowParentId="cyber-score-parent-rows"
                    displayTopBorder={toolIndex !== 0}
                    isViewOnly={isViewOnly}
                  />
                )
              })}
            </div>

            <div className="border-item f-12 f-500 f-grey p-2 text-align-end border-item-top">
              <div className="p-2">
                <span className="f-grey">
                  Your {cyberScoreData?.ScoreText} is
                </span>
                <span className="f-black f-500 mx-2">
                  {cyberScoreData?.ScoreValue}{" "}
                </span>
                <span
                  className=""
                  style={{
                    color: cyberScoreData?.ScoreRatingColour,
                  }}
                >
                  {cyberScoreData?.ScoreRating}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CyberScoreForGroupModal;

import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ImportedClientDetailModal from "./ImportedClientDetailModal";
import { GreyLoader } from "../../Theme/APILoaders";
import MspMappingDropdownSearchInput from "./mspMappingScreens/mspMappingComponents/mspMappingDropdownSearchInput";
import ContentLoader from "react-content-loader";
import DotsLoader from "../../../Utils/dotsLoader";
import { parseIntegrationName } from "./mspMappingScreens/MSPMappings";
import { HGHighlightText, viewOnlyAccess } from "../../App/App";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { getPageDataApi, postData } from "../../../Utils/commonApi";
import OAuth2Login from "react-simple-oauth2-login/dist/OAuth2Login";
import { useAuth } from "../../context/Auth";
import { HGPopUp } from "../../AttackSurface/KPIpopup/KPIpopup";
import ScrollableDivWithShadow from "../MspAttackSurface.js/ScrollableDivWithShadow";
import { AvailableClientStateForAuth } from "../../../constants/map-clients";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useApiActions from "../../../hooks/api-actions.hook";
import { VisibilityType } from "../../../constants/filter.constants";
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import SortingHeaderComponent from "../../SearchFilterComponent/SortingHeaderComponent";
import CrossIcon from "../../icons/cross.icon";

const MapClients = ({
  getClientsListData,
  clientPageData,
  setClientPageData,
  clientsMappingData,
  getClientsMappingListData,
  updateClientData,
  updateImportStatus,
  applyToAllFunc,
}) => {
  const { authTokens } = useAuth()
  const [loading, setLoading] = useState(false);
  const [fetchingNewClients, setFetchingNewClients] = useState(false);
  const [clientSearchString, setClientSearchString] = useState("");
  const [automaticallyImportStatus, setAutomaticallyImportStatus] =
    useState(true);
  const [clientGroupsToShow, setClientGroupsToShow] = useState(5);
  const location = useLocation();
  const state = location?.state;
  const isPSA = state?.isPSA;


  useEffect(() => {
    // if (clientPageData?.ObjectList?.length > 0) {
    // } else {
    setLoading(true);
    Promise.all([
      getClientsListData({
        NextPageNo: 1,
        searchString: clientSearchString,
      }),
      getClientsMappingListData({
        NextPageNo: 1,
        searchString: "",
      }),
    ])
      .then((response) => {
        setLoading(false);

        setAutomaticallyImportStatus(response?.[0]?.AutoImport);
      })
      .catch((err) => {
        setLoading(false);
      });
    // }
  }, []);

  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (clientPageData?.NextPage === false) return;
        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", getMoreClients);

        setFetchingNewClients(true);
        getClientsListData({
          NextPageNo: null,
          searchString: clientSearchString,
        }).then(() => {
          setFetchingNewClients(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.addEventListener("scroll", getMoreClients);

    return () => {
      element.removeEventListener("scroll", getMoreClients);
    };
  }, [clientPageData]);

  const [timeoutId, setTimeoutId] = useState();
  const [clientDataLoading, setClientDataLoading] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setClientSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setClientDataLoading(true);
      getClientsListData({
        NextPageNo: 1,
        searchString: value,
      }).then(() => {
        setClientDataLoading(false);
        // highlightText({
        //   searchText:value,
        //   wrapperClass: CLIENT_LIST_HIGHLIGHT_CLASS ,
        //   highlightClass:"list-highlight-class"
        // })
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };

  function getSingleClientObjectData(kwargs = {}) {
    return new Promise((resolve, reject) => {
      getPageDataApi("GetLightHouseTenentAuthDetails", authTokens, {
        objId_client: kwargs?.objId_client,
        integrationId: kwargs?.integrationId
      }).then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err);
      });
    });
  }
  function deAuthoriseOAuthClient(kwargs = {}) {
    return new Promise((resolve, reject) => {
      getPageDataApi("DeAuthLightHouseTenent", authTokens, {
        authRecordId: kwargs?.authRecordId,
        integrationId: kwargs?.integrationId
      }).then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err);
      });
    });
  }


  async function updateClientGroupMapping(recordId, value, integrationId) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateClientGroupMapping", {
        SelectedValue: value ?? "",
        RecordId: recordId,
        ObjId_Integration: integrationId
      })
        .then((response) => {
          if (response?.Success) {
            getClientsListData({
              NextPageNo: 1,
              searchString: clientSearchString,
            })
            CallToast("Updated successfully!", false, false, "v2style");
          } else {
            reject("error");
            CallToast("Something went wrong!", false, false, "v2style");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");
  const [searchInput, setSearchInput] = useState(false);
  const SearchInput = useRef();


  const filterDataOptions = [
    {
      id: 0,
      title: 'Import Status',
      data: clientPageData?.ImportStatusList?.length > 0 && [
        { id: null, type: "All" },
        ...(clientPageData?.ImportStatusList.map((item) => ({ id: item, type: item }))),
      ]
    },
    {
      id: 1,
      title: 'Company Type',
      data: clientPageData?.ClientGroups && [
        // { id: 0, ClientGroupName: 'Show all' },
        ...clientPageData?.ClientGroups?.filter((item) => item?.showInFilters)
      ]
    }
  ]
  const [selectedFilterList, setSelectedFilterList] = useState([]);


  return (
    <>
      {loading ? (
        <MapClientsLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className={`map-clients-screen`}
          style={{
            pointerEvents: clientDataLoading ? "none" : "all",
          }}
        >
          <div className="d-flex align-items-center pt-4">
            <img
              alt=""
              width={42}
              height={42}
              src={`/images/attack-surface/integrations/${parseIntegrationName(
                "importedIntegrtionId"
              )}.webp`}
              className="mr-3 radius-4"
              style={{
                border: "1px solid #ebecf180",
              }}
            />
            <div>
              <p className="f-black f-600 f-15 m-0">Import & Map Companies</p>
              <p className="f-grey f-14">
                Categorise, Import and Map Companies from your {" "}
                {parseIntegrationName("importedIntegrtionName")}
              </p>
            </div>
          </div>

          <div className="mt-3 mb-2 f-500">{/* Import */}</div>
          <div className="border-parent bg-grey radius-8">
            <div className="d-flex align-items-center justify-content-between border-item  p-3">
              <div className="f-black f-500">
                Automatically import new companies
              </div>
              <div className="">
                <Form.Check
                  style={{
                    scale: "1.5",
                  }}
                  type="switch"
                  id={`switchnitification`}
                  checked={automaticallyImportStatus}
                  className={`m-0`}
                  onClick={() => { }}
                  disabled={viewOnly}
                  onChange={(e) => {
                    updateImportStatus(0, Number(e.target.checked));
                    setAutomaticallyImportStatus(e.target.checked);
                  }}
                />
              </div>
            </div>

            {clientPageData?.ClientGroups?.length > 0 &&
              clientPageData?.ClientGroups?.[0]?.length > 0 &&
              automaticallyImportStatus && (
                <div className="border-item p-3">
                  <div className="f-12 f-500 f-grey mb-2">Company Types</div>
                  {clientPageData?.ClientGroups?.slice(
                    0,
                    clientGroupsToShow
                  ).map((item, index) => {
                    return (
                      <div
                        className="d-flex align-items-center mb-2"
                        key={index}
                      >
                        <div className="mb-4">
                          <Form.Group
                            controlId={"check" + index}
                            className="round-checkbox blue-checkbox checkboxstyle"
                          >
                            <Form.Check
                              type="checkbox"
                              name={"check" + index}
                              label=""
                              checked={clientPageData?.ImportClientList?.includes(
                                item
                              )}
                              onChange={(e) => {
                                try {
                                  let new_list = [
                                    ...clientPageData?.ImportClientList,
                                  ];
                                  if (new_list.includes(item)) {
                                    new_list = new_list.filter(
                                      (item2) => item2 !== item
                                    );
                                    updateImportStatus(10, item, {
                                      SetValue: 0,
                                    });
                                  } else {
                                    new_list.push(item);
                                    updateImportStatus(10, item, {
                                      SetValue: 1,
                                    });
                                  }
                                  setClientPageData({
                                    ...clientPageData,
                                    ImportClientList: new_list,
                                  });
                                } catch (error) {
                                  console.log("error", error);
                                }
                              }}
                            // defaultChecked={elmt.StripeId}
                            />
                          </Form.Group>
                        </div>
                        <div className="f-500 ml-3">{item} </div>
                      </div>
                    );
                  })}

                  {clientPageData?.ClientGroups?.length > 5 && (
                    <>
                      {clientGroupsToShow === 5 ? (
                        <div
                          className="f-12 f-500 f-blue mb- pointer"
                          onClick={() => {
                            setClientGroupsToShow(
                              clientPageData?.ClientGroups?.length + 2
                            );
                          }}
                        >
                          Show all
                        </div>
                      ) : (
                        <div
                          className="f-12 f-500 f-blue mb- pointer"
                          onClick={() => {
                            setClientGroupsToShow(5);
                          }}
                        >
                          Show less
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
          </div>

          {
            isPSA &&
            <div className="my-3">
              <div className="f-400 f-14 f-black line-height-22 letter-space-2">Import & Map Company Types</div>
              <div className="grey-container mt-2">
                <div className="f-500 f-12 greyText2 line-height-18 letter-space-2">Company type</div>
                {
                  clientPageData?.ClientGroups?.map((clientItem) => {
                    const isValueSelected = (clientItem?.ClientGroupType != null && clientItem?.ClientGroupType != "") ? true : false;
                    const toggleId = `ct-toggle-${clientItem?.RecordId}`;
                    const onCrossClick = () => {
                      if (viewOnly) return;
                      const updated_data = clientPageData?.ClientGroups.map(item => {
                        if (item.RecordId === clientItem?.RecordId) {
                          return {
                            ...item,
                            ClientGroupType: null
                          };
                        }
                        return item;
                      });
                      setClientPageData({
                        ...clientPageData,
                        ClientGroups: updated_data
                      })
                      updateClientGroupMapping(clientItem?.RecordId, undefined, clientItem?.IntegrationId)
                    }
                    return (
                      <div className="d-flex justify-content-between my-1">
                        <div className="d-flex justify-content-between align-items-center w-50 gap-8">
                          <div className="d-flex justify-content-between align-items-center">
                            {/* <Form.Group className="blue-checkbox mx-2"> */}
                            <Form.Group className="blue-checkbox pointer-checkbox mx-2">
                              <Form.Check
                                type="checkbox"
                                name="checkbox"
                                checked={isValueSelected}
                                onChange={(e) => {
                                  if (!isValueSelected) {
                                    const toggle = document.getElementById(toggleId);
                                    toggle.click();
                                  } else {
                                    onCrossClick();
                                  }
                                }}
                              />
                            </Form.Group>
                            <div className="f-400 f-14 f-black line-height-22 letter-space-2">{clientItem?.ClientGroupName}</div>
                          </div>
                          <img
                            alt=""
                            width={11}
                            height={11}
                            className="mr-2"
                            src='/images/attack-surface/right-arrow.svg'
                          />
                        </div>

                        {
                          (clientItem?.ClientGroupType != null && clientItem?.ClientGroupType != "") ?
                            <div className="d-flex justify-content-between align-items-center w-50">
                              <div className="f-400 f-14 f-black line-height-22 letter-space-2">{clientItem?.ClientGroupType}</div>
                              <img
                                alt=""
                                width={11}
                                height={11}
                                className="mr-2 pointer m-1"
                                src='/images/attack-surface/small-cross.svg'
                                onClick={onCrossClick}
                              />
                            </div> : <div className="d-flex justify-content-between align-items-center w-50">
                              <Dropdown className="transparennt-wrapper-dropdown-menu w-100">
                                <Dropdown.Toggle id={toggleId} disabled={viewOnly} className="d-flex align-items-center justify-content-between w-100">
                                  <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2">Select company type</div>
                                  <img
                                    alt=""
                                    width={11}
                                    height={11}
                                    className="mr-2"
                                    src='/images/chevron-down-light.svg'
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-0">
                                  {
                                    clientPageData?.ClientGroupOptions?.map((optionItem) => {
                                      return (
                                        <div
                                          className="f-400 f-14 f-black line-height-22 letter-space-2 hg-border-bottom bg-hover-dark-grey px-2 py-1 pointer"
                                          onClick={(e) => {
                                            const updated_data = clientPageData?.ClientGroups.map(item => {
                                              if (item.RecordId === clientItem?.RecordId) {
                                                return {
                                                  ...item,
                                                  ClientGroupType: optionItem
                                                };
                                              }
                                              return item;
                                            });
                                            setClientPageData({
                                              ...clientPageData,
                                              ClientGroups: updated_data
                                            })
                                            updateClientGroupMapping(clientItem?.RecordId, optionItem, clientItem?.IntegrationId)

                                            e.target
                                              .closest(".transparennt-wrapper-dropdown-menu")
                                              .click();
                                          }}
                                        >
                                          {optionItem}
                                        </div>
                                      )
                                    })
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }


          <SearchFilterComponent
            leftSide={(
              <div className="f-black f-16 f-600 ">
                <span className="d-none d-sm-block">Map Clients & Prospects</span>
              </div>
            )}
            passedMainStyles={'my-3'}
            searchValue={clientSearchString}
            searchOnChange={(e) => {
              setSearchInput(true);
              handleInputChange(e);
            }}
            searchOnCross={() => {
              handleInputChange({ target: { value: "" } });
            }}
            passedRef={SearchInput}
            setRef={() => {
              SearchInput.current.focus()
            }}
            visibilityType={VisibilityType.TABLE}
            showLastButton={false}
            onSortClick={() => {
              setClientDataLoading(true);
              getClientsListData({
                NextPageNo: 1,
                searchString: clientSearchString,
                sortOrder: window.localStorage.getItem("clientSortOrder") == 0 ? 1 : 0,
              }).then(() => {
                setClientDataLoading(false);
              });
              window.localStorage.setItem(
                "clientSortOrder",
                JSON.stringify(window.localStorage.getItem("clientSortOrder") == 0 ? 1 : 0)
              );
            }}
            passedFilterList={filterDataOptions}
            childFilterItem={<div>
              {
                filterDataOptions?.map((filterItem) => {
                  return (
                    <div className="d-flex align-items-center bg-hover-grey pointer"
                      onClick={(e) => {
                        setSelectedFilterList(prevList => {
                          const newList = [...prevList];
                          const index = newList.findIndex(item => item?.id === filterItem?.id);
                          if (index !== -1) {
                            newList.splice(index, 1);
                          } else {
                            newList.push(filterItem);
                          }
                          console.log(newList);
                          return newList;
                        });

                        e.target
                          .closest(".transparennt-wrapper-dropdown")
                          .click();
                      }}
                    >
                      {
                        selectedFilterList?.some(option => option?.id === filterItem?.id) ?
                          <img
                            alt=""
                            src="/images/msp/dropdown-grey-tick.svg"
                          /> : <div style={{
                            width: '12px'
                          }}></div>
                      }
                      <div className="f-400 f-14 f-grey p-1 ml-2">
                        {filterItem?.title}
                      </div>
                    </div>
                  )
                })
              }
            </div>}
            childAppliedFilterItem={
              <div className="d-flex align-items-center">
                {
                  selectedFilterList?.map((filterItem) => {
                    const selectedFilterGroup = filterItem?.data?.filter(item => (filterItem?.id == 0 ?
                      JSON.parse(localStorage.getItem("clientType")) == item?.id
                      :
                      JSON.parse(localStorage.getItem("clientFilterGroup"))?.includes(item?.ClientGroupName?.replace(/ /g, "_"))
                    ));
                    const selectedFilterStrArr = selectedFilterGroup?.map(item => (filterItem?.id == 0 ? item?.type : item?.ClientGroupName));
                    const hasSelectedItems = selectedFilterGroup?.length > 0;
                    return (
                      <div className={`d-flex align-items-center py-1 px-2 mr-2 mb-3 rounded-full applied-filter-item ${hasSelectedItems ? 'has-value ' : ''}`}>
                        <Dropdown className="transparennt-wrapper-dropdown">
                          <Dropdown.Toggle className="d-flex align-items-center">
                            <div
                              className="f-500 f-12 text pointer"
                            >
                              {filterItem?.title}
                            </div>
                            {hasSelectedItems && (
                              <div className={`f-500 f-12 text rounded-full pointer`}>
                                : {selectedFilterStrArr?.join(', ')}
                              </div>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              filterItem?.data?.map((filterDataOptionItem) => {
                                return (
                                  <div
                                    className="p-1 d-flex align-items-center bg-hover-grey pointer"
                                    style={{
                                      width: '200px'
                                    }}
                                    onClick={(e) => {
                                      if (filterItem?.id == 0) {
                                        if (filterDataOptionItem?.id) {
                                          window.localStorage.setItem(
                                            "clientType",
                                            JSON.stringify(filterDataOptionItem?.id)
                                          );

                                          setClientDataLoading(true);
                                          getClientsListData({
                                            NextPageNo: 1,
                                            searchString: clientSearchString,
                                            FilterState: filterDataOptionItem?.id,
                                          }).then(() => {
                                            setClientDataLoading(false);
                                          });
                                        } else {
                                          window.localStorage.removeItem("clientType")
                                          setClientDataLoading(true);
                                          getClientsListData({
                                            NextPageNo: 1,
                                            searchString: clientSearchString,
                                            FilterState: "",
                                          }).then(() => {
                                            setClientDataLoading(false);
                                          });
                                        }
                                      } else {
                                        if (filterDataOptionItem?.id == 0 ? [] : filterDataOptionItem?.ClientGroupName?.replace(/ /g, "_")) {
                                          let clientFilterGroups = JSON.parse(localStorage.getItem("clientFilterGroup")) || [];
                                          if (typeof clientFilterGroups === "string") {
                                            clientFilterGroups = [clientFilterGroups];
                                          }
                                          const groupName = filterDataOptionItem?.ClientGroupName?.replace(/ /g, "_");

                                          if (clientFilterGroups.includes(groupName)) {
                                            clientFilterGroups = clientFilterGroups.filter(name => name !== groupName);
                                          } else {
                                            clientFilterGroups.push(groupName);
                                          }

                                          window.localStorage.setItem("clientFilterGroup", JSON.stringify(clientFilterGroups));

                                          setClientDataLoading(true);
                                          getClientsListData({
                                            NextPageNo: 1,
                                            searchString: clientSearchString,
                                            filters: clientFilterGroups.join(','),
                                          }).then(() => {
                                            setClientDataLoading(false);
                                          });
                                        } else {
                                          window.localStorage.removeItem("clientFilterGroup")
                                          setClientDataLoading(true);
                                          getClientsListData({
                                            NextPageNo: 1,
                                            searchString: clientSearchString,
                                            filters: "",
                                          }).then(() => {
                                            setClientDataLoading(false);
                                          });
                                        }
                                      }

                                      e.target
                                        .closest(".transparennt-wrapper-dropdown")
                                        .click();
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src="/images/msp/dropdown-grey-tick.svg"
                                      className="mx-1"
                                      style={{
                                        visibility: `${(filterItem?.id == 0 ?
                                          JSON.parse(localStorage.getItem("clientType")) == filterDataOptionItem?.id
                                          :
                                          JSON.parse(localStorage.getItem("clientFilterGroup"))?.includes(filterDataOptionItem?.ClientGroupName?.replace(/ /g, "_")))
                                          ? "visible"
                                          : "hidden"
                                          }`,
                                      }}
                                    />
                                    <div className="f-400 f-14 f-grey">

                                      {filterItem?.id == 0 ? filterDataOptionItem?.type : filterDataOptionItem?.ClientGroupName}
                                    </div>
                                  </div>
                                )
                              })
                            }

                          </Dropdown.Menu>
                        </Dropdown>
                        <CrossIcon
                          className="pointer ml-2 cross-icon"
                          width={9}
                          height={9}
                          onClick={(e) => {
                            if (filterItem?.id == 0) {
                              window.localStorage.removeItem("clientType")
                              setClientDataLoading(true);
                              getClientsListData({
                                NextPageNo: 1,
                                searchString: clientSearchString,
                                FilterState: "",
                              }).then(() => {
                                setClientDataLoading(false);
                              });
                            } else {
                              window.localStorage.removeItem("clientFilterGroup")
                              setClientDataLoading(true);
                              getClientsListData({
                                NextPageNo: 1,
                                searchString: clientSearchString,
                                filters: "",
                              }).then(() => {
                                setClientDataLoading(false);
                              });
                            }

                            let filterUpd = selectedFilterList?.filter(item => item?.id !== filterItem?.id)
                            setSelectedFilterList(filterUpd);
                          }}
                        />
                      </div>
                    )
                  })
                }
              </div>
            }
            selectedFilterList={selectedFilterList}
            setSelectedFilterList={setSelectedFilterList}
            onResetClick={() => {
              window.localStorage.removeItem("clientType")
              window.localStorage.removeItem("clientFilterGroup")
              setClientDataLoading(true);
              getClientsListData({
                NextPageNo: 1,
                searchString: clientSearchString,
                FilterState: "",
                filters: ""
              }).then(() => {
                setClientDataLoading(false);
              });
              // setClientDataLoading(true);
              // getClientsListData({
              //   NextPageNo: 1,
              //   searchString: clientSearchString,
              //   filters: "",
              // }).then(() => {
              //   setClientDataLoading(false);
              // });

              setSelectedFilterList([]);
            }}
          />


          <div className="border-parent bg-grey radius-8 position-relative">
            {clientDataLoading && (
              <div
                className="position-absolute w-100 h-100 bg-white"
                style={{
                  opacity: "0.6",
                  zIndex: "2",
                }}
              ></div>
            )}
            <MapClientsBlock
              getSingleClientObjectData={getSingleClientObjectData}
              deAuthoriseOAuthClient={deAuthoriseOAuthClient}
              clientPageData={clientPageData}
              setClientPageData={setClientPageData}
              clientsMappingData={clientsMappingData}
              getClientsMappingListData={getClientsMappingListData}
              clientSearchString={clientSearchString}
              updateClientData={updateClientData}
              applyToAllFunc={applyToAllFunc}
              clientDataLoading={clientDataLoading}
              filterDataOptions={filterDataOptions}
              selectedFilterList={selectedFilterList}
              setSelectedFilterList={setSelectedFilterList}
              setClientDataLoading={setClientDataLoading}
              refreshData={() => {
                getClientsListData({
                  NextPageNo: 1,
                  searchString: clientSearchString,
                }).then(() => {
                  setClientDataLoading(false);
                  // highlightText({
                  //   searchText:value,
                  //   wrapperClass: CLIENT_LIST_HIGHLIGHT_CLASS ,
                  //   highlightClass:"list-highlight-class"
                  // })
                });
              }}
              getClientsListData={getClientsListData}
            />
          </div>
          {fetchingNewClients && (
            <div className="d-flex align-items-center f-darkgrey">
              <GreyLoader width={12} />
              Loading...
            </div>
          )}

          <div className="p-3"></div>
        </div>
      )}
    </>
  );
};

export default MapClients;

export const MapClientsBlock = ({
  getSingleClientObjectData,
  deAuthoriseOAuthClient,
  clientPageData,
  setClientPageData,
  clientsMappingData,
  getClientsMappingListData,
  clientSearchString,
  updateClientData,
  clientDataLoading,
  filterDataOptions,
  selectedFilterList,
  setSelectedFilterList = () => { },
  applyToAllFunc = () => { },
  setClientDataLoading = () => { },
  refreshData = () => { },
  getClientsListData = () => { },
}) => {
  const [showMappedClientDetailModal, setShowMappedClientDetailModal] =
    useState(false);

  const [clientDetails, setClientDetails] = useState([]);
  const [applyToAllData, setApplyToAllData] = useState({
    state: null,
  });
  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");
  return (
    <>
      <ImportedClientDetailModal
        show={showMappedClientDetailModal}
        hideModal={() => setShowMappedClientDetailModal(false)}
        data={clientDetails}
        parentID={"mapped-client-inegtration-modal-parent"}
      />
      <div className="border-item d-flex align-items-center justify-content-between">
        <div className="f-12 f-500 f-grey py-2 pl-4 pr-5 w-100 d-flex align-items-center justify-content-between">
          {/* <div> {parseIntegrationName("importedIntegrtionName")}</div> */}

          <SortingHeaderComponent
            headerName={parseIntegrationName("importedIntegrtionName")}
            onSortClick={() => {
              setClientDataLoading(true);
              getClientsListData({
                NextPageNo: 1,
                searchString: clientSearchString,
                sortOrder: window.localStorage.getItem("clientSortOrder") == 0 ? 1 : 0,
              }).then(() => {
                setClientDataLoading(false);
              });
              window.localStorage.setItem(
                "clientSortOrder",
                JSON.stringify(window.localStorage.getItem("clientSortOrder") == 0 ? 1 : 0)
              );
            }}
            sorting={clientDataLoading}
            sortOrder={(Number(window.localStorage.getItem("clientSortOrder")))}
            showHideButton={false}
            filterList={filterDataOptions}
            childFilterItem={<div>
              {
                filterDataOptions?.map((filterItem) => {
                  return (
                    <div className="d-flex align-items-center bg-hover-grey pointer"
                      onClick={(e) => {
                        setSelectedFilterList(prevList => {
                          const newList = [...prevList];
                          const index = newList.findIndex(item => item?.id === filterItem?.id);
                          if (index !== -1) {
                            newList.splice(index, 1);
                          } else {
                            newList.push(filterItem);
                          }
                          console.log(newList);
                          return newList;
                        });

                        e.target
                          .closest(".transparennt-wrapper-dropdown")
                          .click();
                      }}
                    >
                      {
                        selectedFilterList?.some(option => option?.id === filterItem?.id) ?
                          <img
                            alt=""
                            src="/images/msp/dropdown-grey-tick.svg"
                          /> : <div style={{
                            width: '12px'
                          }}></div>
                      }
                      <div className="f-400 f-14 f-grey p-1 ml-2">
                        {filterItem?.title}
                      </div>
                    </div>
                  )
                })
              }
            </div>}
          />
          <div> {clientPageData?.TotalCount}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">HighGround</div>
      </div>

      {clientPageData?.ObjectList?.length > 0 && (
        <div className="border-item d-flex align-items-center justify-content-between clients-wrapper">
          <div className="d-flex align-items-center justify-content-between integration-block pl-4 py-3" style={{ paddingRight: '1px' }}>
            <div className="d-flex align-items-center justify-content-between hover-on-show-parent w-100 position-relative">
              <div className={`d-flex align-items-center f-500 f-black  `}>
                {/* <img
                  src="/images/msp/integrations/msp-integration-user-icon.svg"
                  alt=""
                  className="mr-2"
                /> */}
                <div className={`ml-2 f-grey f-500`}>
                  Apply to all {clientPageData?.TotalCount} records
                </div>
              </div>

              <div></div>
            </div>

            <div
              className="mr-2 bg-grey rounded-full d-flex align-items-center justify-content-center apply-all-filter-notification position-relative"
              data-count={clientPageData?.TotalCount}
              style={{
                background: "#EBEDF1",
                width: "24px",
                height: "24px",
              }}
            >
              <img
                src="/images/msp/mapping-arrow-down.svg"
                alt=""
                className=" pointer"
              />
            </div>
          </div>
          <div className="highground-block pl-1">
            <ApplyToAllDropdown
              detail={applyToAllData}
              onStateChange={(newState, mappingData) => {
                setApplyToAllData({
                  state: newState,
                });
                let importStatus = localStorage.getItem("clientType");
                if (importStatus) {
                  importStatus = JSON.parse(importStatus);
                  if (typeof importStatus !== "string") {
                    importStatus = null
                  }
                }
                let clientType = localStorage.getItem("clientFilterGroup");
                if (clientType) {
                  clientType = JSON.parse(clientType);
                  if (typeof clientType !== "string") {
                    clientType = null
                  }
                }
                const ImportStateList = [
                  ...(importStatus ? [importStatus] : []),
                ]
                const ClientGroupType = [
                  ...(clientType ? [clientType] : []),
                ]
                const FilterList = {
                  ImportStateList,
                  ClientGroupType,
                  SearchString: clientSearchString,
                  StateToMap: newState
                }
                setClientDataLoading(true);
                applyToAllFunc({
                  PSAObjectEnum: 0,
                  StateEnum: newState === "Ignored" ? 0 : 2,
                  FilterList,
                  SearchStr: clientSearchString,
                }).then(() => {
                  refreshData();
                });
              }}
            />
          </div>
        </div>
      )}

      {/* Firts row */}
      {clientPageData?.ObjectList
        // ?.filter((item) =>   true
        // item?.name?.toLowerCase().includes(clientSearchString?.toLowerCase())
        // )
        // ?.filter((item) => {
        //   if (clientGroupFilter.length === 0) return true;
        //   else {
        //     const lowerCaseClientGroupFilter = clientGroupFilter.map((group) =>
        //       group.toLowerCase()
        //     );
        //     const itemGroups = item?.ClientGroupName?.split(",").map((group) =>
        //       group.trim().toLowerCase()
        //     );
        //     return itemGroups?.some((group) =>
        //       lowerCaseClientGroupFilter.includes(group)
        //     );
        //   }
        // })
        ?.map((client, clientIndex) => {
          return (
            <div className="border-item d-flex align-items-center justify-content-between clients-wrapper">
              <div className="d-flex align-items-center justify-content-between integration-block pl-4 py-3 pr-0">
                <div className="d-flex align-items-center justify-content-between hover-on-show-parent w-100 position-relative">
                  {client?.state === "Not_Selected" && (
                    <span className="blue-dot" />
                  )}

                  <div
                    className={`d-flex align-items-center   `}

                  >
                    <img
                      src="/images/msp/integrations/msp-integration-user-icon.svg"
                      alt=""
                      className="mr-2"
                      width={16}
                      height={16}
                    />
                    <div className="f-500 f-black break-word" >
                      <div title={client?.name}>  <span
                        dangerouslySetInnerHTML={{
                          __html: HGHighlightText(
                            TrimString(client?.name, 25),
                            clientSearchString
                          ),
                        }}
                      /></div>
                      {client?.tenentId && client?.state === "Imported" &&
                        <>

                          {client?.Authenticated === true ? <div>
                            <HGPopUp
                              darkMode
                              maxWidth={"650px"}
                              content={<div className="p-2 ">
                                <div className="mb-1 f-darkgrey">
                                  Microsoft Tenant ID

                                </div>
                                <div className="f-white">
                                  {client?.tenentId}

                                </div>
                              </div>}
                            >
                              <div className="d-flex align-items-center f-12 f-darkgrey">
                                {TrimString(client?.tenentId, 20)}
                              </div>
                            </HGPopUp>
                          </div> :

                            <div className="f-12 f-darkgrey">
                              Not authorised in Microsoft
                            </div>

                          }
                        </>
                      }
                    </div>


                  </div>




                  {(client?.tenentId && AvailableClientStateForAuth.includes(client?.state)) ? (
                    <div className="mr-2">
                      {client?.Authenticated === false && <OAuthIntegrationForMapClients
                        key={clientIndex}
                        objId_Customer={client?.objId_Customer}
                        integrationId={"7242957"}
                        refreshData={() => { }}
                        hideModal={() => { }}
                        OAuthEnabled={{
                          7242957: {
                            AUTH_URL:
                              clientPageData?.oAuth2_Params?.AuthURL?.replace("common", client?.tenentId),
                            TOKEN_URL:
                              clientPageData?.oAuth2_Params?.TokenURL,
                            SCOPE:
                              clientPageData?.oAuth2_Params?.Scope,


                            CLIENT_ID: clientPageData?.oAuth2_Params?.ClientId,
                            CLIENT_SECRET:
                              clientPageData?.oAuth2_Params?.Secret,
                            extraParams_: { ...{} },
                          },
                        }}

                        onSuccess={() => {
                          setClientDataLoading(true)
                          getSingleClientObjectData({
                            integrationId: 7242957,
                            objId_client: client?.id
                          }).then((response) => {
                            let updated_data = { ...clientPageData }
                            updated_data.ObjectList[clientIndex] = response.detail
                            setClientPageData(updated_data)
                            setClientDataLoading(false)
                          })
                        }}
                      />}
                      {client?.Authenticated === true &&
                        <>
                          <div className="f-grey f-12 f-500 pointer"
                            onClick={() => {
                              setClientDataLoading(true)
                              deAuthoriseOAuthClient({
                                integrationId: 7242957,
                                authRecordId: client?.AuthRecordId
                              }).then(() => {
                                getSingleClientObjectData({
                                  integrationId: 7242957,
                                  objId_client: client?.id
                                }).then((response) => {
                                  let updated_data = { ...clientPageData }
                                  updated_data.ObjectList[clientIndex] = response.detail
                                  setClientPageData(updated_data)
                                  setClientDataLoading(false)
                                })
                              })
                            }}

                          >
                            Deauthorise
                          </div>
                          <div>
                            <HGPopUp
                              darkMode
                              maxWidth={"350px"}
                              content={<div className="p-2 f-white">
                                <div className="mb-1">
                                  Last synced: {client?.LastSyncDate}

                                </div>
                                <div>
                                  Authorised: {client?.AuthenticationDate}

                                </div>
                              </div>}
                            >
                              <div className="d-flex align-items-center f-12 f-darkgrey">
                                <img
                                  src="/images/msp/syncing-icon.svg"
                                  alt=""
                                  className=" mr-1"
                                  width={10}
                                  height={10}
                                />
                                {client?.LastSyncDate}
                              </div>
                            </HGPopUp>
                          </div>

                        </>
                      }

                    </div>
                  ) : (
                    <div
                      className="f-12 f-darkgrey hover-on-show-child mr-2 pointer"
                      onClick={() => {
                        setClientDetails(client.details);
                        setShowMappedClientDetailModal(true);
                      }}
                    >
                      Details
                    </div>
                  )}
                </div>

                <div>
                  {client?.state === "Ignored" && (
                    <img
                      src="/images/msp/integrations/map-client-cancel-icon.svg"
                      alt=""
                      className="mr-2 pointer"
                      onClick={() => {
                        if (viewOnly) return;
                        updateClientData([
                          {
                            id: client?.id,
                            state: "Provisional_Import",
                            parentObjId: 0,
                            ImportName: "",
                          },
                        ]);
                        let client_page_data = { ...clientPageData };

                        let _clientIndex =
                          client_page_data?.ObjectList?.findIndex(
                            (item) => item?.id === client?.id
                          );

                        client_page_data.ObjectList[_clientIndex].state =
                          "Provisional_Import";
                        client_page_data.ObjectList[
                          _clientIndex
                        ].parentObjId = 0;
                        client_page_data.ObjectList[_clientIndex].ImportName =
                          "";
                        setClientPageData(client_page_data);
                      }}
                    />
                  )}
                  {client?.state === "Imported" && (
                    <img
                      // src="/images/msp/right-blue-mark.svg"
                      src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {client?.state === "Auto_Imported" && (
                    <img
                      src="/images/msp/right-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {client?.state === "Mapped" && (
                    <img
                      src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {client?.state === "Deleted" && (
                    <img
                      src="/images/msp/red-cross-circle.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {client?.state === "Not_Selected" && (
                    <img
                      src="/images/msp/choose-action-icon.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {client?.state === "Provisional_Import" && (
                    <img
                      src="/images/msp/right-blue-mark.svg"
                      // src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                </div>
              </div>
              <div className="highground-block position-relative">
                {
                  (client?.tenentId && client?.state === "Imported" && client?.Authenticated === false) && <div className="position-absolute w-100 h-100"

                    style={{
                      zIndex: "1000000000",
                      cursor: "not-allowed"
                    }}


                  ></div>
                }
                <MapClientsDropdown
                  clientSearchString={clientSearchString}
                  clientDetail={client}
                  parentObjId={client?.parentObjId}
                  onStateChange={(newState, mappingData) => {
                    updateClientData([
                      {
                        id: client?.id,
                        state: newState,
                        parentObjId: mappingData?.id ?? 0,
                        ImportName: mappingData?.ImportName,
                      },
                    ]);

                    let client_page_data = { ...clientPageData };
                    // let client_page_data = { ...clientPageData?.ObjectList?.filter((item) =>
                    //   item?.name?.toLowerCase().includes(clientSearchString?.toLowerCase())
                    // )
                    //   ?.filter((item) => {
                    //     if (clientGroupFilter.length === 0) return true;
                    //     else return clientGroupFilter.includes(item?.ClientGroupName);
                    //   }) };

                    let _clientIndex = client_page_data?.ObjectList?.findIndex(
                      (item) => item?.id === client?.id
                    );

                    client_page_data.ObjectList[_clientIndex].state = newState;
                    if (newState === "Ignored") {
                      client_page_data.ObjectList[_clientIndex].parentObjId = 0;
                    } else if (newState === "Provisional_Import") {
                      client_page_data.ObjectList[_clientIndex].parentObjId = 0;
                      client_page_data.ObjectList[_clientIndex].ImportName =
                        mappingData?.ImportName;
                    } else if (newState === "Mapped") {
                      client_page_data.ObjectList[_clientIndex].parentObjId =
                        mappingData.id;
                    }
                    setClientPageData(client_page_data);
                  }}
                  clientsMappingData={clientsMappingData}
                  getClientsMappingListData={getClientsMappingListData}
                />
              </div>
            </div>
          );
        })}
      {clientPageData?.ObjectList?.filter((item) =>
        item?.name?.toLowerCase().includes(clientSearchString?.toLowerCase())
      )?.length === 0 && (
          <div className="p-2 d-flex justify-content-center">
            No clients found
          </div>
        )}
    </>
  );
};

//
const MapClientsDropdown = ({
  clientDetail,
  clientsMappingData,
  getClientsMappingListData,
  onStateChange,
  clientSearchString,
}) => {
  function closeDropdown(e) {
    e.target.closest(".map-clients-dropdown").click();
  }
  const [fetchingMapping, setFetchingMapping] = useState(false);
  const [loadingMappingData, setLoadingMappingData] = useState(false)
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();
  const [page, setPage] = useState("home");

  useEffect(() => {
    if (clientDetail?.state === "Ignored") {
      setSelected();
    } else if (clientDetail?.state === "Imported") {
      setSelected();
    } else if (clientDetail?.state === "Not_Selected") {
      setSelected();
    } else if (clientDetail?.state === "Provisional_Import") {
      setSelected();
    } else if (clientDetail?.state === "Mapped") {
      let mapped_list = [...clientsMappingData?.ObjectList || []];
      if (clientDetail?.parentObjId > 0) {
        let selected = mapped_list.filter(
          (item) => item?.id === clientDetail?.parentObjId
        )[0];
        setSelected(selected);
      }
    }
  }, [clientDetail]);

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);


    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setLoadingMappingData(true);
      getClientsMappingListData({
        NextPageNo: 1,
        searchString: value,
      }).then(() => {
        setLoadingMappingData(false);
      });
    }, 700);
    setTimeoutId(_timeoutId);
  };

  const showModal = !viewOnlyAccess("AccessState_PSA_Integration");

  return (
    <Dropdown
      className="map-clients-dropdown"
      onToggle={(e) => {
        if (!showModal) return;
        if (e) {
          setPage("home");
          // getClientsMappingListData({
          //   NextPageNo: 1,
          //   searchString: "",
          // });

        } else {
          setLoadingMappingData(false);

          setSearch("");
          getClientsMappingListData({
            NextPageNo: 1,
            searchString: "",
          });
        }
      }}
    >
      <Dropdown.Toggle className={`d-flex align-items-center w-100 pre-wrap break-word`}>
        {clientDetail?.state === "Ignored" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Ignore</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {clientDetail?.state === "Not_Selected" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {clientDetail?.state === "Provisional_Import" && (
          <div
            className={`d-flex w-100 align-items-center justify-content-between  text-align-start`}
          >
            <div className={`w-100 bg-grey f-black`}>
              {clientDetail?.ImportName ? (
                <>
                  <span>Import as "</span>
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: HGHighlightText(
                          TrimString(clientDetail.ImportName, 20),
                          clientSearchString
                        ),
                      }}
                    />
                  }
                  <sapn>"</sapn>
                </>
              ) : (
                <>
                  <span>Import "</span>
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: HGHighlightText(
                          TrimString(clientDetail?.name, 20),
                          clientSearchString
                        ),
                      }}
                    />
                  }
                  <span>"</span>
                </>
              )}
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {clientDetail?.state === "Imported" && (
          <div
            className={`d-flex w-100 align-items-center justify-content-between `}
          >
            <div className={`w-100 bg-grey f-black d-flex  `}>
              {
                <span
                  dangerouslySetInnerHTML={{
                    __html: HGHighlightText(
                      TrimString(clientDetail?.name, 20),
                      clientSearchString
                    ),
                  }}
                />
              }
              {/* {clientDetail?.ImportName ? (
                <>Import as "{TrimString(clientDetail.ImportName, 20)}"</>
              ) : (
                <>Import "{TrimString(clientDetail?.name, 20)}"</>
              )} */}
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {clientDetail?.state === "Auto_Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {clientDetail?.state === "Deleted" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Deleted</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {clientDetail?.state === "Mapped" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className={`d-flex  align-items-center f-black  `}>
              <img
                alt=""
                width={16}
                height={16}
                className=" mr-2"
                src={"/images/user-logo.svg"}
              />

              <span
                dangerouslySetInnerHTML={{
                  __html: HGHighlightText(
                    TrimString(
                      selected?.name ??
                      clientDetail?.relationalObjectDetails?.name,
                      25
                    ),
                    clientSearchString
                  ),
                }}
              />
            </div>
            <div>
              <img
                alt=""
                className="mr-2"
                src="/images/msp/integrations/chevron-down-msp.svg"
              />
            </div>
          </div>
        )}
        {/* // )} */}
      </Dropdown.Toggle>
      {showModal && (
        <Dropdown.Menu className="no-border-shadow-dropdown">
          {page === "home" && (
            <>
              <div className="d-flex align-items-center mb-2">
                <MspMappingDropdownSearchInput
                  loading={loadingMappingData}
                  placeholder="Find or import as..."
                  value={search}
                  onChange={(e) => {
                    handleInputChange(e)
                  }}
                />
              </div>
              <div className="f-12 f-400 f-darkgrey my-1 pl-2">Map with</div>
              <ScrollableDivWithShadow
                className="transparent-scroller  client-right-list-scroller pl-2"
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                }}
              >
                {clientsMappingData?.ObjectList

                  // filter((item) =>
                  //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
                  // )

                  ?.map((client, clientIndex) => {
                    return (
                      <div
                        className="d-flex align-items-center f-grey f-14 f-500 w-100   pointer p1-5  radius-4 bg-hover-little-grey"
                        title={client?.name}
                        onClick={(e) => {
                          closeDropdown(e);
                          setSearch("");
                          onStateChange("Mapped", client);
                          setSelected(client);
                        }}
                      >
                        <img
                          alt=""
                          width={16}
                          height={16}
                          className="mx-2"
                          src={client?.imageUrl}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                              "/images/user-logo.svg";
                          }}
                        />

                        <span
                          dangerouslySetInnerHTML={{
                            __html: HGHighlightText(
                              TrimString(client?.name, 25),
                              search
                            ),
                          }}
                        />
                      </div>
                    );
                  })}
              </ScrollableDivWithShadow>

              {
                // ?.filter((item) =>
                //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
                // )
                clientsMappingData?.ObjectList?.length === 0 && (
                  <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
                    No results...
                  </div>
                )
              }

              {search !== "" && clientDetail?.state !== "Imported" && (
                <div
                  className="f-blue pointer d-flex align-items-center pl-3"
                  onClick={(e) => {
                    onStateChange("Provisional_Import", { ImportName: search });
                    closeDropdown(e);
                    setSearch("");
                  }}
                >
                  <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
                  Import as “{TrimString(search, 20)}”
                </div>
              )}
              {clientsMappingData?.NextPage === true && (
                <div
                  className="d-flex pointer align-items-center pl-3"
                  onClick={() => {
                    if (fetchingMapping) return;
                    setFetchingMapping(true);
                    getClientsMappingListData({
                      NextPageNo: null,
                      searchString: search,
                      skip: clientsMappingData?.ObjectList?.length,
                      count: 1000,
                    })
                      .then(() => {
                        setFetchingMapping(false);
                        const elementToScroll = document.querySelector(
                          ".client-right-list-scroller"
                        );
                        elementToScroll.scrollTo({
                          top: 10000000,
                          behavior: "smooth", // You can use 'auto' for instant scrolling
                        });
                      })
                      .catch(() => {
                        setFetchingMapping(false);
                      });
                  }}
                >
                  <DotsLoader animate={fetchingMapping} />
                  <div className="f-darkgrey ml-2 mt-2">Show all</div>
                </div>
              )}
              <hr className="hg-border mt-1 mx-auto px-3" style={{ width: "80%" }} />
              <div className="mx-2  ">
                {clientDetail?.state !== "Imported" && (
                  <>
                    <div
                      className="f-grey  pointer d-flex align-items-center contextmenu-grey-item"
                      onClick={(e) => {
                        setSelected();
                        closeDropdown(e);
                        setSearch("");
                        onStateChange("Provisional_Import");
                      }}
                    >
                      <img
                        src="/images/attack-surface/right-arrow.svg"
                        alt=""
                        className="mr-2"
                      />
                      Import "{TrimString(clientDetail?.name, 20)}"
                    </div>
                    {/* <div
                      className="f-grey mx-2 mt-2 pointer d-flex align-items-center bg-hover-grey-light"
                      onClick={(e) => {
                        setPage("importAs");
                        // setSearch("")
                      }}
                    >
                      <img
                        src="/images/attack-surface/right-arrow.svg"
                        alt=""
                        className="mr-2"
                      />
                      Import as ...
                    </div> */}
                  </>
                )}
                <div
                  className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
                  onClick={(e) => {
                    setSelected();
                    closeDropdown(e);
                    setSearch("");
                    onStateChange("Ignored");
                  }}
                >
                  <img
                    src="/images/msp/integrations/cross-icon-msp.svg"
                    alt=""
                    className="mr-2"
                  />
                  Ignore
                </div>
              </div>
            </>
          )}

          {page === "importAs" && (
            <div className="w-100 p-2">
              <div className="f-12 f-darkgrey mb-2 pl-1"> Import as </div>

              <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border">
                <input
                  type="text"
                  className="border-none bg-transparent w-100"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className="mt-2 d-flex align-items-center justify-content-end">
                <Button
                  className="hg-cancel-btn mr-2"
                  onClick={() => {
                    setPage("home");
                    setSearch("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="hg-blue-btn"
                  disabled={search === ""}
                  onClick={(e) => {
                    onStateChange("Provisional_Import", { ImportName: search });
                    closeDropdown(e);
                    setSearch("");
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
const ApplyToAllDropdown = ({
  detail,

  onStateChange,
}) => {
  function closeDropdown(e) {
    e.target.closest(".map-clients-dropdown").click();
  }

  const [search, setSearch] = useState("");

  const [page, setPage] = useState("home");

  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");

  return (
    <Dropdown className="map-clients-dropdown" onToggle={(e) => { }}>
      <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
        {detail?.state === "Ignored" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Ignore</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {!detail?.state && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {detail?.state === "Imported" && (
          <div
            className={`d-flex w-100 align-items-center justify-content-between `}
          >
            <div className={`w-100 bg-grey f-black d-flex  ${true}`}>
              Import
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
      </Dropdown.Toggle>
      {!viewOnly && (
        <Dropdown.Menu className="no-border-shadow-dropdown">
          {page === "home" && (
            <>
              {/* <hr className="hg-border mx-auto px-3" style={{ width: "80%" }} /> */}
              <div className="mx-2  ">
                <>
                  <div
                    className="f-grey pointer d-flex align-items-center contextmenu-grey-item"
                    onClick={(e) => {
                      closeDropdown(e);

                      onStateChange("Imported");
                    }}
                  >
                    <img
                      src="/images/attack-surface/right-arrow.svg"
                      alt=""
                      className="mr-2"
                    />
                    Import
                  </div>
                </>

                <div
                  className="f-grey    pointer d-flex align-items-center contextmenu-grey-item"
                  onClick={(e) => {
                    closeDropdown(e);

                    onStateChange("Ignored");
                  }}
                >
                  <img
                    src="/images/msp/integrations/cross-icon-msp.svg"
                    alt=""
                    className="mr-2"
                  />
                  Ignore
                </div>
              </div>
            </>
          )}

          {page === "importAs" && (
            <div className="w-100 p-2">
              <div className="f-12 f-darkgrey mb-2 pl-1"> Import as </div>

              <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border">
                <input
                  type="text"
                  className="border-none bg-transparent w-100"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className="mt-2 d-flex align-items-center justify-content-end">
                <Button
                  className="hg-cancel-btn mr-2"
                  onClick={() => {
                    setPage("home");
                    setSearch("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="hg-blue-btn"
                  disabled={search === ""}
                  onClick={(e) => {
                    onStateChange("Provisional_Import", { ImportName: search });
                    closeDropdown(e);
                    setSearch("");
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const ClientSearchByGroupDropdown = ({
  data = [],
  importStatus = [],
  onChange = () => { },
  selectedItems /*= []*/,
  onChangeSortOrder = () => { },
  selectedSortOrder,
  onChangeClientType = () => { },
  selectedClientType
}) => {
  const importStatusList = [
    { id: null, type: "All" },
    ...(importStatus.map((item) => ({ id: item, type: item }))),
  ];
  const isImportStatusAll = importStatusList.find(item => item.id === selectedClientType)?.id === null;
  let itemsLength = selectedItems?.length ? 1 : 0;
  if (!isImportStatusAll) {
    itemsLength += 1;
  }
  return (
    <Dropdown
      className="transparennt-wrapper-dropdown"
      onToggle={(e) => { }}
      alignRight
    >
      <Dropdown.Toggle>
        {/* {selectedItems?.length === 0 ? (
          <div className="f-grey px-2">Choose Filters</div>
        ) : (
          <div className="d-flex align-items-center px-2">
            <div className="f-black">{selectedItems[0]}</div>
            <div className="f-darkgrey ml-1">
              {selectedItems.length > 1 && `+${selectedItems.length - 1}`}
            </div>
          </div>
        )} */}
        {(itemsLength) ? (
          <div className="f-blue f-500">Filters +{itemsLength}</div>
        ) : (
          <div className="f-black">Filters</div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 transparent-scroller ">



        <div className="f-12 f-400 f-darkgrey my-2 px-3">Import Status</div>

        {importStatusList.map((item, index) => {
          console.log('item - ', item)
          return (
            <div
              className="d-flex align-items-center contextmenu-grey-item pointer mx-2"
              onClick={() => {
                onChangeClientType(item?.id);
              }}
            >
              {
                <img
                  src="/images/msp/dropdown-grey-tick.svg"
                  alt=""
                  className="mr-2 pl-1"
                  style={{
                    visibility:
                      selectedClientType === item?.id ? "visible" : "hidden",
                  }}
                />
              }
              <div className="f-14 f-400 f-grey">{item?.type}</div>
            </div>
          );
        })}










        <div className="f-12 f-400 f-darkgrey my-2 px-3">Client Type</div>
        <div
          className="d-flex align-items-center f-grey contextmenu-grey-item pointer mx-2"
          onClick={() => {
            onChange([]);
          }}
        >
          {
            <img
              src="/images/msp/dropdown-grey-tick.svg"
              alt=""
              className="mr-2"
              style={{
                // visibility: selectedItems.length === 0 ? "visible" : "hidden",
                visibility: selectedItems == '' ? "visible" : "hidden",
              }}
            />
          }
          Show all
        </div>

        <div
          className="transparent-scroller"
          style={{
            maxHeight: "200px",
            overflowY: "scroll",
            width: "259px",
          }}
        >
          {data
            ?.filter((item) => item?.showInFilters)
            ?.map((item, index) => {
              console.log('item === ', item)
              return (
                <>
                  <div
                    className="d-flex align-items-center contextmenu-grey-item pointer mx-2"
                    onClick={() => {
                      // if (selectedItems.includes(item)) {
                      //   let new_list = [...selectedItems];
                      //   new_list = new_list.filter((item2) => item2 !== item);
                      //   onChange(new_list);
                      // } else {
                      //   onChange([...selectedItems, item]);
                      // }

                      onChange(item?.ClientGroupName?.replace(/ /g, "_"));
                    }}
                  >
                    {
                      <img
                        src="/images/msp/dropdown-grey-tick.svg"
                        alt=""
                        className="mr-2 pl-1"
                        style={{
                          // visibility: selectedItems.includes(item?.ClientGroupName)
                          //   ? "visible"
                          //   : "hidden",
                          visibility: selectedItems == (item?.ClientGroupName)?.replace(/ /g, "_")
                            ? "visible"
                            : "hidden",
                        }}
                      />
                    }
                    <div className="f-14 f-400 f-grey" title={item?.ClientGroupName}>
                      {TrimString(item?.ClientGroupName, 30)}
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        <div className="f-12 f-400 f-darkgrey my-2 px-3">Sort</div>

        {[
          { order: 0, type: "Sort A to Z" },
          { order: 1, type: "Sort Z to A" },
        ].map((item, index) => {
          return (
            <div
              className="d-flex align-items-center contextmenu-grey-item mx-2 pointer"
              onClick={() => {
                onChangeSortOrder(item?.order);
              }}
            >
              {
                <img
                  src="/images/msp/dropdown-grey-tick.svg"
                  alt=""
                  className="mr-2 pl-1"
                  style={{
                    visibility:
                      selectedSortOrder === item?.order ? "visible" : "hidden",
                  }}
                />
              }
              <div className="f-14 f-400 f-grey">{item?.type}</div>
            </div>
          );
        })}
        <div className="p-1" />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const MapClientsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};

const OAuthIntegrationForMapClients = ({
  onSuccess,
  integrationId,
  refreshData,
  hideModal,
  OAuthEnabled,
  objId_Customer,
  key
}) => {
  const { authTokens } = useAuth();
  const [Oauthing, setOauthing] = useState(false);

  return (
    <div
      className="integrationv2-map-clients-oauth2-btn"
      onClick={(e) => {
        e.stopPropagation();
        setOauthing(true);
      }}
    >
      <OAuth2Login
        state={`12345678` + Number(key)}
        // isCrossOrigin={true}
        extraParams={OAuthEnabled[integrationId].extraParams_}
        authorizationUrl={OAuthEnabled[integrationId].AUTH_URL}
        responseType="code"
        scope={OAuthEnabled[integrationId].SCOPE}
        clientId={OAuthEnabled[integrationId].CLIENT_ID}
        clientSecret={OAuthEnabled[integrationId].CLIENT_SECRET}
        redirectUri={`${window.location.origin}/`}
        onSuccess={(response) => {
          console.log(response, "response")
          console.log(response, "response")
          console.log(response, "response")
          //   {code: 'J5ayMuZGoVX-Sk-Ko2RpRQZXqTQGeGcKoNA6Nzwv_9w', scope: 'accounting.contacts.read offline_access'}
          var realmId = {};
          if (response?.realmId) {
            realmId["KeyString2"] = response?.realmId;
          }

          let key0 = response?.code;

          let key1 = `${window.location.origin}/`;

          postData(authTokens, "SubmitIntegrationKeys", {
            IntegrationId: integrationId,
            KeyString0: key0,
            KeyString1: key1,
            TestOrCreate: 3,
            ForTenentAuth: true,
            ObjId_Customer: objId_Customer,
            ...realmId,
          })
            .then((response) => {
              setOauthing(false);
              if (response?.mr?.Success) {
                if (refreshData) refreshData();
                if (hideModal) hideModal();
                if (onSuccess) onSuccess();
                CallToast(
                  "Integration connected successfully",
                  false,
                  false,
                  "v2style"
                );
              }
            })
            .catch((res) => {
              console.log("fail-new-error", res);
              console.log("CATCH-fail-new-error222");
              setOauthing(false);
              CallToast("Error, Please try again", false, false, "v2style");
            });
        }}
        onFailure={(res) => {
          console.log("fail", res);
          setOauthing(false);
          CallToast("Error, Please try again", false, false, "v2style");
        }}
      >
        {Oauthing ? (
          <>
            {/* <WhiteLoader width={13} /> */}
            Loading...
          </>
        ) : (
          "Authorise"
        )}
      </OAuth2Login>
    </div>
  );
};


const test = {
  "code": "0.AUgAjQkS0ODGh0-L3t7gg3Pwwzh1ALYx3OJOpDGniwkPqoBIAAA.AgABBAIAAADnfolhJpSnRYB1SVj-Hgd8AgDs_wUA9P98yhnzcsS50Gv_qj8TuTn1onrucLeRizadxrzIKGN8gqa5RD8NZgtYEfsMGz3Ljy7q78Sh2TWJNkT8Sfxy8ZPqOoZ5D31D5gSuxExY3RdCOiKAu9rc1sbCEwOfQ1epY0WLWijCT_rN7K3fxpACWpo_1p94qCh44XMoVEtrIxSAOy_o9VCCX0YpbsXAl5x6NGSb6XcMlsVQjTFPICve_HR_Qe2wWMMyN6CTOb4kvT_L9GNW9MPiFCIuOKWdho3dfF-BQjqR_sU9FfbHZhnNmj8-PSzfVxTRSxOGJI7JC4wM0XON9FYh0JlZeVypEjANaB7D6F17ZVpOX_w-vnYJhZRnD9koHIJoptX3YtbAKK8OnMA48Ix48-1OEozDNf57KoZHsZdQ0jtSEzgKmH2CGd6OIoH4KZhtupp33dJIloeTb-0WDmrHcGGLAp69ZKmsWlLthgcOdrLGCtlogYnMkR9uz06CAylFc7RmV8iN-oVASO48WBxzGsC8P3t6-e0P8yu7J222fmeoEqzrmpFmWlvjf4Rk1-qAcZaYypCDrPcGsE4LABtdm3KBS6HtG2A54vFYk9Ic0kzM5oJi7IsFqSQ6e7XcBbhtxZP_pvrMc94gqx3NX0kV-PXxijCkBKxJ34Ej7VlO71uHjb6QoB_NvlC8wUUxWCELh5RiP7S7rJ5jVoliqjpMK22c_gACw0VlHK4j_htq2RvflmOu7aAieNO1aQ",
  "state": "12345678",
  "session_state": "298f8686-5d83-4dba-8236-daee1df1d708"
}
const FrameworksSection = ({ auditDetails }) => (
  <div className="w-100 d-flex flex-column">
    <div className="f-14 f-600"
      style={{
        marginBottom: '8px'
      }}
    >
      Frameworks
    </div>
    {/* Security Frameworks Block */}
    <>
      <div className="f-14 f-500"
        style={{
          marginBottom: '8px'
        }}
      >
        {auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.Name} Frameworks
        {/* Security Frameworks */}
      </div>
      {
        auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.FrameworkList?.length !== 0 ?
          <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
            {
              auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.FrameworkList?.map((frameworkItem, index) => {
                return (
                  <div
                    className={`border-item d-flex align-items-center justify-content-between p-3`}
                    style={{
                      borderBottom: `${index < auditDetails?.SecurityFrameworks?.FrameworkGroupList[0]?.FrameworkList?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div>
                          <span className="f-500 f-black">
                            {frameworkItem?.Abbreviation}
                          </span>
                          &nbsp; &nbsp;
                        </div>
                        <div className="f-darkgrey">
                          {frameworkItem?.Name}{" "}
                        </div>
                      </div>
                    </div>
                    {!frameworkItem?.CyberFrameworkAudit && (
                      <div className="d-flex align-items-center">
                        <>
                          <div
                            className="mr-4 p-2 f-grey f-12 f-500"
                          >
                            <img
                              src="/images/attack-surface/shown-icon.svg"
                              alt=""
                              className="pointer mr-2"
                            />{" "}
                            <span className="pt-3">Show</span>
                          </div>
                          <img
                            alt=""
                            src="/images/actioncenter/plusgrey.svg"
                          />
                        </>
                      </div>
                    )}
                    <div className="d-flex align-items-center">
                      {frameworkItem?.CyberFrameworkAudit && (
                        <div className="d-flex align-items-center">
                          <div className="f-12 f-darkgrey mr-3 w-max-content">
                            <div>
                              <>
                                Last audit:{" "}
                                {frameworkItem?.CyberFrameworkAudit
                                  ?.LastAudit !== "NA" ? frameworkItem?.CyberFrameworkAudit
                                  ?.LastAudit
                                  : "Not Selected"}
                              </>
                            </div>
                            <div>
                              <>
                                Next audit:{" "}
                                {frameworkItem?.CyberFrameworkAudit
                                  ?.NextAudit !== 'NA'
                                  ? frameworkItem?.CyberFrameworkAudit
                                    ?.NextAudit
                                  : "Not Selected"}
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                        style={{
                          height: '24px',
                          color: `${frameworkItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                          backgroundColor: `${frameworkItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        {frameworkItem?.UpdatedStatus}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
            style={{
              backgroundColor: '#F6F8FB',
            }}
          >
            Security Frameworks have not been changed
          </div>
      }
    </>
    {/* Data Privacy Frameworks Block */}
    <>
      <div className="f-14 f-500"
        style={{
          marginTop: '8px',
          marginBottom: '8px'
        }}
      >
        {auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.Name} Frameworks
      </div>
      {
        auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.FrameworkList?.length !== 0 ?
          <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
            {
              auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.FrameworkList?.map((frameworkItem, index) => {
                return (
                  <div
                    className={`border-item d-flex align-items-center justify-content-between p-3`}
                    style={{
                      borderBottom: `${index < auditDetails?.DataPrivacyFrameworks?.FrameworkGroupList[0]?.FrameworkList?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div>
                          <span className="f-500 f-black">
                            {frameworkItem?.Abbreviation}
                          </span>
                          &nbsp; &nbsp;
                        </div>
                        <div className="f-darkgrey">
                          {frameworkItem?.Name}{" "}
                        </div>
                      </div>
                    </div>
                    {!frameworkItem?.CyberFrameworkAudit && (
                      <div className="d-flex align-items-center">
                        <>
                          <div
                            className="mr-4 p-2 f-grey f-12 f-500"
                          >
                            <img
                              src="/images/attack-surface/shown-icon.svg"
                              alt=""
                              className="pointer mr-2"
                            />{" "}
                            <span className="pt-3">Show</span>
                          </div>
                          <img
                            alt=""
                            src="/images/actioncenter/plusgrey.svg"
                          />
                        </>
                      </div>
                    )}
                    <div className="d-flex align-items-center">
                      {frameworkItem?.CyberFrameworkAudit && (
                        <div className="d-flex align-items-center">
                          <div className="f-12 f-darkgrey mr-3 w-max-content">
                            <div>
                              <>
                                Last audit:{" "}
                                {frameworkItem?.CyberFrameworkAudit
                                  ?.LastAudit !== "NA" ? frameworkItem?.CyberFrameworkAudit
                                  ?.LastAudit
                                  : "Not Selected"}
                              </>
                            </div>
                            <div>
                              <>
                                Next audit:{" "}
                                {frameworkItem?.CyberFrameworkAudit
                                  ?.NextAudit !== 'NA'
                                  ? frameworkItem?.CyberFrameworkAudit
                                    ?.NextAudit
                                  : "Not Selected"}
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                        style={{
                          height: '24px',
                          color: `${frameworkItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                          backgroundColor: `${frameworkItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        {frameworkItem?.UpdatedStatus}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
            style={{
              backgroundColor: '#F6F8FB',
            }}
          >
            Data Privacy Frameworks have not been changed
          </div>
      }
    </>
  </div>
);

export default FrameworksSection;

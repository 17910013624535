import React, { useEffect, useState } from "react";
import { Button, Dropdown, Nav, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SwitchButtonMulti from "../../Theme/switchButtonMulti";
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { TrimString } from "../../../Utils/StringTrimmer";
import { HGHighlightText, getAccessStateValue, viewOnlyAccess } from "../../App/App";
import { PackageListRadarUI } from "./MspClientPriceCalculator";
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import { VisibilityType } from "../../../constants/filter.constants";
import { GreyLoader } from "../../Theme/APILoaders";
import { CustomDeleteModal } from "../../DeleteModal/DeleteModal";
import SourcesModal from "./SourcesModal";
import UpdateCyberKPIModal from "./UpdateCyberKPIModal";
import InfoModal from "./InfoModal";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import QuantityOfAssetsBlock from "./packaesDetails/QuantityOfAssetsBlock";
import ActivePackageBlock from "./ActivePackageBlock";
import PackagesListBlock from "./PackagesListBlock";

export const PriceCalculatorDashBoard = ({
    pageData,
    inUseData,
    SearchInput,
    ownClientID,
    packagesLoading,
    activePackageId,
    isMspClientView,
    deleteModalConfig,
    activePackageFound,
    _openedPackagesIds,
    switchIncludedTaxVal,
    priceCalculatorState,
    showUpdateCyberKPIModal,
    activeOrInactiveLoading,
    defaultUserDeviceDetails,
    activeOrInactivePackageId,
    defaultUserDeviceDetailsStartData,
    refreshData = () => { },
    getPageData = () => { },
    closeScenario = () => { },
    showInfoModal = () => { },
    updateCyberKPI = () => { },
    setClickedItem = () => { },
    getFeaturesData = () => { },
    setShowInfoModal = () => { },
    getPackageDetails = () => { },
    getPackagesPageData = () => { },
    checkPackageIfInUse = () => { },
    setActivePackageFound = () => { },
    _setOpenedPackagesIds = () => { },
    setIsActiveOrInactive = () => { },
    updateQuantityOfAssets = () => { },
    setSwitchIncludedTaxVal = () => { },
    getSecurityCapabilities = () => { },
    setShowUpdateCyberKPIModal = () => { },
    setShowMapCurrentSpendModal = () => { },
    setMapCurrentSpendPackageId = () => { },
    setActiveOrInactivePackageId = () => { },
    setMapCurrentSpendPackagePrice = () => { },
    setShowCreateCustomPackageModal = () => { },
    setMapCurrentSpendPackagePeriod = () => { },
    setMapCurrentSpendPackagePriceText = () => { },
    setPriceCalculatorState = () => { }
}) => {
    const [showUptoStandard, setShowUptoStandard] = useState(5);
    const [showUptoCustom, setShowUptoCustom] = useState(5);

    const [searchString, setSearchString] = useState("");
    const [savingAssets, setSavingAssets] = useState(false);
    const [tempUserDetailsData, setTempUserDetailsData] = useState([]);
    const [openedPackages, setOpenedPackages] = useState(_openedPackagesIds);
    const preDataState = tempUserDetailsData.every((item) => item.Qty === 0);
    const [showSourcesModal, setShowSourcesModal] = useState(false);
    const [packageOrder, setPackageOrder] = useState(0);

    useEffect(() => {
        if (activePackageId != 0) {
            setOpenedPackages([activePackageId]);
            getPackageDetails(activePackageId);
            getFeaturesData(activePackageId);
            getSecurityCapabilities(activePackageId);
        }
    }, [])

    useEffect(() => {
        setOpenedPackages(_openedPackagesIds);
    }, [_openedPackagesIds]);

    useEffect(() => {
        setTempUserDetailsData(defaultUserDeviceDetails);
    }, [defaultUserDeviceDetails]);

    function saveAssets() {
        setSavingAssets(true);
        let userDetails = [...tempUserDetailsData];

        userDetails.forEach((item) => {
            if (item.Qty === "") {
                item.Qty = 0;
            }
        });

        updateQuantityOfAssets(userDetails)
            .then((res) => {
                document.getElementById("quantyAssetOnDashboard").click();
                setSavingAssets(false);
                refreshData();
                getPackagesPageData();
                CallToast("Updated successfully", false, false, "v2style");
            })
            .catch((err) => {
                setSavingAssets(false);
                console.log(err);
                CallToast("Something went wrong", false, false, "v2style");
            });
    }


    const clientId = window.location.pathname.split("/")?.[4] ?? -1;

    const [assetsFromIntegrations, setAssetsFromIntegrations] = useState([]);
    const [isActivePackageOpen, setIsActivePackageOpen] = useState(false);
    const [selectActivePackageDataSearch, setSelectActivePackageDataSearch] = useState('');

    useEffect(() => {
        setActivePackageFound(pageData?.ScenarioHheaderList?.find(item => item?.IsPackageActive));

        setIsActivePackageOpen(activePackageFound ? openedPackages?.includes(activePackageFound.objId_Scenario) : false)
    }, [pageData])

    return (
        <div
            className="main-block"
        >

            <QuantityOfAssetsBlock
                clientId={clientId}
                ownClientID={ownClientID}
                savingAssets={savingAssets}
                preDataState={preDataState}
                isMspClientView={isMspClientView}
                tempUserDetailsData={tempUserDetailsData}
                assetsFromIntegrations={assetsFromIntegrations}
                defaultUserDeviceDetails={defaultUserDeviceDetails}
                saveAssets={saveAssets}
                setShowSourcesModal={setShowSourcesModal}
                setTempUserDetailsData={setTempUserDetailsData}
            />

            <ActivePackageBlock
                clientId={clientId}
                pageData={pageData}
                ownClientID={ownClientID}
                searchString={searchString}
                savingAssets={savingAssets}
                openedPackages={openedPackages}
                activePackageFound={activePackageFound}
                isActivePackageOpen={isActivePackageOpen}
                switchIncludedTaxVal={switchIncludedTaxVal}
                priceCalculatorState={priceCalculatorState}
                activeOrInactiveLoading={activeOrInactiveLoading}
                selectActivePackageDataSearch={selectActivePackageDataSearch}
                closeScenario={closeScenario}
                setClickedItem={setClickedItem}
                getFeaturesData={getFeaturesData}
                getPackageDetails={getPackageDetails}
                setOpenedPackages={setOpenedPackages}
                checkPackageIfInUse={checkPackageIfInUse}
                setIsActiveOrInactive={setIsActiveOrInactive}
                _setOpenedPackagesIds={_setOpenedPackagesIds}
                getSecurityCapabilities={getSecurityCapabilities}
                setShowUpdateCyberKPIModal={setShowUpdateCyberKPIModal}
                setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                setActiveOrInactivePackageId={setActiveOrInactivePackageId}
                setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                setSelectActivePackageDataSearch={setSelectActivePackageDataSearch}
                setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
            />

            <PackagesListBlock
                data={pageData?.ScenarioHheaderList}
                SearchInput={SearchInput}
                ownClientID={ownClientID}
                packageOrder={packageOrder}
                savingAssets={savingAssets}
                searchString={searchString}
                openedPackages={openedPackages}
                showUptoCustom={showUptoCustom}
                isMspClientView={isMspClientView}
                setSearchString={setSearchString}
                packagesLoading={packagesLoading}
                switchIncludedTaxVal={switchIncludedTaxVal}
                priceCalculatorState={priceCalculatorState}
                showRightSide={true}
                activeOrInactiveLoading={activeOrInactiveLoading}
                packageTypeIsStandard={false}
                closeScenario={closeScenario}
                setClickedItem={setClickedItem}
                getFeaturesData={getFeaturesData}
                setPackageOrder={setPackageOrder}
                setShowUptoCustom={setShowUptoCustom}
                setOpenedPackages={setOpenedPackages}
                getPackageDetails={getPackageDetails}
                checkPackageIfInUse={checkPackageIfInUse}
                setIsActiveOrInactive={setIsActiveOrInactive}
                _setOpenedPackagesIds={_setOpenedPackagesIds}
                getSecurityCapabilities={getSecurityCapabilities}
                setSwitchIncludedTaxVal={setSwitchIncludedTaxVal}
                setShowUpdateCyberKPIModal={setShowUpdateCyberKPIModal}
                setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                setActiveOrInactivePackageId={setActiveOrInactivePackageId}
                setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                setShowCreateCustomPackageModal={setShowCreateCustomPackageModal}
                setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
            />

            <PackagesListBlock
                data={pageData?.ScenarioHheaderList}
                SearchInput={SearchInput}
                ownClientID={ownClientID}
                packageOrder={packageOrder}
                savingAssets={savingAssets}
                searchString={searchString}
                openedPackages={openedPackages}
                showUptoCustom={showUptoCustom}
                isMspClientView={isMspClientView}
                setSearchString={setSearchString}
                packagesLoading={packagesLoading}
                switchIncludedTaxVal={switchIncludedTaxVal}
                priceCalculatorState={priceCalculatorState}
                activeOrInactiveLoading={activeOrInactiveLoading}
                closeScenario={closeScenario}
                setClickedItem={setClickedItem}
                getFeaturesData={getFeaturesData}
                setPackageOrder={setPackageOrder}
                setShowUptoCustom={setShowUptoCustom}
                setOpenedPackages={setOpenedPackages}
                getPackageDetails={getPackageDetails}
                checkPackageIfInUse={checkPackageIfInUse}
                setIsActiveOrInactive={setIsActiveOrInactive}
                _setOpenedPackagesIds={_setOpenedPackagesIds}
                getSecurityCapabilities={getSecurityCapabilities}
                setSwitchIncludedTaxVal={setSwitchIncludedTaxVal}
                setShowUpdateCyberKPIModal={setShowUpdateCyberKPIModal}
                setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                setActiveOrInactivePackageId={setActiveOrInactivePackageId}
                setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                setShowCreateCustomPackageModal={setShowCreateCustomPackageModal}
                setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                setPriceCalculatorState={setPriceCalculatorState}
            />


            <CustomDeleteModal
                show={deleteModalConfig.show}
                hideModal={deleteModalConfig.hideModal}
                deleteMspFunction={deleteModalConfig.deleteFunction}
                deleteMspSellConfig={deleteModalConfig}
                deleteMessage={deleteModalConfig.deleteMessage}
                deleteType={deleteModalConfig.deleteType}
            />
            <SourcesModal
                show={showSourcesModal}
                tempUserDetailsData={tempUserDetailsData}
                startData={defaultUserDeviceDetailsStartData}
                setTempUserDetailsData={setTempUserDetailsData}
                saveAssets={saveAssets}
                _setAssetsFromIntegrations={setAssetsFromIntegrations}
                getPageData={getPageData}
                hideModal={() => {
                    setShowSourcesModal(false);
                    const drag = document?.getElementById("sources-modal");
                    drag.style.display = "none";
                }}
                clientId={clientId}
            />
            <UpdateCyberKPIModal
                show={showUpdateCyberKPIModal}
                clientId={clientId}
                packageId={activeOrInactivePackageId}
                updateCyberKPI={updateCyberKPI}
                setIsActiveOrInactive={setIsActiveOrInactive}
                hideModal={() => {
                    setShowUpdateCyberKPIModal(false);
                    setActiveOrInactivePackageId(0);
                }}
            />
            <InfoModal
                show={showInfoModal}
                label={"Package in use and can't be deleted"}
                children={<div>
                    <div className="f-500 f-14 f-black mb-2">
                        List of customers it is applied to:
                    </div>
                    {
                        inUseData?.IntegrationClient?.map((clientData) => {
                            return (
                                <div className="d-flex align-items-center pb-1">
                                    <ImageWithInitials
                                        initials={clientData?.Intials}
                                        width={20}
                                        background={"purple"}
                                    />
                                    <div className="f-400 f-14 f-grey ml-2">
                                        {clientData?.ClientName}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>}
                hideModal={() => {
                    setShowInfoModal(false);
                }}
            />
        </div>
    );
};

export default PriceCalculatorDashBoard;

import React, { useEffect, useState } from "react";
import { Button,   Nav,   Tab } from "react-bootstrap";
import "./drilldownModal.scss";
import { IndependentGetAPIData, getAPIData, postRequest } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import { accessStateValue, viewOnlyAccess } from "../App/App";
import CompanySettingsDropdown from "../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { GreyLoader } from "../Theme/APILoaders"; 
import SlidingModal from "../CustomModal/SlidingModal";
import { AttestationPopUp } from "./KPIpopup/KPIpopup";
import CyberComplianceForGroupModal, {
  CreateClientComplianceLoader,
} from "../MSPv2/clients/createClientScreens/CyberComplianceForGroupModal";
import { Attestation_Icon_Mappings } from "../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import CyberScoreForGroupModal from "./KPICyberScoreSection";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import useCyberGroupModalData from "./cyber-group-modal.data";
import integrationUtils from "../../Utils/integration.utils";
import useUserActions from "../../hooks/actions/user.actions";
import { CLIENT_GETTING_STARTED_TYPE } from "../../constants/user.constants";
import SuggestModal from "./suggest-modal/suggest.modal";

const CyberGroupModal = ({
  show,
  hideModal,
  parentID,
  categoryData,
  refreshData = () => { },
  defaultCurrencyData,
  isDraggableParent,
  isSaas,
  clientId,
  defaultTab,
  AccessStateLabel,
  passedOptionEnum99,
  dontShowCyberThreatLevel = false,
  clientData,
  // showActivePackage = false,
  activePackageId,
  activePackageName,
  isViewOnly = false
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState("cyberScore");
  const [showAttestationIcons, setShowAttestationIcons] = useState(true);
  const [displaySuggestModal, setDisplaySuggestModal] = useState(false);
  const [suggestUpdatesCount, setSuggestUpdatesCount] = useState(0);

  useEffect(() => {

    if (show && defaultTab) {
      setDefaultSelectedTab(defaultTab)
    }
    setSuggestUpdatesCount(0);
  }, [defaultTab, show])


  //   Cyber Score states
  const { authTokens } = useAuth();
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const [toolData, setToolData] = useState([]);
  const [cyberScoreData, setCyberScoreData] = useState({});
  const [cyberThreatLevelData, setCyberThreatLevelData] = useState({});
  const [selectedIntegrationId, setSelectedIntegrationId] = useState();

  const [showMspConnectModal, setShowMspConnectModal] = useState(false);
  const { isLoading: isUserDetailsLoading, data: userDetails, fetchData: fetchUserDetails } = useCyberGroupModalData();
  const { updateGettingStarted } = useUserActions();

  useEffect(() => {
    if (!show) return;
    if (defaultSelectedTab === "cyberScore") {

    } else if (defaultSelectedTab === "cyberThreatLevel") {
      setMainPageLoading(true);
      getCyberThreatLevelData();
    }
  }, [show, defaultSelectedTab]);

  useEffect(() => {
    let main_wrapper = document.querySelector("#cyber-score-group-wrapper");
    if (!main_wrapper) return;
    let all_rows = main_wrapper.querySelectorAll(".point");
    for (let i = 0; i < all_rows.length; i++) {
      let panel = all_rows[i].querySelector(".panel");
      if (panel?.style?.maxHeight) {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }, [toolData]);

  useEffect(() => {
    if (isUserDetailsLoading || !userDetails) return;
    setShowAttestationIcons(userDetails.ShowAttestationIcons);
  }, [userDetails, isUserDetailsLoading]);

  useEffect(() => {
    if (!show) {
      setDisplaySuggestModal(false);
      return;
    };
    fetchUserDetails();
    updateGettingStarted({
      configValue: true,
      configType: CLIENT_GETTING_STARTED_TYPE.ReviewSecurityPosture,
      clientId
    })
  }, [show]);

  /**
   * @param
   *
   * {
   * showUpdateToast: false,
   * showErrorToast:false,
   * keepToolsAtRow
   * }
   */
  function getCyberScoreData(kwargs = {}) {
    if (passedOptionEnum99) {
      IndependentGetAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              selected:
                toolInfo?.SelectedTool_List?.length > 0
                  ? { id: 1, category: "Yes" }
                  : toolInfo?.ShowNoCapability
                    ? { id: 0, category: "No" }
                    : null,
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    } else {
      getAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              selected:
                toolInfo?.SelectedTool_List?.length > 0
                  ? { id: 1, category: "Yes" }
                  : toolInfo?.ShowNoCapability
                    ? { id: 0, category: "No" }
                    : null,
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    }
  }


  // kwargs={
  //   technologyEnum,
  //   IntegrationId,
  //   toolName,
  //   selected,
  //   rowIndex
  // }




  // kwargs={
  //   technologyEnum,
  //   rowIndex
  // }
  async function removeAllIntegrations(kwargs = {}) {
    if (passedOptionEnum99) {
      await IndependentGetAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
         
          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
  
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    } else {
      await getAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
      })
        .then((response) => {
         
          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
  
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    }
  }

  function itemsLoading() {
    let isLoading = toolData?.some((toolRow) => {
      let integrationLoading = toolRow?.IntegrationList?.some(
        (integrationItem) => integrationItem?.loading === true
      );

      return integrationLoading || toolRow?.loading || toolRow?.addingNewTool;
    });

    return isLoading;
  }



  async function checkValidationForCyberScore() {
    let updated_data = [...toolData];
    let errors = 0;

    updated_data = updated_data.map((item) => {
      const newItem = {
        ...item,
        error: !item.selected,
      };
      if (newItem.error) {
        errors += 1;
      }
      return newItem;
    });

    setToolData(updated_data);

    return errors;
  }

  // Cyber Threat Level

  function getCyberThreatLevelData(kwargs = {}) {
    if (passedOptionEnum99) {
      IndependentGetAPIData(434, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId,
        optionEnum2: 4,
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberThreatLevelData(response);
          return;
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              selected:
                toolInfo?.SelectedTool_List?.length > 0
                  ? { id: 1, category: "Yes" }
                  : toolInfo?.ShowNoCapability
                    ? { id: 0, category: "No" }
                    : null,
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    } else {
      getAPIData(434, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId,
        optionEnum2: 4,
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberThreatLevelData(response);
          return;
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              selected:
                toolInfo?.SelectedTool_List?.length > 0
                  ? { id: 1, category: "Yes" }
                  : toolInfo?.ShowNoCapability
                    ? { id: 0, category: "No" }
                    : null,
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    }
  }

  return (
    <>
      <div className="suggest-modal-wrapper">
        <SuggestModal 
          show={displaySuggestModal} 
          clientId={clientId} 
          onHide={() => setDisplaySuggestModal(false)}
          refreshData={() => {
            setSuggestUpdatesCount(prev => prev + 1);
          }}
        />
      </div> 
      <SlidingModal
        show={show}
        dialogClassName="cyber-group-modal position-relative"
        className="cyber-group-modal"
        aria-labelledby="cyber-group-modal "
        parentID={parentID}
        id="0016sqxw"
        onHide={hideModal}
      // isDraggableParent={isDraggableParent}
      >
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={defaultSelectedTab}
          activeKey={defaultSelectedTab}
        >
          <SlidingModal.Header className=" d-flex align-items-center justify-content-between border-bottom px-3 pt-3">
            <div
              sm={12}
              className="tab-header d-flex justify-content-between align-items-center ml- p-0"
            >
              <div className={`${true}`}>
                <Nav
                  variant="pills"
                  className="flex-row"
                  onSelect={(e) => setDefaultSelectedTab(e)}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="cyberScore">CyberScore </Nav.Link>
                  </Nav.Item>
                  {/* {
                    !dontShowCyberThreatLevel &&
                    <Nav.Item>
                      <Nav.Link eventKey="cyberThreatLevel">
                        CyberThreat Level{" "}
                      </Nav.Link>
                    </Nav.Item>
                  } */}
                  <Nav.Item id="">
                    <Nav.Link eventKey="cyberCompliance">
                      Governance & Resilience
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div>
              <img
                src="/images/attack-surface/cross-icon.svg"
                alt=""
                className="pointer mb-2"
                onClick={hideModal}
              />
            </div>
          </SlidingModal.Header>

          <SlidingModal.Body
            className={` ${dataLoading ? "stop-all-events" : ""
              }  transparent-scroller`}
          >
            {mainPageLoading && isUserDetailsLoading ? (
              <CreateClientComplianceLoader />
            ) : (
              <Tab.Content>
                {show && 
                    <>{
                      clientData && 
                      <div className="d-flex align-items-center mt-2 ml-2 mb-4">
                        {clientData.icon ? (
                          <img
                            alt=""
                            className="mr-2 rounded-full"
                            style={{ width: "32px" }}
                            src={clientData.icon}
                          />
                        ) : (
                          <div className="mr-2">
                            <ImageWithInitials
                              initials={clientData?.clientName?.charAt(0)}
                              width={24}
                              height={24}
                              background={"purple"}
                              borderRadius={20}
                            />
                          </div>
                        )}
                        <div>{clientData?.clientName}</div>
                      </div>
                    }</>
                  }
                <Tab.Pane eventKey="cyberScore">
                  {show && <CyberScoreForGroupModal 
                    clientId={clientId} 
                    passedOptionEnum99={passedOptionEnum99} 
                    showActivePackage={/*showActivePackage*/!clientData}
                    activePackageName={activePackageName}
                    activePackageId={activePackageId}
                    setShowAttestationIcons={setShowAttestationIcons}
                    showAttestationIcons={showAttestationIcons}
                    isSaas={isSaas}
                    isViewOnly={isViewOnly}
                    setDisplaySuggestModal={setDisplaySuggestModal}
                    suggestUpdatesCount={suggestUpdatesCount}
                  />}
                </Tab.Pane>

                <Tab.Pane eventKey="cyberThreatLevel">
                  <div
                    id="cyber-score-group-wrapper"
                    className="w-100 pt-1"
                    style={{
                      pointerEvents:
                        accessStateValue("AccessState_MSP_Security_Stack") === 1
                          ? "none"
                          : "all",
                    }}
                  >
                    <div
                      className={`bg-grey radius-8 border-parent   ${itemsLoading() || isViewOnly ? "stop-all-events" : ""
                        }`}
                      id="stack-wrapper"
                    >
                      <div className="border-item f-12 f-500 f-grey p-2 cyber-score-table-row">
                        <div className="pl-3">Component</div>
                        <div>Details</div>
                        <div>
                          {/* Impact on Score */}
                        </div>
                      </div>
                      {cyberThreatLevelData?.AttestationRow_List?.map(
                        (tool, toolIndex) => {
                          return (
                            <div
                              className="point  border-item"
                            // onClick={(e) => ToggleAccordion(e, true)}
                            >
                              {toolIndex % 2 === 0 && false && (
                                <div className="d-flex flex-column align-items-center justify-content-between bg-hover-row w-100 pointer px-4  py-3">
                                  <div className="d-flex align-items-center justify-content-between w-100 ">
                                    <div className="d-flex align-items-center  ">
                                      <div className="mr-2">
                                        <AttestationPopUp>
                                          <img
                                            alt=""
                                            src="/images/attack-surface/unverified.svg"
                                            className=""
                                          />
                                        </AttestationPopUp>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="d-flex align-items-baseline ">
                                          <p className="f-500 m-0">
                                            {tool?.TechnologyTitle}
                                          </p>
                                        </div>
                                        {tool?.ShowSelectedTool ? (
                                          <>
                                            {tool?.SelectedTool_List?.map(
                                              (
                                                connectedTool,
                                                connectedToolIndex
                                              ) => {
                                                return (
                                                  <div className="d-flex align-items-center ">
                                                    <img
                                                      alt=""
                                                      width={16}
                                                      height={16}
                                                      className="rounded-full"
                                                      src={integrationUtils.getImagePath(connectedTool?.obj_IntegrationId, connectedTool?.ToolIcon)}
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null; // prevents looping
                                                        currentTarget.src =
                                                          "/images/attack-surface/generic2.svg";
                                                      }}
                                                    />
                                                    <div className="d-flex align-items-center mx-2 f-12 f-500 f-black">
                                                      {connectedTool?.ToolName}
                                                    </div>
                                                    <div className="d-flex align-items-center f-12 f-500  ">
                                                      {!connectedTool?.IsConnected && (
                                                        <div
                                                          className="f-blue mr-2"
                                                          onClick={(e) => {
                                                            if (!isViewOnly || !viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard")) {
                                                              e.stopPropagation();
                                                              setSelectedIntegrationId(
                                                                connectedTool?.obj_IntegrationId
                                                              );
                                                              setShowMspConnectModal(
                                                                true
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {/* Connect{" "} */}
                                                        </div>
                                                      )}
                                                      <div
                                                        className="f-darkgrey"
                                                        onClick={
                                                          (e) => { }
                                                          // ToggleAccordion(e, true)
                                                        }
                                                      >
                                                        Change
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <div className="f-12 f-darkgrey ">
                                            {tool?.TechnologyQuestion}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="option-dropdown d-flex align-items-center"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <div>{tool?.loading && <GreyLoader />}</div>
                                      <CompanySettingsDropdown
                                        error={tool?.error}
                                        transparentBackgroundToggle
                                        fieldId={"id"}
                                        fieldValue={"category"}
                                        selected={tool.selected}
                                        placeholder="Select"
                                        //  showid={true}
                                        onClick={(id, val, e) => {
                                          if (tool?.selected?.category === val)
                                            return;
                                          let updated_data = [...toolData];

                                          updated_data[toolIndex].selected = {
                                            id,
                                            category: val,
                                          };
                                          updated_data[toolIndex].error = false;

                                          var parent = e.target.closest(".point");

                                          if (val === "Yes") {
                                            let panel =
                                              parent.querySelector(".panel");

                                            if (panel?.style?.maxHeight) {
                                              // panel.style.maxHeight = null;
                                            } else {
                                              panel.style.maxHeight =
                                                panel.scrollHeight + "px";
                                            }
                                          }

                                          if (val === "No") {
                                            let panel =
                                              parent.querySelector(".panel");
                                            updated_data[
                                              toolIndex
                                            ].loading = true;
                                            removeAllIntegrations({
                                              rowIndex: toolIndex,
                                              technologyEnum:
                                                updated_data[toolIndex]
                                                  ?.TechnologyEnum,
                                            });
                                            if (panel?.style?.maxHeight) {
                                              panel.style.maxHeight = null;
                                            } else {
                                              // panel.style.maxHeight =
                                              //   panel.scrollHeight + "px";
                                            }
                                          }

                                          setToolData(updated_data);
                                        }}
                                        data={[
                                          { id: 1, category: "Yes" },
                                          { id: 0, category: "No" },
                                        ]}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {(toolIndex % 2 !== 0 || true) && (
                                <div className={`align-items-center  bg-hover-row w-100 px-4  py-3 cyber-score-table-row w-100 ${isViewOnly ? 'cursor-default' : viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ? 'cursor-default' : 'pointer'
                                  }`}>
                                  {/* first Column */}
                                  <div className="d-flex align-items-center  ">
                                    <div className="mr-2">
                                      <AttestationPopUp>
                                        <img
                                          alt=""
                                          src={
                                            Attestation_Icon_Mappings[
                                            tool?.ScoreAttestationRow
                                              ?.ScoreAttestation
                                            ]
                                          }
                                          className=""
                                        />
                                      </AttestationPopUp>
                                    </div>
                                    <div className="d-flex flex-column">
                                      <div className="d-flex align-items-baseline ">
                                        <p className="f-500 m-0">
                                          {tool?.TechnologyTitle}
                                        </p>
                                      </div>
                                      {tool?.ShowSelectedTool ? (
                                        <>
                                          {tool?.SelectedTool_List?.map(
                                            (
                                              connectedTool,
                                              connectedToolIndex
                                            ) => {
                                              return (
                                                <div className="d-flex align-items-center ">
                                                  <img
                                                    alt=""
                                                    width={16}
                                                    height={16}
                                                    className="rounded-full"
                                                    src={integrationUtils.getImagePath(connectedTool?.obj_IntegrationId, connectedTool?.ToolIcon)}
                                                    onError={({
                                                      currentTarget,
                                                    }) => {
                                                      currentTarget.onerror =
                                                        null; // prevents looping
                                                      currentTarget.src =
                                                        "/images/attack-surface/generic2.svg";
                                                    }}
                                                  />
                                                  <div className="d-flex align-items-center mx-2 f-12 f-500 f-black">
                                                    {connectedTool?.ToolName}
                                                  </div>
                                                  <div className="d-flex align-items-center f-12 f-500  ">
                                                    {!connectedTool?.IsConnected && (
                                                      <div
                                                        className="f-blue mr-2"
                                                        onClick={(e) => {
                                                          if (!isViewOnly || !viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard")) {
                                                            e.stopPropagation();
                                                            setSelectedIntegrationId(
                                                              connectedTool?.obj_IntegrationId
                                                            );
                                                            setShowMspConnectModal(
                                                              true
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        {/* Connect{" "} */}
                                                      </div>
                                                    )}
                                                    {/* <div
                                                  className="f-darkgrey"
                                                  onClick={
                                                    (e) => {}
                                                    // ToggleAccordion(e, true)
                                                  }
                                                >
                                                  Change
                                                </div> */}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <div className="f-12 f-darkgrey ">
                                          {/* {tool?.TechnologyQuestion} */}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* Second column */}
                                  <div className="f-black">
                                    {tool?.ScoreAttestationRow?.Narrative}
                                  </div>

                                  {/* Thhird column */}
                                  <div className="text-align-end mr-2">
                                    {/* {tool?.ImpactOnScore} */}
                                    {/* <img
                                src="/images/big-chevron-down.svg"
                                alt=""
                                className="rowDownArrow mr-2 ml-2"
                                
                                /> */}
                                  </div>
                                </div>
                              )}
                              <div
                                className="panel"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {/* <div className="tool-listing-wrapper ml-2">
                              {
                                <div className="f-12 f-500 f-grey ml-3 mt-2">
                                  Select all that apply
                                </div>
                              }

                              {tool?.IntegrationList?.map(
                                (option, optionIndex) => {
                                  const IsToolChanging = false;

                                  let ToolInSelectionIndex =
                                    tool?.dummy_SelectedTool_List?.findIndex(
                                      (item) =>
                                        item?.obj_IntegrationId ===
                                        option?.obj_IntegrationId
                                    );
                                  return (
                                    <div
                                      className="d-flex align-items-center mt-2 pointer w-fit-content"
                                      onClick={(e) => {
                                        let updated_data = [...toolData];
                                        updated_data[toolIndex].IntegrationList[
                                          optionIndex
                                        ].loading = true;
                                        if (ToolInSelectionIndex > -1) {
                                          // If Tool Exist in selectedTool list
                                          updated_data[
                                            toolIndex
                                          ].dummy_SelectedTool_List.splice(
                                            ToolInSelectionIndex,
                                            1
                                          );
                                          disconnectIntegration(
                                            option?.obj_IntegrationId
                                          );
                                        } else {
                                          // If tool is not in selected Tool list
                                          updated_data[
                                            toolIndex
                                          ].dummy_SelectedTool_List = [
                                            ...updated_data[toolIndex]
                                              .dummy_SelectedTool_List,
                                            {
                                              obj_IntegrationId:
                                                option?.obj_IntegrationId,
                                              ToolName: option?.IntegrationName,
                                              ToolSelected: true,
                                            },
                                          ];
                                          addNewIntegration(
                                            updated_data[toolIndex]
                                              .TechnologyEnum,
                                            option?.obj_IntegrationId,
                                            option?.IntegrationName
                                          );
                                        }
                                        setToolData(updated_data);
                                      }}
                                    >
                                      <div
                                        className="checkbox-margin ml-3 mb-2"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Form.Group
                                          controlId={`card ${toolIndex}${optionIndex}`}
                                          className={`round-checkbox blue-checkbox checkboxstyle mb-3 ${
                                            IsToolChanging
                                              ? "cursor-default"
                                              : "radio-shadow"
                                          }`}
                                        >
                                          <Form.Check
                                            type={"checkbox"}
                                            checked={
                                              ToolInSelectionIndex > -1
                                                ? true
                                                : false
                                            }
                                            name={
                                              `check ` +
                                              toolIndex +
                                              "" +
                                              optionIndex
                                            }
                                            label=""
                                            disabled={IsToolChanging}
                                            onChange={() => {}}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              let updated_data = [...toolData];
                                              updated_data[
                                                toolIndex
                                              ].IntegrationList[
                                                optionIndex
                                              ].loading = true;
                                              if (ToolInSelectionIndex > -1) {
                                                // If Tool Exist in selectedTool list
                                                updated_data[
                                                  toolIndex
                                                ].dummy_SelectedTool_List.splice(
                                                  ToolInSelectionIndex,
                                                  1
                                                );
                                                disconnectIntegration(
                                                  option?.obj_IntegrationId
                                                );
                                              } else {
                                                // If tool is not in selected Tool list
                                                updated_data[
                                                  toolIndex
                                                ].dummy_SelectedTool_List = [
                                                  ...updated_data[toolIndex]
                                                    .dummy_SelectedTool_List,
                                                  {
                                                    obj_IntegrationId:
                                                      option?.obj_IntegrationId,
                                                    ToolName:
                                                      option?.IntegrationName,
                                                    ToolSelected: true,
                                                  },
                                                ];
                                                addNewIntegration(
                                                  updated_data[toolIndex]
                                                    .TechnologyEnum,
                                                  option?.obj_IntegrationId,
                                                  option?.IntegrationName
                                                );
                                              }
                                              setToolData(updated_data);
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                      <img
                                        alt=""
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          borderRadius: "50%",
                                        }}
                                        src={`/images/attack-surface/integrations/${option?.obj_IntegrationId}.webp`}
                                        className="ml-3 mr-2"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/attack-surface/generic2.svg";
                                        }}
                                      />
                                      <div className="f-500">
                                        {" "}
                                        {option?.IntegrationName}
                                      </div>
                                      <div>
                                        {option?.loading ? (
                                          <Spinner
                                            animation="border"
                                            variant="secondary"
                                            size="sm"
                                            className="mx-2"
                                            style={{
                                              width: "12px",
                                              height: "12px",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                              {!tool?.addNewTool ? (
                                <div
                                  className="d-flex align-items-center f-grey f-12 ml-4 pointer mt-2 mb-4"
                                  onClick={(e) => {
                                    let updated_data = [...toolData];
                                    updated_data[toolIndex].addNewTool = true;
                                    setToolData(updated_data);
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/attack-surface/add-tool-icon.svg"
                                    className="mr-2"
                                  />
                                  Add
                                </div>
                              ) : (
                                <div className="d-flex align-items-center mb-3 mt-2">
                                  <div className="checkbox-margin ml-3 mb-2">
                                    <Form.Group
                                      controlId={`card ${toolIndex}  `}
                                      className={`round-checkbox blue-checkbox checkboxstyle mb-3 radio-shadow`}
                                    >
                                      <Form.Check
                                        type={"checkbox"}
                                        checked={true ?? tool?.newToolSelected}
                                        name={`check `}
                                        label=""
                                        // onChange={(e) => {
                                        //   let updated_data = [...toolData];
                                        //   updated_data[toolIndex].newToolSelected =
                                        //     e.target.checked;
                                        //   setToolData(updated_data);
                                        // }}
                                      />
                                    </Form.Group>
                                  </div>
                                  <img
                                    alt=""
                                    width={24}
                                    height={24}
                                    src="/images/attack-surface/generic2.svg"
                                    className="ml-3 mr-2"
                                  />
                                  <div className="tool-creator-field d-flex align-items-center ">
                                    <input
                                      type="text"
                                      value={tool?.newToolName}
                                      onChange={(e) => {
                                        let updated_data = [...toolData];
                                        updated_data[toolIndex].newToolName =
                                          removeWhiteSpace(e.target.value);
                                        setToolData(updated_data);
                                      }}
                                      autoFocus
                                    />
                                    <Button
                                      className=""
                                      onClick={(e) => {
                                        if (tool?.newToolName === "") return;
                                        let updated_data = [...toolData];
                                        updated_data[
                                          toolIndex
                                        ].addingNewTool = true;
                                        setToolData(updated_data);
                                        addNewIntegration(
                                          updated_data[toolIndex].TechnologyEnum,
                                          0,
                                          tool?.newToolName,
                                          true ?? tool?.newToolSelected
                                        );
                                      }}
                                    >
                                      {tool?.addingNewTool ? (
                                        <span className="d-inline-flex align-items-center">
                                          <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                            className="mx-1"
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                            }}
                                          />
                                          <span className="d-inline-flex align-items-center">
                                            Add
                                          </span>
                                        </span>
                                      ) : (
                                        "Add"
                                      )}
                                    </Button>
                                  </div>
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-cross.svg"
                                    className="ml-2 pointer"
                                    onClick={() => {
                                      let updated_data = [...toolData];
                                      updated_data[toolIndex].addNewTool = false;
                                      setToolData(updated_data);
                                    }}
                                  />
                                </div>
                              )}
                            </div> */}
                              </div>
                            </div>
                          );
                        }
                      )}

                      <div className="border-item f-12 f-500 f-grey p-2 text-align-end">
                        <div className="p-2">
                          <span className="f-grey">
                            Your {cyberThreatLevelData?.ScoreText} is
                          </span>
                          <span className="f-black f-500 mx-2">
                            {cyberThreatLevelData?.ScoreValue}{" "}
                          </span>
                          <span
                            className=""
                            style={{
                              color: cyberThreatLevelData?.ScoreRatingColour,
                            }}
                          >
                            {cyberThreatLevelData?.ScoreRating}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="cyberCompliance">
                  {show && (
                    <CyberComplianceForGroupModal 
                    clientId={clientId} 
                    passedOptionEnum99={passedOptionEnum99}
                    setShowAttestationIcons={setShowAttestationIcons}
                    showAttestationIcons={showAttestationIcons}
                    isSaas={isSaas}
                    isViewOnly={isViewOnly}
                    setDisplaySuggestModal={setDisplaySuggestModal}
                  />
                  )}
                </Tab.Pane>
              </Tab.Content>
            )}
          </SlidingModal.Body>
        </Tab.Container>
        {false && (
          <>
            <SlidingModal.Footer className="p-2 position-sticky w-100 border-top">
              <div className="footer d-flex justify-content-end">
                <Button
                  className="hg-cancel-btn f-500"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={`hg-blue-btn f-500 ml-2`}
                  onClick={async () => {
                    if (defaultSelectedTab === "cyberScore") {
                      let errors = await checkValidationForCyberScore();

                      if (errors > 0) {
                        let error_element =
                          document.getElementsByClassName(`input-error`);
                        if (error_element?.length > 0) {
                          error_element[0].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest",
                          });
                        }
                      } else {
                        setDefaultSelectedTab("cyberCompliance");
                      }
                    } else {
                    }
                  }}
                // disabled={}
                >
                  {defaultSelectedTab === "cyberScore" && <>Continue</>}
                  {defaultSelectedTab === "cyberCompliance" && (
                    <>
                      {/* {saving ? (
                <div className="d-flex align-items-center">
                  <WhiteLoader />
                  <span className="d-flex align-items-center">Saving...</span>
                </div>
              ) : (
                "Save"
              )} */}
                      Save
                    </>
                  )}
                </Button>
              </div>
            </SlidingModal.Footer>
          </>
        )}
      </SlidingModal>
    </>
  );
};

export default CyberGroupModal;

import { useState } from "react";
import { Button } from "react-bootstrap";

const NumberRangeFilterType = ({
  title,
  value,
  onFilterChange
}) => {
  const [fromValue, setFromValue] = useState(value?.value?.[0]);
  const [toValue, setToValue] = useState(value?.value?.[1]);
  const selectedFromLessThanTo = !isNaN(fromValue) && !isNaN(toValue) && +fromValue > +toValue;
  return (
    <div style={{ maxWidth: '200px' }}>
      <div className="f-12 f-500 f-darkgrey my-2 ml-2">
        {title}
      </div>
      <div className="d-flex align-items-center ">
        <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border d-flex align-items-center">
          <div className="f-darkgrey f-500 flex-grow-1 mt-1px">
            {value?.prefix}&nbsp;
          </div>
          <input
            type="number"
            className="border-none bg-transparent w-100 flex-grow-1"
            placeholder="From"
            value={fromValue}
            onChange={(e) => {
              setFromValue(e.target.value);
            }}
            autoFocus
          />
        </div>
        <div className="mx-1">-</div>
        <div className={`p-1 bg-grey radius-4 highlight-input-with-grey-border d-flex align-items-center ${selectedFromLessThanTo ? "error" : ""}`}>
          <div className="f-darkgrey f-500 flex-grow-1 mt-1px">
            {value?.prefix}&nbsp;
          </div>
          <input
            type="number"
            className="border-none bg-transparent w-100 flex-grow-1"
            placeholder="To"
            value={toValue}
            onChange={(e) => {
              setToValue(e.target.value);
            }}
            onBlur={(e) => {
              return;
            }}
          />
          {!selectedFromLessThanTo && fromValue && toValue && (
            <Button 
              className="btn-blue-imp px-2 p-0"
              onClick={e => {
                e.stopPropagation();
                onFilterChange(fromValue, toValue);
              }}
            >
              <img
                alt=""
                width={11}
                className=" pointer"
                src="/images/settings-v2/yes-tick-task.svg"
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NumberRangeFilterType;

import React, { useState } from "react";
import { Row, Col, Modal, Button, Spinner } from "react-bootstrap";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./rewards.scss";
import {
  CallToast,
  CustomRightSideToastForSendReport,
  RightSideToast,
} from "../../PriceCalculator/modals/CustomToast";
import { getRequestOptions, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useHistory } from "react-router-dom";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
import { ROUTE_PATHS } from "../../../constants/app.constants";
const SendReportModal = ({
  show,
  hideModal,
  title,
  rewardId,
  refreshData,
  rewardData,
}) => {
  const { authTokens } = useAuth();
  const history= useHistory()
  const [inviteEmail, setInviteEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);

  const [post, setPost] = useState({
    id: 12,
    category: "CEO",
  });

  async function previewReport() {
    setPreviewLoader(true);
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/PreviewReportPDF?emailReportTypeInt=1`,
      getRequestOptions(authTokens, "GET")
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.mr.Success == true) {
          TrackUser("Preview report clicked");
          setPreviewLoader(false);
          window.open(data.FileURL);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setPreviewLoader(false);
        }
      })
      .catch((error) => {
        CallToast("Something went wrong", false, false, "v2style");
        console.log(error);
      });
  }
  async function SendReport() {
    setSending(true);
    const accessKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : "null";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var inputs = new URLSearchParams();
    inputs.append("RewardId", rewardId);
    inputs.append("RewardType", 0);
    inputs.append("EmailAddress", inviteEmail);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CompleteReward?accessKey=${accessKey}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.mr.Success) {
          updateAccessKeyInLocalStorage(data?.mr?.Message)
         
          refreshData();
          setSending(false);
          setSent(true);
          // CallToast("Report sent successfully", false, false, "v2style");
          

          setTimeout(() => {
            setSent(false);
            setSendDisabled(true);
          }, 1000);
          setTimeout(() => {
            hideModal();
            setTimeout(() => {
              // RightSideToast(
              //   rewardData.AwardAmount,
              //   `Thanks for sending report to ${rewardData?.Name.slice(
              //     -3
              //   )}, we have credited to your Pro Services account.`
              // );
              CustomRightSideToastForSendReport(
                "+ £5 Congratulations!",
                `Thanks for sending report to ${rewardData?.Name.slice(-3)}, we have credited to your Pro Services account.`,
                `Add to your Team`,
                () => {
                  history.push(`${ROUTE_PATHS.saas.settings.team}?openTeamModal=true`);
                }
              );
              setInviteEmail("");
            }, 100);
          }, 1500);
        } else {
          setSending(false);
          CallToast("Something went wrong", false, false, "v2style");
          setSent(false);
        }
      });
  }
  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="send-report-modal"
      className=" "
      aria-labelledby="send-report-modal"
      centered
      onHide={hideModal}
      //   scrollable
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Send Report
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={12} className="px-4">
            <div className="heading my-2">{title}</div>
            <div className="subtitle my-2">
              Enter your {title ? title?.slice(-3) : "CEO"}'s email below to
              send them your report.
            </div>

            <Button
              className="preview-btn d-flex align-items-center"
              onClick={() => {
                previewReport();
              }}
            >
              <div>Preview Report</div>
              {previewLoader ? (
                <Spinner size="sm" className="mr8px- ml-2" animation="border" />
              ) : (
                <img
                  alt=""
                  src="/images/settings-v2/preview-icon.svg"
                  className="ml-2"
                />
              )}
            </Button>
          </Col>

          <Col xs={12}>
            <div className="email-item-wrapper mt-4 mb-3">
              <div className="label-input mt-3">Email</div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center email-field-wrapper ">
                  <input
                    type="email"
                    className="email-input"
                    placeholder="Enter Email"
                    value={inviteEmail}
                    onChange={(e) => {
                      const re =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                      if (!re.test(e.target.value)) {
                        setSendDisabled(true);
                      } else {
                        setSendDisabled(false);
                      }

                      setInviteEmail(e.target.value);
                    }}
                  />
                  <div
                    className=""
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    <CompanySettingsDropdown
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={post}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        setPost({ id, category: val });
                      }}
                      data={[
                        { id: 12, category: "CEO" },
                        { id: 112, category: "CTO" },
                        { id: 113, category: "CFO" },
                      ]}
                    />
                  </div>
                </div>
                <Button
                  className={`d-flex align-items-center copy-link-btn ${
                    sendDisabled ? "disabled-btn" : ""
                  }  ${sending && "sending-btn"} ${sent && "sent-btn"}   `}
                  disabled={sending}
                  onClick={() => {
                    const re =
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (!inviteEmail || !re.test(inviteEmail)) {
                      CallToast(
                        "Please enter a valid email",
                        false,
                        false,
                        "v2style"
                      );
                      return false;
                    }
                    SendReport();
                  }}
                >
                  {!sending && !sent && <div>Send Report</div>}
                  {sending && (
                    <div>
                      <span>
                        <Spinner
                          size="sm"
                          className="mr8px"
                          animation="border"
                        />{" "}
                        Sending...
                      </span>
                    </div>
                  )}
                  {sent && <div>Sent!</div>}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SendReportModal;

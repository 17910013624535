import { useEffect, useRef, useState } from "react";
import { useAuth } from "../context/Auth";
import { getAPIData } from "../../Utils/commonApi";
import "./marketplace.scss"
import { Button } from "react-bootstrap";
import AddSellRequestModal from "../MSPv2/mspSell/modals/AddSellRequestModal";
import useRespondTo from "../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../constants/window.constants";

const MarketplaceView = ({
    marketplaceId = 0,
}) => {
    const { authTokens } = useAuth();
    const scrollContainerRef = useRef(null);
    const [marketplaceData, setMarketplaceData] = useState({});
    const [showAddRequestModal, setShowAddRequestModal] = useState(false);
    const isMobile = useRespondTo(WindowBreakpoints.md);


    useEffect(() => {
        getMarketplaceInfo();
    }, [])

    async function getMarketplaceInfo() {
        await getAPIData(977, authTokens, {
            optionEnum1: marketplaceId ?? 0
        })
            .then((response) => {
                if (response?.mr?.Success) {
                    setMarketplaceData(response);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <div className="preview-block">
            <div className="f-600 f-14 f-black">Preview</div>

            <div className="d-flex align-items-center mt-3 pb-4 justify-content-between border-bottom">
                <div className="d-flex align-items-center ">
                    {
                        marketplaceData?.ProductIcon ? <img
                            alt=""
                            className="radius-8 mr-3"
                            width={80}
                            height={80}
                            src={marketplaceData?.ProductIcon}
                        /> : <div className="bg-dark-greyish p-4 radius-8 mr-3">
                            <img
                                alt=""
                                className="radius-12"
                                width={isMobile ? 30 : 80}
                                height={isMobile ? 30 : 80}
                                src={marketplaceData?.ProductIcon}
                            />
                        </div>
                    }
                    <div>
                        <div className={`f-600 f-black ${isMobile ? 'f-18' : 'f-24'}`}>{marketplaceData?.ProductName}</div>
                        <div className="f-400 f-14 f-darkgrey my-2">{marketplaceData?.ShortDescription}</div>
                        {
                            marketplaceData?.ProductURL &&
                            <div className="d-flex align-items-center pointer"
                                onClick={() => {
                                    window.open(marketplaceData?.ProductURL, "_blank");
                                }}
                            >
                                <div className="f-500 f-14 txt-blue">Learn more</div>
                                <img
                                    alt=""
                                    className="ml-2"
                                    src="/images/supply-chain/blue-redirect.svg"
                                />
                            </div>
                        }
                    </div>
                </div>

                <Button className="hg-blue-btn"
                    onClick={() => {
                        setShowAddRequestModal(true);
                    }}
                >
                    Request
                </Button>
            </div>



            <div className="d-flex align-items-center mt-3">
                <div className="f-500 f-12 f-grey bg-greyish radius-8 px-2 mr-2"
                    style={{
                        width: 'fit-content'
                    }}
                >
                    {marketplaceData?.Category_Selected?.Name}
                </div>
                <div className="f-500 f-12 f-grey bg-greyish radius-8 px-2 mr-2"
                    style={{
                        width: 'fit-content'
                    }}
                >
                    {marketplaceData?.SubCategory_Selected?.Name}
                </div>
            </div>


            {
                marketplaceData?.BannersUploaded_List?.length > 0 ?
                    <div>
                        <div
                            className="d-flex"
                            id="scroll-container"
                            ref={scrollContainerRef}
                            style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}
                        >
                            {
                                marketplaceData?.BannersUploaded_List?.map((media) => {
                                    return (
                                        <div className="mt-3 radius-8 mr-3">
                                            <img
                                                alt=""
                                                width={isMobile ? 280 : 320}
                                                height={isMobile ? 130 : 180}
                                                className=""
                                                src={media?.FileURL}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : <div className="f-400 f-12 greyText2 px-5 py-5 d-flex align-items-center justify-content-center">No media yet</div>
            }

            <div className="mt-4 border-bottom pb-3">
                <div className="f-500 f-16 f-black">Overview</div>
                <div className="f-400 f-14 greyText2 mt-2">{marketplaceData?.Description}</div>
            </div>


            <div className="border-bottom pb-4">
                <div className="f-500 f-16 mt-4 f-black">Pricing</div>
                <div className={`d-flex mt-2 ${isMobile ? 'justify-content-center w-100' : ''}`}>
                    <div className={`${isMobile ? '' : 'd-flex align-items-center w-100'} pricing-list`}>
                        {
                            marketplaceData?.AllMarketPlacePricing_List?.map((item) => {
                                return (
                                    <div className="border-gray px-3 pb-4 radius-8 pricing-list-item "
                                        style={{
                                            minHeight: '246px',
                                            minWidth: '240px',
                                            marginTop: isMobile ? '16px' : '',
                                            marginRight: isMobile ? '' : '40px'
                                        }}
                                    >
                                        <div className="f-500 f-16 f-black pt-4 pb-1">{item?.PricingOptionName}</div>
                                        <div className="f-400 f-12 f-darkgrey">One-time purchase</div>
                                        <div className="d-flex align-items-center my-4">
                                            <div className="f-400 f-24 f-black">{item?.CurrencyCode_Selected?.CurrencySymbol}{item?.Cost}/{item?.MeasurementOfUnit_Selected?.DDLText?.replace("Per ", "")}</div>
                                        </div>
                                        <div className="f-400 f-12 f-darkgrey">Tax - {item?.TaxRates_Selected?.TaxName}</div>
                                        <Button
                                            className="hg-blue-btn mr-2 w-100 mt-3"
                                            onClick={() => {
                                                setShowAddRequestModal(true);
                                            }}
                                        >
                                            Request
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>


            <div className="border-bottom pb-4">
                <div className="f-500 f-16 mt-4 f-black">Resources</div>
                <div className={`w-100 mt-3 flex-wrap resources-list ${isMobile ? '' : 'd-flex '}`}>
                    <div className="d-flex align-items-center"
                        style={{
                            marginRight: '30px'
                        }}
                    >
                        <img width={15} className="mr-2" src="/images/icons/fileIcon.svg" alt="" />
                        <div>
                            <div className="f-400 f-14 f-black">Terms and conditions</div>
                            {
                                marketplaceData?.TermAndConditionUploaded ? <div className="f-400 f-14 txt-blue">{marketplaceData?.TermAndConditionUploaded?.FileNameOrLink}</div> : <div className="f-400 f-12 f-darkgray">No data yet</div>
                            }
                        </div>
                    </div>
                    {
                        marketplaceData?.ResourceUploaded_List?.map((resItem) => {
                            return (
                                <div className="d-flex align-items-center"
                                    style={{
                                        marginRight: '30px',
                                        marginTop: isMobile ? '8px' : ''
                                    }}>
                                    <img width={15} className="mr-2" src={resItem?.IsLink ? '/images/msp/msp-cert-link-icon.svg' : '/images/fileIcon-dark.svg'} alt="" />
                                    <div className="f-400 f-14 txt-blue">{resItem?.FileNameOrLink}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>


            <div className="">
                <div className="f-500 f-16 mt-4 f-black">Related Services</div>
                <div className="d-flex w-100 mt-3">
                    {
                        marketplaceData?.CrossSellProduct_Selected?.map((item) => {
                            return (
                                <div className="d-flex align-items-center"
                                    style={{ marginRight: '30px' }}>
                                    <div className="d-flex align-items-center justify-content-center mr-2 radius-4 bg-dark-greyish"
                                        style={{
                                            width: '24px',
                                            height: '24px'
                                        }}
                                    >
                                        <img
                                            alt=""
                                            width={12}
                                            height={12}
                                            className=""
                                            src={item?.Icon ?? '/images/img-icon.svg'}
                                        />
                                    </div>
                                    <div>
                                        <div className="f-500 f-14 f-black">{item?.Name}</div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <AddSellRequestModal
                show={showAddRequestModal}
                marketplaceId={marketplaceId}
                customerId={0}
                hideModal={() => {
                    setShowAddRequestModal();
                }}
            />
        </div>
    );
};

export default MarketplaceView;


import React, { useEffect, useState } from "react";
import "./mspAttackSurface.scss";
import moment from "moment";
import { HGHighlightFuzzyText, HGHighlightText } from "../../App/App";
import { HGPopUp } from "../../AttackSurface/KPIpopup/KPIpopup";
import { GreyLoader } from "../../Theme/APILoaders";
import integrationUtils from "../../../Utils/integration.utils";
import SubPaymentInvoiceIcon from "../../icons/sub-payment-invoice.icon";
import SortingHeaderComponent from "../../SearchFilterComponent/SortingHeaderComponent";
const TechSpendListView = ({
  listViewData,
  searchString,
  onOpenInvoice = () => {},
  setListViewData,
  getListViewData,
  UpdateListViewSorting,
  sortData,
  setSortData
}) => {
  useEffect(() => {
    if (!sortData?.sorting) return;
    const isSorting = listViewData?.SortVisibility?.find(
      (item) => item.ColumnName === sortData?.sortItem
    )?.sorting;
    if (isSorting) return;
    setSortData({
      ...sortData,
      sorting: false,
    })
  }, [sortData, listViewData?.SortVisibility]);

  return (
    <>
      {listViewData?.InvoiceList?.length > 0 && (
        <div className="tech-spend-list-view border-parent w-100 hide-scrollbar">
          <div className="border-item p-2 d-flex d-flex align-items-center justify-content-between">
            {listViewData?.SortVisibility?.filter(
              (item) => item.ColumnVisibility === true
            )
              ?.sort((a, b) => a.ColumnSortOrder - b.ColumnSortOrder)
              ?.map((headerItem, headerItemIndex) => {
                return (
                  <div
                    key={headerItemIndex}
                    className={`table-col f-12 f-500 f-grey pointer ${
                      headerItemIndex === 0 && "pl-2"
                    } `}>
                    <SortingHeaderComponent
                      headerName={headerItem?.ColumnName}
                      headerKey={headerItem?.ColumnName}
                      sortData={sortData}
                      onSortClick={() => {
                        console.log('AAAAAAAAAAAAAAAAA')
                        let updated_list= {...listViewData}
                        let index = listViewData?.SortVisibility?.findIndex(obj => obj.ColumnName === headerItem?.ColumnName);

                        updated_list.SortVisibility  =  listViewData?.SortVisibility?.map((obj, i) => {
                          if (i === index) {
                              return { ...obj, sorting: true };
                          }
                          return obj;
                        })
                        setListViewData(updated_list)
                        getListViewData({
                          sortOrder:Number(!listViewData?.sortedBy?.sortOrder),
                          sortColumn: headerItem?.ColumnName,
                          stopLoader:true
                        })
                        setSortData({
                          sortItem: headerItem?.ColumnName,
                          sortOrder: Number(!sortData?.sortOrder),
                          sorting: true,
                        });
                      }}
                      updatedHeaderList={() => {
                        let updated_data = [...listViewData?.SortVisibility].map((item) => {
                          if (item.ColumnName === headerItem?.ColumnName) {
                            return {
                              ...item,
                              ColumnVisibility: false,
                            };
                          }
                          return item;
                        });
                        UpdateListViewSorting(updated_data);
                        setListViewData({
                          ...listViewData,
                          menuSorting: updated_data
                        });
                      }}
                    />
                  </div>
                );
              })}
          </div>
          {listViewData?.InvoiceList?.map((tableItem, tableItemIndex) => {
            return (
              <div
                key={tableItemIndex}
                className="border-item p-3  d-flex align-items-center justify-content-between"
                onClick={() => {
                  onOpenInvoice(tableItem);
                }}
              >
                {listViewData?.SortVisibility?.filter(
                  (item) => item.ColumnVisibility === true
                )
                  ?.sort((a, b) => a.ColumnSortOrder - b.ColumnSortOrder)
                  ?.map((headerItem, headerItemIndex) => {
                    return (
                      <>
                        {headerItem?.ColumnName === "Spend Title" && (
                          <div className="table-col f-500">
                            {tableItem?.IntegrationID > 0 ? (
                              <img
                                src={integrationUtils.getImagePath(tableItem?.IntegrationID, tableItem?.ToolIcon)}
                                alt=""
                                width={16}
                                height={16}
                                className="mr-2 rounded-full"
                              />
                            ) : tableItem?.isSubscription ? (
                              <SubPaymentInvoiceIcon
                                width="16px"
                                height="16px"
                                className="d-inline-block mr-2"
                              />
                            ) : (
                              <img
                                src="/images/msp/msp-spend-invoice-icon.svg"
                                alt=""
                                width={16}
                                height={16}
                                className="mr-2"
                              />
                            )}

                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  tableItem?.SpendTitle,
                                  searchString
                                ),
                              }}
                            />
                          </div>
                        )}
                        {headerItem?.ColumnName === "3rd Party Invoice#" && (
                          <div className="table-col f-500">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  tableItem?.ThirdPartyInvoice ?? "",
                                  searchString
                                ),
                              }}
                            />
                          </div>
                        )}
                        {headerItem?.ColumnName === "Supplier" && (
                          <div className="table-col f-500">
                            {tableItem?.Supplier === 0 ? (
                              ""
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: HGHighlightText(
                                    tableItem?.Supplier ?? "",
                                    searchString
                                  ),
                                }}
                              />
                            )}
                          </div>
                        )}
                        {headerItem?.ColumnName === "Invoice Date" && (
                          <div className="table-col f-500">
                            <span 
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  moment(tableItem?.invoiceDate).format(
                                    "DD/MM/YYYY"
                                  ),
                                  searchString
                                ),
                              }}
                            />
                          </div>
                        )}

                        {headerItem?.ColumnName === "Categories" && (
                          <div className="table-col  ">
                            {" "}
                            {tableItem?.SpendCats?.SpendCatList?.map((item) => {
                              return (<HGPopUp
                                maxWidth={"300px"}
                                content={<>
                                 <div className="d-flex align-items-center   p-3">
                                  {" "}
                                  <div
                                    className="mr-2  color-dot"
                                    style={{
                                      background: item?.ColorCode,
                                    }}
                                  />
                                  <div className=" ">
                                    <span 
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          item?.CategoryName,
                                          searchString
                                        ),
                                      }}
                                    />

                                  </div>
                                </div>
                                </>}
                                
                                >
                                <div className="d-flex align-items-center cat-subcat-ship max-w-100">
                                  {" "}
                                  <div
                                    className="mr-2  color-dot"
                                    style={{
                                      background: item?.ColorCode,
                                    }}
                                  />
                                  <div className="text-ellipsis">
                                  <span 
                                    dangerouslySetInnerHTML={{
                                      __html: HGHighlightText(
                                        item?.CategoryName,
                                        searchString
                                      ),
                                    }}
                                  />

                                  </div>
                                </div>
                                </HGPopUp>
                              );
                            })}
                            {tableItem?.SpendCats?.SpendCatList?.length > 3 && (
                              <div className="cat-subcat-ship">
                                +
                                {tableItem?.SpendCats?.SpendCatList?.length - 3}{" "}
                              </div>
                            )}
                          </div>
                        )}
                        {headerItem?.ColumnName === "Subcategories" && (
                          <div className="table-col  ">
                            {" "}
                            {tableItem?.SpendSubCats?.SpendCatList?.map(
                              (item) => {
                                return (<HGPopUp
                                  maxWidth={"300px"}
                                  content={<>
                                   <div className="d-flex align-items-center  p-3">
                                    {" "}
                                    <div
                                      className="mr-2  color-dot"
                                      style={{
                                        background: item?.ColorCode,
                                      }}
                                    />
                                    <div className=" ">
                                    <span 
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          item?.CategoryName,
                                          searchString
                                        ),
                                      }}
                                    />
  
                                    </div>
                                  </div>
                                  </>}
                                  
                                  >
                                  <div className="d-flex align-items-center cat-subcat-ship  max-w-100">
                                    {" "}
                                    <div
                                      className="mr-2  color-dot"
                                      style={{
                                        background: item?.ColorCode,
                                      }}
                                    />{" "}
                                    <div className="text-ellipsis">

                                    <span 
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          item?.CategoryName,
                                          searchString
                                        ),
                                      }}
                                    />
                                    </div>
                                  </div>
                                  </HGPopUp>
                                );
                              }
                            )}
                            {tableItem?.SpendSubCats?.SpendCatList?.length >
                              3 && (
                              <div className="cat-subcat-ship">
                                +
                                {tableItem?.SpendSubCats?.SpendCatList?.length -
                                  3}{" "}
                              </div>
                            )}
                          </div>
                        )}
                        {headerItem?.ColumnName === "Amount" && (
                          <div className="table-col f-500 pl-2">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  `${tableItem?.Amount_Text}` ?? "",
                                  searchString
                                ),
                              }}
                            />
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}

      {listViewData?.InvoiceList?.length === 0 && searchString && (
        <div
          className="mx-auto w-50 d-flex align-items-center justify-content-center flex-column"
          style={{
            marginTop: "4rem",
          }}
        >
          <img
            src="/images/attack-surface/search-icon.svg"
            alt=""
            className=""
          />
          <div className="f-500 f-grey mt-3">
            No results for “{searchString}”{" "}
          </div>
          <div className="f-500 f-darkgrey mt-2">
            Try to search another request or rest filters{" "}
          </div>
        </div>
      )}
      {listViewData?.InvoiceList?.length === 0 && !searchString && (
        <div
          className="mx-auto w-50 d-flex align-items-center justify-content-center flex-column"
          style={{
            marginTop: "4rem",
          }}
        >
          <div className="f-600 f-18 f-grey mt-3">No Data found !!</div>
          {/* <div className="f-500 f-darkgrey mt-2"> </div> */}
        </div>
      )}
    </>
  );
};

export default TechSpendListView;

import { Button, Spinner } from "react-bootstrap";
import "./sections.styles.scss";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import AuthTextInput from "../auth-text-input.component";
import KeyIcon from "../../icons/key.icon";
import AuthChangeInput from "../auth-change-input.component";
import AvatarIcon from "../../icons/avatar.icon";
import { ROUTE_PATHS } from "../../../constants/app.constants";

const UserPasswordSection = ({ 
  error,
  setError,
  loading,
  passwordError,
  setPasswordError,
  defaultEmail,
  setFormState,
  loginUser,
  setDisableCredFieldsHL,
  disableCredFieldsHL,
}) => {
  const [password, setPassword] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [shownPassword, setShownPassword] = useState(false);

  useEffect(() => {
    if (location?.state?.newEmail) {
      setPassword(location?.state?.newPassword);
    }
  }, []);

  return (
    <div className="user-email-section d-flex flex-column" style={{ gap: '16px' }}>
      <AuthChangeInput
        value={defaultEmail}
        onChange={() => {
          setFormState(0);
        }}
        icon={(
          <AvatarIcon />
        )}
        className="rounded"
        valueStyles={{ marginLeft: '4px' }}
      />
      <div>
        <form
          onSubmit={e => loginUser(e, defaultEmail, password)}
        >
          <AuthTextInput 
            error={error || passwordError}
            type={shownPassword ? "text" : "password"}
            placeholder="Enter your password"
            onChange={(event) => {
              setPassword(
                event.target.value
              );
              setPasswordError("");
              setError("");
              setDisableCredFieldsHL(false);
            }}
            value={password}
            icon={(
              <KeyIcon />
            )}
            disableErrorHighlight={disableCredFieldsHL}
            endIcon={(
              <div 
                className="ml-2 cursor-pointer"
                onClick={() => {
                  setShownPassword(prev => !prev);
                }}
              >
                <img
                  src={shownPassword ? "/images/attack-surface/shown-icon.svg" : "/images/attack-surface/hidden-icon.svg"}
                  className="ml-2"
                  alt=""
                />
              </div>
            )}
            autoFocus
          />

          <div style={{ marginTop: '8px' }}>
            <Button
              size="lg"
              variant="blue"
              className="w-100 f-14"
              type={loading ? "button" : "submit"}
              style={{ padding: "5px" }}
            >
              {loading ? (
                <span>
                  <Spinner
                    size="sm"
                    className="mr8px"
                    animation="border"
                  />{" "}
                  Authenticating
                </span>
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </form>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="f-500 pointer f-blue clean-button"
          variant="blue"
          onClick={() => {
            history.push(`${ROUTE_PATHS.auth.forgotPassword}?email=${encodeURIComponent(defaultEmail)}`);
          }}
        >
          Forgot Password?
        </Button>
      </div>
    </div>
  );
}

export default UserPasswordSection;

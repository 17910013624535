import React from "react";
import { renderPriceText } from "../../MspClientPriceCalculator";

export const MarginTable = ({
  discount,
  packageDetails,
  contributionValue,
  assetBreakDownData,
}) => {
  return (
    <div className="">
          <div className="my-2 f-500 f-12 f-darkgrey d-flex align-items-center justify-content-between">
            Margin
          </div>
          <div className="border-block radius-8">
            <div className="border-item f-grey per_asset_period-breakdown_table">
              <div className="f-500 f-12 f-darkgrey pl-2">Period</div>
              <div className="f-500 f-12 f-darkgrey">Cost</div>
              <div className="f-500 f-12 f-darkgrey">Sell</div>
              <div className="f-500 f-12 f-darkgrey">Margin</div>
              <div className="f-500 f-12 f-darkgrey">Margin %</div>
            </div>

            {assetBreakDownData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
              (data, index) => {
                return (
                  <div
                    className="border-item per_asset_period-breakdown_table">
                    <div className="f-400 f-14 f-black pl-2">{data?.Period} </div>
                    <div className="f-400 f-14 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Cost).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-400 f-14 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Sell - contributionValue - discount).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-400 f-14 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Sell - contributionValue - discount - data?.Cost).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-400 f-14 f-grey">
                      {
                        isNaN((100 * (Number(data?.Sell - contributionValue - discount - data?.Cost) / data?.Sell)).toFixed(0)) ? 0 : (100 * (Number(data?.Sell - contributionValue - discount - data?.Cost) / data?.Sell)).toFixed(0)
                      }%
                    </div>
                  </div>
                );
              }
            )}
            {
              packageDetails?.Nist_All?.some(item => item?.IsOnDemand === true) ?
                <div className="px-2 py-3">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      width={13}
                      height={13}
                      src="/images/info-blue.svg"
                      className="mr-2"
                    />
                    <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                  </div>
                  <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                </div> : <></>
            }
          </div>
        </div>
  );
};

export default MarginTable;

import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { FullScreenLoader } from "../Common/loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { APP_ROUTES, ROUTE_PATHS } from "../../constants/app.constants";

function PrivateRoute({ component: Component, render, ...rest }) {
  const { auth, isAuthenticated, loading } = useAuth();
  const location = useLocation();

  function allowedURL() {
    if (location.pathname === ROUTE_PATHS.auth.login) {
      return true;
    }
    if (location.pathname === ROUTE_PATHS.auth.signup) {
      return true;
    }
    if (location.pathname.includes("login")) {
      return true;
    }
    if (location.pathname.includes("signup")) {
      return true;
    }
    if (location.pathname === ROUTE_PATHS.saas.submitNewPassword || location.pathname.includes("submitNewPassword")) {
      return true;
    }

    return false;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <FullScreenLoader />
        ) : isAuthenticated ||
          auth ||
          (isAuthenticated === false && allowedURL()) ? (
          Component ? (
            <Component {...props} />
          ) : render ? (
            render(props)
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: APP_ROUTES.auth.login,
              state: {
                // redirectUser: allowedURL() ? false : true,
                // redirectUrl: allowedURL()
                //   ? ""
                //   : `${window.location.pathname}${window.location.search}`,
              },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;

import CyberCompliance from "../../../CyberCompliance/CyberCompliance";
import { MspTechSpendScreenRadarChart } from "../../../MSPv2/mspClientPriceCalculator/MspClientPriceCalculatorRadarChart";
import CyberScore2 from "../../../CyberScore/CyberScore2";
import styles from './cyber-security-posture.module.scss';
import ArrowDownIcon from "../../../icons/arrow-down.icon";
import ContentLoader from "react-content-loader";
import { Spinner } from "react-bootstrap";

const CyberSecurityPosture = ({ 
  isMSPCyberSPLoading,
  cyberSecurityPostureData,
  setShowTechnicalDrillDowncyberScoreModal = () => {},
  setShowTechnicalDrillDownCyberComplianceModal = () => {},
}) => {
  if (isMSPCyberSPLoading && !cyberSecurityPostureData) {
    return <CyberSecurityPostureLoader />
  }
  return (
    <div className={`${styles['cyber-security-posture']}`}>
      <div className={`${styles['section']} flex-1`}>
        <div className={styles['header']}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="f-14 f-500 f-grey">
              Cyber Score
            </div>
            {isMSPCyberSPLoading && (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                className="mx-2"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </div>
          <div className="f-18 f-500">
            {
              cyberSecurityPostureData?.CommonComponents
                ?.CyberScoreNumber ?? 0
            }
          </div>
        </div>
        <div 
          className={`${styles['chart']} client-report-cyber-score cursor-pointer position-relative poster-hover-element`}
          onClick={() => {
            setShowTechnicalDrillDowncyberScoreModal(true);
          }}
        >
          <CyberScore2
            key={0}
            size="small"
            scoreNumber={
              cyberSecurityPostureData?.CommonComponents
                ?.CyberScoreNumber ?? 0
            }
            dataStroke={18}
            bottomText={false}
            Width="180"
            Height="180"
            hideLabels={false}
          />
        </div>
      </div>
      <div className={`${styles['section']} flex-1`}>
        <div className={styles['header']}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="f-14 f-500 f-grey">
              Cyber Resilience
            </div>
            {isMSPCyberSPLoading && (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                className="mx-2"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </div>
          <div className="mb-2 f-18 f-500">
            {
              cyberSecurityPostureData?.CEOComponents
                ?.CyberComplianceNumber ?? 0
            }
            %
          </div>
        </div>
        <div 
          className={`${styles['chart']} cursor-pointer position-relative poster-hover-element`}
          onClick={() => {
            setShowTechnicalDrillDownCyberComplianceModal(true);
          }}
          style={{ marginTop: '-10px' }}
        >
          <CyberCompliance
            ccNumber={
              cyberSecurityPostureData?.CEOComponents
                ?.CyberComplianceNumber ?? 0
            }
            dataStroke={20}
            fontSize={22}
            id="cs-cyber-compilancer"
            wh={180}
            whpx="180px"
          />
        </div>
      </div>
      <div className={`${styles['section']} ${styles['footer']} flex-1`}>
        <div className={styles['header']}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="f-14 f-500 f-grey">
              Cyber Capabilities
            </div>
            {isMSPCyberSPLoading && (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                className="mx-2"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </div>
        </div>
        <div 
          className={`${styles['chart']} position-relative client-report-nist-graph d-flex align-items-center justify-content-center cursor-pointer poster-hover-element`}
          onClick={() => {
            setShowTechnicalDrillDowncyberScoreModal(true);
          }}
          style={{
            maxHeight: '236px',
          }}
        >
          <MspTechSpendScreenRadarChart
            _datasets={[
              {
                data: [
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Identity ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Protect ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Detect ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Response ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Recover ?? 0,
                ],
                pointRadius: [0, 0, 0, 0, 0],
                datalabels: {
                  display: false,
                },
                legend: {
                  display: false
                },
                backgroundColor: `${"#008fd4"}`,
                borderColor: `${"#008fd4"}`,
                pointBackgroundColor: "#008fd4",
                borderWidth: 1,
                pointLabelFontSize: 0,
                scaleShowLabels: false,
                label: "Current Posture",
              },
            ]}
            activeChart={0}
            width={'160px'}
            height={'160px'}
          />
        </div>
        <div className="d-flex justify-content-center" style={{ padding: '12px 0' }}>
          <div 
            className={styles['button']}
            onClick={() => { 
              setShowTechnicalDrillDowncyberScoreModal(true);
            }}
          >
            <span>Map posture</span>
            <div className={styles['icon']}>
              <ArrowDownIcon width="13px" height="13px" fill="#fff" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CyberSecurityPosture;

const CyberSecurityPostureLoader = () => {
  return (
    <ContentLoader
      width={'100%'}
      height={'347px'}
    >
      <rect x="0" y="0" rx="8px" ry="8px" width="32%" height = "100%" />

      <rect x="34%" y="0" rx="8px" ry="8px" width="32%" height = "100%" />

      <rect x="68%" y="0" rx="8px" ry="8px" width="32%" height = "100%" />
    </ContentLoader>
  )
}

import React, { useEffect } from "react";
import CyberScoreGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import CyberComplianceGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";

export const PriceCalculatorPosture = ({
    tabsData,
    activateChartIndex,
}) => {
    const isMobile = useRespondTo(WindowBreakpoints.md);

    useEffect(() => {
        if (tabsData.length <= 1) {
            activateChartIndex(0);
        }
    }, [tabsData]);

    function ToggleAccordionPostureData(e, rowIndex) {
        let event = e;

        var parent = event.target.closest(".point-row");
        var panel = parent.querySelector(".panel");
        if (panel?.style?.maxHeight) {
            parent.querySelector("#chevron").classList.toggle("rotate180");
            panel.style.maxHeight = null;
            activateChartIndex(0);
        } else {
            activateChartIndex(rowIndex);
            let wrapper = event.target.closest(".posture-data-expandables");
            let rows = wrapper.getElementsByClassName("point-row");
            for (let index = 0; index < rows.length; index++) {
                rows[index].querySelector(".panel").style.maxHeight = null;
                rows[index].querySelector("#chevron").classList.remove("rotate180");
            }
            parent.querySelector("#chevron").classList.toggle("rotate180");
            panel.style.maxHeight = panel.scrollHeight + 5 + "px";
        }
    }

    return (
        <div className="posture-data-expandables">
            <div
                className={`w-100 point-row mb-3`}
            >
                <div
                    className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hover-grey radius-4"
                    onClick={(e) => {
                        ToggleAccordionPostureData(e, 0);
                        e.preventDefault();
                    }}
                >
                    <div className="d-flex align-items-center f-500 f-15">
                        <div
                            className="rounded-full mx-2"
                            style={{
                                width: "16px",
                                height: "16px",
                                backgroundColor: tabsData.circleColour,
                            }}
                        />
                        {tabsData?.Name}
                    </div>
                    <div className="w-fit-content d-flex align-items-center">
                        <img
                            alt=""
                            id="chevron"
                            className="chevron"
                            src="/images/attack-surface/chevron-up.svg"
                        />
                    </div>
                </div>


                <div className="panel" style={{ maxHeight: isMobile ? '320px' : '183px' }}>
                    <div className="f-darkgrey f-12 f-500 pl-4 py-2">
                        Current scores
                    </div>
                    <div className="score-block"> 
                        <div className="d-flex m-auto" style={{
                            width: '140px',
                            height: '140px'
                        }}>
                            <CyberComplianceGauge value={tabsData?.ScenarioHeader?.CyberCompliance} />
                        </div>
                        <div className="d-flex m-auto" style={{
                            width: '140px',
                            height: '140px'
                        }}>
                            <CyberScoreGauge value={tabsData?.ScenarioHeader?.CyberScore} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PriceCalculatorPosture;

import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { APP_ROUTES } from "../../constants/app.constants";

const NoAccessToHighground = () => {
  const history = useHistory();
  return (
    <div className="w-100 h-100 p-3 d-flex flex-column">
      <div className="d-flex align-items-center justify-content-start">
        <img src="/images/logo/highground/regular.svg" alt="" />
      </div>
      <div className="d-flex flex-column justify-content-between align-items-center flex-1">
        <div className="d-flex flex-column align-items-center justify-content-center mx-auto w-30 mt-5 text-center">
          <img
            src="/images/msp/red-cross-circle.svg"
            alt=""
            className="mb-3"
            width={40}
            height={40}
          />
          <div
            className="highground-royal-font f-24 f-700"
            style={{
              lineHeight: "30px",
            }}
          >
            You are trying to access the <br /> wrong portal
          </div>

          <div className="f-grey mt-2">
            You are logged in with an MSP account. To manage your own <br /> MSP
            or your clients, you must access the account via the MSP Portal
          </div>

          <Button
            className="radis-2 hg-blue-btn w-fit-content mt-4"
            onClick={() => {
              history.push(APP_ROUTES.mspv2.clientsView);
            }}
          >
            Return to MSP Portal
          </Button>

          <Button
            className="hg-tranparent-grey-btn mt-2"
            onClick={() => {
              history.push(APP_ROUTES.mspv2.clientsView);
           
              // if (history.length >1) {
                
              //   history.goBack();
              // } else {
              // }
            }}
          >
            Back
          </Button>
        </div>

        <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
          © 2024 HighGround. &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/privacy_policy/",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </u>
          &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/highground-terms-and-conditions/",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </u>
        </div>
      </div>
    </div>
  );
};

export default NoAccessToHighground;

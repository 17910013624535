import React from "react";
import { PackageToolTip, QuestionChip } from "../../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";

export const AlignmentToFrameworkBlock = ({
    featuresLoading,
    featuresData,
}) => {

    return (
        <div>
        <div className="f-600 f-14 f-black my-2">
            Alignment to NIST CSF 2.0 Framework
        </div>
        {!featuresLoading ?
            <div className="border-block w-100">
                {
                    featuresData?.map((featureItem, featureIndex) => {
                        return (
                            <div>
                                {
                                    featureItem?.Question_List?.filter(question =>
                                        question?.AnswerId !== 0 && question?.AnswerBool === true
                                    )?.length > 0 && <div className="w-100">
                                        <div className={`f-500 f-14 f-black bg-grey px-2 py-1 ${featureIndex < featuresData?.length - 1 ? 'border-item' : ''
                                            } ${featureIndex > 0 ? 'border-top-item' : ''}`}>
                                            {featureItem?.Title}
                                        </div>
                                        <div className="">
                                            {
                                                featureItem?.Question_List?.map((question, questionIndex) => {
                                                    return (
                                                        <div>
                                                            {question?.AnswerId !== 0 &&
                                                                question?.AnswerBool === true && (
                                                                    <div className={`d-flex ${questionIndex < featureItem?.Question_List?.filter(question =>
                                                                        question?.AnswerId !== 0 && question?.AnswerBool === true
                                                                    )?.length - 1 ? 'border-item' : ''
                                                                        }`}>
                                                                        <img
                                                                            alt=""
                                                                            className="mx-2"
                                                                            src="/images/msp/green-circular-tick.svg"
                                                                        />
                                                                        <>
                                                                            {question?.InheritedText && (
                                                                                <div className="table-title radius-4 f-500">
                                                                                    <div className="img">
                                                                                        <img
                                                                                            alt=""
                                                                                            className=" "
                                                                                            src="/images/msp/arrow-enter.svg"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="title f-darkgrey">
                                                                                        Inherited from `
                                                                                        {question?.InheritedText}`{" "}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                className=""
                                                                                style={{ padding: "12px 16px" }}
                                                                            >
                                                                                <div className="d-flex align-items-start justify-content-between">
                                                                                    <div>
                                                                                        {question.Explanation}

                                                                                        <div className="d-flex flex-wrap mt-2">
                                                                                            {
                                                                                                question?.nistType &&
                                                                                                <div
                                                                                                    className='px-2 rounded-full d-flex align-items-center border1 f-grey bg-greyish f-12 f-500 mr-2'
                                                                                                >
                                                                                                    {question?.nistType}
                                                                                                </div>
                                                                                            }
                                                                                            {question?.NISTAssetClassification_List?.map(
                                                                                                (chip, chipIndex) => {
                                                                                                    return (
                                                                                                        <div className="position-relative tooltip-parent">
                                                                                                            <QuestionChip
                                                                                                                iconUrl={
                                                                                                                    chip.IsFramework
                                                                                                                        ? "/images/msp/msp-framework-icon.svg"
                                                                                                                        : null
                                                                                                                }
                                                                                                                title={chip?.Name}
                                                                                                                className={"mr-2"}
                                                                                                                IsBlue={
                                                                                                                    chip?.Name?.toLowerCase() ===
                                                                                                                    "best practice"
                                                                                                                }
                                                                                                            />
                                                                                                            {!chip.IsFramework && (
                                                                                                                <PackageToolTip
                                                                                                                    content={chip?.Detail}
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div> : <div className="d-flex align-items-center w-100 justify-content-center p-5">
                <div className="f-400 f-14 f-grey">Loading..</div>
                <div className="msp-service-update-loader ml-2 mr-2"></div>
            </div>
        }

    </div>
    );
};

export default AlignmentToFrameworkBlock;

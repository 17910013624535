import AuthTextInput from "../auth-text-input.component";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { Button, Spinner } from "react-bootstrap";
import { useState } from "react";

const QRCodeSection = ({
  qrCode,
  hmac,
  loading,
  submit2FACode,
  setTotpError,
  totpError
}) => {
  const [confirmCode, setConfirmCode] = useState("");

  const onCopyHMAC = () => {
    navigator.clipboard.writeText(hmac);
    CallToast("Copied", false, false, "v2style");
  }

  return (
    <form 
      className="user-email-section d-flex flex-column qr-code-section" style={{ gap: '16px' }}
      onSubmit={e => {
        e.preventDefault();
        submit2FACode(confirmCode)
      }}
    >
      <h3 className="m-0 title">
        Scan QR code
      </h3>
      <div>
        <div className="qr-code">
          <img 
            width="120px"
            height="120px"
            src={qrCode}
            alt="qr-code"
          />
        </div>
        <AuthTextInput
          id="user-email" 
          type="text"
          onChange={(event) => {}}
          value={hmac}
          disabled
          className="f-black"
          endIcon={(
            <div 
              className="d-flex align-items-center pointer"
              onClick={onCopyHMAC}
            >
              <img 
                src="/images/settings-v2/copy-dark.svg"
                width="12px"
                height="13px"
                alt="copy"
              />
            </div>
          )}
        />
      </div>
      <div>
        <AuthTextInput
          id="user-email" 
          type="number"
          placeholder="Enter 6-digit code"
          onChange={(event) => {
            if (String(event.target.value).length > 6) {
              return;
            }
            setTotpError("");
            setConfirmCode(event.target.value)
          }}
          value={confirmCode}
          className="f-black"
          disabled={loading}
          error={totpError}
          autoFocus
        />
        <div className="d-flex flex-column" style={{ marginTop: '8px' }}>
          <Button
            size="lg"
            type="submit"
            variant={"blue"}
            className="w-100 f-14"
            style={{ padding: "5px", fontWeight: 500 }}
            disabled={loading}
          >
            {loading ? (
              <span>
                <Spinner
                  size="sm"
                  className="mr8px"
                  animation="border"
                />{" "}
                Authenticating
              </span>
            ) : (
              "Confirm"
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default QRCodeSection;

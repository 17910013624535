import CrossIcon from "../../icons/cross.icon";
import LightbulbIcon from "../../icons/lightbulb.icon";
import './review-suggestions.styles.scss';

const ReviewSuggestionsBanner = ({ 
  onReviewClick = () => { },
  onCrossClick = () => { } 
}) => {
  return (
    <div className="bg-lightblue radius-4 d-flex align-items-center review-suggestions">
      <div className="h-100 d-flex align-self-start" style={{ marginTop: '2px' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ width: '16px', height: '16px' }}>
          <LightbulbIcon />
        </div>
      </div>
      <div className="w-100">
        <h3 className="m-0 f-14 line-height-22 f-500 f-black">
          Review security tool suggestions
        </h3>
        <p className="m-0 f-14 line-height-22 f-darkgrey">
          Use your PSA data and Security Stack to help you answer...
        </p>
      </div>
      <div>
        <button className="review-btn" onClick={onReviewClick}>
          <span className="f-14 line-height-22 f-500 f-white">
            Review
          </span>
        </button>
      </div>
      <div 
        className="d-flex align-items-center justify-content-center cursor-pointer" 
        style={{ width: '32px', height: '32px' }}
        onClick={onCrossClick}
      >
        <CrossIcon width="12px" height="12px" fill="#616778" />
      </div>
    </div>
  );
}

export default ReviewSuggestionsBanner;

import React from "react";
import { Dropdown } from "react-bootstrap";


export const SecurityCapabilitiesBlock = ({
    showBy,
    selectedShowBy,
    selectedShowByData,
    securityCapabilitiesData,
    securityCapabilitiesLoading,
    setSelectedShowBy = () => { },
    setSelectedShowByData = () => { },
}) => {

    return (
        <div className="mb-1 mt-5">
            <div className="d-flex align-items-center justify-content-between">
                <div className="f-600 f-14 f-black">
                    Security Capabilities
                </div>
                <Dropdown className="security-capabilities-dropdown">
                    <Dropdown.Toggle>
                        <div className="d-flex align-items-center py-1 px-2">
                            <div className="f-500 f-12 greyText2">
                                {selectedShowBy?.label}
                            </div>
                            <img
                                width={8}
                                alt=""
                                className="ml-2"
                                src="/images/chevron-down.svg"
                            />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div>
                            {
                                showBy?.map((showByItem, index) => {
                                    return (
                                        <div
                                            className={`f-400 f-12 px-2 py-1 ${showByItem?.id == selectedShowBy?.id ? 'txt-blue' : 'greyText2 bg-hover-grey pointer'}`}
                                            onClick={(e) => {
                                                if (showByItem?.id != selectedShowBy?.id) {
                                                    setSelectedShowBy(showByItem);
                                                    setSelectedShowByData(showByItem?.id == 0 ? securityCapabilitiesData?.SecurityCapabiltiesByNist_List?.sort((a, b) => a?.ObjectId - b?.ObjectId) : securityCapabilitiesData?.SecurityCapabiltiesBySecurityPillar_List)
                                                    e.target
                                                        .closest(".security-capabilities-dropdown")
                                                        .click();
                                                }
                                            }}
                                        >
                                            {showByItem?.label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {
                securityCapabilitiesLoading ? <div className="d-flex align-items-center w-100 justify-content-center p-5">
                    <div className="f-400 f-14 f-grey">Loading..</div>
                    <div className="msp-service-update-loader ml-2 mr-2"></div>
                </div> : <>
                    {
                        selectedShowByData?.length > 0 && (
                            <div className="my-2 border-gray w-100">
                                <div className="security-capabilities-table">
                                    {selectedShowByData?.map((securityCapabilityItem, itemIndex) => (
                                        <div
                                            key={itemIndex}
                                            className={`p-3 ${itemIndex % 2 === 0 ? 'border-right' : ''} ${itemIndex < (itemIndex % 2 === 0 ? selectedShowByData?.length - 2 : selectedShowByData?.length - 1) ? 'border-bottom' : ''
                                                }`}
                                        >
                                            <div className="f-500 f-14 f-black mb-1">
                                                {securityCapabilityItem?.Name}
                                            </div>
                                            {securityCapabilityItem?.SecurityCapabiltiesData_List?.length > 0 ? (
                                                securityCapabilityItem?.SecurityCapabiltiesData_List.map((dataItem, dataIndex) => (
                                                    <div key={dataIndex} className="d-flex align-items-center mb-2">
                                                        <img
                                                            width={14}
                                                            alt=""
                                                            className="mr-2"
                                                            src={
                                                                dataItem?.IsGreyColour
                                                                    ? '/images/icons/check-gray-circle.svg'
                                                                    : '/images/icons/check-blue-circle.svg'
                                                            }
                                                        />
                                                        <div className="f-400 f-12 f-black">
                                                            {dataItem?.Name}
                                                        </div>
                                                        {dataItem?.IsManagedBySOC && (
                                                            <img
                                                                width={14}
                                                                alt=""
                                                                className="ml-2"
                                                                src="/images/icons/monitor-icon.svg"
                                                            />
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="f-400 f-12 f-black">No security functionality in this area</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex align-items-center border-top p-2">
                                    <img width={14} alt="" className="mr-1 ml-2" src="/images/icons/check-gray-circle.svg" />
                                    <div className="f-400 f-12 f-darkgrey">Pre-existing</div>
                                    <img width={14} alt="" className="mr-1 ml-3" src="/images/icons/check-blue-circle.svg" />
                                    <div className="f-400 f-12 f-darkgrey">New!</div>
                                    <img width={14} alt="" className="mr-1 ml-3" src="/images/icons/monitor-icon.svg" />
                                    <div className="f-400 f-12 f-darkgrey">Monitored by SOC</div>
                                </div>
                            </div>
                        )
                    }
                </>
            }


        </div>
    );
};

export default SecurityCapabilitiesBlock;

import React from "react";
import { TrimString } from "../../../../Utils/StringTrimmer";
import { getAccessStateValue, viewOnlyAccess } from "../../../App/App";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import useRespondTo from "../../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../../constants/window.constants";

export const QuantityOfAssetsBlock = ({
    clientId,
    ownClientID,
    savingAssets,
    preDataState,
    isMspClientView,
    tempUserDetailsData,
    assetsFromIntegrations,
    defaultUserDeviceDetails,
    saveAssets = () => { },
    ToggleAccordion = () => { },
    setShowSourcesModal = () => { },
    setTempUserDetailsData = () => { },

}) => {
    const isMobile = useRespondTo(WindowBreakpoints.md);

    function ToggleAccordion(e, work = true) {
        if (!work) return;

        var parent = e.target.closest(".point-row");
        var panel = parent.querySelector(".panel");
        if (panel?.style?.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }

    return (
        <div className={`bg-grey radius-8 point-row ${isMobile ? 'p-2' : 'p-3'}`}>
            <div
                className={`d-flex align-items-center justify-content-between ${getAccessStateValue("AccessState_ManagedSecurity") !== 99 ? 'cursor-default' : 'ponter'}`}
                onClick={(e) => {
                    if (getAccessStateValue("AccessState_ManagedSecurity") == 99) {
                        ToggleAccordion(e)
                    }
                }}
                id="quantyAssetOnDashboard"
            >
                <div>
                    <div className="f-500 f-14 f-black">Quantity of Assets</div>
                    <div className="f-14 f-darkgrey">
                        {preDataState
                            ? `Provide us with how many users, workstations/laptops and servers
        you have in your organisation.`
                            : TrimString(
                                tempUserDetailsData
                                    .map(
                                        (item) =>
                                            `${item.Name?.replace("Per ", "")}: ${item?.Qty}`
                                    )
                                    .join(", "),
                                isMobile ? 20 : 65
                            )}
                    </div>
                </div>
                {
                    getAccessStateValue("AccessState_ManagedSecurity") !== 99 ? <img
                        alt=''
                        className='lockerDarkGreyImage ml-3 mr-2'
                        src='/images/settings-v2/locker-grey.svg'
                    /> :
                        <div>
                            <img
                                alt=""
                                src="/images/calculator/edit.svg"
                                className="ml-3 mr-2"
                            />
                        </div>
                }
            </div>
            <div
                className="panel"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >

                <div className="d-flex align-items-center justify-content-between my-3">
                    <div className="f-500 f-14 f-darkgrey">Assets</div>
                    {
                        <div
                            className="d-flex align-items-center pointer"
                            onClick={() => {
                                setShowSourcesModal(true);
                                const drag = document?.getElementById("sources-modal");
                                drag.style.display = "block";
                            }}
                        >
                            <img
                                alt=''
                                width={20}
                                height={22}
                                className='mr-1'
                                src='/images/get-access-icon.svg'
                            />
                            <div className="f-500 f-14 txt-blue">Get Assets</div>
                        </div>
                    }
                </div>

                <div
                    className={`msp_assets_inputs_wrapper ${isMobile ? 'd-block' : 'd-flex flex-wrap'}`}
                    style={{
                        pointerEvents: isMspClientView ? "none" : "auto",
                    }}
                >
                    {tempUserDetailsData?.map((item, assetIndex) => {
                        return (
                            <div
                                key={assetIndex}
                                className={`highlight-input-with-grey-border d-flex align-items-center p-2 radius-4 mt-2 ${false ? "input-error" : ""}`}
                            >
                                <div
                                    className="f-darkgrey flex-shrink-0 user-details-item"
                                    onClick={() => {
                                        const updatedData = tempUserDetailsData.map((dataItem) => {
                                            if (dataItem.ObjectId === item.ObjectId) {
                                                return {
                                                    ...dataItem,
                                                    Qty: 1,
                                                    IsUpdatedByAsset: false,
                                                };
                                            }
                                            return dataItem;
                                        });
                                        setTempUserDetailsData(updatedData);
                                    }}
                                >
                                    {item?.Name?.replace("Per ", "")}:
                                </div>
                                <input
                                    tabIndex={0}
                                    disabled={Number(ownClientID) != Number(clientId) ? viewOnlyAccess("AccessState_ManagedSecurity") : false}
                                    type="number"
                                    placeholder="0"
                                    className="pl-1 pr-2 w-100"
                                    value={item?.Qty}
                                    onChange={(e) => {
                                        if (Number(ownClientID) != Number(clientId)) {
                                            if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                                const updatedData = tempUserDetailsData.map((dataItem) => {
                                                    if (dataItem.ObjectId === item.ObjectId) {
                                                        return {
                                                            ...dataItem,
                                                            Qty: e.target.value?.trim(),
                                                            IsUpdatedByAsset: false,
                                                        };
                                                    }
                                                    return dataItem;
                                                });
                                                setTempUserDetailsData(updatedData);
                                            }
                                        } else {
                                            const updatedData = tempUserDetailsData.map((dataItem) => {
                                                if (dataItem.ObjectId === item.ObjectId) {
                                                    return {
                                                        ...dataItem,
                                                        Qty: e.target.value?.trim(),
                                                        IsUpdatedByAsset: false,
                                                    };
                                                }
                                                return dataItem;
                                            });
                                            setTempUserDetailsData(updatedData);
                                        }
                                    }}
                                />
                                {item?.IsUpdatedByAsset && assetsFromIntegrations?.length > 0 ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        delay={{ show: 200, hide: 250 }}
                                        overlay={
                                            <Tooltip id={`tooltip-assets`}>
                                                {assetsFromIntegrations
                                                    ?.find(
                                                        (obj) =>
                                                            obj?.Name?.slice(0, -1) === item.Name?.replace("Per ", "")
                                                    )
                                                    ?.AssetDevicePreview_List?.[0]?.AssetDeviceSource_List?.map(
                                                        (hoverItem, index) => (
                                                            <div className="d-flex" key={index}>
                                                                <img
                                                                    alt=""
                                                                    height={16}
                                                                    src={`/images/attack-surface/integrations/${hoverItem?.AssetSourceLogo}.webp`}
                                                                    className="mr-1"
                                                                />
                                                                <div className="f-400 f-12 f-white line-height-18 letter-space-2">
                                                                    {hoverItem?.AssetSourceName}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            alt=""
                                            height={14}
                                            width={14}
                                            src="/images/info.svg"
                                        />
                                    </OverlayTrigger>
                                ) : null}
                            </div>
                        );
                    })}

                </div>
                {!isMspClientView && (
                    <div className="d-flex align-items-center mt-2">
                        <Button
                            className={` mr-2 ${Number(ownClientID) == Number(clientId) ? 'hg-cancel-btn' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'btn-disable cursor-default' : 'hg-cancel-btn'}`}
                            onClick={(e) => {
                                if (Number(ownClientID) != Number(clientId)) {
                                    if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                        saveAssets(e);
                                    }
                                } else {
                                    saveAssets(e);
                                }
                            }}
                        >
                            {(Number(ownClientID) != Number(clientId)) && (
                                (viewOnlyAccess("AccessState_ManagedSecurity")) &&
                                <img
                                    alt=''
                                    className='lockerDarkGreyImage mx-2 mb-1'
                                    src='/images/settings-v2/locker-grey.svg'
                                />)
                            }
                            {savingAssets ? "Saving.." : "Save"}
                        </Button>
                        <Button
                            className="hg-cancel-btn"
                            onClick={(e) => {
                                ToggleAccordion(e);
                                setTempUserDetailsData(defaultUserDeviceDetails);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuantityOfAssetsBlock;

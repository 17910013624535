import React, { useState, useEffect, lazy, Suspense } from "react";
import { Modal, Button, Col, Row, Dropdown } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./requestsModal.scss";
import { getAPIData, getPageDataApi, postData } from "../../../Utils/commonApi";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { GreyLoader, WhiteLoader } from "../../Theme/APILoaders";
// import AddComplianceProfileModal from "../AddComplianceProfileModal/AddComplianceProfileModal";
import AccessLevelModal from "../AccessLevelModal/AccessLevelModal";
import SupplyPopUp from "../SupplyPopUp/SupplyPopUp";
import { useHistory } from "react-router-dom";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import capitalize from "../../../Utils/capitalizeWord";
import { TrimString } from "../../../Utils/StringTrimmer";
import { addEventOnScroll } from "../../../Utils/CommonFunctions";
import { handleUnmanagedText } from "../../AttackSurface/Suppliers/Index";
import { validateEmail } from "../../../Utils/CommonFunctions";
import integrationUtils from "../../../Utils/integration.utils";
import { ROUTE_PATHS } from "../../../constants/app.constants";
const AddComplianceProfileModal = lazy(() => import("../AddComplianceProfileModal/AddComplianceProfileModal"));

const RequestsModal = ({
  show,
  hideModal,
  refreshData,
  showPotentialSupplierPage,
  refreshPotentialSuppliers,
  openSupplierDetailScreen,
}) => {
  const { authTokens } = useAuth();
  const history = useHistory();
  const [
    hidePotentialSupplierListBackButton,
    setHidePotentialSupplierListBackButton,
  ] = useState(false);
  const [page, setPage] = useState("homepage");
  const [showIntegrationPopup, setShowIntegrationPopup] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [supplyChainId, setSupplyChainId] = useState("");
  // const [clientRequests?.length, setClientRequestCount] = useState(0);
  const [potentialSupplierLoader, setPotentialSupplierLoader] = useState(false);
  const [potentialSupplierID, setPotentialSupplierID] = useState("");
  const [supplierRequestCount, setSupplierRequestCount] = useState(0);
  const [clientRequests, setClientRequests] = useState([]);
  const [supplierRequests, setSupplierRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supplierInputError, setSupplierInputError] = useState("");
  const [supplierLoaderAsideDropdown, setSupplierLoaderAsideDropdown] =
    useState(false);

  const [rejectionReason, setRejectionReason] = useState("");
  const [requestToPotentialSupplier, setRequestToPotentialSupplier] =
    useState(false); 
  const [searchPotentialSupplier, setSearchPotentialSupplier] = useState("");
  const [cancelType, setCancelType] = useState("");
  const [message, setMessage] = useState("");
  const [cancelRequestStatus, setCancelRequestStatus] = useState();
  const [responseModalText, setResponseModalText] = useState({
    Title: "",
    ResponseGreyText: "",
  });
  const [requestDetails, setRequestDetails] = useState({
    Id: "",
    status: "",
    relationId: "",
  });

  const [questionId, setQuestionId] = useState(0);

  // Invite supplier state
  const [invitingSupplier, setInvitingSupplier] = useState(false);
  const [invitedSupplierData, setInvitedSupplierData] = useState("");
  const [supplierNotFound, setSupplierNotFound] = useState(false);
  const [suppliersToInvite, setSuppliersToInvite] = useState("");
  const [alreadyExistSupplier, setAlreadyExistSupplier] = useState("");
  const [showSupplierRequests, setShowSupplierRequests] = useState(false);
  // Supplier Details
  const [supplierName, setSupplierName] = useState("");
  const [supplierLogo, setSupplierLogo] = useState("");
  const [supplierSupplyChainId, setSupplierSupplyChainId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [relationShipID, setRelationShipID] = useState("");

  // Access Level data
  const [accessLevelData, setAccessLevelData] = useState([]);

  const [complianceProfileList, setComplianceProfileList] = useState([]);
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState();
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState();
  const [showUpgradeSection, setShowUpgradeSection] = useState({});
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);

  // Status to handle client details screen UI

  const [processing, setProcessing] = useState(false);
  const [denying, setDenying] = useState(false);

  // Potential Supplier List
  const [potentialSupplierList, setPotentialSupplierList] = useState([]);
  const [potentialSupplierData, setPotentialSupplierData] = useState({});
  const [supplierToInvite, setSupplierToInvite] = useState("");
  const [potentialSupplierFilters, setPotentialSupplierFilters] = useState([]);
  const [selectedPotentialSupplierFilter, setSelectedPotentialSupplierFilter] =
    useState();
    // { Id: 0, Text: "All Tools" }
  const [
    selectedPotentialSupplierFilterStatus,
    setSelectedPotentialSupplierFilterStatus,
  ] =
    useState();
    // { Id: 0, Text: "All Tools" }
  const [
    potentialSupplierFilterStatusList,
    setPotentialSupplierFilterStatusList,
  ] = useState([]);

  const [newSupplierName, setNewSupplierName] = useState("");
  const [invitePotentialSupplierData, setInvitePotentialSupplierData] =
    useState({
      supplierName: "",
      supplierEmail: "",
      supplierWebsite: "",
      supplierMessage: "",
      supplierLogo: "",
      nameError: "",
      emailError: "",
      websiteError: "",
    });
  // Misllenous
  const [showAddComplianceModal, setShowAddComplianceModal] = useState(false);
  const [removing, setRemoving] = useState(false);

  // Context Menu and Button Visbility
  const [contextMenuData, setContextMenuData] = useState({});

  useEffect(() => {
    if (show) {
    }
  }, [show]);

  function openSupplierDetailsScreen(
    ObjId_Customer,
    relId_Request,
    objId_SendFormQuestionnaire
  ) {
    setPage("client");
    setRequestToPotentialSupplier(false);
    getRequestDetails(ObjId_Customer, -99, relId_Request);
    setQuestionId(objId_SendFormQuestionnaire);

    setSupplierId(ObjId_Customer);

    getAccessLevels();
    getComplianceProfiles(ObjId_Customer);
  }

  // homepage
  // client
  // clientlist
  // potentialSupplierList
  // addasasupplier
  // invitesuppliertohighground
  //newinvitesupplier
  // cancelRequest

  function ResetState(resetModal = false) {
    setContextMenuData({});
    setSearchPotentialSupplier("");

    setAlreadyExistSupplier("");
    setInvitedSupplierData("");
    setSelectedAccessLevel();
    setSelectedComplianceProfile();

    setClientFilter({
      id: 0,
      category: "All requests",
    });
    setSupplierFilter({
      id: 0,
      category: "All requests",
    });
    if (resetModal) getModalData(0, 0);

    resetInvitePotentialSupplierData();
  }

  function resetInvitePotentialSupplierData() {
    setInvitePotentialSupplierData({
      supplierId: 0,
      supplierName: "",
      supplierEmail: "",
      supplierWebsite: "",
      supplierMessage: "",
      supplierLogo: "",
    });
  }
  const [clientFilter, setClientFilter] = useState({
    id: 0,
    category: "All requests",
  });
  const [supplierFilter, setSupplierFilter] = useState({
    id: 0,
    category: "All requests",
  });

  useEffect(() => {
    setSearchPotentialSupplier("");
    setSupplierToInvite("");
    setMessage("");
  }, [page]);

  useEffect(() => {
    if (show) {
      setSupplierName("");
      getPotentialSuppliers();
      setShowIntegrationPopup(true);
      setNewSupplierId("");
      setLoading(true);
      setPotentialSupplierLoader(true);
      setRequestToPotentialSupplier(false);
      setPage("homepage");
      ResetState();
      setSupplierInputError("");
      setInvitedSupplierData("");
      setProcessing(false);

      // getAccessLevels();
      setClientFilter({
        id: 0,
        category: "All requests",
      });

      setSupplierFilter({
        id: 0,
        category: "All requests",
      });
      getModalData(0, 0);

      if (showPotentialSupplierPage) {
        setPage("potentialSupplierList");
        setHidePotentialSupplierListBackButton(true);
      } else {
        setHidePotentialSupplierListBackButton(false);
      }

      // To open Supplier Details Screen

      if (openSupplierDetailScreen) {
        openSupplierDetailsScreen(
          openSupplierDetailScreen?.ObjId_Customer,
          openSupplierDetailScreen?.relId_Request,
          openSupplierDetailScreen?.objId_SendFormQuestionnaire
        );
      }
    }
  }, [show]);

  async function getModalData(clientOrder = 0, supplierOrder = 0) {
    await getAPIData("342", authTokens, {
      optionEnum1: clientOrder, // client All=0, sent =1, Denied=2
      optionEnum2: supplierOrder, // Supplier
    })
      .then((response) => {
        setLoading(false);
        setSupplierLoaderAsideDropdown(false);
        if (response?.mr?.Success) {
          setInfoMessage(response?.InfoMessage);
          setSupplyChainId(response?.SupplyChainId);

          setClientRequests(
            response?.ClientRequests.filter(
              (item) => item?.ContextMenu?.requestList_ItemVisible === true
            )
          );

          setShowSupplierRequests(response?.Access?.ShowRequests);
          setShowUpgradeSection(response?.Access);
          // Setup for Subscription
          if (response?.Access?.InviteSupplierAccess === 1) {
          } else if (response?.Access?.InviteSupplierAccess === -2) {
            CallToast("Something went wrong", false, false, "v2style");
            return;
          } else if (response?.Access?.InviteSupplierAccess === -1) {
            return;
          } else if (response?.Access?.InviteSupplierAccess === 0) {
            return;
          }

          setSupplierRequestCount(response?.SupplierRequestCount);
          setSupplierRequests(
            response?.SupplierRequests?.filter(
              (item) => item?.ContextMenu?.requestList_ItemVisible === true
            )
          );
        }
      })
      .catch((err) => {
        setSupplierLoaderAsideDropdown(false);
        console.log(err);
      });
  }

  // Pge list=== homepage, clientlist,client

  const [newSupplierId, setNewSupplierId] = useState("");

  // Function to invite
  async function inviteSupplier(supplierId) {
    setInvitingSupplier(true);
    await getAPIData(`${318}`, authTokens, {
      optionStrEnum1: supplierId,
    }).then((response) => {
      setInvitingSupplier(false);
      if (!response?.SupplierDetail) {
        CallToast("Something went wrong", false, false, "v2style");
      } else {
        setSuppliersToInvite(response?.SupplierDetail);
      }

      if (response?.Toast_Message) {
        setAlreadyExistSupplier(response?.SupplierDetail?.ObjId_Customer);
        CallToast(response?.Toast_Message, false, false, "v2style");
      } else {
        setInvitedSupplierData(true);
        setSuppliersToInvite(response?.SupplierDetail);
        setSupplierNotFound(response?.NotFound_ShowMessage);
      }
    });
  }

  // Function to get Request Details for already existing Supplier:STEP-1
  async function getRequestDetails(Id, status, relation) {
    let relationId =relation
    setLoading(true);
    await getAPIData("346", authTokens, {
      optionEnum1: Id,
      optionEnum2: status,
      optionEnum3: relation,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          // createSupplierRequest(supplierId);
          setSupplierName(response?.Obj_SupplierName);
          setSupplierLogo(response?.Obj_SupplierLogo);
          setSupplierSupplyChainId(response?.SupplyChainId);
           if(relationId){
            setRelationShipID(relationId)
           }

          setRejectionReason(response?.RequestResponseMessage);
          // setSupplierId(response?.ObjId_Supplier)
          setContextMenuData(response?.ContextMenu);
          if (response?.ContextMenu?.ResponseModalText) {
            setResponseModalText(response?.ContextMenu?.ResponseModalText);
          }
          if (response?.AccessLevelVM) {
            setAccessLevelData(
              makeGroup(
                response?.AccessLevelVM?.AccessLevelSelection_List || [],
                "Section"
              )
            );
            if (response?.AccessLevelVM?.ObjectId > 0) {
              setSelectedAccessLevel({
                ObjectId: response?.AccessLevelVM?.ObjectId,
                Name: response?.AccessLevelVM?.Name,
              });
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        } else {
          setPage("homepage");
          CallToast(response?.mr?.Message, false, false, "v2style");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  // Function to create Suplier Request:STEP-2
  async function createSupplierRequest(supplierId) {
    setLoading(true);
    await postData(authTokens, "CreateSupplierRequest", {
      objId_Supplier: supplierId,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.mr?.Success) {
          if (response?.SubSuccess === false) {
            CallToast(response?.mr?.Message, false, false, "v2style");
            return;
          } else {
            setPage("client");

            getRequestDetails(supplierId);
            setSupplierId(supplierId);
            if (refreshData) {
              refreshData();
            }
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to get Compliance Profile list:STEP-3
  async function getComplianceProfiles(supplierID) {
    await getAPIData(`${314}`, authTokens, {
      optionEnum1: supplierID,
      optionEnum2: 1,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setComplianceProfileList(response?.Profiles);
          getAccessLevels();
          let selected_complianceProfile = response?.Profiles?.find(
            (item) => item?.Selected === true
          );
          if (selected_complianceProfile) {
            setSelectedComplianceProfile(selected_complianceProfile);
          }
        } else {
          // CallToast("Something went wrong", false, false, "v2style");
          // setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to get Access Level Data:STEP-4
  async function getAccessLevels() {
    await getAPIData(`${309}`, authTokens).then((response) => {
      if (response?.length > 0) {
        setAccessLevelList(response);
        setLoading(false);
      }
    });
  }

  // Function to get Access Details
  async function getAccessDetails(accessLevelId) {
    await getAPIData(`${344}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: accessLevelId,
    }).then((response) => {
      if (response?.mr?.Success) {
        if (response?.AccessLevelSelection_List) {
          setAccessLevelData(
            makeGroup(response?.AccessLevelSelection_List || [], "Section")
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      }
    });
  }
  // function to Update compliance profile
  async function updateComplianceProfile(complianceId) {
    await getAPIData(`${343}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: complianceId,
    }).then((response) => {
      if (response?.Success) {
        // CallToast(
        //   "Compliance profile updated successfully",
        //   false,
        //   false,
        //   "v2style"
        // );
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function sendRequest(extraParams = {}) {
    let _accessLevelList = [];
    for (let i = 0; i < accessLevelData.length; i++) {
      _accessLevelList.push(...accessLevelData[i].data);
    }

    if (requestToPotentialSupplier) {
      extraParams = { ObjId_PotentialSupplier: potentialSupplierID };
    }
    await postData(authTokens, "SendRequestToSupplier", {
      ObjectId: 0,
      ObjId_AccessLevel: selectedAccessLevel?.ObjectId,
      ObjId_Supplier: supplierId,
      //  ObjId_PotentialSupplier:"",
      AccessLevelSelection_List: JSON.stringify(_accessLevelList),
      ...extraParams,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Request sent successfully", false, false, "v2style");
          if (refreshData) {
            refreshData();
          }

          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setProcessing(false);
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
        setProcessing(false);
      });
  }

  function moveTocancelRequestScreen(client, status) {
    setSupplierLogo(client?.Obj_CustomerLogo);
    setSupplierId(client?.ObjId_Customer);
    setSupplierName(client?.Obj_CustomerName);
    setSupplierSupplyChainId(client?.SupplyChainId);
    if (status === 6) {
      setCancelType("suspend");
      setPage("cancelRequest");
      setCancelRequestStatus(6);
    }
    if (status === 4) {
      setCancelType("remove");
      setPage("cancelRequest");
      setCancelRequestStatus(4);
    }
    if (status === 3) {
      setCancelType("remove");
      setPage("cancelRequest");
      setCancelRequestStatus(3);
    }
  }
  // Not_Sent, //-------0
  // Sent,//-------1
  // Denied,//-------2
  // Removed_By_Client,//-------3
  // Removed_By_Supplier,//-------4
  // Suspended_By_Client,//-------5,
  // Suspended_By_Supplier,//----6
  // Approved_By_Supplier,//----7
  // Cancelled_By_Client,//----8
  async function cancelRequest() {
    setProcessing(true);
    postData(authTokens, "SendResponseToSupplier", {
      RequestStatus: cancelRequestStatus,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Response: message,
      ObjId_Supplier: supplierId,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          hideModal();
          if (refreshData) refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Update status function
  // Not_Sent, //-------0
  // Sent,//-------1
  // Denied,//-------2
  // Removed_By_Client,//-------3
  // Removed_By_Supplier,//-------4
  // Suspended_By_Client,//-------5,
  // Suspended_By_Supplier,//----6
  // Approved_By_Supplier,//----7
  // Cancelled_By_Client,//----8

  async function updateStatus(status, relationshipId) {
    await getAPIData("345", authTokens, {
      optionEnum1: supplierId,
      optionEnum2: status,
      optionEnum3: relationshipId,
    })
      .then((response) => {
        setProcessing(false);
        setDenying(false);
        setRemoving(false);
        if (response?.Success) {
          hideModal();
          CallToast("Updated successfully", false, false, "v2style");
          if (refreshData) {
            refreshData();
          }
        }
      })
      .catch(() => {
        setProcessing(false);
        setDenying(false);
        setRemoving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Potential Suppliers APIs
  // Function to get Potential Suppliers

  async function getPotentialSuppliers(filter = 0, status = 0) {
    await getAPIData(`${452}`, authTokens, {
      optionEnum1: filter,
      optionEnum2: status,
    }).then((response) => {
      setPotentialSupplierLoader(false);
      if (response?.mr?.Success) {
        setPotentialSupplierList(
          response?.PotentialSupplierDetail_List?.filter(
            (item) => item?.Type === "Potential"
          )
        );
        setPotentialSupplierData(response);
        setPotentialSupplierFilters(response?.PotentialSupplierFilterEnum_List);
        setPotentialSupplierFilterStatusList(
          response?.PotentialSupplierInviteEnum_List
        );
      }
    });
  }

  // Archive or UnArchive Potential supplier
  // 0= archived
  // 1= Unarchived
  async function archiveUnarchivePotentialSupplier(Id, status) {
    await getAPIData(`${453}`, authTokens, {
      optionEnum1: Id,
      optionEnum2: status,
    })
      .then((response) => {
        if (response?.Success) {
          getPotentialSuppliers(selectedPotentialSupplierFilter?.Id ?? 0);
          if (status === 0) {
            CallToast("Archived successfully", false, false, "v2style");
          } else {
            CallToast("Unarchived successfully", false, false, "v2style");
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        console.log(err);
      });
  }

  // function to Invite Supplier to highground

  async function InviteSupplierToHighGround() {
    await postData(authTokens, "CreatePotentialSupplier", {
      ObjId_PotentialSupplier: invitePotentialSupplierData?.supplierId,
      Email: invitePotentialSupplierData?.supplierEmail,
      SupplierName: invitePotentialSupplierData?.supplierName,
      Website: invitePotentialSupplierData?.supplierWebsite,
      Message: invitePotentialSupplierData?.supplierMessage,
      Type: 2,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Invited successfully", false, false, "v2style");
          hideModal();
          if (refreshPotentialSuppliers) {
            refreshPotentialSuppliers();
          }
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setProcessing(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to invite with supplier chain Id and send request
  async function InviteSupplierToSupplierChain(
    _supplyChainID,
    isPotentialSupplier = false
  ) {
    await getAPIData(`${318}`, authTokens, {
      optionStrEnum1: _supplyChainID,
    })
      .then((response) => {
        if (!response?.SupplierDetail) {
          setProcessing(false);
          CallToast(response?.mr?.Message, false, false, "v2style");
          return;
        }
        if (response?.Toast_Message) {
          setProcessing(false);
          CallToast(response?.Toast_Message, false, false, "v2style");
          return;
        }
        if (response?.mr?.Success) {
          setRequestToPotentialSupplier(isPotentialSupplier);
          setSuppliersToInvite(response?.SupplierDetail);
          let SupplierId = response?.SupplierDetail?.ObjId_Customer;
          getAccessLevels();
          getComplianceProfiles(SupplierId);
          createSupplierRequest(SupplierId);
          setAccessLevelData([...dummyData]);
        } else {
          setProcessing(false);
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setProcessing(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to move Supplier to ASE
  function moveSuppliertoASE(suppID) {
    getPageDataApi("MoveUnallocatedSupplierToASE", authTokens, {
      AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      TokenKey: "FrontEnd",
      SupplierObjId: suppID,
    })
      .then((response) => {
        if (response?.Success) {
          if (refreshData) {
            refreshData();
          }
          getPotentialSuppliers();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Update Status without closing modal
  async function updateStatusWithoutClosingModal(id, status, relationshipId) {
    await getAPIData("345", authTokens, {
      optionEnum1: id,
      optionEnum2: status,
      optionEnum3: relationshipId,
    })
      .then((response) => {
        if (response?.Success) {
          getModalData(clientFilter?.id, supplierFilter?.id);
          CallToast("Updated successfully", false, false, "v2style");
          if (refreshData) {
            refreshData();
          }
        }
      })
      .catch(() => {
        setProcessing(false);
        setDenying(false);
        setRemoving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName=" supplychain-requestsmodal"
      className=""
      aria-labelledby=" supplychain-requestsmodal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        {page !== "homepage" &&
          (page === "potentialSupplierList" &&
          hidePotentialSupplierListBackButton
            ? false
            : true) && (
            <img
              alt=""
              src="/images/supply-chain/chevron-left.svg"
              className="mt-2 pointer"
              onClick={() => {
                if (page === "addasasupplier") {
                  setPage("potentialSupplierList");
                } else {
                  setLoading(true);
                  setPage("homepage");
                  ResetState(true);
                }

                // getModalData(clientFilter?.id, supplierFilter?.id);
              }}
            />
          )}
        <div className="w-100 mb-0 font-bold-14">
          {page === "homepage" && "Requests"}
          {page === "client" && supplierName && `Request to ${supplierName}`}
          {page === "clientlist" && `Clients`}
          {page === "potentialSupplierList" && `Potential Suppliers`}
          {page === "addasasupplier" &&
            `Request to ${invitePotentialSupplierData?.supplierName}`}
          {page === "invitesuppliertohighground" &&
            `Invite Supplier to HighGround`}
          {page === "newinvitesupplier" && `Invite Supplier to HighGround`}
          {page === "cancelRequest" &&
            supplierName &&
            `${capitalize(cancelType)} ${supplierName}`}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img alt="" src="/images/icons/close.svg" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {/* HOME PAGE  PAGE-1 */}
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {page === "homepage" && (
              <div className="homepage ">
                {/* Supply chain id wrapper */}
                <div className="supplychain-id-wrapper d-flex align-items-center justify-content-between mx-2">
                  <div className="f-500">
                    Your Supply Chain ID
                    <AttackSurfaceTooltip
                      className={"ml-2"}
                      content={<span>{infoMessage}</span>}
                    >
                      <img
                        alt=""
                        src="/images/supply-chain/grey-info.svg"
                        className=""
                      />
                    </AttackSurfaceTooltip>
                  </div>
                  <div className="f-grey">
                    {supplyChainId}
                    <img
                      alt=""
                      src="/images/supply-chain/copy-icon.svg"
                      className="ml-2 pointer"
                      onClick={() => {
                        CallToast(
                          `<img src="/images/settings-v2/copy-link.svg" className="mr-2" />&nbsp;&nbsp;Supply Chain ID copied to clipboard`,
                          false,
                          false
                        );

                        navigator.clipboard.writeText(supplyChainId);
                      }}
                    />
                  </div>
                </div>
                {/* Access-data message wrapper */}
                <div
                  className="access-data-wrapper d-flex align-items-center py-2 pointer"
                  onClick={() => {
                    if (clientRequests?.length > 0) {
                      setPage("clientlist");
                    }
                  }}
                >
                  <div
                    className={` ${
                      clientRequests?.length > 0 ? "image-group" : "d-none"
                    }`}
                  >
                    {clientRequests?.slice(0, 3)?.map((client) => {
                      return (
                        <img
                          alt=""
                          src={client?.Obj_CustomerLogo}
                          width={24}
                          style={{ border: "1px solid white" }}
                          height={24}
                          className="rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/user-logo.svg";
                          }}
                        />
                      );
                    })}
                  </div>
                  <div
                    className={`${
                      clientRequests?.length > 0 ? "ml-2" : "ml-2"
                    }`}
                  >
                    <span className="f-blue f-500">
                      {clientRequests?.length} client
                      {clientRequests?.length > 1 && "s"} requested
                    </span>
                    &nbsp;
                    <span className="f-500">
                      access to your HighGround data
                    </span>
                    <img
                      alt=""
                      src="/images/chevron-right.svg"
                      className="ml-2"
                    />
                  </div>
                </div>
                {/* New requests list wrapper */}
                <div className="new-requests-wrapper">
                  <div className="f-12 f-grey f-500 ml-3">
                    {clientRequests?.length} new request
                    {clientRequests?.length > 1 && "s"}
                  </div>

                  {clientRequests?.length > 0 &&
                    clientRequests?.map((request) => {
                      return (
                        <div
                          className={`${
                            request?.ContextMenu?.requestList_ItemVisible
                              ? "d-flex"
                              : "d-none"
                          } justify-content-between align-items-center  pointer request-item`}
                          onClick={() => {
                            setRequestToPotentialSupplier(false);
                            setPage("client");
                            setRelationShipID(request?.relId_Request);
                            getRequestDetails(
                              request?.ObjId_Customer,
                              -99,
                              request?.relId_Request
                            );
                            setRequestDetails({
                              Id: request?.ObjId_Customer,
                              status: -99,
                              relationId: request?.relId_Request,
                            });

                            setSupplierId(request?.ObjId_Customer);

                            getAccessLevels();
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <img
                              alt=""
                              src={request?.Obj_CustomerLogo}
                              width={32}
                              height={32}
                              className="mr-2 rounded-full"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/images/no-image.png";
                              }}
                            />
                            <div className="f-500 f-black">
                              {request?.Obj_CustomerName}
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="f-grey mr-2">
                              {request?.ContextMenu?.contextMenuLabel}
                            </div>
                            <div
                              className={"mr-2 requestlist-dropdown"}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Dropdown
                                className={`device-dropdown ${
                                  request?.ContextMenu?.ContextMenuItems
                                    ?.length > 0
                                    ? ""
                                    : "d-none"
                                }`}
                              >
                                <Dropdown.Toggle>
                                  <img
                                    alt=""
                                    src="/images/supply-chain/horizontal-dots.svg"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div>
                                    <Row>
                                      {request?.ContextMenu?.ContextMenuItems?.map(
                                        (menuItem, menuItemIndex) => {
                                          return (
                                            <Col xs={12} className=" pointer">
                                              <div
                                                className="drop-item"
                                                onClick={(e) => {
                                                  setRequestToPotentialSupplier(
                                                    false
                                                  );
                                                  setRelationShipID(
                                                    request?.relId_Request
                                                  );
                                                  setRequestDetails({
                                                    Id: request?.ObjId_Customer,
                                                    status:
                                                      menuItem?.newRequestStatus_Id,
                                                    relationId:
                                                      request?.relId_Request,
                                                  });
                                                  if (
                                                    menuItem?.newRequestStatus_Id ===
                                                      4 ||
                                                    menuItem?.newRequestStatus_Id ===
                                                      6
                                                  ) {
                                                    getRequestDetails(
                                                      request?.ObjId_Customer,
                                                      menuItem?.newRequestStatus_Id,
                                                      request?.relId_Request
                                                    );
                                                    moveTocancelRequestScreen(
                                                      request,
                                                      menuItem?.newRequestStatus_Id
                                                    );
                                                    return;
                                                  }
                                                  setPage("client");
                                                  getRequestDetails(
                                                    request?.ObjId_Customer,
                                                    menuItem?.newRequestStatus_Id,
                                                    request?.relId_Request
                                                  );

                                                  setSupplierId(
                                                    request?.ObjId_Customer
                                                  );

                                                  getAccessLevels();

                                                  e.target
                                                    .closest(".device-dropdown")
                                                    .click();
                                                }}
                                              >
                                                {menuItem?.menuActionLabel}
                                              </div>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <hr className="hg-border-top" color="#EBEDF1" />
                {/* Add supplier wrapper */}
                {showUpgradeSection?.InviteSupplierAccess === 1 && (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="f-500 f-black">Add Supplier</div>
                      <div
                        className="f-blue pointer"
                        onClick={() => {
                          setPage("newinvitesupplier");
                          resetInvitePotentialSupplierData();
                        }}
                      >
                        <img
                          alt=""
                          src="/images/supply-chain/invite-supplier-icon.svg"
                          className="mr-1"
                        />
                        Invite supplier
                      </div>
                    </div>
                    <div className="d-flex align-items-center search-supplier-input highlight-input">
                      <input
                        type="text"
                        className=" w-100 border-none bg-transparent"
                        placeholder="XXX-XXX-XXX"
                        value={newSupplierId}
                        onChange={(e) => {
                          // let foo = e.target.value;
                          if (e.target.value?.length > 11) return;
                          let foo = e.target.value.split("-").join("");
                          if (foo.length > 0) {
                            foo = foo
                              .match(new RegExp(".{1,3}", "g"))
                              .join("-");
                          }
                          setAlreadyExistSupplier("");
                          // if (e.target.value === "") setInvitedSupplierData(false);
                          setInvitedSupplierData(false);
                          setNewSupplierId(foo);
                          setAlreadyExistSupplier("");
                          if (foo?.length === 11) {
                            inviteSupplier(foo);
                          }
                        }}
                      />
                      {newSupplierId && (
                        <img
                          src="/images/settings-v2/header-search-cross.svg"
                          alt=""
                          className="pointer mr-2"
                          onClick={() => {
                            setNewSupplierId("");
                          }}
                        />
                      )}
                    </div>
                  </>
                )}

                {/* Upgrade state    ===== 1 */}
                {showUpgradeSection?.InviteSupplierAccess === -1 && (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex align-items-center justify-content-center rounded-full mr-2"
                          style={{
                            background: "#008fd430",
                            width: "60px",
                            height: "38px",
                          }}
                        >
                          <img
                            alt=""
                            className=""
                            src={
                              showUpgradeSection?.IconImage
                                ? showUpgradeSection?.IconImage
                                : "/images/attack-surface/blue-bell.svg"
                            }
                          />
                        </div>
                        <div>
                          <p className="f-600 f-15 m-0">
                            {showUpgradeSection?.Title}{" "}
                          </p>
                          <p className="f-grey f-14">
                            {" "}
                            {showUpgradeSection?.SubTitle}{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="f-blue f-500 pointer ml-3 mr-2"
                        onClick={() => {
                          history.push(showUpgradeSection?.UpgradeLinkPath);
                        }}
                      >
                        {showUpgradeSection?.ButtonText}{" "}
                      </div>
                    </div>
                  </>
                )}

                {showUpgradeSection?.InviteSupplierAccess === 0 && (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex align-items-center justify-content-center rounded-full mr-2"
                          style={{
                            background: "#61677830",
                            width: "70px",
                            height: "48px",
                          }}
                        >
                          {showUpgradeSection?.IconText}{" "}
                          {/* <img alt="" className="" src={showUpgradeSection?.IconImage ?   showUpgradeSection?.IconImage  : '/images/attack-surface/blue-bell.svg'}/> */}
                        </div>
                        <div>
                          <p className="f-600 f-15 m-0">
                            {showUpgradeSection?.Title}
                          </p>
                          <p className="f-grey f-14">
                            {showUpgradeSection?.SubTitle}
                          </p>
                        </div>
                      </div>
                      <div
                        className="f-blue f-500 pointer ml-3 mr-2"
                        onClick={() => {
                          history.push(showUpgradeSection?.UpgradeLinkPath);
                        }}
                      >
                        {showUpgradeSection?.ButtonText}{" "}
                      </div>
                    </div>
                  </>
                )}
                {/* If there no exist Potential Supplier */}

                {showIntegrationPopup &&
                  !newSupplierId &&
                  potentialSupplierList?.length === 0 && (
                    <div className="bg-grey radius-4 d-flex align-items-center justify-content-between mt-2 p-2">
                      <div className=" d-flex align-items-center">
                        <img
                          alt=""
                          src="/images/supply-chain/blue-plug.svg"
                          className="mr-2"
                        />
                        <div className="f-black">
                          Integrate your tools to identify your suppliers
                          automatically
                        </div>
                      </div>

                      <div className="f-blue d-flex align-items-center">
                        <div
                          className="pointer"
                          onClick={() => {
                            history.push(ROUTE_PATHS.saas.integrations);
                          }}
                        >
                          Integrations
                        </div>
                        <img
                          src="/images/supply-chain/blue-redirect.svg"
                          alt=""
                          className="mx-2 pointer"
                          onClick={() => {
                            history.push(ROUTE_PATHS.saas.integrations);
                          }}
                        />
                        <img
                          src="/images/small-cross-icon.svg"
                          alt=""
                          className="mx-2 pointer"
                          onClick={() => {
                            setShowIntegrationPopup(false);
                          }}
                        />
                      </div>
                    </div>
                  )}

                {newSupplierId && !alreadyExistSupplier && (
                  <>
                    <div className="sent-requests-header d-flex align-items-center justify-content-between mt-3 mb-2">
                      <div className="f-12 f-grey">
                        {newSupplierId?.length > 0 ? "Search" : "Suppliers"}
                      </div>
                      <div className="sent-request-dropdown"></div>
                    </div>
                    {invitingSupplier && (
                      <div className="d-flex text-center flex-column mt-4">
                        <div className="d-flex align-items-center justify-content-center">
                          <GreyLoader width={14} />
                        </div>

                        <div className=" mt-3 d-flex align-items-center justify-content-center f-grey">
                          Looking for supplier...
                        </div>
                      </div>
                    )}

                    {!invitingSupplier && newSupplierId?.length < 11 && (
                      <div className="d-flex text-center flex-column mt-4">
                        <div className="d-flex align-items-center justify-content-center f-500 f-grey">
                          Enter in format XXX-XXX-XXX
                        </div>
                        <div className="d-flex align-items-center justify-content-center f-darkgrey">
                          Please enter suppliers supply-chain ID in order to
                          find them.
                        </div>
                      </div>
                    )}
                    <>
                      {!invitingSupplier && newSupplierId?.length === 11 && (
                        <>
                          {supplierNotFound ? (
                            <div className="d-flex flex-column justify-content-center align-items-center w-75 mt-5 mx-auto">
                              {/* <img
                                alt=""
                                src="/images/supply-chain/no-supplier-icon.svg"
                                className="no-supplier-icon mb-3"
                              /> */}
                              <div className="text-center f-14 f-500 mb-2">
                                Supplier could not be found
                              </div>
                              <p
                                className="banner-subtitle text-center"
                                onClick={() => {}}
                              >
                                Please check the Supply Chain ID used or Invite
                                them to HighGround
                              </p>
                            </div>
                          ) : (
                            <div>
                              <div
                                className="d-flex justify-content-between align-items-center  pointer request-item"
                                onClick={(e) => {
                                  e.stopPropagation();

                                  getAccessLevels();
                                  getComplianceProfiles(
                                    suppliersToInvite?.ObjId_Customer
                                  );
                                  createSupplierRequest(
                                    suppliersToInvite?.ObjId_Customer
                                  );
                                  setAccessLevelData([...dummyData]);
                                  setNewSupplierId("");
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    alt=""
                                    src={suppliersToInvite?.Obj_CustomerLogo}
                                    width={32}
                                    height={32}
                                    className="mr-2 rounded-full"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/no-image.png";
                                    }}
                                  />
                                  <div className="f-500 f-black">
                                    {suppliersToInvite?.Obj_CustomerName}
                                  </div>
                                </div>

                                <div className="d-flex align-items-center">
                                  <div className="f-blue mr-2">Request</div>
                                  <div
                                    className="mr-2 requestlist-dropdown"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </>
                )}

                {(!newSupplierId || alreadyExistSupplier) &&
                  showSupplierRequests && (
                    <>
                      <div className="sent-requests-header d-flex align-items-center justify-content-between mt-3 mb-2">
                        <div className="f-12 f-grey">Sent requests</div>
                        <div className="sent-request-dropdown d-flex align-items-center">
                          {supplierLoaderAsideDropdown && (
                            <GreyLoader width={13} />
                          )}
                          <CompanySettingsDropdown
                            fieldId={"id"}
                            fieldValue={"category"}
                            selected={supplierFilter}
                            placeholder="Choose..."
                            //  showid={true}
                            onClick={(id, val) => {
                              setSupplierFilter({ id, category: val });
                              setSupplierLoaderAsideDropdown(true);
                              getModalData(clientFilter.id, id);
                            }}
                            data={[
                              { id: 0, category: "All requests" },
                              { id: 1, category: "Sent requests" },
                              { id: 2, category: "Denied requests" },
                              { id: 6, category: "Archived" },
                            ]}
                          />
                        </div>
                      </div>
                      {/* Potential Supplier List redirect link */}
                      {potentialSupplierList?.length > 0 && (
                        <div
                          className="access-data-wrapper d-flex align-items-center py-2 pointer w-100 justify-content-between pr-2"
                          onClick={() => {
                            setPage("potentialSupplierList");
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className={` ${"image-group"}`}>
                              {potentialSupplierList
                                ?.slice(0, 3)
                                ?.map((supplier) => {
                                  return (
                                    <>
                                      {supplier?.PotentialSupplierIcon?.toLowerCase() ===
                                      "noicon" ? (
                                        <span style={{ marginLeft: "-10px" }}>
                                          <ImageWithInitials
                                            width={24}
                                            background="purple"
                                            initials={
                                              supplier?.PotentialSupplierName?.toUpperCase()?.[0] ??
                                              "X"
                                            }
                                          />
                                        </span>
                                      ) : (
                                        <img
                                          alt=""
                                          src={supplier?.PotentialSupplierIcon}
                                          width={24}
                                          style={{ border: "1px solid white" }}
                                          height={24}
                                          className="rounded-full"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "/images/user-logo.svg";
                                          }}
                                        />
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                            <div className={`${"ml-2"}`}>
                              {
                                potentialSupplierData?.PotentialSupplierCounterText
                              }
                            </div>
                          </div>

                          <img
                            alt=""
                            src="/images/chevron-right.svg"
                            className="ml-2"
                          />
                        </div>
                      )}
                      {/* List wrapper */}
                      {/* No Supplier State */}
                      {
                        // supplierRequests?.length
                        supplierRequestCount === 0 && (
                          <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
                            <img
                              alt=""
                              src="/images/supply-chain/no-supplier-icon.svg"
                              className="no-supplier-icon mb-3"
                            />
                            <div className="banner-element-name mb-2">
                              No suppliers
                            </div>
                            <p
                              className="banner-subtitle text-center"
                              onClick={() => {}}
                            >
                              You haven't added any suppliers yet.
                              {/* To add suppliers,
                      send them a <br /> request to access their HighGround data */}
                            </p>
                          </div>
                        )
                      }

                      {supplierRequestCount > 0 &&
                        supplierRequests
                          ?.filter((item) => {
                            if (alreadyExistSupplier) {
                              return (
                                item?.ObjId_Customer === alreadyExistSupplier
                              );
                            } else {
                              return true;
                            }
                          })
                          ?.map((request, supplierRequestIndex) => {
                            return (
                              <div
                                className={`${
                                  request?.ContextMenu?.requestList_ItemVisible
                                    ? "d-flex"
                                    : "d-none"
                                } justify-content-between align-items-center  pointer request-item`}
                                onClick={() => {
                                  setPage("client");
                                  setRelationShipID(request?.relId_Request);
                                  setRequestToPotentialSupplier(false);
                                  getRequestDetails(
                                    request?.ObjId_Customer,
                                    -99,
                                    request?.relId_Request
                                  );

                                  setSupplierId(request?.ObjId_Customer);

                                  getAccessLevels();
                                  getComplianceProfiles(
                                    request?.ObjId_Customer
                                  );
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    alt=""
                                    src={request?.Obj_CustomerLogo}
                                    width={32}
                                    height={32}
                                    className="mr-2 rounded-full"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/no-image.png";
                                    }}
                                  />
                                  <div className="f-500 f-black">
                                    {request?.Obj_CustomerName}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="f-grey mr-2">
                                    {request?.ContextMenu?.contextMenuLabel}
                                  </div>
                                  <div
                                    className={"mr-2 requestlist-dropdown"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Dropdown
                                      className={`device-dropdown ${
                                        request?.ContextMenu?.ContextMenuItems
                                          ?.length > 0
                                          ? ""
                                          : "d-none"
                                      }`}
                                    >
                                      <Dropdown.Toggle>
                                        <img
                                          alt=""
                                          src="/images/supply-chain/horizontal-dots.svg"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <div>
                                          <Row>
                                            {request?.ContextMenu?.ContextMenuItems?.map(
                                              (menuItem, menuItemIndex) => {
                                                return (
                                                  <Col
                                                    xs={12}
                                                    className=" pointer"
                                                  >
                                                    <div
                                                      className="drop-item"
                                                      onClick={(e) => {
                                                        // STOP USER TO MOVE TO NEXT SCREEN AND CALL 345 API
                                                        // To Archive the user
                                                        if (
                                                          menuItem?.newRequestStatus_Id ===
                                                          10
                                                        ) {
                                                          updateStatusWithoutClosingModal(
                                                            request?.ObjId_Customer,
                                                            10,
                                                            request?.relId_Request
                                                          );
                                                          e.target
                                                            .closest(
                                                              ".device-dropdown"
                                                            )
                                                            .click();
                                                          return;
                                                        }

                                                        // To Unarchive the user
                                                        if (
                                                          menuItem?.newRequestStatus_Id ===
                                                          0
                                                        ) {
                                                          updateStatusWithoutClosingModal(
                                                            request?.ObjId_Customer,
                                                            0,
                                                            request?.relId_Request
                                                          );
                                                          e.target
                                                            .closest(
                                                              ".device-dropdown"
                                                            )
                                                            .click();
                                                          return;
                                                        }

                                                        // To Remove the Supplier
                                                        if (
                                                          menuItem?.newRequestStatus_Id ===
                                                          3
                                                        ) {
                                                          getRequestDetails(
                                                            request?.ObjId_Customer,
                                                            menuItem?.newRequestStatus_Id,
                                                            request?.relId_Request
                                                          );
                                                          moveTocancelRequestScreen(
                                                            request,
                                                            menuItem?.newRequestStatus_Id
                                                          );
                                                          return;
                                                        }
                                                        // ------------------------
                                                        setPage("client");
                                                        setRelationShipID(
                                                          request?.relId_Request
                                                        );
                                                        setRequestToPotentialSupplier(
                                                          false
                                                        );
                                                        getRequestDetails(
                                                          request?.ObjId_Customer,
                                                          menuItem?.newRequestStatus_Id,
                                                          request?.relId_Request
                                                        );

                                                        setSupplierId(
                                                          request?.ObjId_Customer
                                                        );

                                                        getAccessLevels();
                                                        getComplianceProfiles(
                                                          request?.ObjId_Customer
                                                        );
                                                        e.target
                                                          .closest(
                                                            ".device-dropdown"
                                                          )
                                                          .click();
                                                      }}
                                                    >
                                                      {
                                                        menuItem?.menuActionLabel
                                                      }
                                                    </div>
                                                  </Col>
                                                );
                                              }
                                            )}
                                          </Row>
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </>
                  )}
              </div>
            )}
            {/* CLIENT REQUEST PAGE-- 2 */}
            {page === "client" && (
              <div className="client-request-data">
                {/* verify client alert */}
                <div className="verify-client-alert py-2 px-3 d-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="f-500 f-black d-flex align-items-center">
                      <img
                        alt=""
                        src="/images/supply-chain/alert-icon.svg"
                        className="mr-2"
                      />
                      <span className="pt-1">
                        Verify this clients Supply Chain ID: 182-773-628
                      </span>
                    </div>
                    <img
                      alt=""
                      src="/images/supply-chain/tiny-cross.svg"
                      className="pointer"
                    />
                  </div>
                  <p className="f-grey">
                    You should verify with your client outside of HighGround
                    that this is their HIghGround Supply Chain ID and that they
                    submitted this request before approving this.
                  </p>
                </div>
                {/* Client name and logo wrapper */}
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      src={supplierLogo}
                      width={32}
                      height={32}
                      className="mr-2 rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/no-image.png";
                      }}
                    />
                    <div>
                      <p className="m-0 f-500 f-black">{supplierName}</p>
                      <p className="m-0 f-14 f-grey">
                        Supply Chain ID:{supplierSupplyChainId}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={`requestlist-dropdown ${
                        contextMenuData?.ContextMenuItems?.length > 0
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <Dropdown className="device-dropdown">
                        <Dropdown.Toggle>
                          <img
                            alt=""
                            src="/images/supply-chain/horizontal-dots.svg"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            <Row>
                              {contextMenuData?.ContextMenuItems?.map(
                                (menuItem, menuItemIndex) => {
                                  return (
                                    <Col xs={12} className="  pointer">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          if (menuItem?.callPageEnum == 754) {
                                            history.push(
                                              `${ROUTE_PATHS.saas.supplierProfiler}/profiling`,
                                              {
                                                backUrl: `${
                                                  window.location.pathname
                                                }?${new URLSearchParams(
                                                  window.location.search
                                                ).toString()}`,
                                                isContributorView: false,
                                                isTemplate: false,
                                                isTemplateEdit: false,
                                                questionId: questionId,
                                              }
                                            );
                                          }
                                          updateStatus(
                                            menuItem?.newRequestStatus_Id,
                                            relationShipID
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {menuItem?.menuActionLabel}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {/*  Client Status with you */}
                <p className="f-500 p-2 d-none">
                  <strong>Nike Inc. </strong> has suspended you from their
                  account and are no longer sharing their HighGround data with
                  you.
                </p>

                {/* Client response */}
                {rejectionReason && (
                  <div className="client-reponse">
                    <p className="f-500 mb-2"> Response</p>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<p class="m-0">“${rejectionReason}”</p>`,
                        }}
                      />
                    </p>
                  </div>
                )}

                {/* Clients Complaine profile and access level option */}
                <div className="client-compliance-access d-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="f-500 f-black">Compliance profile</div>
                    <div></div>
                  </div>
                </div>

                {/* Complaince Profile dropdowns */}
                {contextMenuData?.allowEditAccessAndCompliance && (
                  <>
                    <div className="d-flex align-items-center justify-content-between my-1 mx-2 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="f-500 mr-2">Compliance Profile</div>
                      </div>

                      <div className="compliance-filter-dropdow n user-detail-dropdown">
                        <Dropdown
                          className="device-dropdown"
                          onClick={addEventOnScroll}
                        >
                          <Dropdown.Toggle className="fade_on_scroll_toggle">
                            <div className="d-flex align-items-center">
                              {selectedComplianceProfile ? (
                                <div className="f-black f-500 mr-2">
                                  {TrimString(
                                    selectedComplianceProfile?.ProfileName,
                                    35
                                  )}
                                </div>
                              ) : (
                                <div className="f-grey mr-2">Choose...</div>
                              )}
                              <img
                                alt=""
                                className=""
                                src="/images/big-chevron-down.svg"
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="hg-bg-white hide-scrollbar fade_on_scroll"
                            style={{
                              maxHeight: "225px",
                              // overflowY: "scroll",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              className="hide-scrollbar position-relative black-blur-div"
                              style={{
                                maxHeight: "216px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              <Row>
                                {complianceProfileList?.map(
                                  (profile, profileIndex) => {
                                    return (
                                      <Col xs={12}>
                                        <div
                                          style={{ marginTop: "3px" }}
                                          className={`notification-btn bg-hover-grey-light ${
                                            profile?.ObjectId ===
                                            selectedComplianceProfile?.ObjectId
                                              ? "bg-grey-light"
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            setSelectedComplianceProfile(
                                              profile
                                            );
                                            updateComplianceProfile(
                                              profile?.ObjectId
                                            );
                                            // getAccessDetails(profile?.ObjectId);
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {profile?.ProfileName}
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}

                                <hr
                                  className=" line-divider-light "
                                  style={{
                                    width: "80%",
                                    margin: "auto",
                                    /* margin-left: auto; */
                                    marginTop: "12px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <Col xs={12}>
                                  <div
                                    style={{
                                      marginBottom: "8px",
                                    }}
                                    className="notification-btn bg-hover-grey-light"
                                    onClick={(e) => {
                                      setShowAddComplianceModal(true);
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Add compliance profile
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    {/* Access Level dropdown */}
                    <div className="d-flex align-items-center justify-content-between my-1 mx-2 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="f-500 mr-2">Access Level</div>
                      </div>

                      <div className="compliance-filter-dropdow n user-detail-dropdown">
                        <Dropdown
                          className="device-dropdown"
                          onClick={addEventOnScroll}
                        >
                          <Dropdown.Toggle>
                            <div className="d-flex align-items-center">
                              {selectedAccessLevel ? (
                                <div className="f-black f-500 mr-2">
                                  {TrimString(selectedAccessLevel?.Name, 35)}
                                </div>
                              ) : (
                                <div className="f-grey mr-2">Choose...</div>
                              )}
                              <img
                                alt=""
                                className=""
                                src="/images/big-chevron-down.svg"
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="hg-bg-white hide-scrollbar fade_on_scroll "
                            style={{
                              maxHeight: "225px",
                              // overflowY: "scroll",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              className="hide-scrollbar position-relative black-blur-div"
                              style={{
                                maxHeight: "216px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              <Row>
                                {accessLevelList?.map(
                                  (accessLevel, acessLevelIndex) => {
                                    return (
                                      <Col xs={12}>
                                        <div
                                          style={{ marginTop: "3px" }}
                                          className={`notification-btn bg-hover-grey-light ${
                                            accessLevel?.ObjectId ===
                                            selectedAccessLevel?.ObjectId
                                              ? "bg-grey-light"
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            setSelectedAccessLevel(accessLevel);
                                            getAccessDetails(
                                              accessLevel?.ObjectId
                                            );
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {TrimString(accessLevel?.Name, 30)}
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}

                                <hr
                                  className=" line-divider-light "
                                  style={{
                                    width: "80%",
                                    margin: "auto",
                                    /* margin-left: auto; */
                                    marginTop: "12px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <Col xs={12}>
                                  <div
                                    style={{
                                      marginBottom: "8px",
                                    }}
                                    className="notification-btn bg-hover-grey-light position-relative"
                                    onClick={(e) => {
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                      setShowAccessLevelModal(true);
                                    }}
                                  >
                                    Add access level
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </>
                )}

                <hr color="#EBEDF1" />
                {/* General Permissions */}
                {accessLevelData?.map((data, dataIndex) => {
                  return (
                    <div className="general-permissions">
                      <div className="f-12 f-grey my-2 ml-2">
                        {data?.Section}
                      </div>

                      {data?.data?.map((row, rowIndex) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between my-1 mx-2">
                            <div className="d-flex align-items-center">
                              <div className="f-500 mr-2">
                                {row?.SubSection}
                              </div>
                              <span className="pointer">
                                <SupplyPopUp
                                  header={row?.SubSection}
                                  dataList={row?.Permission_List?.map(
                                    (permission) => {
                                      return {
                                        title: permission?.Permission,
                                        subtitle: permission?.Rights,
                                      };
                                    }
                                  )}
                                >
                                  <img
                                    alt=""
                                    src="/images/supply-chain/grey-info.svg"
                                  />
                                </SupplyPopUp>
                              </span>
                            </div>

                            <div
                              className={`compliance-filter-dropdown   ${
                                contextMenuData?.allowEditAccessAndCompliance
                                  ? ""
                                  : "disabled-dropdown"
                              } `}
                            >
                              <CompanySettingsDropdown
                                fieldId={"ObjectId"}
                                fieldValue={"Permission"}
                                selected={row?.PermissionSelected}
                                placeholder="Choose..."
                                //  showid={true}
                                onClick={(id, val) => {
                                  let updated_data = [...accessLevelData];
                                  let selected_value = updated_data[dataIndex][
                                    "data"
                                  ][rowIndex].Permission_List.filter(
                                    (item) => item.ObjectId === id
                                  )[0];
                                  updated_data[dataIndex]["data"][
                                    rowIndex
                                  ].PermissionSelected = selected_value;
                                  setAccessLevelData(updated_data);
                                  setSelectedAccessLevel({
                                    ObjectId: "123",
                                    Name: "Custom Access Level",
                                  });
                                }}
                                data={row?.Permission_List}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}

            {page === "clientlist" && (
              <div>
                <div
                  className="access-data-wrapper d-flex align-items-center py-2 pointer mt-1 mb-3"
                  onClick={() => {}}
                >
                  <div className="image-group">
                    {clientRequests?.slice(0, 3)?.map((client) => {
                      return (
                        <img
                          alt=""
                          src={client?.Obj_CustomerLogo}
                          width={24}
                          style={{ border: "1px solid white" }}
                          height={24}
                          className="rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/user-logo.svg";
                          }}
                        />
                      );
                    })}
                  </div>
                  <div>
                    <span className="f-blue f-500 ml-2">
                      {clientRequests?.length} client
                      {clientRequests?.length > 1 && "s"} requested
                    </span>
                    &nbsp;
                    <span className="f-500">
                      access to your HighGround data
                    </span>
                    <img
                      alt=""
                      src="/images/chevron-right.svg"
                      className="ml-2"
                    />
                  </div>
                </div>
                {/* Heading */}
                <div className="sent-requests-header d-flex align-items-center justify-content-between mt-3 mb-2">
                  <div className="f-12 f-grey ml-3">Your clients</div>
                  <div className="sent-request-dropdown">
                    <CompanySettingsDropdown
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={clientFilter}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        setClientFilter({ id, category: val });
                        getModalData(id, supplierFilter.id);
                      }}
                      data={[
                        { id: 0, category: "All clients" },
                        { id: 1, category: "Sent clients" },
                        { id: 2, category: "Denied clients" },
                      ]}
                    />
                  </div>
                </div>
                {/* No Client State */}

                {clientRequests?.length === 0 && (
                  <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
                    <img
                      alt=""
                      src="/images/supply-chain/no-supplier-icon.svg"
                      className="no-supplier-icon mb-3"
                    />
                    <div className="banner-element-name mb-2">No Clients</div>
                    <p
                      className="banner-subtitle text-center"
                      onClick={() => {}}
                    >
                      {/* You haven't added any suppliers yet. To add suppliers,
                      send them a <br /> request to access their HighGround data */}
                    </p>
                  </div>
                )}

                {/* Clienst listing */}
                {clientRequests?.length > 0 &&
                  clientRequests?.map((request) => {
                    return (
                      <div
                        className={`${
                          request?.ContextMenu?.requestList_ItemVisible
                            ? "d-flex"
                            : "d-none"
                        } justify-content-between align-items-center  pointer request-item`}
                        onClick={() => {
                          setRequestToPotentialSupplier(false);
                          setPage("client");
                          setRelationShipID(request?.relId_Request);
                          getRequestDetails(
                            request?.ObjId_Customer,
                            -99,
                            request?.relId_Request
                          );
                          setRequestDetails({
                            Id: request?.ObjId_Customer,
                            status: -99,
                            relationId: request?.relId_Request,
                          });

                          setSupplierId(request?.ObjId_Customer);

                          getAccessLevels();
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            alt=""
                            src={request?.Obj_CustomerLogo}
                            width={32}
                            height={32}
                            className="mr-2 rounded-full"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/images/no-image.png";
                            }}
                          />
                          <div className="f-500 f-black">
                            {request?.Obj_CustomerName}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="f-grey mr-2">
                            {request?.ContextMenu?.contextMenuLabel}
                          </div>
                          <div
                            className={"mr-2 requestlist-dropdown"}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Dropdown
                              className={`device-dropdown ${
                                request?.ContextMenu?.ContextMenuItems?.length >
                                0
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              <Dropdown.Toggle>
                                <img
                                  alt=""
                                  src="/images/supply-chain/horizontal-dots.svg"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  <Row>
                                    {request?.ContextMenu?.ContextMenuItems?.map(
                                      (menuItem, menuItemIndex) => {
                                        return (
                                          <Col xs={12} className=" pointer">
                                            <div
                                              className="drop-item"
                                              onClick={(e) => {
                                                setRequestToPotentialSupplier(
                                                  false
                                                );
                                                setRelationShipID(
                                                  request?.relId_Request
                                                );
                                                setRequestDetails({
                                                  Id: request?.ObjId_Customer,
                                                  status:
                                                    menuItem?.newRequestStatus_Id,
                                                  relationId:
                                                    request?.relId_Request,
                                                });
                                                if (
                                                  menuItem?.newRequestStatus_Id ===
                                                    4 ||
                                                  menuItem?.newRequestStatus_Id ===
                                                    6
                                                ) {
                                                  getRequestDetails(
                                                    request?.ObjId_Customer,
                                                    menuItem?.newRequestStatus_Id,
                                                    request?.relId_Request
                                                  );
                                                  moveTocancelRequestScreen(
                                                    request,
                                                    menuItem?.newRequestStatus_Id
                                                  );
                                                  return;
                                                }
                                                setPage("client");
                                                getRequestDetails(
                                                  request?.ObjId_Customer,
                                                  menuItem?.newRequestStatus_Id,
                                                  request?.relId_Request
                                                );

                                                setSupplierId(
                                                  request?.ObjId_Customer
                                                );

                                                getAccessLevels();

                                                e.target
                                                  .closest(".device-dropdown")
                                                  .click();
                                              }}
                                            >
                                              {menuItem?.menuActionLabel}
                                            </div>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            {page === "potentialSupplierList" && (
              <div>
                <div className="d-flex align-items-center search-supplier-input highlight-input">
                  <img
                    alt=""
                    className="mr-2"
                    src="/images/attack-surface/search-icon.svg"
                  />
                  <input
                    type="text"
                    className=" w-100 border-none bg-transparent"
                    placeholder="Search"
                    value={searchPotentialSupplier}
                    onChange={(e) => {
                      setSearchPotentialSupplier(e.target.value);
                    }}
                  />
                  {searchPotentialSupplier && (
                    <img
                      src="/images/settings-v2/header-search-cross.svg"
                      alt=""
                      className="pointer mr-2"
                      onClick={() => {
                        setSearchPotentialSupplier("");
                      }}
                    />
                  )}
                </div>
                <div className="sent-requests-header d-flex align-items-center justify-content-between mt-3 mb-2">
                  <div className="f-12 f-grey"></div>
                  <div className="d-flex align-items-center">
                    <div className="compliance-filter-dropdow n user-detail-dropdown">
                      <Dropdown
                        className="device-dropdown"
                        onClick={addEventOnScroll}
                      >
                        <Dropdown.Toggle>
                          <div className="d-flex align-items-center">
                            {selectedPotentialSupplierFilter ? (
                              <div className="f-black f-500 mr-2">
                                {TrimString(
                                  selectedPotentialSupplierFilter?.Text?.replaceAll(
                                    "_",
                                    " "
                                  ),
                                  30
                                )}
                              </div>
                            ) : (
                              <div className="f-grey mr-2">
                                Choose filter...
                              </div>
                            )}
                            <img
                              alt=""
                              className=""
                              src="/images/big-chevron-down.svg"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="hg-bg-white hide-scrollbar fade_on_scroll "
                          style={{
                            maxHeight: "225px",
                            // overflowY: "scroll",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className="hide-scrollbar position-relative black-blur-div"
                            style={{
                              maxHeight: "216px",
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            <Row>
                              {potentialSupplierFilters?.map(
                                (potentialFilter, potentialFilterIndex) => {
                                  return (
                                    <Col xs={12}>
                                      <div
                                        style={{ marginTop: "3px" }}
                                        className={`notification-btn bg-hover-grey-light ${
                                          potentialFilter?.Id ===
                                          selectedPotentialSupplierFilter?.Id
                                            ? "bg-grey-light"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          setPotentialSupplierLoader(true);
                                          setSelectedPotentialSupplierFilter(
                                            potentialFilter
                                          );
                                          getPotentialSuppliers(
                                            potentialFilter?.Id,
                                            selectedPotentialSupplierFilterStatus?.Id ??
                                              0
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {TrimString(
                                          potentialFilter?.Text?.replaceAll(
                                            "_",
                                            " "
                                          ),
                                          30
                                        )}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                              <div className="p-1"></div>
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    {/* status Filter */}
                    <div className="compliance-filter-dropdow n user-detail-dropdown">
                      <Dropdown
                        className="device-dropdown"
                        onClick={addEventOnScroll}
                      >
                        <Dropdown.Toggle>
                          <div className="d-flex align-items-center">
                            {selectedPotentialSupplierFilterStatus ? (
                              <div className="f-black f-500 mr-2">
                                {TrimString(
                                  selectedPotentialSupplierFilterStatus?.Text?.replaceAll(
                                    "_",
                                    " "
                                  ),
                                  30
                                )}
                              </div>
                            ) : (
                              <div className="f-grey mr-2">
                                Choose Status...
                              </div>
                            )}
                            <img
                              alt=""
                              className=""
                              src="/images/big-chevron-down.svg"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="hg-bg-white hide-scrollbar fade_on_scroll "
                          style={{
                            maxHeight: "225px",
                            // overflowY: "scroll",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className="hide-scrollbar position-relative black-blur-div"
                            style={{
                              maxHeight: "216px",
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            <Row>
                              {potentialSupplierFilterStatusList?.map(
                                (potentialFilter, potentialFilterIndex) => {
                                  return (
                                    <Col xs={12}>
                                      <div
                                        style={{ marginTop: "3px" }}
                                        className={`notification-btn bg-hover-grey-light ${
                                          potentialFilter?.Id ===
                                          selectedPotentialSupplierFilterStatus?.Id
                                            ? "bg-grey-light"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          setPotentialSupplierLoader(true);
                                          setSelectedPotentialSupplierFilterStatus(
                                            potentialFilter
                                          );
                                          getPotentialSuppliers(
                                            selectedPotentialSupplierFilter?.Id ??
                                              0,
                                            potentialFilter?.Id
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {TrimString(
                                          potentialFilter?.Text?.replaceAll(
                                            "_",
                                            " "
                                          ),
                                          30
                                        )}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                              <div className="p-1"></div>
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className="mx-2 f-darkgrey pt-1 f-500 pointer"
                      onClick={() => {
                        if (
                          selectedPotentialSupplierFilter ||
                          selectedPotentialSupplierFilterStatus
                        ) {
                          setSelectedPotentialSupplierFilter();
                          setSelectedPotentialSupplierFilterStatus();
                          setPotentialSupplierLoader(true);
                          getPotentialSuppliers();
                        }
                      }}
                    >
                      Reset
                    </div>
                  </div>
                </div>

                {potentialSupplierLoader && (
                  <>
                    <div
                      className="d-flex align-items-center justify-content-center w-100  "
                      style={{
                        height: "320px",
                      }}
                    >
                      <Loader />
                    </div>
                  </>
                )}

                {!potentialSupplierLoader &&
                  potentialSupplierList
                    ?.filter((item) =>
                      item?.PotentialSupplierName?.toLowerCase()?.includes(
                        searchPotentialSupplier?.toLowerCase()
                      )
                    )

                    .map((potentialSupplier) => {
                      return (
                        <div
                          className="d-flex justify-content-between align-items-center  pointer request-item hover-on-show-parent"
                          onClick={() => {}}
                        >
                          <div className="d-flex align-items-center">
                            {potentialSupplier?.PotentialSupplierIcon?.toLowerCase() ===
                            "noicon" ? (
                              <ImageWithInitials
                                width={32}
                                background="purple"
                                initials={
                                  potentialSupplier?.PotentialSupplierName?.[0]?.toUpperCase() ??
                                  "X"
                                }
                              />
                            ) : (
                              <img
                                alt=""
                                src={potentialSupplier?.PotentialSupplierIcon}
                                width={32}
                                height={32}
                                className=" rounded-full"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "/images/user-logo.svg";
                                }}
                              />
                            )}

                            <div className="d-flex flex-column ml-2">
                              <div className="d-flex align-items-center">
                                <div className="f-500 f-black">
                                  {potentialSupplier?.PotentialSupplierName}
                                </div>
                                {potentialSupplier?.IsEmailSent && (
                                  <div className="d-flex align-items-center f-darkgrey ml-2">
                                    <img
                                      alt=""
                                      className="mr-1"
                                      src="/images/supply-chain/grey-tick-icon.svg"
                                    />
                                    {handleUnmanagedText(
                                      potentialSupplier?.PotentialSupplierStatusText
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="f-grey mr-2 f-500 d-flex">
                                {/* If the source is Attcak Surface */}
                                {potentialSupplier?.Type === "ASE" && (
                                  <div className="d-flex radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish">
                                    <img
                                      width={13}
                                      src="/images/attack-surface/attack_surface_dashboard_icon.svg"
                                      alt=""
                                      className="mr-2"
                                    />
                                    Attack Surface
                                  </div>
                                )}
                                {/* If the source is SCM */}
                                {potentialSupplier?.Type === "SCM" && (
                                  <div className="d-flex radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish">
                                    <img
                                      width={13}
                                      src="/images/settings-v2/supply-chain.svg"
                                      alt=""
                                      className="mr-2"
                                    />
                                    Supply Chain Monitoring
                                  </div>
                                )}
                                {potentialSupplier?.IntegrationInfo
                                  ?.IntegrationName && (
                                  <div className="d-flex align-items-center radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish">
                                    <img
                                      width={16}
                                      height={16}
                                      src={integrationUtils.getImagePath(potentialSupplier?.IntegrationInfo?.IntegrationId, potentialSupplier?.IntegrationInfo?.ToolIcon)}
                                      alt=""
                                      className="mr-2"
                                    />
                                    {
                                      potentialSupplier?.IntegrationInfo
                                        ?.IntegrationName
                                    }
                                  </div>
                                )}
                                {potentialSupplier?.PotentialSupplierService_List?.slice(
                                  0,
                                  2
                                ).map((item) => {
                                  return (
                                    <div
                                      className="d-flex radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish"
                                      // style={{ background: "#ebedf1" }}
                                    >
                                      # {item?.ServiceName}
                                    </div>
                                  );
                                })}
                                {potentialSupplier
                                  ?.PotentialSupplierService_List?.length >
                                  2 && (
                                  <div className="d-flex radius-2 f-grey f-500 f-12 p-1 bg-greyish">
                                    +
                                    {potentialSupplier
                                      ?.PotentialSupplierService_List?.length -
                                      2}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div
                              className="mr-2 requestlist-dropdown"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Dropdown className="device-dropdown">
                                <Dropdown.Toggle>
                                  <img
                                    alt=""
                                    src="/images/supply-chain/horizontal-dots.svg"
                                    className="hover-on-show-child"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div>
                                    <Row>
                                      {potentialSupplier?.Type ===
                                        "Potential" &&
                                        !potentialSupplier?.IsArchived && (
                                          <Col xs={12} className=" pointer">
                                            <div
                                              className="drop-item pl-3"
                                              onClick={(e) => {
                                                moveSuppliertoASE(
                                                  potentialSupplier?.PotentialSupplierId
                                                );
                                                e.target
                                                  .closest(".device-dropdown")
                                                  .click();
                                              }}
                                            >
                                              Add as Unmanaged Supplier
                                            </div>
                                          </Col>
                                        )}
                                      {!potentialSupplier?.IsArchived && (
                                        <Col xs={12} className=" pointer">
                                          <div
                                            className="drop-item pl-3"
                                            onClick={(e) => {
                                              setInvitePotentialSupplierData({
                                                supplierId:
                                                  potentialSupplier?.PotentialSupplierId,
                                                supplierName:
                                                  potentialSupplier?.PotentialSupplierName,
                                                supplierEmail:
                                                  potentialSupplier?.PotentialSupplierEmail,
                                                supplierWebsite:
                                                  potentialSupplier?.PotentialSupplierWebsite,
                                                supplierMessage:
                                                  potentialSupplier?.Message,
                                                supplierLogo:
                                                  potentialSupplier?.PotentialSupplierIcon,
                                              });

                                              setPotentialSupplierID(
                                                potentialSupplier?.PotentialSupplierId
                                              );

                                              setPage("addasasupplier");
                                              e.target
                                                .closest(".device-dropdown")
                                                .click();
                                            }}
                                          >
                                            Add as Managed Supplier
                                          </div>
                                        </Col>
                                      )}
                                      {!potentialSupplier?.IsArchived && (
                                        <hr
                                          className=" line-divider-light "
                                          style={{
                                            width: "80%",
                                            margin: "auto",
                                            /* margin-left: auto; */
                                            marginTop: "12px",
                                            marginBottom: "8px",
                                          }}
                                        />
                                      )}
                                      <Col xs={12} className="  pointer">
                                        <div
                                          className="drop-item pl-3"
                                          onClick={(e) => {
                                            archiveUnarchivePotentialSupplier(
                                              potentialSupplier?.PotentialSupplierId,
                                              Number(
                                                potentialSupplier?.IsArchived
                                              )
                                            );
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {potentialSupplier?.IsArchived
                                            ? "Unarchive"
                                            : "Archive"}
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                {!potentialSupplierLoader &&
                  potentialSupplierList?.filter((item) =>
                    item?.PotentialSupplierName?.toLowerCase()?.includes(
                      searchPotentialSupplier?.toLowerCase()
                    )
                  )?.length === 0 && (
                    <div>
                      <div className="text-center f-grey f-500 mt-5">
                        No results found.
                      </div>
                    </div>
                  )}
              </div>
            )}

            {page === "addasasupplier" && (
              <div className="pt-2 pl-2">
                <div className="d-flex align-items-center mb-2">
                  {invitePotentialSupplierData?.supplierLogo?.toLowerCase() ===
                  "noicon" ? (
                    <ImageWithInitials
                      width={32}
                      background="purple"
                      initials={
                        invitePotentialSupplierData?.supplierName?.[0]?.toUpperCase() ??
                        "X"
                      }
                    />
                  ) : (
                    <img
                      alt=""
                      src={invitePotentialSupplierData?.supplierLogo}
                      width={32}
                      height={32}
                      className=" rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/user-logo.svg";
                      }}
                    />
                  )}
                  <div className="ml-2">
                    <p className="m-0 f-500 f-black">
                      {invitePotentialSupplierData?.supplierName}
                    </p>
                  </div>
                </div>

                <div className="my-2 mt-3">
                  To add{" "}
                  <strong>{invitePotentialSupplierData?.supplierName}</strong>{" "}
                  to your supply chain, please enter their HighGround unique
                  Supply Chain ID
                </div>

                <div className="d-flex align-items-center search-supplier-input highlight-input mt-2">
                  <input
                    type="text"
                    className=" w-100 border-none bg-transparent"
                    placeholder="Enter suppliers unique Supply Chain ID"
                    value={supplierToInvite}
                    onChange={(e) => {
                      if (e.target.value?.length > 11) return;
                      let foo = e.target.value.split("-").join("");
                      if (foo.length > 0) {
                        foo = foo.match(new RegExp(".{1,3}", "g")).join("-");
                      }
                      setSupplierToInvite(foo);
                      if (e.target.value?.length === 11) {
                        // InviteSupplierToSupplierChain(foo, true);
                      }
                    }}
                  />
                  {supplierToInvite && (
                    <img
                      src="/images/settings-v2/header-search-cross.svg"
                      alt=""
                      className="pointer mr-2"
                      onClick={() => {
                        setSupplierToInvite("");
                      }}
                    />
                  )}
                </div>
                <div
                  className="f-blue my-2 mt-3 pointer"
                  onClick={() => {
                    setPage("invitesuppliertohighground");
                  }}
                >
                  Request unique Supply Chain ID or Invite them to join
                  HighGround
                </div>

                {supplierToInvite !== "" && supplierToInvite?.length < 11 && (
                  <div className="d-flex text-center flex-column mt-5">
                    <div className="d-flex align-items-center justify-content-center f-500 f-grey">
                      Enter in format XXX-XXX-XXX
                    </div>
                    <div className="d-flex align-items-center justify-content-center f-darkgrey">
                      Please enter suppliers supply-chain ID in order to find
                      them.
                    </div>
                  </div>
                )}
              </div>
            )}
            {page === "invitesuppliertohighground" && (
              <div className="pt-2 pl-2">
                <div className="d-flex align-items-center mb-2">
                  {invitePotentialSupplierData?.supplierLogo?.toLowerCase() ===
                  "noicon" ? (
                    <ImageWithInitials
                      width={32}
                      background="purple"
                      initials={
                        invitePotentialSupplierData?.supplierName?.[0]?.toUpperCase() ??
                        "X"
                      }
                    />
                  ) : (
                    <img
                      alt=""
                      src={invitePotentialSupplierData?.supplierLogo}
                      width={32}
                      height={32}
                      className=" rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/user-logo.svg";
                      }}
                    />
                  )}
                  <div className="ml-2">
                    <p className="m-0 f-500 f-black">
                      {invitePotentialSupplierData?.supplierName}
                    </p>
                  </div>
                </div>

                <div className="my-2 mt-3">
                  You are about to send request to
                  <strong>{invitePotentialSupplierData?.supplierName}</strong>
                  to join HighGround.
                </div>

                <div className="f-500 mt-3">Email</div>

                <div className="d-flex align-items-center search-supplier-input highlight-input ">
                  <input
                    // disabled
                    type="text"
                    className=" w-100 border-none bg-transparent"
                    placeholder="Enter email here"
                    value={invitePotentialSupplierData?.supplierEmail}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (
                        value.match(
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                        )
                      ) {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierWebsite: `www.${value?.split("@")[1]}`,
                          supplierEmail: value,
                        }));
                      } else {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierEmail: value,
                        }));
                      }

                      // autoFillWebsite(e.target.value);
                    }}
                  />
                  {/* {invitePotentialSupplierData?.supplierEmail && (
                    <img
                      src="/images/settings-v2/header-search-cross.svg"
                      alt=""
                      className="pointer mr-2"
                      onClick={() => {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierEmail: "",
                        }));
                      }}
                    />
                  )} */}
                </div>
                <div className="f-500 mt-3">Website</div>

                <div className="d-flex align-items-center search-supplier-input highlight-input ">
                  <input
                    type="text"
                    className=" w-100 border-none bg-transparent"
                    placeholder="Enter website here"
                    value={invitePotentialSupplierData?.supplierWebsite}
                    onChange={(e) => {
                      let value = e.target.value;
                      setInvitePotentialSupplierData((prev) => ({
                        ...prev,
                        supplierWebsite: value,
                      }));
                    }}
                  />
                  {invitePotentialSupplierData?.supplierWebsite && (
                    <img
                      src="/images/settings-v2/header-search-cross.svg"
                      alt=""
                      className="pointer mr-2"
                      onClick={() => {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierWebsite: "",
                        }));
                      }}
                    />
                  )}
                </div>
                <textarea
                  placeholder="Type your message here..."
                  className="w-100 p-2 bg-grey radius-4 highlight-input mt-2"
                  rows={9}
                  value={invitePotentialSupplierData?.supplierMessage}
                  onChange={(e) => {
                    let value = e.target.value;
                    setInvitePotentialSupplierData((prev) => ({
                      ...prev,
                      supplierMessage: window.sanitizeHGtext(value),
                    }));
                  }}
                ></textarea>
              </div>
            )}
            {page === "newinvitesupplier" && (
              <div className="pt-2 pl-2">
                <div className="d-flex align-items-center mb-2">
                  {invitePotentialSupplierData?.supplierLogo?.toLowerCase() ===
                  "noicon" ? (
                    <ImageWithInitials
                      width={32}
                      background="purple"
                      initials={
                        invitePotentialSupplierData?.supplierName?.[0]?.toUpperCase() ??
                        "X"
                      }
                    />
                  ) : (
                    <img
                      alt=""
                      src={invitePotentialSupplierData?.supplierLogo}
                      width={32}
                      height={32}
                      className=" rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/user-logo.svg";
                      }}
                    />
                  )}
                  <div className="w-100 ml-2">
                    <div
                      className={`  ${
                        invitePotentialSupplierData?.nameError
                          ? "input-error"
                          : ""
                      }   w-100 d-flex align-items-center search-supplier-input highlight-input mt-0`}
                    >
                      <input
                        type="text"
                        className=" w-100 border-none bg-transparent"
                        placeholder="Supplier Name"
                        value={invitePotentialSupplierData?.supplierName}
                        onChange={(e) => {
                          let value = e.target.value;
                          setInvitePotentialSupplierData((prev) => ({
                            ...prev,
                            supplierName: value,
                            nameError: "",
                          }));
                        }}
                      />
                      {newSupplierName && (
                        <img
                          src="/images/settings-v2/header-search-cross.svg"
                          alt=""
                          className="pointer mr-2"
                          onClick={() => {
                            setNewSupplierName("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="my-2 mt-3">
                  You are about to send request to Supplier to join
                  <strong> HighGround</strong>.
                </div>

                <div className="f-500 mt-3">Email</div>
 
                <div
                  className={`  ${
                    (invitePotentialSupplierData?.emailError ||  (
                      invitePotentialSupplierData?.supplierEmail &&
                      !validateEmail(invitePotentialSupplierData?.supplierEmail)
                    )   )? "input-error" : ""
                  }    d-flex align-items-center search-supplier-input highlight-input `}
                >
                  <input
                    type="text"
                    className=" w-100 border-none bg-transparent"
                    placeholder="Enter email here"
                    value={invitePotentialSupplierData?.supplierEmail}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (
                        value.match(
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                        )
                      ) {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierWebsite: `www.${value?.split("@")[1]}`,
                          supplierEmail: value,
                          emailError: "",
                          websiteError: "",
                        }));
                      } else {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierEmail: value,
                          emailError: "",
                        }));
                      }
                    }}
                  />
                  {invitePotentialSupplierData?.supplierEmail && (
                    <img
                      src="/images/settings-v2/header-search-cross.svg"
                      alt=""
                      className="pointer mr-2"
                      onClick={() => {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierEmail: "",
                        }));
                      }}
                    />
                  )}
                </div>
                <div className="f-500 mt-3">Website</div>

                <div
                  className={`    ${
                    invitePotentialSupplierData?.websiteError
                      ? "input-error"
                      : ""
                  }   d-flex align-items-center search-supplier-input highlight-input `}
                >
                  <input
                    type="text"
                    className=" w-100 border-none bg-transparent"
                    placeholder="Enter website here"
                    value={invitePotentialSupplierData?.supplierWebsite}
                    onChange={(e) => {
                      let value = e.target.value;
                      setInvitePotentialSupplierData((prev) => ({
                        ...prev,
                        supplierWebsite: value,
                        websiteError: "",
                      }));
                    }}
                  />
                  {invitePotentialSupplierData?.supplierWebsite && (
                    <img
                      src="/images/settings-v2/header-search-cross.svg"
                      alt=""
                      className="pointer mr-2"
                      onClick={() => {
                        setInvitePotentialSupplierData((prev) => ({
                          ...prev,
                          supplierWebsite: "",
                        }));
                      }}
                    />
                  )}
                </div>
                <textarea
                  placeholder="Type your message here..."
                  className="w-100 p-2 bg-grey radius-4 highlight-input mt-2"
                  rows={9}
                  value={invitePotentialSupplierData?.supplierMessage}
                  onChange={(e) => {
                    let value = e.target.value;
                    setInvitePotentialSupplierData((prev) => ({
                      ...prev,
                      supplierMessage: window.sanitizeHGtext(value),
                    }));
                  }}
                ></textarea>
              </div>
            )}
            {page === "cancelRequest" && (
              <div>
                <div className="px-2 pt-3">
                  {/* User Details */}
                  <div className="d-flex align-items-center">
                    <img
                      src={supplierLogo}
                      alt=""
                      className="mr-2 rounded-full"
                      width={48}
                      height={48}
                    />
                    <div>
                      <p className="f-16 f-600 m-0 mb-1">{supplierName}</p>
                      <p className="f-14 f-500 f-darkgrey m-0">
                        {supplierSupplyChainId}
                      </p>
                    </div>
                  </div>
                  <p className="f-black f-500 mt-3">
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: responseModalText?.Title,
                        }}
                      />
                    }
                  </p>
                  <div className="f-14 f-500 f-black mt-4">Response</div>
                  <textarea
                    rows={10}
                    className="hg-border radius-4 w-100 mt-2 p-2"
                    placeholder={`${
                      responseModalText?.ResponseGreyText ??
                      "Type your message..."
                    }`}
                    value={message}
                    onChange={(e) => {
                      setMessage(window.sanitizeHGtext(e.target.value));
                    }}
                  ></textarea>
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>

      {page === "client" && contextMenuData?.ButtonMenuItems?.length > 0 && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className={` ${
                contextMenuData?.ButtonMenuItems?.[0]?.menuActionLabel ===
                "HideButton"
                  ? "d-none"
                  : ""
              }    hg-cancel-btn mr-2`}
              onClick={() => {
                if (
                  contextMenuData?.ButtonMenuItems?.[0]?.menuActionLabel?.toLowerCase() ===
                  "cancel"
                ) {
                  hideModal();
                  return;
                }

                if (
                  contextMenuData?.ButtonMenuItems?.[0]?.newRequestStatus_Id ===
                  2
                ) {
                  // Condition for Denying
                  getRequestDetails(
                    requestDetails?.Id,
                    requestDetails?.status,
                    requestDetails?.relationId
                  );
                  setPage("cancelRequest");
                  setCancelType("deny");
                  setCancelRequestStatus(2);
                  return;
                }
                setDenying(true);
                updateStatus(
                  contextMenuData?.ButtonMenuItems?.[0]?.newRequestStatus_Id,
                  relationShipID
                );
              }}
            >
              {denying && <GreyLoader width={12} />}
              {contextMenuData?.ButtonMenuItems?.[0]?.menuActionLabel}
            </Button>

            {contextMenuData?.ButtonMenuItems?.[1] && (
              <Button
                className="hg-blue-btn"
                disabled={
                  false
                  // contextMenuData?.allowEditAccessAndCompliance &&
                  // (!selectedComplianceProfile || !selectedAccessLevel?.Name)
                  //   ? true
                  //   : false
                }
                onClick={() => {
                  
                  // Complinace profile Validation
                  if (
                    contextMenuData?.allowEditAccessAndCompliance &&
                    !selectedComplianceProfile
                  ) {
                    CallToast(
                      "Please select a Compliance profile.",
                      false,
                      false,
                      "v2style"
                    );
                    return;
                  }
                  setProcessing(true);

                  if (contextMenuData?.allowEditAccessAndCompliance) {
                    sendRequest();
                  } else {
                    updateStatus(
                      contextMenuData?.ButtonMenuItems?.[1]
                        ?.newRequestStatus_Id,
                      relationShipID
                    );
                  }
                }}
              >
                <div className="d-flex align-items-center">
                  {processing && <WhiteLoader width={12} />}

                  {contextMenuData?.ButtonMenuItems?.[1]?.menuActionLabel}
                </div>
              </Button>
            )}
          </div>
        </Modal.Footer>
      )}

      {/* Footer for Add as a supplier */}
      {page === "addasasupplier" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              disabled={supplierToInvite?.length < 11 ? true : false}
              onClick={() => {
                if (supplierToInvite?.length === 11) {
                  setProcessing(true);
                  InviteSupplierToSupplierChain(supplierToInvite, true);
                }
              }}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                Continue
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}

      {/* Footer for Invite Supplier to Highground */}
      {page === "invitesuppliertohighground" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              onClick={() => {
                setProcessing(true);

                InviteSupplierToHighGround();
              }}
              disabled={
                invitePotentialSupplierData?.supplierEmail.match(
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                )
                  ? false
                  : true
              }
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                Send
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}
      {/* Footer for Invite Supplier to Highground */}
      {page === "newinvitesupplier" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              onClick={() => {
                let error = 0;
                if (invitePotentialSupplierData?.supplierName === "") {
                  error += 1;
                  setInvitePotentialSupplierData((prev) => ({
                    ...prev,

                    nameError: "xxxx",
                  }));
                }
                if (invitePotentialSupplierData?.supplierEmail === "" || !validateEmail(invitePotentialSupplierData?.supplierEmail)) {
                  error += 1;
                  setInvitePotentialSupplierData((prev) => ({
                    ...prev,

                    emailError: "xxxx",
                  }));
                }
                if (invitePotentialSupplierData?.supplierWebsite === "") {
                  error += 1;
                  setInvitePotentialSupplierData((prev) => ({
                    ...prev,

                    websiteError: "xxxx",
                  }));
                }

                if (error > 0) return;
                setProcessing(true);
                InviteSupplierToHighGround();
              }}
              disabled={
                false
                // invitePotentialSupplierData?.supplierEmail.match(
                //   /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                // ) && invitePotentialSupplierData?.supplierName !== ""
                //   ? false
                //   : true
              }
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                Send
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}

      {/* Footer for cancel Request (suspend/remove/deny) to Highground */}
      {page === "cancelRequest" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              onClick={() => {
                cancelRequest();
              }}
              disabled={message === ""}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                {capitalize(cancelType)}
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}

      <Suspense fallback={<div>Loading...</div>}>
        <AddComplianceProfileModal
          show={showAddComplianceModal}
          complianceProfileId={0}
          hideModal={() => setShowAddComplianceModal(false)}
          refreshData={() => {
            getComplianceProfiles(supplierId);
            if (refreshData) refreshData();
          }}
        />
      </Suspense>
      <AccessLevelModal
        show={showAccessLevelModal}
        hideModal={() => setShowAccessLevelModal(false)}
        refreshData={() => {
          getAccessLevels();
        }}
      />
    </Modal>
  );
};

export default RequestsModal;

function makeGroup(list, key) {
  if (list?.length === 0) return;
  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition][key] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      }
    }
  }

  return resultArr;
}

const dummyData = [
  {
    data: [
      {
        Section: "General Permissions",
        SubSection: "Primary KPIs",
        Permission_List: [
          {
            ObjectId: 3346236,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346237,
            Level: 1,
            Permission: "View KPI",
            Rights: "View KPI Value Only",
          },
          {
            ObjectId: 3346238,
            Level: 2,
            Permission: "View Drilldown Summary",
            Rights: "View KPI and Drilldown Summary",
          },
          {
            ObjectId: 3346239,
            Level: 3,
            Permission: "View Detailed Drilldown",
            Rights: "View KPI, Drilldown Summary and Drilldown Details",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Role Based Cyber Data\t",
        Permission_List: [
          {
            ObjectId: 3346240,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346241,
            Level: 1,
            Permission: "View KPI",
            Rights: "View KPI Value Only",
          },
          {
            ObjectId: 3346242,
            Level: 2,
            Permission: "View Drilldown Summary",
            Rights: "View KPI and Drilldown Summary",
          },
          {
            ObjectId: 3346244,
            Level: 1,
            Permission: "View Trend Data",
            Rights: "View Trend Data",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Cyber Trend Data",
        Permission_List: [
          {
            ObjectId: 3346243,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Technical Drilldown",
        Permission_List: [
          {
            ObjectId: 3346245,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346246,
            Level: 1,
            Permission: "View Health",
            Rights: "View health of the data for every technology",
          },
          {
            ObjectId: 3346247,
            Level: 2,
            Permission: "View Tools",
            Rights:
              "View health of the data for every technology and details of the connected tool",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "General Permissions",
        SubSection: "Integrations",
        Permission_List: [
          {
            ObjectId: 3346248,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346249,
            Level: 1,
            Permission: "View Health",
            Rights:
              "View health of every integration, inc. if a tool has been connected or not",
          },
          {
            ObjectId: 3346250,
            Level: 2,
            Permission: "View Tools",
            Rights:
              "View health of every integration, inc. if a tool has been connected or not, tool name, last synch date/time and sync health",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
    ],
    Section: "General Permissions",
  },
  {
    data: [
      {
        Section: "Compliance Manager",
        SubSection: "Cyber Essentials",
        Permission_List: [
          {
            ObjectId: 3346251,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346252,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Cyber Essentials",
          },
          {
            ObjectId: 3346253,
            Level: 2,
            Permission: "View Certificate",
            Rights:
              "View overall status of your Cyber Essentials and view/download a copy of your Certificate",
          },
          {
            ObjectId: 3346254,
            Level: 3,
            Permission: "View Audit Summary",
            Rights:
              "View overall status of your Cyber Essentials,  view/download a copy of your Certificate and view your summary details for your Cyber Essential Audits",
          },
          {
            ObjectId: 3346255,
            Level: 4,
            Permission: "View Audit Details",
            Rights:
              "View overall status of your Cyber Essentials, view/download a copy of your Certificate and view your full Cyber Essentials Audits",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Incident Response",
        Permission_List: [
          {
            ObjectId: 3346256,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346257,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Incident Response",
          },
          {
            ObjectId: 3346258,
            Level: 2,
            Permission: "View IR Documents",
            Rights:
              "View overall status of your Incident Response and view/download a copy of your IR Documents",
          },
          {
            ObjectId: 3346259,
            Level: 3,
            Permission: "View Review Summary",
            Rights:
              "View overall status of your Incident Response, view/download a copy of your IR Documents and view summary details of your IR Reviews",
          },
          {
            ObjectId: 3346260,
            Level: 4,
            Permission: "View Review Details",
            Rights:
              "View overall status of your Incident Response, view/download a copy of your IR Documents and view your full IR Reviews",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Information Security Policies",
        Permission_List: [
          {
            ObjectId: 3346261,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346262,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Information Security Policies",
          },
          {
            ObjectId: 3346263,
            Level: 2,
            Permission: "View Recommended + Cyber Essentials Policies Summary",
            Rights:
              "View overall status of your Information Security Policies and summary details of your Recommended and Cyber Essentials Policies",
          },
          {
            ObjectId: 3346264,
            Level: 3,
            Permission: "View Recommended + Cyber Essentials Policies",
            Rights:
              "View overall status of your Information Security Policies and view/download a copy of your Recommended and Cyber Essentials Policies",
          },
          {
            ObjectId: 3346265,
            Level: 4,
            Permission: "View All Policies Summary",
            Rights:
              "View overall status of your Information Security Policies and summary details of your Recommended, Cyber Essentials and 'My Policies' Policies",
          },
          {
            ObjectId: 3346266,
            Level: 5,
            Permission: "View All Policies",
            Rights:
              "View overall status of your Information Security Policies and view/download a copy of your Recommended, Cyber Essentials and 'My Policies' Policies",
          },
          {
            ObjectId: 3346267,
            Level: 0,
            Permission: "0",
            Rights: "0",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Risk Assessments",
        Permission_List: [
          {
            ObjectId: 3346268,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346269,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Risk Assessments",
          },
          {
            ObjectId: 3346270,
            Level: 2,
            Permission: "View Assessment Summary",
            Rights:
              "View overall status of your Risk Assessments and summary details of your Risk Assessments",
          },
          {
            ObjectId: 3346271,
            Level: 3,
            Permission: "View Assessment Details",
            Rights:
              "View overall status of your Risk Assessments and your full Risk Assessments",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "Annual Cyber Reviews",
        Permission_List: [
          {
            ObjectId: 3346272,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346273,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your Annual Cyber Reviews",
          },
          {
            ObjectId: 3346274,
            Level: 2,
            Permission: "View Assessment Summary",
            Rights:
              "View overall status of your Annual Cyber Reviews and summary details of your Annual Cyber Reviews",
          },
          {
            ObjectId: 3346275,
            Level: 3,
            Permission: "View Assessment Details",
            Rights:
              "View overall status of your Annual Cyber Reviewss and your full Annual Cyber Reviews",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
      {
        Section: "Compliance Manager",
        SubSection: "BCDR",
        Permission_List: [
          {
            ObjectId: 3346276,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
          {
            ObjectId: 3346277,
            Level: 1,
            Permission: "View Status",
            Rights: "View overall status of your IT BCDR Planning",
          },
          {
            ObjectId: 3346278,
            Level: 2,
            Permission: "View BCDR Plan Documents",
            Rights:
              "View overall status of your IT BCDR Planning and view/download a copy of your IT BCDR Plan. Note: your SOPs are not visible to the client",
          },
          {
            ObjectId: 3346279,
            Level: 3,
            Permission: "View BCDR Test Summary",
            Rights:
              "View overall status of your IT BCDR Planning, view/download a copy of your IT BCDR Plan and view summary details of your IT BCDR Tests",
          },
          {
            ObjectId: 3346280,
            Level: 4,
            Permission: "View BCDR Test Details",
            Rights:
              "View overall status of your IT BCDR Planning, view/download a copy of your IT BCDR Plan and view your full IT BCDR Tests",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
    ],
    Section: "Compliance Manager",
  },
  {
    data: [
      {
        Section: "Attack Surface",
        SubSection: null,
        Permission_List: [
          {
            ObjectId: 3346281,
            Level: 0,
            Permission: "No Access",
            Rights: "No Access",
          },
        ],
        PermissionSelected: null,
        mr: null,
      },
    ],
    Section: "Attack Surface",
  },
];


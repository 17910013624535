import { useMemo } from "react";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";

const useDateDefineds = () => {
  const defineds = useMemo(() => (
    {
      startOfWeek: startOfWeek(new Date()),
      endOfWeek: endOfWeek(new Date()),
      startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
      endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
      startOfToday: startOfDay(new Date()),
      startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
      startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
      startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
      endOfToday: endOfDay(new Date()),
      startOfYesterday: startOfDay(addDays(new Date(), -1)),
      endOfYesterday: endOfDay(addDays(new Date(), -1)),
      startOfMonth: startOfMonth(new Date()),
      endOfMonth: endOfMonth(new Date()),
      startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
      endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
      startOfYear: startOfYear(new Date()),
      endOfYear: endOfYear(new Date()),
      startOflastYear: startOfYear(addYears(new Date(), -1)),
      endOflastYear: endOfYear(addYears(new Date(), -1)),
      startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
      endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
      startOfLastSixMonth: startOfDay(subMonths(new Date(), 6)),
      startOfLastTwelveMonth: startOfDay(subYears(new Date(), 1)),
      startOfCurrentYear: startOfYear(new Date()),
      endOfPreviousYear: endOfYear(subYears(new Date(), 1)),
    }
  ), []);

  return defineds;
}

export default useDateDefineds;

import { Button } from "react-bootstrap";

const InputFilterType = ({
  item,
  inputType,
  inputValue,
  setInputValue,
  onCancel,
  onApply
}) => {
  return (
    <div className='modal-input'>
      <div className='d-flex align-items-center' style={{ gap: '8px' }}>
         <h3 className='m-0 label f-black'>
           {item.label}
         </h3>
         <input
           type={inputType}
           className='highlight-input-with-grey-border'
           value={inputValue}
           onChange={(e) => {
             setInputValue(e.target.value);
           }}
         />
       </div>
       <div className='d-flex justify-content-end align-items-center' style={{ gap: '8px' }}>
         <Button 
           className='hg-cancel-btn'
           onClick={onCancel}
         >
           Cancel
         </Button>
         <Button 
           className='hg-blue-btn'
           onClick={onApply}
         >
           Apply
         </Button>
       </div>
    </div>
  )
}

export default InputFilterType;

export const MINIMAL_DATE = new Date(0);
export const NO_DATE = "0001-01-01T00:00:00";

export const APP_PORTALS = {
  mspv2: '/msp',
  clientmspsettingsv2: '/companymspsettings',
  mspsettingsv2: '/mspsettings',
  client: '',
}

export const APP_ROUTES = {
  mspv2Type: `${APP_PORTALS.mspv2}/:type`,
  mspv2: {
    createClient: `${APP_PORTALS.mspv2}/create-company`,
    securityStack: `${APP_PORTALS.mspv2}/securitystack`,
    securityStackSections: {
      mapIntegrations: `${APP_PORTALS.mspv2}/securitystack/mapIntegrations`,
      mapIntegrationsGR: `${APP_PORTALS.mspv2}/securitystack/mapIntegrationsGR`,
    },
    clientsView: `${APP_PORTALS.mspv2}/companies`,
    archivedClientsView: `${APP_PORTALS.mspv2}/companies/archived-companies`,
    clientsViewWithPage: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/:pageName`,
    priceCalculator: `${APP_PORTALS.mspv2}/msp-price-calculator/:packageId`,
    marketplace: `${APP_PORTALS.mspv2}/marketplace/:marketplaceId`,
    clients: {
      technologySpend: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/technology-spend`,
      attackSurface: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/assets`,
      license: `${APP_PORTALS.clientmspsettingsv2}/:clientId/company-settings`,
      securityPackage: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/security-packages`,
      dashboard: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/dashboard`,
      companySettings: `${APP_PORTALS.clientmspsettingsv2}/:clientId/company-settings`,
      clientAccess: `${APP_PORTALS.clientmspsettingsv2}/:clientId/client-access-levels`,
      complianceManager: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager`,
      complianceManagerSections: {
        cyberEssentialStatus: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Cyber_Essential_Status`,
        irPlanStatus: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/IR_Plan_Status`,
        riskAssessment: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Risk_Assessment`,
        annualCyberReview: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Annual_Cyber_Review`,
        testRecovery: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Test_Recovery`,
        infosecPolicies: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Infosec_Policies`,
        form: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Form`,
        securityFrameworks: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Security_Frameworks`,
        dataPrivacyFrameworks: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Data_Privacy_Frameworks`,
        cyberInsurancePolicies: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/Compliance_Manager/Cyber_Insurance_Policies`,
      },
      integrations: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/integrations`,
      integrationDetail: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/integrations/:integrationId`,
      storefront: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/storefront`,
      storefrontTest: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/storefrontTest`,
      trendData: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/trend_data`,
      technicalDrilldown: `${APP_PORTALS.mspv2}/companies/companiesView/:clientId/technical_drilldown`,
    },
    integrationDetails: `${APP_PORTALS.mspv2}/integrationDetails/:integrationId`,
    integrations: `${APP_PORTALS.mspv2}/integrations`,
    mappings: `${APP_PORTALS.mspv2}/mappings`,
    sell: `${APP_PORTALS.mspv2}/sell`,
    sellPackages: `${APP_PORTALS.mspv2}/sell/packages`,
    sellServices: `${APP_PORTALS.mspv2}/sell/services`,
    sellSettings: `${APP_PORTALS.mspv2}/sell/settings`,
    sellRequests: `${APP_PORTALS.mspv2}/sell/requests`,
    sellProducts: `${APP_PORTALS.mspv2}/sell/products`,
    sellTemplates: `${APP_PORTALS.mspv2}/sell/templates`,
    sellStorefront: `${APP_PORTALS.mspv2}/sell/storefront`,
    sellSettingsCategories: `${APP_PORTALS.mspv2}/sell/settings/categories`,
    sellSettingsCategory: `${APP_PORTALS.mspv2}/sell/settings/category/:categoryId`,
    sellSettingsBanners: `${APP_PORTALS.mspv2}/sell/settings/banners`,
    sellSettingsSubcategory: `${APP_PORTALS.mspv2}/sell/settings/category/:categoryId/subcategory/:subcategoryId`,
    secops: `${APP_PORTALS.mspv2}/secops`,
    insights: `${APP_PORTALS.mspv2}/insights`,
    secopsSections: {
      secutiryProcesses: `${APP_PORTALS.mspv2}/secops/security-processes`,
      operationalSecutiry: `${APP_PORTALS.mspv2}/secops/operational-security`,
      securityAssets: `${APP_PORTALS.mspv2}/secops/operational-security/security-assets`,
      secutiryBaseline: `${APP_PORTALS.mspv2}/secops/security-baseline`,
      frameworks: `${APP_PORTALS.mspv2}/secops/frameworks`,
      frameworksAssets: `${APP_PORTALS.mspv2}/secops/frameworks/frameworks/assets`,
      trainingCertificates: `${APP_PORTALS.mspv2}/secops/training-certificates`,
      accreditations: `${APP_PORTALS.mspv2}/secops/accreditations`,
    },
    actionCenter: `${APP_PORTALS.mspv2}/action_center`,
    audit: `${APP_PORTALS.mspv2}/audit`,
  },
  clientmspsettingsv2Type: `${APP_PORTALS.clientmspsettingsv2}/:type`,
  clientmspsettingsv2: {
    actionCentre: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/action_centre`,
    notifications: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/notifications`,
    cSuite: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/c-suite`,
    companySettings: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/company-settings`,
    clientAccessLevels: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/client-access-levels`,
    clientAccessLevelsMember: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/client-access-levels/:memberId`,
    resetSettings: `${APP_PORTALS.clientmspsettingsv2}/:mspClientId/reset-settings`,
  },
  mspsettingsv2Type: `${APP_PORTALS.mspsettingsv2}/:type`,
  mspsettingsv2: {
    myProfile: `${APP_PORTALS.mspsettingsv2}/my_profile`,
    notifications: `${APP_PORTALS.mspsettingsv2}/notifications`,
    cSuite: `${APP_PORTALS.mspsettingsv2}/c-suite`,
    activeSessions: `${APP_PORTALS.mspsettingsv2}/active_sessions`,
    companySettings: `${APP_PORTALS.mspsettingsv2}/company-settings`,
    clientAccessLevels: `${APP_PORTALS.mspsettingsv2}/client-access-levels`,
    memberaccess: `${APP_PORTALS.mspsettingsv2}/memberaccess`,
    memberaccessUser: `${APP_PORTALS.mspsettingsv2}/memberaccess/:user`,
    team: `${APP_PORTALS.mspsettingsv2}/team`,
    teamUser: `${APP_PORTALS.mspsettingsv2}/team/:user`,
    rewards: `${APP_PORTALS.mspsettingsv2}/rewards`,
    subscriptions: `${APP_PORTALS.mspsettingsv2}/subscriptions`,
    billing: `${APP_PORTALS.mspsettingsv2}/billing`,
    actionCentre: `${APP_PORTALS.mspsettingsv2}/action_centre`,
    resetSettings: `${APP_PORTALS.mspsettingsv2}/reset_settings`,
  },
  client: {
    dashboard: `${APP_PORTALS.client}/dashboard`,
    technologySpend: `${APP_PORTALS.client}/technology-spend`,
    attackSurface: `${APP_PORTALS.client}/assets`,
    userIssues: `${APP_PORTALS.client}/assets?section=MFA`,
    deviceIssues: `${APP_PORTALS.client}/assets?section=EOL`,
    license: `${APP_PORTALS.client}/settings/v2/my_profile`,
    securityPackage: `${APP_PORTALS.client}/security-packages`,
    complianceManager: `${APP_PORTALS.client}/Compliance_Manager`,
    complianceManagerSections: {
      cyberEssentialStatus: `${APP_PORTALS.client}/Compliance_Manager/Cyber_Essential_Status`,
      irPlanStatus: `${APP_PORTALS.client}/Compliance_Manager/IR_Plan_Status`,
      riskAssessment: `${APP_PORTALS.client}/Compliance_Manager/Risk_Assessment`,
      annualCyberReview: `${APP_PORTALS.client}/Compliance_Manager/Annual_Cyber_Review`,
      testRecovery: `${APP_PORTALS.client}/Compliance_Manager/Test_Recovery`,
      infosecPolicies: `${APP_PORTALS.client}/Compliance_Manager/Infosec_Policies`,
      securityFrameworks: `${APP_PORTALS.client}/Compliance_Manager/Security_Frameworks`,
      dataPrivacyFrameworks: `${APP_PORTALS.client}/Compliance_Manager/Data_Privacy_Frameworks`,
      cyberInsurancePolicies: `${APP_PORTALS.client}/Compliance_Manager/Cyber_Insurance_Policies`,
      form: `${APP_PORTALS.client}/Compliance_Manager/Form`,
    },
    complianceManagerPageType: `${APP_PORTALS.client}/Compliance_Manager/:pageType`,
    integrations: `${APP_PORTALS.client}/integrations`,
    integrationDetail: `${APP_PORTALS.client}/integrations/:integrationId`,
    storefront: `${APP_PORTALS.client}/storefront`,
    storefrontTest: `${APP_PORTALS.client}/storefrontTest`,
    settingsType: `${APP_PORTALS.client}/settings/v2/:type`,
    settings: {
      myProfile: `${APP_PORTALS.client}/settings/v2/my_profile`,
      notifications: `${APP_PORTALS.client}/settings/v2/notifications`,
      cSuite: `${APP_PORTALS.client}/settings/v2/c-suite`,
      activeSessions: `${APP_PORTALS.client}/settings/v2/active_sessions`,
      companySettings: `${APP_PORTALS.client}/settings/v2/company-settings`,
      memberaccess: `${APP_PORTALS.client}/settings/v2/memberaccess`,
      memberaccessMember: `${APP_PORTALS.client}/settings/v2/memberaccess/:member`,
      rewards: `${APP_PORTALS.client}/settings/v2/rewards`,
      subscriptions: `${APP_PORTALS.client}/settings/v2/subscriptions`,
      billing: `${APP_PORTALS.client}/settings/v2/billing`,
      actionCentre: `${APP_PORTALS.client}/settings/v2/action_centre`,
      resetSettings: `${APP_PORTALS.client}/settings/v2/reset_settings`,
      team: `${APP_PORTALS.client}/settings/v2/team`,
    },
    submitNewPassword: `${APP_PORTALS.client}/SubmitNewPassword`,
    systemSettings: `${APP_PORTALS.client}/system_settings/user_profile/:id`,
    test: `${APP_PORTALS.client}/test`,
    handlesubscription3ds: `${APP_PORTALS.client}/handlesubscription3ds`,
    supplierProfilerType: `${APP_PORTALS.client}/supplier-profiler/:type`,
    supplierProfiler: `${APP_PORTALS.client}/supplier-profiler`,
    trendData: `${APP_PORTALS.client}/trend_data`,
    createSupportTicket: `${APP_PORTALS.client}/create_support_ticket`,
    design_system: `${APP_PORTALS.client}/design_system`,
    auditLogs: `${APP_PORTALS.client}/audit-logs`,
    marketplace: `${APP_PORTALS.client}/marketplace`,
    actionCentre: `${APP_PORTALS.client}/action_centre`,
    personalSettings: `${APP_PORTALS.client}/personal_settings`,
    technicalDrilldown: `${APP_PORTALS.client}/technical_drilldown`,
    technicalDrilldownPageType: `${APP_PORTALS.client}/technical_drilldown/:pageType`,
    technicalDrilldownSections: {
      patchManagement: `${APP_PORTALS.client}/technical_drilldown/Patch_Management`,
      incidentResponse: `${APP_PORTALS.client}/technical_drilldown/Incident_Response`,
      endpointProtection: `${APP_PORTALS.client}/technical_drilldown/Endpoint_Protection`,
      webFiltering: `${APP_PORTALS.client}/technical_drilldown/Web_Filtering`,
      phishingTraining: `${APP_PORTALS.client}/technical_drilldown/Phishing_Training`,
      siemIDS: `${APP_PORTALS.client}/technical_drilldown/SIEM/IDS`,
      emailFiltering: `${APP_PORTALS.client}/technical_drilldown/Email_Filtering`,
      websiteScan: `${APP_PORTALS.client}/technical_drilldown/Website_Scan`,
      externalNetworkScan: `${APP_PORTALS.client}/technical_drilldown/External_Network_Scan`,
      darkWebMonitoring: `${APP_PORTALS.client}/technical_drilldown/Dark_Web_Monitoring`,
      preData: `${APP_PORTALS.client}/technical_drilldown/preData/:name`,
    },
    integrationsV2: `${APP_PORTALS.client}/integrationsV2`,
  },
  auth: {
    login: '/login',
    loginAnonymous: '/login/anonymous',
    forgotPassword: '/login/forgot-password',
    resetPassword: '/login/reset-password',
    clientRegistration: '/login/client-registration',
    forgottenPassword: '/forgottenPassword',
    loginMfa: '/login-mfa',
    signup: '/signup',
    logout: '/logout',
    signUpReferraltoken: '/signup/:referraltoken',
    submitNewPassword: '/submitNewPassword',
  },
  anonymous: '/anonymous',
  error: '/error',
  noAccessToMsp: '/no-access-to-msp',
  noAccessToHighground: '/no-access-to-highground',
  sessionExpiry: '/session-expiry',
}

export const ROUTE_PATHS = {
  mspv2: {
    securityStack: APP_ROUTES.mspv2.securityStack,
    securityStackSections: {
      mapIntegrations: APP_ROUTES.mspv2.securityStackSections.mapIntegrations,
      mapIntegrationsGR: APP_ROUTES.mspv2.securityStackSections.mapIntegrationsGR,
    },
    clientsView: APP_ROUTES.mspv2.clientsView,
    clients: {
      technologySpend: (clientId) => APP_ROUTES.mspv2.clients.technologySpend.replace(':clientId', clientId),
      attackSurface: (clientId) => APP_ROUTES.mspv2.clients.attackSurface.replace(':clientId', clientId),
      userIssues: (clientId) => `${APP_ROUTES.mspv2.clients.attackSurface.replace(':clientId', clientId)}?section=MFA`,
      deviceIssues: (clientId) => `${APP_ROUTES.mspv2.clients.attackSurface.replace(':clientId', clientId)}?section=EOL`,
      license: (clientId) => APP_ROUTES.mspv2.clients.license.replace(':clientId', clientId),
      securityPackage: (clientId) => APP_ROUTES.mspv2.clients.securityPackage.replace(':clientId', clientId),
      dashboard: (clientId) => APP_ROUTES.mspv2.clients.dashboard.replace(':clientId', clientId),
      companySettings: (clientId) => APP_ROUTES.mspv2.clients.companySettings.replace(':clientId', clientId),
      clientAccess: (clientId) => APP_ROUTES.mspv2.clients.clientAccess.replace(':clientId', clientId),
      complianceManager: (clientId) => APP_ROUTES.mspv2.clients.complianceManager.replace(':clientId', clientId),
      complianceManagerSections: {
        cyberEssentialStatus: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.cyberEssentialStatus.replace(':clientId', clientId),
        irPlanStatus: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.irPlanStatus.replace(':clientId', clientId),
        riskAssessment: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.riskAssessment.replace(':clientId', clientId),
        annualCyberReview: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.annualCyberReview.replace(':clientId', clientId),
        testRecovery: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.testRecovery.replace(':clientId', clientId),
        infosecPolicies: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.infosecPolicies.replace(':clientId', clientId),
        form: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.form.replace(':clientId', clientId),
        securityFrameworks: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.securityFrameworks.replace(':clientId', clientId),
        dataPrivacyFrameworks: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.dataPrivacyFrameworks.replace(':clientId', clientId),
        cyberInsurancePolicies: (clientId) => APP_ROUTES.mspv2.clients.complianceManagerSections.cyberInsurancePolicies.replace(':clientId', clientId),
      },
      integrations: (clientId) => APP_ROUTES.mspv2.clients.integrations.replace(':clientId', clientId),
      integrationDetail: (clientId, integrationId) => APP_ROUTES.mspv2.clients.integrationDetail.replace(':clientId', clientId).replace(':integrationId', integrationId),
      storefront: (clientId) => APP_ROUTES.mspv2.clients.storefront.replace(':clientId', clientId),
      storefrontTest: (clientId) => APP_ROUTES.mspv2.clients.storefrontTest.replace(':clientId', clientId),
      trendData: (clientId) => APP_ROUTES.mspv2.clients.trendData.replace(':clientId', clientId),
      technicalDrilldown: (clientId) => APP_ROUTES.mspv2.clients.technicalDrilldown.replace(':clientId', clientId),
    },
    integrationDetails: (integrationId) => APP_ROUTES.mspv2.integrationDetails.replace(':integrationId', integrationId),
    connectedIntegrations: `${APP_ROUTES.mspv2.integrations}?type=connectedIntegrations`,
    allIntegrations: `${APP_ROUTES.mspv2.integrations}?type=allIntegrations`,
    integrations: APP_ROUTES.mspv2.integrations,
    mappings: APP_ROUTES.mspv2.mappings,
    priceCalculator: (packageId) => APP_ROUTES.mspv2.priceCalculator.replace(':packageId', packageId),
    marketplace: (marketplaceId) => APP_ROUTES.mspv2.marketplace.replace(':marketplaceId', marketplaceId),
    sell: APP_ROUTES.mspv2.sell,
    sellPackages: APP_ROUTES.mspv2.sellPackages,
    sellServices: APP_ROUTES.mspv2.sellServices,
    sellProducts: APP_ROUTES.mspv2.sellProducts,
    sellSettings: APP_ROUTES.mspv2.sellSettings,
    sellTemplates: APP_ROUTES.mspv2.sellTemplates,
    sellStorefront: APP_ROUTES.mspv2.sellStorefront,
    sellRequests: APP_ROUTES.mspv2.sellRequests,
    sellSettingsCategories: APP_ROUTES.mspv2.sellSettingsCategories,
    sellSettingsCategory: (categoryId) => APP_ROUTES.mspv2.sellSettingsCategory.replace(':categoryId', categoryId),
    sellSettingsSubcategory: (categoryId, subcategoryId) => APP_ROUTES.mspv2.sellSettingsSubcategory.replace(':categoryId', categoryId).replace(':subcategoryId', subcategoryId),
    sellSettingsBanners: APP_ROUTES.mspv2.sellSettingsBanners,
    secops: APP_ROUTES.mspv2.secops,
    insights: APP_ROUTES.mspv2.insights,
    secopsSections: {
      secutiryProcesses: APP_ROUTES.mspv2.secopsSections.secutiryProcesses,
      operationalSecutiry: APP_ROUTES.mspv2.secopsSections.operationalSecutiry,
      securityAssets: APP_ROUTES.mspv2.secopsSections.securityAssets,
      secutiryBaseline: APP_ROUTES.mspv2.secopsSections.secutiryBaseline,
      frameworks: APP_ROUTES.mspv2.secopsSections.frameworks,
      frameworksAssets: APP_ROUTES.mspv2.secopsSections.frameworksAssets,
      trainingCertificates: APP_ROUTES.mspv2.secopsSections.trainingCertificates,
      accreditations: APP_ROUTES.mspv2.secopsSections.accreditations,
    },
    actionCenter: APP_ROUTES.mspv2.actionCenter,
    audit: APP_ROUTES.mspv2.audit,
  },
  clientmspsettingsv2: {
    actionCentre: (clientId) => APP_ROUTES.clientmspsettingsv2.actionCentre.replace(':mspClientId', clientId),
    notifications: (clientId) => APP_ROUTES.clientmspsettingsv2.notifications.replace(':mspClientId', clientId),
    cSuite: (clientId) => APP_ROUTES.clientmspsettingsv2.cSuite.replace(':mspClientId', clientId),
    companySettings: (clientId) => APP_ROUTES.clientmspsettingsv2.companySettings.replace(':mspClientId', clientId),
    clientAccessLevels: (clientId) => APP_ROUTES.clientmspsettingsv2.clientAccessLevels.replace(':mspClientId', clientId),
    clientAccessLevelsMember: (clientId, memberId) => APP_ROUTES.clientmspsettingsv2.clientAccessLevelsMember.replace(':mspClientId', clientId).replace(':memberId', memberId),
    resetSettings: (clientId) => APP_ROUTES.clientmspsettingsv2.resetSettings.replace(':mspClientId', clientId),
  },
  mspsettingsv2: {
    myProfile: APP_ROUTES.mspsettingsv2.myProfile,
    notifications: APP_ROUTES.mspsettingsv2.notifications,
    cSuite: APP_ROUTES.mspsettingsv2.cSuite,
    activeSessions: APP_ROUTES.mspsettingsv2.activeSessions,
    companySettings: APP_ROUTES.mspsettingsv2.companySettings,
    clientAccessLevels: APP_ROUTES.mspsettingsv2.clientAccessLevels,
    memberaccess: APP_ROUTES.mspsettingsv2.memberaccess,
    memberaccessUser: (user) => APP_ROUTES.mspsettingsv2.memberaccessUser.replace(':user', user),
    team: APP_ROUTES.mspsettingsv2.team,
    teamUser: (user) => APP_ROUTES.mspsettingsv2.teamUser.replace(':user', user),
    rewards: APP_ROUTES.mspsettingsv2.rewards,
    subscriptions: APP_ROUTES.mspsettingsv2.subscriptions,
    billing: APP_ROUTES.mspsettingsv2.billing,
    actionCentre: APP_ROUTES.mspsettingsv2.actionCentre,
    resetSettings: APP_ROUTES.mspsettingsv2.resetSettings,
  },
  saas: {
    dashboard: APP_ROUTES.client.dashboard,
    technologySpend: APP_ROUTES.client.technologySpend,
    attackSurface: APP_ROUTES.client.attackSurface,
    userIssues: `${APP_ROUTES.client.attackSurface}?section=MFA`,
    deviceIssues: `${APP_ROUTES.client.attackSurface}?section=EOL`,
    license: APP_ROUTES.client.license,
    securityPackage: APP_ROUTES.client.securityPackage,
    complianceManager: APP_ROUTES.client.complianceManager,
    complianceManagerSections: {
      cyberEssentialStatus: APP_ROUTES.client.complianceManagerSections.cyberEssentialStatus,
      irPlanStatus: APP_ROUTES.client.complianceManagerSections.irPlanStatus,
      riskAssessment: APP_ROUTES.client.complianceManagerSections.riskAssessment,
      annualCyberReview: APP_ROUTES.client.complianceManagerSections.annualCyberReview,
      testRecovery: APP_ROUTES.client.complianceManagerSections.testRecovery,
      infosecPolicies: APP_ROUTES.client.complianceManagerSections.infosecPolicies,
      securityFrameworks: APP_ROUTES.client.complianceManagerSections.securityFrameworks,
      dataPrivacyFrameworks: APP_ROUTES.client.complianceManagerSections.dataPrivacyFrameworks,
      cyberInsurancePolicies: APP_ROUTES.client.complianceManagerSections.cyberInsurancePolicies,
      team: APP_ROUTES.client.settings.team,
      form: APP_ROUTES.client.complianceManagerSections.form,
    },
    integrations: APP_ROUTES.client.integrations,
    storefront: APP_ROUTES.client.storefront,
    integrationDetail: (integrationId) => APP_ROUTES.client.integrationDetail.replace(':integrationId', integrationId),
    settings: {
      myProfile: APP_ROUTES.client.settings.myProfile,
      notifications: APP_ROUTES.client.settings.notifications,
      cSuite: APP_ROUTES.client.settings.cSuite,
      activeSessions: APP_ROUTES.client.settings.activeSessions,
      companySettings: APP_ROUTES.client.settings.companySettings,
      memberaccess: APP_ROUTES.client.settings.memberaccess,
      memberaccessMember: (member) => APP_ROUTES.client.settings.memberaccessMember.replace(':member', member),
      rewards: APP_ROUTES.client.settings.rewards,
      subscriptions: APP_ROUTES.client.settings.subscriptions,
      billing: APP_ROUTES.client.settings.billing,
      actionCentre: APP_ROUTES.client.settings.actionCentre,
      resetSettings: APP_ROUTES.client.settings.resetSettings,
      team: APP_ROUTES.client.settings.team,
    },
    submitNewPassword: APP_ROUTES.client.submitNewPassword,
    systemSettings: (id) => APP_ROUTES.client.systemSettings.replace(':id', id),
    test: APP_ROUTES.client.test,
    handlesubscription3ds: APP_ROUTES.client.handlesubscription3ds,
    supplierProfilerType: (type) => APP_ROUTES.client.supplierProfilerType.replace(':type', type),
    supplierProfiler: APP_ROUTES.client.supplierProfiler,
    trendData: APP_ROUTES.client.trendData,
    createSupportTicket: APP_ROUTES.client.createSupportTicket,
    design_system: APP_ROUTES.client.design_system,
    auditLogs: APP_ROUTES.client.auditLogs,
    marketplace: APP_ROUTES.client.marketplace,
    actionCentre: APP_ROUTES.client.actionCentre,
    personalSettings: APP_ROUTES.client.personalSettings,
    technicalDrilldown: APP_ROUTES.client.technicalDrilldown,
    technicalDrilldownPageType: (pageType) => APP_ROUTES.client.technicalDrilldownPageType.replace(':pageType', pageType),
    integrationsV2: APP_ROUTES.client.integrationsV2,
  },
  auth: {
    login: APP_ROUTES.auth.login,
    loginAnonymous: APP_ROUTES.auth.loginAnonymous,
    forgotPassword: APP_ROUTES.auth.forgotPassword,
    resetPassword: APP_ROUTES.auth.resetPassword,
    clientRegistration: APP_ROUTES.auth.clientRegistration,
    forgottenPassword: APP_ROUTES.auth.forgottenPassword,
    loginMfa: APP_ROUTES.auth.loginMfa,
    signup: APP_ROUTES.auth.signup,
    logout: APP_ROUTES.auth.logout,
    signUpReferraltoken: APP_ROUTES.auth.signUpReferraltoken,
    submitNewPassword: APP_ROUTES.auth.submitNewPassword,
  },
  anonymous: APP_ROUTES.anonymous,
  error: APP_ROUTES.error,
  noAccessToMsp: APP_ROUTES.noAccessToMsp,
  noAccessToHighground: APP_ROUTES.noAccessToHighground,
  sessionExpiry: APP_ROUTES.sessionExpiry,
};

export const ROUTE_ACTIONS = {
  addSubscription: 'addSubscription',
  cyberScore: 'cyberScore',
  clientReport: 'clientReport',
  passwordChanged: 'passwordChanged',
  integrationViewMapData: 'integrationViewMapData',
  addMember: 'addMember',
}

export const HG_PAGES = {
  pricing: process.env.REACT_APP_HG_PRICING_URL,
}

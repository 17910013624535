import React from "react";
import { MspSingleTagUI } from "../../integrations/mspIntegrationdetailScreen/MspIntegrationCard";
import { renderPriceText } from "../MspClientPriceCalculator";
import useRespondTo from "../../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../../constants/window.constants";


export const GeneralInformationBlock = ({
    packageData,
}) => {
    const isMobile = useRespondTo(WindowBreakpoints.md);

    return (
        <div>
            <div className="d-flex align-items-center">
                {
                    !isMobile &&
                    <div className="d-flex align-items-center justify-content-center bg-greyish p-1 radius-8 border-dark-grey mr-2"
                        style={{
                            height: '40px',
                            width: '40px'
                        }}
                    >
                        <img
                            width={19}
                            height={21}
                            alt=""
                            className=""
                            src="/images/msp/package-icon2.svg"
                        />
                    </div>
                }
                <div>
                    <div className="f-14 f-600 f-black mr-4">
                        {packageData?.ScenarioHeader?.Name}
                    </div>
                    <div className="f-400 f-14 f-darkgrey">
                        Enhanced security suite offering advanced threat detection, incident response, and data privacy compliance.
                    </div>
                </div>
            </div>


            <div className="d-flex w-50 mt-5">
                <div className="w-50 f-400 f-14 f-darkgrey">
                    Billing type
                </div>
                <div className="w-50">
                    {packageData?.ScenarioHeader?.PackageType_Text && (
                        <MspSingleTagUI
                            itemName={packageData?.ScenarioHeader?.PackageType_Text}
                        />
                    )}
                </div>
            </div>

            <div className="d-flex w-50 mt-1">
                <div className="w-50 f-400 f-14 f-darkgrey">
                    Billing period
                </div>
                <div className="w-50 f-400 f-14 f-black">
                    {packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLText}
                </div>
            </div>

            <div className="d-flex w-50 mt-1">
                <div className="w-50 f-400 f-14 f-darkgrey">
                    Currency
                </div>
                <div className="w-50 f-400 f-14 f-black">
                    {packageData?.CurrencyCode_Selected?.CurrencyName}
                </div>
            </div>

            {
                packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
                <div className="d-flex w-50 mt-1">
                    <div className="w-50 f-400 f-14 f-darkgrey">
                        Qty Seats
                    </div>
                    <div className="w-50 f-400 f-14 f-black">
                        {packageData?.MSPPackagePrice?.RequiredSeatsOverriden}
                    </div>
                </div>
            }

            <div className="d-flex w-50 mt-1">
                <div className="w-50 f-400 f-14 f-darkgrey">
                    Price
                </div>
                <div className="w-50 f-400 f-14 f-black">
                    {renderPriceText(
                        packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLSort,
                        (packageData?.MSPPackagePrice?.Subtotal)?.toFixed(2),
                        packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                    )}
                    <span className="f-400 f-14 f-darkgrey ml-2">
                        {'(ex tax)'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default GeneralInformationBlock;

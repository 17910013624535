import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useHistory } from "react-router-dom";
import "./mspIntegrations.scss";
import MSPClientFilters from "../clients/MSPClientFilters";
import { useAuth } from "../../context/Auth";
import { getAPIData, postData, postRequest } from "../../../Utils/commonApi";
import ContentLoader from "react-content-loader";
import { GreyLoader } from "../../Theme/APILoaders";
import Loader from "../../Common/loader";
import { accessStateValue, HGHighlightText } from "../../App/App";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { makeGroup } from "../../AttackSurface/AssetsList/WorkStations/WorkstationModal";
import SearchInput from "../../Common/search-input/search-input.component";
import integrationUtils from "../../../Utils/integration.utils";
import CrossIcon from "../../icons/cross.icon";
import AddToolBtn from "../../AttackSurface/KPICyberScoreSection/select-tool/add-tool.button";
import useApiActions from "../../../hooks/api-actions.hook";
import ToolsSection from "../../Common/tools-section.component";
import { ContributionTypeTitles } from "../../../constants/integrations.constants";
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import { VisibilityType } from "../../../constants/filter.constants";
import { ROUTE_PATHS } from "../../../constants/app.constants";

export const MAP_INTEGRATION_HIGHLIGHT_CLASS = "highlight-class"
const MapIntegrations = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();
  const [pageData, setPageData] = useState({});
  const [headersList, setHeadersList] = useState([]);
  const [fetchingNewClients, setFetchingNewClients] = useState(false);
  const [ClientNumber, setClientNumber] = useState(0);
  const [mspFilters, setMspFilters] = useState(
    `[{"MSPFilter_Enum":1,"FilterText_List":[""]}]`
  );
  const [tableLoading, setTableLoading] = useState(false)
  const [filterType, setFilterType] = useState("All")



  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('userId');
  const location = useLocation();

  const backUrl = location.state?.backUrl ?? ROUTE_PATHS.mspv2.securityStack


  const [NoData, setNoData] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPageData();
  }, []);

  /**
   * @params
   * {
   * MSPClientSortVisibility_List:[],
   * ClientNumber: 0,
   * MSPFilter_List: `[{"MSPFilter_Enum":1,"FilterText_List":[""]}]`
   *
   * }
   */
  function getPageData(kwargs) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "GetMSPMapIntegration", {
        ObjectId: 0,
        ClientNumber: kwargs?.ClientNumber ?? ClientNumber,
        isArchived: false,
        ClientSortOrder: 0,
        ClientSort: 0,
        MSPFilter_List: kwargs?.MSPFilter_List ?? mspFilters,
        MSPClientSortVisibility_List:
          kwargs?.MSPClientSortVisibility_List ?? JSON.stringify(headersList),
        TechnologyFilter: kwargs?.filterType ?? filterType,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        objId_Customer: userId
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setNoData(response?.NoData);
            setClientNumber(response?.ClientNumber);
            let updated_header = [...response?.MSPClientSortVisibility_List];
            setHeadersList([...updated_header]);

            if (kwargs?.ClientNumber === 0 || ClientNumber === 0) {
              setPageData({ ...response });
            } else {
              let new_output = { ...response };
              let old_client_data = []

              if (pageData?.MSPClientRowData_List?.length > 0) {
                old_client_data = [
                  ...pageData.MSPClientRowData_List,
                  ...new_output.MSPClientRowData_List.map((item) => ({
                    ...item,
                    ClientNumber
                  })),
                ];
              } else {
                old_client_data = [...new_output.MSPClientRowData_List];
              }

              let updated_data = {
                ...new_output,
                MSPClientRowData_List: old_client_data,
              };

              setPageData({ ...updated_data });
            }
            setLoading(false);
            setTableLoading(false);
            resolve(response);
          } else {
            setLoading(false);
            setTableLoading(false);
            reject("false");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          reject("false");
        });
    });
  }

  function updateSpecificClient(customerId) {
    return new Promise((resolve, reject) => {
      const customerClientNumber = pageData?.MSPClientRowData_List?.findIndex(
        (item) => item?.objId_Customer === customerId
      );
      postData(authTokens, "GetMSPMapIntegration", {
        objId_Customer: userId,
        ObjectId: 0,
        ClientNumber: customerClientNumber !== -1
          ? pageData?.MSPClientRowData_List[customerClientNumber].ClientNumber
          : 0,
        isArchived: false,
        ClientSortOrder: 0,
        ClientSort: 0,
        MSPFilter_List: mspFilters,
        MSPClientSortVisibility_List: JSON.stringify(headersList),
        TechnologyFilter: filterType,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          if (response?.mr?.Success) {
            let updated_data = { ...pageData };
            let index = updated_data?.MSPClientRowData_List?.findIndex(
              (item) => item?.objId_Customer === customerId
            );
            let responseIndex = response?.MSPClientRowData_List?.findIndex(
              (item) => item?.objId_Customer === customerId
            );

            updated_data.MSPClientRowData_List[index] = {
              ...updated_data?.MSPClientRowData_List[index],
              ...response?.MSPClientRowData_List[responseIndex],
            };

            setPageData({ ...updated_data });
            resolve(response);
          }
        })
        .catch((err) => {
          reject("false");
        });
    });
  }

  const SearchInput = useRef();
  const [searchInput, setSearchInput] = useState(false);
  const [searchString, setSearchString] = useState('')
  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 40 >=
        e.target.scrollHeight
      ) {

        if (ClientNumber === 0 || fetchingNewClients) return;

        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", getMoreClients);

        setFetchingNewClients(true);

        getPageData().then(() => {
          setFetchingNewClients(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");

    element.removeEventListener("scroll", getMoreClients);
    element.addEventListener("scroll", getMoreClients);

    return () => {
      element.removeEventListener("scroll", getMoreClients);
    };
  }, [pageData, fetchingNewClients]);


  const [timerId, setTimerId] = useState()
  function searchRecords(inputValue) {
    clearTimeout(timerId); // Clear the existing timer
    const _value = inputValue.trim();



    // Set a new timer to call the API after 1 second of user inactivity
    let _timerId = setTimeout(function () {

      setTableLoading(true);
      getPageData({
        MSPFilter_List: `[{"MSPFilter_Enum":1,"FilterText_List":["${_value}"]}]`,
        ClientNumber: 0,
      });
      setMspFilters(
        `[{"MSPFilter_Enum":1,"FilterText_List":["${_value}"]}]`
      );// Call your API function here
    }, 800);

    setTimerId(_timerId)
  }




  const highlightClientListText = (searchText) => {
    if (!searchText || searchText?.length === 0) return
    // debugger

    const elements = document.querySelectorAll(`.${MAP_INTEGRATION_HIGHLIGHT_CLASS}`);

    elements.forEach((element) => {
      const text = element.innerText;
      const highlightedText = text.replace(
        new RegExp(searchText, "gi"),
        (match) => `<span class="list-highlight-class">${match}</span>`
      );
      element.innerHTML = highlightedText;
    });
  };



  useEffect(() => {

    if (tableLoading === false) {

      highlightClientListText(searchString)

    }
  }, [tableLoading, pageData])




  // SCROLL BUTTON SETUP

  // Scroll button setup
  const [showScrollIcon, setShowScrollIcon] = useState(true);
  const [showScrollLeftIcon, setShowScrollLeftIcon] = useState(false);

  const handleScroll = () => {
    const scrollContainer = document.getElementById("filters-scroll-container");

    const isScrolledToRight =
      scrollContainer.scrollLeft + scrollContainer.clientWidth + 15 >
      scrollContainer.scrollWidth;

    console.log(scrollContainer.scrollLeft + scrollContainer.clientWidth);
    console.log(scrollContainer.scrollWidth);
    // Check if scrolled to the right edge
    setShowScrollIcon(!isScrolledToRight);
    const isScrolledToLeft = scrollContainer.scrollLeft > 30;

    setShowScrollLeftIcon(isScrolledToLeft);
  };

  const handleIconClick = () => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    // You can adjust the scroll amount based on your requirements
    // scrollContainer.scrollLeft += 50;
    const scrollAmount = 320;
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const handleLeftIconClick = () => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    // You can adjust the scroll amount based on your requirements
    const scrollAmount = -320;
    scrollContainer.scrollTo({
      left: scrollContainer.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollContainer = document.getElementById("filters-scroll-container");
    // Add scroll event listener
    if (!scrollContainer) return;

    scrollContainer.removeEventListener("scroll", handleScroll);
    scrollContainer.addEventListener("scroll", handleScroll);
    // Initial check for scroll position
    handleScroll();

    // Cleanup: Remove event listener when component unmounts
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [pageData, loading, tableLoading]);


  const filterDataOptions = [
    {
      id: 0,
      title: 'Type',
      data: [{
        id: 0,
        type: "All",
      },
      {
        id: 1,
        type: "Undefined",
      },
      {
        id: 2,
        type: "No tool",
      }]
    }
  ]

  const [selectedFilterList, setSelectedFilterList] = useState([]);

  return (
    <div className="integration-header">
      {loading ? (
        <MspMapIntegrationLoader />
      ) : (
        <>
          <div
            className="w-100 bg-black d-flex align-items-center justify-content-between"
            style={{
              position: "sticky",
              top: "56px",
              height: "51px",
              borderTop: "1px solid rgba(255, 255, 255, 0.08)",
              zIndex: 6,
            }}
          >
            <div className="d-flex align-items-center f-white f-500 ml-3 pointer" onClick={() => {
              if (backUrl) {
                history.push(backUrl);
              } else {
                history.push(ROUTE_PATHS.mspv2.securityStack);
              }
            }}>
              <img
                alt=""
                src="/images/msp/msp-white-cross.svg"
                className="mr-3 pointer"

              />
              Map your security stack to your clients
            </div>
            {/* Mapping Tabs List */}
            <div className="d-flex align-items-center"></div>

            <div className="mr-3">
              {/* <Button
                className="bg-transparent border-none mr-2"
                onClick={() => {
                  if (backUrl) {
                    history.push(backUrl);
                  } else {
                    history.push("/mspv2/securitystack");
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className="hg-blue-btn" 
                onClick={() => {
                  if (backUrl) {
                    history.push(backUrl);
                  } else {
                    history.push("/mspv2/securitystack");
                  }
                }}
              >
                Save
              </Button> */}
            </div>
          </div>

          {
            userId ? <div className="d-flex align-items-center justify-content-between mt-3 pl-5 pr-2">
              <div className="f-black f-600">Map integrations</div>
            </div> :
              <SearchFilterComponent
                leftSide={<div className="f-black f-600">Map integrations</div>}
                passedMainStyles={'mt-3 pl-5 pr-2'}
                searchValue={searchString}
                searchOnChange={(e) => {
                  setSearchInput(true);
                  setSearchString(e.target.value);
                  searchRecords(e.target.value);
                }}
                searchOnCross={() => {
                  setSearchString("");
                  setTableLoading(true);
                  getPageData({
                    MSPFilter_List: `[{"MSPFilter_Enum":"Client","FilterText_List":[""]}]`,
                    ClientNumber: 0,
                  });
                }}
                passedRef={SearchInput}
                setRef={() => {
                  SearchInput.current.focus()
                }}
                visibilityType={VisibilityType.TABLE}
                sortOptionTooltip={'No sorting options for this page at the moment'}
                showLastButton={false}
                orderData={headersList}
                setOrderData={(e) => {
                  setTableLoading(true);
                  getPageData({
                    MSPClientSortVisibility_List: JSON.stringify([...e]),
                    ClientNumber: 0,
                  });
                  setHeadersList([...e]);
                }}
                passedFilterList={filterDataOptions}
                childFilterItem={<div>
                  {
                    filterDataOptions?.map((filterItem) => {
                      return (
                        <div className="d-flex align-items-center bg-hover-grey pointer"
                          onClick={(e) => {
                            setSelectedFilterList(prevList => {
                              const newList = [...prevList];
                              const index = newList.findIndex(item => item?.id === filterItem?.id);
                              if (index !== -1) {
                                newList.splice(index, 1);
                              } else {
                                newList.push(filterItem);
                              }
                              console.log(newList);
                              return newList;
                            });

                            e.target
                              .closest(".transparennt-wrapper-dropdown")
                              .click();
                          }}
                        >
                          {
                            selectedFilterList?.some(option => option?.id === filterItem?.id) ?
                              <img
                                alt=""
                                src="/images/msp/dropdown-grey-tick.svg"
                              /> : <div style={{
                                width: '12px'
                              }}></div>
                          }
                          <div className="f-400 f-14 f-grey p-1 ml-2">
                            {filterItem?.title}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>}
                childAppliedFilterItem={
                  <div className="d-flex align-items-center">
                    {
                      selectedFilterList?.map((filterItem) => {
                        const hasSelectedItems = filterItem?.id === 0 ? filterType !== "All" : false;
                        return (
                          <div className={`d-flex align-items-center py-1 px-2 mr-2 mb-3 rounded-full applied-filter-item ${hasSelectedItems ? 'has-value ' : ''}`}>
                            <Dropdown className="transparennt-wrapper-dropdown">
                              <Dropdown.Toggle className="d-flex align-items-center">
                                <div
                                  className="f-500 f-12 text pointer"
                                >
                                  {filterItem?.title}
                                </div>
                                {
                                  filterItem?.data?.map((filterDataOptionItem) => {
                                    return (
                                      <div>
                                        {
                                          (filterItem?.id == 0 ?
                                            filterType === filterDataOptionItem?.type : false) &&
                                          <div className={`f-500 f-12 text rounded-full pointer`}>
                                            : {filterDataOptionItem?.type}
                                          </div>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {
                                  filterItem?.data?.map((filterDataOptionItem) => {
                                    return (
                                      <div
                                        className="p-1 d-flex align-items-center bg-hover-grey pointer"
                                        style={{
                                          width: '200px'
                                        }}
                                        onClick={(e) => {
                                          if (filterItem?.id == 0) {
                                            setFilterType(filterDataOptionItem?.type);
                                            setTableLoading(true);
                                            getPageData({
                                              MSPFilter_List: `[{"MSPFilter_Enum":1,"FilterText_List":["${searchString}"]}]`,
                                              ClientNumber: 0,
                                              filterType: filterDataOptionItem?.type,
                                            });
                                          }

                                          e.target
                                            .closest(".transparennt-wrapper-dropdown")
                                            .click();
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/msp/dropdown-grey-tick.svg"
                                          className="mx-1"
                                          style={{
                                            visibility: `${(filterItem?.id == 0 ?
                                              filterType === filterDataOptionItem?.type
                                              :
                                              false)
                                              ? "visible"
                                              : "hidden"
                                              }`,
                                          }}
                                        />
                                        <div className="f-400 f-14 f-grey">

                                          {filterDataOptionItem?.type}
                                        </div>
                                      </div>
                                    )
                                  })
                                }

                              </Dropdown.Menu>
                            </Dropdown>
                            <CrossIcon 
                              className="pointer ml-2 cross-icon"
                              width={9}
                              height={9}
                              onClick={(e) => {
                                if (filterItem?.id == 0) {
                                  setFilterType('All');
                                  setTableLoading(true);
                                  getPageData({
                                    MSPFilter_List: `[{"MSPFilter_Enum":1,"FilterText_List":["${searchString}"]}]`,
                                    ClientNumber: 0,
                                    filterType: "All",
                                  });
                                }

                                let filterUpd = selectedFilterList?.filter(item => item?.id !== filterItem?.id)
                                setSelectedFilterList(filterUpd);
                              }}
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                }
                selectedFilterList={selectedFilterList}
                setSelectedFilterList={setSelectedFilterList}
              onResetClick={() => {
                setFilterType("All");
                setTableLoading(true);
                getPageData({
                  MSPFilter_List: `[{"MSPFilter_Enum":1,"FilterText_List":["${searchString}"]}]`,
                  ClientNumber: 0,
                  filterType: "All",
                });

                setSelectedFilterList([]);
              }}
              />
          }




          {showScrollLeftIcon && <div className="map-integration-scrolling-btn bg-white d-none d-md-flex rotate180" style={{
            position: 'fixed',
            top: 'calc(35% + 101px)',
            left: '30px',
            zIndex: 5,
          }} onClick={() => {
            handleLeftIconClick()
          }} >
            <img src="/images/msp/right-arrow-icon.svg" alt="" className="" />
          </div>}
          {showScrollIcon && <div className="map-integration-scrolling-btn bg-white d-none d-md-flex" onClick={() => {
            handleIconClick()
          }}

            style={{
              position: 'fixed',
              top: 'calc(35% + 101px)',
              right: '30px',
              zIndex: 5,
            }}


          >
            <img src="/images/msp/right-arrow-icon.svg" alt="" className="" />
          </div>}

          {tableLoading ? (
            <div className="pl-5 pt-3">
              <MspMapIntegrationTableLoader />
            </div>
          ) : (
            <>
              {pageData?.MSPClientRowData_List?.length === 0 ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className=" f-grey mt-5 w-fit-content">
                    No Data Found!
                  </div>
                </div>
              ) : (
                <div
                  className="map-integrations-screen hide-scrollbar pt-3 px-3 px-md-5"
                  id="filters-scroll-container"

                  style={{
                    overflowX: "scroll",
                  }}
                >
                  <div
                    className="bg-grey radius-8 border-parent "
                    style={{
                      width: "max-content",
                      pointerEvents:
                        accessStateValue("AccessState_MSP_Security_Stack") === 1
                          ? "none"
                          : "all",
                    }}
                  >
                    {/* Header */}
                    <div className="border-item d-flex align-items-center justify-content-between p-2 map-integrations-table-row-parent">
                      {pageData?.MSPClientSortVisibility_List?.map(
                        (headerName, headerIndex) => {
                          return (
                            <>
                              {headerName.Visibility === true && (
                                <div
                                  className={`f-12 f-grey  pl-3 justify-content-start text-ellipsis map-integrations-table-row-child ${true}`}
                                  title={headerName}
                                  style={
                                    {
                                      //   paddingLeft: `${headerIndex === 0 ? "3rem" : "0.5rem"}`,
                                    }
                                  }
                                >
                                  {headerName.FieldName}
                                </div>
                              )}
                            </>
                          );
                        }
                      )}
                    </div>

                    {/* Row */}

                    {pageData?.MSPClientRowData_List?.map((row, rowIndex) => {
                      return (
                        <div className="border-item d-flex align-items-center justify-content-between p-2 map-integrations-table-row-parent">
                          {row?.MSPClientColumnData_List?.map(
                            (headerName, headerIndex) => {
                              const contriType = headersList.find(
                                (item) => item.FieldName === headerName.FieldName
                              )?.ContriType;
                              return (
                                <>
                                  {headerName.FieldName === "Client Name" && (
                                    <div
                                      className={`d-flex align-items-center map-integrations-table-row-child p-2`}
                                    >
                                      {headerName.Icon ? (
                                        <img
                                          alt=""
                                          className="mr-2 rounded-full"
                                          style={{ width: "32px" }}
                                          src={headerName.Icon}
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          className="mr-2"
                                          src="/images/msp/integrations/add-user-icon-msp.svg"
                                        />
                                      )}
                                      <div className="">
                                        <div
                                          className={`f-black f-500 m-0 ${MAP_INTEGRATION_HIGHLIGHT_CLASS}`}
                                        >
                                          {headerName.FieldValue}{" "}
                                        </div>
                                        <div className="f-darkgrey m-0"> </div>
                                      </div>
                                    </div>
                                  )}
                                  {headerName.FieldName !== "Client Name" && (
                                    <div
                                      className={`f-12 f-grey   justify-content-start map-integrations-table-row-child ${true}`}
                                      title={headerName.FieldValue}
                                      style={
                                        {
                                          //   paddingLeft: `${headerIndex === 0 ? "3rem" : "0.5rem"}`,
                                        }
                                      }
                                    >
                                      <MapIntegrationsDropdown
                                        DropdownMenuStyle={{
                                          zIndex: 5,
                                        }}
                                        technologyEnum={
                                          headerName
                                            ?.ToolAttestationRow_List?.[0]
                                            ?.TechnologyEnum
                                        }
                                        toolAttestationData={
                                          headerName
                                            ?.ToolAttestationRow_List?.[0]
                                        }
                                        customerId={row?.objId_Customer}
                                        updateSpecificClient={
                                          updateSpecificClient
                                        }
                                        FieldName={headerName.FieldName}
                                        contriType={contriType}
                                      />
                                    </div>
                                  )}
                                </>
                              );
                            }
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {pageData?.MSPClientRowData_List?.length <= 10 &&
                    ClientNumber > 0 && (
                      <Button
                        className={`${fetchingNewClients
                          ? "transparent-btn f-grey-imp"
                          : "hg-cancel-btn"
                          }  mt-2 mb-5    `}
                        onClick={() => {
                          setFetchingNewClients(true);
                          getPageData().then(() => {
                            setFetchingNewClients(false);
                          });
                        }}
                        disabled={fetchingNewClients}
                      >
                        {fetchingNewClients ? (
                          <>
                            <GreyLoader width={13} /> Loading..
                          </>
                        ) : (
                          "Show more"
                        )}
                      </Button>
                    )}

                  {fetchingNewClients &&
                    pageData?.MSPClientRowData_List?.length > 10 && (
                      <div className="d-flex align-items-center f-darkgrey ml-3 mt-3">
                        <GreyLoader width={12} />
                        Loading...
                      </div>
                    )}
                  <div
                    style={{
                      padding: `${pageData?.MSPClientRowData_List?.length < 5
                        ? "13rem"
                        : "1rem"
                        }`,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MapIntegrations;

const MapIntegrationsDropdown = ({
  technologyEnum,
  toolAttestationData,
  customerId,
  updateSpecificClient,
  DropdownMenuStyle,
  FieldName,
  contriType
}) => {
  const [loading, setLoading] = useState(false);
  const [addingNewTool, setAddingNewTool] = useState(false);
  const [newToolName, setNewToolName] = useState("");
  const [toolsList, setToolsList] = useState({});
  const { authTokens } = useAuth();
  const [search, setSearch] = useState("");
  const [loaders, setLoaders] = useState({
    noToolLoader: false,
    deleteAllToolLoader: false,
  });
  const { getAllIntegrationsByTechType } = useApiActions();

  const integrationsToolsFiltered = useMemo(() => {
    return toolsList?.Integration_List?.filter((tool) => {
      return tool.IntegrationName.toLowerCase().includes(search.toLowerCase());
    }) || [];
  }, [toolsList, search]);

  const securityStackToolsFiltered = useMemo(() => {
    return toolsList?.SecurityStack_List?.filter((tool) => {
      return tool.IntegrationName.toLowerCase().includes(search.toLowerCase());
    }) || [];
  }, [toolsList, search]);

  function IsAnythingLoading() {
    return (
      loaders.noToolLoader ||
      loaders.deleteAllToolLoader ||
      toolsList?.Integration_List?.some((tool) => {
        return tool.loading;
      }) ||
      toolsList?.SecurityStack_List?.some((tool) => {
        return tool.loading;
      }) ||
      loading ||
      addingNewTool
    );
  }

  function stopAllLoadings() {
    let updated_data = { ...toolsList };

    updated_data = {
      ...updated_data,
      Integration_List: updated_data?.Integration_List?.map((tool) => {
        return {
          ...tool,
          loading: false,
        };
      }),
      SecurityStack_List: updated_data?.SecurityStack_List?.map((tool) => {
        return {
          ...tool,
          loading: false,
        };
      }),
    }
    setToolsList(updated_data);
    setLoaders({
      noToolLoader: false,
      deleteAllToolLoader: false,
    });
  }

  /**
   *{
  kwargs={
  event:"click event",
  toolName:"tool name",
  toolId:"tool id",
  customerId:"customer id",
}
   }
   */
  function addNewTool(kwargs) {
    let event = kwargs.event;
    let toolId = kwargs.toolId ?? 0;
    let scroller;
    if (kwargs.event) {
      scroller = kwargs.event.target
        .closest(".map-integrations-dropdown")
        ?.getElementsByClassName("transparent-scroller");
    }

    let objId_CustomerDetails = {};

    if (kwargs.customerId) {
      objId_CustomerDetails = { ObjId_Customer: kwargs.customerId ?? 0 };
    }

    postRequest(authTokens, "CreateIntegrationTechnologyTool", {
      ...objId_CustomerDetails,
      Selected: true,
      IsMultipleIntegrationAllowed: true,
      ToolName: kwargs.toolName,
      ObjectId: kwargs.toolId ?? 0,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      IntegrationTechnology: technologyEnum,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          if (toolId === 0) {
            getDropdownData({
              scrollToBottom: event ? true : false,
              element: event ? scroller : null,
            });
          } else {
            updateSpecificClient(customerId)
              .then(() => {
                stopAllLoadings();
              })
              .catch((err) => {
                console.log(err);
              });
          }

          setNewToolName("");
          setAddTool(false);
        }
        setAddingNewTool(false);
      })
      .catch((err) => {
        setAddingNewTool(false);
        console.log(err);
      });
  }

  async function deleteAllTool() {
    await getAPIData("648", authTokens, {
      optionEnum1: customerId,
      optionEnum2: technologyEnum,
    })
      .then((response) => {
        if (response?.Success) {
          updateSpecificClient(customerId)
            .then(() => {
              stopAllLoadings();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        stopAllLoadings();
        console.log(err);
      });
  }
  async function deleteTool(_integrationId) {
    await getAPIData("626", authTokens, {
      optionEnum1: _integrationId,
      optionEnum2: customerId,
    })
      .then((response) => {
        if (response?.Success) {
          updateSpecificClient(customerId)
            .then(() => {
              stopAllLoadings();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   *
   * @param
   * {
   * scrollToBottom: true,
   * element: elemnt
   *
   * }
   */

  function getDropdownData(kwargs) {
    getAllIntegrationsByTechType({
      technologyTypeEnum: technologyEnum,
      showAll: true
    }).then((response) => {
      if (response?.mr?.Success) {
        setToolsList(response);
      }
      setLoading(false);
      if (kwargs?.scrollToBottom) {
        kwargs.element[0].scrollTo({
          top: kwargs?.element?.[0]?.scrollHeight,
          behavior: "smooth",
        });
      }
    }).catch((err) => {
      setLoading(false);
    });
  }
  function closeDropdown(e) {
    e.target.closest(".map-integrations-dropdown").click();
  }
  const clientsData = [
    {
      image: "/images/msp/integrations/apple-icon.svg",
      name: "Vipre",
      connected: true,
    },
    {
      image: "/images/msp/integrations/apple-icon.svg",
      name: "SpamTitan",
    },
    {
      image: "/images/msp/integrations/alphabet-icon.svg",
      name: "Mimecast",
    },
    {
      image: "/images/msp/integrations/nike-icon.svg",
      name: "Microsoft Defender for Office 365",
    },
  ];
  const [data, setData] = useState(clientsData);
  const [addTool, setAddTool] = useState(false);

  function checkIfIdExists(id, dataArray) {
    const ids = dataArray.map((obj) => obj.obj_IntegrationId);
    return ids.indexOf(id) !== -1;
  }

  const onAddTool = (tool) => {
    if (IsAnythingLoading()) return;
    if (
      checkIfIdExists(
        tool?.obj_IntegrationId,
        toolAttestationData.SelectedTool_List
      ) === false
    ) {
      let updated_data = { ...toolsList };
      const securityStackIndex = updated_data?.SecurityStack_List?.findIndex(
        (item) => item.IntegrationId === tool.IntegrationId
      );
      if (securityStackIndex !== -1) {
        updated_data.SecurityStack_List[securityStackIndex].loading = true;
      }
      const integrationsIndex = updated_data?.Integration_List?.findIndex(
        (item) => item.IntegrationId === tool.IntegrationId
      );
      if (integrationsIndex !== -1) {
        updated_data.Integration_List[integrationsIndex].loading = true;
      }
      setToolsList(updated_data);

      addNewTool({
        customerId: customerId,
        toolId: tool.IntegrationId,
        toolName: tool.IntegrationName,
      });
    }
  }

  const onRemoveTool = (tool) => {
    if (IsAnythingLoading()) return;
    let updated_data = { ...toolsList };
    const toolIndex = updated_data?.Integration_List?.findIndex(
      (item) => item.IntegrationId === tool.IntegrationId
    );
    if (toolIndex !== -1) {
      updated_data.Integration_List[toolIndex].loading = true;
    }
    const securityStackIndex = updated_data?.SecurityStack_List?.findIndex(
      (item) => item.IntegrationId === tool.IntegrationId
    );
    if (securityStackIndex !== -1) {
      updated_data.SecurityStack_List[securityStackIndex].loading = true;
    }
    setToolsList(updated_data);
    setToolsList(updated_data);

    deleteTool(tool?.IntegrationId);
  }

  return (
    <Dropdown
      className="map-integrations-dropdown my-dropdown"
      onToggle={(e) => {
        setAddTool(false);
        if (e) {
          setLoading(true);
          getDropdownData();
        }
      }}
    >
      <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
        {toolAttestationData.ResponseProvided === true && (
          <>
            {toolAttestationData.SelectedTool_List.length === 0 && (
              <div className="d-flex w-100 align-items-center justify-content-sta-rt">
                <div className="d-flex  align-items-center f-grey f-500">
                  <img
                    alt=""
                    className="mr-2 rounded-full"
                    width={15}
                    src={`/images/msp/msp-no-tool-icon.svg`}
                  />
                  No tool
                </div>
                <div>
                  <img
                    alt=""
                    className="ml-2"
                    src="/images/msp/integrations/chevron-down-msp.svg"
                  />
                </div>
              </div>
            )}
            {toolAttestationData.SelectedTool_List.length === 1 && (
              <div className="d-flex w-100 align-items-center justify-content-sta-rt">
                <div className="d-flex  align-items-center f-grey f-500">
                  <img
                    alt=""
                    className="mr-2 rounded-full"
                    width={15}
                    src={integrationUtils.getImagePath(toolAttestationData.SelectedTool_List[0]?.obj_IntegrationId, toolAttestationData.SelectedTool_List[0]?.ToolIcon)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/attack-surface/generic2.svg";
                    }}
                  />
                  <div className={` ${MAP_INTEGRATION_HIGHLIGHT_CLASS}`}>
                    {TrimString(
                      toolAttestationData.SelectedTool_List[0]?.ToolName,
                      15
                    )}
                  </div>
                </div>
                <div>
                  <img
                    alt=""
                    className="ml-2"
                    src="/images/msp/integrations/chevron-down-msp.svg"
                  />
                </div>
              </div>
            )}

            {toolAttestationData.SelectedTool_List.length > 1 && (
              <div
                className="d-flex w-auto align-items-center justify-content-sta-rt p-2 radius-4"
                style={{
                  background: "#008fd435",
                }}
              >
                <div className="d-flex  align-items-center f-blue f-500">
                  <img
                    alt=""
                    className="mr-2 rounded-full"
                    width={15}
                    src={integrationUtils.getImagePath(toolAttestationData.SelectedTool_List[0]?.obj_IntegrationId, toolAttestationData.SelectedTool_List[0]?.ToolIcon)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/attack-surface/generic2.svg";
                    }}
                  />
                  <div className={`f-blue ${MAP_INTEGRATION_HIGHLIGHT_CLASS}`}>
                    {TrimString(
                      toolAttestationData.SelectedTool_List[0]?.ToolName,
                      15
                    )}
                  </div>
                  {toolAttestationData.SelectedTool_List.length > 1 && (
                    <div className="f-darkgrey f-12 mx-1">
                      + {toolAttestationData.SelectedTool_List.length - 1}
                    </div>
                  )}
                </div>
                <div>
                  {loaders.deleteAllToolLoader ? (
                    <div className="msp-service-update-loader mx-1"></div>
                  ) : (
                    <img
                      alt=""
                      className="ml-2"
                      src="/images/msp/msp-blue-cross.svg"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (IsAnythingLoading()) return;

                        setLoaders((prev) => ({
                          ...prev,
                          deleteAllToolLoader: true,
                        }));
                        deleteAllTool();
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {toolAttestationData.ResponseProvided === false && (
          <div className="d-flex w-100 align-items-center justify-content-sta-rt">
            <div className="d-flex  align-items-center f-grey f-500">
              <img
                alt=""
                className="mr-2"
                src="/images/msp/integrations/no-tool-icon-msp.svg"
              />
              Undefined
            </div>
            <div>
              <img
                alt=""
                className="ml-2"
                src="/images/msp/integrations/chevron-down-msp.svg"
              />
            </div>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu sec-stack-map-integration-modal" style={{
        ...DropdownMenuStyle,
        padding: "4px 0",
      }}>
        {loading ? (
          <div
            className="p-4"
            style={{
              minWidth: "250px",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div>
            <SearchInput
              value={search}
              onChange={value => setSearch(value)}
              placeholder="Search"
              className="search pt-0"
            />
            <div
              className="transparent-scroller hide-scrollbar"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {!search && (
                <div className="mb-3" style={{ padding: '0px 4px' }}>
                  <div
                    className="f-grey mt-2 pointer d-flex align-items-center justify-content-between bg-hover-grey-light item py-1"
                    onClick={(e) => {
                      if (IsAnythingLoading()) return;
                      // setSelected({
                      //   image: "/images/msp/msp-no-tool-icon.svg",
                      //   name: "No tool",
                      // });
                      setLoaders((prev) => ({ ...prev, noToolLoader: true }));
                      deleteAllTool();
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ gap: "6px", marginLeft: "12px" }}
                    >
                      <img
                        src="/images/msp/msp-no-tool-icon.svg"
                        alt=""
                      />
                      No tool
                    </div>

                    <div>
                      {loaders.noToolLoader ? (
                        <div className="msp-service-update-loader mr-1"></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex flex-column" style={{ gap: '16px' }}>
                {search ? (
                  <ToolsSection
                    title="Results"
                    list={[
                      ...integrationsToolsFiltered,
                      ...securityStackToolsFiltered,
                    ]}
                    search={search}
                    checkIfIdExists={checkIfIdExists}
                    onAddTool={onAddTool}
                    onRemoveTool={onRemoveTool}
                    toolAttestationData={toolAttestationData}
                    noToolsTitle=""
                  />
                ) : (
                  <>
                    <ToolsSection
                      title="Security Stack"
                      list={securityStackToolsFiltered}
                      search={search}
                      checkIfIdExists={checkIfIdExists}
                      onAddTool={onAddTool}
                      onRemoveTool={onRemoveTool}
                      toolAttestationData={toolAttestationData}
                    />
                    <ToolsSection
                      title={`All ${FieldName} ${ContributionTypeTitles[contriType]}`}
                      list={integrationsToolsFiltered}
                      search={search}
                      checkIfIdExists={checkIfIdExists}
                      onAddTool={onAddTool}
                      onRemoveTool={onRemoveTool}
                      toolAttestationData={toolAttestationData}
                    />
                  </>
                )}
              </div>
            </div>
            {search.trim().length > 0 && (
              <div className="px-3 mt-2">
                <AddToolBtn
                  isAdding={addingNewTool}
                  onClick={() => {
                    setAddingNewTool(true);
                    addNewTool({
                      toolName: search,
                    });
                  }}
                  value={search}
                />
                {addingNewTool && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    size="sm"
                    className="mx-2 result-spinner "
                    style={{
                      width: "12px",
                      height: "12px",
                      marginBottom: '2px'
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const MspMapIntegrationLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 160 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="1%" y="200" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="262" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="324" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="386" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="448" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="510" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="572" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="634" rx="4" ry="4" width="97%" height="60" />

      <rect x="1%" y="696" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="758" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="820" rx="4" ry="4" width="97%" height="60" />
      <rect x="1%" y="882" rx="4" ry="4" width="97%" height="60" />
    </ContentLoader>
  );
};
export const MspMapIntegrationTableLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 160 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="150" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="212" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="274" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="336" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="398" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="460" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="522" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="584" rx="4" ry="4" width="97%" height="60" />

      <rect x="0%" y="646" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="708" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="770" rx="4" ry="4" width="97%" height="60" />
      <rect x="0%" y="832" rx="4" ry="4" width="97%" height="60" />
    </ContentLoader>
  );
};



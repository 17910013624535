import React, { useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip"; 
import './shown-hidden-cols.styles.scss';

const ShownHiddenCols = ({ columns, setColumns, draggableParentOffset, whiteListColumns = [] }) => {
  const [shownList, hiddenList] = useMemo(() => (
    columns.reduce(
      (acc, column) => {

        if (column.Visibility) {
          acc[0].push(column);
        } else {
          acc[1].push(column);
        }
        return acc;
      },
      [[], []]
    )
  ), [columns]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function handleDragEnd(result, list) {
    if (!result.destination) {
      return list;
    }

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    const resultList = items.map((item, index) => {
      return {
        ...item,
        SortOrder: index + 1,
      };
    })
    return resultList;
  }

  function handleShowHideColumn(column, isVisible) {
    const updatedColumns = columns.map((item) => {
      if (item.FieldKey === column.FieldKey) {
        return {
          ...item,
          Visibility: isVisible,
        };
      }
      return item;
    });
    setColumns(updatedColumns);
  }

  function handleDragEndShown(result) {
    const resultList = handleDragEnd(result, shownList);
    setColumns([
      ...resultList,
      ...hiddenList
    ]);
  }

  function handleDragEndHidden(result) {
    const resultList = handleDragEnd(result, hiddenList);
    setColumns([
      ...resultList,
      ...shownList
    ]);
  }

  return (
    <div>
      {
        columns?.length > 0 ?
          <div className="shown-hidden-cols ">
            <span className="section-title f-darkgrey">Shown</span>
            <div className="hide-scrollbar" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <div style={{ maxHeight: '100%', overflow: 'hidden' }}>
                <DragDropContext onDragEnd={handleDragEndShown}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {shownList &&
                          shownList
                            .map((section, i) => (
                              <DraggableItem
                                item={section}
                                index={i}
                                handleShowHideColumn={() => handleShowHideColumn(section, false)}
                                draggableParentOffset={draggableParentOffset}
                                whiteListColumns={whiteListColumns}
                                shownHiddenIcon="/images/attack-surface/shown-icon.svg"
                              />
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            {/* Hidden Items */}
            <div className="mt-3" />
            <span className="section-title f-darkgrey">Hidden</span>
            <div className="hide-scrollbar" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <div style={{ maxHeight: '100%', overflow: 'hidden' }}>
                <DragDropContext onDragEnd={handleDragEndHidden}>
                  <Droppable
                    direction="vertical"
                    droppableId="droppableSection"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {hiddenList &&
                          hiddenList
                            .map((section, i) => (
                              <DraggableItem
                                item={section}
                                index={i}
                                handleShowHideColumn={() => handleShowHideColumn(section, true)}
                                draggableParentOffset={draggableParentOffset}
                                whiteListColumns={whiteListColumns}
                                shownHiddenIcon="/images/attack-surface/hidden-icon.svg"
                              />
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div> : <div className="f-400 f-13 f-darkgrey py-2 px-3">
            No options yet
          </div>
      }
    </div>
  );
}

export default ShownHiddenCols;

const DraggableItem = ({
  item, index, handleShowHideColumn, draggableParentOffset, shownHiddenIcon, whiteListColumns = []
}) => {
  return (
    <Draggable
      key={item?.FieldKey}
      draggableId={item?.FieldKey}
      index={index}
    >
      {(provided, snapshot) => {
        const isDisabled = whiteListColumns.length > 0 ? !whiteListColumns.includes(item.FieldKey) : false;
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`section-item d-flex justify-content-between ${isDisabled ? 'disabled' : ''}`}
            key={index}
            index={index}
            style={{
              ...provided.draggableProps.style,
              ...(provided.draggableProps.style.top && draggableParentOffset && {
                top: draggableParentOffset.y + provided.draggableProps.style.top,
                left: draggableParentOffset.x + provided.draggableProps.style.left
              })
            }}
          >
            <>
              <div
                className="d-inline-flex w-75"
                onClick={() => { }}
                style={{
                  alignItems: "center",
                  paddingTop: "3px",
                }}
              >
                <img
                  alt=""
                  src="/images/actioncenter/contextmenu.svg"
                  className="pr-2"
                  style={{ cursor: "grab" }}
                  onClick={(e) => { }}
                />
                <span className="f-grey">
                  {item.FieldName}
                </span>
              </div>
              <AttackSurfaceTooltip
                disabled={(item?.IsEditable===false && item?.IsEditable!==null) && item?.EditableContent ? false : true}
                bottomLeft
                content={item?.EditableContent}
              >
                <div
                  id="rename-div"
                  style={{
                    float: "right",
                  }}
                  className="position-relative sideBarDropdown complianceManagerDropdown actioncenterdropdown cursor-pointer"
                  onClick={(e) => {
                    if (item?.IsEditable===false && item?.IsEditable!==null) return;
                    handleShowHideColumn();
                  }}
                >
                  <img
                    id="rename-div"
                    alt=""
                    src={shownHiddenIcon}
                    className="float-right mt-2"
                    width={15}
                    height={15}
                    style={{
                      opacity:
                      (item?.IsEditable===false && item?.IsEditable !==null) ? 0.3 : 1,
                    }}
                  />
                </div>
              </AttackSurfaceTooltip>
            </>
          </div>
        );
      }}
    </Draggable>
  );
}

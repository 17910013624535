const OperationalSecurityBlockSection = ({ auditDetails }) => (
  <div className="w-100 d-flex flex-column">
    <div className="f-14 f-600">
      MSP Operational Security
    </div>
    {
      auditDetails?.MSPOperationalSecurity?.MSPSecopsAuditSection_List !== null && 
      auditDetails?.MSPOperationalSecurity?.MSPSecopsAuditSection_List?.some(section => section?.MSPSecopsAuditQuestion_List.length > 0) ?
        <div >
          {
            auditDetails?.MSPOperationalSecurity?.MSPSecopsAuditSection_List?.map((questionBlock, index) => {
              return (
                <>
                  {
                    questionBlock?.MSPSecopsAuditQuestion_List?.length !== 0 ? <>
                      <div className="f-14 f-500"
                        style={{
                          marginTop: "8px",
                          marginBottom: '8px'
                        }}
                      >
                        {questionBlock?.SectionText}
                      </div>
                      <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
                        {
                          questionBlock?.MSPSecopsAuditQuestion_List?.map((question, index) => {
                            return (
                              <div
                                className={`border-item d-flex align-items-center justify-content-between p-3`}
                                style={{
                                  borderBottom: `${index < questionBlock?.MSPSecopsAuditQuestion_List?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div>
                                      <span className="f-400 f-14 f-black">
                                        {question?.QuestionText}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex ml-2"
                                  style={{
                                    minWidth: 'fit-content'
                                  }}>
                                  <div className="f-400 f-14"
                                    style={{
                                      color: "#9499AA"
                                    }}
                                  >
                                    {question?.OldStatus}
                                  </div>
                                  <img
                                    alt=""
                                    src="/images/actioncenter/arrowdown.svg"
                                    className="mx-2"
                                    style={{
                                      transform: "rotate(270deg)"
                                    }}
                                  />
                                  <div className="f-400 f-14"
                                    style={{
                                      color: `${question?.NewStatus == 'No' ? "#F36060" : question?.NewStatus == "In Progress" ? "#008FD4" : "#9499AA"}`
                                    }}
                                  >
                                    {question?.NewStatus}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </> : <></>
                  }
                </>
              )
            })
          }
        </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
          style={{
            backgroundColor: '#F6F8FB',
            marginTop: '8px'
          }}
        >
          MSP Operational Security have not been changed
        </div>
    }
  </div>
);

export default OperationalSecurityBlockSection;

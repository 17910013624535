import React, { useEffect, useState } from "react";
import { getAPIData, getRequestOptions } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { Button, Dropdown } from "react-bootstrap";
import AddEditSecurityBaselineItemModal from "./AddEditSecurityBaselineItemModal";
import { CustomDeleteModal } from "../../../DeleteModal/DeleteModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const SecurityBaseline = () => {
    const { authTokens } = useAuth();
    const [loading, setLoading] = useState(false);
    const [allQuestions, setAllQuestions] = useState([]);
    const [showAddEditSecurityBaselineItemModal, setShowAddEditSecurityBaselineItemModal] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isHovered, setIsHovered] = useState(0);
    const [draggingId, setDraggingId] = useState(null);

    useEffect(() => {
        setLoading(true);
        getAllQuestions();
    }, []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEndShown(result) {
        if (!result.destination) {
            return;
        }
        let items = reorder(
            allQuestions,
            result.source.index,
            result.destination.index
        );
        setAllQuestions(
            items.map((item, index) => {

                return {
                    ...item,
                    SortOrder: index + 1,
                };
            })
        );
        items = items.map((item, index) => {
            return {
                ...item,
                SortOrder: index + 1,
            };
        })
        UpdateDragDrop(items);
    }

    async function getAllQuestions() {
        await getAPIData("695", authTokens)
            .then((response) => {
                if (response?.MSPBaselineQuestion_List) {
                    setAllQuestions(response?.MSPBaselineQuestion_List);
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                CallToast("Something went wrong", false, false, "v2style");
                setLoading(false);
            });
    }

    async function deleteQuestion(questionId, isPreSave = false) {
        await getAPIData("694", authTokens, {
            optionEnum1: questionId,
            optionEnum2: 0,
        })
            .then((response) => {
                if (response?.Success) {
                    getAllQuestions();
                    if (!isPreSave) {
                        CallToast("Deleted successfully", false, false, "v2style");
                    }
                } else {
                    if (!isPreSave) {
                        CallToast("Something went wrong", false, false, "v2style");
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                CallToast("Something went wrong", false, false, "v2style");
                setLoading(false);
            });
    }


    async function UpdateDragDrop(data) {
        let resData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/BaselineQuestionSortOrder`,
            getRequestOptions(authTokens, "POST", {
                MSPBaselineQuestion_List: JSON.stringify(data),
                AccessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
            })
        )
            .then((response) => response.json())
            .then((result) => {
                if (result?.Success) {
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
            });
    }

    return (
        <>
            {loading ? (
                <div className="security-baseline-wrapper">
                    <SecurityBaselineLoader />
                </div>
            ) : (
                <div className="security-baseline-wrapper">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <div className="f-14 f-600 f-black mt-4 mb-2">
                                MSP Security Baseline
                            </div>
                            <div className="f-darkgrey f-400 f-12">
                                Define your MSPs minimum security standards for your clients. These will be transposed into a client
                            </div>
                            <div className="f-darkgrey f-400 f-12 mb-2">
                                security checklist for each client.
                            </div>
                        </div>
                        <Button className="hg-blue-btn no-wrap"
                            onClick={() => {
                                setSelectedQuestionId(0);
                                setShowAddEditSecurityBaselineItemModal(true);
                            }}
                        >
                            <img
                                src="/images/white-plus-icon.svg"
                                alt=""
                                className="mr-2"
                            />
                            Add
                        </Button>
                    </div>
                    {
                        allQuestions?.length <= 0 &&
                        <div className="d-flex flex-column align-items-center justify-content-center mt-5 text-center">
                            <div className="f-14 f-600 f-grey text-center">
                                No security standards yet
                            </div>
                            <div
                                className={`f-14 f-500 text-center mt-3 f-500 f-blue pointer`}
                                onClick={() => {
                                    setSelectedQuestionId(0);
                                    setShowAddEditSecurityBaselineItemModal(true);
                                }}
                            >
                                Add your first security standard
                            </div>
                        </div>
                    }

                    {/* <DragDropContext onDragEnd={onDragEndShown}> */}
                    <DragDropContext onDragEnd={(result) => {
        setDraggingId(null);
        onDragEndShown(result);
      }}
      onDragStart={(start) => {
        setDraggingId(start.draggableId);
      }}>
                        <Droppable
                            direction="vertical"
                            droppableId="droppableSection"
                        >
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <div className="border-parent radius-8">
                                        <ul>
                                            {(allQuestions?.sort((a, b) => a.SortOrder - b.SortOrder))?.map((item, index) => {
                                                return (
                                                    <>
                                                        {item && (
                                                            <Draggable
                                                                key={item.Title}
                                                                draggableId={item?.ObjId_MSPBaselineQuestion?.toString()}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <li
                                                                        className=" border-item"
                                                                        onMouseEnter={() =>
                                                                            setIsHovered(item?.ObjId_MSPBaselineQuestion)
                                                                        }
                                                                        onMouseLeave={() =>
                                                                            setIsHovered(0)
                                                                        }
                                                                        onClick={() => {
                                                                            setSelectedQuestionId(item?.ObjId_MSPBaselineQuestion);
                                                                            setShowAddEditSecurityBaselineItemModal(true);
                                                                          }}
                                                                    >
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className=" d-flex flex-column border-item pointer hover-on-show-parent"
                                                                            key={index}
                                                                            index={index}
                                                                            onClick={() => {

                                                                            }}
                                                                        >
                                                                            <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                                                                                <div
                                                                                    className="d-flex flex-column "
                                                                                    style={{ width: "75%" }}
                                                                                >
                                                                                    <div className="d-flex align-items-center ">
                                                                                        <img
                                                                                            alt=""
                                                                                            className="mr-3"
                                                                                            src={
                                                                                                (/*isHovered == item?.ObjId_MSPBaselineQuestion || */draggingId == item?.ObjId_MSPBaselineQuestion)? "/images/supply-chain/drag-n-drop.svg" :
                                                                                                    "/images/msp/integrations/green-tick.svg"
                                                                                            }
                                                                                        />
                                                                                        <div>
                                                                                            <p className="f-500 m-0 f-black">{item?.Title}</p>
                                                                                            <p className="f-12 f-darkgrey">
                                                                                                {item?.Description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-center hover-on-show-child">
                                                                                    <Dropdown className="transparennt-wrapper-dropdown">
                                                                                        <Dropdown.Toggle>
                                                                                            <img
                                                                                                alt=""
                                                                                                className="pointer mx-2"
                                                                                                src="/images/settings-v2/horizontal-dots.svg"
                                                                                            />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu className="p-0">
                                                                                            <div
                                                                                                className="greyText2 f-14 f-400 my-2 ml-3 pointer"
                                                                                                onClick={() => {
                                                                                                    setSelectedQuestionId(item?.ObjId_MSPBaselineQuestion);
                                                                                                    setShowAddEditSecurityBaselineItemModal(true);
                                                                                                }}
                                                                                            >
                                                                                                Edit
                                                                                            </div>
                                                                                            <div
                                                                                                className="redText f-14 f-400 my-2 ml-3 pointer"
                                                                                                onClick={() => {
                                                                                                    setSelectedQuestionId(item?.ObjId_MSPBaselineQuestion);
                                                                                                    setShowDeleteModal(true);
                                                                                                }}
                                                                                            >
                                                                                                Delete
                                                                                            </div>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <div className="p-5" />

                </div>

            )}
            <AddEditSecurityBaselineItemModal
                questionId={selectedQuestionId}
                setQuestionId={setSelectedQuestionId}
                show={showAddEditSecurityBaselineItemModal}
                hideModal={() => {
                    setShowAddEditSecurityBaselineItemModal(false);
                    getAllQuestions();
                    setSelectedQuestionId(0);
                }}
                parentID="mainBody"
                deleteQuestion={deleteQuestion}
            />
            <CustomDeleteModal
                show={showDeleteModal}
                deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
                hideModal={() => {
                    setShowDeleteModal(false);
                    setSelectedQuestionId(0);
                }}
                deleteButtonClass={"hg-blue-btn"}
                deleteButtonText={"Delete"}
                deleteHeaderText={"Warning"}
                deleteBodyText={""}
                deleteFooterText={"Are you sure you want to delete?"}
                deleteFunction={() => {
                    deleteQuestion(selectedQuestionId);
                }}
                deleteParams={{}}
                deleteType={"warningClose"}
            />
        </>

    );
};

export default SecurityBaseline;

const SecurityBaselineLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={"100%"}
            viewBox="0 0 1500 900"
            backgroundColor="#F6F8FB"
            foregroundColor="#ecebeb"
        >
            <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
            <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
            <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
            <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
            <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
            <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
            <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
        </ContentLoader>
    );
};
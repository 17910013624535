import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import "./mspAttackSurface.scss";
import { postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import { ColorDropdown } from "./CreateCategorySpendModal";

const AddSubcategoryModal = ({
  show,
  categoryData,
  hideModal,
  parentCategoryId,
  colorList,
  refreshData,
  subCategoryName,
  mapProductSubCategory,
  fromStorefront = false
}) => {
  const clientId = fromStorefront ? 0 : window.location.pathname.split("/")[4];
  const isEditMode = useMemo(() => categoryData && Object.keys(categoryData).length > 0, [categoryData]);

  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState({
    name: "",
    colorData: {},
  });

  function changedzIndexOfModal(){
let modal_backdrop= document.querySelector(".modal-backdrop.show")
if(modal_backdrop){
  modal_backdrop.style.zIndex=1041
  return true
}else{
  setTimeout(() => {
    changedzIndexOfModal()
    
  }, 50);
}
  }
  useEffect(() => {
    if (show) {
      let _colorData={}
      let availableColor=colorList?.find((item)=> item?.IsInUse===false)
      if(availableColor){

        _colorData= {...availableColor}
      }
      setSubCategoryData({
        name: subCategoryName?? "",
        colorData: {..._colorData},
      });
     let test= changedzIndexOfModal()
      if (isEditMode) {
        console.log('categoryData', categoryData)
        setSubCategoryData({
          name: categoryData?.CategoryName,
          objId_Category: categoryData?.objId_Category,
          colorData: {
            ColorCode: categoryData?.CategoryColourHexCode,
            ColorName: categoryData?.CategoryColour,
          },
        });
      }
    }
  }, [show]);

  async function createCategory() {
    setSaving(true);

    await postData(authTokens, "CreateMSPTechnologyCategory", {
      objId_Customer: clientId,
      CategoryName: subCategoryData?.name,
      CategoryColour: subCategoryData?.colorData?.ColorName,
      objId_Category: subCategoryData?.objId_Category,
      ParentCategoryId: parentCategoryId,
      SubCategory_List: JSON.stringify([]),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          hideModal();
          refreshData();
          try {
            mapProductSubCategory(response?.ObjectTemplateList?.[0])
          } catch (err){}
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function errorCount() {
    let errors = 0;
    if (subCategoryData?.name === "") {
      errors += 1;
      setSubCategoryData((prev) => ({ ...prev, error: true }));
    }

    return errors;
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="create-sub-category-modal"
      className=""
      aria-labelledby=" "
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div></div>
          <div className="f-500 ml-3 f-16">
            {isEditMode ? "Edit subcategory" : "Add subcategory"}
          </div>
          <img
            alt=""
            src="/images/user-detail-close.svg"
            className="mr-3 pointer"
            onClick={hideModal}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        {/* Header */}

        <div className="d-flex align-items-center" style={{ height: "37px" }}>
          {/* Input */}
          <div className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 ${subCategoryData?.error ? "input-error" :""}`}>
            <div className="f-darkgrey">Name:</div>

            <input
              type="text"
              className="bg-transparent w-100 border-none pl-2"
              placeholder="Type"
              value={subCategoryData?.name}
              onChange={(e) => {
                let value = e.target.value.replaceAll(/[^a-zA-Z0-9\s]/g, "");
                setSubCategoryData((prev) => ({
                  ...prev,
                  name: value,
                  error: false,
                }));
              }}
            />
          </div>

          <div className="bg -grey h-1 00 d-flex align-items-center">
            <ColorDropdown
              height={37}
              itemCircleWidth={14}
              selectedCircleWidth={17}
              colorListWithAvailability={colorList}
             
              selected={subCategoryData?.colorData}
              onChange={(_colorData) => {
                setSubCategoryData((prev) => ({
                  ...prev,
                  colorData: _colorData,
                }));
              }}
            />
          </div>
        </div>

        <div className="" style={{ padding: "1rem" }} />
        {/* <div className="f-12 f-500 f-darkgrey ml-2 mt-2">Subcategories</div> */}
      </Modal.Body>
      <Modal.Footer className="p-2">
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="hg-blue-btn"
          
          onClick={async () => {
            let errors = await errorCount();
            if (errors === 0) {
              createCategory();
            }
          }}
          
          
          >
            {" "}
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSubcategoryModal;

export const _ColorDropdown = (props) => {
  const {
    data,
    itemCircleWidth,
    selectedCircleWidth,
    dropDownClass,
    selected,
    height,
  } = props;
  return (
    <Dropdown
      // alignRight
      className={`msp-color-list-dropdown d-flex align-items-center radius-4 bg-grey ${dropDownClass}`}
      style={{
        height: `${height}px`,
      }}
    >
      <Dropdown.Toggle>
        <div className="d-flex align-items-center">
          <div
            className="rounded-full"
            style={{
              width: `${selectedCircleWidth}px`,
              height: `${selectedCircleWidth}px`,
              background: selected.color,
            }}
          />
          <img alt="" className="ml-2" src="/images/big-chevron-down.svg" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="hide-scrollbar">
        {data?.map((color, colorIndex) => {
          return (
            <>
              <div
                className={`d-flex drop-item pl-3 py-2 align-items-center pointer ${
                  color?.id === selected?.id ? "selected" : ""
                }`}
                onClick={(e) => {
                  props.onChange(color);
                  e.target.closest(".msp-color-list-dropdown").click();
                }}
              >
                <div
                  className="rounded-full mr-2"
                  style={{
                    width: `${itemCircleWidth}px`,
                    height: `${itemCircleWidth}px`,
                    background: color.color,
                  }}
                />
                <div className="f-grey"> {color?.colorName} </div>
              </div>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const colorList = [
  {
    id: 0,
    color: "#0C0D11",
    colorName: "Black",
  },
  {
    id: 1,
    color: "#616778",
    colorName: "Comet",
  },
  {
    id: 2,
    color: "#9499AA",
    colorName: "Grey",
  },
  {
    id: 3,
    color: "#BBBEC9",
    colorName: "Light Grey",
  },
  {
    id: 4,
    color: "#0C0D11",
    colorName: "Black",
  },
  {
    id: 5,
    color: "#D4D6DD",
    colorName: "Silver",
  },
  {
    id: 6,
    color: "#A77322",
    colorName: "Rust",
  },
  {
    id: 7,
    color: "#BB7F24",
    colorName: "Dark Yellow",
  },
  {
    id: 8,
    color: "#FFAC2C",
    colorName: "Yellow",
  },
  {
    id: 9,
    color: "#FFCA78",
    colorName: "Light Yellow",
  },
  {
    id: 10,
    color: "#FFDEAB",
    colorName: "Navajo",
  },
  {
    id: 11,
    color: "#934732",
    colorName: "Burnt",
  },
  {
    id: 12,
    color: "#A44E36",
    colorName: "Dark Orange",
  },
  {
    id: 13,
    color: "#DF6844",
    colorName: "Orange",
  },
  {
    id: 14,
    color: "#EB9E87",
    colorName: "Light Orange",
  },
  {
    id: 15,
    color: "#0C0D11",
    colorName: "Black",
  },
];

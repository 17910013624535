import { BASE_API_PAGE_ENUMS, BASE_API_PATHS, BASE_API_ROUTES } from "../../constants/apis.constants";
import httpUtils from "../../Utils/http.utils";
import userUtils from "../../Utils/user.utils";
import useBaseActions from "./base.actions";
import { getAPIData, postData } from "../../Utils/commonApi";

const useIntegrationActions = () => {
  const { sendRequest, authTokens } = useBaseActions();

  const getConfigurationMappingList = ({
    page,
    limit,
    sellOrAse,
    search
  }) => {
    const query = httpUtils.generateQueryString({
      accessKey: userUtils.getUserMessage(),
      pageNo: page,
      pageLimit: limit,
      SellOrASE: sellOrAse,
      searchString: search,
    });
    const path = `${BASE_API_ROUTES.getConfigurationMappingList}${query}`;
    return sendRequest(path);
  }

  const rateAIMapping = ({
    objectId,
    rating,
  }) => {
    const query = httpUtils.generateQueryString({
      accessKey: userUtils.getUserMessage(),
      ObjectId: objectId,
      rating: rating ? "1" : "0",
    });
    const path = `${BASE_API_ROUTES.rateAIMapping}${query}`;
    return sendRequest(path, 'POST', {});
  }

  const reviewAssetMapping = ({ objectId }) => {
    const query = httpUtils.generateQueryString({
      accessKey: userUtils.getUserMessage(),
      ObjectId: objectId,
    });
    const path = `${BASE_API_ROUTES.reviewAssetsMapping}${query}`;
    return sendRequest(path, 'POST', {});
  }

  const getAllTechsByIntegration = ({ integrationId, customerId }) => {
    return getAPIData(BASE_API_PAGE_ENUMS.GetAllTechsByIntegration, authTokens, {
      accessKey: userUtils.getUserMessage(),
      optionEnum1: integrationId,
      ...(customerId && { optionEnum2: customerId }),
    })
  }

  const getSuggestions = ({ clientId }) => {
    return getAPIData(BASE_API_PAGE_ENUMS.GetSuggestions, authTokens, {
      accessKey: userUtils.getUserMessage(),
      optionEnum1: clientId,
    })
  }

  const getRecurringSpend = ({ clientId, sortVisibiityList = [] }) => {
    const query = httpUtils.generateQueryString({
      accessKey: userUtils.getUserMessage(),
    });
    const payload = httpUtils.toFormData({
      CategoryId: 0,
      SubCategoryId: 0,
      objId_Customer: clientId,
      optionEnum98: clientId,
      MSPClientSortVisibility_List: sortVisibiityList
    })
    const path = `${BASE_API_ROUTES.getRecurringSpend}${query}`;
    return sendRequest(path, 'POST', payload);
  }

  const reviewSuggestions = ({ clientId, reviewClicked = 0 }) => {
    return getAPIData(BASE_API_PAGE_ENUMS.ReviewSuggestions, authTokens, {
      accessKey: userUtils.getUserMessage(),
      optionEnum1: clientId,
      optionEnum2: String(reviewClicked)
    })
  }

  return {
    getConfigurationMappingList,
    rateAIMapping,
    reviewAssetMapping,
    getAllTechsByIntegration,
    getSuggestions,
    getRecurringSpend,
    reviewSuggestions
  }
}

export default useIntegrationActions;

import React, { useCallback, useEffect,   useRef,   useState } from "react";
import { Button,   Dropdown,   Modal,  } from "react-bootstrap";
import "../secOps.scss";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { getAPIData, postData,   } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import MultiSelectDropdown from "./MultiSelectDropdown";
import MspMemberAccessRoleModal from "../../../MSPSettingsv2/mspMembersAccess/MspMemberAccessRoleModal";
import { TrimString } from "../../../../Utils/StringTrimmer";
import Loader from "../../../Common/loader";
import AddMemberModal from "../../../Common/add-member-modal/add-member.modal";
const AddMemberModalOuter = ({ show, hideModal, refreshData, showMemberAccessModal, hideCreateOption, isForMSP, isForMSPMember }) => {
  const USERS_LIMIT = 20; 
  const { authTokens } = useAuth();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedMembersList, setSelectedMembersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false)
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(1);
  const [modalLoading, setModalLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (show) {
      setPageLoading(true);
      getMembersDetails(0, "");
      getAccessRolesData();
    } else {
      setTimeout(() => {
        setMembers([]);
        setRoles([]);
        setPageNumber(1);
        setModalLoading(false)
        setSearch("")
      }, 300);
    }
  }, [show]);

  const handleScroll = useCallback(() => {
    if (isForMSP && pages > 1) {
      setModalLoading(true)
      setPageNumber(pageNumber+1)
      getMembersDetails(0, search, pageNumber+1)
    }
  }, [pages, pageNumber, members, search]);

  const onSearch = (searchValue) => {
    setSearch(searchValue)
    setModalLoading(true)
    getMembersDetails(0, searchValue)
    setPageNumber(1)
  }
  
  async function getMembersDetails(option1, option2, pageNum) {
    await getAPIData("564", authTokens, isForMSP ? {
      optionEnum1: option1,
      optionStrEnum1: option2,
      optionEnum2: pageNum ? pageNum : 1,
      optionEnum3: USERS_LIMIT
    } : {
      optionEnum1: option1,
      optionStrEnum1: option2
    }).then((response) => {
      setModalLoading(false)
      if (response.mr.Success) {
        if (isForMSP) {
          if (!members[0] || !pageNum) {
            setPages(Math.ceil(response?.totalUsers/USERS_LIMIT))
            setMembers(response?.users);
          } else {
            setMembers(members.concat(response?.users))
          }
        } else {
          setMembers(response?.users);
        }
        // console.log(response.users);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    }).finally(() => {
      setPageLoading(false);
    });
  }

  async function getAccessRolesData(){
    await getAPIData("564", authTokens, {
      optionEnum1: "1",
    }).then((response) => {
      if (response.mr.Success) {
        setRoles(response.roles);
      }
    });
  }

  async function inviteMember(selectedMembersList, sendInviteOnly){
    setLoading(true);
    let list = [];
    selectedMembersList.map((member) => {
      list.push({
        Key: member.objId_PSAContact, 
        Value: member.userName,
        ...(member?.selectedRole?.objId_AccessRole ? { objId_AccessRole: [member?.selectedRole?.objId_AccessRole] } : {})
      });
    });
    if(list.length > 0){
      await postData(authTokens, "Invite_MSPTeamMember", 
      {
        obj_List: JSON.stringify(list),
        ...(sendInviteOnly ? { SendInviteOnly: sendInviteOnly } : {})
      }
    )
      .then((response) => {
        setLoading(false);
        if (response?.Success) {
          CallToast("Member invited successfully", false, false, "v2style");

          hideModal();
          setSelectedMembersList([]);
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
    }else {
      setLoading(false);
      CallToast("Choose member to invite", false, false, "v2style");

    }
    
  }

  return (
    <div>
      {show && (
        <AddMemberModal 
          show={show}
          onHide={hideModal} 
          roles={roles}
          handleScroll={handleScroll}
          members={members}
          inviteMember={inviteMember}
          hideCreateOption={hideCreateOption}
          loading={loading}
          pageLoading={pageLoading}
          modalLoading={modalLoading}
          setShowAccessLevelModal={setShowAccessLevelModal}
          isForMSPMember={true}
          getAccessRolesData={getAccessRolesData}
          memberKey="objId_PSAMember"
          onSearch={onSearch}
          currentPage={pageNumber}
          totalPages={pages}
        />
      )}
    </div>
  )
};

export default AddMemberModalOuter;

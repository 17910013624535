import React from "react";
import { useAuth } from "../context/Auth";
import { useDb } from "../context/db";
import { Redirect, useLocation } from "react-router-dom";
import { APP_ROUTES, ROUTE_PATHS } from "../../constants/app.constants";
const Logout = () => {
  const { setAuth } = useAuth();
  const location = useLocation();
  const { state: redirectInformation } = location;
  localStorage.clear();
  setAuth(false);
  let userAgent = navigator.userAgent;
    if(userAgent.match(/firefox|fxios/i)){
      window.localStorage.clear()
      window.location.replace(ROUTE_PATHS.auth.login)
    }
  // window.location.href = "/login"; //Performs a hard-refresh, thus, refreshing all non-persistant states
  return <Redirect to={APP_ROUTES.auth.login} />;
};

export default Logout;

import moment from "moment";
import { addVariableIntoAPIForMSPClient } from "./CommonFunctions";
import { ROUTE_PATHS } from "../constants/app.constants";
const url = `${process.env.REACT_APP_API_BASE_URL}/CC_API`;
const urlContributor = `${process.env.REACT_APP_API_BASE_URL}/HGIR`;

// staging URL= h t  t p s: / / cy be rc ar  e.ob jec tr a.co-m/
// Production URL= https://api.highground.io-/
// new
//CHange to allow commit GR 3/12/23 and again 6/12/23

function handleResponse(APIresponse) {
  if (APIresponse.status === 401) {
    localStorage.setItem(
      "logout_reason",
      ` Token expiry at: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`
    );
    // window.localStorage.clear();
    // window.location.replace("/logout");
    localStorage.removeItem("tokens");
    // localStorage.removeItem("user_MessageObj");
    // alert("dcd");
    let userAgent = navigator.userAgent;
    if (userAgent.match(/firefox|fxios/i)) {
      window.localStorage.clear();
      window.location.replace(ROUTE_PATHS.auth.login);
    }
  }

  return APIresponse.json();
}

export async function setUserDetails(authToken) {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPageData?pageEnum=0`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export function getRequestOptions(token, method = "GET", bodyContents = "") {
  var myHeaders = new Headers();

  if (token) myHeaders.append("Authorization", `Bearer ${token}`);

  if (bodyContents != "") {
    //create FormData Object to hold the body's contents
    var urlencoded = new FormData();
    for (const key in bodyContents) urlencoded.append(key, bodyContents[key]);

    var requestOptions = {
      method: `${method}`,
      headers: myHeaders,
      body: urlencoded,
    };
  } else {
    var requestOptions = {
      method: `${method}`,
      headers: myHeaders,
    };
  }

  return requestOptions;
}

export async function getAPIData(page, authToken, additionalParameters = []) {
  additionalParameters = addVariableIntoAPIForMSPClient(additionalParameters);
  
  
  try {
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    var impersinateObject =
      page == 70
        ? ""
        : JSON.parse(localStorage.getItem("impersinatingDetails"));
    // if messageEncrypt does exist in localStorage then call function
    let url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPageData?pageEnum=${page}`;
    Object.keys(additionalParameters).forEach((key) => {
      url += `&${key}=${additionalParameters[key]}`;
    });

    url += message ? `&accessKey=${message}` : "";
    url += `${
      impersinateObject
        ? `&impersonateOrgId=${impersinateObject.orgId}&impersonateRole=${impersinateObject.role}`
        : ""
    }`;

    let data = await fetch(url, getRequestOptions(authToken)).then(
      (response) => {
        return handleResponse(response);
      },
      (error) => {
        // window.location.href = "/error";
      }
    );

    if (data.AccessKey !== undefined) {
      localStorage.setItem("user_MessageObj", JSON.stringify(data.AccessKey));
      // data = await getAPIData(page,authToken,additionalParameterLabel,additionalParameterValue);
      return { authToken: data.AccessToken };
    }
    if (
      data.Message &&
      data.Message == "Authorization has been denied for this request."
    ) {
      localStorage.setItem(
        "logout_reason",
        ` Token expiry at: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`
      );
      userTokenExpired(authToken);
    }

    return data;
  } catch (error) {
    // window.location.href = "/error";
  }
}

export async function getPageDataApi(
  pageName,
  authToken,
  additionalParameters = []
) {
  try {
    additionalParameters = addVariableIntoAPIForMSPClient(additionalParameters);
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));

    // if messageEncrypt does exist in localStorage then call function
    let url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/${pageName}?accessKey=${message}`;
    Object.keys(additionalParameters).forEach((key) => {
      url += `&${key}=${additionalParameters[key]}`;
    });

    url += message ? `&` : "";

    let data = await fetch(url, getRequestOptions(authToken)).then(
      (response) => {
        return handleResponse(response);
      }
    );

    return data;
  } catch (error) {
    // window.location.href = "/error";
  }
}

export async function postData(authToken, type, formData) {
  formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${url}/${type}?&accessKey=${message}`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}
export async function postJsonData(authToken, type, JsonData, additionalParams = null) {
  var myHeaders = new Headers();

  if (authToken) myHeaders.append("Authorization", `Bearer ${authToken}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: `POST`,
    headers: myHeaders,
    body: JSON.stringify(JsonData),
  };

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  const additionalParamsString = additionalParams ?  '&' + new URLSearchParams(additionalParams).toString() : '';
  let data;
  data = await fetch(
    `${url}/${type}?&accessKey=${message}${additionalParamsString}`,
    requestOptions
  ).then((response) => handleResponse(response));
  return data;
}

export async function postRequest(authToken, type, formData) {
  formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  let data;
  data = await fetch(
    `${url}/${type}`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}

export async function getAPIDatBlob(
  page,
  authToken,
  additionalParameterLabel = "",
  additionalParameterValue = "",
) {
  var message = JSON.parse(localStorage.getItem("user_MessageObj"));

  let _url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPageData?pageEnum=${page}&accessKey=${message}${additionalParameterLabel && additionalParameterValue !== ""
  ? `&${additionalParameterLabel}=${additionalParameterValue}`
  : ""
  }`

  let additionalParameters = addVariableIntoAPIForMSPClient({});
  Object.keys(additionalParameters).forEach((key) => {
    _url += `&${key}=${additionalParameters[key]}`;
  });

  let data = await fetch(
    _url,
    getRequestOptions(authToken)
  ).then((response) => response.blob());

  return data;
}

export async function getTicketNotes(authTokens, bizObjectId, ticketId) {

  let _url = 
  `${url}/GetIncidentReportNotes?ticketId=${ticketId}&bizObjectId=${bizObjectId}&accessKey=${JSON.parse(
    localStorage.getItem("user_MessageObj")
  )}`
  
  let additionalParameters = addVariableIntoAPIForMSPClient({});
  Object.keys(additionalParameters).forEach((key) => {
    _url += `&${key}=${additionalParameters[key]}`;
  });

  const data = await fetch(
    _url,
    getRequestOptions(authTokens)
  ).then((response) => handleResponse(response));
  return data;
}

export async function PrintDataByEntityId(entityId, authToken) {
  const data = await getAPIDatBlob("60", authToken, "optionEnum1", entityId);
  return data;
}
export async function PostInvestment(
  authToken,
  formBodyJson,
  objectId = 0,
  type = "insert",
) {
  let data;
  if (type === "insert") {

    let _url = `${url}/AddEditCyberInvestment`
    
    let additionalParameters = addVariableIntoAPIForMSPClient({});
    Object.keys(additionalParameters).forEach((key) => {
      _url += `&${key}=${additionalParameters[key]}`;
    });

    data = await fetch(
        _url,
      getRequestOptions(authToken, "POST", formBodyJson)
    ).then((response) => handleResponse(response));
  } else if (type == "delete") {

    let _url = `${url}/DeleteObject?ObjectId=${objectId}`
    
    let additionalParameters = addVariableIntoAPIForMSPClient({});
    Object.keys(additionalParameters).forEach((key) => {
      _url += `&${key}=${additionalParameters[key]}`;
    });

    data = await fetch(
        _url,
      getRequestOptions(authToken)
    ).then((response) => handleResponse(response));
    console.log("PostInvestment", data);
  }
  return data;
}

export async function getInvestmentDDL(authToken, pageEnum) {

  let _url = `${url}/GetPageEnumDDLList?pageEnum=${pageEnum}`
    
  let additionalParameters = addVariableIntoAPIForMSPClient({});
  Object.keys(additionalParameters).forEach((key) => {
    _url += `&${key}=${additionalParameters[key]}`;
  });

  const data = await fetch(
      _url,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export async function getScenarioPacks(authToken, pageEnum, optionEnum) {
  let accessKey =localStorage.getItem("user_MessageObj")? JSON.parse(localStorage.getItem("user_MessageObj")):"";

  let _url = `${url}/GetPageData?pageEnum=${pageEnum}&optionEnum1=${optionEnum}&accessKey=${accessKey}`
  let additionalParameters = addVariableIntoAPIForMSPClient({});
  Object.keys(additionalParameters).forEach((key) => {
    _url += `&${key}=${additionalParameters[key]}`;
  });


  const data = await fetch(
    _url ,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export async function AddRemoveSecurityPack(
  authToken,
  pageEnum,
  optionEnum1,
  optionEnum2,
  optionEnum3
) {
  const data = await fetch(
    `${url}/GetPageData?pageEnum=${pageEnum}&optionEnum1=${optionEnum1}&optionEnum2=${optionEnum2}${
      optionEnum3 ? `&optionEnum3=${optionEnum3}&` : "&"
    }accessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export async function AddRemoveSecurityPackOptions(
  authToken,
  pageEnum,
  optionEnum1,
  optionEnum2,
  optionEnum3
) {
  const data = await fetch(
    `${url}/GetPageData?pageEnum=${pageEnum}&optionEnum1=${optionEnum1}&optionEnum2=${optionEnum2}&optionEnum3=${optionEnum3}&optionEnum4=1&accessKey=${JSON.parse(
      localStorage.getItem("user_MessageObj")
    )}`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export function userTokenExpired(authToken) {
  /* TOKEN WILL NEED TO BE REFRESHED IN FUTURE */
  // window.localStorage.clear();
  // alert("dcd");
  // window.location.replace("/logout");
  localStorage.removeItem("tokens");
  // localStorage.removeItem("user_MessageObj");
}

export function convertDateNumericToString(dateInt, limit = 0) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (limit) return months[dateInt].slice(0, limit)[0];
  return months[dateInt];
}

//check if the image contains a gravatar or not
export async function generateGravatar(imageLink) {
  const data = await fetch(`${imageLink}`, getRequestOptions(null));

  if (data.status == 404)
    //The user does not have a gravatar
    return false;
  return true; //The user has a gravatar
}

export function cloneObject(source) {
  if (Object.prototype.toString.call(source) === "[object Array]") {
    var clone = [];
    for (var i = 0; i < source.length; i++) {
      clone[i] = cloneObject(source[i]);
    }
    return clone;
  } else if (typeof source == "object") {
    var clone = {};
    for (var prop in source) {
      if (source.hasOwnProperty(prop)) {
        clone[prop] = cloneObject(source[prop]);
      }
    }
    return clone;
  } else {
    return source;
  }
}

export async function GetDbObject(authToken, userRole) {
  console.log("USER ROLE", userRole);
  switch (userRole) {
    case 111111:
      return await getAPIData("0", authToken);
    case 222222:
      return await getAPIData("2", authToken);
    case 333333:
      return await getAPIData("1", authToken);
  }
  return undefined;
}

// stripe api

const secret =
  "sk_test_51InKXvD0srAsAW8TFDHlZTT9FMDfwLsKw8TgzwfXYvHVavSCmiqHeyxqiDy2qR6Ey2t3HYo0IxVMY4Mxmo8jc0e100Mzw57czT";

// Add Card In Stripe
export async function addPaymentMethodStripe(billingDetails, cardDetails) {
  const month = cardDetails.monthyear.split("/");
  var formBody = [];
  formBody.push("type" + "=card");
  formBody.push("card[number]" + "=" + cardDetails.cardnumber);
  formBody.push("card[exp_month]" + "=" + month[0]);
  formBody.push("card[exp_year]" + "=" + month[1]);
  formBody.push("card[cvc]" + "=" + cardDetails.cvc);
  formBody.push("billing_details[name]" + "=" + cardDetails.cardname);

  formBody = formBody.join("&");

  const url = "https://api.stripe.com/v1/payment_methods";

  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  return data;
}

// Attach Payment Method In Stripe
export async function attachPaymentMethodStripe(
  paymentIntent,
  paymentMethodId
) {
  const request = await getPaymentIntentStripe(paymentIntent);
  if (request.error) {
    return false;
  }
  const customer = request.customer;

  var formBody = [];
  formBody.push("customer" + "=" + customer);

  formBody = formBody.join("&");

  const url = `https://api.stripe.com/v1/payment_methods/${paymentMethodId}/attach`;

  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  return data;
}

// Get Payment Intent from stripe
export async function getPaymentIntentStripe(paymentIntent) {
  const url = `https://api.stripe.com/v1/payment_intents/${paymentIntent}`;
  const data = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  }).then((response) => handleResponse(response));
  return data;
}

// confirm payment
export async function confirmPaymentStripe(paymentMethodId, paymentIntent) {
  var formBody = [];
  formBody.push("payment_method" + "=" + paymentMethodId);
  const url = `https://api.stripe.com/v1/payment_intents/${paymentIntent}/confirm`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  return data;
}

// Subscription payment
export async function subscriptionStripe(customerId, priceId, taxId = false) {
  var formBody = [];
  formBody.push("customer" + "=" + customerId);
  formBody.push("items[0][price]" + "=" + priceId);
  if (taxId) {
    formBody.push("default_tax_rates[0]" + "=" + taxId);
  }
  formBody = formBody.join("&");
  const url = `https://api.stripe.com/v1/subscriptions`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  return data;
}

// create token
export async function createTokenStripe(cardDetails, billing_details, amt) {
  const month = cardDetails.monthyear.split("/");
  var formBody = [];
  formBody.push("card[number]" + "=" + cardDetails.cardnumber);
  formBody.push("card[exp_month]" + "=" + month[0]);
  formBody.push("card[exp_year]" + "=" + month[1]);
  formBody.push("card[cvc]" + "=" + cardDetails.cvc);
  formBody.push("card[name]" + "=" + cardDetails.cardname);

  formBody = formBody.join("&");
  const url = `https://api.stripe.com/v1/tokens`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  if (data.error) {
    return data;
  } else {
    const { id } = data;
    const chargeRes = createChargeStripe(id, amt);
    return chargeRes;
  }
}

// create Charge
async function createChargeStripe(tokenId, amt) {
  var formBody = [];
  formBody.push("amount" + "=" + `${amt}00`);
  formBody.push("currency" + "=" + "gbp");
  formBody.push("source" + "=" + tokenId);
  formBody.push("description" + "=" + "Charged successfully");
  formBody = formBody.join("&");
  const url = `https://api.stripe.com/v1/charges`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  return data;
}

// update Customer
export async function updateCustomer(customer, method) {
  var formBody = [];
  formBody.push("invoice_settings[default_payment_method]" + "=" + method);
  const url = `https://api.stripe.com/v1/customers/${customer}`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${secret}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  return data;
}

export async function getAccessToken(accessKey, authToken) {
  var formBody = [];
  formBody.push("AccessKey" + "=" + accessKey);
  const url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetAccessKeyAndToken`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));
  if (data.AccessKey && data.AccessToken) {
    localStorage.setItem("user_MessageObj", JSON.stringify(data.AccessKey));
    localStorage.setItem("tokens", JSON.stringify(data.AccessToken));
  }
  return data;
}

export const refreshAccessToken = async (callback = null) => {
  const refresh_token = localStorage.getItem("refresh_token");

  if (!refresh_token) return;

  var formBody = [];
  formBody.push(`grant_type=refresh_token`);
  formBody.push(`refresh_token=${localStorage.getItem("refresh_token")}`);
  formBody = formBody.join("&");

  const url = `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`;  

  const data = await fetch(url, {
    method: "POST",
    // mode: 'no-corse',
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => handleResponse(response));

  await localStorage.setItem("tokens", JSON.stringify(data.access_token));
  await localStorage.setItem("token_time", Math.floor(Date.now() / 60000));

  if (callback) callback(data.access_token);
};

export async function SubmitQuestionaire(authToken, type, formData) {
  formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${url}/CM_SubmitQuestionnaire`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}

export async function CreateNewScenario(authToken, type, formData) {
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${url}/CreateScenario`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}

export async function getDefaultAssets(authToken, pageEnum) {
  const data = await fetch(
    `${url}/GetPageData?pageEnum=${pageEnum}`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export async function getAllQuestions(authToken, pageEnum) {
  const data = await fetch(
    `${url}/GetPageData?pageEnum=${pageEnum}`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export async function GetSubmitQuoteData(authToken, pageEnum, optionEnum1) {
  const data = await fetch(
    `${url}/GetPageData?pageEnum=${pageEnum}&optionEnum1=${optionEnum1}&AccessKeys=${JSON.parse(
      localStorage.getItem("user_MessageObj")
    )}`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

export async function getCaptureAutomatically(
  authToken,
  pageEnum,
  optionEnum1
) {
  const data = await fetch(
    `${url}/GetPageData?pageEnum=${pageEnum}&accesskey=${JSON.parse(
      localStorage.getItem("user_MessageObj")
    )}&QuestionnaireType=PricingCalculator&optionEnum1=${optionEnum1}`,
    getRequestOptions(authToken)
  ).then((response) => handleResponse(response));
  return data;
}

// ATTACK SURFACE APIs Setup
export async function AttackSurfaceGetApi(
  page,
  authToken,
  additionalParameters = []
) {
  additionalParameters = addVariableIntoAPIForMSPClient(additionalParameters);
  try {
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    var impersinateObject =
      page == 70
        ? ""
        : JSON.parse(localStorage.getItem("impersinatingDetails"));
    // if messageEncrypt does exist in localStorage then call function

    let url = `${process.env.REACT_APP_API_BASE_URL}/${page}`;
    Object.keys(additionalParameters).forEach((key) => {
      url += `${key}=${additionalParameters[key]}&`;
    });

    url += message ? `AccessKey=${message}` : "";
    url += `${
      impersinateObject
        ? `&impersonateOrgId=${impersinateObject.orgId}&impersonateRole=${impersinateObject.role}`
        : ""
    }`;

    let data = await fetch(url, getRequestOptions(authToken)).then(
      (response) => {
        return handleResponse(response);
      },
      (error) => {
        // window.location.href = "/error";
      }
    );

    if (data.AccessKey !== undefined) {
      localStorage.setItem("user_MessageObj", JSON.stringify(data.AccessKey));
      // data = await getAPIData(page,authToken,additionalParameterLabel,additionalParameterValue);
      return { authToken: data.AccessToken };
    }
    if (
      data.Message &&
      data.Message == "Authorization has been denied for this request."
    ) {
      localStorage.setItem(
        "logout_reason",
        ` Token expiry at: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`
      );
      userTokenExpired(authToken);
    }

    return data;
  } catch (error) {
    // window.location.href = "/error";
  }
}

export async function AttackSurfacePostApi(authToken, type, body) {
 let additionalParameters = addVariableIntoAPIForMSPClient({
  optionEnumm:0
 });

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));

  let url = `${process.env.REACT_APP_API_BASE_URL}/${type}`;
  Object.keys(additionalParameters).forEach((key) => {
    url += `${key}=${additionalParameters[key]}&`;
  });

  url += message ? `AccessKey=${message}` : "";

  const rawResponse = await fetch(
    `${url}`,
    // `https://hg-dev-ui-ac-api.azurewebsites.net/${type}&AccessKey=${message}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(body),
    }
  ).then((response) => response);
  return handleResponse(rawResponse);
}

 

export async function CustomGetApi(page, authToken, additionalParameters = []) {
  try {
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));


    let url = `${process.env.REACT_APP_API_BASE_URL}/${page}?`;
    Object.keys(additionalParameters).forEach((key) => {
      url += `${key}=${additionalParameters[key]}&`;
    });

    url += message ? `AccessKey=${message}` : "";

    let data = await fetch(url, getRequestOptions(authToken)).then(
      (response) => {
        return handleResponse(response);
      },
      (error) => {
        // window.location.href = "/error";
      }
    );

    return data;
  } catch (error) {
    // window.location.href = "/error";
  }
}
export async function ContributorGetAPIData(page, authToken, additionalParameters = []) {
  additionalParameters = addVariableIntoAPIForMSPClient(additionalParameters);

  try {
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    var impersinateObject =
      page == 70
        ? ""
        : JSON.parse(localStorage.getItem("impersinatingDetails"));
    // if messageEncrypt does exist in localStorage then call function

    let url = `${process.env.REACT_APP_API_BASE_URL}/HGIR/GetPageData?pageEnum=${page}`;
    Object.keys(additionalParameters).forEach((key) => {
      url += `&${key}=${additionalParameters[key]}`;
    });

    url += message ? `&key=${message}` : "";
    url += `${
      impersinateObject
        ? `&impersonateOrgId=${impersinateObject.orgId}&impersonateRole=${impersinateObject.role}`
        : ""
    }`;

    let data = await fetch(url, getRequestOptions(authToken)).then(
      (response) => {
        return handleResponse(response);
      },
      (error) => {
        // window.location.href = "/error";
      }
    );

    if (data.AccessKey !== undefined) {
      localStorage.setItem("user_MessageObj", JSON.stringify(data.AccessKey));
      // data = await getAPIData(page,authToken,additionalParameterLabel,additionalParameterValue);
      return { authToken: data.AccessToken };
    }
    if (
      data.Message &&
      data.Message == "Authorization has been denied for this request."
    ) {
      localStorage.setItem(
        "logout_reason",
        ` Token expiry at: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`
      );
      userTokenExpired(authToken);
    }

    return data;
  } catch (error) {
    // window.location.href = "/error";
  }
}

export async function ContributorPostData(authToken, type, formData) {
  formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${urlContributor}/${type}?&key=${message}`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}
export async function ContributorPostJsonData(authToken, type, JsonData) {
  var myHeaders = new Headers();

  if (authToken) myHeaders.append("Authorization", `Bearer ${authToken}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: `POST`,
    headers: myHeaders,
    body: JSON.stringify(JsonData),
  };

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${urlContributor}/${type}?&key=${message}`,
    requestOptions
  ).then((response) => handleResponse(response));
  return data;
}

export async function ContributorPostRequest(authToken, type, formData) {
  formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  let data;
  data = await fetch(
    `${urlContributor}/${type}`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}

export async function ContributorSubmitQuestionaire(authToken, type, formData) {
  formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${urlContributor}/CM_SubmitQuestionnaire`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}



// export async function AccessControlGetAPIData(page, authToken, additionalParameters = []) {
//   additionalParameters = addVariableIntoAPIForMSPClient(additionalParameters);

//   try {
//     var message = JSON.parse(localStorage.getItem("user_MessageObj"));
//     var impersinateObject =
//       page == 70
//         ? ""
//         : JSON.parse(localStorage.getItem("impersinatingDetails"));
//     // if messageEncrypt does exist in localStorage then call function
//     let url = `https:hg-dev-ui-ac-api.azurewebsites.net/CC_API/GetPageData?pageEnum=${page}`;
//     Object.keys(additionalParameters).forEach((key) => {
//       url += `&${key}=${additionalParameters[key]}`;
//     });
// console.log('url --- ', url)
//     url += message ? `&accessKey=${message}` : "";
//     url += `${
//       impersinateObject
//         ? `&impersonateOrgId=${impersinateObject.orgId}&impersonateRole=${impersinateObject.role}`
//         : ""
//     }`;

//     let data = await fetch(url, /*{mode: "no-cors"},*/ getRequestOptions(authToken)).then(
//       (response) => {
//         return handleResponse(response);
//       },
//       (error) => {
//         // window.location.href = "/error";
//       }
//     );

//     if (data.AccessKey !== undefined) {
//       localStorage.setItem("user_MessageObj", JSON.stringify(data.AccessKey));
//       // data = await getAPIData(page,authToken,additionalParameterLabel,additionalParameterValue);
//       return { authToken: data.AccessToken };
//     }
//     if (
//       data.Message &&
//       data.Message == "Authorization has been denied for this request."
//     ) {
//       localStorage.setItem(
//         "logout_reason",
//         ` Token expiry at: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`
//       );
//       userTokenExpired(authToken);
//     }

//     return data;
//   } catch (error) {
//     // window.location.href = "/error";
//   }
// }

// export async function AccessControlPostData(authToken, type, formData) {
//   formData = addVariableIntoAPIForMSPClient(formData);
//   let getFormData = JSON.stringify(formData);
//   getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
//   formData = JSON.parse(getFormData);

//   var message = JSON.parse(localStorage.getItem("user_MessageObj"));
//   let data;
//   data = await fetch(
//     `${urlAccessControl}/${type}?&accessKey=${message}`,
//     getRequestOptions(authToken, "POST", formData)
//   ).then((response) => handleResponse(response));
//   return data;
// }
// export async function AccessControlPostJsonData(authToken, type, JsonData) {
//   var myHeaders = new Headers();

//   if (authToken) myHeaders.append("Authorization", `Bearer ${authToken}`);
//   myHeaders.append("Accept", "application/json");
//   myHeaders.append("Content-Type", "application/json");

//   let requestOptions = {
//     method: `POST`,
//     headers: myHeaders,
//     body: JSON.stringify(JsonData),
//   };

//   var message = JSON.parse(localStorage.getItem("user_MessageObj"));
//   let data;
//   data = await fetch(
//     `${urlAccessControl}/${type}?&accessKey=${message}`,
//     requestOptions
//   ).then((response) => handleResponse(response));
//   return data;
// }

// export async function AccessControlPostRequest(authToken, type, formData) {
//   formData = addVariableIntoAPIForMSPClient(formData);
//   let getFormData = JSON.stringify(formData);
//   getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
//   formData = JSON.parse(getFormData);

//   let data;
//   data = await fetch(
//     `${urlAccessControl}/${type}`,
//     getRequestOptions(authToken, "POST", formData)
//   ).then((response) => handleResponse(response));
//   return data;
// }

// export async function AccessControlSubmitQuestionaire(authToken, type, formData) {
//   formData = addVariableIntoAPIForMSPClient(formData);
//   let getFormData = JSON.stringify(formData);
//   getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
//   formData = JSON.parse(getFormData);

//   var message = JSON.parse(localStorage.getItem("user_MessageObj"));
//   let data;
//   data = await fetch(
//     `${urlAccessControl}/CM_SubmitQuestionnaire`,
//     getRequestOptions(authToken, "POST", formData)
//   ).then((response) => handleResponse(response));
//   return data;
// }

export async function IndependentGetAPIData(page, authToken, additionalParameters = []) {
  

  try {
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    var impersinateObject =
      page == 70
        ? ""
        : JSON.parse(localStorage.getItem("impersinatingDetails"));
    // if messageEncrypt does exist in localStorage then call function
    let url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPageData?pageEnum=${page}`;
    // let url = `https://hg-dev-ui-ac-api.azurewebsites.net/CC_API/GetPageData?pageEnum=${page}`;
    Object.keys(additionalParameters).forEach((key) => {
      url += `&${key}=${additionalParameters[key]}`;
    });

    url += message ? `&accessKey=${message}` : "";
    url += `${
      impersinateObject
        ? `&impersonateOrgId=${impersinateObject.orgId}&impersonateRole=${impersinateObject.role}`
        : ""
    }`;

    let data = await fetch(url, getRequestOptions(authToken)).then(
      (response) => {
        return handleResponse(response);
      },
      (error) => {
        // window.location.href = "/error";
      }
    );

    if (data.AccessKey !== undefined) {
      localStorage.setItem("user_MessageObj", JSON.stringify(data.AccessKey));
      // data = await getAPIData(page,authToken,additionalParameterLabel,additionalParameterValue);
      return { authToken: data.AccessToken };
    }
    if (
      data.Message &&
      data.Message == "Authorization has been denied for this request."
    ) {
      localStorage.setItem(
        "logout_reason",
        ` Token expiry at: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`
      );
      userTokenExpired(authToken);
    }

    return data;
  } catch (error) {
    // window.location.href = "/error";
  }
}

export async function IndependentSubmitQuestionaire(authToken, type, formData/*, additionalParameters = []*/) {
  // formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  var message = JSON.parse(localStorage.getItem("user_MessageObj"));
  let data;
  data = await fetch(
    `${url}/CM_SubmitQuestionnaire`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}

export async function IndependentPostRequest(authToken, type, formData) {
  // formData = addVariableIntoAPIForMSPClient(formData);
  let getFormData = JSON.stringify(formData);
  getFormData = getFormData.replace(/<\/?[^>]+>/gi, "");
  formData = JSON.parse(getFormData);

  let data;
  data = await fetch(
    `${url}/${type}`,
    getRequestOptions(authToken, "POST", formData)
  ).then((response) => handleResponse(response));
  return data;
}
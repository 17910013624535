import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { menuToggle } from "../../../redux/highgroundGlobalState/actions";
import './sidebar.styles.scss';
import useRespondTo from "../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../constants/window.constants";
import CollapseArrowIcon from "../../icons/collapse-arrow.icon";
import SmallLockIcon from "../../icons/small-lock.icon";
import SmallComingSoonIcon from "../../icons/small-coming-soon.icon";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

/**
 * @param {Object} props
 * @param {Array<{ tabName: string, tabImage: string, tabIcon?: Function, tabURL: string, tabKey: string, disableState: boolean,
 * comingSoon?: string, activePage?: boolean, renderItem?: Function, onClick?: Function }>} props.list
 * @param {string} props.activePage
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {React.ReactNode} props.headerComponent
 * @param {boolean} props.loading
 */
const Sidebar = ({
  list = [],
  activePage = "",
  className = "",
  style = {},
  headerComponent,
  loading = false,
  children
}) => {
  const [preload, setPreload] = useState(true);
  const isMobile = useRespondTo(WindowBreakpoints.md);
  const isTablet = useRespondTo(WindowBreakpoints.lg);
  const dispatch = useDispatch();
  const isMenuCollapse = useSelector((state) => state.global.menuCollapse) || isTablet;

  const handleCollapse = () => {
    dispatch(menuToggle(!isMenuCollapse));
  };

  useEffect(() => {
    if (loading) return;
    setTimeout(() => {
      setPreload(false);
    }, 1000);
  }, [loading]);

  return (
    <div
      className={`common-sidebar ${isMenuCollapse ? "minimized onCollapse" : "expanded onExpand"} ${preload ? 'preload' : ''} ${className}`}
      style={style}
      onClick={() => {
        if (loading) return;
        setPreload(false);
      }}
    >
      <div className="sidebar-container">
        <div className="sidebar-header-body min-w-0">
          {headerComponent ? headerComponent : <div></div>}
        </div>
        {!isTablet && (
          <div 
            className={`collapse-btn ${isMenuCollapse ? "reverse" : ""}`}
            onClick={() => {
              handleCollapse();
            }}
          >
            <CollapseArrowIcon width="14px" height="14px" />
          </div>
        )}
      </div>
      <div
        className="d-flex flex-column hide-scrollbar list-view"
      >
        {loading ? (
          Array(7).fill(0).map((_, index) => (
            <ItemLoader key={index} />
          ))
        ) : (list.map((sidebarItem, sidebarItemIndex) => {
          if (sidebarItem?.renderItem) {
            return sidebarItem.renderItem();
          }
          const isActivePage = sidebarItem?.activePage || activePage === sidebarItem?.tabKey;
          const ItemWrapper = sidebarItem?.onClick ? "div" : Link;
          return (
            <OverlayTrigger
              trigger={sidebarItem?.disableState ? ["hover", "focus"] : []}
              key={sidebarItemIndex}
              placement={"right"}
              overlay={
                <Tooltip id={`tooltip-${sidebarItemIndex}`}>
                  {
                    <>
                      <img
                        alt=""
                        className="lockerTooltipImage"
                        src="/images/settings-v2/locker.svg"
                      />
                      You don't have access to this feature
                    </>
                  }
                </Tooltip>
              }
            >
              <ItemWrapper
                to={
                  sidebarItem?.disableState !== true && (sidebarItem.tabURL?.startsWith('/') ? sidebarItem.tabURL : '/' + sidebarItem.tabURL)
                }
                className={`d-flex align-items-center nav_bar_item pointer position-relative ${isActivePage ? "active" : ""} ${sidebarItem?.disableState ? "disabled-main-dashboard-menuItem" : ""} ${sidebarItem?.disableState ? "locked" : ""}`}
                onClick={sidebarItem?.onClick}
              >
                <div className="state-icon">
                  {sidebarItem?.disableState ? (
                    <SmallLockIcon />
                  ) : sidebarItem?.comingSoon ? (
                    <SmallComingSoonIcon className="coming-soon-icon" />
                  ) : null}
                </div>
                <div
                  className={`row-body d-flex align-items-center`}
                >
                  <div className="d-flex align-items-center justify-content-center w-fit-content" style={{ minWidth: '14px' }}>
                    {sidebarItem?.disableState || sidebarItem.tabImage ? (
                      <img
                        src={
                          sidebarItem?.disableState
                            ? "/images/msp/lock-icon.svg"
                            : sidebarItem.tabImage
                        }
                        alt=""
                        className={`nav_bar_item_image`}
                      />
                    ) : sidebarItem?.tabIcon ? (
                      <sidebarItem.tabIcon className="tabIcon" />
                    ) : null}
                  </div>
                  <div className={`f-500 f-14 line-height-18 nav_bar_item_text ${isMenuCollapse ? 'slideOutToBottom-text' : 'slideInFromBottom-text'}`}>
                    {sidebarItem.tabName}
                  </div>
                </div>
                {sidebarItem?.comingSoon && (
                  <div className={`${isMobile ? 'd-none' : 'd-flex'} ${isMenuCollapse ? 'slideOutToBottom-text' : 'slideInFromBottom-text'} d-flex justify-content-end w-100`}>
                    <span className={`coming-soon-tag-msp-menu line-height-18`}>
                      {sidebarItem?.comingSoon}
                    </span>
                  </div>
                )}
              </ItemWrapper>
            </OverlayTrigger>
          );
        }))}
      </div>
      {children}
    </div> 
  )
}

export default Sidebar;

const ItemLoader = () => {
  return (
    <ContentLoader
      width={'100%'}
      height={'38px'}
    >
      <rect x="0" y="0" rx="4px" ry="4px" width="100%" height = "38px" />
    </ContentLoader>
  )
}

import React from "react";
import { IncreaseDecreaseNumbersComponentAlwaysHighlight } from "../../../mspSell/mspPriceCalculator/MspAddEditServiceModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const PriceBreakdownTable = ({
  priceResetting,
  isMspClientView,
  seatBreakDownData,
  handleSave = () => {},
  handleReset = () => {},
  setSeatBreakDownData = () => {},
}) => {
  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between">
        <div className="f-12 f-darkgrey w-100">
          Price Breakdown
        </div>
        <div className="d-flex align-items-center">
          {isMspClientView ? (
            <div></div>
          ) : (
            <div>
              <div className="d-flex align-items-center">
                {(seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited || seatBreakDownData?.MSPPackagePrice?.IsSellEddited) && (
                  <div
                    className="f-blue f-500 mr-2 pointer d-flex"
                    onClick={() => {
                      handleReset(true);
                      // setIsSmthOverriden(true);
                    }}
                  >
                    <img
                      alt=""
                      className={`mr-1 ${/*resetting*/priceResetting && "rotate-full"}`}
                      src="/images/msp/blue-load-icon.svg"
                    />
                    {/*resetting*/priceResetting ? "Resetting.." : "Reset"}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="">
        {/* Req seat table */}
        <div className="border-parent  radius-8">
          <div className="per_asset_period-breakdown_table border-item f-grey">
            <div className="f-12 f-500 f-darkgrey  pl-2">Asset Type</div>
            <div className="f-12 f-500 f-darkgrey">Inc. per Seat</div>
            <div className="f-12 f-500 f-darkgrey">Client Qty</div>
            <div className="f-12 f-500 f-darkgrey">Seats Req</div>
          </div>
          {seatBreakDownData?.MSPPackagePrice?.PackagePerSeat_List?.map(
            (data, dataIndex) => {
              return (
                <div className="per_asset_period-breakdown_table  border-item">
                  <div className="f-500  pl-2">{data.Name} </div>
                  <div className="f-500 f-grey">{data.PerSeat} </div>
                  <div className="f-500 f-grey">{data.ClientQty} </div>
                  <div className="f-600 f-grey">{data.SeatRequired} </div>
                </div>
              );
            }
          )}
          <div className="per_asset_period-breakdown_table  border-item">
            <div className="d-flex align-items-center">
              <div className="f-500  px-2">
                Req. Seats
              </div>

              <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    Required seats is calculated from the asset type which has the highest qty requirement vs qty included per seat
                  </Tooltip>
                }
              >
                <img
                  alt=""
                  src="/images/calculator/info-light.svg"
                />
              </OverlayTrigger>
            </div>
            <div className="f-500 f-grey"></div>
            <div className="f-500 f-grey"></div>
            <div>
              <div style={{
                width: '70%',
                marginBottom: '4px',
                marginLeft: '-14px'
              }}>
                <IncreaseDecreaseNumbersComponentAlwaysHighlight
                  type={"number"}
                  // floatValue={false}
                  value={seatBreakDownData?.MSPPackagePrice?.RequiredSeatsOverriden}
                  isBlue={seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited}
                  isBold={seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited}
                  onChange={(value) => {
                    if (seatBreakDownData.MSPPackagePrice.RequiredSeatsOverriden != Number(value)) {
                      let updated_data = { ...seatBreakDownData };
                      updated_data.MSPPackagePrice.RequiredSeatsOverriden = Number(value);
                      updated_data.MSPPackagePrice.IsRequiredSeatsEdited = true;

                      setSeatBreakDownData(updated_data);
                      return true;
                    }
                  }}
                  onBlur={() => {
                    console.log('save - 1')
                    handleSave();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceBreakdownTable;

import { useMemo } from "react";
import { HGHighlightText } from "../../../App/App";
import NoDataFound from "./no-data.found";
import moment from 'moment';
import SortingHeaderComponent from "../../../SearchFilterComponent/SortingHeaderComponent";
import tableUtils from "../../../../Utils/table.utils";
import useRespondTo from "../../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../../constants/window.constants";

const TableHeaders = [
  { FieldKey: "title", FieldName: "Recurring Items", gridArea: 'col1', gridAreaClassName: 'grid-area-1', gridWidth: '32%' },
  { FieldKey: "price", FieldName: "Price", gridArea: 'col2', gridAreaClassName: 'grid-area-2', gridWidth: '15%' },
  { FieldKey: "qty", FieldName: "Qty", gridArea: 'col3', gridAreaClassName: 'grid-area-3', gridWidth: '11%' },
  { FieldKey: "total", FieldName: "Total", gridArea: 'col4', gridAreaClassName: 'grid-area-4', gridWidth: '18%' },
  { FieldKey: "lastInvoiced", FieldName: "Last Invoiced", gridArea: 'col5', gridAreaClassName: 'grid-area-5', gridWidth: '15%' },
]

const FiltersEnums = {
  LAST_INVOICED: "lastinvoiced-filter",
  PRICE: "price-filter",
  QTY: "quantity-filter",
  TOTAL: "total-filter",
  CATEGORIES: "categories-filter",
  SUBCATEGORIES: "subcategories-filter",
}

const RecurringSpendContent = ({ data, searchValue, filters, sort, setSort, orderData, onOrderUpdate }) => {
  const searchItems = useMemo(() => {
    return data.filter((item) => {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [searchValue, data]);

  const filteredItems = useMemo(() => {
    if (filters?.length === 0) {
      return searchItems;
    }
    let updatedData = [...searchItems];
    filters.forEach((filter) => {
      switch (filter.key) {
        case FiltersEnums.LAST_INVOICED:
          updatedData = updatedData.filter((item) => {
            const lastInvoicedDate = moment(item?.lastInvoiced, 'DD/MM/YYYY').toDate();
            if (!filter?.value?.startDate && !filter?.value?.endDate) return true;
            if (filter?.value?.startDate && !filter?.value?.endDate) {
              return lastInvoicedDate >= filter.value.startDate;
            }
            if (!filter?.value?.startDate && filter?.value?.endDate) {
              return lastInvoicedDate <= filter.value.endDate;
            }
            return lastInvoicedDate >= filter.value.startDate && lastInvoicedDate <= filter.value.endDate;
          });
          break;
        case FiltersEnums.PRICE:
          updatedData = updatedData.filter((item) => {
            const [minValue, maxValue] = filter?.value?.value;
            if (!minValue && !maxValue) return true;
            if (minValue && !maxValue) {
              return item.price >= +minValue;
            }
            if (!minValue && maxValue) {
              return item.price <= maxValue;
            }
            return item.price >= +minValue && item.price <= +maxValue;
          });
          break;
        case FiltersEnums.QTY:
          updatedData = updatedData.filter((item) => {
            const [minValue, maxValue] = filter?.value?.value;
            if (!minValue && !maxValue) return true;
            if (minValue && !maxValue) {
              return item.qty >= +minValue;
            }
            if (!minValue && maxValue) {
              return item.qty <= maxValue;
            }
            return item.qty >= +minValue && item.qty <= +maxValue;
          });
          break;
        case FiltersEnums.TOTAL:
          updatedData = updatedData.filter((item) => {
            const [minValue, maxValue] = filter?.value?.value;
            if (!minValue && !maxValue) return true;
            if (minValue && !maxValue) {
              return item.total >= +minValue;
            }
            if (!minValue && maxValue) {
              return item.total <= maxValue;
            }
            return item.total >= +minValue && item.total <= +maxValue;
          });
          break
        case FiltersEnums.CATEGORIES: {
          const isSomeCategorySelected = filter?.value?.some((category) => category.value);
          if (!isSomeCategorySelected) return;
          updatedData = updatedData.filter((item) => {
            return filter?.value?.find((category) => category.label === item?.categoryName)?.value;
          });
          break;
        }
        case FiltersEnums.SUBCATEGORIES: {
          const isSomeSubCategorySelected = filter?.value?.some((subCategory) => subCategory.value);
          if (!isSomeSubCategorySelected) return;
          updatedData = updatedData.filter((item) => {
            return filter?.value?.find((category) => category.label === item?.subCategoryName)?.value;
          });
          break;
        }
        default:
          break;
      }
    });
    return updatedData;
  }, [searchItems, filters]);

  const sortedData = useMemo(() => {
    if (!sort?.sortItem) return filteredItems;
    const tableKey = TableHeaders.find((header) => header.FieldName === sort?.sortItem)?.FieldKey;
    return [...filteredItems].sort((a, b) => {
      if (sort?.sortOrder === 1) {
        return a[tableKey] > b[tableKey] ? 1 : -1;
      }
      return a[tableKey] < b[tableKey] ? 1 : -1;
    });
  }, [filteredItems, sort]);

  const spreadOrderData = orderData?.map((order) => {
    const headerItem = TableHeaders.find((header) => header?.FieldName === order?.FieldName);
    return {
      ...order,
      gridArea: headerItem?.gridArea,
      gridAreaClassName: headerItem?.gridAreaClassName,
      gridWidth: headerItem?.gridWidth,
    }
  });

  const gridTemplateColumnsStr = useMemo(() => {
    return tableUtils.getGridTemplateColumns(spreadOrderData);
  }, [spreadOrderData]);
  
  const showColumns = useMemo(() => {
    return orderData?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr?.FieldName]: curr,
      }
    }, {})
  }, [orderData]);

  const smWidth = useRespondTo(WindowBreakpoints.sm, 'min');

  return (
    <div className="recurring-spend-content">
      {data?.length === 0 ? (
        <NoDataFound />
      ) : (
        <div className="data-section hide-scrollbar">
          <div 
            className={`table-header ${!smWidth ? 'small-view' : ''}`}
            style={{
              gridTemplateColumns: gridTemplateColumnsStr,
            }}
          >
            {spreadOrderData
            ?.filter((header) => header?.Visibility)
            ?.sort((a, b) => a?.SortOrder - b?.SortOrder)
            ?.map((header) => {
              return (
                <div key={`header-${header.FieldKey}`} className="f-12 line-height-18 f-500 f-darkgrey" style={{ whiteSpace: 'nowrap' }}>
                  <SortingHeaderComponent 
                    headerName={header.FieldName}
                    headerKey={header.FieldName}
                    sortData={sort}
                    onSortClick={() => {
                      const isCurrentColumnSorted = sort?.sortItem === header.FieldName;
                      if (isCurrentColumnSorted) {
                        setSort({
                          ...sort,
                          sortOrder: sort?.sortOrder === 1 ? 0 : 1,
                        });
                      } else {
                        setSort({
                          sortItem: header.FieldName,
                          sortOrder: 1,
                          sorting: false
                        });
                      }
                    }}
                    updatedHeaderList={() => {
                      onOrderUpdate(orderData?.map((order) => {
                        if (order?.FieldName === header.FieldName) {
                          return {
                            ...order,
                            Visibility: false,
                          }
                        }
                        return order;
                      }));
                    }}
                  />
                </div>
              )
            })}
          </div>
          <div className="table-items">
            {sortedData?.map((recurringSpendItem) => (
              <RecurringSpendItem 
                key={`recurringSpendItem-${recurringSpendItem?.title}`} 
                recurringSpendItem={recurringSpendItem} 
                searchValue={searchValue}
                showColumns={showColumns}
                gridTemplateColumnsStr={gridTemplateColumnsStr}
                smWidth={smWidth}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const RecurringSpendItem = ({ recurringSpendItem, searchValue, showColumns, gridTemplateColumnsStr, smWidth }) => {
  const columns = [
    {
      header: showColumns[TableHeaders[0].FieldName],
      Component: (
        <div>
          <h3 className="m-0 f-14 line-height-22 f-black" style={{ fontWeight: 400 }}>
            <span 
              dangerouslySetInnerHTML={{ __html: HGHighlightText(recurringSpendItem.title, searchValue) }}
            />
          </h3>
          <p className="m-0 f-12 line-height-18 f-darkgrey">
            {recurringSpendItem.subTitle}
          </p>
        </div>
      )
    },
    {
      header: showColumns[TableHeaders[1].FieldName],
      Component: (
        <div className="f-14 line-height-22 f-black">
          {recurringSpendItem.priceText}
        </div>
      )
    },
    {
      header: showColumns[TableHeaders[2].FieldName],
      Component: (
        <div className="f-14 line-height-22 f-black">
          {recurringSpendItem.qty}
        </div>
      )
    },
    {
      header: showColumns[TableHeaders[3].FieldName],
      Component: (
        <div className="f-14 line-height-22 f-black">
          {recurringSpendItem.totalText}
        </div>
      )
    },
    {
      header: showColumns[TableHeaders[4].FieldName],
      Component: (
        <div className="f-14 line-height-22 f-black">
          {recurringSpendItem.lastInvoicedText}
        </div>
      )
    }
  ];
  return (
    <div 
      className={`table-item ${!smWidth ? 'small-view' : ''}`}
      style={{
        gridTemplateColumns: gridTemplateColumnsStr
      }}
    >
      {
        columns
          ?.filter((column) => column?.header?.Visibility)
          ?.sort((a, b) => a?.header?.SortOrder - b?.header?.SortOrder)
          ?.map((column, index) => (
            column?.Component
          ))
      }
    </div>
  )
}

export default RecurringSpendContent;

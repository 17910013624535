import React, { useEffect, useState, Fragment } from "react";
import { Redirect } from "react-router-dom";
import Notifications from "../SystemSettings/Notifications";
import PersonalProfile from "./PersonalProfile";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import { Row, Col, Tab, Tabs, Table } from "react-bootstrap";
import Notification from "./../Notification/Notification";
import queryString from "query-string";
import { useAuth } from "../context/Auth";
import { getAPIData, postData } from "../../Utils/commonApi";
import { ToastContainer, toast } from "react-toastify";
import CircularProgressBar from "../CircularProgressBar";
import { FiCheck, FiX } from "react-icons/fi";
import Header from "../Header";
import "../../main.scss";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { APP_ROUTES, ROUTE_PATHS } from "../../constants/app.constants";

const PersonalSettings = (props) => {
  const { authTokens } = useAuth();
  const params = queryString.parse(props.location.search);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [getPersonalDevices, setPersonalDevices] = useState([]);
  const [deviceId, setDeviceId] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [isResponse, setIsResponse] = useState(false);

  useEffect(() => {
    if (isResponse) {
      getdeviceeData();
    }
  }, [isResponse]);

  async function getdeviceeData() {
    const response = await getAPIData(102, authTokens);
    setPersonalDevices(response);
  }
  async function handleRemove(enum1) {
    setShowToast(true);
    await getAPIData(105, authTokens, {
      optionEnum1: enum1,
    })
      .then((result) => {
        if (result.Success == true) {
          TrackUser("Device deleted");
          getdeviceeData();
          toast.dark(
            <span>
              <FiCheck style={{ marginRight: "10px" }} />
              Personal Setting updated successfully
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        } else {
          toast.dark(
            <span>
              <FiX style={{ marginRight: "10px", color: "#f36060" }} />
              <span style={{ width: "500px", color: "#f36060" }}>
                Unable to update
              </span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        }
      })
      .catch((err) => {
        toast.dark(
          <span>
            <FiX style={{ marginRight: "10px", color: "#f36060" }} />
            <span style={{ width: "500px", color: "#f36060" }}>
              Unable to update
            </span>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      });
  }

  const handleNotifModal = (deviceID) => {
    setDeviceId(deviceID);
    setShowNotificationModal(false);
    setInterval(() => {
      setShowNotificationModal(true);
    }, 100);
  };

  const referralData = () => {
    return (
      <div className="container-grey p-0">
        <div>
          <div className="mt24px ml24px mr24px mb40px">
            <img src="/images/icons/bell.svg" className="float-left" />
            <span className="textBlack" style={{ marginLeft: "14px" }}>
              Send Notification
            </span>
          </div>
          <div className="ml40px mr40px">
            <Notification
              index={0}
              device={deviceId}
              role={1}
              type="scoretab"
              company="test"
              value={getPersonalDevices.CyberScore.CyberScoreNumber}
              time="3s ago"
              heading="CyberScore"
              text={`Your CyberScore has decreased to   ${getPersonalDevices.CyberScore.CyberScoreNumber}%`}
              color="#007BD4"
            />
            <Notification
              index={1}
              device={deviceId}
              role={1}
              type="threattab"
              company="test"
              value={getPersonalDevices.CyberScore.CyberThreatNumber}
              time="3s ago"
              heading="CyberThreat Level"
              text={`Your CyberThreat Level has increased to   ${getPersonalDevices.CyberScore.CyberThreatNumber}%`}
              color="#007BD4"
            />
            <Notification
              index={2}
              device={deviceId}
              role={1}
              type="compliancetab"
              company="test"
              value={getPersonalDevices.CyberScore.CyberComplianceNumber}
              time="3s ago"
              heading="CyberCompliance"
              text={`Your CyberCompliance has decreased to   ${getPersonalDevices.CyberScore.CyberComplianceNumber}%`}
              color="#007BD4"
            />
          </div>
        </div>
      </div>
    );
  };

  const handleLogout = (active_tab) => {
    if (active_tab == "logout") {
      let element = document.getElementById("root").firstChild;
      element.classList.add("fadeOut");
      setTimeout(() => {
        localStorage.clear();
        window.location.href = ROUTE_PATHS.auth.login;
        return <Redirect to={APP_ROUTES.auth.login} />;
      }, 500);
    }
    setIsResponse(true);
  };

  return (
    <Fragment>
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight">
            <header
              className="d-flex justify-content-between navbar sticky-top pb-0"
              style={{ paddingTop: "1em", background: "#FFFFFF" }}
            >
              <h1>Personal Profile</h1>
              <Header />
            </header>
            <Tabs
              className="integration_tabs"
              defaultActiveKey={params.tab ? params.tab : "personal-info"}
              transition={false}
              style={{ paddingTop: "5%", borderBottom: "1px solid #EBECF1" }}
              onSelect={(k) => handleLogout(k)}
            >
              <Tab eventKey="personal-info" title="Personal Info">
                <div className="mt40px">
                  <PersonalProfile />
                </div>
              </Tab>
              <Tab eventKey="notifications" title="Notifications">
                <div className="mt40px">
                  <Notifications pageEnum="103" isResponse={isResponse} />
                </div>
              </Tab>
              <Tab eventKey="devices" title="Devices">
                <div className="mt40px">
                  <div className="settings_container mt16px">
                    <div className="heading3">Devices</div>
                    <Table
                      className="settings_table cybercare-table2"
                      striped
                      borderless
                    >
                      <thead>
                        <tr>
                          <th className="border-bottom">
                            <span className="linkGreyText">Name</span>
                          </th>
                          <th className="border-bottom">
                            <span className="linkGreyText">Date Added</span>
                          </th>
                          <th className="border-bottom">
                            <span className="linkGreyText"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getPersonalDevices.UserDevices &&
                          getPersonalDevices.UserDevices.map(
                            (UserDevice, index) => (
                              <tr key={index}>
                                <td>
                                  <img
                                    src="/images/icons/phoneIcon.svg"
                                    style={{ marginRight: "10px" }}
                                  />
                                  <span className="txt-small">
                                    {UserDevice.Detail}
                                  </span>
                                </td>
                                <td>
                                  <span className="txt-small">
                                    {UserDevice.DateAdded}
                                  </span>
                                </td>
                                <td
                                  className="float-right"
                                  style={{ paddingTop: "22px" }}
                                >
                                  {UserDevice.DeviceId == 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      <span
                                        className="light-blue-btn"
                                        onClick={() =>
                                          handleNotifModal(UserDevice.DeviceId)
                                        }
                                      >
                                        Send Notification
                                        <img
                                          src="/images/icons/sendIcon.svg"
                                          style={{ marginLeft: "10px" }}
                                        />
                                      </span>
                                      <span
                                        className="redText ml56px cursor-pointer"
                                        onClick={() =>
                                          handleRemove(UserDevice.DeviceId)
                                        }
                                      >
                                        Remove
                                      </span>
                                    </>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>
              <Tab
                title="Log out"
                tabClassName="logout-tab"
                eventKey="logout"
              ></Tab>
            </Tabs>
          </div>
          {showNotificationModal && (
            <ModalDynamic
              setShow={true}
              modalData={referralData}
              modalHeader={"Test Notifications"}
            />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default PersonalSettings;

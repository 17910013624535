import { Button, Spinner } from "react-bootstrap";
import "./sections.styles.scss";
import { useState } from "react";
import AuthChangeInput from "../auth-change-input.component";
import AvatarIcon from "../../icons/avatar.icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AuthTextInput from "../auth-text-input.component";
import KeyIcon from "../../icons/key.icon";
import PasswordStrengthInput from "../password-strength.input";
import { ROUTE_PATHS } from "../../../constants/app.constants";

const ResetPasswordSection = ({ 
  loading,
  defaultEmail,
  error,
  passwordError,
  confirmPasswordError,
  setPasswordError,
  setConfirmPasswordError,
  setError,
  handleResetPassword
}) => {
  const [shownConfirmPassword, setShownConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();

  return (
    <div className="forgot-password-section d-flex flex-column" style={{ gap: '16px' }}>
      <AuthChangeInput
        value={defaultEmail}
        onChange={() => {
          history.push(`${ROUTE_PATHS.auth.login}?email=${encodeURIComponent(defaultEmail)}`);
        }}
        icon={(
          <AvatarIcon />
        )}
        className="rounded"
        valueStyles={{ marginLeft: '4px' }}
      />
      <div className="d-flex flex-column" style={{ gap: '8px' }}>
        <PasswordStrengthInput 
          error={error}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          password={password}
          setPassword={setPassword}
          setError={setError}
          updatePasswordStrength={setPasswordStrength}
        />
        <AuthTextInput
          id="password" 
          error={confirmPasswordError}
          type={shownConfirmPassword ? "text" : "password"}
          placeholder="Confirm new password"
          onChange={(event) => {
            setConfirmPassword(
              event.target.value
            );
            setConfirmPasswordError("");
          }}
          value={confirmPassword}
          icon={(
            <KeyIcon />
          )}
          endIcon={(
            <div 
              className="ml-2 cursor-pointer"
              onClick={() => {
                setShownConfirmPassword(prev => !prev);
              }}
            >
              <img
                src={shownConfirmPassword ? "/images/attack-surface/shown-icon.svg" : "/images/attack-surface/hidden-icon.svg"}
                className="ml-2"
              />
            </div>
          )}
        />
      </div>
      <Button
        size="lg"
        variant={"blue"}
        className={`w-100 f-14 continue-btn`}
        type="submit"
        style={{ padding: "5px" }}
        onClick={() => {
          if (loading) return;
          handleResetPassword(password, confirmPassword, passwordStrength);
        }}
      >
        {loading ? (
          <span>
            <Spinner
              size="sm"
              className="mr8px"
              animation="border"
            />{" "}
            Sending...
          </span>
        ) : (
          "Change Password"
        )}
      </Button>
    </div>
  );
}

export default ResetPasswordSection;

import React, { useState, useRef, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ReactQuill from 'react-quill';
import ReactToPrint from "react-to-print";
import 'react-quill/dist/quill.snow.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './export-package-left.styles.scss';
import SettingsOption from "../../../exportClientReport/settings.option";
import SwitchButtonMulti from "../../../../Theme/switchButtonMulti";

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'header': '1' }, { 'header': '2' },],

    [{ 'list': 'ordered' },
    ],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
}

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

const ExportPackageLeftSection = ({
  showPriceIncludingTax,
  setShowPriceIncludingTax,
  showFinancialBreakdown,
  setShowFinancialBreakdown,
  showSecurityCapabilities,
  setShowSecurityCapabilities,
  showSecurityCapabilitiesBy,
  showSecurityCapabilitiesBySelected,
  setShowSecurityCapabilitiesBySelected,
  setShowSecurityPosture,
  showSecurityPosture,
  securityPostureData,
  setSecurityPostureData,
  showIncludedFeatures,
  setShowIncludedFeatures,
  showServiceComponents,
  setShowServiceComponents,
  showNistCsfAlignment,
  setShowNistCsfAlignment,
  showCoverNote,
  setShowCoverNote,
  coverNoteMessage,
  setCoverNoteMessage,
  hideModal,
  setShowContentOnPrint,
  clientReportref,
  setSorting,
  setIsDragChanged,
  setIsClose,
  setIsCoverNoteMoved,
  setIsSecurityPostureMoved
}) => {
  const [orders, setOrders] = useState({
    'cover_note': 0,
    'price_including_tax': 1,
    'security_posture': 2,
    'security_capabilities': 3,
    'financial_breakdown': 4,
    'included_features': 5,
    'service_components': 6,
    'nist_csf_alignment': 7,
  });
  const [draggingId, setDraggingId] = useState(null);
  const handleDragEnd = (result) => {

    if (draggingId == 'cover_note') {
      setIsCoverNoteMoved(true);
    }
    if (draggingId == 'security_posture') {
      setIsSecurityPostureMoved(true);
    }
    setDraggingId(null);
    if (!result.destination) return;
    const newOrders = [...Object.keys(orders)];
    newOrders.splice(result.source.index, 1);
    newOrders.splice(result.destination.index, 0, result.draggableId);
    const newOrdersObj = {};
    newOrders.forEach((key, index) => {
      newOrdersObj[key] = index;
    });

    setOrders(newOrdersObj);
    console.log('newOrdersObj', newOrdersObj)
    const { price_including_tax, ...newNewOrdersObj } = newOrdersObj;
    if (newNewOrdersObj['cover_note'] == 0) {
      setIsCoverNoteMoved(false);
    }
    if (newNewOrdersObj['security_posture'] == 1 || newNewOrdersObj['security_posture'] == 2) {
      setIsSecurityPostureMoved(false);
    }
    console.log('newNewOrdersObj', newNewOrdersObj)
    setSorting(newNewOrdersObj);
  };

  const sections = [
    {
      sectionId: 'cover_note',
      Component: (
        <SettingsOption
          title="Cover note"
          switchId="cover_note"
          value={showCoverNote}
          onChange={() => setShowCoverNote(!showCoverNote)}
        >
          {showCoverNote && (
            <div className="cliet-report-cover-note covernote mt-3">
              <ReactQuill
                theme="snow"
                onChange={(e) => {
                  setCoverNoteMessage(e);
                }}
                value={coverNoteMessage}
                modules={modules}
                formats={formats}
                bounds={".app"}
                placeholder={"Write Something..."}
              />
            </div>
          )}
        </SettingsOption>
      ),
    },
    {
      sectionId: 'price_including_tax',
      Component: (
        <SettingsOption
          title="Price Including Tax"
          switchId="showPriceIncludingTax"
          value={showPriceIncludingTax}
          onChange={() => setShowPriceIncludingTax(!showPriceIncludingTax)}
        />
      ),
    },
    {
      sectionId: 'security_posture',
      Component: (
        <SettingsOption
          title="Security Posture"
          switchId="showSecurityPosture"
          value={showSecurityPosture}
          onChange={() => setShowSecurityPosture(!showSecurityPosture)}
        >
          {showSecurityPosture && (
            <div>
              {
                securityPostureData?.map((securityPostureItem) => {
                  return (
                    <div
                      className="ml-3 item"
                    >
                      <div className="d-flex align-items-center justify-content-between p-1">
                        <div className="f-12 f-500 greyText2 line-height-18">{securityPostureItem?.title}</div>
                        <div className="switch">
                          {" "}
                          <SwitchButtonMulti
                            value={securityPostureItem?.selected}
                            id={securityPostureItem?.title}
                            onChange={() => {
                              setSecurityPostureData(prevData =>
                                prevData.map(item =>
                                  item.id === securityPostureItem?.id ? { ...item, selected: !item.selected } : item
                                )
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )}
        </SettingsOption>
      ),
    },
    {
      sectionId: 'security_capabilities',
      Component: (
        <SettingsOption
          title="Security Capabilities"
          switchId="showSecurityCapabilities"
          value={showSecurityCapabilities}
          onChange={() => setShowSecurityCapabilities(!showSecurityCapabilities)}
        >
          {showSecurityCapabilities && (
            <div className="d-flex align-items-center justify-content-between p-1 ml-3">
              <div className="f-500 f-12 line-height-18 greyText2">
                Show by
              </div>
              <Dropdown className="security-capabilities-dropdown">
                <Dropdown.Toggle>
                  <div className="d-flex align-items-center py-1 px-2">
                    <div className="f-500 f-12 greyText2">
                      {showSecurityCapabilitiesBySelected?.label}
                    </div>
                    <img
                      width={8}
                      alt=""
                      className="ml-2"
                      src="/images/chevron-down.svg"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div>
                    {
                      showSecurityCapabilitiesBy?.map((showByItem, index) => {
                        return (
                          <div
                            className={`f-400 f-12 px-2 py-1 ${showByItem?.id == showSecurityCapabilitiesBySelected?.id ? 'txt-blue' : 'greyText2 bg-hover-grey pointer'}`}
                            onClick={(e) => {
                              if (showByItem?.id != showSecurityCapabilitiesBySelected?.id) {
                                setShowSecurityCapabilitiesBySelected(showByItem);
                                // setSelectedShowByData(showByItem?.id == 0 ? packageData?.SecurityCapabiltiesByNist_List : packageData?.SecurityCapabiltiesBySecurityPillar_List)
                                e.target
                                  .closest(".security-capabilities-dropdown")
                                  .click();
                              }
                            }}
                          >
                            {showByItem?.label}
                          </div>
                        )
                      })
                    }
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </SettingsOption>
      ),
    },
    {
      sectionId: 'financial_breakdown',
      Component: (
        <SettingsOption
          title="Financial Breakdown"
          switchId="showFinancialBreakdown"
          value={showFinancialBreakdown}
          onChange={() => setShowFinancialBreakdown(!showFinancialBreakdown)}
        />
      ),
    },
    {
      sectionId: 'included_features',
      Component: (
        <SettingsOption
          title="Included Features"
          switchId="showIncludedFeatures"
          value={showIncludedFeatures}
          onChange={() => setShowIncludedFeatures(!showIncludedFeatures)}
        />
      ),
    },
    {
      sectionId: 'service_components',
      Component: (
        <SettingsOption
          title="Security Component"
          switchId="showServiceComponents"
          value={showServiceComponents}
          onChange={() => setShowServiceComponents(!showServiceComponents)}
        />
      ),
    },
    {
      sectionId: 'nist_csf_alignment',
      Component: (
        <SettingsOption
          title="NIST CSF 2.0 Alignment"
          switchId="showNistCsfAlignment"
          value={showNistCsfAlignment}
          onChange={() => setShowNistCsfAlignment(!showNistCsfAlignment)}
        />
      ),
    }
  ];

  console.log('sections - ', sections)
  console.log('sort', sections?.sort((a, b) => orders[a.sectionId] - orders[b.sectionId]))
  return (
    <div className="w-30 border-right h-100 d-flex flex-column export-client-left-section">
      <div className="d-flex align-items-center justify-content-between border-bottom header">
        <h5 className="modal-title">Print Security Package</h5>
      </div>
      <div className="flex-1 transparent-scroller overflow-x-hidden">
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={(start) => {
            setDraggingId(start.draggableId);
          }}
        >
          <Droppable
            direction="vertical"
            droppableId="droppableSection" 
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div>
                  {sections
                    .sort((a, b) => orders[a.sectionId] - orders[b.sectionId])
                    .map((section, index) => (
                      <Draggable
                        key={section.sectionId}
                        draggableId={section.sectionId}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="draggable-container"
                            >
                            {/* {provided.placeholder} */}
                            <div className={`grapper ${draggingId === section.sectionId ? 'is-dragging' : ''}`}>
                              <img
                                alt=""
                                className="mr-3"
                                src="/images/supply-chain/drag-n-drop.svg"
                              />
                            </div>
                            {section.Component}
                          </div>
                        )}
                      </Draggable>
                    )
                    )}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {/* Bottom Section */}
      <div className="p-3 d-flex align-items-center justify-content-end position-sticky bottom-0 border-top bg-white">
        <Button
          className="hg-cancel-btn mr-2"
          onClick={() => {
            hideModal();
          }}
        >
          Cancel
        </Button>
        <ReactToPrint
          // bodyClass="py-5"
          onAfterPrint={() => {
            setShowContentOnPrint(false);
          }}
          onBeforeGetContent={() => {
            setShowContentOnPrint(true);
          }}
          trigger={() => {
            return <Button className="hg-blue-btn">Export</Button>;
          }}
          content={() => clientReportref.current}
        />
      </div>
    </div>
  );
}

export default ExportPackageLeftSection;
const AccreditationsSection = ({ auditDetails }) => (
  <div className="w-100 d-flex flex-column">
    <div>
      <div className="f-14 f-600"
        style={{
          marginBottom: '8px',
        }}
      >
        Accreditations
      </div>
    </div>
    {
    auditDetails?.AccreditationVM?.items?.length !== 0 ?
      <div style={{ backgroundColor: '#F6F8FB', borderRadius: '8px' }}>
        {
          auditDetails?.AccreditationVM?.items?.map((accreditationItem, index) => {
            return (
              <div
                className={`border-item d-flex align-items-center justify-content-between p-3`}
                style={{
                  borderBottom: `${index < auditDetails?.AccreditationVM?.items?.length - 1 ? "1px solid #EBEDF1" : 'none'}`
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    width={33}
                    src={`/images/msp/${accreditationItem?.icon}`}
                    alt=""
                    className="mr-3 rounded-full"
                  />
                  <div>
                    <div>
                      <span className="f-500 f-black">
                        {accreditationItem?.name}
                      </span>
                      &nbsp; &nbsp;
                      {accreditationItem?.status && (
                        <span
                          style={{
                            color: accreditationItem?.status?.foreColor,
                          }}
                        >
                          {accreditationItem?.status?.text}
                        </span>
                      )}
                    </div>
                    <div className="f-darkgrey">
                      {accreditationItem?.points?.map((item, idx) => (
                        <span>
                          {idx > 0 ? " • " : ""} {item}
                        </span>
                      ))}{" "}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    {accreditationItem?.linkUrl && (
                      <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                        <img
                          src={"/images/msp/msp-cert-link-icon.svg"}
                          alt=""
                          className="mr-2  "
                        />
                        Link
                      </div>
                    )}
                    {accreditationItem?.fileUrl && (
                      <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                        <img
                          src={"/images/msp/msp-cert-file-icon.svg"}
                          alt=""
                          className="mr-2  "
                        />
                        File
                      </div>
                    )}
                  </div>
                  <div
                    className={`ml-2 px-2 rounded-full d-flex align-items-center f-12 f-500 pointer `}
                    style={{
                      height: '24px',
                      color: `${accreditationItem?.UpdatedStatus == 'New' ? '#008FD4' : '#616778'}`,
                      backgroundColor: `${accreditationItem?.UpdatedStatus == 'New' ? '#D6EDF8' : '#EBEDF1'}`,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {accreditationItem?.UpdatedStatus}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div> : <div className="d-flex justify-content-center align-items-lg-center py-4 rounded-sm"
        style={{
          backgroundColor: '#F6F8FB',
        }}
      >
        Accreditations have not been changed
      </div>
    }
  </div>
);

export default AccreditationsSection;

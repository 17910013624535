const TermsInfo = () => (
  <div className="terms-info">
    <div className="f-darkgrey">
      {"By creating an account, you are confirming you have read,\n understood and agree to our "}
      <u
        className="pointer"
        onClick={() => {
          window.open(
            "https://www.highground.io/privacy_policy/",
            "_blank"
          );
        }}
      >
        Privacy Policy
      </u>
      {" and "} 
      <u
        className="pointer"
        onClick={() => {
          window.open(
            "https://www.highground.io/highground-terms-and-conditions/",
            "_blank"
          );
        }}
      >
        Terms & Conditions
      </u>.
    </div>
  </div>
);

export default TermsInfo;

import { Spinner } from 'react-bootstrap';
import ConnectedToolItem from '../../../MSPv2/securityStack/connected-tool-item.component';
import './technologies-practices.styles.scss';
import { useMemo, useState } from 'react';
import { AttestationStatus } from '../../../../constants/integrations.constants';
import ContentLoader from 'react-content-loader';

const ListItem = ({ spendItem, deep = 0 }) => {
  const [showHiddenBlock, setShowHiddenBlock] = useState(true);
  return (

    <li className={`list-item ${deep > 0 ? 'child' : ''}`}>
      <div className={`item-row d-flex justify-content-between ${spendItem?.detailsList?.length > 0 && 'pointer'}`} style={{ gap: '16px' }}
        onClick={() => {
          if (spendItem?.detailsList?.length > 0) {
            setShowHiddenBlock(!showHiddenBlock)
          }
        }}
      >
        <div className="d-flex  f-500 f-grey">
          {
            spendItem?.icon &&
            <img
              width="13px"
              height="13px"
              src={spendItem?.icon}
              className='mr-2'
              style={{ marginTop: '4px' }}
              alt=''
            />
          }
          <span className={`text spend-item-name f-grey`}>
            {spendItem?.name}
          </span>
        </div>
        <div className={`${'text'} f-black d-flex`}>
          {spendItem?.detailsList?.length > 0 ? (
            <div className='d-flex align-content-center justify-content-between'>
              {
                spendItem?.numberOfIssues && <div className='incomplete-missing-txt orangeText px-2 py-1 radius-8 mr-2' style={{ background: '#FFF2DD' }}>
                  {spendItem?.numberOfIssues} Issues
                </div>
              }
              <img
                alt=""
                className={showHiddenBlock ? 'rotate180' : ''}
                width={12}
                src="/images/big-chevron-down.svg"
              />
            </div>
          ) : spendItem?.IntegrationList?.length > 0 ? (
            <div className='d-flex' style={{ flexWrap: 'wrap', gap: '4px', justifyContent: 'flex-end' }}>
              {spendItem?.IntegrationList.map((integration, index) => (
                <ConnectedToolItem
                  key={`connected-tool-item-${integration?.name}-${index}`}
                  tool={{
                    obj_IntegrationId: integration?.IntegrationId,
                    ToolName: integration?.Name,
                    ToolIcon: integration?.Logo,
                  }}
                  passedStyle={'w-100'}
                />
              ))}
            </div>
          ) : spendItem?.noCapability ? (
            <span className={`f-400 f-12 line-height-18 f-black align-self-center`}>No capability</span>
          ) : spendItem?.answerText ? (
            <span className={`f-400 f-12 line-height-18 f-black align-self-center`}>{spendItem?.answerText}</span>
          ) : spendItem?.isAnswerError ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey align-self-center`}>Not answered</span>
          ) : spendItem?.isMissing ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey align-self-center`}>Missing</span>
          ) : spendItem?.isIssue ? (
            <span className={`${'incomplete-missing-txt'} align-self-center`} style={{ color: '#BB7F24' }}>Issues</span>
          ) : spendItem?.isinComplete ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey align-self-center`}>Incomplete</span>
          ) : null}
        </div>
      </div>
      {
        showHiddenBlock && <div className='ml-3'>
          {spendItem?.detailsList?.map(
            (dataItem, dataItemIndex) => {
              return (
                <ListItem
                  key={dataItemIndex}
                  spendItem={{
                    ...dataItem,
                    name: dataItem?.TechnologyName,
                  }}
                  deep={deep + 1}
                />
              );
            }
          )}
        </div>
      }
    </li>
  )
}

const TechnologiesPractices = ({ technologiesandPractices, isLoading }) => {
  const mapItem = (item) => ({
    ...item,
    icon: item?.StatusIcon,
    noCapability: AttestationStatus.NoCapability === item?.StatusText,
    isAnswerError: AttestationStatus.NotAnswered === item?.StatusText,
    isinComplete: AttestationStatus.IsIncomplete === item?.StatusText,
    isIssue: AttestationStatus.Issues === item?.StatusText,
  })

  const mappedTechnologiesData = useMemo(() => {
    if (!technologiesandPractices) return [];
    return technologiesandPractices?.Technologies?.reduce((acc, item) => {
      const hasChilds = item.Grouping && item.IsChild === false;
      const isChildOf = item.Grouping && item.IsChild === true;
      if (isChildOf) return acc;
      const childs = hasChilds 
        ? technologiesandPractices?.Technologies.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === item.Grouping) 
        : [];
      const newItem = {
        ...mapItem(item),
        detailsList: childs?.map((child) => mapItem(child)),
      }
      acc.push(newItem);
      return acc;
    }, []);
  }, [technologiesandPractices]);

  const mappedPracticesData = useMemo(() => {
    if (!technologiesandPractices) return [];
    const data = technologiesandPractices?.Practices?.reduce((acc, item) => {
      const hasChilds = item.Grouping && item.IsChild === false;
      const isChildOf = item.Grouping && item.IsChild === true;
      if (isChildOf) return acc;
      const childs = hasChilds 
        ? technologiesandPractices?.Practices.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === item.Grouping) 
        : [];
      const newItem = {
        ...mapItem(item),
        detailsList: childs?.map((child) => mapItem(child)),
      }
      acc.push(newItem);
      return acc;
    }, []);
    data.push({
      TechnologyName: "Governance & Resilience",
      StatusIcon: "",
      StatusValue: "",
      detailsList: technologiesandPractices?.GovAndRes?.map((item) => {
        const hasChilds = item.Grouping && item.IsChild === false;
        const isChildOf = item.Grouping && item.IsChild === true;
        if (isChildOf) return null;
        const childs = hasChilds 
          ? technologiesandPractices?.GovAndRes.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === item.Grouping) 
          : [];
        return {
          ...mapItem(item),
          detailsList: childs?.map((child) => mapItem(child)),
        }
      }).filter((item) => item),
    })
    return data;
  }, [technologiesandPractices]);

  return (
    <div className={'technologies-ptractices'}>
      <div className="highground-royal-font f-700 f-16 d-flex align-items-center justify-content-between">
        <div className='d-flex align-items-center' style={{ gap: '8px' }}>
          <span>
            Technologies and Practices
          </span>
          {isLoading && technologiesandPractices ? (
            <Spinner
              animation="border"
              variant="secondary"
              size="sm"
              className="mx-2"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
          ) : null}
        </div>
      </div>
      {isLoading && !technologiesandPractices ? (
        <TechologiesPracticesLoader />
      ) : (
        <div className={'data-container'}>
          <div className={'section'}>
            <h3 className={`${'section-header'} m-0 f-grey ml-1 hg-border-bottom`}>
              Technologies
            </h3>
            <ul className={'list'}>
              {mappedTechnologiesData?.map(
                (spendItem, spendItemIndex) => {
                  return (
                    <ListItem
                      key={spendItemIndex}
                      spendItem={{
                        ...spendItem,
                        name: spendItem?.TechnologyName,
                      }}
                    />
                  );
                }
              )}
            </ul>
          </div>
          <div className={'section'}>
            <h3 className={`${'section-header'} m-0 f-grey ml-1`}>
              Practices
            </h3>
            <ul className={'list'}>
              {mappedPracticesData?.map(
                (spendItem, spendItemIndex) => {
                  return (
                    <ListItem
                      key={spendItemIndex}
                      spendItem={{
                        ...spendItem,
                        name: spendItem?.TechnologyName,
                      }}
                    />
                  );
                }
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default TechnologiesPractices;

const TechologiesPracticesLoader = () => {
  return (
    <ContentLoader
      width={'100%'}
      height={'400px'}
      style={{ marginTop: '12px' }}
    >
      <rect x="0" y="0" rx="8px" ry="8px" width="100%" height = "100%" />
    </ContentLoader>
  )
}

import { useEffect, useRef } from "react";
import { APP_ROUTES } from "../../constants/app.constants";
import { accessAllowed, NoAccessUI } from "../App/App";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ActionCentreV2 from "../settingsV2/action-center-notification/Index";
import ActiveSessions from "../settingsV2/activeSessions/ActiveSessions";
import BillingV2 from "../settingsV2/billing/Index";
import CSuite from "../settingsV2/c-suite/Index";
import CompanySettings from "../settingsV2/companySettings/CompanySettings";
import MembersAccess from "../settingsV2/membersAccess/MembersAccess";
import SaasMemberDetails from "../settingsV2/membersAccess/SaasMemberDetails";
import NotificationsV2 from "../settingsV2/notifications/Index";
import PersonalProfileV2 from "../settingsV2/personal-profile/Index";
import ResetSettings from "../settingsV2/resetSettings/ResetSettings";
import RewardsV2 from "../settingsV2/rewards/Index";
import SettingsSideNav from "../settingsV2/SettingsSideNav";
import SettingsV2Header from "../settingsV2/SettingsV2Header";
import SaasSubscriptionV2 from "../settingsV2/subscriptions/SaasSubscriptionV2";

const ClientSettingsLayoutRoutes = ({ clientDetail }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    wrapperRef.current.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <div className="settings-v2-header-wrapper hide-scrollbar">
      <div className="border-bottom-v2-header w-100">
        <SettingsV2Header />
      </div>
      <div className="d-flex body-wrapper">
        <SettingsSideNav />
        <div ref={wrapperRef} className="main-wrapper hide-scrollbar">
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.myProfile}
            component={PersonalProfileV2}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.notifications}
            component={NotificationsV2}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.cSuite}
            component={CSuite}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.activeSessions}
            component={ActiveSessions}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.companySettings}
            component={
              accessAllowed("AccessState_SystemSettings", clientDetail)
                ? CompanySettings
                : NoAccessUI
            }
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.memberaccess}
            component={
              accessAllowed("AccessState_SystemSettings", clientDetail)
                ? MembersAccess
                : NoAccessUI
            }
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.memberaccessMember}
            component={
              accessAllowed("AccessState_SystemSettings", clientDetail)
                ? SaasMemberDetails
                : NoAccessUI
            }
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.rewards}
            component={
              accessAllowed("AccessState_Reward_Settings", clientDetail)
                ? RewardsV2
                : NoAccessUI
            }
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.subscriptions}
            component={
              accessAllowed("AccessState_SystemSettings", clientDetail)
                ? SaasSubscriptionV2
                : NoAccessUI
            }
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.billing}
            component={
              accessAllowed("AccessState_SystemSettings", clientDetail)
                ? BillingV2
                : NoAccessUI
            }
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.actionCentre}
            render={() => {
              return accessAllowed(
                "AccessState_ActionCentre_Settings",
                clientDetail
              ) ? (
                <ActionCentreV2
                  accessStateName={"AccessState_ActionCentre"}
                />
              ) : (
                <NoAccessUI />
              );
            }}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.client.settings.resetSettings}
            component={ResetSettings}
          />
        </div>
      </div>
    </div>
  )
}

export default ClientSettingsLayoutRoutes;

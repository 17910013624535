import React, { useEffect, useMemo, useState } from "react";
import {
  getDateFormatForMoment,
} from "./createClientComplianceDropdowns/CyberEssentialAuditDropdown";
import { handleDateForInvoice } from "../../MspAttackSurface.js/technologySpendModal/TechnologySpendModal";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { AttestationPopUp } from "../../../AttackSurface/KPIpopup/KPIpopup";
import {
  Attestation_Icon_Text_Mappings,
} from "../../../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import CyberComplianceDateDropdown from "./createClientComplianceDropdowns/CyberComplianceDateDropdown";
import "../../../AttackSurface/drilldownModal.scss";
import CustomInputField from "../../../Theme/CustomInputField";
import { HGHighlightText, viewOnlyAccess } from "../../../App/App";
import SearchTitle from '../../../Common/search-title.component';
import { CustomHGDropdown } from "./CyberComplianceForGroupModal";
import SelectArrowIcon from "../../../icons/select-arrow.icon";
import SearchInput from "../../../Common/search-input/search-input.component";
import ToolsSection from "../../../Common/tools-section.component";
import useDropdown from "../../../../hooks/dropdown.hook";
import OrderSummary from "../../../Common/loader";
import integrationUtils from "../../../../Utils/integration.utils";
import { MAP_INTEGRATION_HIGHLIGHT_CLASS } from "../../integrations/MapIntegrations";
import { TrimString } from "../../../../Utils/StringTrimmer";
import AddToolBtn from "../../../AttackSurface/KPICyberScoreSection/select-tool/add-tool.button";

const QuestionListItem = ({ 
  item,
  itemIndex,
  question,
  questionIndex,
  questionList,
  originalData,
  setOriginalData,
  setQuestionList,
  SubmitQuestions,
  curSectionState,
  searchComponent,
  showAttestationIcons,
  AccessStateLabel,
  SectionState,
  getQuestionState,
  getTools,
  onToolAdd,
  onRemoveTool,
  onNewToolAdd,
  isViewOnly=false,
  isShow = 0
}) => {
  const childQuestionTitle = SearchTitle({ title: item.QuestionText, searchValue: searchComponent });
  const toolsTitle = useMemo(() => (
    item?.SelectedTool_List?.some(tool => tool.ToolName.toLowerCase().includes(searchComponent.toLowerCase()))
  ), [searchComponent, item?.SelectedTool_List]);
  if (!childQuestionTitle && !toolsTitle) return null;
  let { addBorder, addRequiredBorder, showNever, hasCheckbox } = getQuestionState(item);
  if (curSectionState?.sectionIndex !== questionIndex || curSectionState?.sectionState === SectionState.Open) {
    addBorder = false;
    addRequiredBorder = false;
  }
  const isViewOnlyState = viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard");
  const isYesNoType = item?.QuestionTypeText === "YesOrNo";
  return (
    <div
      className={`align-items-center justify-content-between bg-hover-row radius-4 ${isYesNoType ? 'question-list-item-yes-no' : 'question-list-item'}`}
    >
      <div className="d-flex align-items-center top-item">
        {hasCheckbox ? (
          <div className="mb-4 mr-3">
            <Form.Group
              controlId={
                "check1" + questionIndex + itemIndex
              }
              className={`${
                item?.QuestionStatusText?.toLowerCase() ===
                "verified"
                  ? "disabled-checkbox"
                  : ""
              } round-checkbox blue-checkbox checkboxstyle`}
            >
              <Form.Check
                type="checkbox"
                disabled={
                  item?.QuestionStatusText?.toLowerCase() ===
                  "verified"
                }
                name={"check" + itemIndex + 1}
                label=""
                checked={item?.AnswerBool}
                onChange={(e) => {
                  let data_to_send = [...originalData];
                  let updated_data = [...questionList];
                  data_to_send[questionIndex][
                    "Question_List"
                  ][itemIndex].QuestionStatus = 3;
                  data_to_send[questionIndex][
                    "Question_List"
                  ][itemIndex].QuestionStatusText =
                    "Answered";
                  data_to_send[questionIndex][
                    "Question_List"
                  ][itemIndex].AnswerBool =
                    e.target.checked;
                  updated_data[questionIndex][
                    "Question_List"
                  ][itemIndex].AnswerId = 123;
                  updated_data[questionIndex][
                    "Question_List"
                  ][itemIndex].AnswerBool =
                    e.target.checked;
                  setOriginalData(data_to_send);
                  SubmitQuestions([
                    data_to_send[questionIndex][
                      "Question_List"
                    ][itemIndex],
                  ]);
                  setQuestionList(updated_data);
                }}
                // defaultChecked={elmt.StripeId}
              />
            </Form.Group>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <img
              alt=""
              src={item?.QuestionDrivedStatus_Logo}
              width={14}
              height={14}
              className="mr-2"
            />
          </div>
        )}
        <div className="break-word">
          {childQuestionTitle || item.QuestionText}
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row justify-content-md-end">
        {(item?.QuestionTypeText === "DropdownList" ||
          item?.QuestionDropdownListMembers?.length >
            1) && (
          <div className="d-flex align-items-center">
            <div className={`d-flex align-items-center px-1`}>
              {item.QuestionText === "Cyber Essential Certificate" && (
                <div 
                  className="f-darkgrey mr-3 white-space-no-wrap"
                >
                  Type
                </div>
              )}
              <div className={`${isViewOnly ? '' : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? '' : isShow == 0 ? '' : addBorder ? "bordered-option" : ''}`}>
                {isViewOnly ? <div className="f-darkgrey">
                    {item?.selectedDropdownValue?.category ??
                      "No Selected"}
                  </div> : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? (
                  <div className="f-darkgrey">
                    {item?.selectedDropdownValue?.category ??
                      "No Selected"}
                  </div>
                ) : (
                  <CustomHGDropdown
                    minWidth={"200px"}
                    disabled={
                      item?.QuestionStatusText?.toLowerCase() ===
                      "verified"
                    }
                    question={item?.QuestionDescription}
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={item.selectedDropdownValue}
                    placeholder="Choose"
                    onClick={(id, val) => {
                      //   To send Data to API
                      let data_to_send = [...originalData];
                      data_to_send[questionIndex][
                        "Question_List"
                      ][
                        itemIndex
                      ].AnswserSelectedDropdownItems = [val];
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].AnswerText = val;
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatus = 3;
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatusText =
                        "Answered";
                      setOriginalData(data_to_send);
                      SubmitQuestions([
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex],
                      ]);

                      // To Update the drop down value
                      let updated_data = [...questionList];
                      updated_data[questionIndex][
                        "Question_List"
                      ][itemIndex].selectedDropdownValue = {
                        id,
                        category: val,
                      };
                      updated_data[
                        questionIndex
                      ].AnswerId = 123;
                      setQuestionList(updated_data);
                    }}
                    data={item?.dropDowndata}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {item?.QuestionTypeText === "Date" && (
          <div className="d-flex align-items-center">
            <div className={`d-flex align-items-center px-1`}>
              {(item.QuestionText !== "Cyber Essential Certificate" && item?.Explanation) && (
                <div className="f-darkgrey mr-3 white-space-no-wrap" style={{ width: '104px' }}>
                  {item?.Explanation}
                </div>
              )}
              <div className={`${isViewOnly ? '' : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? '' : isShow == 0 ? '' : (!addRequiredBorder && addBorder) ? "bordered-option" : ''}`}>
                {isViewOnly ? <div className="f-darkgrey">
                    {handleDateForInvoice(item.AnswerDate) ??
                      "No Selected"}
                  </div> : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? (
                  <div className="f-darkgrey">
                    {handleDateForInvoice(item.AnswerDate) ??
                      "No Selected"}
                  </div>
                ) : (
                  <CyberComplianceDateDropdown
                    disabled={
                      item?.QuestionStatusText?.toLowerCase() ===
                      "verified"
                    }
                    placeholder={
                      item.QuestionText ===
                      "Cyber Essential Certificate"
                        ? "Expiry Date"
                        : null
                    }
                    id={
                      "powr" +
                      questionIndex +
                      "dfxd" +
                      itemIndex +
                      "xub"
                    }
                    question={item}
                    screenType={"calender"}
                    showNever={showNever}
                    selected={
                      handleDateForInvoice(item.AnswerDate) ??
                      null
                      // getDateFormatForMoment(question.AnswerDate) ?? null
                    }
                    {...(addRequiredBorder && {
                      className: "required-option"
                    })}
                    onChange={(e, type) => {
                      //   To send Data to API
                      let data_to_send = [...originalData];
                      let updated_data = [...questionList];
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatus = 3;
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatusText =
                        "Answered";
                      // To Update the drop down value
                      if (type === "text") {
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerText = e;

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerText = e;

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerId = 123;
                      }
                      if (type === "date") {
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerDate =
                          getDateFormatForMoment(e);

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerDate =
                          getDateFormatForMoment(e);
                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerId = 123;
                      }
                      if (!type && e == "") {
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerDate = null;

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerDate = null;

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerId = 123;
                      }

                      setOriginalData(data_to_send);
                      SubmitQuestions([
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex],
                      ]);
                      setQuestionList(updated_data);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {item?.QuestionTypeText === "DateAndCheckBox" && (
          <div
            className="ml-4 d-flex align-items-center w-60"
            style={{
              visibility: item?.AnswerBool
                ? "visible"
                : "hidden",
            }}
          >
            <div className={`d-flex align-items-center px-1`}>
              {item?.Explanation && (
                <div className="f-darkgrey mr-3 white-space-no-wrap" style={{ width: '104px' }}>
                  {item?.Explanation}
                </div>
              )}
              <div className={`${isViewOnly ? '' : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? ''  : isShow == 0 ? '' : addBorder ? "bordered-option" : ''}`}>
                {isViewOnly ? <div className="f-darkgrey">
                    {handleDateForInvoice(item.AnswerDate) ??
                      "No Selected"}
                  </div> : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? (
                  <div className="f-darkgrey">
                    {handleDateForInvoice(item.AnswerDate) ??
                      "No Selected"}
                  </div>
                ) : (
                  <CyberComplianceDateDropdown
                    disabled={
                      item?.QuestionStatusText?.toLowerCase() ===
                      "verified"
                    }
                    id={
                      "powr" +
                      questionIndex +
                      "dfxd" +
                      itemIndex +
                      "xub"
                    }
                    question={item}
                    screenType={"calender"}
                    showNever={showNever}
                    selected={
                      handleDateForInvoice(item.AnswerDate) ??
                      null
                      // getDateFormatForMoment(question.AnswerDate) ?? null
                    }
                    onChange={(e, type) => {
                      //   To send Data to API
                      let data_to_send = [...originalData];
                      let updated_data = [...questionList];
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatus = 3;
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatusText =
                        "Answered";
                      // To Update the drop down value
                      if (type === "text") {
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerText = e;

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerText = e;

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerId = 123;
                      }
                      if (type === "date") {
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerDate =
                          getDateFormatForMoment(e);

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerDate =
                          getDateFormatForMoment(e);

                        updated_data[questionIndex][
                          "Question_List"
                        ][itemIndex].AnswerId = 123;
                      }

                      setOriginalData(data_to_send);
                      SubmitQuestions([
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex],
                      ]);
                      setQuestionList(updated_data);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {item?.QuestionTypeText === "CurrencyDropdown" && (
          <div className="d-flex align-items-center w-60">
            <div className={`d-flex align-items-center px-2 ${isViewOnly ? '' : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? ''  :  isShow == 0 ? '' : addBorder ? "bordered-option" : ''}`}>
              <div className="mr-1">
                {isViewOnly ? <div className="f-darkgrey">
                    {item?.AnswerJson?.FullCurrencyName ??
                      "No Selected"}
                  </div> : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? (
                  <div className="f-darkgrey">
                    {item?.AnswerJson?.FullCurrencyName ??
                      "No Selected"}
                  </div>
                ) : (
                  <CustomHGDropdown
                    disabled={
                      item?.QuestionStatusText?.toLowerCase() ===
                      "verified"
                    }
                    showSearch={true}
                    greyBackground
                    fieldId={"ObjectId"}
                    fieldValue={"FullCurrencyName"}
                    placeholder="Select"
                    selected={item?.AnswerJson}
                    onClick={(id, val, _item) => {
                      //   To send Data to API
                      let data_to_send = [...originalData];
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].AnswerJson = _item;
                      // data_to_send[questionIndex][
                      //   "Question_List"
                      // ][itemIndex].AnswerText = val;
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatus = 3;
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex].QuestionStatusText =
                        "Answered";
                      setOriginalData(data_to_send);
                      SubmitQuestions([
                        data_to_send[questionIndex][
                          "Question_List"
                        ][itemIndex],
                      ]);

                      // To Update the drop down value
                      let updated_data = [...questionList];

                      updated_data[
                        questionIndex
                      ].AnswerId = 123;
                      setQuestionList(updated_data);
                    }}
                    data={item?.CurrencyCode_List}
                  />
                )}
              </div>
              <CustomInputField
                disabled={
                  item?.QuestionStatusText?.toLowerCase() ===
                  "verified"
                }
                prefix={
                  item?.AnswerJson?.CurrencySymbol ?? ""
                }
                greyBorder
                placeholder={"0.00"}
                type={"number"}
                value={item?.AnswerText ?? ""}
                onChange={(e) => {
                  let data_to_send = [...originalData];
                  data_to_send[questionIndex][
                    "Question_List"
                  ][itemIndex].AnswerText = e.target.value;
                  // data_to_send[questionIndex][
                  //   "Question_List"
                  // ][itemIndex].AnswerText = val;
                  data_to_send[questionIndex][
                    "Question_List"
                  ][itemIndex].QuestionStatus = 3;
                  data_to_send[questionIndex][
                    "Question_List"
                  ][itemIndex].QuestionStatusText =
                    "Answered";
                  setOriginalData(data_to_send);

                  // To Update the drop down value
                  let updated_data = [...questionList];
                  updated_data[questionIndex][
                    "Question_List"
                  ][itemIndex].AnswerText = e.target.value;

                  updated_data[questionIndex].AnswerId = 123;
                  setQuestionList(updated_data);
                }}
                onBlur={() => {
                  let data_to_send = [...originalData];

                  if (
                    data_to_send[questionIndex][
                      "Question_List"
                    ][itemIndex].AnswerText
                  ) {
                    SubmitQuestions([
                      data_to_send[questionIndex][
                        "Question_List"
                      ][itemIndex],
                    ]);
                  }
                }}
                isError={question?.error}
                errorMsg={"This field must be valid."}
                style={{
                  width: "150px",
                }}
              />
            </div>
          </div>
        )}
        {item?.QuestionTypeText === "Attestation" && (
          <div className="ml-4 d-flex align-items-center">
            <div className={`d-flex align-items-center px-1`}>
              <div className="f-darkgrey mr-3 white-space-no-wrap">
                Tool
              </div>
              <div className={`${isViewOnly ? '' : viewOnlyAccess(
                  AccessStateLabel ?? "AccessState_Dashboard"
                ) ? ''  :  isShow == 0 ? '' : (!addRequiredBorder && addBorder) ? "bordered-option" : ''}`}>
                <ToolModal 
                  getTools={getTools} 
                  integrationlist={item?.IntegrationList?.map(tool => ({
                    ...tool,
                    IntegrationName: tool.ToolName,
                    IntegrationId: tool.obj_IntegrationId,
                  }))}
                  securityStackList={item?.SecurityStackList?.map(tool => ({
                    ...tool,
                    IntegrationName: tool.ToolName,
                    IntegrationId: tool.obj_IntegrationId,
                  }))}
                  Name={item?.QuestionText}
                  item={item}
                  onToolAdd={onToolAdd}
                  onRemoveTool={onRemoveTool}
                  onNewToolAdd={onNewToolAdd}
                  searchComponent={searchComponent}
                  isViewOnlyState={isViewOnlyState}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {
        <div className="d-flex align-items-center justify-content-end top2-item" style={{ gap: '8px' }}>
          {
            showAttestationIcons ? (
              <AttestationPopUp>
                <img
                  alt=""
                  src={
                    Attestation_Icon_Text_Mappings[
                      item?.QuestionStatusText
                    ]
                  }
                  className=" mr-2"
                />
              </AttestationPopUp>
            ) : null
          }
          <span
            className="px-2 py-1 f-500 "
            style={{
              borderRadius: "50px",
              background: item?.QuestionScoreColour ? `${item?.QuestionScoreColour}50` : '#EBEDF1',
              color: item?.QuestionScoreColour ? `${item?.QuestionScoreColour}` : '#616778',
              width: '65px',
              textAlign: 'center'
            }}
          >
            {item?.QuestionAchievedScore} of {item?.QuestionMaxScore}
          </span>
        </div>
      }
    </div>
  );
}

export default QuestionListItem;

const ToolModal = ({
  item,
  getTools = () => {},
  onToolAdd = () => {},
  onRemoveTool = () => {},
  onNewToolAdd = () => {},
  integrationlist = [],
  securityStackList = [],
  searchComponent = "",
  Name = "",
  isViewOnlyState = false
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false });
  const filteredIntegrationList = useMemo(() => 
    integrationlist.filter((tool) => tool.IntegrationName.toLowerCase().includes(search.toLowerCase())), 
  [search, integrationlist]);
  const filteredSecurityStackList = useMemo(() => securityStackList.filter((tool) => 
    tool.IntegrationName.toLowerCase().includes(search.toLowerCase())), 
  [search, securityStackList]);

  useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    getTools({ technologyEnum: item.TechnologyEnum }).finally(() => setIsLoading(false));
  }, [isOpen]);

  function checkIfIdExists(id, dataArray) {
    const ids = dataArray.map((obj) => obj.obj_IntegrationId);
    return ids.indexOf(id) !== -1;
  }

  const handleAddTool = (tool) => {
    onToolAdd(tool).finally(() => {
      setIsOpen(false);
    });
  }

  const handleRemoveTool = (tool) => {
    onRemoveTool(tool).finally(() => {
      setIsOpen(false);
    });
  }

  const handleNewToolAdd = () => {
    onNewToolAdd(search).finally(() => {
      setIsOpen(false);
      setSearch("");
    });
  }

  return (
    <Dropdown show={isOpen} onToggle={() => setIsOpen(prev => !prev)} className="transparennt-wrapper-dropdown my-dropdown">
      <Dropdown.Toggle
        id="dropdown-basic"
        className="d-flex align-items-center toggle"
        ref={refBtn}
        style={{
          ...(isViewOnlyState && {
            pointerEvents: 'none',
          })
        }}
      >
        <div className="f-darkgrey d-flex align-items-center px-2" style={{ gap: '8px' }}>
          {item?.SelectedTool_List?.length === 1 ? (
            <div className="d-flex w-100 align-items-center justify-content-sta-rt p-1 my-1 radius-4">
              <div className="d-flex  align-items-center f-grey f-500">
                <img
                  alt=""
                  className="mr-2 rounded-full"
                  width={15}
                  src={integrationUtils.getImagePath(item.SelectedTool_List[0]?.obj_IntegrationId, item.SelectedTool_List[0]?.ToolIcon)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/attack-surface/generic2.svg";
                  }}
                />
                <div className={` ${MAP_INTEGRATION_HIGHLIGHT_CLASS}`}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: HGHighlightText(
                        TrimString(
                          item.SelectedTool_List[0]?.ToolName,
                          15
                        ),
                        searchComponent
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          ) : item?.SelectedTool_List?.length > 1 ? (
            <div
              className="d-flex w-auto align-items-center justify-content-sta-rt p-1 my-1 radius-4"
              style={{
                background: "#008fd435",
              }}
            >
              <div className="d-flex  align-items-center f-blue f-500">
                <img
                  alt=""
                  className="mr-2 rounded-full"
                  width={15}
                  src={integrationUtils.getImagePath(item.SelectedTool_List[0]?.obj_IntegrationId, item.SelectedTool_List[0]?.ToolIcon)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/attack-surface/generic2.svg";
                  }}
                />
                <div className={`f-blue ${MAP_INTEGRATION_HIGHLIGHT_CLASS}`}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: HGHighlightText(
                        TrimString(
                          item.SelectedTool_List[0]?.ToolName,
                          15
                        ),
                        searchComponent
                      ),
                    }}
                  />
                </div>
                {item.SelectedTool_List.length > 1 && (
                  <div className="f-darkgrey f-12 mx-1">
                    + {item.SelectedTool_List.length - 1}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <span className="py-2">Select</span>
          )}
          <SelectArrowIcon className="arrow" width="12px" height="8px" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="menu question-list-tool-modal"
        ref={refMenu}
        style={{
          padding: '4px 0'
        }}
      >
        <div>
          <div className="mb-3">
            <SearchInput 
              value={search}
              onChange={(value) => setSearch(value)}
              placeholder="Search"
              className="bg-white p-0 px-2"
            />
          </div>
          <div className="hide-scrollbar" style={{ maxHeight: '280px', overflowY: 'auto' }}>
            {isLoading ? (
              <OrderSummary />
            ) : (
              <div className="d-flex flex-column" style={{ gap: '16px' }}>
                { search ? (
                <ToolsSection 
                  title="Results"
                  list={[
                    ...filteredSecurityStackList,
                    ...filteredIntegrationList,
                  ]}
                  search={search}
                  checkIfIdExists={checkIfIdExists}
                  onAddTool={handleAddTool}
                  onRemoveTool={handleRemoveTool}
                  toolAttestationData={item}
                  noToolsTitle=""
                />
              ) : (
                <>
                  <ToolsSection 
                    title="Security Stack"
                    list={filteredSecurityStackList}
                    search={search}
                    checkIfIdExists={checkIfIdExists}
                    onAddTool={handleAddTool}
                    onRemoveTool={handleRemoveTool}
                    toolAttestationData={item}
                  />
                  <ToolsSection 
                    title={`All ${Name} tools`}
                    list={filteredIntegrationList}
                    search={search}
                    checkIfIdExists={checkIfIdExists}
                    onAddTool={handleAddTool}
                    onRemoveTool={handleRemoveTool}
                    toolAttestationData={item}
                  />
                </>
              )}
              </div>
            )}
          </div>
          <div className="px-3 mt-2">
            {search.trim() && (
              <AddToolBtn
                value={search} 
                onClick={handleNewToolAdd}
                isAdding={item?.addingNewTool}
              />
            )}
            {item.addingNewTool && (
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                className="mx-2 result-spinner "
                style={{
                  width: "12px",
                  height: "12px",
                  marginBottom: '2px'
                }}
              />
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const getGridTemplateAreas = (columns) => {
  return columns
    ?.filter((column) => column?.Visibility)
    ?.sort((a, b) => a?.SortOrder - b?.SortOrder)
    ?.map((column) => column?.gridArea)
    .join(" ");
}

const getGridTemplateColumns = (columns) => {
  return columns
    ?.filter((column) => column?.Visibility)
    ?.sort((a, b) => a?.SortOrder - b?.SortOrder)
    ?.map((column) => column?.gridWidth)
    .join(" ");
}

const tableUtils = {
  getGridTemplateAreas,
  getGridTemplateColumns,
};

export default tableUtils;

import React, { useEffect, useState } from "react";
import { IncreaseDecreaseNumbersComponentAlwaysHighlight } from "../../../mspSell/mspPriceCalculator/MspAddEditServiceModal";

export const PriceBreakdownTable = ({
  data,
  switchVal,
  resetting,
  isTableEdited,
  packageDetails,
  isMspClientView,
  assetBreakDownData,
  handleSave = () => { },
  handleReset = () => { },
  getPackageDetails = () => { },
  setIsSmthOverriden = () => { },
  resetAssetSeatDataApi = () => { },
  setAssetBreakDownData = () => { },
  updateSeatAssetDataApi = () => { },
}) => {
  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between">
        <div className="f-12 f-darkgrey w-100">
          Price Breakdown
        </div>
        <div className="d-flex align-items-center">
          {isMspClientView ? (
            <div></div>
          ) : (
            <div>
              <div className="d-flex align-items-center">
                {isTableEdited && (
                  <div
                    className="f-blue f-500 mr-2 pointer d-flex"
                    onClick={() => {
                      handleReset();
                      setIsSmthOverriden(true);
                    }}
                  >
                    <img
                      alt=""
                      className={`mr-1 ${resetting && "rotate-full"}`}
                      src="/images/msp/blue-load-icon.svg"
                    />
                    {resetting ? "Resetting.." : "Reset"}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="border-parent  radius-8">
        <div
          className={`border-item f-12 f-500 f-darkgrey p-2 ${switchVal
            ? " per_asset_price_breakdown_table "
            : " per_asset_price_breakdown_table_switch "
            }`}
        >
          <div>Asset Type</div>
          <div>Qty</div>
          {switchVal && <div>Unit Cost</div>}
          {switchVal && <div>Unit RRP</div>}
          <div>Unit Sell</div>
          <div>Line Total</div>
        </div>
        {assetBreakDownData?.MSPPackagePrice?.PackagePerAsset_List
          ?.map((data, dataIndex) => {
            return (
              <>
                {data.UnitCost !== 0 && (
                  <div
                    className={`border-item f-grey px-2 ${switchVal
                      ? " per_asset_price_breakdown_table "
                      : " per_asset_price_breakdown_table_switch "
                      }`}
                  >
                    <div className="f-black f-500">{data.Name}</div>
                    <div>
                      <div style={{
                        width: '70%',
                        marginBottom: '4px',
                        marginLeft: '-14px'
                      }}>
                        <IncreaseDecreaseNumbersComponentAlwaysHighlight
                          type={"number"}
                          floatValue={false}
                          value={data.Qty}
                          isBlue={data?.IsEditedQuantity}
                          onChange={(value) => {
                            let updated_data = { ...assetBreakDownData };
                            updated_data.MSPPackagePrice.PackagePerAsset_List[
                              dataIndex
                            ].IsEditedQuantity = true;
                            updated_data.MSPPackagePrice.PackagePerAsset_List[
                              dataIndex
                            ].Qty =
                              value && Number(value);
                            setAssetBreakDownData(updated_data);
                            handleSave();
                            setIsSmthOverriden(true);
                            return true;
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </div>
                    </div>
                    {switchVal && (
                      <div>
                        {" "}
                        <span>
                          {packageDetails?.CurrencyCode_Selected
                            ?.CurrencySymbol ?? "£"}
                        </span>
                        {Number(data.UnitCost).toFixed(2)}
                      </div>
                    )}
                    {switchVal && (
                      <div>
                        {packageDetails?.CurrencyCode_Selected
                          ?.CurrencySymbol ?? "£"}
                        {Number(data?.UnitRRP)?.toFixed(2)}{" "}
                      </div>
                    )}
                    <div style={{
                      width: '70%',
                      marginBottom: '4px',
                      marginLeft: '-14px'
                    }}>
                      <IncreaseDecreaseNumbersComponentAlwaysHighlight
                        type={"number"}
                        floatValue={false}
                        value={data.UnitSell}
                        children={<div
                          className={`f-400 f-14 ${assetBreakDownData.MSPPackagePrice.PackagePerAsset_List[dataIndex].IsEditedUnitSell ? 'txt-blue' : 'f-black'}`}
                          style={{
                            marginBottom: '1px'
                          }}
                        >
                          {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                        </div>}
                        isBlue={data.IsEditedUnitSell}
                        onChange={(value) => {
                          if (assetBreakDownData.MSPPackagePrice.PackagePerAsset_List[dataIndex].UnitSell != Number(value)) {
                            let updated_data = { ...assetBreakDownData };
                            updated_data.MSPPackagePrice.PackagePerAsset_List[
                              dataIndex
                            ].IsEditedUnitSell = true;
                            updated_data.MSPPackagePrice.PackagePerAsset_List[
                              dataIndex
                            ].UnitSell = Number(value);
                            setAssetBreakDownData(updated_data);
                            return true;
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!isNaN(value)) {
                            if (value.includes(".")) {
                              const decimalPartLength =
                                value.split(".")[1].length;
                              if (decimalPartLength === 1) {
                                let updated_data = { ...assetBreakDownData };
                                updated_data.MSPPackagePrice.PackagePerAsset_List[
                                  dataIndex
                                ].UnitSell =
                                  value + "0";
                                setAssetBreakDownData(updated_data);
                              } else if (decimalPartLength > 2) {
                                let updated_data = { ...assetBreakDownData };
                                updated_data.MSPPackagePrice.PackagePerAsset_List[
                                  dataIndex
                                ].UnitSell =
                                  Number(value).toFixed(2).toString();
                                setAssetBreakDownData(updated_data);
                              }
                            } else {
                              let updated_data = { ...assetBreakDownData };
                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                dataIndex
                              ].UnitSell =
                                value + ".00";
                              setAssetBreakDownData(updated_data);
                            }
                          }
                          handleSave();
                        }}
                      />
                    </div>
                    <div>
                      <span>
                        {" "}
                        {packageDetails?.CurrencyCode_Selected
                          ?.CurrencySymbol ?? "£"}
                      </span>
                      {Number(data?.SellTotal)?.toFixed(2)}
                    </div>
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default PriceBreakdownTable;

import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, ToggleButton } from "react-bootstrap";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { HGHighlightText } from "../../../App/App";
import { addZerows } from ".";
import { BASE_API_ROUTES } from "../../../../constants/apis.constants";
import userUtils from "../../../../Utils/user.utils";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import integrationUtils from "../../../../Utils/integration.utils";
import ChooseProductModal from "./ChooseProductModal";
import MultiplyIcons from "./mspCalculatorDropdownAndModals/MultiplyIcons";
import IntegrationIcon from "./IntegrationIcon";
import { ROUTE_PATHS } from "../../../../constants/app.constants";

const SelectProductOrLabour = ({
  children,
  productData,
  serviceComponents,
  setServiceComponents = () => { },
  contributionName,
  setUpdateModal = () => { },
  labourData = [],
  contributionType,
  techItem,
  setPage = () => { },
  setChanged = () => { },
  setServicePageErrors = () => { },
  productsList,
  setProductsList = () => { },
  userSelectedCurrency,
  setNestedProductModalState = () => { },
  showChooseProductModal,
  setShowChooseProductModal = () => { },
  selectAfterOpen = 0,
  setSelectAfterOpen = () => { },
  selectedTool,
  setSelectedTool = () => { },
  selectedContributionName,
  setSelectedContributionName = () => { }
}) => {
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [selectedTab, setSelectedTab] = useState(contributionType == 1 ? {
    id: 0,
    name: 'Product'
  } : {
    id: 2,
    name: 'Security Stack'
  })
  const [allTools, setAllTools] = useState([]);
  const [securityStackTools, setSecurityStackTools] = useState([]);
  const history = useHistory();

  // const [selectedTool, setSelectedTool] = useState({});

  const tabs = contributionType == 1 ? [
    {
      id: 0,
      name: 'Product'
    },
    {
      id: 1,
      name: 'Labour'
    },
  ] : [
    {
      id: 2,
      name: 'Security Stack'
    },
    {
      id: 0,
      name: 'Product'
    }
  ]


  useEffect(() => {
    if (IsDropdownOpen) {
      if (selectedTab?.id == 0) {
        setLoading(true);
        getLinkedProducts();
        getProducts();
      }
      if (selectedTab?.id == 2) {
        setLoading(true);
        getAllIntegrationsByTechType(/*contributionName, true, */searchStr)
        // getAllIntegrationsByTechType(contributionName, false, searchStr)
      }
    }
  }, [IsDropdownOpen, selectedTab])


  const scrollContainerRef = useRef(null);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [data, setData] = useState([]);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const { authTokens } = useAuth();

  useEffect(() => {
    if (!open) setPageNumber(0);
  }, [open]);
  useEffect(() => {
  }, [pageNumber]);

  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 201 >=
        e.target.scrollHeight
      ) {
        if (isLastPage) return;

        let element = document.getElementById("scrollList");
        element.removeEventListener("scroll", getMoreClients);

        // setLoading(true);
        try {
          await getProducts();

          console.log("You've reached the end of the screen!");
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("scrollList");
    if (element) {
      element.removeEventListener("scroll", getMoreClients);
      element.addEventListener("scroll", getMoreClients);

      return () => {
        element.removeEventListener("scroll", getMoreClients);
      };
    }
  }, [data, loading, open]);

  function removeDuplicates(arr) {
    const seen = {};
    return arr.filter(item => {
      return seen.hasOwnProperty(item.ObjId_PSAProduct) ? false : (seen[item.ObjId_PSAProduct] = true);
    });
  }

  async function getProducts(num, searchStr) {
    try {
      const pageN = num ?? pageNumber;
      const response = await postData(authTokens, "GetPagedPackageProduct", {
        PageNumber: pageN,
        SearchText: searchStr ?? searchString,
        Technology: contributionName ?? '',
        IsToolNeeded: true
      });

      if (num == 0) {
        setData(response?.PSAProductVM_List);
        setPageNumber(response?.PageNumber);
      } else if (response.PSAProductVM_List?.length == 0) {
        setData(response?.PSAProductVM_List);
      } else if (response?.PageNumber === 0 || pageNumber === 0) {
        setData(response?.PSAProductVM_List);
        setPageNumber(response?.PageNumber);
      } else {
        const new_output = response?.PSAProductVM_List;
        const old_client_data = [...data];
        const updated_data = [...new Set([...old_client_data, ...new_output])];

        const new_updated_data = removeDuplicates(updated_data);
        setData(new_updated_data);
        setPageNumber(response?.PageNumber);
      }
      setLoading(false);
      setIsLastPage(response?.IsLastPage);

      console.log("response num", response?.PageNumber);

      return response?.PageNumber;
    } catch (error) {
      console.error("error in getProducts", error);
      setLoading(false);
      throw error;
    }
  }


  async function getLinkedProducts() {
    await getAPIData(1053, authTokens, {
      optionEnum1: techItem ?? 0,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setLinkedProducts(response?.PSAProductVM_List);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getAllIntegrationsByTechType = async (
    /*technologyType,
    showAll = false,*/
    searchText = ''
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const message = userUtils.getUserMessage();
        const formData = new FormData();
        formData.append('TechnologyType', /*technologyType*/'');
        formData.append('ShowAll', /*showAll*/true);
        formData.append('Search_text', searchText);
        formData.append('TechnologyTypeEnum', techItem);
        formData.append('accessKey', message);

        fetch(
          BASE_API_ROUTES.getAllIntegrationsByTechType,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${authTokens}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data?.mr?.Success) {
              setAllTools(data?.Integration_List);
              setSecurityStackTools(data?.SecurityStack_List)
              // if (showAll) {
              //   setAllTools(data?.Integration_List)
              // } else {
              //   setSecurityStackTools(data?.Integration_List)
              // }
              setLoading(false);
            } else {
              reject('Failed to fetch integrations');
              setLoading(false);
            }
          })
          .catch((error) => {
            CallToast("Something went wrong", false, false, "v2style");
            reject(error);
            setLoading(false);
          });
      } catch (error) {
        console.error('Error fetching integrations', error);
        reject(error);
        setLoading(false);
      }
    });
  };


  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (value) => {
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);
    console.log("check input", value);
    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setLoading(true);
      getProducts(0, value).then((tempN) => {
        setLoading(false);
        setPageNumber(tempN);
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };


  const checkAnyImageExists = (imagePath1, imagePath2) => {
    return new Promise((resolve) => {
      const img1 = new Image();
      const img2 = new Image();

      img1.onload = () => resolve(true); 
      img1.onerror = () => {
        img2.onload = () => resolve(true); 
        img2.onerror = () => resolve(false); 
        img2.src = `/images/attack-surface/integrations/${imagePath2}.webp`;
      };

      img1.src = `/images/attack-surface/integrations/${imagePath1}.webp`;
    });
  };


  return (
    <Dropdown className='select-product-or-labour-dropdown mr-4'
      onToggle={(e) => {
        setIsDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <div>
          <div className="drop-header">
            <div className="d-flex align-items-center py-2 px-2">
              {
                tabs?.map((tabsItem) => {
                  return (
                    <div
                      className="d-flex align-items-center w-50 pointer"
                      onClick={() => {
                        setSelectedTab(tabsItem);
                        setSearchStr("");
                      }}
                    >
                      <img
                        src={selectedTab?.id === tabsItem?.id ? "/images/radio-button-checked-icon.svg" : "/images/radio-button-unchecked-icon.svg"}
                        className="mr-2"
                        alt=""
                      />
                      <div className="f-400 f-14 f-black line-height-22 letter-space-2 ml-">{tabsItem?.name}</div>
                    </div>
                  )
                })
              }
            </div>

            <div className="no-highlight-input w-100 d-flex align-items-center p-2">
              <div>
                <img
                  width={14}
                  height={14}
                  src="/images/attack-surface/search-icon.svg"
                  className="m-1"
                  alt=""
                />
              </div>

              <input
                className="pl-2 flex-grow-1"
                placeholder="Search"
                type="text"
                value={searchStr}
                onChange={(e) => {
                  setSearchStr(e.target.value);
                  if (selectedTab?.id == 0) {
                    setPageNumber(0);
                    handleInputChange(e.target.value);
                  } else if (selectedTab?.id == 2) {
                    getAllIntegrationsByTechType(/*contributionName, true, */e.target.value)
                    // getAllIntegrationsByTechType(contributionName, false, e.target.value)
                  }
                }}
              />
            </div>
          </div>

          {
            loading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
              <div className="msp-service-update-loader ml-2 mr-1"></div>
              <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
            </div> : <div>
              {
                selectedTab?.id == 0 ? <div>
                  {
                    searchStr != "" &&
                    <div
                      className="f-blue f-500 ml-3 my-2 d-flex align-items-center pointer"
                      onClick={() => {
                        setPage("addProduct", searchStr);
                      }}
                    >
                      <img alt="" src="/images/msp/blue-add-icon.svg" className="mr-2" />
                      {searchStr ? (
                        <div className="pt-1">Add {` "${searchStr}" `}</div>
                      ) : (
                        <div className="pt-1">Add product</div>
                      )}
                    </div>
                  }

                  <div className="h-50">
                    <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                      Linked Products
                    </div>
                    {
                      linkedProducts?.filter((product) =>
                        product?.ProductName.toLowerCase().includes(searchStr.toLowerCase()))?.length > 0 ? <div>
                        {
                          linkedProducts?.filter((product) =>
                            product?.ProductName.toLowerCase().includes(searchStr.toLowerCase()))?.map((product) => {
                              return (
                                <div
                                  className={`product-item align-items-center justify-content-between bg-hover-grey p-2 mx-1 mb-1 radius-4 pointer`}
                                  onClick={(e) => {
                                    if (product?.IsMissingDetails) {
                                      e.stopPropagation();
                                      setNestedProductModalState((prev) => ({
                                        ...prev,
                                        show: true,
                                        selectedProductId: product.ObjId_PSAProduct,
                                        contribName: contributionName,
                                        forAdd: true
                                      }));
                                    } else {
                                      const updated_data = serviceComponents?.map(item => {
                                        if (item.ContributionName === contributionName) {
                                          const productIndex = item.List_ScoreProducts?.findIndex(p => p?.objId_Product === product?.ObjId_PSAProduct);

                                          let updatedProducts;
                                          if (productIndex !== -1) {
                                            updatedProducts = item.List_ScoreProducts.filter(p => p?.objId_Product !== product?.ObjId_PSAProduct);
                                          } else {
                                            updatedProducts = [...(item.List_ScoreProducts || []), {
                                              ...product,
                                              List_ProductTool: product?.ToolSelected_List
                                                ? product.ToolSelected_List.map(tool => ({
                                                  ObjId_Tool: tool?.IntegrationId,
                                                  ToolIcon: tool?.ToolIcon,
                                                  ToolName: tool?.IntegrationName
                                                }))
                                                : [],
                                              objId_Product: product.ObjId_PSAProduct
                                            }];
                                          }

                                          return {
                                            ...item,
                                            List_ScoreProducts: updatedProducts
                                          };
                                        }
                                        return item;
                                      });
                                      setServiceComponents(updated_data);

                                      setChanged(true);

                                      setServicePageErrors((prev) => ({
                                        ...prev,
                                        selectedListError: false,
                                      }));
                                      let updated_product_list = [...productsList];
                                      let Index = updated_product_list.findIndex(
                                        (item) =>
                                          item?.ObjId_PSAProduct ===
                                          product?.ObjId_PSAProduct
                                      );
                                      if (Index > -1) {
                                        updated_product_list.splice(Index, 1);
                                      } else {
                                        updated_product_list.push({ ...product });
                                      }

                                      setProductsList([
                                        ...updated_product_list,
                                      ]);
                                    }

                                    e.target
                                      .closest(".select-product-or-labour-dropdown")
                                      .click();
                                  }}
                                >
                                  <div>
                                    <div className={` f-500 subclass ${serviceComponents?.find(obj => obj?.ContributionName === contributionName)?.List_ScoreProducts.some(p => p?.objId_Product === product?.ObjId_PSAProduct) ?
                                      "txt-blue" : "f-grey"
                                      }`}>
                                      {/* {product?.IntegrationId > 0 && (
                                        <img
                                          src={integrationUtils.getImagePath(product?.IntegrationId, product?.ToolIcon)}
                                          alt=""
                                          className="rounded-full mr-1"
                                          width={13}
                                          height={13}
                                          style={{}}
                                        />
                                      )} */}
                                      <div className="d-flex align-items-center">
                                        {
                                          product?.ToolSelected_List?.length > 0 &&
                                          <MultiplyIcons
                                            icons={product?.ToolSelected_List}
                                            nameKey={'IntegrationName'}
                                            idKey={'IntegrationId'}
                                            iconKey={'ToolIcon'}
                                          />
                                        }
                                        <span className={`${product?.ToolSelected_List?.length > 0 && 'ml-2'}`}
                                          dangerouslySetInnerHTML={{
                                            __html: HGHighlightText(
                                              product?.ProductName,
                                              searchStr
                                            ),
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex f-darkgrey f-400 f-12 align-items-center">

                                      {product?.CurrencyCode_Selected?.CurrencySymbol && (
                                        <div>{`${product?.CurrencyCode_Selected?.CurrencySymbol} `}</div>
                                      )}
                                      {product?.Cost && (
                                        <div className="mr-1">{`${addZerows(product?.Cost?.toString())} `}</div>
                                      )}
                                      {product?.SnapToUnit_Text && (
                                        <div className="mr-1">{`/${product?.SnapToUnit_Text} `}</div>
                                      )}
                                      {product?.BillingPeriod_Text && (
                                        <div>{`/${product?.BillingPeriod_Text} `}</div>
                                      )}
                                    </div>
                                  </div>
                                  {product?.IsMissingDetails && (
                                    <div className="d-flex align-items-center f-darkgrey f-500 f-12">
                                      {" "}
                                      <img
                                        width={16}
                                        alt=""
                                        className="mr-1"
                                        src="/images/msp/grey-alert.svg"
                                      />
                                      <div>Missing Details</div>
                                    </div>
                                  )}
                                </div>
                              )
                            })
                        }
                      </div> : searchStr && searchStr != "" ? <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                        {searchStr != "" ? `No results for "${searchStr}"` : 'No tools yet'}
                      </div> : <div className="d-flex align-items-center justify-content-center w-100 my-3">
                        <div style={{
                          marginRight: '90px',
                          marginLeft: '90px'
                        }}>
                          <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey">
                            You have not linked any products to a {contributionType == 1 ? 'practice' : 'tool'} in your security stack for this technology type.
                          </div>
                          <div className="d-flex align-items-center justify-content-center w-100 f-400 f-14 f-blue pointer"
                            onClick={() => {
                              history.push(ROUTE_PATHS.mspv2.securityStack);
                            }}
                          >
                            Go to Security Stack
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  <div className="h-50">
                    <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                      Products
                    </div>
                    {
                      data?.filter((product) => product.IsLinked === false)?.length > 0 ? <div
                        className="transparent-scroller"
                        style={{
                          maxHeight: "246px",
                          overflowY: "auto",
                        }}
                        ref={scrollContainerRef}
                        id="scrollList"
                      >
                        {
                          data?.filter((product) => product.IsLinked === false)?.map((product) => {
                            return (
                              <div
                                className={`product-item align-items-center justify-content-between bg-hover-grey p-2 mx-1 mb-1 radius-4 pointer`}
                                onClick={(e) => {
                                  if (product?.IsMissingDetails) {
                                    e.stopPropagation();
                                    setNestedProductModalState((prev) => ({
                                      ...prev,
                                      show: true,
                                      selectedProductId: product.ObjId_PSAProduct,
                                      contribName: contributionName,
                                      forAdd: true
                                    }));
                                  } else {
                                    const updated_data = serviceComponents?.map(item => {
                                      if (item.ContributionName === contributionName) {
                                        const productIndex = item.List_ScoreProducts?.findIndex(p => p?.objId_Product === product?.ObjId_PSAProduct);

                                        let updatedProducts;
                                        if (productIndex !== -1) {
                                          updatedProducts = item.List_ScoreProducts.filter(p => p?.objId_Product !== product?.ObjId_PSAProduct);
                                        } else {
                                          updatedProducts = [...(item.List_ScoreProducts || []), {
                                            ...product,
                                            List_ProductTool: product?.ToolSelected_List
                                              ? product.ToolSelected_List.map(tool => ({
                                                ObjId_Tool: tool?.IntegrationId,
                                                ToolIcon: tool?.ToolIcon,
                                                ToolName: tool?.IntegrationName
                                              }))
                                              : [],
                                            objId_Product: product.ObjId_PSAProduct
                                          }];
                                        }

                                        return {
                                          ...item,
                                          List_ScoreProducts: updatedProducts
                                        };
                                      }
                                      return item;
                                    });
                                    setServiceComponents(updated_data);

                                    setChanged(true);

                                    setServicePageErrors((prev) => ({
                                      ...prev,
                                      selectedListError: false,
                                    }));
                                    let updated_product_list = [...productsList];
                                    let Index = updated_product_list.findIndex(
                                      (item) =>
                                        item?.ObjId_PSAProduct ===
                                        product?.ObjId_PSAProduct
                                    );
                                    if (Index > -1) {
                                      updated_product_list.splice(Index, 1);
                                    } else {
                                      updated_product_list.push({ ...product });
                                    }

                                    setProductsList([
                                      ...updated_product_list,
                                    ]);
                                  }

                                  e.target
                                    .closest(".select-product-or-labour-dropdown")
                                    .click();
                                }}
                              >
                                <div>
                                  <div className={` f-500 subclass ${serviceComponents?.find(obj => obj?.ContributionName === contributionName)?.List_ScoreProducts.some(p => p?.objId_Product == product?.ObjId_PSAProduct) ?
                                    "txt-blue" : "f-grey"
                                    }`}>
                                    {/* {product?.IntegrationId > 0 && (
                                      <img
                                        src={integrationUtils.getImagePath(product?.IntegrationId, product?.ToolIcon)}
                                        alt=""
                                        className="rounded-full mr-1"
                                        width={13}
                                        height={13}
                                        style={{}}
                                      />
                                    )} */}
                                    <div className="d-flex align-items-center">
                                      {
                                        product?.ToolSelected_List?.length > 0 &&
                                        <MultiplyIcons
                                          icons={product?.ToolSelected_List}
                                          nameKey={'IntegrationName'}
                                          idKey={'IntegrationId'}
                                          iconKey={'ToolIcon'}
                                        />
                                      }
                                      <span className={`${product?.ToolSelected_List?.length > 0 && 'ml-2'}`}
                                        dangerouslySetInnerHTML={{
                                          __html: HGHighlightText(
                                            product?.ProductName,
                                            searchStr
                                          ),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex f-darkgrey f-400 f-12 align-items-center">

                                    {product?.CurrencyCode_Selected?.CurrencySymbol && (
                                      <div>{`${product?.CurrencyCode_Selected?.CurrencySymbol} `}</div>
                                    )}
                                    {product?.Cost && (
                                      <div className="mr-1">{`${addZerows(product?.Cost?.toString())} `}</div>
                                    )}
                                    {product?.SnapToUnit_Text && (
                                      <div className="mr-1">{`/${product?.SnapToUnit_Text} `}</div>
                                    )}
                                    {product?.BillingPeriod_Text && (
                                      <div>{`/${product?.BillingPeriod_Text} `}</div>
                                    )}
                                  </div>
                                </div>
                                {product?.IsMissingDetails && (
                                  <div className="d-flex align-items-center f-darkgrey f-500 f-12">
                                    {" "}
                                    <img
                                      width={16}
                                      alt=""
                                      className="mr-1"
                                      src="/images/msp/grey-alert.svg"
                                    />
                                    <div>Missing Details</div>
                                  </div>
                                )}
                              </div>
                            )
                          })
                        }
                      </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                        No data yet
                      </div>
                    }
                  </div>
                </div> : selectedTab?.id == 1 ? <div>
                  <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                    Labours
                  </div>
                  <div>
                    {
                      labourData?.filter((labour) =>
                        labour.LabourName.toLowerCase().includes(searchStr.toLowerCase()))?.length > 0 ? <div>
                        {
                          labourData?.filter((labour) =>
                            labour.LabourName.toLowerCase().includes(searchStr.toLowerCase()))?.map((labour) => {
                              return (
                                <div
                                  className="bg-hover-grey p-2 mx-1 mb-1 radius-4 pointer"
                                  onClick={(e) => {
                                    // const updated_data = serviceComponents?.map(item => {
                                    //   if (item.ContributionName == contributionName) {
                                    //     return {
                                    //       ...item,
                                    //       List_ScoreProducts: [...(item.List_ScoreProducts || []), labour],
                                    //     };
                                    //   }
                                    //   return item;
                                    // });
                                    // setServiceComponents(updated_data);

                                    const updated_data = serviceComponents?.map(item => {
                                      if (item.ContributionName === contributionName) {
                                        const productIndex = item.List_ScoreProducts?.findIndex(p => p?.objId_SecurityPackLabour === labour?.objId_SecurityPackLabour);

                                        let updatedProducts;
                                        if (productIndex !== -1) {
                                          updatedProducts = item.List_ScoreProducts.filter(p => p?.objId_SecurityPackLabour !== labour?.objId_SecurityPackLabour);
                                        } else {
                                          updatedProducts = [...(item.List_ScoreProducts || []), {
                                            ...labour,
                                            ProductName: labour.LabourName,
                                            ...(!labour.List_ProductTool && {
                                              List_ProductTool: [
                                                { ObjId_Tool: labour.objId_SecurityPackLabour, ToolIcon: null, ToolName: labour.LabourName }
                                              ]
                                            }),
                                            objId_Product: labour.objId_SecurityPackLabour
                                          }];
                                        }

                                        return {
                                          ...item,
                                          List_ScoreProducts: updatedProducts,
                                        };
                                      }
                                      return item;
                                    });
                                    setServiceComponents(updated_data);

                                    // const updated_data = serviceComponents?.map(item => {
                                    //   if (item.ContributionName === contributionName) {
                                    //     const isPresent = item.List_ScoreProducts?.some(product => product.id === labour.id);

                                    //     return {
                                    //       ...item,
                                    //       List_ScoreProducts: isPresent
                                    //         ? item.List_ScoreProducts.filter(product => product.id !== labour.id)
                                    //         : [...(item.List_ScoreProducts || []), labour],
                                    //     };
                                    //   }
                                    //   return item;
                                    // });

                                    // setServiceComponents(updated_data);
                                    // setUpdateModal(true);

                                    e.target
                                      .closest(".select-product-or-labour-dropdown")
                                      .click();
                                  }}
                                >
                                  <div className={` f-500 subclass ${serviceComponents?.find(obj => obj?.ContributionName === contributionName)?.List_ScoreProducts.some(p => p?.objId_SecurityPackLabour === labour?.objId_SecurityPackLabour) ?
                                    "txt-blue" : "f-grey"
                                    }`}>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          labour?.LabourName,
                                          searchStr
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div className="f-400 f-12 f-darkgrey">
                                    {labour?.NoOfHours} {labour?.Frequency_Text} • {labour?.CurrencyCode_Selected
                                      ? labour?.CurrencyCode_Selected
                                        ?.CurrencySymbol
                                      : userSelectedCurrency?.CurrencySymbol} {addZerows((labour?.Total?.toString()))}
                                  </div>
                                </div>
                              )
                            })
                        }
                      </div> : <div className="my-3">
                        <div className="d-flex align-items-center justify-content-center f-400 f-12 f-darkgrey">No labour costs</div>
                        <div className="d-flex align-items-center justify-content-center f-400 f-12 f-darkgrey">Add labour costs at the bottom</div>
                      </div>
                    }
                  </div>
                </div> : <div>
                  <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                    Tools in your stack
                  </div>

                  <div>
                    {
                      securityStackTools?.length > 0 ? <div>
                        {
                          securityStackTools?.map((integration) => {
                            return (
                              <div className="d-flex align-items-center justify-content-between w-100 bg-hover-grey p-2 hover-on-show-parent">
                                <div className="d-flex align-items-center">
                                  <IntegrationIcon integrationIconId={integration?.IntegrationId} toolIcon={integration?.ToolIcon} passedClassName={'mr-2'} />

                                  {/* <img
                                    alt=""
                                    width={16}
                                    height={16}
                                    className="mr-2 rounded-full"
                                    src={integration?.ToolIcon == 'generic' ? '/images/attack-surface/generic2.svg' : integrationUtils.getImagePath(integration?.IntegrationId, integration?.ToolIcon)}
                                  /> */}
                                  <div className={`f-500 f-12 line-height-18 letter-space-2
                          ${serviceComponents?.find(obj => obj?.ContributionName === contributionName)?.List_ScoreProducts.some(p => p?.IntegrationId === integration?.IntegrationId) ?
                                      "txt-blue" : "f-grey"
                                    }`}>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          integration?.IntegrationName,
                                          searchStr
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="hover-on-show-child pointer"
                                  onClick={() => {
                                    setShowChooseProductModal(true);
                                    setSelectedContributionName(contributionName);
                                    setSelectedTool(integration);
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/attack-surface/add-tool-icon.svg"
                                    className="mr-2"
                                    width={11}
                                    height={11}
                                  />
                                  <span className="f-grey f-14" style={{ fontWeight: 500 }}>
                                    Add
                                  </span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                        {searchStr != "" ? `No results for "${searchStr}"` : 'No tools yet'}
                      </div>
                    }
                  </div>

                  <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2">
                    All {contributionName} tools
                  </div>

                  <div>
                    {
                      allTools?.length > 0 ? <div>
                        {
                          allTools?.map((integration) => {
                            return (
                              <div className="d-flex align-items-center justify-content-between w-100 bg-hover-grey p-2 hover-on-show-parent">
                                <div className="d-flex align-items-center">
                                  <IntegrationIcon integrationIconId={integration?.IntegrationId} toolIcon={integration?.ToolIcon} passedClassName={'mr-2'} />
                                  {/* <img
                                    alt=""
                                    width={16}
                                    height={16}
                                    className="mr-2 rounded-full"
                                    src={integration?.ToolIcon == 'generic' ? '/images/attack-surface/generic2.svg' : integrationUtils.getImagePath(integration?.IntegrationId, integration?.ToolIcon)}
                                  /> */}
                                  <div className={`f-500 f-12 line-height-18 letter-space-2
                          ${serviceComponents?.find(obj => obj?.ContributionName === contributionName)?.List_ScoreProducts.some(p => p?.IntegrationId === integration?.IntegrationId) ?
                                      "txt-blue" : "f-grey"
                                    }`}>                            <span
                                      dangerouslySetInnerHTML={{
                                        __html: HGHighlightText(
                                          integration?.IntegrationName,
                                          searchStr
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="hover-on-show-child pointer"
                                  onClick={(e) => {
                                    const updated_data = serviceComponents?.map(item => {
                                      if (item.ContributionName === contributionName) {
                                        const productIndex = item.List_ScoreProducts?.findIndex(p => p?.IntegrationId === integration?.IntegrationId);

                                        let updatedProducts;
                                        if (productIndex !== -1) {
                                          updatedProducts = item.List_ScoreProducts.filter(p => p?.IntegrationId !== integration?.IntegrationId);
                                        } else {
                                          updatedProducts = [...(item.List_ScoreProducts || []), {
                                            ...integration,
                                            ProductName: integration.IntegrationName,
                                            ...(!integration.List_ProductTool && {
                                              List_ProductTool: [
                                                { ObjId_Tool: integration.IntegrationId, ToolIcon: /*null*/integration?.ToolIcon, ToolName: integration.IntegrationName }
                                              ]
                                            }),
                                            objId_Product: integration.ObjId_PSAProduct
                                          }];
                                        }

                                        return {
                                          ...item,
                                          List_ScoreProducts: updatedProducts,
                                        };
                                      }
                                      return item;
                                    });

                                    setServiceComponents(updated_data);

                                    e.target
                                      .closest(".select-product-or-labour-dropdown")
                                      .click();
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/attack-surface/add-tool-icon.svg"
                                    className="mr-2"
                                    width={11}
                                    height={11}
                                  />
                                  <span className="f-grey f-14" style={{ fontWeight: 500 }}>
                                    Add
                                  </span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                        {searchStr != "" ? `No results for "${searchStr}"` : 'No tools yet'}
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          }

        </div>
      </Dropdown.Menu>
      {/* <ChooseProductModal
        show={showChooseProductModal}
        selectedTool={selectedTool}
        toolId={selectedTool?.IntegrationId}
        toolName={selectedTool?.IntegrationName}
        toolIcon={selectedTool?.ToolIcon}
        serviceComponents={serviceComponents}
        contributionName={contributionName}
        setServiceComponents={setServiceComponents}
        setChanged={setChanged}
        setServicePageErrors={setServicePageErrors}
        productsList={productsList}
        setProductsList={setProductsList}
        hideModal={() => {
          setShowChooseProductModal(false);
          setSelectedTool({});
          setSelectAfterOpen(0);
        }}
        setNestedProductModalState={setNestedProductModalState}
        selectAfterOpen={selectAfterOpen}
      /> */}

    </Dropdown >
  );
};

export default SelectProductOrLabour;
import React, { Component, Fragment, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Button,
  Container,
  Alert,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { AlertDialog } from "../Alert/Alert";
import "../../main.scss";
// import qrcode from 'qrcode';
// import speak from 'speakeasy';
import "./MFA.css";
import { APP_ROUTES, ROUTE_PATHS } from "../../constants/app.constants";

const MFA = (props) => {
  const [error, setError] = useState(false);
  const [qrImg, setQrImg] = useState(0);

  const [qrConfirmed, setQrConfirmed] = useState(false);

  // const {authToken, qrCode, authenticated} = useAuth();

  // const [accessToken, setAccessToken] = authToken;
  // const [qrData, setQrData] = qrCode;
  // const [userAuth, setUserAuth] = authenticated;

  const { authTokens, qrCode, auth } = useAuth();
  const { setAuthTokens } = useAuth();
  const [isRequestSend, setIsRequestSend] = useState(false);

  console.log(qrCode);
  let element = document.getElementById("root").firstChild;
  element.classList.add("fadeIn");
  setTimeout(() => {
    element.classList.remove("fadeIn");
  }, 1000);

  async function SubmitQR(event) {
    event.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);
    // console.log(accessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsRequestSend(true);
    //
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmQRScan`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setIsRequestSend(false);
        if (data.error) {
          setError({
            type: "danger",
            heading: "Login Attempt Failed",
            body: "The email or password is incorrect, please try again",
          });
        } else {
          setQrConfirmed(true);
        }
      })
      .catch((error) => {
        setError(
          <AlertDialog
            type="danger"
            heading="Login Attempt Failed"
            body="The email or password is incorrect, please try again"
          />
        );
      });
  }

  function clearStorage() {
    localStorage.clear();
    setAuthTokens(0);
  }

  // SubmitQR();

  // useEffect( () => {

  // }, [qrData]);

  if (auth != null && auth && authTokens) {
    console.log(auth);
    console.log("authed");

    return <Redirect to={{ pathname: APP_ROUTES.client.dashboard }} />;
  } else if (qrConfirmed && authTokens) {
    console.log("qr confirm");
    return <Redirect to={{ pathname: `${ROUTE_PATHS.auth.login}/totp` }} />;
  } else {
    return (
      <div className="loginBg" id="scan_mfa_wrapper">
        {error != false ? (
          <div className="alertDialog">
            <Alert
              variant={error.type}
              onClose={() => setError(false)}
              dismissible
            >
              <Alert.Heading>{error.heading}</Alert.Heading>
              <p>{error.body}</p>
            </Alert>
          </div>
        ) : (
          ""
        )}
        <Row className="innerLoginWrapper">
          <Col md={{ span: 4, offset: 1 }}>
            <div className="wrapperContainer paddingTopWrap">
              <Row
                className="align-items-center"
                style={{ paddingTop: "40px" }}
              >
                <Col md={12} className="text-center">
                  <div>
                    <img src="/images/logo-large.svg" className="icon"></img>
                    {/* <p style={{ paddingTop: "4em" }} className="pt-3 makingcyber">Making Cyber Simple</p> */}
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "1.5em" }}>
                <Col md={12} className="text-center pt-5 pb-4">
                  <div>
                    <h3 className="lgn_HeaderText2">Scan the barcode</h3>
                    <p className="mfa_grey pt-3">
                      Open a code generator app like Open the
                      <a
                        target="_blank"
                        className="scan_link"
                        href="https://www.google.com/landing/2step/"
                      >
                        {" "}
                        Google Authenticator App
                      </a>{" "}
                      on <br></br>
                      your phone or tablet and scan the Qr code below.
                    </p>
                    <div className="qr_code_wrapper">
                      <img
                        src={qrCode}
                        className="icon"
                        width="124px"
                        height="124px"
                      ></img>
                    </div>

                    <p className="click-continuemfa">
                      Click “Continue” once you have successfully<br></br>{" "}
                      scanned the code with your device.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row
                className="align-items-center"
                style={{ paddingTop: "0.1em" }}
              >
                <Col md={10} className="mx-auto">
                  <Form
                    className="userLoginForm"
                    onSubmit={SubmitQR}
                    autoComplete="none"
                  >
                    {/* <Form.Group controlId="loginEmail">
          
            <Form.Control type="email" placeholder="Email"  onChange={(event) => setEmail(event.target.value)} />
        </Form.Group> */}

                    {/* <Form.Group controlId="loginPassword">
          
            <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
        </Form.Group> */}
                    <Form.Group controlId="submitBtn">
                      <div>
                        <Button
                          className="form-control"
                          variant="blue"
                          size="lg"
                          type="submit"
                        >
                          {isRequestSend ? (
                            <span>
                              <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                              />{" "}
                              Continuing
                            </span>
                          ) : (
                            "Continue"
                          )}
                        </Button>
                      </div>
                    </Form.Group>
                    <div className="text-center pt-3">
                      <Link
                        to={ROUTE_PATHS.auth.login}
                        style={{ alignSelf: "flex-center" }}
                        onClick={clearStorage}
                        className="forgotPass"
                      >
                        Back
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>

              <Row className="paddingTop">
                <Col md={12} className="pt-5 text-center">
                  {/* <div>
                    <p className="bytext">
                      by
                      <img className="pl-2 " src="/images/small.svg"></img>
                    </p>
                  </div> */}
                </Col>
              </Row>

              <Row>
                <Col md={10} className="pt-4 pb-4 mx-auto ">
                  <div>
                    <p className="lgnGreyText terms">
                      By clicking “Continue” above, you acknowledge that you
                      have read and understood, and agree to M3 Networks Limited
                      &nbsp;
                      <a
                        className="lgnLinks"
                        className="lgnLinks"
                        target="_blank"
                        href="https://www.highground.io/highground-terms-and-conditions/"
                      >
                        Terms & Conditions
                      </a>
                      &nbsp; and
                      <a
                        href="https://www.highground.io/privacy_policy/"
                        target="_blank"
                        className="lgnLinks"
                      >
                        &nbsp; Privacy Policy
                      </a>
                      .
                    </p>{" "}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={7} className="imageLoginWrapper">
            <img src="/images/login.svg" />
          </Col>
        </Row>
      </div>
    );
  }
};

export default MFA;

import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";

import { GreyLoader } from "../../Theme/APILoaders";
import ContentLoader from "react-content-loader";
import { parseIntegrationName } from "./mspMappingScreens/MSPMappings";
import { HGHighlightText, viewOnlyAccess } from "../../App/App";
const MapContacts = ({
  getContactsListData,
  contactPageData,
  setContactPageData,
  updateContactData,
  updateImportStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [showContactsScreen, setShowContactsScreen] = useState(true);
  const [fetchingNewContacts, setFetchingNewContacts] = useState(false);
  const [contactSearchString, setContactSearchString] = useState("");

  const [automaticallyImportStatus, setAutomaticallyImportStatus] = useState(false)
  const [importStatus, setImportStatus] = useState(false)

  useEffect(() => {
    // if (contactPageData?.ObjectList?.length > 0) {
    // } else {
      setLoading(true);
      Promise.all([getContactsListData({
        NextPageNo: 1,
        searchString: contactSearchString,
      })])
        .then((response) => {
          setLoading(false);
          setAutomaticallyImportStatus(response?.[0]?.AutoImport)
          setImportStatus(response?.[0]?.Import)
        })
        .catch((err) => {
          setLoading(false);
        });
    // }
  }, []);
  async function getMoreContacts(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (contactPageData?.NextPage === false) return;
        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", getMoreContacts);

        setFetchingNewContacts(true);
        getContactsListData({
          NextPageNo:null,
          searchString:contactSearchString
        }).then(() => {
          setFetchingNewContacts(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.addEventListener("scroll", getMoreContacts);

    return () => {
      element.removeEventListener("scroll", getMoreContacts);
    };
  }, [contactPageData]);

  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");

  return (
    <>
      {loading ? (
        <MapContactsLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className="map-clients-screen  "
        >
          <div className="d-flex align-items-center pt-4 justify-content-between">
            <div className="d-flex align-items-center">
              <img
                alt=""
                width={42}
                height={42}
                src={`/images/attack-surface/integrations/${parseIntegrationName(
                  "importedIntegrtionId"
                )}.webp`}
                className="mr-3 radius-4"
                style={{
                  border: "1px solid #ebecf180",
                }}
              />
              <div>
                <p className="f-black f-600 f-15 m-0">Import & Map Contacts</p>
                <p className="f-grey f-14">
                  Categorise, Import and Map Contacts from your {" "}
                  {parseIntegrationName("importedIntegrtionName")}
                </p>
              </div>
            </div>
            <div className="">
              <Form.Check
                style={{
                  scale: "1.5",
                }}
                type="switch"
                id={`switchnitification1`}
                checked={importStatus}
                className={`m-0`}
                onClick={() => {}}
                disabled={viewOnly}
                onChange={(e) => {
                  updateImportStatus(1, Number(e.target.checked));
                  setImportStatus(e.target.checked);
                }}
              />
            </div>
          </div>
          {importStatus && (
            <>
              <div className="radius-8 bg-grey p-3 d-flex  align-items-start justify-content-between my-3">
                <div className="d-flex align-items-start">
                  <img
                    alt=""
                    src="/images/msp/msp-info-icon.svg"
                    className="mr-2 mt-1"
                  />
                  <div>
                    Importing contacts makes it quicker and easier to create
                    user accounts for your clients. Don't worry, they won't
                    receive any notifications until you invite them.
                  </div>
                </div>
                <img
                  alt=""
                  className="pointer d-none"
                  src="/images/small-cross-icon.svg"
                />
              </div>
              <div className="mt-3 mb-2 f-500">Auto Import Settings</div>
              <div className="d-flex align-items-center justify-content-between bg-grey radius-8 p-3">
                <div className="f-black f-500">
                  Automatically import new contact types and associated contacts
                </div>
                <div className="">
                  <Form.Check
                    style={{
                      scale: "1.5",
                    }}
                    type="switch"
                    id={`switchnitification`}
                    checked={automaticallyImportStatus}
                    className={`m-0`}
                    onClick={() => {}}
                    disabled={viewOnly}
                    onChange={(e) => {
                      updateImportStatus(2, Number(e.target.checked));
                      setAutomaticallyImportStatus(e.target.checked);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between pt-5 pb-2">
                <div className="f-black f-16 f-600">
                  <span className="d-none d-sm-block">Contact Types</span>
                </div>
                <div className="mx-2 d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={contactSearchString}
                    onChange={(e) => {
                      // let value = e.target.value
                      setContactSearchString(e.target.value);
                      // getContactsListData({
                      //   NextPageNo:1,
                      //   searchString:value
                      // })
                    }}
                    onBlur={() => {}}
                    className={`border-none bg-tranparent pl-2`}
                  />
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div className="border-parent bg-grey radius-8">
                <MapContactsBlock
                  contactPageData={contactPageData}
                  setContactPageData={setContactPageData}
                  contactSearchString={contactSearchString ?? ""}
                  updateContactData={updateContactData}
                />
              </div>
              {fetchingNewContacts && (
                <div className="d-flex align-items-center f-darkgrey">
                  <GreyLoader width={12} />
                  Loading...
                </div>
              )}
            </>
          )}

          <div className="p-3"></div>
        </div>
      )}
    </>
  );
};

export default MapContacts;

export const MapContactsBlock = ({
  contactPageData,
  setContactPageData,
  contactSearchString,
  updateContactData
}) => {
  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");
  return (
    <>
      
      {/* Header */}
      <div className="border-item d-flex align-items-center justify-content-between">
      <div className="f-12 f-500 f-grey py-2 pl-4 pr-5 integration-block d-flex align-items-center justify-content-between">
          <div> {parseIntegrationName("importedIntegrtionName")}</div>
          <div> {contactPageData?.TotalCount}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 highground-block">HighGround</div>
      </div>
      {/* Firts row */}
      <div>
        {contactPageData?.ObjectList?.filter((item) =>
          item?.name?.toLowerCase().includes(contactSearchString?.toLowerCase())
        )?.map((contact, contactIndex) => {
          return (
            <div className="border-item d-flex align-items-center justify-content-between contacts-wrapper">
              <div
                className="integration-block d-flex align-items-center justify-content-between pl-4 py-3 pr-3 break-word pre-wrap"
                onClick={() => {}}
              >
                <div className="d-flex align-items-center justify-content-between hover-on-show-parent position-relative">
                {contact?.state === "Not_Selected" && (
                  <span className="blue-dot" />
                  )}
                <div
                  className="d-flex align-items-center f-500 f-black"
                  title={contact?.name}
                >
                  <img
                    src="/images/msp/msp-user-icon.svg"
                    alt=""
                    className="mr-2"
                  />

      
                  <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(contact?.name, 25), contactSearchString) }} />
                          
                
                </div>
              </div>
                <div className="d-flex">
                  {contact?.state === "Ignored" && (
                    <img
                      src="/images/msp/integrations/map-client-cancel-icon.svg"
                      alt=""
                      className="mr-2 pointer"
                      onClick={()=>{
                        if (viewOnly) return;
                        updateContactData([{
                          id:contact?.id,
                          state:"Provisional_Import",
                          ParentObjId:0
                        }])
                        let contact_page_data = { ...contactPageData };
                        let _memberIndex = contact_page_data.ObjectList.findIndex(
                          (item) => item.id === contact.id
                        );
                        contact_page_data.ObjectList[_memberIndex].state = "Provisional_Import";
                        contact_page_data.ObjectList[_memberIndex].ParentObjId = 0;
                        setContactPageData(contact_page_data);
                      }}
                    />
                  )}
                  {contact?.state === "Imported" && (
                    <img
                      // src="/images/msp/right-blue-mark.svg"
                      src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {contact?.state === "Provisional_Import" && (
                    <img
                      src="/images/msp/right-blue-mark.svg"
                      // src="/images/msp/twoway-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {contact?.state === "Auto_Imported" && (
                    <img
                      src="/images/msp/right-blue-mark.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                  {contact?.state === "Deleted" && (
                    <img
                      src="/images/msp/red-cross-circle.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                    {contact?.state === "Not_Selected" && (
                    <img
                      src="/images/msp/choose-action-icon.svg"
                      alt=""
                      className="mr-2 pointer"
                    />
                  )}
                </div>
              </div>
              <div className="highground-block">
                <MapContactsDropdown
                  contactDetail={contact}
                  onStateChange={(newState, mappingData) => {
                    updateContactData([{
                      id:contact?.id,
                      state:newState,
                      ParentObjId:mappingData?.id ?? 0
                    }])
                    let contact_page_data = { ...contactPageData };
                


                    contact_page_data.ObjectList[contactIndex].state = newState;
                    if (newState === "Ignored") {
                      contact_page_data.ObjectList[contactIndex].ParentObjId = 0;
                    } else if (newState === "Provisional_Import") {
                      contact_page_data.ObjectList[contactIndex].ParentObjId = 0;
                    }
                    setContactPageData(contact_page_data);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
//
const MapContactsDropdown = ({
  contactDetail,

  onStateChange,
}) => {
  function closeDropdown(e) {
    e.target.closest(".map-clients-dropdown").click();
  }
  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");
  return (
    <Dropdown className="map-clients-dropdown">
      <Dropdown.Toggle disabled={viewOnly} className={`d-flex align-items-center w-100 pre-wrap break-word text-align-start`}>
        {contactDetail?.state === "Ignored" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Ignore</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {contactDetail?.state === "Not_Selected" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {contactDetail?.state === "Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">
               {TrimString(contactDetail?.name, 20)}
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {contactDetail?.state === "Provisional_Import" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">
              Import "{TrimString(contactDetail?.name, 20)}"
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {contactDetail?.state === "Auto_Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {contactDetail?.state === "Deleted" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Deleted</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {/* // )} */}
      </Dropdown.Toggle>
      <Dropdown.Menu className="no-border-shadow-dropdown">
        <div className="mx-2  ">
          <div
            className="f-grey pointer d-flex align-items-center contextmenu-grey-item"
            onClick={(e) => {
              closeDropdown(e);

              onStateChange("Provisional_Import");
            }}
          >
            <img
              src="/images/attack-surface/right-arrow.svg"
              alt=""
              className="mr-2"
            />
            Import "{TrimString(contactDetail?.name, 20)}"
          </div>
          <div
            className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
            onClick={(e) => {
              closeDropdown(e);

              onStateChange("Ignored");
            }}
          >
            <img
              src="/images/msp/integrations/cross-icon-msp.svg"
              alt=""
              className="mr-2"
            />
            Ignore
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const MapContactsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};

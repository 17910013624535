import { Button, Dropdown } from "react-bootstrap";
import { USER_ROLES } from "../../constants/user.constants";
import { accessStateValue, getAccessStateValue, NO_ACCESS_TEXT } from "../App/App";
import AttackSurfaceTooltip from "../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { useDispatch } from "react-redux";
import { setactioncentredata } from "../../redux/actionCentre/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import UserActionsList from "./user-actions.list";
import UserDetailsButton from "./user-details.button";
import { ROUTE_PATHS } from "../../constants/app.constants";
import ActionCentreIcon from "../../Components/icons/navigation/action-centre.icon";
import './msp.scss';

const MspHeaderActionList = ({
  userDetails, setShowTemporarySupportAccessModal,
  onItemClick = () => {},
  isSidebar = false,
  isSaas = false,
}) => {
  const dispatch = useDispatch();
  return (
    <>
     {!isSaas && (
      <div
        className={`d-flex align-items-center pointer mr-3 ${isSidebar ? "p-2 contextmenu-grey-item menuItem" : "f-white "}`}
        onClick={() => {
          let btn = document.querySelector(".calendly-button");
          btn.click();
          onItemClick();
        }}
      >
        <img
          src="/images/msp/request-demo-grey-icon.svg"
          alt=""
          className="mr-2"
        />
        Live Session 
      </div>
     )}
      <AttackSurfaceTooltip
            disabled={userDetails?.Role !== USER_ROLES.MSP_MEMBER ?
              getAccessStateValue("AccessState_ActionCentre") === 99 : getAccessStateValue("AccessState_MSP_ActionCentre") === 99
            }
            bottomLeft={true}
            topLeft={false}
            content={<span>{NO_ACCESS_TEXT}</span>}
          >
      <Button
        className={`d-flex align-items-center ${isSidebar ? "contextmenu-grey-item menuItem w-100 text-align-start" : "msp-grey-btn mr-1"}`}
        onClick={() => {
          if ((userDetails?.Role !== USER_ROLES.MSP_MEMBER ? accessStateValue("AccessState_ActionCentre") > 3 : accessStateValue("AccessState_MSP_ActionCentre") > 1)) {
            dispatch(
              setactioncentredata({
                refreshCreateTaskModalModule: true,
              })
            );
            const drag = document.getElementById("create-task-modal");
            drag.style.display = "block";
            onItemClick();
          }
        }}
        variant="text"
        style={{ height: '32px' }}
      >
        <img
          src={(userDetails?.Role !== USER_ROLES.MSP_MEMBER ? accessStateValue("AccessState_ActionCentre") > 3 : accessStateValue("AccessState_MSP_ActionCentre") > 1) ? "/images/attack-surface/white-plus-icon.svg" : "/images/settings-v2/locker-grey.svg"}
          alt=""
          width={14}
          className={`${(userDetails?.Role !== USER_ROLES.MSP_MEMBER ? accessStateValue("AccessState_ActionCentre") > 3 : accessStateValue("AccessState_MSP_ActionCentre") > 1) ? "" : ''}`} 
          style={{
            marginRight: isSidebar ? "10px" : "",
          }}
        />
        {isSidebar && (
          <span className="f-white">
            Create task
          </span>
        )}
      </Button>
      </AttackSurfaceTooltip>
      <Link to={isSaas ? ROUTE_PATHS.saas.actionCentre : ROUTE_PATHS.mspv2.actionCenter}>
        <div
          className={`${isSidebar ? "p-2 contextmenu-grey-item menuItem" : "msp-grey-btn"}`}
          onClick={() => {
            // history.push();
            onItemClick();
          }}
        >
          {
            accessStateValue("AccessState_ActionCentre") <= 3 ?
            <img
              src="/images/settings-v2/locker-grey.svg"
              alt=""
              width={14}
              className="mr-2"
            /> : isSidebar ? (
              <ActionCentreIcon className="mr-2" />
            ) : null
          }
          Action Centre
        </div>
      </Link>
      <div
        style={{ marginLeft: "" }}
        className={`sideBarDropdown supportDropdown  header-logo-dropdown ${isSidebar ? "d-none" : ""}`}
      >
        <Dropdown>
          <Dropdown.Toggle>
            {userDetails.Avatar == "No URL" ? (
              <ImageWithInitials
                width={31}
                background="purple"
                initials={userDetails?.Initials}
              />
            ) : (
              <img
                alt=""
                src={userDetails?.Avatar}
                className="thumbnail-pic"
              />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <UserDetailsButton userDetails={userDetails} />
            </Dropdown.Item>
            <hr className="m-0" />
            <div className="p-1" />
            <UserActionsList 
              setShowTemporarySupportAccessModal={setShowTemporarySupportAccessModal}
              userDetails={userDetails} 
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}

export default MspHeaderActionList;

import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setuser } from "../../../redux/userState/actions";
import { getAPIData, postRequest } from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useAuth } from "../../context/Auth";
import { useDb } from "../../context/db";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import TestNotificationModal from "../activeSessions/TestNotificationModal";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import InvitationModal from "./InvitationModal";
import "./members.scss";
import UserPageLoader from "./UserPageLoader";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
import { ROUTE_PATHS } from "../../../constants/app.constants";

const User = () => {
  const userDetails = useSelector((state) => state.user?.userDetail);
  const userEmail = useSelector((state) => state?.user?.userDetail?.UserName);
  const history = useHistory();
  const dispatch = useDispatch();
  let { user } = useParams();
  const { setdbObject } = useDb();
  const { authTokens, setAuthTokens, setUserType } = useAuth();
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");
  const [myprofile, setmyProfile] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [invitationModal, setInvitationModal] = useState(false);

  const [notificationModal, setNotificationModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [deviceList, setDeviceList] = useState(5);
  const [otherRole, setOtherRole] = useState("");
  const [editOtherRole, setEditOtherRole] = useState(false);
  const [otherRoleLoading, setOtherRoleLoading] = useState(false);
  // const IsAdmin= userEmail

  const statusList = [
    { id: 12, category: "Enabled" },
    { id: 112, category: "Disabled" },
  ];
  const hideNotificationModal = () => {
    setNotificationModal(false);
  };
  const hideDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const hideInvitationModal = () => {
    setInvitationModal(false);
  };

  useEffect(() => {
    setMainPageLoading(true);
    getUserData();
  }, []);

  async function getUserData() {
    await getAPIData("101", authTokens).then((response) => {
      setMainPageLoading(false);
      if (response.mr.Success) {
        let data = response?.UserAndDevices.filter(
          (item) => item?.UserDetails?.objId_UserSettings == user
        )[0];
        if (userEmail == data?.UserDetails?.UserName) {
          setmyProfile(true);
        }
        setUserData(data);
      }
    });
  }

  useEffect(() => {
    if (userData) {
      setRole(userData?.UserDetails?.Role);
      setStatus(
        statusList.filter((item) => item.category == userData?.User_Status)[0]
      );
    }
  }, [userData]);

  // remove device

  async function RemoveDevice(enum1) {
    await getAPIData(105, authTokens, {
      optionEnum1: enum1,
    })
      .then((result) => {
        if (result.Success) {
          TrackUser("Device deleted");

          CallToast("Device removed successfully", false, false, "v2style");
        } else {
          CallToast("Unable to update", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // reset data

  async function resetData(id) {
    const response = await getAPIData(id, authTokens, {
      optionEnum1: userData?.UserDetails?.objId_UserSettings,
    });

    if (response.Success) {
      // CallToast(response?.Message, false, false);
      if(Number(id) === 107){
        CallToast("Password reset email sent", false, false, "v2style");}
    } else {
      CallToast("Something went wrong", false);
    }
  }
  function changeMemberRole(role, email, otherole = "", element = "") {
    postRequest(authTokens, "UpdateMemberUserRole", {
      Role: role,
      OtherRole: otherole,
      MemberUsername: email,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (element) {
        element.click();
      }
      setOtherRoleLoading(false);
      if (response?.Success) {
        CallToast("Role updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  function ChangeRole(role, roleType = "", element = "") {
    return new Promise((resolve, reject) => {
      postRequest(authTokens, "UpdateUserRole", {
        Role: role,
        OtherRole: roleType,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }).then((response) => {
        if (element) {
          element.click();
        }
        setOtherRoleLoading(false);
        if (response.Success) {
          resolve(response);
          dispatch(setuser({ Role: role }));
          CallToast("Role updated successfully", false, false, "v2style");
          window.localStorage.setItem(
            "userType",
            JSON.stringify(response?.EntityId)
          );
          updateAccessKeyInLocalStorage(response?.Message)
          
          setUserType(response?.EntityId);
          if (role == "CEO") {
            makeAPICall(0);
          } else if (role == "CTO") {
            makeAPICall(1);
          } else if (role == "CFO") {
            makeAPICall(2);
          } else {
            makeAPICall(0);
          }
        } else {
          reject(response);
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    });
  }
  async function makeAPICall(num) {
    let data = await getAPIData(num, authTokens, {
      // OptionEnum1: "99",
      // OptionEnum2: "99",
    });
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setdbObject();
      data = await getAPIData(num, data.authToken);
    } else {
      setdbObject(data);
      // console.log(data?.UserDetail);
      dispatch(setuser(data?.UserDetail));
    }
  }

  // user Delete modal
  const DeleteUserModal = ({ show, hideModal }) => {
    async function handleDeleteUser(deviceID) {
      await getAPIData("109", authTokens, {
        optionEnum1: deviceID,
      }).then((response) => {
        hideModal();
        if (response.Success) {
          CallToast(
            " <img src='/images/settings-v2/bin.svg' class='mr-2'/>&nbsp;&nbspAccount successfully deleted",
            false,
            false
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
    return (
      <Modal
        animation={false}
        // show={true}
        show={show}
        dialogClassName="delete-user-modal"
        className=" "
        aria-labelledby="delete-user-modal"
        centered
        onHide={hideModal}
        //   scrollable
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="permission mb-3 mt-3">
                Are you sure you want to delete this account?
              </div>
            </Col>
            <Col xs={12}>
              <div className="d-flex align-items-center">
                {userData?.UserDetails?.showInitials ? (
                  <ImageWithInitials
                    initials={userData.UserDetails.Initials}
                    width={40}
                    background={"purple"}
                  />
                ) : (
                  <img
                    alt=""
                    src={userData?.UserDetails?.ImageURL}
                    className="user-avatar "
                    style={{
                      width: "40px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                )}

                <div className="ml-3">
                  <p className="user-name m-0">
                    {userData?.UserDetails?.FullName}
                  </p>
                  <p className="user-email m-0">
                    {userData?.UserDetails?.UserName}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button className="cancel-btn" onClick={hideModal}>
              No, Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={() => {
                handleDeleteUser(userData?.UserDetails?.objId_UserSettings);
              }}
            >
              Yes, delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  async function handleUserStatus(deviceID) {
    await getAPIData("106", authTokens, {
      optionEnum1: user,
    }).then((response) => {
      if (response.Success) {
        CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <Fragment>
      {mainPageLoading && <UserPageLoader />}
      <div className={`user-wrapper  ${mainPageLoading ? "d-none" : ""}  `}>
        <div className="d-flex justify-content-start align-items-center">
          <img
            alt=""
            src="/images/leftarrow.png"
            className="mr-4 pointer"
            onClick={() => history.push(ROUTE_PATHS.saas.settings.team)}
          />
          <div className="user-title">{userData?.UserDetails?.FullName} </div>
        </div>
        <div className="d-flex align-items-center my-4">
          {userData?.UserDetails?.showInitials ? (
            <ImageWithInitials
              initials={userData.UserDetails.Initials}
              width={40}
              background={"purple"}
            />
          ) : (
            <img
              alt=""
              src={userData?.UserDetails?.ImageURL}
              className="user-avatar "
              style={{
                width: "40px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          )}

          <div className="ml-3">
            <p className="userName m-0">{userData?.UserDetails?.FullName}</p>
            <p className="userEmail m-0">{userData?.UserDetails?.UserName}</p>
          </div>
        </div>
        <hr className="m-0 mb-3 p-0" />

        {/* Devices details */}

        <p className="topic-heading">Devices</p>
        <div className="devices">
          {!myprofile && (
            <Row>
              <div className="devices-wrapper w-100">
                {userData?.Device_Count > 0 && (
                  <>
                    {userData?.MobileList.slice(0, deviceList).map(
                      (device, index) => {
                        return (
                          <>
                            <Col xs={12}>
                              <div className="d-flex justify-content-between device-wrap">
                                <div className="d-flex align-items-center">
                                  <img
                                    alt=""
                                    src="/images/mobile.svg"
                                    className="mr-3"
                                  />
                                  <div className="device-name">
                                    {device.Detail}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="device-date">
                                    {device.DateAdded}
                                  </div>
                                  <Dropdown className="device-dropdown black-dropdown">
                                    <Dropdown.Toggle>
                                      <img
                                        alt=""
                                        src="/images/horizontal-dots.svg"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div>
                                        <Row>
                                          <Col
                                            xs={12}
                                            onClick={(e) => {
                                              setNotificationModal(true);
                                              e.target
                                                .closest(".device-dropdown")
                                                .click();
                                            }}
                                          >
                                            <div className="notification-btn bg-hover-grey-light">
                                              Send Notifications
                                            </div>
                                          </Col>
                                          <hr
                                            style={{
                                              width: "70%",
                                              margin: "auto",
                                            }}
                                          />
                                          <Col
                                            xs={12}
                                            onClick={(e) => {
                                              RemoveDevice(device?.DeviceId);

                                              e.target
                                                .closest(".device-dropdown")
                                                .click();
                                            }}
                                          >
                                            <div className="remove-btn delete-btn bg-hover-grey-light">
                                              Remove
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </Col>
                            {index !==
                              userData?.MobileList.slice(0, deviceList).length -
                                1 && <hr className="m-0 p-0" />}
                          </>
                        );
                      }
                    )}
                  </>
                )}
              </div>
              {userData?.Device_Count == 0 && (
                <div className="w-100 p-3 devices-wrapper f-500 f-16 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img alt="" src="/images/mobile.svg" className="mr-3" />

                    <div>{userData?.MobileList[0]?.Detail}</div>
                  </div>

                  <div className="device-date">NA</div>
                </div>
              )}
            </Row>
          )}

          {myprofile && (
            <Row>
              <Col xs={12} className="devices-wrapper">
                <div className="d-flex justify-content-between device-wrap">
                  <div className="device-name">Go to Your Active Sessions</div>
                  <img
                    alt=""
                    src="/images/settings-v2/goto.svg"
                    className="goto-btn"
                    onClick={() => {
                      history.push(ROUTE_PATHS.saas.settings.activeSessions);
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>

        {/* show more button */}

        {userData?.Device_Count > 0 && !myprofile && (
          <div
            className={`show-more-btn  mt-3  ${
              deviceList > 5 ? "arrow-down" : "arrow-up"
            } `}
            onClick={() => {
              if (deviceList > 5) {
                setDeviceList(5);
              } else {
                setDeviceList(1000);
              }
            }}
          >
            {deviceList > 5 ? "Show less" : "Show more"}
          </div>
        )}

        {/* User Settings */}
        <p className="topic-heading mt-4">User Settings</p>
        <div className="user-settings">
          <Row>
            <div className="settings-wrapper w-100">
              {!myprofile && (
                <>
                  <Col xs={12}>
                    <div className="d-flex justify-content-between settings-wrap">
                      <div className="d-flex align-items-center title">
                        User Status
                      </div>
                      <div className="d-flex align-items-center">
                        <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={status}
                          placeholder="Choose..."
                          //  showid={true}
                          onClick={(id, val) => {
                            handleUserStatus();
                            setStatus({ id, category: val });
                          }}
                          data={statusList}
                        />
                      </div>
                    </div>
                  </Col>
                  <hr className=" m-0" />
                  <Col xs={12}>
                    <div className="d-flex justify-content-between settings-wrap">
                      <div className="d-flex align-items-center title">
                        User Role
                      </div>
                      <div className="d-flex align-items-center">
                        <Dropdown
                          className="hg-white-dropdown dropdown-w215"
                          onToggle={() => {
                            setEditOtherRole(false);
                            setOtherRole("");
                            setOtherRoleLoading(false);
                          }}
                        >
                          <Dropdown.Toggle className="custom-dropdown-toggle f-black-imp">
                            {role}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              className="p-2 pl-3 my-1 pointer f-black f-500"
                              onClick={(e) => {
                                if (
                                  userData?.UserDetails?.UserName ==
                                  userDetails?.UserName
                                ) {
                                  ChangeRole("CEO");
                                } else {
                                  changeMemberRole(
                                    "CEO",
                                    userData?.UserDetails?.UserName
                                  );
                                }

                                setRole("CEO");
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              CEO
                              {role == "CEO" && (
                                <img
                                  alt=""
                                  src="/images/settings-v2/black-tick.svg"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                    paddingTop: "3px",
                                  }}
                                />
                              )}
                            </div>
                            <div
                              className="p-2 pl-3 my-1 pointer f-black f-500"
                              onClick={(e) => {
                                if (
                                  userData?.UserDetails?.UserName ==
                                  userDetails?.UserName
                                ) {
                                  ChangeRole("CFO");
                                } else {
                                  changeMemberRole(
                                    "CFO",
                                    userData?.UserDetails?.UserName
                                  );
                                }
                                setRole("CFO");
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              CFO
                              {role == "CFO" && (
                                <img
                                  alt=""
                                  src="/images/settings-v2/black-tick.svg"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                    paddingTop: "3px",
                                  }}
                                />
                              )}
                            </div>
                            <div
                              className="p-2 pl-3 my-1 pointer f-black f-500"
                              onClick={(e) => {
                                if (
                                  userData?.UserDetails?.UserName ==
                                  userDetails?.UserName
                                ) {
                                  ChangeRole("CTO");
                                } else {
                                  changeMemberRole(
                                    "CTO",
                                    userData?.UserDetails?.UserName
                                  );
                                }
                                setRole("CTO");
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              CTO
                              {role == "CTO" && (
                                <img
                                  alt=""
                                  src="/images/settings-v2/black-tick.svg"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                    paddingTop: "3px",
                                  }}
                                />
                              )}
                            </div>
                            <div className="p-2 pl-3 my-1 pointer f-black f-500">
                              {editOtherRole ? (
                                <div className="d-flex algn-items-center">
                                  <div className="d-flex align-items-center other-role-editor">
                                    <input
                                      style={{
                                        maxWidth: "124px",
                                      }}
                                      value={otherRole}
                                      onChange={(e) =>
                                        setOtherRole(e.target.value)
                                      }
                                    />
                                    <Button
                                      className="d-flex align-items-center"
                                      onClick={(e) => {
                                        if (otherRole == "") return;
                                        setOtherRoleLoading(true);
                                        var element =
                                          e.target.closest(
                                            ".hg-white-dropdown"
                                          );
                                        if (
                                          userData?.UserDetails?.UserName ==
                                          userDetails?.UserName
                                        ) {
                                          ChangeRole(
                                            "Other",
                                            otherRole,
                                            element
                                          );
                                        } else {
                                          changeMemberRole(
                                            "CEO",
                                            userData?.UserDetails?.UserName,
                                            otherRole,
                                            element
                                          );
                                        }
                                        setRole("Other");
                                      }}
                                    >
                                      {otherRoleLoading && (
                                        <WhiteLoader width={11} />
                                      )}
                                      <div>Save</div>
                                    </Button>
                                  </div>
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-cross.svg"
                                    className="mx-1 pointer"
                                    onClick={() => {
                                      setEditOtherRole(false);
                                      setOtherRole("");
                                    }}
                                  />
                                </div>
                              ) : (
                                <div onClick={() => setEditOtherRole(true)}>
                                  Other
                                  {role == "Other" && (
                                    <img
                                      alt=""
                                      src="/images/settings-v2/black-tick.svg"
                                      style={{
                                        float: "right",
                                        marginRight: "10px",
                                        paddingTop: "3px",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>

                        {/* <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={role}
                          placeholder="Choose..."
                          //  showid={true}
                          onClick={(id, val) => {
                            if (
                              userData?.UserDetails?.UserName ==
                              userDetails?.UserName
                            ) {
                              // ChangeAdminRole(val);
                            }

                            setRole({ id, category: val });
                          }}
                          data={roleList}
                        /> */}
                      </div>
                    </div>
                  </Col>
                  <hr className=" m-0" />
                  <Col xs={12}>
                    <div className="d-flex justify-content-between settings-wrap">
                      <div className="">
                        <p className="title m-0">Reset MFA</p>
                        <p className="subtitle m-0">
                        Reset MFA for this user
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          className="reset-btn"
                          // onClick={() => setInvitationModal(true)}
                          onClick={() => {
                            resetData("108");
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <hr className=" m-0" />
                  <Col xs={12}>
                    <div className="d-flex justify-content-between settings-wrap">
                      <div className="">
                        <p className="title m-0"> Reset Password</p>
                        <p className="subtitle m-0">
                          We will send email to your email with reset
                          instruction
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          className="reset-btn"
                          onClick={() => {
                            resetData("107");
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <hr className=" m-0" />
                  <Col xs={12}>
                    <div className="d-flex justify-content-between settings-wrap">
                      <div className="">
                        <p className="title m-0"> Delete user</p>
                        <p className="subtitle m-0">
                          This action can't be undone
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          className="delete-user-btn"
                          onClick={() => setDeleteUserModal(true)}
                        >
                          Delete user
                        </Button>
                      </div>
                    </div>
                  </Col>
                </>
              )}

              {myprofile && (
                <Col xs={12}>
                  <div className="d-flex justify-content-between settings-wrap">
                    <div className="title">Go to My Profile Settings</div>
                    <img
                      alt=""
                      src="/images/settings-v2/goto.svg"
                      className="goto-btn"
                      onClick={() => {
                        history.push(ROUTE_PATHS.saas.settings.myProfile);
                      }}
                    />
                  </div>
                </Col>
              )}
            </div>
          </Row>
        </div>
        <p className="date-status m-0 mt-3">Added: {userData?.User_Added}</p>
      </div>
      <DeleteUserModal show={deleteUserModal} hideModal={hideDeleteUserModal} />
      <InvitationModal show={invitationModal} hideModal={hideInvitationModal} />
      <TestNotificationModal
        show={notificationModal}
        hideModal={hideNotificationModal}
      />
    </Fragment>
  );
};

export default User;

import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { GreyLoader, WhiteLoader } from "../../../Theme/APILoaders";
import "../mspIntegrations.scss";
import Loader from "../../../Common/loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import OAuth2Login from "react-simple-oauth2-login/dist/OAuth2Login";
import { TrackUser } from "../../../../Utils/SegmentFunctions";
import MspConnectModalLoader from "./MspConnectModalLoader";
import "./mspSpinner.scss";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { useHistory } from "react-router-dom";
import { screenMappingEnums } from "../mspMappingScreens/MSPMappings";
import { updateAccessKeyInLocalStorage } from "../../../../Utils/CommonFunctions";
import integrationUtils from "../../../../Utils/integration.utils";
import { ROUTE_PATHS } from "../../../../constants/app.constants";

const MspConnectIntegrationModal = ({
  show,
  hideModal,
  integrationId = 607325,
  isEdit,
  refreshData,
}) => {
  const { authTokens } = useAuth();
  const history= useHistory()
  const [integraionType, setIntegraionType] = useState("");
  const [integrationData, setIntegrationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [keyStrings, setKeyStrings] = useState([]);
  const [keyStringToSend, setKeyStringToSend] = useState({});

  
  
  const [testing, setTesting] = useState(true);



  const [integrationError, setIntegrationError] = useState("");

  const [completeIntegration, setCompleteIntegration] = useState(false);
  const [OAuthEnabled, setOAuthEnabled] = useState({});

  const [importRequestList, setImportRequestList] = useState([]);

  // homepage
  // testingPage
  const [page, setPage] = useState("testingPage");
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState();

  useEffect(() => {
    if (show) {
      setLoading(true);
      getData();
      setIntegrationError("");
      setTesting(false);
      setPage("homepage");
      setChecked(false);
      setCheckedError("");
    } else {
      setOAuthEnabled({});
    }
    localStorage.removeItem("mappingState")
  }, [show]);

  async function getData( ) {
    await getAPIData("65", authTokens, {
      OptionEnum1: integrationId,
      OptionEnum2: isEdit ? 2 : 1,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          // redirect to importing page if the integration is already connected
          if (response?.IntegrationStatus?.PSAImportFinished && !isEdit) {
            setTesting(false);
            let importListData = localStorage.getItem("importListData");
            if (importListData) {
              let headers = JSON.parse(importListData);
              history.push(
                `${ROUTE_PATHS.mspv2.mappings}?type=${
                  screenMappingEnums[headers[0]?.IntegrationRequestEnum]
                }`
              );
            }
            return;
          }

          // Setup for OAuth2 integration
          setIntegrationData(response);
          if (response?.IntegrationDetail?.IsOAuth2 === true) {
            let _extraParameters = {};
            if (response?.oAuth2_Params?.AuthURL?.split("?")?.length > 1) {
              let allParams =
                response?.oAuth2_Params?.AuthURL?.split("?")?.[1]?.split("&");

              for (let i = 0; i < allParams.length; i++) {
                _extraParameters[allParams[i]?.split("=")[0]] =
                  allParams[i]?.split("=")[1];
              }
            }

            setOAuthEnabled({
              [integrationId]: {
                AUTH_URL: response?.oAuth2_Params?.AuthURL?.split("?")?.[0],
                TOKEN_URL: response?.oAuth2_Params?.TokenURL,
                SCOPE: response?.oAuth2_Params?.Scope,
                CLIENT_ID: response?.oAuth2_Params?.ClientId,
                CLIENT_SECRET: response?.oAuth2_Params?.Secret,
                extraParams_: { ..._extraParameters },
              },
            });

            return;
          }

          // Extract Import Request List

          let import_request_list = response?.ImportRequestList?.sort(
            (a, b) =>
              a.IntegrationRequestImportOrder - b.IntegrationRequestImportOrder
          )?.map((importItem) => ({
            ...importItem,
            loading: null,
            error: null,
            success: null,
          }));
          setImportRequestList(import_request_list);

          // SETUP TO STORE ALL THE IMPORT LIST FOR RENDERING THE UI ONCE THE IMPORTING FINISHED---------------
          if (response?.ImportRequestList?.length > 0) {
            let render_import_list = response?.ImportRequestList?.filter(
              (item) => item?.ShowUINavigationTab === true
            )
              .sort(
                (a, b) =>
                  a.IntegrationRequestImportOrder -
                  b.IntegrationRequestImportOrder
              )
              .map((importItem, importIndex) => {
                return {
                  ...importItem,
                };
              });

            localStorage.setItem(
              "importListData",
              JSON.stringify(render_import_list)
            );
            localStorage.setItem(
              "importedIntegrtionId",
              JSON.stringify(integrationId)
            );
            localStorage.setItem(
              "importedIntegrtionName",
              JSON.stringify(response?.IntegrationDetail?.IntegrationName)
            );
          }
          // SETUP TO STORE ALL THE IMPORT LIST FOR RENDERING THE UI ONCE THE IMPORTING FINISHED----------------

          let ServerTypeAutoFillInIntegrationtype =
            response?.ConnectionStrings?.filter(
              (_item) => _item?.Label?.toLowerCase() === "server type"
            )?.[0]?.ServerType?.[0];
          if (ServerTypeAutoFillInIntegrationtype) {
            setIntegraionType(ServerTypeAutoFillInIntegrationtype);
          }
          setKeyStrings(response?.ConnectionStrings ?? []);
          setKeyStringToSend(
            Object.assign(
              {},
              ...response?.ConnectionStrings?.map((item, index) => ({
                [`KeyString${item?.KeyNumber}`]: item?.KeyValue,
              }))
            )
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function connectIntegration(testCreateValue) {
     
    
      setTesting(true)

    
    let keystringsTosend = keyStrings
      ?.filter((filteritem) => filteritem?.ServerType?.includes(integraionType))
      ?.map((item, index) => ({
        [`KeyString${item?.KeyNumber}`]: item?.KeyValue,
      }));

    const keyPairs = keystringsTosend.reduce((result, item) => {
      const key = Object.keys(item)[0];
      const value = item[key];
      result[key] = value;
      return result;
    }, {});

    await postData(authTokens, "SubmitIntegrationKeys", {
      ...keyPairs,
      IntegrationId: integrationId,
      TestOrCreate: testCreateValue,
    }).then((response) => {
      setCompleteIntegration(false);
      if (response?.mr?.Success) {
        // testing phase
        if (testCreateValue === 0) {
           
          connectIntegration(1)
        }
        //completing Integration
        if (testCreateValue === 1) {
          if(isEdit){
            hideModal()
          }
          setTesting(false)
          getData()
          if(response?.mr?.Message?.length >50){
            updateAccessKeyInLocalStorage(response?.mr?.Message)
          
          }
          // callImportDataApis(0)
          // hideModal();
          CallToast(
            "Integration connected successfully",
            false,
            false,
            "v2style"
          );
          TrackUser("Tool connected", {
            type: integrationData?.IntegrationDetail?.IntegrationName,
          });
          if (refreshData) refreshData();
        }
      } else {
        setPage("homepage");
        setIntegrationError(response?.mr?.Message);
        setTesting(false)

        let import_request_list = importRequestList.sort(
          (a, b) =>
            a.IntegrationRequestImportOrder - b.IntegrationRequestImportOrder
        )?.map((importItem) => ({
          ...importItem,
          loading: null,
          error: null,
          success: null,
        }));
        setImportRequestList(import_request_list);

      }
    });
  }
   

  async function callImportDataApis(requestOrder) {
    if(!show) return
    if (requestOrder === importRequestList?.length) {
      setTesting(false)
      let importListData = localStorage.getItem("importListData");
    if (importListData) {
      let headers =JSON.parse(importListData);
      history.push(
        `${ROUTE_PATHS.mspv2.mappings}?type=${
          screenMappingEnums[headers[0]?.IntegrationRequestEnum]
        }`
      );
    }
      return;
    }
    let updated_import_list = [...importRequestList];
    updated_import_list[requestOrder].loading = true;
    setImportRequestList(updated_import_list);
    await postData(authTokens, "IntegrationImportData", {
      IntegrationRequestId:
        importRequestList[requestOrder].IntegrationRequestId,
    }).then((response) => {
      if (response?.Success) {
        let updated_import_list = [...importRequestList];
        updated_import_list[requestOrder].loading = null;
        updated_import_list[requestOrder].success = true;
        setImportRequestList(updated_import_list);
      } else {
        let updated_import_list = [...importRequestList];
        updated_import_list[requestOrder].loading = null;
        updated_import_list[requestOrder].success = null;
        updated_import_list[requestOrder].error = true;
        setImportRequestList(updated_import_list);
      }
      if (requestOrder < importRequestList?.length) {
        callImportDataApis(requestOrder + 1);
      }
    });
  }

  async function checkValidation() {
    let key_strings = [...keyStrings];
    let errors = 0;
    if (key_strings?.length > 0) {
      for (let i = 0; i < key_strings.length; i++) {
        if (
          key_strings[i].KeyValue === null ||
          key_strings[i].KeyValue === ""
        ) {
          key_strings[i].error = true;
          errors = errors + 1;
        }
      }
      setKeyStrings(key_strings);
      return errors;
    } else {
      return 0;
    }
  }

  function completedImportList() {
    return importRequestList?.filter(
      (item) =>
         item?.error !== null || item?.success !== null
    )?.length;
  }

  function totalImportList(){
    return importRequestList?.length
  }

  function startedImporting() {
    let filter_list = importRequestList?.filter(
      (item) =>
        item?.loading !== null || item?.error !== null || item?.success !== null
    )?.length;
    if (filter_list === 0) {
      return false;
    } else {
      return true;
    }
  }

  function workedOnImportList() {
    return importRequestList?.filter(
      (item) =>
      item?.loading !== null || item?.error !== null || item?.success !== null
    )?.length;
  }

  function importedDataPercentage(){
return Math.ceil((completedImportList() / totalImportList()) * 100)
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="  connect-integration-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Body className="p-3 hide-scrollbar position-relative">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            {page === "homepage" && (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="d-flex align-items-center">
                    <img
                      width={48}
                      height={48}
                      alt=""
                      className="mt-2 mb-3 border-ebc radius-8"
                      src={integrationUtils.getImagePath(integrationData?.IntegrationDetail?.IntegrationId, integrationData?.IntegrationDetail?.ToolIcon)}
                    />
                    <img
                      width={16}
                      height={16}
                      alt=""
                      className="mx-3"
                      src={`/images/attack-surface/double-arrow.svg`}
                    />
                    <img
                      width={48}
                      height={48}
                      alt=""
                      className="mt-2 mb-3"
                      src="/images/attack-surface/integration-hg-logo.svg"
                    />
                  </div>
                </div>
                <div className="text-center f-16 f-500 f-black mb-2">
                  Connect {integrationData?.IntegrationDetail?.IntegrationName}{" "}
                  to HighGround
                </div>
                <div className="text-center f-14 f-darkgrey mb-3">
                  Sync your clients, define their security capabilties and
                  manage them towards better security with HighGround.
                </div>

                {!OAuthEnabled[integrationId] && (
                  <div className="mx-2">
                    {keyStrings?.filter((filteritem) =>
                      filteritem?.ServerType?.includes(integraionType)
                    )?.length > 0 &&
                      keyStrings
                        ?.filter((filteritem) =>
                          filteritem?.ServerType?.includes(integraionType)
                        )
                        ?.map((item, index) => {
                          let dropDownListItems = item?.KeyValueList?.map(
                            (itemData, idx) => ({ Id: idx, cat: itemData })
                          );
 
                            function disableDropdown(){
                              const serverTypeIndex = keyStrings?.findIndex(
                                (filteritem) =>
                                  filteritem?.Label?.toLowerCase() ===
                                  "server type"
                              );

                              if (
                                keyStrings[serverTypeIndex]?.KeyValue ===
                                  "OnPremise" &&
                                item?.KeyNumber === 3
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }




                          return (
                            <div
                              className={`msp-cat-dropdown w-100 highlight-input radius-4 company-id-dropdown mb-2 ${
                                item.error ? "input-error" : ""
                              }`}
                            >
                              <CompanySettingsDropdown
                               
                                DropDownMenuClassName={"w-100"}
                                selectionArrowAtLeft
                                greyTextAndArrowTheme
                                trimStringItem={42}
                                fieldId={"Id"}
                                fieldValue={"cat"}
                                selected={
                                  dropDownListItems?.filter(
                                    (selectedItem, seletdIndex) =>
                                      selectedItem?.cat === item.KeyValue
                                  )?.[0] ?? null
                                }
                                placeholder="Choose..."
                                disabled={item?.KeyValueList?.length === 0 || disableDropdown()}
                                onClick={(id, val) => {
                                  let updated_data = [...keyStrings];
                                  updated_data[index].KeyValue = val;
                                  updated_data[index].error = false;
                                  setKeyStrings(updated_data);
                                }}
                                data={dropDownListItems ?? []}
                              >
                                <div
                                  className={`d-flex align-items-center bg-grey radius-4 p-2 highlight-inpu  t w-100 `}
                                >
                                  <div className="d-flex align-items-center f-darkgrey">
                                    {item?.Label}:
                                  </div>
                                  <input
                                  autoComplete="off"
                                    // autoComplete="new-password"
                                    type={`${
                                      item?.ShowText ? "text" : "password"
                                    }`}
                                    className="border-none bg-transparent px-1 flex-grow-1"
                                    name={`KeyString${item?.KeyNumber}`}
                                    value={item.KeyValue ?? ""}
                                    placeholder=""
                                    onChange={(e) => {
                                      if(item?.Label?.toLowerCase() ===
                                      "server type"
                                    //    ||
                                    // (item?.KeyValueList?.length > 0 &&
                                    //   !item?.ServerType?.includes(
                                    //     "OnPremise"
                                    //   ))
                                      
                                      ){
return
                                      }
                                      let updated_data = [...keyStrings];

                                      updated_data[index].KeyValue =
                                        e.target.value;
                                      updated_data[index].error = false;
                                      setKeyStrings(updated_data);
                                    }}
                                    disabled={false
                                      // item?.Label?.toLowerCase() ===
                                      //   "server type" ||
                                      // (item?.KeyValueList?.length > 0 &&
                                      //   !item?.ServerType?.includes(
                                      //     "OnPremise"
                                      //   ))
                                    }
                                  />
                                  {index === 0 && (
                                    <img
                                      alt=""
                                      src="/images/chevron-down.svg"
                                      className="mx- 2"
                                    />
                                  )}
                                </div>
                              </CompanySettingsDropdown>
                            </div>
                            // <div
                            //   className={`d-flex align-items-center bg-grey radius-4 p-2 highlight-input mb-2  ${
                            //     item.error ? "input-error" : ""
                            //   }`}
                            // >
                            //   <div className="d-flex align-items-center f-darkgrey">
                            //     {item?.Label}:
                            //   </div>
                            //   <input
                            //     autoComplete="new-password"
                            //     type={`${item?.ShowText ? "text" : "password"}`}
                            //     className="border-none bg-transparent px-1 flex-grow-1"
                            //     name={`KeyString${item?.KeyNumber}`}
                            //     value={item.KeyValue ?? ""}
                            //     placeholder=""
                            //     onChange={(e) => {
                            //       setIntegrationError("");
                            //       let updated_data = [...keyStrings];
                            //       // updated_data[index].error = false;
                            //       updated_data[index].KeyValue =
                            //         window.sanitizeHGtext(e.target.value);
                            //       updated_data[index].error = false;
                            //       setKeyStrings(updated_data);

                            //       setKeyStringToSend({
                            //         ...keyStringToSend,
                            //         [e.target.name]: window.sanitizeHGtext(
                            //           e.target.value
                            //         ),
                            //       });
                            //     }}
                            //   />
                            // </div>
                          );
                        })}
                  </div>
                )}

                {integrationError && (
                  <div className="f-danger my-1 pl-2">{integrationError}</div>
                )}

                <div className="mx-2 mt-2">
                  {!OAuthEnabled[integrationId] && (
                    <div className="d-flex align-items-start mb-2">
                      <div className="mr-3 mb-3">
                        <Form.Group
                          controlId={"check2"}
                          className="checkboxstyle mb-1 blue-checkbox  round-checkbox"
                        >
                          <Form.Check
                            type="checkbox"
                            name={"check"}
                            label=""
                            checked={checked}
                            onChange={(e) => {
                              setChecked(e.target.checked);
                              setCheckedError("");
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="f-black f-12">
                        I confirm I am the account owner and have legal
                        permission to connect this product to HighGround.
                      </div>
                    </div>
                  )}
                </div>

                {checkedError && (
                  <div className="f-danger my-1 pl-2">
                    Please check above to proceed
                  </div>
                )}
              </>
            )}

            {/* Testing page */}
            {page === "testingPage" && (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className={`d-flex align-items-center ${
                      testing ? "" : "combined_images"
                    }`}
                  >
                    <img
                      width={48}
                      height={48}
                      alt=""
                      className="mt-2 mb-3 border-ebc radius-8 left-image"
                      src={integrationUtils.getImagePath(integrationData?.IntegrationDetail?.IntegrationId, integrationData?.IntegrationDetail?.ToolIcon)}
                    />
                    {/* <img
                      width={16}
                      height={16}
                      alt=""
                      className="mx-3"
                      src={`/images/attack-surface/double-arrow.svg`}
                    /> */}
                    <div>
                      {testing ? (
                        <>
                          <img
                            alt=""
                            src="/images/msp/grey-refresh-icon.svg"
                            className="rotate-full mx-3"
                          />
                          {/* <img
                            alt=""
                            src="/images/msp/msp-connect-error.svg"
                            className=" mx-3"
                          /> */}
                        </>
                      ) : (
                        <div>
                          <img
                            alt=""
                            width={17}
                            height={17}
                            src="/images/attack-surface/suggestionSuccess.svg"
                            className="middle-image"
                          />
                        </div>
                      )}
                    </div>
                    <img
                      width={48}
                      height={48}
                      alt=""
                      className="mt-2 mb-3 right-image"
                      src="/images/attack-surface/integration-hg-logo.svg"
                    />
                  </div>
                </div>
                <div className="text-center f-16 f-500 f-black mb-2 d-flex align-items-center justify-content-center">
                  <div className="f-16 f-500">
                    {testing ? "Connection test..." : "Importing data.."}
                    {/* {startedImporting() === false && "Connecting..."} */}

                    {/* {startedImporting() === true &&
                      importedDataPercentage() < 100 &&
                      `Importing ${importRequestList[
                        workedOnImportList() - 1
                      ]?.IntegrationRequestTitle?.toLowerCase()}...`}
                    {startedImporting() === true &&
                      importedDataPercentage() === 100 &&
                      `Finishing...`} */}
                  </div>

                  <div className="f-16 f-darkgrey">
                    {/* {completedImportList() > 0 &&
                      `${importedDataPercentage()}%`} */}
                  </div>
                </div>
                <div className=" mb-2">
                  {/* <progress
                    min="0"
                    value={completedImportList()}
                    max={totalImportList()}
                  /> */}
                </div>

                <div className="mx-2">
                  {testing ? (
                    <div
                      className={` d-flex align-items-center  ${"f-black"}  `}
                      style={{ height: "25px" }}
                    >
                      <div className="mr-3">
                        <MspConnectModalLoader />
                      </div>
                      Connection test...
                    </div>
                  ) : (
                    <div
                      className={` d-flex align-items-center  ${"f-black"} `}
                      style={{ height: "25px" }}
                    >
                      <img
                        alt=""
                        src="/images/attack-surface/integration-green-tick.svg"
                        className=""
                        style={{ marginRight: "0.65rem" }}
                      />
                      Connection tested
                    </div>
                  )}

                  {/* {startedImporting() === true && (
                    <div>
                      {importRequestList?.map((importItem, ImportIndex) => {
                        return (
                          <div
                            className={` d-flex align-items-center
                          
                          ${
                            importItem?.loading === null &&
                            importItem?.success === null &&
                            importItem?.error === null &&
                            "f-grey pl-4"
                          }

                          ${
                            (importItem?.loading === true ||
                              importItem?.success === true) &&
                            "f-black"
                          }

                          ${importItem?.error === true && "f-danger"}
                          
                          `}
                            style={{ height: "25px" }}
                          >
                            {importItem?.loading && (
                              <div className="mr-3">
                                <MspConnectModalLoader />
                              </div>
                            )}
                            {importItem?.success && (
                              <img
                                alt=""
                                src="/images/attack-surface/integration-green-tick.svg"
                                className=""
                                style={{ marginRight: "0.65rem" }}
                              />
                            )}
                            {importItem?.error && (
                              <img
                                alt=""
                                src="/images/msp/error-icon.svg"
                                className=""
                                style={{ marginRight: "0.65rem" }}
                              />
                            )}
                            Importing{" "}
                            {importItem?.IntegrationRequestTitle?.toLowerCase()}
                          </div>
                        );
                      })}
                    </div>
                  )} */}
                </div>
                <div className="mx-2 d-none">
                  {testing === false ? (
                    <div
                      className={` d-flex align-items-center  ${"f-black"}  `}
                      style={{ height: "25px" }}
                    >
                      <div className="mr-3">
                        <MspConnectModalLoader />
                      </div>
                      Importing data..
                    </div>
                  ) : (
                    <div
                      className={` d-flex align-items-center  ${"f-darkgrey"} `}
                      style={{ height: "25px" }}
                    >
                      <img
                        alt=""
                        src="/images/attack-surface/integration-green-tick.svg"
                        className=""
                        style={{ marginRight: "0.65rem", visibility: "hidden" }}
                      />
                      Importing data
                    </div>
                  )}
                </div>



                {testing ? (
          <div className="f-darkgrey position-absolute bottom-0"
          style={{
            left: '50%',
            width: '90%',
            transform: 'translate(-50%, -10%)',
          }}
          
          >
            Please wait whilst we test your connection.
          </div>
        ) : (
          <div className="f-darkgrey text-center position-absolute bottom-0"
          style={{
            left: '50%',
            width: '90%',
            transform: 'translate(-50%, -10%)',
          }}>
            Your integration is running in the background and may take up to 1hr
            to complete. 
            <br />
            <br />
            
             We will email you when its finished with a link
             We will email you when its finished with a link
            for you to complete the integration configuration.
          </div>
        )}

                 
              </>
            )}
          </>
        )}
      </Modal.Body>

      {page === "homepage" && !loading && (
        <Modal.Footer>
          <div className="footer d-flex justify-content-end">
            <Button className="hg-cancel-btn f-500" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              className={`${"hg-blue-btn "} f-500 ml-2`}
              onClick={async () => {
                if (!checked) {
                  setCheckedError("xxx");
                  return;
                }
                let _errors = await checkValidation();

                if (_errors === 0) {
                  if(isEdit){
                    
                    // setTesting(true);
                    connectIntegration(1);
                  }else{
                    setPage("testingPage");
                    connectIntegration(0);

                  }
                }
              }}
              disabled={false}
            >
              {testing?    "Please wait..."  :"Continue"}
            </Button>
          </div>
        </Modal.Footer>
      )}
      {page === "testingPage" && (
        <Modal.Footer>
          <div className="footer d-flex justify-content-end">
            <Button
              className="hg-cancel-btn f-500 w-100"
              onClick={() => {
                hideModal();
              }}
            >
              {/* {importedDataPercentage() === 100 ? "Close" : "Cancel"} */}
              Close
            </Button>
            {/* <Button
              className={`${
                testing ? " hg-red-btn" : "hg-blue-btn"
              } f-500 ml-2`}
              onClick={() => {
                if (testing) {
                } else {
                  setCompleteIntegration(true);
                  connectIntegration(1);
                }
              }}
              disabled={false}
            >
              {completeIntegration && <WhiteLoader width={12} />}{" "}
              {testing ? "Stop testing" : "Complete Integration"}
            </Button> */}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MspConnectIntegrationModal;

const OAuthEnabled__ = {
  3417718: {
    AUTH_URL: "https://login.xero.com/identity/connect/authorize",
    TOKEN_URL: "https://identity.xero.com/connect/token",
    SCOPE: "accounting.contacts.read offline_access",
    CLIENT_ID: process.env.REACT_APP_XERO_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_XERO_CLIENT_SECRET,
  },
  3450675: {
    AUTH_URL: "https://www.sageone.com/oauth2/auth/central",
    TOKEN_URL: "https://oauth.accounting.sage.com/token",
    SCOPE: "full_access",
    CLIENT_ID: process.env.REACT_APP_SAGEONE_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_SAGEONE_CLIENT_SECRET,
  },
  3448056: {
    AUTH_URL: "https://appcenter.intuit.com/connect/oauth2",
    TOKEN_URL: "https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer",
    SCOPE: "com.intuit.quickbooks.accounting",
    CLIENT_ID: "ABfcdAqhFVFUMcuAj0etxZLGZGJ2mOhnAReDCFMVrvZFQUpTuM",
    CLIENT_SECRET: "Vo60TchtN95IOSWsGWXm1SHMdRpXzrQC6Z9xzGOg",
  },
  3544486: {
    AUTH_URL: "https://id.sage.com/authorize",
    TOKEN_URL: "https://id.sage.com/oauth/token",
    SCOPE: "openid profile email offline_access",
    CLIENT_ID: "9uesjyYoAtcyBtiJSNC0v09yfRxkktJI",
    CLIENT_SECRET:
      "8ITgoZbEpcWKMZJOWu_prkC6RSPpLlcFSzROI6MzQzkZtI9q7AgTrGFNnayEcwxo",
    extraParams_: { audience: "s200ukipd/sage200" },
  },
  2895436: {
    AUTH_URL:
      "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize",
    TOKEN_URL:
      "https://login.microsoftonline.com/organizations/oauth2/v2.0/token",
    SCOPE:
      "https://api.securitycenter.microsoft.com/Machine.Read https://api.securitycenter.microsoft.com/Alert.Read https://api.securitycenter.microsoft.com/User.Read.All offline_access",
    CLIENT_ID: "16345520-f397-4909-9117-1faa305421dd",
    CLIENT_SECRET: "JUQ8Q~RFkYOl7xptTpTQyFae7HDk141fLhTwocm8",
    // extraParams_: { audience: "s200ukipd/sage200" },
  },
  3200904: {
    AUTH_URL: "https://accounts.zoho.eu/oauth/v2/auth",
    TOKEN_URL: "https://accounts.zoho.eu/oauth/v2/token",
    SCOPE:
      "DesktopCentralCloud.Common.READ,DesktopCentralCloud.PatchMgmt.READ,DesktopCentralCloud.Inventory.READ",
    CLIENT_ID: "1000.WK6W20ZC8X7SAXI0AZA8NFEAF00VJS",
    CLIENT_SECRET: "5787e6b72614d7782bcd30cf694c67fbb1ac6e0bd2",
    extraParams_: { access_type: "offline", prompt: "consent" },
  },
  3284592: {
    AUTH_URL:
      "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize",
    TOKEN_URL:
      "https://login.microsoftonline.com/organizations/oauth2/v2.0/token",
    SCOPE:
      "https://graph.microsoft.com/User.Read User.Read.All Directory.Read.All DeviceManagementManagedDevices.Read.All offline_access",
    CLIENT_ID: "16345520-f397-4909-9117-1faa305421dd",
    CLIENT_SECRET: "JUQ8Q~RFkYOl7xptTpTQyFae7HDk141fLhTwocm8",
    // extraParams_: { access_type: "offline",prompt:"consent"},
  },
};

const OAuthIntegration = ({
  integrationId,
  refreshData,
  hideModal,
  OAuthEnabled,
}) => {
  const { authTokens } = useAuth();
  const [Oauthing, setOauthing] = useState(false);

  return (
    <div
      className="integrationv2-oauth2-btn"
      onClick={(e) => {
        e.stopPropagation();
        setOauthing(true);
      }}
    >
      <OAuth2Login
        state="12345678"
        extraParams={OAuthEnabled[integrationId].extraParams_}
        authorizationUrl={OAuthEnabled[integrationId].AUTH_URL}
        responseType="code"
        scope={OAuthEnabled[integrationId].SCOPE}
        clientId={OAuthEnabled[integrationId].CLIENT_ID}
        clientSecret={OAuthEnabled[integrationId].CLIENT_SECRET}
        redirectUri={`${window.location.origin}/`}
        onSuccess={(response) => {
          //   {code: 'J5ayMuZGoVX-Sk-Ko2RpRQZXqTQGeGcKoNA6Nzwv_9w', scope: 'accounting.contacts.read offline_access'}
          var realmId = {};
          if (response?.realmId) {
            realmId["KeyString2"] = response?.realmId;
          }

          let key0 = response?.code;
          let key1 = `${window.location.origin}/`;

          postData(authTokens, "SubmitIntegrationKeys", {
            IntegrationId: integrationId,
            KeyString0: key0,
            KeyString1: key1,
            TestOrCreate: 3,
            ...realmId,
          })
            .then((response) => {
              setOauthing(false);
              if (response?.mr?.Success) {
                if (refreshData) refreshData();
                if (hideModal) hideModal();
                CallToast(
                  "Integration connected successfully",
                  false,
                  false,
                  "v2style"
                );
              }
            })
            .catch((res) => {
              console.log("fail", res);
              setOauthing(false);
              CallToast("Error, Please try again", false, false, "v2style");
            });
        }}
        onFailure={(res) => {
          console.log("fail", res);
          setOauthing(false);
          CallToast("Error, Please try again", false, false, "v2style");
        }}
      >
        {Oauthing ? (
          <>
            <WhiteLoader width={13} />
            Please wait...
          </>
        ) : (
          "Authenticate to connect"
        )}
      </OAuth2Login>
    </div>
  );
};

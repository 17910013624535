import React, { useState, useEffect } from "react";
import { Button, Dropdown, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  CallToast,
  CustomRightSideToastForSendReport,
  RightSideToast,
} from "../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "./companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./rewardsDashoardDropdown.scss";
import BigModal from "./rewards/BigModal";
import { getAPIData, getRequestOptions } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { TrimString } from "../../Utils/StringTrimmer";
import RewardsDashboardDropdownLoader from "./RewardsDashboardDropdownLoader";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";
import { ROUTE_PATHS } from "../../constants/app.constants";

const RewardsDashboardDropdown = ({ children }) => {
  const { authTokens } = useAuth();
  const history = useHistory();
  //mainPage
  //socialPost
  //sendReport
  //invite
  const [page, setPage] = useState("invite");
  const [post, setPost] = useState({ id: 12, category: "CEO" });
  const [inviteEmail, setInviteEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [bigModal, setBigModal] = useState(false);
  const [rewardId, setRewardId] = useState("");
  const [proServicesList, setProServicesList] = useState([]);
  const [changesHappen, setChangesHappen] = useState(false);
  const [role, setRole] = useState("");
  const [postData, setPostData] = useState({});
  const [rewardData, setRewardData] = useState({});
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  // get data
  useEffect(() => {
    if (showModal) {
      setMainPageLoading(true);
      getAPIData("130", authTokens, { optionEnum1: 1 }).then((response) => {
        if (response.mr.Success) {
          setMainPageLoading(false);
          // setPageData(response);
          // setRefferalList(response?.Referral_List);
          setReferralLink(response?.Referral_Link);
          setProServicesList(response?.ProServices_List);
        }
      });
    }
  }, [changesHappen, showModal, authTokens]);

  // Preview Report Setup
  async function previewReport() {
    setPreviewLoader(true);
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/PreviewReportPDF?emailReportTypeInt=1`,
      getRequestOptions(authTokens, "GET")
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data?.mr?.Success === true) {
          TrackUser("Preview report clicked");
          setPreviewLoader(false);
          window.open(data.FileURL);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setPreviewLoader(false);
        }
      })
      .catch((error) => {
        setPreviewLoader(false);
        CallToast("Something went wrong", false, false, "v2style");
        console.log(error);
      });
  }

  // Send Invite Setup
  async function handleSendInvite(inviteEmail) {
    setSending(true);

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendReferral`,
      getRequestOptions(authTokens, "POST", {
        EmailAddress: inviteEmail,
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
    ).then((response) => response.json());
    if (data.mr.Success) {
      updateAccessKeyInLocalStorage(data?.mr?.Message)
    
      setSending(false);
      setSent(true);
      setInviteEmail("");
      var icon = document.querySelector(".gifts-icon");
      setTimeout(() => {
        setSent(false);
        setSendDisabled(true);
        if (icon) {
          icon.click();
        }
        RightSideToast(
          5,
          `Thanks for sending invitation, we have credited to your Pro Services account.`
        );
      }, 1500);
      setChangesHappen(!changesHappen);
      TrackUser("Invite Sent");

      // CallToast("Invitation sent successfully", false, false, "v2style");
    } else {
      CallToast("Invite failed", false, false, "v2style");
    }
  }

  // Send Report Setup

  async function SendReport(inviteEmail) {
    setSending(true);
    const accessKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : "null";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var inputs = new URLSearchParams();
    inputs.append("RewardId", rewardId);
    inputs.append("RewardType", 0);
    inputs.append("EmailAddress", inviteEmail);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CompleteReward?accessKey=${accessKey}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.mr?.Success) {
          updateAccessKeyInLocalStorage(data?.mr?.Message)
        
         
          setSending(false);
          setSent(true);
          setChangesHappen(!changesHappen);
          // CallToast("Report sent successfully", false, false, "v2style");
          var icon = document.querySelector(".gifts-icon");
          setTimeout(() => {
            setSent(false);
            setSendDisabled(true);
            if (icon) {
              icon.click();
            }
          }, 1500);
          setTimeout(() => {
            // RightSideToast(
            //   rewardData.AwardAmount,
            //   `Thanks for sending report to ${rewardData?.Name.slice(
            //     -3
            //     )}, we have credited to your Pro Services account.`
            //     );
            CustomRightSideToastForSendReport(
              "+ £5 Congratulations!",
              `Thanks for sending report to ${rewardData?.Name.slice(-3)}, we have credited to your Pro Services account.`,
              `Add to your Team`,
              () => {
                history.push(`${ROUTE_PATHS.saas.settings.team}?openTeamModal=true`);
              }
            );
            setInviteEmail("");
          }, 1500);
        } else {
          setSending(false);
          CallToast("Something went wrong", false, false, "v2style");
          setSent(false);
        }
      });
  }
  // Share Post Setup

  async function ShareReport() {
    const accessKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : "null";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var inputs = new URLSearchParams();
    inputs.append("RewardId", rewardId);
    inputs.append("RewardType", "Share Post");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CompleteReward?accessKey=${accessKey}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.mr?.Success) {
          updateAccessKeyInLocalStorage(data?.mr?.Message)
           
          setTimeout(() => {
            RightSideToast(
              data.RewardAmount,
              `Thanks for sharing post, we have credited to your Pro Services account.`
            );
          }, 500);
        }
      });
  }

  const hideBigModal = () => {
    setBigModal(false);
  };

  const ImageType = {
    Sent: "/images/settings-v2/send-report.svg",
    Complete: "/images/settings-v2/checklist.svg",
    Shared: "/images/settings-v2/earth-reward.svg",
  };
  return (
    <div>
      <Dropdown
        onToggle={(e) => {
          setPage("mainPage");
          setSendDisabled(true);
          setInviteEmail("");
          setShowModal(e);
        }}
        className="rewards-dropdown-main-dashboard"
      >
        <Dropdown.Toggle className="rewards-toggle">{children}</Dropdown.Toggle>

        <Dropdown.Menu className="rewards-v2-dropdown">
          {page == "mainPage" && (
            <div className="main-page-wrapper d-flex flex-column justify-content-between">
              <div style={{ padding: "9px" }}>
                <div
                  className="d-flex align-items-center justify-content-between invite-wrapper"
                  onClick={() => setPage("invite")}
                >
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      src="/images/settings-v2/invite-user-rewards.svg"
                      className="mr-2"
                    />
                    <div className="title">Invite Members</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="reward-text mr-2">+ £5</div>
                    <img alt="" src="/images/settings-v2/chevron-right.svg" />
                  </div>
                </div>
                <div
                  className="hide-scrollbar"
                  style={{ height: "280px", overflowY: "scroll" }}
                >
                  {mainPageLoading && <RewardsDashboardDropdownLoader />}
                  {!mainPageLoading &&
                    proServicesList?.map((content, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between invite-wrapper"
                          onClick={() => {
                            if (content?.Complete) return;
                            if (content?.Status == "Shared") {
                              setPage("socialPost");
                              setRewardId(content?.RewardId);
                              setPostData(content);
                            }
                            if (content?.Status == "Sent") {
                              setRole(content?.Name?.slice(-3));
                              setPage("sendReport");
                              setRewardId(content?.RewardId);
                              setRewardData(content);
                            }
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <img
                              alt=""
                              src={ImageType[content?.Status]}
                              className="mr-2"
                            />
                            <div className="title">{content?.Name}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="reward-text mr-2">
                              £{content?.AwardAmount}
                            </div>
                            <Form.Group className="round-checkbox titleCheckbox  rewards-checkbox">
                              <Form.Check
                                type="checkbox"
                                label=""
                                name={`checkbox`}
                                checked={content?.Complete}
                                onChange={(e) => e.preventDefault()}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="main-botm-wrap">
                <Button
                  className="view-rewrads-btn"
                  onClick={() => {
                    TrackUser("Rewards button clicked");
                    history.push(ROUTE_PATHS.saas.settings.rewards);
                  }}
                >
                  View Rewards
                  <img
                    alt=""
                    src="/images/settings-v2/tilt-arrow.svg"
                    className="ml-2"
                  />
                </Button>
                <div className="pointer" onClick={() => setBigModal(true)}>
                  <span
                    className="mr-2"
                    style={{ color: "#616778", fontWeight: "500" }}
                  >
                    Referral rules
                  </span>
                  <img src="/images/info.svg" />
                </div>
              </div>
            </div>
          )}

          {page == "socialPost" && (
            <div className="social-post-wrapper">
              <div className="banner-wrapper">
                <div
                  style={{
                    width: "368px",
                    height: "192px",
                    borderRadius: "8px",
                    background: "#9499AA20",
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/sharepost-banner.svg"
                    className="banner"
                  />
                </div>
                <p className="title pt-3">
                  I've just completed a Cyber Risk Assessment with HighGround
                </p>
              </div>
              <hr color="#EBEDF1" className="m-0" />
              <div className="post-footer">
                <div
                  style={{
                    color: "#9499AA",
                    fontSize: "12px",
                    margin: "10px 19px",
                  }}
                >
                  Share on
                </div>
                <div className="d-flex align-items-center justify-content-between post-btn-wrapper">
                  <Button
                    className="post-btn mr-3"
                    onClick={ShareReport}
                    target="_blank"
                    href={
                      "https://www.linkedin.com/shareArticle?mini=true&url=" +
                      window.location.origin +
                      "/signup" +
                      "&title=" +
                      postData.Name +
                      "&summary=I've just completed a Cyber Risk Assessment with CyberCare Vision&source=LinkedIn"
                    }
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/linkedin.svg"
                      className="mr-2"
                    />
                    LinkedIn
                  </Button>
                  <Button
                    className="post-btn"
                    onClick={ShareReport}
                    target="_blank"
                    href={
                      "https://twitter.com/intent/tweet?url=" +
                      window.location.origin +
                      "/sign-up" +
                      "&text=" +
                      postData.Name
                    }
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/twitter.svg"
                      className="mr-2"
                    />
                    Twitter
                  </Button>
                  <Button
                    className="post-btn ml-3"
                    onClick={ShareReport}
                    target="_blank"
                    href={
                      "https://www.facebook.com/share.php?u=https://cybercare-ui-dev.azurewebsites.net/login" +
                      window.location.origin +
                      "/signup" +
                      "&quote=" +
                      postData.Name
                    }
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/facebook.svg"
                      className="mr-2"
                    />
                    Facebook
                  </Button>
                </div>
              </div>
            </div>
          )}

          {page == "sendReport" && (
            <div className="p-3" style={{ borderBottom: "1px solid #EBEDF1" }}>
              <div
                className="mb-2"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                Send a Cyber Report to your {role}
              </div>
              <div
                className="mb-2"
                style={{
                  fontWeight: "4500",
                  fontSize: "14px",
                  color: "#9499AA",
                }}
              >
                Enter your {role}'s email below to send them your report.
              </div>
              <Button
                className="preview-btn my-2"
                onClick={() => {
                  previewReport();
                }}
              >
                Preview Report
                {previewLoader ? (
                  <Spinner
                    size="sm"
                    className="mr8px- ml-2"
                    animation="border"
                  />
                ) : (
                  <img
                    alt=""
                    src="/images/settings-v2/preview-icon.svg"
                    className="ml-2"
                  />
                )}
              </Button>
            </div>
          )}

          {page == "invite" && (
            <div className="p-3">
              <div className="title">Invite link</div>

              <div className="d-flex align-items-center">
                <div
                  className="f-black f-500 clipboard-txt"
                  style={{ height: "31px" }}
                >
                  {TrimString(
                    `${referralLink ? referralLink : "Loading..."}`,
                    32
                  )}
                </div>
                <Button
                  style={{ width: "104px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(referralLink);
                    CallToast(
                      '<img src="/images/settings-v2/copy-link.svg" className="mr-2" />&nbsp;&nbsp;Link Copied &nbsp; &nbsp;',
                      false,
                      false,
                      "v2style"
                    );
                  }}
                  className="d-flex align-items-center justify-content-center copy-link-btn"
                >
                  {" "}
                  <img
                    alt=""
                    src="/images/settings-v2/copy-link.svg"
                    className="mr-2"
                  />
                  <div>Copy link</div>
                </Button>
              </div>
            </div>
          )}

          {(page == "sendReport" || page == "invite") && (
            <div className="email-sender-wrapper">
              <div className="title mb-1">
                {page == "sendReport" ? "Enter email" : "Invite by email"}
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center email-field-wrapper ">
                  <input
                    type="email"
                    className="email-input"
                    placeholder="Enter Email"
                    value={inviteEmail}
                    onChange={(e) => {
                      const re =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                      if (!re.test(e.target.value)) {
                        setSendDisabled(true);
                      } else {
                        setSendDisabled(false);
                      }

                      setInviteEmail(e.target.value);
                    }}
                  />
                  <div
                    className=""
                    style={{
                      visibility: "hidden",
                      display: "none",
                    }}
                  >
                    <CompanySettingsDropdown
                      fieldId={"id"}
                      fieldValue={"category"}
                      selected={post}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        setPost({ id, category: val });
                      }}
                      data={[
                        { id: 12, category: "CEO" },
                        { id: 112, category: "CTO" },
                        { id: 113, category: "CFO" },
                      ]}
                    />
                  </div>
                </div>
                <Button
                  className={`d-flex align-items-center px-2 copy-link-btn ${sendDisabled ? "send-disabled" : ""
                    }  ${sending && "sending-btn"} ${sent && "sent-btn"}   `}
                  disabled={sending || sent || sendDisabled}
                  onClick={() => {
                    const re =
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (!inviteEmail || !re.test(inviteEmail)) {
                      CallToast(
                        "Please enter a valid email",
                        false,
                        false,
                        "v2style"
                      );
                      return false;
                    }
                    if (page == "invite") {
                      handleSendInvite(inviteEmail);
                    }
                    if (page == "sendReport") {
                      SendReport(inviteEmail);
                    }
                  }}
                >
                  {!sending && !sent && (
                    <div>
                      {page == "sendReport" ? "Send Report" : "Send Invite"}
                    </div>
                  )}
                  {sending && (
                    <div>
                      <span>
                        <Spinner
                          size="sm"
                          className="mr8px"
                          animation="border"
                        />{" "}
                        Sending...
                      </span>
                    </div>
                  )}
                  {sent && <div>Sent!</div>}
                </Button>
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <BigModal show={bigModal} hideModal={hideBigModal} />
    </div>
  );
};

export default RewardsDashboardDropdown;

import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import MspAttackSurface from "../MSPv2/MspAttackSurface.js/MspAttackSurface";
import { useSelector } from "react-redux";
import { getMenuItemId } from "../SideNav/SideNav";
import { NoAccessUI } from "../App/App";

const SaasTechnologySpend = () => {
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);

  const menuItemId = getMenuItemId(window.location.pathname.split('/')[1])
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;


  return (
    <>
      {
        menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_TechnologySpend === 0) ? <>
          {
            userPortalRole === 'MSP' ?
              <NoAccessUI /> : <NoAccessUI type={userPortalRole} />
          }
        </> : <>
          <div
            className="dashboard fullHeight h-100 overflow-hidden"
            style={{ padding: "0", background: "inherit" }}
          >
            <Header title="Technology Spend" />
            <div className="w-100 hide-scrollbar">
              <MspAttackSurface setClientData={() => { }} isSaas={true}/>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default SaasTechnologySpend;

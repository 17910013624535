import { useState, useEffect } from 'react';

/**
 * @returns {Object} windowSizes
 * @returns {number} windowSizes.width
 * @returns {number} windowSizes.height
 */
const useWindowSizes = () => {
  const [windowSizes, setWindowSizes] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSizes({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSizes;
}

export default useWindowSizes;

import React from "react";
import MultiplyIcons from "../../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MultiplyIcons";

export const ServiceComponentsBlock = ({
    containerStyle,
    componentsData = []
}) => {


    return (
        <div className="my-3">
            <div className="f-600 f-14 f-black mt-2">
                Security Component
            </div>
            <div className={`${containerStyle ? containerStyle : 'grey-container'} mt-3`}>
                {
                    componentsData?.length > 0 ? <div>
                        <div className="d-flex align-items-center">
                            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Component</div>
                            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Products</div>
                        </div>
                        <div>
                            {
                                componentsData?.map((componentDataItem) => {
                                    return (
                                        <div className="d-flex align-items-top">
                                            <div
                                                className="d-flex justify-content-between align-items-start mt-1 w-50"
                                            // style={{
                                            //   height: '22px'
                                            // }}
                                            >
                                                <div className="f-400 f-14 f-black line-height-22 letter-space-2">{componentDataItem?.ContributionName}</div>
                                                <img
                                                    alt=""
                                                    id=""
                                                    style={{
                                                        marginTop: '3px'
                                                    }}
                                                    className="mr-3"
                                                    width={11}
                                                    src="/images/attack-surface/right-arrow.svg"
                                                />
                                            </div>
                                            <div className="">
                                                {
                                                    componentDataItem?.List_ScoreProducts?.length > 0 ? <>
                                                        {
                                                            componentDataItem?.List_ScoreProducts?.map((productItem, productIndex) => {
                                                                return (
                                                                    <div className="d-flex align-items-center bg-greyish radius-2 p-1 mt-1 w-fit-content">
                                                                        {
                                                                            productItem?.List_ProductTool?.some(item => item?.ObjId_Tool != 0 || item?.ToolIcon !== null && item?.ToolName !== "") &&
                                                                            <MultiplyIcons
                                                                                icons={productItem?.List_ProductTool}
                                                                                nameKey={'ToolName'}
                                                                                idKey={'ObjId_Tool'}
                                                                                iconKey={'ToolIcon'}
                                                                            />
                                                                        }
                                                                        <div className="f-500 f-12 greyText2 line-height-18 letter-space-2 ml-1">
                                                                            {productItem?.ProductName}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </> : <div className="f-500 f-12 greyText2">No product selected</div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                        No data for Components yet
                    </div>
                }
            </div>
        </div>
    );
};

export default ServiceComponentsBlock;

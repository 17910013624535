import React, { useEffect, useState } from "react";
import { CallToast } from "../../../../PriceCalculator/modals/CustomToast";
import PriceBreakdownTable from "./PriceBreakdownTable";
import SeatPricingTable from "./SeatPricingTable";
import ContributionTable from "./ContributionTable";
import SummaryTable from "./SummaryTable";
import MarginTable from "./MarginTable";

export const PerSeatBlock = ({
  data,
  discount,
  switchVal,
  packageDetails,
  contribLoading,
  isMspClientView,
  showContribReset,
  contributionValue,
  switchIncludedTaxVal,
  updatePackageMappedPrice,
  setDiscount = () => { },
  setContribLoading = () => { },
  getPackageDetails = () => { },
  setShowContribReset = () => { },
  resetAssetSeatDataApi = () => { },
  updateSeatAssetDataApi = () => { },
  setIsFromAddContribTable = () => { },
  setShowMapCurrentSpendModal = () => { },
  setMapCurrentSpendPackageId = () => { },
  setMapCurrentSpendPackagePrice = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  setMapCurrentSpendPackagePriceText = () => { },
}) => {
  const [resetting, setResetting] = useState(false);
  const [priceResetting, setPriceResetting] = useState(false);

  // Dynamic Implementation
  const [seatBreakDownData, setSeatBreakDownData] = useState({});

  useEffect(() => {
    setSeatBreakDownData(data);
  }, [data, data.MSPPackagePrice]);

  function handleSave() {
    updateSeatAssetDataApi(seatBreakDownData?.MSPPackagePrice, 0)
      .then((res) => {
        getPackageDetails(seatBreakDownData?.ScenarioHeader?.objId_Scenario, true)
          .then((res) => {
          })
          .catch((err) => {
            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function handleReset() {
    setPriceResetting(true);
    resetAssetSeatDataApi(seatBreakDownData?.ScenarioHeader?.objId_Scenario, 0)
      .then((res) => {
        getPackageDetails(seatBreakDownData?.ScenarioHeader?.objId_Scenario, true)
          .then((res) => {
            setPriceResetting(false);
          })
          .catch((err) => {
            setPriceResetting(false);
            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setPriceResetting(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <div>
      <PriceBreakdownTable
        priceResetting={priceResetting}
        isMspClientView={isMspClientView}
        seatBreakDownData={seatBreakDownData}
        handleSave={handleSave}
        handleReset={handleReset}
        setSeatBreakDownData={setSeatBreakDownData}
      />

      <SeatPricingTable
        switchVal={switchVal}
        packageDetails={packageDetails}
        seatBreakDownData={seatBreakDownData}
        handleSave={handleSave}
      />

      <ContributionTable
        resetting={resetting}
        packageDetails={packageDetails}
        contribLoading={contribLoading}
        showContribReset={showContribReset}
        seatBreakDownData={seatBreakDownData}
        contributionValue={contributionValue}
        switchIncludedTaxVal={switchIncludedTaxVal}
        setResetting={setResetting}
        setContribLoading={setContribLoading}
        setShowContribReset={setShowContribReset}
        updatePackageMappedPrice={updatePackageMappedPrice}
        setIsFromAddContribTable={setIsFromAddContribTable}
        setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
        setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
        setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
        setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
        setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
      />

      <SummaryTable
        discount={discount}
        packageDetails={packageDetails}
        seatBreakDownData={seatBreakDownData}
        contributionValue={contributionValue}
        switchIncludedTaxVal={switchIncludedTaxVal}
        setDiscount={setDiscount}
        setIsFromAddContribTable={setIsFromAddContribTable}
        setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
        setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
        setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
        setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
        setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
      />

      {
        switchVal &&
        <MarginTable
          discount={discount}
          packageDetails={packageDetails}
          seatBreakDownData={seatBreakDownData}
          contributionValue={contributionValue}
        />
      }
    </div>
  );
};

export default PerSeatBlock;

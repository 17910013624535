import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/Auth";
import "./addRequest.scss";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { GreyLoader } from "../../../Theme/APILoaders";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useSelector } from "react-redux";
import CompanyTimeZoneDropDown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanyTimeZoneDropDown";
import { addZerows } from "../mspPriceCalculator";
import HighgroundDatePicker from "../../../test/TestComponent";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import useRespondTo from "../../../../hooks/respond-to.hook";
import { WindowBreakpoints } from "../../../../constants/window.constants";


const AddSellRequestModal = ({
  show,
  hideModal,
  marketplaceId = 0,
  customerId = 0,
  setIsChanges = () => { }
}) => {
  const { authTokens } = useAuth();
  const isMobile = useRespondTo(WindowBreakpoints.md);
  const [loading, setLoading] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState({});
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [message, setMessage] = useState("");
  const [quantity, setQuantity] = useState();
  const [timezone, setTimezone] = useState();
  const [selectedPricingItem, setSelectedPricingItem] = useState({})
  const [time, setTime] = useState("08:00");
  const [date, setDate] = useState();
  const [errorsList, setErrorsList] = useState({
    nameError: false,
    phonNumberError: false,
    timezoneError: false,
    dateError: false,
    quantityError: false
  });
  const companyDetails = useSelector((state) => state?.company?.companyDetail);

  const timeArray = [];
  for (let hour = 0; hour < 24; hour++) {
    const formattedTime = hour.toString().padStart(2, '0') + ':00';
    timeArray.push(formattedTime);
  }

  function clearModal() {
    setMarketplaceData({});
    setName("");
    setPhoneNumber();
    setMessage("");
    setQuantity();
    setTimezone();
    setSelectedPricingItem({})
    setTime("08:00");
    setDate();
    setErrorsList({
      nameError: false,
      phonNumberError: false,
      timezoneError: false,
      dateError: false,
      quantityError: false
    });
  }

  function formatDate(inputDate) {
    if (inputDate && inputDate !== "") {
      if (/^\d{1,2}\/\d{2}\/\d{4}$/.test(inputDate)) {
        return inputDate;
      } else {
        const parts = inputDate?.split("T");
        const datePart = parts[0];
        const [year, month, day] = datePart?.split("-");

        return `${day}/${month}/${year}`;
      }
    } else {
      return inputDate;
    }
  }

  useEffect(() => {
    getMarketplaceByIdToAddRequest();
  }, [show])

  function getMarketplaceByIdToAddRequest() {
    setLoading(true);
    getAPIData(994, authTokens, {
      optionEnum1: /*12417619*/marketplaceId
    }).then((response) => {
      if (response?.mr?.Success) {
        setMarketplaceData(response);
        setSelectedPricingItem(response?.AllMarketPlacePricing_List[0])
      }
      setLoading(false);
    });
  }


  function createRequest() {
    // setSaving(true);
    postData(authTokens, "CreateMarketPlaceRequest", {
      ObjectId: 0,
      MarketPlacePricing: JSON.stringify(selectedPricingItem ?? {}),
      Quantity: quantity,
      ObjId_Marketplace: /*12417619*/marketplaceId,
      CustomerId: /*5920581*/customerId,
      PreferredDate: formatDate(date),
      PreferredTime: time,
      Telephone: phoneNumber,
      RequestName: name,
      Total: quantity * selectedPricingItem?.Total,
      Message: message
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Created successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        // setSaving(false);
        hideModal();
        clearModal();
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
        hideModal();
        clearModal();
        console.log(err);
      });
  }

  function validation() {
    let errors = 0;
    if (!name || name == '') {
      errors++;
      setErrorsList((prev) => ({ ...prev, nameError: true }));
    }
    if (!phoneNumber) {
      errors++;
      setErrorsList((prev) => ({ ...prev, phonNumberError: true }));
    }
    if (!quantity) {
      errors++;
      setErrorsList((prev) => ({ ...prev, quantityError: true }));
    }
    if (!timezone) {
      errors++;
      setErrorsList((prev) => ({ ...prev, timezoneError: true }));
    }
    if (!date) {
      errors++;
      setErrorsList((prev) => ({ ...prev, dateError: true }));
    }
    return errors;

  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="sell-add-request-modal"
      id="sell-add-request-modal"
      className="sell-add-request-modal"
      aria-labelledby="sell-add-request-modal"
      centered
      scrollable
      onHide={() => {
        hideModal();
        clearModal();
      }}
    >
      <Modal.Header className="d-flex align-items-center justify-content-between">
        <div className="f-500 f-14 f-black line-height-22 letter-space-2 w-100">Request</div>
        <img
          alt=""
          height={11}
          width={11}
          src="/images/attack-surface/cross-icon.svg"
          onClick={() => {
            hideModal();
            clearModal();
          }}
        />
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        {
          loading ? <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
            <GreyLoader width={30} />
          </span> :
            <div className="d-flex align-items-center w-100 h-100 main-container">
              <div className="sell-request-section sell-request-left h-100 p-3">
                <div className="f-500 f-14">Name</div>
                <input
                  type="text"
                  className={`w-100 p-2 mt-1 radius-4 highlight-input-with-grey-border ${errorsList?.nameError ? "input-error radius-4" : ""}`}
                  value={name}
                  placeholder="Name"
                  onChange={(e) => {
                    if (e?.target?.value == '') {
                      setErrorsList((prev) => ({ ...prev, nameError: true }));
                    } else {
                      setErrorsList((prev) => ({ ...prev, nameError: false }));
                      setName(e?.target?.value);
                    }
                  }}
                />

                <div className="f-500 f-14 mt-3">Tel#</div>
                <input
                  type="number"
                  className={`w-100 p-2 mt-1 radius-4 highlight-input-with-grey-border ${errorsList?.phonNumberError ? "input-error radius-4" : ""}`}
                  value={phoneNumber}
                  placeholder="Placeholder"
                  onChange={(e) => {
                    if (e?.target?.value == 0) {
                      setErrorsList((prev) => ({ ...prev, phonNumberError: true }));
                    } else {
                      setErrorsList((prev) => ({ ...prev, phonNumberError: false }));
                      setPhoneNumber(e?.target?.value);
                    }
                  }}
                />

                <div className="f-500 f-14 mt-3 mb-1">When should we call?</div>
                <div
                  className={`${errorsList?.timezoneError ? 'border-red-1 radius-4' : 'hg-border-solid radius-4'}`}>
                  <div>

                    <CompanyTimeZoneDropDown
                      fieldId={"DDLId"}
                      fieldValue={"DDLText"}
                      placeholder="Choose time zone"
                      selected={timezone}
                      onClick={(id, val) => {
                        setErrorsList((prev) => ({ ...prev, timezoneError: false }));
                        setTimezone({ DDLId: id, DDLText: val });
                      }}
                      data={
                        companyDetails?.DDLMembers &&
                        companyDetails?.DDLMembers[2].DDLMemberList
                      }
                    />
                  </div>
                </div>

                <div className={`mt-1 ${isMobile ? '' : 'd-flex align-items-center justify-content-between'}`}>
                  <div className={`${isMobile ? 'w-100' : 'w-48'}`}>
                    <HighgroundDatePicker
                      selected={
                        formatDate(date) ??
                        ""
                      }
                      placeholder={"Choose date"}
                      icon={"/images/attack-surface/date-icon.svg"}
                      onChange={(e) => {
                        setErrorsList((prev) => ({ ...prev, dateError: false }));
                        setDate(e);
                      }}
                      id={"bhfgefgbhghvwmtv"}
                      DropdownClassName={'dateDrop mt-1 hg-border-solid-imp'}
                      isError={errorsList?.dateError}
                    />
                  </div>

                  <Dropdown className={`time-dropdown ${isMobile ? 'w-100' : 'w-48'}`}>
                    <Dropdown.Toggle>
                      <input
                        type="text"
                        className="px-2 py-1 mt-1 radius-4 highlight-input-with-grey-border w-100"
                        style={{
                          height: '32px'
                        }}
                        value={time}
                        placeholder="Time"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        timeArray?.map((timeItem) => {
                          return (
                            <div className={`f-400 f-14 greyText2 p-1 pointer bg-hover-grey ${time == timeItem ? "bg-grey-light" : ""}`}
                              onClick={(e) => {
                                setTime(timeItem);
                                e.target
                                  .closest(".time-dropdown")
                                  .click();
                              }}
                            >{timeItem}</div>
                          )
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="f-500 f-14 mt-3">Message</div>
                <div
                  className="d-flex align-items-center radius-4 grey-border flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border"
                >
                  <textarea
                    rows={4}
                    className="bg-transparent flex-grow-1 border-none  hide-scrollbar p-2"
                    placeholder="Optional"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />

                </div>


              </div>
              <div className="sell-request-section sell-request-right h-100 p-3">
                <div className="d-flex align-items-center pb-3 border-bottom-gray">
                  <img
                    src={marketplaceData?.ProductIcon}
                    alt=""
                    width={32}
                    height={32}
                    className="mr-2 radius-4"
                  />
                  <div>
                    <div className="f-500 f-14">{marketplaceData?.ProductName}</div>
                    <div className="f-400 f-12 f-darkgrey">{marketplaceData?.ProductIconName}</div>
                  </div>
                </div>

                <div className="f-500 f-14 f-darkgrey mt-3 ">Options</div>
                <div className="pb-3 border-bottom-gray ">
                  <div className="radius-8 border-parent bg-grey">
                    {
                      marketplaceData?.AllMarketPlacePricing_List?.map((pricingItem) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between p-2  border-item pointer"
                            onClick={() => {
                              setSelectedPricingItem(pricingItem);
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={selectedPricingItem?.ObjectId === pricingItem?.ObjectId ? "/images/radio-button-checked-icon.svg" : "/images/radio-button-unchecked-icon.svg"}
                                className="mr-2"
                                alt=""
                              />
                              <div className="">
                                <div className="f-500 f-14">{pricingItem?.PricingOptionName}</div>
                                <div className="f-400 f-12 f-darkgrey mt-1">{pricingItem?.IsRecurring && 'One-time purchase'}</div>
                              </div>
                            </div>
                            <div>
                              <div className="text-right">
                                <div className="f-500 f-14">{pricingItem?.CurrencyCode_Selected?.CurrencySymbol}{pricingItem?.Total}</div>
                                <div className="f-400 f-12 f-darkgrey mt-1">{pricingItem?.BillingPeriod_Selected?.DDLText ? `/${pricingItem?.BillingPeriod_Selected?.DDLText.toLowerCase()}` : ''}{" "}{pricingItem?.MeasurementOfUnit_Selected?.DDLText ? `/${pricingItem?.MeasurementOfUnit_Selected?.DDLText.toLowerCase()}` : ''}</div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>

                <div className="f-500 f-14 mt-3">Quantity</div>
                <input
                  type="number"
                  className={`w-100 p-2 mt-1 radius-4 highlight-input-with-grey-border ${quantity < 2 ? "input-error radius-4" : ""} ${errorsList?.quantityError ? "input-error radius-4" : ""} `}
                  value={quantity}
                  placeholder=""
                  onChange={(e) => {
                    if (e?.target?.value < 2) {
                      setQuantity(e?.target?.value);
                      setErrorsList((prev) => ({ ...prev, quantityError: true }));
                    } else {
                      setErrorsList((prev) => ({ ...prev, quantityError: false }));
                      setQuantity(e?.target?.value);
                    }
                  }}
                />
                <div className="d-flex align-items-center mt-1 pb-3 border-bottom-gray">
                  <img
                    src="/images/info-icon-light.svg"
                    alt=""
                    width={10}
                    height={10}
                    className="mr-1"
                  />
                  <div className="f-400 f-12 f-darkgrey">Minimum quantity 2 devices</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="f-600 f-14">Total</div>
                  <div className="f-600 f-14 greyText2">{selectedPricingItem?.CurrencyCode_Selected?.CurrencySymbol}{" "}{quantity ? addZerows((quantity * selectedPricingItem?.Total)?.toString()) : '0.00'}</div>
                </div>

                <div className="f-500 f-14 f-darkgrey mt-2">*{"  "}TAXes included</div>
                <div className="d-flex align-items-center mt-1">
                  <img
                    src="/images/info-blue.svg"
                    alt=""
                    width={10}
                    height={10}
                    className="mr-1"
                  />
                  <div className="f-400 f-12 f-darkgrey">Contract Term - 18 months</div>
                </div>
              </div>
            </div>
        }
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-end">
        <Button
          className="hg-cancel-btn mr-2"
          onClick={() => {
            hideModal();
            clearModal();
          }}
        >
          Cancel
        </Button>
        <Button
          className="hg-blue-btn"
          onClick={() => {
            let errors = validation();
            if (errors > 0) {
            } else {
              setIsChanges(true);
              createRequest();
            }
          }}
        >
          Send Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSellRequestModal;
